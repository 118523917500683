import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import { clearLocalStorage } from '../components/utils/ValidationScripts';

const Logout = () => {

    useEffect(()=>{
        clearLocalStorage();
    },[]);
    

    return (
        
        <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Login</h4>
                    </div>
                    <div className='col-md-4 offset-md-4 border pt-2'>
                    
                        <Navigate to="/login" replace={true} />
                        
                        <form className="needs-validation" name="logoutForm" >
                            Logging out in progress . . . 
                        </form>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Logout