import { useEffect, useState } from 'react';
import { zipCodeService } from '../../../../services/restAPI/UtilsRest';
import { useSelector } from 'react-redux';
import { isValidAlphaNumeric } from '../../../../utils/ValidationScripts';
const DataAreasServiced = () => {
    const  [filtersInput, setFiltersInput] = useState({
        userLookupInput : '',
        showTable: false
    });

    const [filtersReq,setFiltersReq] = useState({
        userLookupInput:    true,
        userLookupInput_ErrorClass : 'form-control'
    });

    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);

    const onSubmitSearch = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";
                var errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        }else{
            setFiltersInput({...filtersInput,showTable: false});
            loadReportData(filtersInput.userLookupInput);
           // console.log(filtersInput.userLookupInput);
        }
            
    }

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = "form-control";
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }
      
    const loadReportData = () => {
        
        let response =[];
        var postalCodes = filtersInput.userLookupInput.split(',');
        for(var i = 0; i < postalCodes.length; i++) {
            setShowDataLoading(true);
            zipCodeService(postalCodes[i].trim())
            .then(responseData => {
            if( responseData instanceof Error ){
                setReportData([]);
            }else{
                for(var j=0;j<responseData.length;j++){
                   // console.log(JSON.stringify(responseData[j]))
                response.push(responseData[j]);
               // console.log(JSON.stringify(response))
               setReportData([...response]);
                }
            }
            })
            .catch(err=>{
               
                })
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }
            
           // console.log(JSON.stringify(response))
        
       
    }

    const handleInputChange = (name, value) => {
        setFiltersInput({...filtersInput, [name]: value});
    }

    const handleInputChangeAlphaNumeric = (name, value) => {
        let obj = {}
        // console.log(value)
        const val = (isValidAlphaNumeric(value) || value==="") ? value : filtersInput[name];
        obj[name] = val;
        setFiltersInput({...filtersInput, ...obj});
    }

    return {
        handleInputChange,
        handleInputChangeAlphaNumeric,
        onSubmitSearch,
        onValidateFilters,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading
    }
}

export default DataAreasServiced;