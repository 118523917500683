import React, { useState, useEffect } from "react";
import TrackingReportData from "./TrackingReportData";
import { useSelector } from 'react-redux';
import { trackingHomeApiKey } from '../restAPI/TrackingAPI';
import { useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
const NonLoginTrack = () => {
    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);
    const navState = useLocation(); //passed value from Navigator
    let trackingId;
    let trackType;
    if (navState.state) {
        trackingId = navState.state.trackingId;
        trackType = navState.state.trackType;
    }
    let userProfileData = useSelector((state) => state.usr);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let userId = "1026";
            let custCode = "1026";
        if (userProfileData && userProfileData.custCode) {
            custCode = userProfileData.custCode;
            userId = userProfileData.userId;
        }
       // setShowDataLoading(true);
       trackingHomeApiKey(trackType, trackingId)
            .then(response => {
                if (response instanceof Error) {
                    // console.log("error objecct is trackingByDate " + JSON.stringify(response))
                    //setErrorStatus({ restError: true, restErrorMessage: response.message });
                    setReportData([]);
                } else {
                    setReportData([...response]);
                }
                setShowDataLoading(true);
            })
            .catch(err => {
                setShowDataLoading(false);
                setReportData([]);

            })
        }
        return () => mounted = false;
    }, []) // eslint-disable-line


    return (
        <section className="mt-180 mb-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 order-md-1">
                        <div className="card card-custom">
                            <div className="d-flex mb-2">
                                <h4 className="pageHead text-uppercase">Tracking Status</h4>
                            </div>
                            {!showDataLoading ?
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center"> 
                                    <GridLoader  color={'#1E73BE'} loading={!showDataLoading} />
                                </div>
                            </div>
                            :
                            <TrackingReportData reportData={reportData} showDataLoading={showDataLoading} showHideDetailsColumn="hide" />}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NonLoginTrack;