import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility'
//import { createReducer } from '@reduxjs/toolkit'

const initialState = {totQuantity:0 ,totWeight:0 , totDimWeight:0};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADDTOTALDIMENSIONS:
            return updateObject(state,action.totDimObj);
        
        default:
            return state
    }
};


export default reducer;