import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility'
// import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    "token": '',
        "userName": '',
        "custCode": '',
        "companyName": '',
        "address1": '',
        "address2": '',
        "city": '',
        "state": '',
        "postalCode": '',
        "country": '',
        "phone": '',
        "email": '',
        "contact": '',
        "isValid": false,
        "username": '',
        "userid": '',
        "password": '',
        "firstName": '',
        "lastName": '',
        "emailName": '',
        "permissionsLevel": 0,
        "menuPermissions": [
        ],
        "msg": ''
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.VALIDATEUSER:
            return updateObject(state, action.userProfile);
        case actionTypes.LOGOUTUSER:
            return updateObject(state,{isValid:false,token:null});
        case actionTypes.AUTOREFRESHTOKEN:
            return updateObject(state,{token:action.token,userName: action.userName,isValid:action.isValid});
        case actionTypes.REMOVEUSER:
            return updateObject({});
        default:
            return state
    }
};

export default reducer;