import {  useState , useEffect} from 'react';
import {invoiceSearch,invoiceDetailsSearch} from '../../../restAPI/CorporateCenterLinkRest';
import { useSelector } from 'react-redux';
const DataInvoices = () => {
    const  [initFiltersInput] = [{
        invoiceType : '',
        acNo : '',
        waybillNo : '',
        invNo : '',
        startDate : '',
        endDate : '',
        showTable: false
    }];

    const [initFiltersReq] = [{
        invoiceType:    true,
        invoiceType_ErrorClass : 'custom-control-input',
        acNo:    true,
        acNo_ErrorClass : 'form-control',
        waybillNo:    true,
        waybillNo_ErrorClass : 'form-control',
        invNo:    true,
        invNo_ErrorClass : 'form-control',
        startDate:    true,
        startDate_ErrorClass : 'form-control',
        endDate:    true,
        endDate_ErrorClass : 'form-control',
    }];
    const userProfileData = useSelector((state) => state.usr)
    const [filtersInput, setFiltersInput] = useState(initFiltersInput);
    const [filtersReq, setFiltersReq] = useState(initFiltersReq);

    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);
    const [reportDetailsData, setReporDetailstData] = useState([]);
    const [showDetailsDataLoading, setShowDetailsDataLoading] = useState(false);

    const onSubmitSearch = () => {
        var status = false;
        let errState = {};
        var sdStat = false,edStat=false,inStat=false,wbStat=false,typeStat=false;
        if( filtersInput.invoiceType ) {
            errState=Object.assign(errState,{"invoiceType_ErrorClass":' custom-control-input'});
            typeStat = false;

            if(filtersInput.invoiceType === "I" ){
                if(!filtersInput.invNo.trim()){
                    errState=Object.assign(errState,{"invNo_ErrorClass":' form-control is-invalid'});
                    inStat = true;
                }else{
                    errState=Object.assign(errState,{"invNo_ErrorClass":' form-control'});
                    inStat = false;
                }
            }
            else if(filtersInput.invoiceType === "D" ){
                if(!filtersInput.startDate){
                    errState=Object.assign(errState,{"startDate_ErrorClass":' form-control is-invalid'});
                    sdStat = true;
                }else{
                    errState=Object.assign(errState,{"startDate_ErrorClass":' form-control'});
                    sdStat = false;
                }
                if(!filtersInput.endDate){
                    errState=Object.assign(errState,{"endDate_ErrorClass":' form-control is-invalid'});
                    edStat = true;
                }else{
                    errState=Object.assign(errState,{"endDate_ErrorClass":' form-control'});
                    edStat = false;
                }
            }
            else if(filtersInput.invoiceType === "H" ){
                if(!filtersInput.waybillNo.trim()){
                    errState=Object.assign(errState,{"waybillNo_ErrorClass":' form-control is-invalid'});
                    wbStat = true;
                }else{
                    errState=Object.assign(errState,{"waybillNo_ErrorClass":' form-control'});
                    wbStat = false;
                }
            }
        }else{
            errState=Object.assign(errState,{"invoiceType_ErrorClass":' custom-control-input is-invalid'});
            typeStat = true;
        }
        if(typeStat || sdStat || edStat || inStat || wbStat)
        status = true; 
        else
        status = false;
        
        if(status){
            setFiltersReq({...filtersReq,...errState});
        }else{
            setShowDataLoading(true);
            setFiltersInput({...filtersInput, showTable: false});
            loadReportData();
        }
            
    }

    const userProfile = useSelector((state) => state.usr)
    // console.log(JSON.stringify(userProfile));
   
    useEffect(() => {
		// 👇️ scroll to top on page load
        let accountNo = userProfile.custCode;
        handleInputChange("acNo",accountNo);
	  }, []);

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = (key==="invoiceType" ? "custom-control-input" : "form-control");
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }

    const loadReportData = () => {
       invoiceSearch(filtersInput.acNo,filtersInput.invoiceType,
                        filtersInput.startDate,filtersInput.endDate,
                        filtersInput.waybillNo,filtersInput.invNo)
        .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
        })
        .catch(err=>{
            setReportData([]);
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
        })
                //console.log("in finally"+JSON.stringify(searchData));
                
           

    }

    const handleInputChange = (name, value) => {
        setFiltersInput({...filtersInput, [name]: value});
    }

    const clearSearch = () => {
        setFiltersInput(initFiltersInput);
        setFiltersReq(initFiltersReq);
    }

    const fetchInvoiceDetailsData = (invoiceNo, nav) => {
        setShowDetailsDataLoading(true);
        invoiceDetailsSearch(userProfileData.custCode,invoiceNo)
         .then(reponseDate => {
             if( reponseDate instanceof Error ){
                setReporDetailstData([]);
             }else{
                setReporDetailstData([...reponseDate]);
                let _url = '/corporate/invoices/details?invoiceNo='+invoiceNo;
                let _d = [...reponseDate];
                // console.log('rr='+JSON.stringify(_d))
                nav(_url, {state : { invoiceDetails : _d } });
             }
             setShowDetailsDataLoading(false);
            //  setFiltersInput({...filtersInput, showTable: true});
         })
         .catch(err=>{
            setReporDetailstData([]);
             setShowDetailsDataLoading(false);
            //  setFiltersInput({...filtersInput, showTable: true});
         })
                 //console.log("in finally"+JSON.stringify(searchData));
                 
            
 
     }

    

    return {
        handleInputChange,
        onSubmitSearch,
        onValidateFilters,
        clearSearch,
        fetchInvoiceDetailsData,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading,
        reportDetailsData,
        showDetailsDataLoading
    }
}

export default DataInvoices;
