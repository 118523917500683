import {  useState } from 'react'

const DataSpecialNew = () => {
  
    const  [initData] = [{
        terminal: '',
        zone: '',
        moN_FRI_0000_0759: '',
        moN_FRI_0800_1700: '',
        moN_FRI_1701_2359: '',
        saturday: '',
        sunday: '',
        holiday: ''
        
    }];
    const [initDataReq] = [{
        terminal_ErrorClass : 'form-control',
        zone_ErrorClass : 'form-control',
        moN_FRI_0000_0759_ErrorClass : 'form-control',
        moN_FRI_0800_1700_ErrorClass: 'form-control',
        moN_FRI_1701_2359_ErrorClass: 'form-control',
        saturday_ErrorClass: 'form-control',
        sunday_ErrorClass: 'form-control',
        holiday_ErrorClass: 'form-control',
        terminal: false,
        zone: false,
        moN_FRI_0000_0759: true,
        moN_FRI_0800_1700: true,
        moN_FRI_1701_2359: true,
        saturday: true,
        sunday: true,
        holiday: true,
    }];

    const [formData, setFormData] = useState(initData);

    const [formDataReq, setFormDataReq] = useState(initDataReq);

    const [showSaveDataLoading, setSaveShowDataLoading] = useState(false);

    const onSubmitNewSpecial = () => {
        const copy = {...formData};
        const errCopy = {...formDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
            setFormDataReq({...formDataReq,...errObject});
            validationReqFlag = true;
        } 
        return validationReqFlag;
            
    }

    const onValidateNewSpecial = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if(key === name && value) {
            errState = {[name]: true,[errname]: _css+' is-invalid'};
            status=true;
        }else if(key === name && !value) {
            errState = {[name]: false,[errname]: _css+''};
            status=true;
        }
        if(status)
            setFormDataReq({...formDataReq, ...errState});
      }

    const handleInputChange = (name, value) => {
        setFormData({...formData, [name]: value});
    }

    const clearNewSpecialForm = () => {
        setFormData(initData);
        setFormDataReq(initDataReq);
    }

    const setFormDataObj = (_obj) => {
        //console.log("fd",formData)
        let res_obj = { ...formData, ..._obj}
        setFormData(res_obj);
        //console.log("res",res_obj)
    }

    return {
        formData,
        formDataReq,
        handleInputChange,
        onSubmitNewSpecial,
        onValidateNewSpecial,
        clearNewSpecialForm,
        setFormDataObj
    }
}

export default DataSpecialNew;