import React, { useEffect } from "react";
//import { useSelector } from 'react-redux';
//import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../formControls/InputElement";
import DataWebAccount from "./Data/DataWebAccount";
import { useState } from "react";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
window.JSZip = jzip;

const WebAccDatatable = (props) => {

    const [table,setTable] = useState();

    let selectedIds = [];
    const addorRemoveSelection = (value) => {
        selectedIds.push(value);
       // console.log("selected==>"+selectedIds)
        // if(props.filtersInput.multipage){
        //     selectedIds.push(props.selectedUID)
        //     props.handleInputChange("selectedIds", selectedIds);
        //     props.handleSelectedUID(value)
        // }else{
        //     selectedIds.push(props.selectedUID)
        //     props.handleInputChange("selectedIds", selectedIds);
        // }
    }

    
      // let table = null;
   // let userProfileData = useSelector((state) => state.usr)
    useEffect(() => {
         //console.log(" datatable came ==>"+JSON.stringify(props.reportData.length))
         //const param = "?custcode = " +userProfileData.custCode;
         
         setTable(   $("#webAccontTbl")
        .DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            "scrollCollapse":true,
            // "scrollY": '200px',
            "autoWidth": true,
            "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            // "ajax": {
            //     "url": process.env.REACT_APP_USER_LOOKUP_CUSTCODE_URL+param,
            //     "type": "GET",
            //     "dataSrc": function ( json ) {
            //         if(props.filtersInput.accountType === 'V' || props.filtersInput.accountType ==='')
            //         return json;
            //         else {
            //         const filteredReportData = json.filter((eachRow) => {
            //              return eachRow.Status === props.filtersInput.accountType; 
            //          });
            //          return filteredReportData;
            //         }
            //         return [];
            //     }       
            //   },
            "data":  props.reportData,
            "dom":
                "<'row'<'col-lg-3 col-md-4'l><'col-lg-5 col-md-8 text-right'B><'col-lg-4 col-md-6 offset-lg-0 offset-md-6'f>>" +
                "<'row'<'col-lg-12'tr>>" +
                "<'row'<'col-md-5'i><'col-md-7'p>>",
            "buttons": [
                {
                    extend: 'copyHtml5',
                    className: 'btn btn-primary btn-sm',
                    text: '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                    filename: 'UserLookup',
                    title: 'User Lookup',
                    titleAttr: 'Copy'
                },
                {
                    extend: 'excelHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text: '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                    filename: 'UserLookup',
                    title: 'User Lookup',
                    titleAttr: 'Excel',
                    exportOptions: {
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: false
                        }
                    },

                },
                // {
                //     extend: 'csvHtml5',
                //     className: 'btn btn-primary  btn-sm',
                //     text: '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                //     filename: 'UserLookup',
                //     title: 'User Lookup',
                //     titleAttr: 'CSV'
                // },
                {
                    extend: 'pdfHtml5',
                    text: '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                    className: 'btn btn-primary  btn-sm',
                    filename: 'UserLookup',
                    title: 'User Lookup',
                    titleAttr: 'PDF',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    customize: function (doc) {
                        doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                    }
                }
            ],
            "lengthMenu": [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
            ],
            "columns": [
                {
                    "data": 'Uid',
                    render: function (data, type, row, api) {
                        //console.log(api.row.index())
                        return '<input class="form-control chk"  type="checkbox" id="'+ data +'" name="'+ data +'" value='+data +'>';
                    }
                },
                { "data": "Status" },
                { "data": "dValidate" },
                { "data": "Username" },
                { "data": "FirstName" },
                { "data": "Company" },
                { "data": "CustCode" },
                { "data": "Station", },
                { "data": "Email" },
                { "data": "Phone" },
            ],

            "scrollY": 350,
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function (settings, json) {
            },
        }));

      
        // Add event listener for opening and closing details
        $('#webAccontTbl tbody').on('click', 'tr td input.chk', function (event) {
            var allSel = [];
            if(props.filtersInput.multipage){
                allSel =  props.selectedUID;
                props.handleInputChange("selectedIds",allSel);
            }else{
                //allSel =  props.filtersInput.selectedIds
                allSel =  props.selectedUID;
            }

            $("#webAccontTbl input:checkbox").map(function(){
              if($(this)[0].checked){
                var arrPos = allSel.indexOf($(this).val())
                if(arrPos <= -1)
                allSel.push($(this).val());
              }else{
                var arrPos = allSel.indexOf($(this).val())
                if(arrPos > -1)
                allSel.splice(arrPos,1);
              }              
            }) // <----
            props.handleInputChange("selectedIds",allSel);
    });
     
    $('#webAccontTbl').on('dblclick', function (event) {
        $("#webAccontTbl input:checkbox").map(function(){
            if($(this)[0].checked){
                $(this).prop( "checked", false );
                // console.log($(this).val())
            }
            //$('input:checkbox').attr('checked','checked');
            //$(this).removeAttr('checked');
            // $('.form-control chk').toggle(function(){
            //     $('input:checkbox').attr('checked','checked');                
            // },function(){
            //     $('input:checkbox').removeAttr('checked');
            // })
        });
    });
        $('#webAccontTbl').on('page.dt', function (event) {
            props.handleSelectedUID(props.filtersInput.selectedIds)
            props.handleInputChange("multipage", true);
            props.handleInputChange("selectedIds",props.selectedUID);           
         });

    }, [props.filterType,props.showDataLoading]);
    
    
    
    // useEffect( ()=>{ 
    //     var searchVal = props.filtersInput.accountType;
    //     if(props.filtersInput.accountType === 'V' || props.filtersInput.accountType === ''){
    //         searchVal = '';
    //     }
    //     console.log(table+"Search Value"+searchVal)
    //     table.columns( 0 ).search( searchVal ).draw();
    //     }, [props.filtersInput.accountType]); 

    return (
        <div id="datatable">
            <table id="webAccontTbl" className="display" style={{ width: "100%", border: "1px solid #ccc" }}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Status</th>
                        <th>Validate Date</th>
                        <th>User Name</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>Cust-Id</th>
                        <th>Station</th>
                        <th>Email</th>
                        <th>Phone</th>

                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            {/* <div>{JSON.stringify(props.filtersInput)}</div> */}
        </div>
    );
}

export default WebAccDatatable;