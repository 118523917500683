import React, { useEffect,useState } from "react";
import {  requestChangePass, requestValidateOldPass } from '../services/restAPI/UserAPI';
import InputElement from '../formControls/InputElement';
import { useSelector } from "react-redux";
const ChangePassword = () => {

    const handleInputChange = (name, value) => {
        setDataInput({ ...dataInput, [name]: value });
    }
    
    const [dataInput, setDataInput] = useState({
        userName:'',
        email:'',
        oldpass: '',
        oldpassState: true,
        oldpassMessage: '',
        newpass: '',
        newpass1: '',
        newpassState: true,
        newpassMessage: '',
        emailSecCode:'',
    });
    const [dataRequired, setDataRequired] = useState({
        oldpassCS: 'form-control',
        newpassCS: 'form-control',
        newpass1CS: 'form-control',
        newpassMes: 'required.',
        newPassMesCS: '',
        oldPassMesCS:'',
        newpasMes :'required.',
        plen : 'ml-2  text-danger',
        plow : 'ml-2 text-danger',
        pup : 'ml-2 text-danger',
        pnum : 'ml-2 text-danger',
        psp : 'ml-2  text-danger',
        pborder : 'mt-3 passBorderRed'
    })
    const [loading, setLoading] = useState(false);

    const userProfile = useSelector((state) => state.usr)
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                if(userProfile!==null){
            console.log(userProfile.username)
            setDataInput({...dataInput,['userName']: userProfile.username,['email']: userProfile.email})
        }
    }, []);
    const createNewPassword = () => {
        let isValidationReq = true;
        //if (!dataInput.secAns)
           // isValidationReq = false;

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestChangePass(dataInput.email, dataInput.userName, dataInput.emailSecCode, dataInput.newpass1)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['newpassState']: false });
                        Object.assign(response, { ['newpassMessage']: 'Please contact customer support' });
                        Object.assign(error, { ['newPassMesCS']: 'text-danger text-center' });
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                        //console.log('dRes==>', JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.isPasswordChanged === "SUCCESS" ) {
                            Object.assign(response, { ['newpassState']: true });
                            Object.assign(response, { ['newpassMessage']: 'Password Changed Sucessfully' });
                            Object.assign(error, { ['newPassMesCS']: ' col-sm-6 mt-4 text-success text-center' });
                        } else {
                            Object.assign(response, { ['newpassMessage']: 'Unable to change the password' });
                            Object.assign(response, { ['newpassState']: false });
                            Object.assign(error, { ['newPassMesCS']: 'col-sm-6 mt-4 text-danger text-center' });
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(err => {
                    setLoading(false);
                })

        }
    }
    const requestOldPass = () => {
        let isValidationReq = true;
        //if (!dataInput.secAns)
           // isValidationReq = false;

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestValidateOldPass(dataInput.email, dataInput.userName,  dataInput.oldpass)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['oldpassState']: false });
                        Object.assign(response, { ['oldpassMessage']: 'Please contact customer support' });
                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                        //console.log('dRes==>', JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.isValid === "true" || dRes.isValid === true) {
                            Object.assign(response, { ['oldpassState']: true });
                            Object.assign(response, { ['oldpassMessage']: 'Old password is valid' });
                            Object.assign(error, { ['oldPassMesCS']: ' col-sm-6 mt-4 text-success text-center' });
                        } else {
                            Object.assign(response, { ['oldpassMessage']: 'Old password is incorrect' });
                            Object.assign(response, { ['oldpassState']: false });
                            Object.assign(error, { ['oldPassMesCS']: 'col-sm-6 mt-4 text-danger text-center' });
                            Object.assign(error, { ['oldpassCS']: 'form-control is-invalid' });
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(err => {
                    setLoading(false);
                })

        }
    }

    const validateOldPass = () => {
        var errState = {};
        if (!dataInput.oldpass) {
            Object.assign(errState, { ['oldpassCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['oldpassCS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
    }
    const validateNewPass = () => {
        var p = dataInput.newpass;
        var errMes='';
        var  errMess={}
        var errState=true;
        
        if (p.search(/[a-z]/) < 0) {
            errMes="Password must contain at least one letter.";
            errState=false;
            Object.assign(errMess, { ['plow']: 'ml-2 text-danger' });
        }else{
            Object.assign(errMess, { ['plow']: 'ml-2 text-success' });
        }
        if (p.search(/[A-Z]/) < 0) {
            errMes="Password must contain at least one Upper letter.";
            errState=false;
            Object.assign(errMess, { ['pup']: 'ml-2 text-danger' });
        }else{
            Object.assign(errMess, { ['pup']: 'ml-2 text-success' });
        }
        if (p.search(/[0-9]/) < 0) {
            errMes="Password must contain at least one digit."; 
            errState=false;
            Object.assign(errMess, { ['pnum']: 'ml-2 text-danger' });
        }else{
            Object.assign(errMess, { ['pnum']: 'ml-2 text-success' });
        }
        if (p.search(/[(!*@#$%^&+=)]/) < 0) {
            errMes="Password must contain at least one (!*@#$%^&+=) special characters"; 
            errState=false;
            Object.assign(errMess, { ['psp']: 'ml-2 text-danger' });
        }else{
            Object.assign(errMess, { ['psp']: 'ml-2 text-success' });
        }
        
        if (p.length < 8) {
            errMes="Password must be at least 8 characters"; 
            errState=false;
            Object.assign(errMess, { ['plen']: 'ml-2 text-danger' });
        }else{
            Object.assign(errMess, { ['plen']: 'ml-2 text-success' });
        }

        if(!errState){
            Object.assign(errMess, { ['newpassCS']: 'form-control is-invalid' });
            Object.assign(errMess, { ['newpasMes']: 'required.' });
            Object.assign(errMess, { ['pborder']: 'passBorderRed' });
        }
        else{
            Object.assign(errMess, { ['newpassCS']: 'form-control is-valid' });
            Object.assign(errMess, { ['newpasMes']: '' });
            Object.assign(errMess, { ['pborder']: 'passBorderGreen' });
            
        }
        setDataRequired({ ...dataRequired,  ...errMess })
        return errState;
        
    }
    const validateNewPass1 = () => {
        var errState = {};
        if (!dataInput.newpass1) {
            Object.assign(errState, { ['newpass1CS']: 'form-control is-invalid' });
            Object.assign(errState, { ['newpassMes']: 'required.' });
        } else if (dataInput.newpass !== dataInput.newpass1) {
            Object.assign(errState, { ['newpassMes']: 'Password does not match' });
            Object.assign(errState, { ['newpass1CS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['newpassMes']: '' });
            Object.assign(errState, { ['newpass1CS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })

    }

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead">Hey {dataInput.userName} Change your Password</h4>
                    </div>
                    <hr />
                    <div className="row">
                    <div className="col-md-6">
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <label htmlFor="oldpass">Old password</label>
                            <InputElement type="password" id="oldpass" name="oldpass" value={dataInput.oldpass || ''} className={dataRequired.oldpassCS}
                                onChange={handleInputChange} onInvalid={validateOldPass} validation="required" placeholder='Old Password' />
                            <div className="invalid-feedback">
                                    required.
                            </div>
                        </div>
                   
                    
                        <div className="col-sm-6 mt-4">
                            <button className="btn btn-primary btn-block pull-left" type="button"
                                onClick={requestOldPass}
                            >Validate... &nbsp;<i className="fas fa-check-circle"></i>  </button>
                        </div>
                        
                        <div className={dataRequired.oldPassMesCS} >
                            {dataInput.oldpassMessage}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <label htmlFor="newpass">New Password</label>
                            <InputElement type="password" id="newpass" name="newpass" value={dataInput.newpass || ''} className={dataRequired.newpassCS}
                                onChange={handleInputChange} onInvalid={validateNewPass} validation="required" placeholder='New Password' />
                            <div className="invalid-feedback">
                            {dataRequired.newpasMes}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-6">
                            <label htmlFor="newpass1">Re-enter New password</label>
                            <InputElement type="password" id="newpass1" name="newpass1" value={dataInput.newpass1 || ''} className={dataRequired.newpass1CS}
                                onChange={handleInputChange} onInvalid={validateNewPass1} validation="required" placeholder='Re-enter New Password' />
                            <div className="invalid-feedback">
                                {dataRequired.newpassMes}
                            </div>
                        </div>
                   
                    
                        <div className="col-sm-6 mt-4">
                            <button className="btn btn-primary btn-block pull-left" type="button"
                                onClick={createNewPassword}
                            >Change Password &nbsp;<i className="fas fa-check-circle"></i>  </button>
                        </div>
                        <div className={dataRequired.newPassMesCS} >
                            {dataInput.newpassMessage}
                        </div>
                    </div>
                    </div>
                    <div className="col-md-6 mt-5">
                    <div className={dataRequired.pborder} >
                        <span  className={dataRequired.plen}> Password MUST have at least 8 Characters</span><br></br>
                        <span  className={dataRequired.plow}> Password MUST have at least 1 lowercase letter</span><br></br>
                        <span  className={dataRequired.pup}> Password MUST have at least 1 UPPERCASE letter</span><br></br>
                        <span  className={dataRequired.pnum}> Password MUST have at least 1 number</span><br></br>
                        <span  className={dataRequired.psp}> Password MUST have at least 1 of the following special character (!*@#$%^&+=)</span>
                    </div>
                    </div>
                 </div>   
                </div>
            </div>
        </div>

    );
}

export default ChangePassword;