import { useEffect, useState } from 'react';


const DataShipping = () => {
  //console.log("DataShipping");
  const [lineHaulTermDetails, setLineHaulTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [roadRunnerTermDetails, setRoadRunnerTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [fffTermDetails, setfffTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [accessorialDetails, setAccessorialDetails] = useState([{
    code: "",
    description: "***",
    amount: 0,
    min: 0,
    surType: ""
  }]);
 
  
  const [initialInputs] = [{
    lineHaul: "localpu",
    fffTo: true,
    fffFrom: true,
    shipFrom: '',
    shipFromDetails: {},
    shipTo: '',
    dropToCarrier:'',
    recoverCarrier:'',
    shipToDetails: {},
    pickupInfo: null,
    deliveryInfo: null,
    specialPickup: false,
    specialDelivery: false,
    terminalFrom: '0',
    terminalFromShow: true,
    terminalTo: '0',
    terminalToShow: true,
    shipCodeFromEntry: true,
    shipCodeToEntry: true,
    disableTerminalFromSel: false,
    disableTerminalToSel: false
  }];

  const [initialRequired] = [{

    shipFrom: true,
    shipFrom_ErrorClass: 'form-control',
    shipTo: true,
    shipTo_ErrorClass: 'form-control',
    // pickupInfo:true,
    // pickupInfo_ErrorClass:'form-control',
    // deliveryInfo:true,
    // deliveryInfo_ErrorClass:'form-control',

  }];

  const [quoteShipping, setQuoteShipping] = useState(initialInputs);
  const [shippingRequired, setShippingRequired] = useState(initialRequired);

  const fetchAddress = (lineHaul, ffe, code) => {
    if (lineHaul === "lineHaul") {
      return lineHaulTermDetails.find(eachobj => eachobj.AirportCode === code)
    }else if (lineHaul === "NH") {
      return roadRunnerTermDetails.find(eachobj => eachobj.AirportCode === code)
    }else if (lineHaul === "localpu")  {
      return fffTermDetails.find(eachobj => eachobj.AirportCode === code)
    }
    return {};
  }

  const onChangeShip = (name, value ) => {
    var newValue = { [name]: value };
    setQuoteShipping({ ...quoteShipping, [name]: value });
    let lineHaulVal = quoteShipping.lineHaul;
    // setShippingRequired({ ...shippingRequired, });
    if (name[0].trim() === "fffTo" && value) {
      newValue = Object.assign(newValue, { "terminalToShow": true, "terminalTo": "0", "shipTo": "","lineHaul": lineHaulVal })
      setShippingRequired({ ...shippingRequired, ...{ "shipTo": true, "shipTo_ErrorClass": 'form-control' } });
    } else if (name[0].trim() === "fffFrom" && value) {
      newValue = Object.assign(newValue, { "terminalFromShow": true, "terminalFrom": "0", "shipFrom": "", "lineHaul": lineHaulVal })
      setShippingRequired({ ...shippingRequired, ...{ "shipFrom": true, "shipFrom_ErrorClass": 'form-control' } });
    } else if (name[0].trim() === "fffFrom" && !value) {
      newValue = Object.assign(newValue, { "terminalFromShow": true, "terminalFrom": "0", "shipFrom": "", "shipFromDetails": {} })
      setShippingRequired({ ...shippingRequired, ...{ "shipFrom": true, "shipFrom_ErrorClass": 'form-control' } });
    } else if (name[0].trim() === "fffTo" && !value) {
      newValue = Object.assign(newValue, { "terminalToShow": true, "terminalTo": "0", "shipTo": "", "shipToDetails": {} })
      setShippingRequired({ ...shippingRequired, ...{ "shipTo": true, "shipTo_ErrorClass": 'form-control' } });
    } 
    /*else if (name[0].trim() === "fffFrom" && !value) {
      newValue = Object.assign(newValue, { "terminalFromShow": false, "terminalFrom": "0", "shipFrom": "", "shipFromDetails": {} })
      setShippingRequired({ ...shippingRequired, ...{ "shipFrom": true, "shipFrom_ErrorClass": 'form-control' } });
    } else if (name[0].trim() === "fffTo" && !value) {
      newValue = Object.assign(newValue, { "terminalToShow": false, "terminalTo": "0", "shipTo": "", "shipToDetails": {} })
      setShippingRequired({ ...shippingRequired, ...{ "shipTo": true, "shipTo_ErrorClass": 'form-control' } });
    }
    */

    if (name[0].trim() === "terminalFrom" && value) {
      const addrFrom = fetchAddress(quoteShipping.lineHaul, quoteShipping.fffFrom, value);
      newValue = Object.assign(newValue, { "shipFromDetails": addrFrom, "shipFrom": addrFrom.PostalCode, "shipCodeFromEntry": false })
      onValidateShip("shipFrom", false);
    }
    if (name[0].trim() === "terminalTo" && value) {
      const addrTo = fetchAddress(quoteShipping.lineHaul, quoteShipping.fffTo, value);
      newValue = Object.assign(newValue, { "shipToDetails": addrTo, "shipTo": addrTo.PostalCode, "shipCodeToEntry": false })
      onValidateShip("shipTo", false);
    }

    setQuoteShipping({ ...quoteShipping, ...newValue });
    //console.log("name="+name+":value="+value);
    // Iterate the properties with with Requird Fields and set property value true if it is null
    // We will use same object on submit to show error if any values is emapty
    //  Object.entries(shippingRequired).map(([key]) => {
    //       if(key===name && !(value) ) {
    //         setShippingRequired({...shippingRequired, [name]: true});
    //       }
    //     })
    // console.log("DatShipping requiredFiels"+JSON.stringify(shippingRequired));
  }

  const onValidateShip = (name, value) => {
    var errState = {};
    var status = false;
    Object.entries(shippingRequired).forEach(([key]) => {
      var errname = key + "_ErrorClass";
      if (key === name && value) {
        errState = { [name]: true, [errname]: 'form-control is-invalid' };
        status = true;
      } else if (key === name && !value) {
        errState = { [name]: false, [errname]: 'form-control is-valid' };
        status = true;
      }
    })
    if (status)
      setShippingRequired({ ...shippingRequired, ...errState });
    //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
  }

  const onSubmitShipDetails = () => {
    const copy = { ...shippingRequired };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = { [key]: true, [errname]: 'form-control is-invalid' };
        status = true;
        Object.assign(errObject, errState);
        // console.log("Total Error obj "+JSON.stringify(errObject));
      }
    })
    if (status) {
      setShippingRequired({ ...shippingRequired, ...errObject });
      // console.log("Copied  Total "+JSON.stringify(totalDimRequired));
    }

    let reqObj = { ...shippingRequired };
    Object.entries(reqObj).forEach(([key, value]) => {
      if (key.includes("_ErrorClass")) {
        delete reqObj[key]
      }
    })

    // console.log("c="+JSON.stringify(reqObj))
    validationReqFlag = Object.values(reqObj).includes(true);

    return validationReqFlag;
  }

  const toggleTerminals = async (lineHaul, selectedValue, termFrom, termTo, shipFrom, shipTo, shipFromDetails, shipToDetails, shipCodeFromEntry, shipCodeToEntry, resetWithValues) => {
    //console.log(JSON.stringify(selectedValue)+"terminalState"+ JSON.stringify(lineHaul));
    var terminalState = {},terminalStateReq = {};
    if (lineHaul === "lineHaul"  || lineHaul === "NH") { // ==="true"
      // console.log("current shippoing statue"+selectedValue);
      if (selectedValue == "1"){ // Terminal to Door
        terminalState = { "terminalFromShow": true, "terminalToShow": false, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": "0", "fffFrom" : true, "fffTo" : false , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails 
                        , "disableTerminalFromSel" : true , "disableTerminalToSel" : false }
      }else if (selectedValue == "2"){ // Door to Door
        terminalState = { "terminalFromShow": false, "terminalToShow": false, "lineHaul": lineHaul, "terminalFrom": "0" , "terminalTo": "0", "fffFrom" : false, "fffTo" : false , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
      }else if (selectedValue == "3"){ // Door to Terminal 
        terminalState = { "terminalFromShow": false, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": "0", "terminalTo": termTo, "fffFrom" : false, "fffTo" : true, "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails 
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : true }
      }else if (selectedValue == "4"){ // Terminal to Terminal
        terminalState = { "terminalFromShow": true, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": termTo, "fffFrom" : true, "fffTo" : true, "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
      
      //     console.log("terminalState"+ JSON.stringify(quoteShipping));
    }
  } else if (lineHaul === "localpu") { // ==="false"
        terminalState = {  "terminalFromShow": true, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": termTo, "fffFrom" : true, "fffTo": true , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
    } 
    //console.log("shipFromDetails == "+ JSON.stringify(shipFromDetails));
    //console.log("shipToDetails == "+ JSON.stringify(shipToDetails));
    if( resetWithValues ){
    }else{
      if(termFrom == "0" || termFrom == "" ){
        terminalState = Object.assign(terminalState, { "shipFrom": "", "shipCodeFromEntry" : true})
        terminalStateReq = Object.assign(terminalStateReq, { "shipFrom": true, "shipFrom_ErrorClass": "form-control"})
        
      }
      if(termTo == "0" || termTo == "" ){
        terminalState = Object.assign(terminalState, { "shipTo": "" , "shipCodeToEntry" : true})
        terminalStateReq = Object.assign(terminalStateReq, { "shipTo": true, "shipTo_ErrorClass": "form-control"})
      }
    }
    // else if (!lineHaul) {
    //   terminalState = {  "shipTo": "", "shipFrom": "", "shipFromDetails": {}, "shipToDetails": {}, "lineHaul": false, "terminalFrom": "0", "terminalTo": "0", "fffFrom" : true, "fffTo": true }
    // }
    setQuoteShipping({ ...quoteShipping, ...terminalState });
    setShippingRequired({ ...shippingRequired, ...terminalStateReq })
  }
  // useEffect(() => {

  //     setDataFields(dataFieldValues);
  //     console.log("This is in use effect" + dataFields);
  // }, []);

  const setRoadRunnerTermDetailsList = (lineHaul) => {
    setRoadRunnerTermDetails(lineHaul);
  }

  const setLineHualTermDetailsList = (lineHaul) => {
    setLineHaulTermDetails(lineHaul);
  }

  const setfffTermDetailsList = (carriers) => {
    setfffTermDetails(carriers);
  }

  const setAccessorialDetailsList = (accessorial) => {
    setAccessorialDetails(accessorial);
  }


  const updateQuoteShippingState = async (data) => {
    // console.log("quoteShipping="+JSON.stringify(data));
    setQuoteShipping({ ...quoteShipping, ...data });
  }

  const updateQuoteShippingReqState = async (data) => {
    // console.log("quoteShippingReq="+JSON.stringify(data));
    setShippingRequired({ ...shippingRequired, ...data });
  }

  useEffect(() => {
    // console.log("tfshow="+quoteShipping.terminalFromShow+"::tf::"+quoteShipping.terminalFrom)
    // console.log("ttshow="+quoteShipping.terminalToShow+"::tt::"+quoteShipping.terminalTo)
    // setShippingRequired({...shippingRequired, ...{shipFrom:false}});
    if (quoteShipping.terminalFromShow && quoteShipping.terminalFrom !== '0') {
      setQuoteShipping({ ...quoteShipping, ...{ "shipCodeFromEntry": false } });
    } else {
      setQuoteShipping({ ...quoteShipping, ...{ "shipCodeFromEntry": true } });
    }
  }, [quoteShipping.terminalFromShow, quoteShipping.terminalFrom, quoteShipping.fffFrom])// eslint-disable-line

  useEffect(() => {
    //  console.log("ttshow="+quoteShipping.terminalToShow+"::tt::"+quoteShipping.terminalTo)
    //  console.log("tfshow="+quoteShipping.terminalFromShow+"::tf::"+quoteShipping.terminalFrom) 
    // setShippingRequired({...shippingRequired, ...{shipTo:false}});
    if (quoteShipping.terminalToShow && quoteShipping.terminalTo !== '0') {
      setQuoteShipping({ ...quoteShipping, ...{ "shipCodeToEntry": false } });
    } else {
      setQuoteShipping({ ...quoteShipping, ...{ "shipCodeToEntry": true } });
    }

  }, [quoteShipping.terminalToShow, quoteShipping.terminalTo, quoteShipping.fffTo])// eslint-disable-line

  const clearShipping = () => {
    setQuoteShipping(initialInputs);
    setShippingRequired(initialRequired);
}

  return {
    onChangeShip,
    onValidateShip,
    toggleTerminals,
    onSubmitShipDetails,
    updateQuoteShippingState,
    updateQuoteShippingReqState,
    setLineHualTermDetailsList,
    setRoadRunnerTermDetailsList,
    setfffTermDetailsList,
    setAccessorialDetailsList,
    clearShipping,
    quoteShipping,
    shippingRequired,
    accessorialDetails,
  }


}
export default DataShipping;