import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import TopBar from "./TopBar";

import $ from "jquery";
import { useEffect } from "react";
import ApprovedMotorCarrierMenu from "../services/approvedMotor/ApprovedMotorCarrierMenu";

const ApprovedMotorCarrierLayout = (props) => {

    useEffect(() => {
        //"use strict";

        var fullHeight = function() {
            if ($(window).width()>=990)
            $('#sidebar').removeClass('active');
        else
        $('#sidebar').addClass('active');
        
        $('.js-fullheight').css('height', $(window).height());
        $(window).resize(function(){
            $('.js-fullheight').css('height', $(window).height());
        });

    
        };
        fullHeight();
    
        $('#sidebarCollapse').on('click', function () {
          $('#sidebar').toggleClass('active');
      });
    }, [])
    

    return (
        <>
            {/* <TopBar /> */}
            {/* <Header /> */}
            <section className="mt-180 mb-4">
            <div className="container">
                <div className="wrapper d-flex align-items-stretch">
                    <ApprovedMotorCarrierMenu />  
                    {/* <!-- Page Content  --> */}
                    <div id="content" className="pl-5">
                        {props.children ? props.children : <Outlet />}
                    </div>
                    {/* <!-- Page Content  --> */}
                </div>
            </div>
            </section>
        </>
    );
}

export default ApprovedMotorCarrierLayout;