import React from "react";

const AddressList = (props) => {
    const handleMenuClick = (e) => {
        e.preventDefault();
        return false;
    }
    return (
           
                 <>
                 {((Object.keys(props.addr1).length === 0)?'':
					<div className="col-lg-4 col-md-6 order-md-1 addressSize">
							<div className="row shadow-lg p-3 mb-4 mr-3 bg-white rounded justify-content-between"  >
                            <div className="Labelbold15Blue">  {props.addr1.station_id} 
                                {/* <a href={handleMenuClick} onClick={(a)=>{props.showLocation(props.addr1)}} className="pl-3">  */}
                                <i className="fas fa-map-marker-alt bounce pl-3" onClick={(a)=>{props.showLocation(props.addr1)}}></i>
                                {/* </a> */}
                            </div>
                            <div className="mr-lg-0">  <span >Region:</span> <span className="Labelbold">{props.addr1.region} </span> </div>
                            <div className="col-md-12 px-md-0">
                             <label className="Labelbold">{props.addr1.station_name} </label><br/>
                            <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i> {props.addr1.address}<br/>{props.addr1.csz}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco"></i> {props.addr1.phone}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-fax covIco"></i> {props.addr1.fax}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-envelope covIco"></i> {props.addr1.email_address}</div>
                            Manager/Security Coordinator<br/>
                            {props.addr1.manager_name}<br/>
                            <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr1.emerg_phone}</div>
							</div>
                            </div>
					</div>
                 )}
                    {((Object.keys(props.addr2).length === 0)?'':
					<div className="col-lg-4 col-md-6 order-md-1 addressSize">
                        <div className="row shadow-lg p-3 mb-4 mr-3 bg-white rounded justify-content-between"  >
                            <div className="Labelbold15Blue">  {props.addr2.station_id} 
                                {/* <a href={handleMenuClick} onClick={(a)=>{props.showLocation(props.addr2)}} className="pl-3">  */}
                                <i className="fas fa-map-marker-alt bounce pl-3" onClick={(a)=>{props.showLocation(props.addr2)}} ></i>
                                {/* </a> */}
                            </div>
                            <div className="mr-lg-0">  <span >Region:</span> <span className="Labelbold">{props.addr2.region} </span> </div>
                            <div className="col-md-12 px-md-0">
                            <label className="Labelbold">{props.addr2.station_name} </label><br/>
                            <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i> {props.addr2.address}<br/>{props.addr2.csz}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco"></i> {props.addr2.phone}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-fax covIco"></i> {props.addr2.fax}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-envelope covIco"></i> {props.addr2.email_address}</div>
                            Manager/Security Coordinator<br/>
                            {props.addr2.manager_name}<br/>
                            <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr2.emerg_phone}</div>
							</div>
                            </div>
					</div>
                    )}
                   { ((Object.keys(props.addr3).length === 0)?'':
                    <div className="col-lg-4 col-md-6 order-md-1 addressSize">
                       <div className="row shadow-lg p-3 mb-4 mr-3 bg-white rounded justify-content-between"  >
                            <div className="Labelbold15Blue">  {props.addr3.station_id} 
                                {/* <a href={handleMenuClick} onClick={(a)=>{props.showLocation(props.addr3)}} className="pl-3">  */}
                                <i className="fas fa-map-marker-alt bounce pl-3" onClick={(a)=>{props.showLocation(props.addr3)}} ></i>
                                {/* </a> */}
                            </div>
                            <div className="mr-lg-0">  <span>Region:</span> <span  className="Labelbold">{props.addr3.region} </span> </div>
                            <div className="col-md-12 px-md-0">
                           <label className="Labelbold">{props.addr3.station_name} </label><br/>
                           <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i>  {props.addr3.address}<br/>{props.addr3.csz}</div>
                           <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco"></i> {props.addr3.phone}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-fax covIco"></i> {props.addr3.fax}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-envelope covIco"></i> {props.addr3.email_address}</div>
                            Manager/Security Coordinator<br/>
                            {props.addr3.manager_name}<br/>
                            <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr3.emerg_phone}</div>
							</div>
                            </div>
					</div>
                    )}
			    </>
	
    );
}

export default AddressList;