import React from "react";

const AddressList = (props) => {
  
    return (
           
                 <>
                 
                 {((Object.keys(props.addr1).length === 0)?'':
					<div className="col-lg-4 col-md-6 order-md-1 mb-4">
                          <label className="CompCode"> {props.addr1.station_id} </label><br/> 
                            {/* <div> {props.addr1.Region} </div> */}
                            <h6>{props.addr1.station_name}</h6>
                            <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i> {props.addr1.address}<br/>{props.addr1.csz}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco" ></i> {props.addr1.phone}</div>
                            <div className="mb-1 covAdd"><i className="fas fa-fax covIco" ></i> {props.addr1.fax}</div>
                            <div className="mb-2 covAdd"><i className="fas fa-envelope covIco" ></i> {props.addr1.email_address}</div>
                            <strong>Manager/Security Coordinator</strong><br/>
                        {props.addr1.manager_name}<br/>
                            <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr1.emerg_phone}</div>
							</div>
                 )}
                    {((Object.keys(props.addr2).length === 0)?'':
					<div className="col-lg-4 col-md-6 order-md-1 mb-4">
                        
                        <label className="CompCode"> {props.addr2.station_id} </label><br/> 
                        {/* <div> {props.addr2.Region} </div> */}
                        <h6>{props.addr2.station_name}</h6>
                        <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i> {props.addr2.address}<br/>{props.addr2.csz}</div>
                        <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco" ></i> {props.addr2.phone}</div>
                        <div className="mb-1 covAdd"><i className="fas fa-fax covIco" ></i> {props.addr2.fax}</div>
                        <div className="mb-2 covAdd"><i className="fas fa-envelope covIco" ></i> {props.addr2.email_address}</div>
                        <strong>Manager/Security Coordinator</strong><br/>
                        {props.addr2.manager_name}<br/>
                        <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr2.emerg_phone}</div>
						</div>
					
                    )}
                   { ((Object.keys(props.addr3).length === 0)?'':
                    <div className="col-lg-4 col-md-6 order-md-1 mb-4">
                         <label className="CompCode"> {props.addr3.station_id} </label><br/> 
                        {/* <div> {props.addr3.Region} </div> */}
                        <h6>{props.addr3.station_name}</h6>
                        <div className="mb-1 covAdd"><i className="fas fa-home covIco" ></i> {props.addr3.address}<br/>{props.addr3.csz}</div>
                        <div className="mb-1 covAdd"><i className="fas fa-phone-alt covIco" ></i> {props.addr3.phone}</div>
                        <div className="mb-1 covAdd"><i className="fas fa-fax covIco" ></i> {props.addr3.fax}</div>
                        <div className="mb-2 covAdd"><i className="fas fa-envelope covIco" ></i> {props.addr3.email_address}</div>
                        <strong>Manager/Security Coordinator</strong><br/>
                        {props.addr3.manager_name}<br/>
                        <div className="covAdd"><i className="fas fa-mobile-alt covIco bluTxt"></i> {props.addr3.emerg_phone}</div>
						</div>
					
                    )}
			    </>
	
    );
}

export default AddressList;