import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import OpenClosePortal from './OpenClose/OpenClosePortal';
import DataTariffManagement from './Data/DataTariffManagement';
import CreateUpdateTariff from './UpdateTariff/CreateUpdateTariff';
import RefreshTariff from './RefreshTariff/RefreshTariff';
import ViewUserAccess from './UserAccess/ViewUserAccess';
import TariffFiles from './ExportTariff/TariffFiles';
import ViewAllUsers from './AllUsers/ViewAllUsers';



const TariffManagement = () => {
 const {openCloseData,openCloseLoading,loadOpenClose} = DataTariffManagement();
  return (
    <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Manage Users Access To Tariff Update Portal. </h4>
                    </div>
                    <form>
                    <div className="col-md-8 px-0">
                        <div className="row mb-3">
                            <div className="col-md-12 ">
                                <li>How to add/remove user from accessing tariff update portal in "View All Users" tab. </li>
                                <li>Select a user in column "Select User".</li>
                                <li>Select Allow or Remove access option.</li>
                                <li>Click "Submit" button and you are done.</li>
                               
                            </div>
                        </div>
                    </div>
                </form>
                    <hr className="mb-3"/>
                    <div className="row mb-3 mt-3 tabsDiv">
                        <div className="col-md-12">
                            <Tabs defaultActiveKey="tab-viewAllUsers">
                                <Tab eventKey="tab-viewAllUsers" title="View All Users">
                                    <ViewAllUsers />
                                </Tab>
                                <Tab eventKey="tab-userAccess" title="View Users With Access">
                                    <ViewUserAccess />
                                </Tab>
                                <Tab eventKey="tab-openClosePortal" title="Open/Close Portal">
                                    <OpenClosePortal tariffData={openCloseData} restFunCall={loadOpenClose} loading={openCloseLoading} />
                                </Tab>
                                <Tab eventKey="tab-tariffFiles" title="Export Tariff Files">
                                    <TariffFiles />
                                </Tab>
                                <Tab eventKey="tab-createUpdateTariff" title="Create/Update Tariff Record">
                                    <CreateUpdateTariff />
                                </Tab>
                                <Tab eventKey="tab-refreshTariff" title="Refresh Tariff Working Tables">
                                    <RefreshTariff />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
  )
}

export default TariffManagement