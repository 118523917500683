import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { tariffRates } from '../../restAPI/TariffRatesAPI';
const DataTariff = () => {
    const header = {"termCode": "",
                    "manager": "",
                    "emergencyPhone": "",
                    "stationName": "",
                    "inv_add1": "",
                    "inv_add2": "",
                    "csz": "",
                    "inv_phone": "",
                    "inv_fax": "",
                    "wkdy_hours": "",
                    "sat_hours": "",
                    "sun_hours": ""};
    const expirationDate ={"expireDate": ""};

    const tariffRate = {"areato": "",
                        "RATEMIN": 0,
                        "RATE100": 0,
                        "RATE500": 0,
                        "RATE1000":0,
                        "RATE2000": 0,
                        "RATE3000": 0,
                        "RATE5000": 0,
                        "RATE10000": 0,
                        "RATE15000": 0,
                        "RATE20000": 0,
                        "RATECAP": null};
    const   surchargeRate = {
                            "Service_Description": "",
                            "Min": 0.0,
                            "Rate": 0.0,
                            "surtype": ""
                            };
    const specialRate = {
                            "AREATO": "",
                            "MON-FRI 0000-0759": 0.0,
                            "MON-FRI 0800-1700": 0.0,
                            "MON-FRI 1701-2359": 0.0,
                            "SATURDAY": 0.0,
                            "SUNDAY": 0.0,
                            "HOLIDAY": 0.0
                        };
    const airportTransferRate = {
                                "AREAFROM": "",
                                "MINISHIP": 0.0,
                                "MINAMOUNT": 0.0,
                                "WEIGHTBREAKVALUE": 0.0,
                                "WEIGHTAMOUNT": 0.0,
                                "CAPBREAKVALUE": 0.0,
                                "CAPAMOUNT": 0.0
                            };
    const citiesServed ={
                            "zip": "",
                            "city": "",
                            "state": "",
                            "zone": ""
                        };
    const notes = {
                        "tariff": "",
                        "special_delivery": "",
                        "accessorial": "",
                        "airport_transfer": "",
                        "linehaul": "",
                        "special_transfer": "",
                        "cities_served": "",
                    };
    const [headerData,setHeaderData] = useState(header);
    const [expirationData,setExpirationData] = useState(expirationDate);
    const [notesData,setNotesData] = useState(notes);
    const [tariffRateData,setTariffRateData] = useState([tariffRate]);
    const [surchargeRateData,setSurchargeRateData] = useState([surchargeRate]);
    const [specialRateData,setspecialRateData] = useState([specialRate]);
    const [citiesServedData,setCitiesServedData] = useState([citiesServed]);
    
    let userProfileData = useSelector((state) => state.usr)
    const  [filtersInput, setFiltersInput] = useState({
        airportcode : '',
        accountId : userProfileData.custCode,
        showTable: false
    });

    const [filtersReq,setFiltersReq] = useState({
        airportcode:    true,
        // airportcode_ErrorClass : 'form-control',
        airportcode_ErrorClass : 'text-danger d-none',
        // accountId:   true,
        // accountId_ErrorClass : 'form-control',
    });

    const [reportData, setReportData] = useState({});
    const [showDataLoading, setShowDataLoading] = useState(false);

    const [airportCodesList,setAirportCodesList] = useState([
        {value : "***", label : "***"}
    ]);

    const onSubmitSearch = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";
                var errState = null;
                if( errname==="airportcode_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'text-danger' };
                }else{
                    errState = {[key]: true,[errname]: _css+' is-invalid'};
                }
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        }else{
            setShowDataLoading(true);
            setFiltersInput({...filtersInput, showTable: false});
            loadReportData();
        }
    }

    const onValidateTariff = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if( errname==="airportcode_ErrorClass" ){
            if ( value ) {
              errState = { [name]: true, [errname]: 'text-danger' };
              status = true;
            }else{ 
              errState = { [name]: false, [errname]: 'text-danger d-none' };
              status = true;
            }
          }else{
            if(key === name && value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
            }else if(key === name && !value) {
                errState = {[name]: false,[errname]: _css+''};
                status=true;
            }
        }
        //   })
        if(status)
        setFiltersReq({...filtersReq, ...errState});
    }

    const loadReportData = (loadType) => {
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        setShowDataLoading(true);
        tariffRates(filtersInput.airportcode,filtersInput.accountId,userProfileData.userid).then(responseData =>{
            let _reportData = [];
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
    
                  } else {
             _reportData = {
                header : responseData.header[0],
                expirationDate : responseData.expirationDate[0],
                tariffRate : responseData.tariffRate,
                surchargeRate : responseData.surchargeRate,
                specialRate : responseData.specialRate,
                airportTransferRate : responseData.airportTransferRate,
                notes : responseData.notes[0],
                citiesServed : responseData.citiesServed,
            };
            // console.log(JSON.stringify(responseData.tariffRate))
        }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            setReportData(_reportData);
        })
        .catch(err=>{
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: false});
            setReportData([]);
        })
    }

    const onChangeTariff = (name, value) => {
       // console.log("selected valus name"+name+"--"+value);
        setFiltersInput({...filtersInput, [name]: value});
    }

     useEffect(() => {
        const aircodeList = localStorage.getItem("airportCodeList");
        if (aircodeList && aircodeList !== 'undefined') {
            setAirportCodesList(JSON.parse(aircodeList));
        }    
        console.log("userEffet",filtersInput)
        //setFiltersInput({...filtersInput, accountId: userProfileData.userid});     
    //     setShowDataLoading(true);
    //     loadReportData("default");
     }, [])
    

    return {
        onChangeTariff,
        onSubmitSearch,
        onValidateTariff,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading,
        airportCodesList
    }
}

export default DataTariff