import React,{useEffect} from 'react'

const OurMission = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">OUR MISSION</h4>
                        </div>
						<div className="row">
							<div className="col-md-12 text-justify">

							<p> When you choose any of the Freight Force, Inc. approved motor carriers you get a proven cartage carrier backed by experienced industry leaders and a commitment to provide the absolute best service at competitive rates. </p>

                            <p> Since 1983 Freight Force, Inc.'s network of approved motor carriers has been providing excellent service to the Air Freight Industry. Our success is attributed to our commitment to the customer and continued expansions into new locations and service points. </p>

                            <p> It's our mission and pledge to establish lasting relationships with our customers by exceeding their expectations and gaining their trust through exceptional performance. </p>     

							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default OurMission