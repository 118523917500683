import React from 'react'
import { useState, useEffect } from 'react';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { buildInvoiceDetailsHtml } from './Data/DataInvoicesScripts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const InvoiceDetailsDatatable = (props) => {
    const [totalAmount, setTotalAmount] = useState(0);
    const [invTableHtml, setInvTableHtml] = useState([]);
    let pageTotal = 0, pageAllTotal = 0;
    useEffect(() => {
        // console.log("="+JSON.stringify(location.state.invoiceDetails))
        
        let table = $("#invoiceDetTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": -1,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : props.reportData,
            //state.invoiceDetails,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'Invoice',
                  title: 'Invoice Details',
                  titleAttr: 'Copy', 
                //   footer: true 
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'Invoice',
                  title: 'Invoice Details',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'Invoice',
                  title: 'Invoice Details',
                  titleAttr: 'CSV' ,
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'Invoice',
                  title: 'Invoice Details ',
                  titleAttr: 'PDF',
                  orientation: 'portrait',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [-1, 10, 20, 30, 50],
              ["All", 10, 20, 30, 50],
            ],
            "order": [],
            "columns": [
                { "data" : "BOL" },
                { "data" : "Airport" },
                { "data" : "OrderId" },
                { "data" : "DeliveryDate" },
                { "data" : "Weight" },
                { "data" : "Pieces" },
                { "data" : "DimWeight" },
                { "data" : "Units" },
                { "data" : "Rate" },
                { "data" : "Amount" }
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
                props.setInvTableHtml(buildInvoiceDetailsHtml(props.reportData, pageTotal, pageAllTotal));
             },
            // "footerCallback": function (row, data, start, end, display) {
            //     var api = this.api();
            //     // Remove the formatting to get integer data for summation
            //     var intVal = function (i) {
            //         return typeof i === 'string' ? i.replace(/[\$,]/g, '') * 1 : typeof i === 'number' ? i : 0;
            //     };
    
            //     pageTotal = api.column(9, { page: 'current' }).data().reduce(function (a, b) {
            //             return intVal(a) + intVal(b);
            //         }, 0);
            //     pageTotal = pageTotal.toFixed(2);

            //     pageAllTotal = api.column(9).data().reduce(function (a, b) {
            //         return intVal(a) + intVal(b);
            //     }, 0);
            //     pageAllTotal = pageAllTotal.toFixed(2);
                
            //     $(api.column(9).footer()).html( "$"+pageTotal ).removeClass('text-center');
            //     $(api.column(8).footer()).removeClass('text-center');
            //     // $(api.row(1).column(9).footer()).html( "$"+pageAllTotal.toFixed(2) ).removeClass('text-center');
            //     setTotalAmount(pageAllTotal);
            // }
    });
    }, [props.reportData])
    
    

  return (
        
                    <div id="datatable" >
                        <table id="invoiceDetTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                            <thead>
                                <tr>
                                    <th>BOL</th>
                                    <th>Airport</th>
                                    <th>Order</th>
                                    <th>Delivery Date</th>
                                    <th>Weight</th>
                                    <th>Pieces</th>
                                    <th>Dim Weight</th>
                                    <th>Units</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                            {/* <tfoot>
                                <tr>
                                    <th colSpan={9} className="text-right font-weight">HAWB Total</th>
                                    <th className="text-right font-weight"></th>
                                </tr>
                                <tr>
                                    <th colSpan={9} className="text-right">Invoice Total</th>
                                    <th className="text-right font-weight finBill">${totalAmount}</th>
                                </tr>
                            </tfoot> */}
                        </table>
                    </div>
              
  )
}

export default InvoiceDetailsDatatable