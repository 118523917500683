import React,{useEffect} from 'react'

const OurStory = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">OUR STORY</h4>
                        </div>
						<div className="row">
							<div className="col-md-12 text-justify">
							
                            <p> Founded in 1983 as a network of approved motor carriers, Freight Force Inc. provides full back office support including nationwide sales and marketing, billing, collections, data warehousing and software maintenance. </p>

                            <p> Freight Force, Inc. a property broker, offers the following services: Cartage and Truckloads through a network of independently owned and approved motor carriers domiciled at or near convenient airports locations. This network provides the logistics industry with the first and final mile for cartage needs. Freight Force, Inc. provides a single contact for all billing, collections and coordinates with the approved motor carriers to address customer needs and special projects. </p>

                            <p> Cartage Services: The Freight Force motor carrier network can meet the air forwarder and logistics communities’ needs for expedited pickup and delivery of less-than-truckload traffic in compliance with surface, air and TSA requirements. The applicable service terms and conditions for this unique service are set forth in the Cartage Service Conditions and Tariff Rates. </p>

                            <p> Truckload Services: To complement the cartage services provided by the network, Freight Force can arrange for truckload traffic pursuant to spot market rates established by a load confirmation agreement at the time of booking. These traditional truckload services are arranged subject to separate distinct truckload service conditions set forth herein and incorporated by reference at the time of booking. </p>

                            <p> For the logistics industry, Freight Force, Inc’s approved motor carrier network provides a “one stop” destination for nationwide cartage needs as well as providing a single point of contact for billing, collections and coordination with the Freight Force motor carrier network to address any special requirements or projects from customers. </p>
							
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default OurStory