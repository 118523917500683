import React from "react";
import InputElement from "../../formControls/InputElement";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerElement from "../../formControls/DatePickerElement";
import moment from "moment";
import SelectElement from "../../formControls/SelectElement";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

const QuoteShipping = (props) => {

    const noAction = () => {
        return false;
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-3 mb-3">
                    <label htmlFor="fffFrom" className="d-block">Origin Freight Force Facility</label>
                    {(!props.quoteShipping.terminalFromShow)
                    ?
                    <button className="btn btn-light active btn-disabled" type="button">  No  </button>
                    :
                    <>
                    { props.quoteShipping.disableTerminalFromSel
                        ?
                        <button className="btn btn-primary btn-disabled" type="button">  YES  </button>
                        :
                        <BootstrapSwitchButton
                            id="fffFrom" name="fffFrom"
                            checked={props.quoteShipping.fffFrom} disabled={false}
                            onlabel='Yes'
                            offlabel='No'
                            onChange={(checked) => {
                                props.onChangeShip(['fffFrom'],checked)
                            }}
                            onstyle='primary primary-text' 
                            offstyle='dark-gray'
                            width={115} 
                            height={38} 
                        />
                    }
                    </>
                    }
                    {/* disableTerminalFromSel={props.quoteShipping.disableTerminalFromSel+''} */}
                </div>
                <div className="col-md-3 mb-3" >
                    {/* {props.quoteShipping.terminalFromShow ? terminalFromToggle() : null} */}
                    <label htmlFor="TerminalFrom">Origin Locations</label>
                    <SelectElement id="terminalFrom" name="terminalFrom" value={props.quoteShipping.terminalFrom || ''} 
                        optionsData={props.terminalFromOptions} onChange={props.onChangeShip}
                        // disabled={!props.quoteShipping.terminalFromShow} />
                        placeholder={<div className='colorRed'>Select a Location ...</div>}
                        disabled={props.quoteShipping.fffFrom===false}
                        />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="fffTo" className="d-block">Destination Freight Force Facility</label>
                    {!props.quoteShipping.terminalToShow
                    ?
                    <button className="btn btn-light active btn-disabled" type="button">  No  </button>
                    :
                    <>
                    { props.quoteShipping.disableTerminalToSel
                        ?
                        <button className="btn btn-primary btn-disabled" type="button">  YES  </button>
                        :
                        <BootstrapSwitchButton
                            id="fffTo" name="fffTo"
                            checked={props.quoteShipping.fffTo}
                            disabled={false}
                            onlabel='  Yes  '
                            offlabel='  No  '
                            onChange={(checked) => {
                                props.onChangeShip(['fffTo'],checked)
                            }}
                            onstyle='primary primary-text' 
                            offstyle='dark-gray'
                            width={115}
                            height={38} 
                        />
                    }   
                    </>
                    }
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="TerminalTo">Destination Locations</label>
                    <SelectElement id="terminalTo" name="terminalTo" value={props.quoteShipping.terminalTo || ''} 
                        optionsData={props.terminalToOptions} onChange={props.onChangeShip}
                        // disabled={!props.quoteShipping.terminalToShow}  />
                        placeholder={<div className='colorRed'>Select a Location ...</div>}
                        disabled={props.quoteShipping.fffTo===false}
                        />

                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="shipFrom">Ship From</label>
                    <InputElement id="shipFrom" name="shipFrom" value={props.quoteShipping.shipFrom || ''} className={props.shippingRequired.shipFrom_ErrorClass}
                        onChange={props.onChangeShip} onInvalid={props.onValidateShip} validation="required"
                        readOnly={!props.quoteShipping.shipCodeFromEntry ||  props.quoteShipping.disableTerminalFromSel || props.quoteShipping.fffFrom}
                        placeholder='ZIP Code' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="pickInfo">Pickup Info</label>
                    <DatePickerElement id="pickupInfo" name="pickupInfo" onChange={props.onChangeShip} 
                        minDate={new Date()}
                        value={props.quoteShipping.pickupInfo}
                        selected={props.quoteShipping.pickupInfo}
                        className="form-control"
                       // onInvalid={props.onValidateShip}
                       // validation="required"
                        //className={props.shippingRequired.pickupInfo_ErrorClass}
                        maxDate={props.quoteShipping.deliveryInfo ?
                            moment(new Date(props.quoteShipping.deliveryInfo)).toDate()
                            : moment(new Date()).add(6, 'month').toDate()} />
                    <div className="invalid-feedback">
                        required.
                    </div>
                    {/* <div className="custom-control custom-checkbox text-right margintop1">
                        <InputElement type="checkbox" className="custom-control-input" id="specialPickup" name="specialPickup"
                            checked={props.quoteShipping.specialPickup || ''} onChange={props.onChangeShip} />
                        <label className="custom-control-label" htmlFor="specialPickup">Special Pickup</label>
                    </div> */}
                </div>
                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="shipTo">Ship To</label>
                    <InputElement id="shipTo" name="shipTo" value={props.quoteShipping.shipTo || ''} className={props.shippingRequired.shipTo_ErrorClass}
                        onChange={props.onChangeShip} onInvalid={props.onValidateShip} validation="required"
                        readOnly={!props.quoteShipping.shipCodeToEntry || props.quoteShipping.disableTerminalToSel || props.quoteShipping.fffTo}
                        placeholder='ZIP Code' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="delInfo">Delivery Info</label>
                    <DatePickerElement id="deliveryInfo" name="deliveryInfo" onChange={props.onChangeShip} checkDateValidation={true}
                        //minDate={props.quoteShipping.pickupInfo} 
                        //minDate={props.quoteShipping.deliveryDateMin}
                        minDate={props.quoteShipping.pickupInfo ?
                            moment(new Date(props.quoteShipping.pickupInfo)).toDate()
                            : moment(new Date()).toDate()}
                        selected={props.quoteShipping.deliveryInfo}
                        value={props.quoteShipping.deliveryInfo}
                        //onInvalid={props.onValidateShip}
                        //validation="required"
                        //className={props.shippingRequired.deliveryInfo_ErrorClass} 
                        className="form-control"
                        maxDate={moment(new Date()).add(6, 'month').toDate()} />
                    <div className="invalid-feedback">
                        required.
                    </div>
                    {/* <div className="custom-control custom-checkbox text-right margintop1">
                        <InputElement type="checkbox" className="custom-control-input" id="specialDelivery" name="specialDelivery"
                            checked={props.quoteShipping.specialDelivery || ''} onChange={props.onChangeShip} />
                        <label className="custom-control-label" htmlFor="specialDelivery">Special Delivery</label>
                    </div> */}
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="recoverCarrier">Recover from Carrier</label>
                    <InputElement id="recoverCarrier" name="recoverCarrier" value={props.quoteShipping.recoverCarrier || ''} className={props.shippingRequired.recoverCarrier_ErrorClass}
                        onChange={props.onChangeShip} onInvalid={props.onValidateShip} validation="required"
                        
                        placeholder='Recover from Carrier' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-3 mb-3 mb-lg-0"></div>
                <div className="col-md-3 mb-3 mb-lg-0">
                    <label htmlFor="dropToCarrier">Drop to Carrier</label>
                    <InputElement id="dropToCarrier" name="dropToCarrier" value={props.quoteShipping.dropToCarrier || ''} className={props.shippingRequired.dropToCarrier_ErrorClass}
                        onChange={props.onChangeShip} onInvalid={props.onValidateShip} validation="required"
    
                        placeholder='Drop to Carrier' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                
            </div> */}
            <div className="row">


            </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quoteShipping)}
            <hr/> {JSON.stringify(props.quoteShipping.lineHaul)}
            </div>
            <hr/>
            <div style={{ marginTop: 20 }}>{JSON.stringify(props.shippingRequired)}</div> */}
        </div>

    );
}
export default QuoteShipping;