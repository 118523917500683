import React, { useEffect } from "react";
import { useState } from "react";

import DataSales from '../../corporateCenter/salesSop/Data/DataSales'
import SalesSopData from "./SalesSopData";
import { updateCarrierSOP, newCarrierSOP } from '../../restAPI/CorporateCenterLinkRest'
// import PageNavigation from './PageNavigation'
import { Card, Row, Col, Container } from "react-bootstrap";
import ReactPaginate from 'react-paginate';


const SalesSop = () => {
    const { sopData, sopReq, sopList, pageCount, updatePageCount, loadDataTable, handleInputChange, updateSOPObject, updateSOPList,updateSOPListByIndex, createNewSOP, onValidateSop, onSubmitSopDetails, clearFormData, clearFormCss } = DataSales();
    const [editSOP, setEditSOP] = useState(false);
    const [newSOP, setNewSOP] = useState(false);
    const [sopStatus, setSopStatus] = useState(false);
    const [sopErrorMessage, setSopErrorMessage] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(0);

    const activeCls = [];
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const changeEdit = () => {
        // console.log("edit Called");
        setSopStatus(false);
        setSopErrorMessage(undefined);
        setEditSOP(true);
        clearFormCss();
    }
    const updateSOPChanges = () => {
        var isValidationReqSop = onSubmitSopDetails();

        if (!isValidationReqSop) {
            setEditSOP(false);
            let currentPageTem= currentPage;
            setNewSOP(false);
            updateCarrierSOP(sopData)
                .then(responseData => {
                    if (responseData instanceof Error) {
                        setSopStatus(true);
                        setSopErrorMessage("Error In Updating the Changes");
                        // console.log(" Error In Updating the Changes " + JSON.stringify(responseData))
                        clearFormData();
                    } else {
                        setSopErrorMessage("Record updated successfully");
                        //console.log(sopData,"sopData,currentPageTem",currentPageTem);
                        updateSOPListByIndex(sopData,currentPageTem);
                        // console.log("Record updated successfully");
                    }
                    clearFormCss();
                })
                setTimeout(function (){
                    setSopErrorMessage(undefined);
                },5000)
        }
    }
    const saveSOP = () => {
        var isValidationReqSop = onSubmitSopDetails();

        if (!isValidationReqSop) {
            setEditSOP(false);
            setNewSOP(false);
            newCarrierSOP(sopData)
                .then(responseData => {
                    if (responseData instanceof Error) {
                        setSopStatus(true);
                        setSopErrorMessage("Error In Creating New SOP");
                        // console.log(" Error In Creating New SOP " + JSON.stringify(responseData))
                        clearFormData();
                    } else {
                        setSopErrorMessage("Record created successfully");
                        loadDataTable('add');
                        // console.log("Record created successfully");
                    }
                    clearFormCss();
                })
                setTimeout(function (){
                    setSopErrorMessage(undefined);
                },5000)
        }
    }
    // const handleMenuClick = (e) => {
    //     e.preventDefault();
    //     return false;
    // }

    const createSOP = () => {
        setSopStatus(false);
        setSopErrorMessage(undefined);
        createNewSOP();
        setNewSOP(true);
        clearFormData();
        clearFormCss();

    }

    const cancelEditSOP = () => {
        setSopStatus(false);
        setSopErrorMessage(undefined);
        setEditSOP(false);
        updateSOPObject(sopList[currentPage]);
        clearFormCss();
    }

    const cancelNewSOP = () => {
        setSopStatus(false);
        setSopErrorMessage(undefined);
        // createNewSOP();
        setNewSOP(false);
        clearFormData();
        clearFormCss();
        updateSOPObject(sopList[currentPage]);
    }

    // const updateSOPForLI = event => {
    //     updateSOPObject(sopList[event.currentTarget.id]);
    //     setEditSOP(false);
    //     setNewSOP(false);
    //     setSopStatus(false);
    //     setSopErrorMessage(undefined);
    // };

    // const numberOfpages = () => {
    //     let pageList = [];
    //     for (var i = 0; i < sopList.length; i++) {

    //         pageList.push(<li className='page-item' key={i} id={i} onClick={updateSOPForLI} ><a className="page-link" href={handleMenuClick}>{i + 1}</a></li>);
    //     }
    //     return pageList;

    // }

    const [offset, setOffset] = useState(0);
    const [perPage] = useState(1);

    

    useEffect(() => {
        const fetchData = async () => {
            const nbaData = await sopList;
            updatePageCount(Math.ceil(nbaData.length / perPage))
        }
        fetchData()
    }, [offset])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setCurrentPage(selectedPage)
        // console.log(selectedPage)
        updateSOPObject(sopList[selectedPage]);
        setOffset((selectedPage) * perPage)
    }
    
    return (

        <div className="card card-custom">
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase">Freight Force Customer SOP</h4>
            </div>
            {sopErrorMessage ?
                <div className={sopStatus ? 'alert alert-danger text-center' : 'alert alert-success text-center'}>
                    <h6>{sopErrorMessage} </h6>
                </div> : ''}
            <SalesSopData sop={sopData} sopReq={sopReq} onChangeSop={handleInputChange} editSOP={editSOP} newSOP={newSOP} onValidateSop={onValidateSop} />
            <div className="row mt-3" >
                {!newSOP ? !editSOP ?
                    <div className="col-lg-3 col-md-4 mb-3">
                        <button className="btn btn-primary btn-block pull-right" onClick={changeEdit} type="button" >
                            Edit SOP &nbsp;<i className="fas fa-edit"></i>  </button>
                    </div>
                    : <>
                            <div className="col-lg-3 col-md-4 mb-3">
                                <button className="btn btn-primary btn-block pull-right" onClick={updateSOPChanges} type="button" >
                                    Update SOP &nbsp;<i className="fas fa-edit"></i>  </button>
                            </div>
                            <div className="col-lg-3 col-md-4 mb-3">
                                <button className="btn btn-secondary btn-secHeight btn-block pull-right" onClick={cancelEditSOP} type="button">
                                    CANCEL &nbsp;<i className="fas fa-times"></i>  </button>
                            </div>
                            </>: ''}
                {!editSOP ? !newSOP ?
                    <div className="col-lg-3 col-md-4 mb-3">
                        <button className="btn btn-primary btn-block pull-right" onClick={createSOP} type="button">
                            NEW SOP &nbsp;<i className="fas fa-edit"></i>  </button>
                    </div>
                    : <><div className="col-lg-3 col-md-4 mb-3">
                        <button className="btn btn-primary btn-block pull-right" onClick={saveSOP} type="button">
                            Save SOP &nbsp;<i className="fas fa-edit"></i>  </button>
                            </div><div className="col-lg-3 col-md-4 mb-3">
                        <button className="btn btn-secondary btn-secHeight btn-block pull-right" onClick={cancelNewSOP} type="button">
                            CANCEL &nbsp;<i className="fas fa-times"></i>  </button>
                            </div>
                     </>: ''}

                    {!editSOP ? !newSOP ?
                <div className="col-lg-6 col-md-6 ">
                    <div className="row justify-content-end">
                    <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        forcePage ={currentPage}
                        activeClassName={"active"} />
                        </div>
                </div>
                : '' : '' }
            </div>
        </div>


    );
}

export default SalesSop;