import {  useState } from 'react'

const DataTariffNew = () => {
  
    const  [initData] = [{
        area : '',
        newMin : '',
        newLbs100 : '',
        newLbs500: '',
        newLbs1000: '',
        newLbs2000: '',
        newLbs3000: '',
        newLbs5000: '',
        newLbs10000: '',
        newLbs15000: '',
        newLbs20000: '',
        newCapRate: ''
    }];
    const [initDataReq] = [{
        area_ErrorClass : 'form-control',
        newMin_ErrorClass : 'form-control',
        newLbs100_ErrorClass : 'form-control',
        newLbs500_ErrorClass: 'form-control',
        newLbs1000_ErrorClass: 'form-control',
        newLbs2000_ErrorClass: 'form-control',
        newLbs3000_ErrorClass: 'form-control',
        newLbs5000_ErrorClass: 'form-control',
        newLbs10000_ErrorClass: 'form-control',
        newLbs15000_ErrorClass: 'form-control',
        newLbs20000_ErrorClass: 'form-control',
        newCapRate_ErrorClass: 'form-control',
        area : true,
        newMin : true,
        newLbs100 : true,
        newLbs500: true,
        newLbs1000: true,
        newLbs2000: true,
        newLbs3000: true,
        newLbs5000: true,
        newLbs10000: true,
        newLbs15000: true,
        newLbs20000: true,
        newCapRate: true
    }];

    const [formData, setFormData] = useState(initData);

    const [formDataReq, setFormDataReq] = useState(initDataReq);

    const [showSaveDataLoading, setSaveShowDataLoading] = useState(false);

    const onSubmitNewTariff = () => {
        const copy = {...formData};
        const errCopy = {...formDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
            setFormDataReq({...formDataReq,...errObject});
            validationReqFlag = true;
        } 
        return validationReqFlag;
            
    }

    const onValidateNewTariff = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if(key === name && value) {
            errState = {[name]: true,[errname]: _css+' is-invalid'};
            status=true;
        }else if(key === name && !value) {
            errState = {[name]: false,[errname]: _css+''};
            status=true;
        }
        if(status)
            setFormDataReq({...formDataReq, ...errState});
      }

    const handleInputChange = (name, value) => {
        setFormData({...formData, [name]: value});
    }

    const clearNewTariffForm = () => {
        setFormData(initData);
        setFormDataReq(initDataReq);
    }

    const setFormDataObj = (_obj) => {
        //console.log("fd",formData)
        let res_obj = { ...formData, ..._obj}
        setFormData(res_obj);
        //console.log("res",res_obj)
    }

    return {
        formData,
        formDataReq,
        handleInputChange,
        onSubmitNewTariff,
        onValidateNewTariff,
        clearNewTariffForm,
        setFormDataObj
    }
}

export default DataTariffNew;