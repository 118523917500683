import React,{useEffect} from 'react'

const RetailDistribution = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">RETAIL DISTRIBUTION</h4>
                        </div>
						<div className="row">
							<div className="col-md-12 text-justify">
							    <h6>Freight Force is a proven commodity for everything related to retail distributions.</h6>
								<ul className="regList">
                                    <li>Before and After hour deliveries  </li>
                                    <li>Weekend deliveries </li>
                                    <li>Multi person deliveries </li>
                                    <li>Kiosk and End Cap deliveries </li>
                                    <li>Point of Sale product swap outs  </li>
                                    <li>Local area inbound shipment consolidations for final mile store refresh deliveries </li>
                                    <li>Light Assembly </li>
                                    <li>Debris removal </li>
                                    <li>Managed transportation by our Freight Force retail distribution consultants.  </li>
                                    <li>Distribution spreadsheets can be sent to one email address : <a href="mailto:sales@freightforce.com" target="_blank">sales@freightforce.com</a> for immediate pricing response for the entire Freight Force network.  </li>
                                </ul>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default RetailDistribution