import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { HashLoader } from 'react-spinners';
import InputElement from "../../../../formControls/InputElementMsg";
import DataAirportTransferEdit from '../Data/DataAirportTransferEdit';
import { useEffect } from 'react';
const AirportTransferEditModel = (props) => {
    const { formEditData, formEditDataReq, handleEditInputChange,  onSubmitEdit, onValidateEdit, clearEditForm, setFormEditDataObj } = DataAirportTransferEdit();

    useEffect(() => {
        // console.log("in use")
        clearEditForm();
        setFormEditDataObj(props.editData);
        //setFormEditDataObj(prevData => ([...prevData, ...props.editData])); // if use state is not effected use this (trail and error)
      },[props.editData])
      
      const handleEdit = () => {
        //  console.log("fd1",formEditData);
         var isValidationReqEdit = onSubmitEdit();
         //console.log("new acc Record edit:"+JSON.stringify(formEditData))
             
         if (!isValidationReqEdit) {
            props.updateRecord(formEditData);
         }
      }

      const closeEdit = () => {
        clearEditForm();
        props.handleClose();
      }

    return (

        <>
            <Modal show={props.show} onHide={closeEdit}
                size="lg" // for large modal box
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"} // not to close popup when  mouse clicked outside of popup
                keyboard={false} // not to close popup on escape key press
            >
                {props.innerLoading &&
                    <div className="modalLoadingOverlay">
                        <div className="modalLoadingSpinner">
                            <HashLoader color={'#1E73BE'} loading={props.innerLoading} size={35} />
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    <Modal.Title>Edit Airport Transfer For Station {props?.editData?.zone} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>{JSON.stringify(formEditData)}</div> 
                    <div>{JSON.stringify(formEditDataReq)}</div> */}
                    <Form id="specialEdit">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Min Amount : &emsp;&emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_0000_0759}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Rate &emsp; : &emsp;&emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_0800_1700}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Cap Amount : &emsp;&emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_1701_2359}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <label> New Min Amount : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_min_amt" name="new_min_amt" className={formEditDataReq.new_min_amt_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEdit}
                                    placeholder='New Min Amount ' />
                            </div>
                            <div className="col-md-2">
                                <label>New Rate :</label>
                            </div>
                            <div className="col-md-2">
                                    <InputElement id="new_rate" name="new_rate" className={formEditDataReq.new_rate_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEdit}
                                    placeholder='New Rate' />
                            </div>
                            <div className="col-md-2">
                                <label> New Cap Rate : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_cap_amt" name="new_cap_amt" className={formEditDataReq.new_cap_amt_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEdit}
                                    placeholder='New Cap Rate' />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={closeEdit}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AirportTransferEditModel