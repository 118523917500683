import * as actionTypes from '../actions/actionTypes'
import {updateIntialState} from '../utility'
//import { createReducer } from '@reduxjs/toolkit'

const initialState = [];

const reducer = //createReducer(initialState, { UPDATE_ITEM: 
    ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.COVERAGELIST:
            return updateIntialState(action.coverageList);
        // case actionTypes.REMOVEDIMENSIONS:
        //     return updateIntialState(initialState);
        default:
            return state
    }
};
//})


export default reducer;