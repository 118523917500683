import React from 'react';



const RequestNotFound = () => {
    return (
        <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">YOU ARE LOGGED OUT. PLEASE LOG IN TO USE THIS FEATURE - Request Not Found</h4>
                        </div>
							
					</div>
                    </div>
                </div>
            </div>
    </section>
      
    );
};

export default RequestNotFound;