import React from 'react'
import { useState } from 'react';
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import {historicalFsc} from '../services/restAPI/UtilsRest'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const FuelSurchargeHistorical = () => {
    const [showDataLoading, setShowDataLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        setShowDataLoading(true);
        loadReportData();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [])

    
    const loadReportData = () => {
        
        historicalFsc().then(responseData=>{
            if (responseData instanceof Error) {
                // console.log("error objecct is " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: quoteReqRes.message });
    
              } else {
            setReportData([...responseData]);
            setShowDataLoading(false);
            setShowTable(true);
              }
        })
                
                
          

    }
    
    useEffect(() => {
        let table = $("#fshTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : reportData,
            "dom": 
                "<'row'<'col-sm-3'l> <'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'FuelSurcharge',
                  title: 'Fuel Surcharge',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'FuelSurcharge',
                  title: 'Fuel Surcharge',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'FuelSurcharge',
                  title: 'Fuel Surcharge',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'FuelSurcharge',
                  title: 'Fuel Surcharge',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                { "data" : "station" },
                { "data" : "week1" },
                { "data" : "week2" },
                { "data" : "week3" },
                { "data" : "week4" },
                { "data" : "week5" }
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[reportData]);

  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="mb-2 d-flex justify-content-between">
                            <div>
                            <h4 className="pageHead text-uppercase ">HISTORICAL FSC   </h4>
                            </div>
                            <div className="mr-lg-0"> 
                                <h6>
                                <NavLink to="/fuelSurcharge" title="Historical FSC" className="btn btn-outline-primary btn-sm pull-right" ><i className="fas fa-arrow-alt-circle-left"></i> BACK</NavLink>
                                </h6>
                            </div>
                        </div>
                        
						<div className="row">
							<div className="col-md-12 text-justify">
                            {
                                showDataLoading && 
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-center"> 
                                        <HashLoader  color={'#1E73BE'} loading={showDataLoading} />
                                    </div>
                                </div>
                            }
                            {
                                showTable &&  
                                <div id="datatable">
                                <table id="fshTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                                    <thead>
                                        <tr>
                                            <th>STATION</th>
                                            <th>WEEK 1</th>
                                            <th>WEEK 2</th>
                                            <th>WEEK 3</th>
                                            <th>WEEK 4</th>
                                            <th>WEEK 5</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                                </div>
                            }			
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default FuelSurchargeHistorical 