import axios from "axios";
import moment from "moment";

export const processLinkCustomerAccountReq = (caInputs) => {

    const postData = buildCustomerAccountReq(caInputs);

    var resp = [];
    var _url = process.env.REACT_APP_CC_LINK_CUST_ACC_SAVE_URL;
    return axios.post(_url, postData)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
            resp = { status: "success", "responseData": resData }
            return resp;
        })
        .catch(error => {
            // console.log("Error in calling api/Quote"+error.response);
            // shall be replaced after valid RESTAPI response. temporarily adding req as resp
            // resp = { status: "fail", "responseData": testRespData(postData) }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/LinCustomer ");
        })
}

export const buildCustomerAccountReq = (caInputs) => {
    var req = {
        "accountType": caInputs.accountType,
        "from": caInputs.from,
        "to": caInputs.to 
    }

    // console.log("final req="+JSON.stringify(req));
    return req;
}

export const testRespData = (reqData) => {
    var resp = { ...reqData, ["bookingNumber"]: "FFQB1234" };
    return resp;
}

export const processMotorCarriersReq = (mcInputs) => {

    const postData = buildMotorCarriersReq(mcInputs);

    var resp = [];
    var _url = process.env.REACT_APP_CC_LINK_MOTOR_CARR_SAVE_URL;
    return axios.post(_url, postData)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
            resp = { status: "success", "responseData": resData }
            return resp;
        })
        .catch(error => {
            // console.log("Error in calling api/Quote"+error.response);
            // shall be replaced after valid RESTAPI response. temporarily adding req as resp
            // resp = { status: "fail", "responseData": testRespData(postData) }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/LinCustomer ");
        })
}

export const buildMotorCarriersReq = (mcInputs) => {
    var req = {
        "accountType": mcInputs.accountType,
        "from": mcInputs.from,
        "to": mcInputs.to 
    }

    // console.log("final req="+JSON.stringify(req));
    return req;
}

export const testRespData1 = (reqData) => {
    var resp = { ...reqData, ["bookingNumber"]: "FFQB1234" };
    return resp;
}

export const invoiceSearch = (custcode,searchType,sDate,eDate,hawb,invoice) => {
    //need to work -- based on conditon send the parameter
    //?custcode=(integer)
    //searchType =(Stirng) I=Invoice, H=House Waybill, D= Date Reange
    //startDate =(String) 
    //endDate =(String)
    //hawb =(String) HouseAirWay bill
    //invoice = (String) List of HAWB, comma delimited
    hawb = hawb.trim();
    invoice = invoice.trim();

    var param = "?custcode="+custcode+"&searchType="+searchType
    if(searchType === "I" && invoice)
        param = param + "&invoice="+invoice
    else if(searchType === "H" && hawb)
        param = param + "&hawb="+hawb
    else if(searchType === "D" && sDate && eDate){
        let _sDate = sDate ? moment(new Date(sDate)).format('MM/DD/YYYY') : '';
        let _eDate = eDate ? moment(new Date(eDate)).format('MM/DD/YYYY') : '';
        param = param + "&startDate="+_sDate+"&endDate="+_eDate
    }
        

    //for testing add
    // param = "?custcode=1742&searchType=D&startDate=9/1/2022&endDate=9/30/2022" 
    // alert(param)
    return axios.get(process.env.REACT_APP_CC_INVOICES_DATA_URL+param)
        .then(response => {
          //  console.log("REACT_APP_CC_INVOICES_DATA_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CC_INVOICES_DATA_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Corporate Invoice Seach ");
        })
}

export const invoiceDetailsSearch = (custcode,invoiceNumber) => {
    
    var param = "?custcode="+custcode+"&invoiceNumber="+invoiceNumber
   
    return axios.get(process.env.REACT_APP_CC_INVOICES_DETAILS_URL+param)
        .then(response => {
          //  console.log("REACT_APP_CC_INVOICES_DETAILS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CC_INVOICES_DETAILS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Corporate invoiceDetailsSearch ");
        })
}




export const carrierSOP = () => {
    return axios.get(process.env.REACT_APP_CC_CARRIER_SOP_URL)
        .then(response => {
           // console.log("REACT_APP_CC_CARRIER_SOP_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CC_CARRIER_SOP_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Corporate carrierSOP get ");
        })
}


export const updateCarrierSOP = (sopObject) => {
    return axios.put(process.env.REACT_APP_CC_CARRIER_SOP_URL,sopObject)
        .then(response => {
           // console.log("REACT_APP_CC_CARRIER_SOP_URL update response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api Update put method REACT_APP_CC_CARRIER_SOP_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Update Corporate carrierSOP put ");
        })
}

export const newCarrierSOP = (sopObject) => {
    sopObject.DepartmentNumber = parseInt(sopObject.DepartmentNumber);
    return axios.post(process.env.REACT_APP_CC_CARRIER_SOP_URL,sopObject)
        .then(response => {
           // console.log("REACT_APP_CC_CARRIER_SOP_URL New response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api New post method REACT_APP_CC_CARRIER_SOP_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of New Corporate carrierSOP Post ");
        })
}

export const customerUsers = (userId,custCode) => {
    var param = "?userId="+userId+"&custCode="+custCode;
    return axios.get(process.env.REACT_APP_CUSTOMER_USERS_URL+param)
        .then(response => {
           // console.log("REACT_APP_CUSTOMER_USERS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CUSTOMER_USERS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of customerUsers get ");
        })
}

export const carrierUsers = (userId) => {
    var param = "?userId="+userId;
    return axios.get(process.env.REACT_APP_CARRIER_USERS_URL+param)
        .then(response => {
           // console.log("REACT_APP_CARRIER_USERS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CARRIER_USERS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of carrierUsers get ");
        })
}

export const corporateUsers = () => {
    return axios.get(process.env.REACT_APP_CORPORATE_USERS_URL)
        .then(response => {
           // console.log("REACT_APP_CORPORATE_USERS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CORPORATE_USERS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of corporateUsers get ");
        })
}

export const adminUsers = () => {
    return axios.get(process.env.REACT_APP_ADMIN_USERS_URL)
        .then(response => {
           // console.log("REACT_APP_ADMIN_USERS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_ADMIN_USERS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of adminUsers get ");
        })
}

export const accountInfo = (userId) => {
    let param = "?uid="+userId;
    return axios.get(process.env.REACT_APP_ACCOUNT_INFO_URL+param)
        .then(response => {
           // console.log("REACT_APP_ACCOUNT_INFO_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_ACCOUNT_INFO_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of accountInfo get ");
        })
}

export const getLinkCustomer = () => {
    return axios.get(process.env.REACT_APP_CC_LINK_CUST_ACC_URL)
        .then(response => {
           // console.log("REACT_APP_CC_LINK_CUST_ACC_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CC_LINK_CUST_ACC_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of getLinkCustomer get ");
        })
}
export const getLinkCarrier = () => {
    return axios.get(process.env.REACT_APP_CC_LINK_CARRIER_URL)
        .then(response => {
           // console.log("REACT_APP_CC_LINK_CARRIER_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_CC_LINK_CARRIER_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of getLinkCarrier get ");
        })
}

export const addLinkCustomer = (parentCode,childcode) => {
    //sopObject.DepartmentNumber = parseInt(sopObject.DepartmentNumber);
    var adlinkCust = { "parent":parentCode, "child":childcode}
    // let _url = process.env.REACT_APP_CC_ADD_LINK_CUST_URL + "?parentCode="+parentCode+"&childcode="+childcode;
    return axios.post(process.env.REACT_APP_CC_ADD_LINK_CUST_URL, adlinkCust) 
        //process.env.REACT_APP_CC_ADD_LINK_CUST_URL,adlinkCust)
        .then(response => {
           // console.log("REACT_APP_CC_ADD_LINK_CUST_URL New response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api New post method REACT_APP_CC_ADD_LINK_CUST_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of addLinkCustomer Post ");
        })
}

export const addLinkCarrier = (parentTerminal,childTerminal) => {
    //sopObject.DepartmentNumber = parseInt(sopObject.DepartmentNumber);
    var adlinkCust = { "parent":parentTerminal, "child":childTerminal}
    return axios.post(process.env.REACT_APP_CC_ADD_LINK_CARRIER_URL,adlinkCust)
        .then(response => {
           // console.log("REACT_APP_CC_ADD_LINK_CARRIER_URL New response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api New post method REACT_APP_CC_ADD_LINK_CARRIER_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of addLinkCarrier Post ");
        })
}

export const removeLinkCustomer = (parentCode,childcode) => {
    //sopObject.DepartmentNumber = parseInt(sopObject.DepartmentNumber);
    var rmlinkCust = {data : { "parent":parentCode, "child":childcode} }
    return axios.delete(process.env.REACT_APP_CC_REMOVE_LINK_CUST_URL, rmlinkCust)
        .then(response => {
           // console.log("REACT_APP_CC_ADD_LINK_CUST_URL New response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api New post method REACT_APP_CC_ADD_LINK_CUST_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of removeLinkCustomer Post ");
        })
}

export const removeLinkCarrier = (parentTerminal,childTerminal) => {
    var rmlinkCust = {data : { "parent":parentTerminal, "child":childTerminal}}
    
    return axios.delete(process.env.REACT_APP_CC_REMOVE_LINK_CARRIER_URL, rmlinkCust)
        .then(response => {
           // console.log("REACT_APP_CC_ADD_LINK_CARRIER_URL New response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api New post method REACT_APP_CC_ADD_LINK_CARRIER_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of removeLinkCarrier Post ");
        })
}

export const processWebAccountReq = (inputs) => {
    var req = {
        "userList": inputs.selectedIds,
        "status": inputs.actionType
      }
    var _url = process.env.REACT_APP_UPDATE_USERS_URL;
    return axios.put(_url, req)
        .then(response => {
            //console.log("resData in s="+response.data);
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api/Quote"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/LinCustomer ");
        })
}

