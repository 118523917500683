import React, { useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import backImage from '../../assets/img/maintenance.png'
const Maintenance = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <section className="mt-180 mb-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 order-md-1">
                        <div className="card card-custom">
                            <div className="text-center">
                                <h4 className="pageHead"> We&rsquo;ll be back soon!</h4>
                            </div>

                            <Carousel>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={backImage}
                                        alt="Performing some maintenance at teh moement" />
                                    <Carousel.Caption style={{bottom: '370px'}}>
                                        <h4 className="maintenance">Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:itsupport@freightforce.com">contact us :</a> , otherwise we&rsquo;ll be back online shortly!
                                    <p>&mdash; The Freight Force Team</p></h4>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Maintenance;