import React,{useEffect} from "react";
import PDF from "../../../../assets/downloads/CorporateDirectory.pdf";
import { copyStyles,extractStyles } from "../../../utils/ValidationScripts";
const CorporateDirectory = () => {

    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const handlePrintClick = () => {
        const printableElements = document.getElementById('print-area').innerHTML;
        var w = window.open('', "_blank");
		let cssStyles = extractStyles(window.document);
		const orderHtml = `<html><head><title></title>` +cssStyles+ `</head><body>` + printableElements + `</body></html>`
	  	w.document.write(orderHtml);
        setTimeout(function() {
                w.window.print();
                w.window.focus();
                w.window.close();  
        }, 1000);

      };

    return (
        <div className="card card-custom">
        <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Corporate Directory</h4>

            <button className="btn btn-secondary btn-sm pull-right ml-auto mb-1 pl-3 pr-3" type="button"  onClick={handlePrintClick} title="Print">
            <i className='fa fa-print'></i></button> 
            {/* <button className="btn btn-secondary btn-sm pull-right ml-1 mb-1 pl-3 pr-3" type="button"  onClick={handleDownloadClick} title="Download">
            <i className='fa fa-download'></i></button>  */}
            
            <a href={PDF} target='_new' download='CorporateDirectory.pdf' title='Corporate Directory' 
            className="btn btn-secondary btn-sm pull-right ml-1 mb-1 pt-1 pl-3 pr-3">
            <i className='fa fa-download'></i></a>
        </div>
        <div  id='print-area'>
        {/* <iframe style={{ width: "100%", height: "800px" }} src={PDF} > </iframe> */}
        <table id="corpDirTbl" className="table table-striped">
            <thead>
                <tr className="mainHead">
                    <th colSpan="4" className="text-center">Freight Force, Inc. Corporate Directory</th>
                </tr>
                <tr className="subHead">
                    <th></th>
                    <th>Executives</th>
                    <th></th>
                    <th>Email</th>
                </tr>
            </thead>
    
            <tbody>
                <tr>
                    <td>CEO</td>
                    <td>Sue Beattie</td>
                    <td>40057</td>
                    <td><a href="mailto:sueb@freightforce.com" target="_blank">sueb@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>COO</td>
                    <td>Dennis Kuhlman</td>
                    <td>40060</td>
                    <td><a href="mailto:dennis.kuhlman@freightforce.com" target="_blank">dennis.kuhlman@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Director of Operations</td>
                    <td>Eric Carlson</td>
                    <td>40058</td>
                    <td><a href="mailto:ericc@freightforce.com" target="_blank">ericc@freightforce.com</a></td>
                </tr>
                </tbody>
            <thead>
          <tr className="subHead2">
                    <th colSpan="4" className="text-center">Sales & Marketing</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>West Coast Regional Sales Manager</td>
                    <td>Joanne Chavez</td>
                    <td>(818) 613-7645</td>
                    <td><a href="mailto:joannec@freightforce.com" target="_blank">joannec@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Midwest Regional Sales Manager</td>
                    <td>Keith Engelmann</td>
                    <td>(773) 491-3960</td>
                    <td><a href="mailto:keithe@freightforce.com" target="_blank">keithe@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Southeast Regional Sales Manager</td>
                    <td>Judy Taylor</td>
                    <td>(678) 340-6604</td>
                    <td><a href="mailto:judyt@freightforce.com" target="_blank">judyt@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Northeast Regional Sales Manager</td>
                    <td>Brian Maurer</td> 
                    <td>(908) 387-4960</td>
                    <td><a href="mailto:judyt@freightforce.com" target="_blank">brianM@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Inside Sales Administrator</td>
                    <td>Patrycja Jasinska</td>
                    <td>40061</td>
                    <td><a href="mailto:patrycjaj@freightforce.com" target="_blank">patrycjaj@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Inside Sales Supervisor</td>
                    <td>Justin Buby</td>
                    <td>(310) 953-7837</td>
                    <td><a href="mailto:justinb@freightforce.com" target="_blank">justinb@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Inside Sales Coordinator</td>
                    <td>Cheryl Rockwell</td>
                    <td>40063</td>
                    <td><a href="mailto:cherylr@freightforce.com" target="_blank">cherylr@freightforce.com</a></td>
                </tr>
                </tbody>
                <thead>
                <tr className="subHead2">
                    <th colSpan="4" className="text-center">Accounting Department</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Controller</td>
                    <td>Jessie O’Bryan</td>
                    <td>40064</td>
                    <td><a href="mailto:jessieo@freightforce.com" target="_blank">jessieo@freightforce.com</a></td>
                </tr>
                    <tr>
                    <td>Accounting Staff</td>
                    <td>Anna Dieu</td>
                    <td>40065</td>
                    <td><a href="mailto:annad@freightforce.com" target="_blank">annad@freightforce.com</a></td>
                </tr>
                </tbody>
                <thead>
                <tr className="subHead2">
                    <th colSpan="4" className="text-center">Collections Department</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Credit Collections Manager</td>
                    <td>DeeAnna Glenn</td>
                    <td>40066</td>
                    <td><a href="mailto:deeg@freightforce.com" target="_blank">deeg@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Credit Collections Supervisor</td>
                    <td>Sheila Laberinto</td>
                    <td>40069</td>
                    <td><a href="mailto:sheilal@freightforce.com" target="_blank">sheilal@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Collections</td>
                    <td>Sonnie Phila</td>
                    <td>40067</td>
                    <td><a href="mailto:sonniep@freightforce.com" target="_blank">sonniep@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Collections</td>
                    <td>Marina Malacara</td>
                    <td>40068</td>
                    <td><a href="mailto:marinam@freightforce.com" target="_blank">marinam@freightforce.com</a></td>
                </tr> 
                <tr>
                    <td>Collections</td>
                    <td>Jamie Nandino</td>
                    <td>40070</td>
                    <td><a href="mailto:jamien@freightforce.com" target="_blank">jamien@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Collections</td>
                    <td>Christine Rodriguez</td>
                    <td>40071</td>
                    <td><a href="mailto: christiner@freightforce.com" target="_blank"> christiner@freightforce.com</a></td>
                </tr>
                <tr>
                <td>Collections</td>
                    <td>Andrea Jimenez</td>
                    <td>40072</td>
                    <td><a href="mailto:andreaj@freightforce.com" target="_blank">andreaj@freightforce.com</a></td>
                </tr>
                </tbody>
                <thead>
                <tr className="subHead2">
                    <th colSpan="4" className="text-center">Billing Department</th>
                </tr>
            </thead>
             <tbody>						   
                <tr>
                    <td>Billing Manager</td>
                    <td>My Vu</td>
                    <td>40073</td>
                    <td><a href="mailto:Myv@freightforce.com" target="_blank">Myv@freightforce.com</a></td>
                </tr>
                    <tr>	
                    <td>Billing Supervisor</td>
                    <td>Yolandah Ekastanti</td>
                    <td>40074</td>
                    <td><a href="mailto:YolandahE@freightforce.com" target="_blank">YolandahE@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>AR Supervisor</td>
                    <td>Helen Dang</td>
                    <td>40081</td>
                    <td><a href="mailto:HelenD@freightforce.com" target="_blank">HelenD@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing/Claims</td>
                    <td>Priscilla Bui</td>
                    <td>40082</td>
                    <td><a href="mailto:Priscillab@freightforce.com" target="_blank">Priscillab@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing</td>
                    <td>Isabels Saavedra</td>
                    <td>40078</td>
                    <td><a href="mailto:Isabels@freightforce.com" target="_blank">Isabels@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing</td>
                    <td>Nancy Khai</td>
                    <td>40076</td>
                    <td><a href="mailto:Nancyk@freightforce.com" target="_blank">Nancyk@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing</td>
                    <td>Nayeli Zepeda</td>
                    <td>40086</td>
                    <td><a href="mailto:Nayeliz@freightforce.com" target="_blank">Nayeliz@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing</td>
                    <td>Nhi Nguyen</td>
                    <td>40080</td>
                    <td><a href="mailto:Nhin@freightforce.com" target="_blank">Nhin@freightforce.com</a></td>
                </tr>
                <tr>
                     <td>Billing</td>
                    <td>Thanh Nguyen</td>
                    <td>40077</td>
                    <td><a href="mailto:ThanhN@freightforce.com" target="_blank">ThanhN@freightforce.com</a></td>
                </tr>
                <tr>
                    <td>Billing</td>
                    <td>Tony Nguyen</td>
                    <td>40075</td>
                    <td><a href="mailto:Tonyn@freightforce.com" target="_blank">Tonyn@freightforce.com</a></td>
                </tr>
                
                </tbody>
                <thead>
                <tr className="subHead2">
                    <th colSpan="4" className="text-center">MIS/IT Department</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>IT Manager</td>
                    <td>Prajna Solanki</td>
                    <td>40083</td>
                    <td><a href="mailto:prajna.solanki@stgusa.com" target="_blank">prajna.solanki@stgusa.com</a></td>
                </tr>
                </tbody>
                <thead>
                <tr className="emerg">
                    <th colSpan="4" className="text-center">Emergency</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="4" >
                    <table className="emergTbl">
                        <tbody>
                        <tr>
                            <td className="blue">
                                <h6>Operations Cellphone</h6>
                                <h5>(714) 951-7294</h5>
                            </td>
                            <td className="red">
                                <h6>IT Emergency Cellphone</h6>
                                <h5>(714) 995-9300 x40083</h5>
                            </td>
                            <td><a href="mailto:itsupport@freightforce.com" target="_blank">itsupport@freightforce.com</a></td>
                        </tr>
                        </tbody>
                    </table>
                    </td>
                </tr>
                </tbody>
                <thead>
                <tr className="subHead2">
                    <th colSpan="4" className="text-center">Corporate Information</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="4" className="text-center corpInfo">
                    <h5>14445 Alondra Blvd. La Mirada, CA. 90638</h5>
                    <h5>P.O. Box 10580 La Mirada, CA. 90637-1058 </h5>
                    <h5>Tel: (714) 995-9300 ~ (Main) Fax: (714) 995-9330 ~ (Billing) Fax: (714) 995 -9340</h5>
                    </td>
                </tr>
            </tbody>
    </table>

        </div>
        </div>
    );
}

export default CorporateDirectory;