import { useEffect, useState } from 'react';
import {getNewsFeed,addNewsFeed,updateNewsFeed,deleteNewsFeed} from '../../../restAPI/NewsFeedAPI';

const DataNewsFeed = () => {
    const [filtersInput, setFiltersInput] = useState({
        Index:0,
        Id:0,
        textAreaId : '',
        showTextArea : false,
        showStatus : false,
        statusDesc : '',
        errorStatus:false,
        news :'',
    });

    const [filtersReq, setFiltersReq] = useState({
        newsFeedInput: true,
        newsFeedInput_ErrorClass: 'form-control newsfeedFocus'
    });

    const [reportData, setReportData] = useState([]);
    const [serverData, setServerData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [showDataLoading, setShowDataLoading] = useState(false);

    const mapResponseData = (responseData) =>{
      let list = [];
        responseData.map((x, i) => {
            var eachRow = {Index:i,Id:x.Id,Description:x.Description,showTextArea:false,textAreaId:"news_"+i};
            list.push(eachRow);
        })
    return list;
    }

    const mapResponseData1 = (responseData) =>{
        let list = [];
          responseData.map((x, i) => {
              var eachRow = {Index:i,Id:x.Id,Description:x.Description,showTextArea:false,textAreaId:"news_"+i,isAdditional:true};
              list.push(eachRow);
          })
      return list;
      }

    const loadReportData = () => {
        let desc1 ="West Coast I-5 Linehaul - Due to escalating operating costs on the West Coast all I-5 corridor linehaul shipments will be assessed a 5% per invoice surcharge effective September 1st 2021"
        let desc2 = "COVID-19 Update: \n NO CONTACT DELIVERIES - Please note until further notice, most Freight Force carriers will not require drivers to obtain shipper/consignee signatures as part of the No Contact Delivery protocol to maintain social distancing. Our carriers will obtain the name of the shipper/consignee and place on the BOL, and will take a photo of the freight placement where appropriate. The same will apply for Residential where most deliveries will be limited to curbside or garage placement. Thank you for your understanding in our effort to keep freight moving while limiting driver/consignee contact."
        let desc3 ="West Coast I-5 Linehaul - West Coast I-5 Corridor Service now available. You can now use Freight Force door to door from SAN, LAX, SFO, PDX, SEA and YVR up and down the West Coast. Contact sales@freightforce.com for additional information."
            getNewsFeed()
            .then(reponseData => {
                if (reponseData instanceof Error) {
                    const tempdata =[{Id:1,Description:desc1},{Id:2,Description:desc2},{Id:3,Description:desc3}]
                    setReportData([...mapResponseData(tempdata)]);
                } else {
                    // console.log(JSON.stringify(reponseData))
                    let modRespData = mapResponseData(reponseData);
                    let modRespData1 = mapResponseData1(reponseData);

                    setReportData([...modRespData]);
                    setServerData(modRespData1);

                }
                setShowDataLoading(false);
                setFiltersInput({ ...filtersInput, showTable: true });
            })
            .catch(err => {
                setReportData([]);
                setShowDataLoading(false);
                setFiltersInput({ ...filtersInput, showTable: true });
            })
    }

    const handleInputChange = (name, value) => {
        if(!String(name).includes("_")){
            setFiltersInput({ ...filtersInput, [name]: value });
        }else{
            setFiltersInput({ ...filtersInput, [name]: value });
            let indexVal =String(name).split("_");
            let found = reportData.find(element => element.Index === parseInt(indexVal[1]));
            // console.log(JSON.stringify(found))
            found.Description=value;
            reportData[indexVal[1]]=found;
            setReportData([...reportData]);
        }
        
    }

    useEffect(() => {
        setShowDataLoading(true);
        loadReportData();
    }, [])
    const editNews = (index) =>{
        let found = reportData.find(element => element.Index === index);
        found.showTextArea =true;
        reportData[index]=found;
        setReportData([...reportData]);
        setShowDataLoading(false);
    }
    const cancelNews = (index) => {
        let found = serverData.find(element => element.Index === index);
        // found.showTextArea =false;
        reportData[index]['Description']=found.Description;
        reportData[index]['showTextArea']=false;

        setReportData([...reportData]);
        setShowDataLoading(false);
    }
    const updateNews = (index) =>{
        let found = reportData.find(element => element.Index === index);
        found.showTextArea =false;
        reportData[index]=found;

        setLoading(true)
        updateNewsFeed(found.Id+'' ,found.Description).then(reponseData => {
            if (reponseData instanceof Error || reponseData==="FAILED") {
                setFiltersInput({ ...filtersInput, errorStatus: true,showStatus: true,"statusDesc" :"Failed to Update News Feed "});
                let found1 = serverData.find(element => element.Index === index);
                reportData[index]['Description']=found1.Description;
                reportData[index]['showTextArea']=found1.showTextArea;

                setReportData([...reportData]);
            } else if( reponseData==="SUCCESS!" ){
                setReportData([...reportData]);
                 setShowDataLoading(false);
                 setFiltersInput({ ...filtersInput, "news": '',errorStatus: false, showStatus: true,"statusDesc" :"News Feed Updated Successfully "});

                 serverData[index]['Description']=found.Description;
                 serverData[index]['showTextArea']=found.showTextArea;
                 setServerData(serverData);
            }else{
                setFiltersInput({ ...filtersInput, errorStatus: true,showStatus: true,"statusDesc" :"Failed to Update News Feed "});
                let found1 = serverData.find(element => element.Index === index);
                reportData[index]['Description']=found1.Description;
                reportData[index]['showTextArea']=found1.showTextArea;

                setReportData([...reportData]);
            }
            setShowDataLoading(false);
        })
        .catch(err => {
            setShowDataLoading(false);
        })
        .finally(r => {
            setLoading(false)
        });

        
    }
    const deleteNews = (index) =>{
        const found = reportData.find(element => element.Index === index);

        setLoading(true)
        deleteNewsFeed(found.Id+'' , found.Description).then(reponseData => {
            if (reponseData instanceof Error || reponseData==="FAILED") {
                setFiltersInput({ ...filtersInput, errorStatus: true, showStatus: true,"statusDesc" :"Failed to Delete News Feed "});
            } else if( reponseData==="SUCCESS!" ){
                reportData.splice(index, 1);
                setReportData([...mapResponseData(reportData)]);
                serverData.splice(index, 1);
                setServerData(serverData);
                setShowDataLoading(false);
                setFiltersInput({ ...filtersInput, "news": '',errorStatus: false, showStatus: true,"statusDesc" :"News Feed Deleted Successfully "});
            }else{
                setFiltersInput({ ...filtersInput, errorStatus: true, showStatus: true,"statusDesc" :"Failed to Delete News Feed "});
            }
        })
        .catch(err => {
            setShowDataLoading(false);
        })
        .finally(r => {
            setLoading(false)
        });
       
    }

    const clearNews = (index) =>{
        let found = reportData.find(element => element.Index === index);
        found.Description ='';
        reportData[index]=found;
        setReportData([...reportData]);
        
    }

    const openAddNews = () =>{
        setShowDataLoading(true);
    }

    const cancelAddNews = () => {
        setShowDataLoading(false);
        setFiltersInput({
            Index:0,
            Id:0,
            textAreaId : '',
            showTextArea : false,
            showStatus : false,
            statusDesc : '',
            errorStatus:false,
            news :'',});
    }

    const saveAddNews = () =>{
        let i=reportData.length;
        let id = (i+1)+'';
        var eachRow = {Index:i,Id:i,Description:filtersInput.news,showTextArea:false,textAreaId:"news_"+i};
        var eachRow1 = {Index:i,Id:i,Description:filtersInput.news,showTextArea:false,textAreaId:"news_"+i,isAdditional:true};

        //reportData.push(eachRow)
        // alert(filtersInput.news)
        
        if( !filtersInput.news){
            setFiltersReq({...filtersReq, ...{newsFeedInput_ErrorClass: 'form-control newsfeedFocus is-invalid'}});
        }else{
            setLoading(true)
            setFiltersReq({...filtersReq, ...{newsFeedInput_ErrorClass: 'form-control newsfeedFocus'}});
            addNewsFeed( id, filtersInput.news ).then(reponseData => {
                if (reponseData instanceof Error || reponseData==="FAILED") {
                    setFiltersInput({ ...filtersInput,errorStatus: true,showStatus: true, "statusDesc" :"Failed to Add News Feed "});
                } else if( reponseData==="SUCCESS!" ){
                    setReportData([...reportData,eachRow]);
                    setServerData([...serverData,eachRow1]);
                    setShowDataLoading(false);
                    setFiltersInput({ ...filtersInput, "news": '',errorStatus: false, showStatus: true,"statusDesc" :"News Feed Added Successfully "});
                }else{
                    setFiltersInput({ ...filtersInput,errorStatus: true,showStatus: true, "statusDesc" :"Failed to Add News Feed "});
                }
                setShowDataLoading(false);
            })
            .catch(err => {
                setShowDataLoading(false);
            })
            .finally(r=>{
                setLoading(false)
            });
        }
        
    }

    const resetErrStatus = () => {
        setFiltersInput({ ...filtersInput, errorStatus: false, showStatus: false,"statusDesc" :""});
    }

    return {
        handleInputChange,
        resetErrStatus,
        editNews,
        deleteNews,
        openAddNews,
        cancelAddNews,
        updateNews,
        cancelNews,
        clearNews,
        saveAddNews,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading,
        serverData,
        loading
    }
}

export default DataNewsFeed;