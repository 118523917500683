import React from 'react'
import InputElement from '../../formControls/InputElement'
import TextareaElement from "../../formControls/TextareaElement";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerElement from "../../formControls/DatePickerElement";
import moment from "moment";
const TrackingReport =  (props) => {

    const showElements = () =>{
        
        if(props.filtersInput.trackingType === "hawb" || props.filtersInput.trackingType === "nationwide"){
        return <div className="col-md-4 mb-3 mb-lg-0">
                    <InputElement type="text" id="trackingInput" name="trackingInput" 
                                onChange={props.handleInputChange} 
                                value={props.filtersInput.trackingInput || ''}
                                className={props.filtersReq.trackingInput_ErrorClass} 
                                placeholder=' HAWB / MAWB'
                                validation="required" onInvalid={props.onValidateFilters}
                                />
                </div>
                } 
        else if (props.filtersInput.trackingType === "date"){
        return    <div className="col-md-8">
                     <div className="row">
                        <div className="col-md-6 mb-3 mb-lg-0">
                            <DatePickerElement id="fromDate" name="fromDate" onChange={props.handleInputChange} 
                                //minDate={new Date()}
                                value={props.filtersInput.fromDate}
                                selected={props.filtersInput.fromDate}
                                className={props.filtersReq.fromDate_ErrorClass}
                            // maxDate={moment(new Date()).add(12, 'month').toDate()}
                            maxDate={props.filtersInput.toDate &&
                                moment(new Date(props.filtersInput.toDate)).subtract(1, 'days').toDate()
                                }
                            placeholder=' From Date'
                            validation="required" onInvalid={props.onValidateFilters}
                                />
                                </div> <div className="col-md-6 mb-3 mb-lg-0">
                                <DatePickerElement id="toDate" name="toDate" onChange={props.handleInputChange} 
                                //minDate={new Date()}
                                minDate={props.filtersInput.fromDate &&
                                    moment(new Date(props.filtersInput.fromDate)).add(0, 'days').toDate()
                                    }
                                value={props.filtersInput.toDate}
                                selected={props.filtersInput.toDate}
                                className={props.filtersReq.toDate_ErrorClass}
                            // maxDate={moment(new Date()).add(12, 'month').toDate()}
                            placeholder=' To Date'
                            validation="required" onInvalid={props.onValidateFilters}
                                />
                                </div>
                    </div>
            </div>
            }
        else if (props.filtersInput.trackingType === "mult"){
        return <div className="col-md-8  mb-3 mb-lg-0"> 
                    <TextareaElement id="trackingText" name="trackingText" rows="2" 
                    onChange={props.handleInputChange} 
                    value={props.filtersInput.trackingText || ''}
                    className={props.filtersReq.trackingText_ErrorClass} 
                    placeholder='Multiple Waybills' 
                    validation="required" onInvalid={props.onValidateFilters} /> 
                </div>
                }
                
    }
    
  return (
    <>
        <form>
            <div className="row  mb-3">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="tacrkingTypeHawbMawb" name="trackingType" 
                                    onChange={props.handleInputChange} value="hawb" 
                                    checked={props.filtersInput.trackingType === "hawb"}
                                    validation="requiredRadio" onInvalid={props.onValidateFilters}
                                    htmlFor="tacrkingTypeHawbMawb"
                                    className={props.filtersReq.trackingType_ErrorClass}  />
                                <label className="custom-control-label" htmlFor="tacrkingTypeHawbMawb">HAWB / MAWB</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="tacrkingTypeDateRange" name="trackingType" 
                                    onChange={props.handleInputChange} value="date" onInvalid={props.onValidateFilters}
                                    checked={props.filtersInput.trackingType === "date"}
                                    validation="requiredRadio" 
                                    htmlFor="tacrkingTypeDateRange"
                                    className={props.filtersReq.trackingType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="tacrkingTypeDateRange">Date Range</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="tacrkingTypeMultipleWayBills" name="trackingType" 
                                    onChange={props.handleInputChange}  
                                    value="mult"
                                    validation="requiredRadio" onInvalid={props.onValidateFilters}
                                    htmlFor="tacrkingTypeMultipleWayBills"
                                    checked={props.filtersInput.trackingType === "mult"}
                                    className={props.filtersReq.trackingType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="tacrkingTypeMultipleWayBills">Multiple Waybills</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="tacrkingTypeNationwide" name="trackingType" 
                                    onChange={props.handleInputChange}  
                                    value="nationwide"
                                    validation="requiredRadio" onInvalid={props.onValidateFilters}
                                    htmlFor="tacrkingTypeMultipleWayBills"
                                    checked={props.filtersInput.trackingType === "nationwide"}
                                    className={props.filtersReq.trackingType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="tacrkingTypeNationwide">Nationwide Linehaul</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-12 mb-3">
                    <div className="row">
                        {showElements()}
                        <div className="col-md-4">
                            <button className="btn btn-primary btn-block pull-right" type="button" onClick={props.onSubmitSearch}>Search</button>
                        </div>
                        
                    </div>
                </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.filtersInput)}</div>
            <div style={{ marginTop: 20 }}>{JSON.stringify(props.filtersReq)}</div> */}
            
            </div>

        </form>
    </>
  )
}

export default TrackingReport