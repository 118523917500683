import { faUnderline } from "@fortawesome/fontawesome-free-solid";
import axios from "axios";

export const fffTermList = () => {
    //let selectObj = [];
    return axios.get(process.env.REACT_APP_CARRIERS_URL)
        .then(response => response.data)
        //     {
        //     response.data.map((eachrow) => {
        //       //  console.log({"value":eachrow.id,"label":eachrow.name}) ;
        //         selectObj.push({"value":eachrow.id,"label":eachrow.name});
        //     })
        //    // console.log("Respose is ==>"+JSON.stringify(selectObj));
        //    return selectObj;
        // Object.entries(responseData).forEach((entry) => {
        //     console.log(JSON.stringify(entry)) ;
        //     const [key, value] = entry;
        //     //console.log(JSON.stringify(key) +":"+ JSON.stringify(value));
        //     });

        // })
        .catch(error => {
            // console.log("Error in calling TerminalList" + error);
            return error;
        })
        .finally(() => {
            // console.log("Finally call rest call in Carriers");
        })
    //return result;
}

export const lhTermList = () => {
    //let selectObj = [];
    return axios.get(process.env.REACT_APP_LINEHAUL_URL)
        .then(response => response.data)
        .catch(error => {
            // console.log("Error in calling TerminalList" + error);
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Linehaul");
        })

}

export const rrTermList = () => {
    //let selectObj = [];
    return axios.get(process.env.REACT_APP_RRTERMINALS_URL)
        .then(response => response.data)
        .catch(error => {
            // console.log("Error in calling TerminalList" + error);
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Linehaul");
        })

}

export const rrAccessorialList = () => {
    //let selectObj = [];
    //console.log("call rrAccessorials"+process.env.REACT_APP_RR_ACCESORIALS_URL);
    return axios.get(process.env.REACT_APP_RR_ACCESORIALS_URL)
        .then(response => response.data)
        .catch(error => {
             console.log("Error in calling rrAccessorialList" + error);
            return null;
        })
        .finally(() => {
            // console.log("Finally call in Accessorials");
        })
}

export const accessorialList = (zipCode,paramType) => {
    //let selectObj = [];
    let data = "?param="+zipCode+"&paramType="+paramType;
    return axios.get(process.env.REACT_APP_ACCESORIALS_URL+data)
        .then(response => response.data)
        .catch(error => {
            // console.log("Error in calling TerminalList" + error);
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Accessorials");
        })
}

export const quickQuote = (quoteTotalDim, quotePreFill, quoteShipping, dimensionList, userProfile
    , preFillRequired, shippingRequired, totalDimRequired,
    accessorialDetails) => {
        
    const postData = generateQuickQote(quoteTotalDim, quotePreFill, quoteShipping, dimensionList, userProfile, accessorialDetails);
    // console.log("request qickqote --",JSON.stringify(postData))
    //  console.log(process.env.REACT_APP_QUOTE_URL)
    
    return axios.post(process.env.REACT_APP_QUOTE_URL, postData)
        .then(response => {
        //    console.log("response qickqote --"+JSON.stringify(response))
            const addValue = {
                "serviceTypeName": quotePreFill.serviceTypeName,
                "typeOfMoveName": quotePreFill.typeOfMoveName,
                "lineHaulServiceTypeName": quotePreFill.lineHaulServiceTypeName,
                //"hazardouesMaterial": quoteTotalDim.hazardouesMaterial,
                "dimWeightTotal": quoteTotalDim.dimWeightTotal,
                "typeOfMove": quotePreFill.typeOfMove,
                "serviceType": quotePreFill.serviceType,
                "lineHaul": quotePreFill.lineHaul,
                "lineHaulServiceType": quotePreFill.lineHaulServiceType,
                "lineHaulServiceDisable": quotePreFill.lineHaulServiceDisable,
                "fffTo": quoteShipping.fffTo,
                "fffFrom": quoteShipping.fffFrom,
                "specialDelivery": quoteShipping.specialDelivery,
                "shipCodeFromEntry": quoteShipping.shipCodeFromEntry,
                "shipCodeToEntry": quoteShipping.shipCodeToEntry,
                "terminalFrom": quoteShipping.terminalFrom,
                "terminalFromShow": quoteShipping.terminalFromShow,
                "disableTerminalFromSel": quoteShipping.disableTerminalFromSel,
                "terminalTo": quoteShipping.terminalTo,
                "terminalToShow": quoteShipping.terminalToShow,
                "disableTerminalToSel": quoteShipping.disableTerminalToSel,
                "quoteShipping" : quoteShipping,
                "quotePreFill":quotePreFill,
                "quoteTotalDim":quoteTotalDim,
                "preFillRequired": preFillRequired,
                "shippingRequired": shippingRequired,
                "totalDimRequired": totalDimRequired,
                "shipFromDetails": quoteShipping.shipFromDetails,
                "shipToDetails": quoteShipping.shipToDetails,
                "accSerType": quoteTotalDim.accSerType
            };
            // console.log("Quote response.data",response);
            //postData = Object.assign(postData,{"addValue":addValue });
            const resData = Object.assign(postData, (response?.data?.result) ? response.data.result : response.data, { "addValue": addValue });
            //console.log("response data to map on UI ",JSON.stringify(resData));
            return resData;
        })
        .catch(error => {
              //console.log("Error in calling api/Quote "+JSON.stringify(error.response));
            //  console.log("Error in calling api/Quote "+JSON.stringify(error.response.data));
            //console.log("Error in calling api/Quote "+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}


export const generateQuickQote = (quoteTotalDim, quotePreFill, quoteShipping, dimensionList,
    userProfile, accessorialDetails) => {
    let accessorials = [];
    let pickupAccessorials = [];
    let deliveryAccessorials = [];

    if (quoteTotalDim.accessorial) {
        accessorials = quoteTotalDim.accessorial.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    if (quoteTotalDim.pickupAccessorials) {
        pickupAccessorials = quoteTotalDim.pickupAccessorials.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    if (quoteTotalDim.deliveryAccessorials) {
        deliveryAccessorials = quoteTotalDim.deliveryAccessorials.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    let lineHaul = false;
    let isRoadRunner = false;
    if(quotePreFill.lineHaul ==='lineHaul')
        lineHaul = true;
    else if(quotePreFill.lineHaul ==='localpu')
        lineHaul = false;
     else if(quotePreFill.lineHaul ==='NH')
     isRoadRunner = true;

    const pickopenTime = new Date(new Date(quoteShipping.pickupInfo).setHours('08')).toISOString();
    const pickcloseTime = new Date(new Date(quoteShipping.pickupInfo).setHours('17')).toISOString();
    const deliopenTime = new Date(new Date(quoteShipping.deliveryInfo).setHours('08')).toISOString();
    const delicloseTime = new Date(new Date(quoteShipping.deliveryInfo).setHours('17')).toISOString();
    const postData = {
        "customer": {
            "custCode": userProfile.custCode,
            "companyName": userProfile.companyName,
            "address1": userProfile.address1,
            "address2": userProfile.address2,
            "city": userProfile.city,
            "state": userProfile.state,
            "postalCode": userProfile.postalCode,
            "country": userProfile.country,
            "phone": userProfile.phone,
            "email": userProfile.email,
            "contact": userProfile.contact,
            "userName": userProfile.username,
            "userid": userProfile.userid+"",
            "additionalEmails": quotePreFill.additionalEmails
        },
        "shipper": {
            "companyName": quoteShipping.shipFromDetails.CompanyName || '',
            "address1": quoteShipping.shipFromDetails.Address1 || '',
            "address2": quoteShipping.shipFromDetails.Address2 || '',
            "city": quoteShipping.shipFromDetails.City || '',
            "state": quoteShipping.shipFromDetails.State || '',
            "postalCode": quoteShipping.shipFrom,
            "country": quoteShipping.shipFromDetails.Country || '',
            "phone": quoteShipping.shipFromDetails.PhoneNumber || '',
            "email": quoteShipping.shipFromDetails.CompanyName || '',
            "contact": quoteShipping.shipFromDetails.Contact || '',
            "terminal": quoteShipping.shipFromDetails.AirportCode || '',
        },
        "consignee": {
            "companyName": quoteShipping.shipToDetails.CompanyName || '',
            "address1": quoteShipping.shipToDetails.Address1 || '',
            "address2": quoteShipping.shipToDetails.Address2 || '',
            "city": quoteShipping.shipToDetails.City || '',
            "state": quoteShipping.shipToDetails.State || '',
            "postalCode": quoteShipping.shipTo || '',
            "country": quoteShipping.shipToDetails.Country || '',
            "phone": quoteShipping.shipToDetails.PhoneNumber || '',
            "email": quoteShipping.shipToDetails.CompanyName || '',
            "contact": quoteShipping.shipToDetails.Contact || '',
            "terminal": quoteShipping.shipToDetails.AirportCode || '',
        },
        "isLinehaul" : lineHaul,
        "isRoadRunner" : isRoadRunner,
        "shipment": {
            "serviceType": quotePreFill.serviceType,
            "linehaulServiceType": quotePreFill.lineHaulServiceDisable ? "0" : quotePreFill.lineHaulServiceType,
            "moveType": quotePreFill.typeOfMove,
            "weight": quoteTotalDim.grossWeight,
            "pickupDate": quoteShipping.pickupInfo,
            "pickupOpenTime": pickopenTime,
            "pickupCloseTime": pickcloseTime,
            "deliveryDate": quoteShipping.deliveryInfo,
            "deliveryOpenTime": deliopenTime,
            "deliveryCloseTime": delicloseTime,
            //"notes": quoteTotalDim.notes,
            "shipperNotes": quoteTotalDim.shipperNotes,
            "consigneeNotes": quoteTotalDim.consigneeNotes,
            "isSpecial": quoteShipping.specialPickup,
            //"isSpecial": quoteShipping.specialDelivery,
           // "recoverFromCarrier":quoteShipping.recoverCarrier,
           // "dropToCarrier":quoteShipping.dropToCarrier,
            "dimList": dimensionList,
            "accessorials": accessorials,
            "pickupAccessorials" : pickupAccessorials,
            "deliveryAccessorials" : deliveryAccessorials,
            "qty": quoteTotalDim.totalPcs,
            "accSerType" : quoteTotalDim.accSerType,
        }
    }

    //console.log(JSON.stringify(postData));
    return postData;
}

export const historyList = (uid) => {
    //let selectObj = [];
    var data="?uid="+uid
    return axios.get(process.env.REACT_APP_HISTORY_URL+data)
        .then(response => response.data)
        .catch(error => {
            // console.log("Error in calling historyList" + error);
            return error;
        })
        .finally(() => {
            // console.log("Finally call in historyList");
        })
}

export const fetchQuoteHistoryById =(quoteId) =>{
     //let selectObj = [];
     var uid="?id="+quoteId
     return axios.get(process.env.REACT_APP_QUOTE_HIST_ID_URL+uid)
         .then(response => response.data)
         .catch(error => {
            //  console.log("Error in calling fetchQuoteHistoryById" + error);
             return error;
         })
         .finally(() => {
            //  console.log("Finally call in fetchQuoteHistoryById");
         })
    
}

export const fetchBookedHistoryById =(bookId) =>{
     //let selectObj = [];
     var uid="?id="+bookId
     return axios.get(process.env.REACT_APP_BOOK_HIST_ID_URL+uid)
         .then(response => response.data)
         .catch(error => {
            //  console.log("Error in calling fetchBookedHistoryById" + error);
             return error;
         })
         .finally(() => {
            //  console.log("Finally call in fetchBookedHistoryById");
         })
    
}