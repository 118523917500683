import React, { useState, useEffect } from 'react'
import { useSelector} from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

import slidePic3 from "../assets/img/Slide-3.jpg";
import slidePic7 from "../assets/img/slide7.jpg";
import slidePic9 from "../assets/img/slide9.jpg";
import InputElement from "../components/formControls/InputElement";
import slidePic2 from "../assets/img/Slide-2.jpg";

import whiteGlovePic from "../assets/img/img_whiteGlove.jpg";
import {getNewsFeed} from '../components/services/restAPI/NewsFeedAPI';
const Login = () => {
    const navigate = useNavigate();
    const [trackingId, setTrackingId] = useState("");
    
    const [trackingId_Class, setTrackingId_Class] = useState("form-control");
    const [placehold, setPlacehold] = useState("Reference Number");
    const [reportData, setReportData] = useState([]);
    const loadNewsFeed = () => {
        let desc1 ="West Coast I-5 Linehaul - Due to escalating operating costs on the West Coast all I-5 corridor linehaul shipments will be assessed a 5% per invoice surcharge effective September 1st 2021"
        let desc2 = "COVID-19 Update: \n NO CONTACT DELIVERIES - Please note until further notice, most Freight Force carriers will not require drivers to obtain shipper/consignee signatures as part of the No Contact Delivery protocol to maintain social distancing. Our carriers will obtain the name of the shipper/consignee and place on the BOL, and will take a photo of the freight placement where appropriate. The same will apply for Residential where most deliveries will be limited to curbside or garage placement. Thank you for your understanding in our effort to keep freight moving while limiting driver/consignee contact."
        let desc3 ="West Coast I-5 Linehaul - West Coast I-5 Corridor Service now available. You can now use Freight Force door to door from SAN, LAX, SFO, PDX, SEA and YVR up and down the West Coast. Contact sales@freightforce.com for additional information."
        const tempdata =[{Id:1,Description:desc1},{Id:2,Description:desc2},{Id:3,Description:desc3}]
            getNewsFeed()
            .then(reponseDate => {
                if (reponseDate instanceof Error) {
                    setReportData([...tempdata]);
                } else {
                    // console.log(JSON.stringify(reportData))
                    setReportData([...reponseDate]);
                }
            })
            .catch(err => {
                setReportData([...tempdata]);
            })
    }
    const [lineHaulSearch, setLineHaulSearch] = useState("");
    
    const handleTrackSearch = (e) => {
        setLineHaulSearch(e.target.value);
    }

    const handleInputChange = (e) => {
        let val = e.target.value;
        setTrackingId( val );
    }
    const nonLoginTrackPage = () =>{
        if(!trackingId){
            setTrackingId_Class("form-control is-invalid");
            setPlacehold("Enter HAWB For Tracking")
        }else{
            setTrackingId_Class("form-control");
            setPlacehold("Reference")
            navigate("/nonLoginTrack", { state: { trackingId: trackingId , trackType : lineHaulSearch} });
        }
       
    }

    const areasServiced = () =>{
        navigate("/areasServiced");
    }
    const userProfile = useSelector((state) => state.usr)
    const quoteBooking = () =>{
         userProfile.isValid ?   navigate("/freightQuote") : navigate("/loginRequired")
    }
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        loadNewsFeed();
        
	  }, []);
    return (
        <>
        <section className="homeSlider">
        <div className="ffcurosel">
        <Carousel>
            {/* <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slidePic4}
                    alt="Your First and Final Mile Specialist"/>
                <Carousel.Caption>
                    <h2>Your First and <br />Final Mile Specialist</h2>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slidePic3}
                    alt="All Locations TSA Certified"/>
                <Carousel.Caption>
                <h2>All Locations  <br />TSA Certified</h2>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slidePic1}
                    alt=">All Locations TSA Certified"/>
                <Carousel.Caption>
                <h2>Courteous, Competitive, <br /> Dependable Service </h2>
                </Carousel.Caption>
            </Carousel.Item> */}
            {/* <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slidePic5}
                    alt=">All Locations TSA Certified"/>
                <Carousel.Caption>
                <h2>Your First and <br />Final Mile Specialist</h2>
                </Carousel.Caption>
        </Carousel.Item>*/}
            {/* <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slidePic6}
                    alt=">All Locations TSA Certified"/>
                <Carousel.Caption>
                <h2>All Locations  <br />TSA Certified</h2>
                </Carousel.Caption>
            </Carousel.Item>  */}
        <Carousel.Item>
                <img
                className="d-block w-100"
                src={slidePic7}
                alt="Your First and Final Mile Specialist"/>
            <Carousel.Caption>
                <h2>Your First and <br />Final Mile Specialist</h2>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
            <img
            className="d-block w-100"
            src={slidePic9}
            alt=">All Locations TSA Certified"/>
            <Carousel.Caption>
                <h2>All Locations  <br />TSA Certified</h2>
            </Carousel.Caption>
        </Carousel.Item>
                {/* <Carousel.Item>
            <img
            className="d-block w-100"
            src={slidePic3}
            alt=">All Locations TSA Certified"/>
            <Carousel.Caption>
                <h2>Courteous, Competitive, <br /> Dependable Service </h2>
            </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item>
            <img
            className="d-block w-100"
            src={slidePic2}
            alt=">Courteous, Competitive, Dependable Service "/>
            <Carousel.Caption>
            <h2>Courteous, Competitive, <br /> Dependable Service </h2>
            </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
                </div>
            </section>
            <section className="bookI5 mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 mb-3">
                            <h5>Book First & Final and Linehaul Shipments Now!</h5>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <form action="" method="post">
                                <div className="row alignLeft ml-1">
                                    <div className="col-lg-6 col-md-3 mb-lg-0 mb-3">
                                        <label>Select & Track By Service Type</label>
                                    </div>
                                    </div>
                                    <div className="row mb-2 alignCenter">
                                        <div className="col-lg-6 col-md-3 mb-lg-0 mb-3">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="lpud" name="lineHaulSearch"  className="custom-control-input"
                                                    onChange={handleTrackSearch} value="LOCAL" 
                                                    checked={lineHaulSearch==="LOCAL"} 
                                                    />
                                                <label className="custom-control-label" htmlFor="lpud">Local PU & D</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="lhnw" name="lineHaulSearch"  className="custom-control-input"
                                                    onChange={handleTrackSearch} value="I5" 
                                                    checked={lineHaulSearch==="I5"}
                                                    />
                                                <label className="custom-control-label" htmlFor="lhnw">I-5 Corridor</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="nwlh" name="lineHaulSearch"  className="custom-control-input"
                                                    onChange={handleTrackSearch} value="NATIONWIDE" 
                                                    checked={lineHaulSearch==="NATIONWIDE"}
                                                    />
                                                <label className="custom-control-label" htmlFor="nwlh">Nationwide Linehaul</label>
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-3 mb-lg-0 mb-3">
                                        <input type="text" id="trackingId" value={trackingId} onChange={handleInputChange} placeholder={placehold} className={trackingId_Class}/>
                                    </div>
                                    <div className="col-lg-8 col-md-9 mb-lg-0">
                                    <div className="row">
                                        <div className="col-md-4">
                                        <input type="button" onClick={nonLoginTrackPage} value="TRACK" className="buttonTrack btn btn-outline-light buttonoutline"/>
                                        </div>
                                        <div className="col-md-4">
                                        <input type="button" onClick={quoteBooking} value="QUOTE/BOOKING" className="buttonTrack btn btn-outline-light buttonoutline"/>
                                        </div>
                                        <div className="col-md-4">
                                        <input type="button" onClick={areasServiced} value="AREAS SERVICED" className="buttonTrack btn btn-outline-light buttonoutline"/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
           
            {/* <!-- News Slider--> for ref:https://react-bootstrap.github.io/components/carousel/*/}
            <section className="newsSlider mb-4">
            <div className="row">
            <div id="newsCarousel" className="col-lg-10 offset-lg-1">
                <Carousel>
                {reportData.map((x, i) => {
                return (<Carousel.Item ><p>{x.Description}</p>  </Carousel.Item>)
                })}
                            
                    {/* <Carousel.Item >
                    <p>West Coast I-5 Linehaul - Due to escalating operating costs on the West Coast all I-5 corridor linehaul shipments will be assessed a 5% per invoice surcharge effective September 1st 2021</p>
                </Carousel.Item>
                    <Carousel.Item>
                        <p><strong>COVID-19 Update:</strong><br/>  NO CONTACT DELIVERIES - Please note until further notice, most Freight Force carriers will not require drivers to obtain shipper/consignee signatures as part of the No Contact Delivery protocol to maintain social distancing.  Our carriers will obtain the name of the shipper/consignee and place on the BOL, and will take a photo of the freight placement where appropriate.  The same will apply for Residential where most deliveries will be limited to curbside or garage placement.  Thank you for your understanding in our effort to keep freight moving while limiting driver/consignee contact. </p>
                    </Carousel.Item>
                    <Carousel.Item>
                        <p>West Coast I-5 Linehaul - West Coast I-5 Corridor Service now available. You can now use Freight Force door to door from SAN, LAX, SFO, PDX, SEA and YVR up and down the West Coast. Contact <a href="mailto:sales@freightforce.com" target="_blank">sales@freightforce.com</a> for additional information. </p>
                    </Carousel.Item> */}
                </Carousel>
            </div>
            </div>
            </section>
            

             {/* <!-- ======= Icon Boxes Section ======= --> */}
             <section className="servCards mb-4">
                <div className="container">
                    <div className="row">
                    
                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-stopwatch"></i></div>
                                <h4 className="title">Cartage Service</h4>
                                <p className="description">Our network of approved motor carriers can meet the air forwarder and logistic communities needs for expedited pick-up and LTL traffic in compliance with all government regulations.</p>
                                <div className="btn-wrap">
                                    <NavLink to="/services/cartage" className="btn-lrnMore">
                                        Click here
                                    </NavLink>
                                </div>
                            </div>
                        </div>



                        {/* <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" >
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-exchange-alt"></i></div>
                                    <h4 className="title">Regional Linehaul</h4>
                                    <p className="description">We can arrange Linehaul service from a one-pound shipment to a full truckload with a competitive schedule and competitive pricing.
                                    </p>
                                <div className="btn-wrap">
                                    <NavLink to="/services/regionalLineHaul" className="btn-lrnMore">
                                    Click here
                                    </NavLink>
                                </div>
                            </div>
                        </div> */}


                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0" >
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-exchange-alt"></i></div>
                                    <h4 className="title">Nationwide Linehaul</h4>
                                    {/* In partnership with our parent company STG Logistics, Freight Force is now able to provide the middle mile offering a nationwide linehaul service. This new linehaul service affords our customers the opportunity to have the complete door to door service experience exclusively through the Freight Force network. Our initial linehaul service rollout offers 10 lanes, expanding to 30 lanes across the country by the end of the first quarter in 2024.  */}
                                    <p className="description">This new linehaul service affords our customers the opportunity to have the complete door to door service experience exclusively through the Freight Force network.
                                    </p>
                                <div className="btn-wrap">
                                    <NavLink to="/services/nationwideLineHaul" className="btn-lrnMore">
                                    Click here
                                    </NavLink>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-search"></i></div>
                                <h4 className="title">I-5 Corridor</h4>
                                <p className="description">Use the Freight Force Linehaul in conjunction with our First & Final Mile Service up & down the West Coast. Servicing SAN, LAX, SFO, PDX, SEA and YVR.
                                </p>
                                <p><a href="mailto:linehaul@freightforce.com" target="_blank">linehaul@freightforce.com</a></p>
                                <div className="btn-wrap">
                                    <NavLink to="/services/i5Corridor" className="btn-lrnMore">
                                        Click here
                                    </NavLink>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="fas fa-chart-line"></i></div>
                                <h4 className="title">National Sales</h4>
                                <p className="description">Dennis Kuhlman<br />
                                    714.995.9300 x40060<br />
                                    <a href="mailto:Dennis.kuhlman@freightforce.com" target="_blank">Dennis.kuhlman@freightforce.com</a><br />
                                    Special Projects<br />
                                    Please email:<br />
                                    <a href="mailto:sales@freightforce.com" target="_blank">sales@freightforce.com</a></p>
                                <div className="btn-wrap">
                                    <NavLink to="/nationalSales" className="btn-lrnMore">
                                        Click here
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- End Icon Boxes Section --> */}
	{/* <!-- News Slider End--> */}


            <section className="aboutSec mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={whiteGlovePic} className="img-fluid"/>
                        </div>
                        <div className="col-lg-6 aboutTxt">
                            <h5>Welcome</h5>
                            <p>Welcome to Freight Force, Inc., with a network of approved motor carriers providing pickup and delivery services to more than 65 cities in the Continental US. We provide the logistics industry with first and final mile. We’ve been doing this for 35 years, and cartage is not all that we do; check out the rest of our services. We provide the logistics industry with a single point of contact for nationwide billing, including EDI 204, 210 and 214, sales & marketing, claims management, and other key operational needs.</p>
                            <p>Founded in 1983 as a network of approved motor carriers, Freight Force Inc. provides full back office support including nationwide sales and marketing, billing, collections, data warehousing and software maintenance.</p>
                            <div className="btn-wrap">
                                <NavLink to="/aboutUs/ourStory" title="More" className="btn-lrnMore">
                                    Read More ...
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}



export default Login;