import { useState,useEffect } from "react";
import { isNumberAndNotZero } from '../../../utils/ValidationScripts';
const DataBooknow = () => {

  
  const [quickBook, setQuickBook] = useState({
    companyName: '',
    email: '',
    phone: '',
    typeOfMove: "1",
    typeOfMoveName: "",
    serviceType: "1",
    serviceTypeName: "",
    lineHaul: "lineHaul",
    localpud: true,
    lineHaulLh: true,
    roadRunner: false,
    lineHaulServiceType: "1",
    lineHaulServiceTypeName: "",
    lineHaulServiceDisable: true,
    additionalEmails: '',
    fffTo: false,
    fffFrom: true,
    shipFrom: '',
    shipFromDetails: {},
    shipTo: '',
    dropToCarrier:'',
    recoverCarrier:'',
    shipToDetails: {},
    deliveryInfo: null,
    terminalFrom: '0',
    terminalFromShow: true,
    terminalTo: '0',
    terminalToShow: false,
    disableTerminalFromSel: false,
    disableTerminalToSel: false,
    shipperFFF: "",
    consigneeFFF: "",
    shiCompanyName: "",
    consiCompanyName: "",
    shiAddress: "",
    consiAddress: "",
    shiCity: "",
    shiState: "",
    shiZipcode: "",
    shiContact: "",
    shiPhone: "",
    shiTerminal:"",
    consiCity: "",
    consiState: "",
    consiZipcode: "",
    consiContact: "",
    consiPhone: "",
    consiTerminal:"",
    pickupInfo: "",
    specialPickup: false,
    piReadyTime: "",
    piCloseTime: "",
    deliInfo: "",
    specialDelivery: false,
    deliReadyTime: "",
    deliCloseTime: "",
    totalPcs: "",
    grossWeight: "",
    dimWeightTotal: "",
    hawb: "",
    mawb: "",
    refNo: "",
    originZip: "",
    destinationZip: "",
    msg: "",
    weight: 0,
    qty: 0,
    fsc: 0,
    fsc_rate: 0,
    freight_charge: 0,
    quoteId: "",
    shipCodeFromEntry: true,
    shipCodeToEntry: true,
    isRoadRunner : false,
    rrBolNumber : "",
    pronumber: "",
    isShipperInfoEdit: true,
    isConsigneeInfoEdit: false,
    isDimensionsInfoEdit : true,
    accessorial: '',
    pickupAccessorials:'',
    deliveryAccessorials:'',
    shipperNotes: '',
    consigneeNotes: '',
    accSerType:'pickup',

    
    // hazContact:"",
    // hazContact:"",
    // hazName:"",
    // printLabel: false,
    // numOfLabel:0,
    // labelStyle:"",
    // billToCon:false,
    // billToShi:false,
    // billToThi:false,
    // billToReq:false,
  });
  const [lineHaulTermDetails, setLineHaulTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [roadRunnerTermDetails, setRoadRunnerTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [fffTermDetails, setfffTermDetails] = useState([{
    CompanyName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostalCode: "",
    Contact: "",
    PhoneNumber: "",
    AirportCode: ""
  }]);
  const [accessorialDetails, setAccessorialDetails] = useState([{
    code: "",
    description: "***",
    amount: 0,
    min: 0,
    surType: ""
  }]);
 
  const [initialInputs] = [{
    totalPcs: '',
    grossWeight: '',
    dimWeightTotal: '',
    accessorial: '',
    pickupAccessorials:'',
    deliveryAccessorials:'',
    shipperNotes: '',
    consigneeNotes: '',
    accSerType:'pickup',
    //hazardouesMaterial: 'false',
  }];
  const [initialRequired] = [{
    totalPcs: true,
    totalPcs_ErrorClass: 'form-control',
    grossWeight: true,
    grossWeight_ErrorClass: 'form-control',
    dimWeightTotal: true,
    dimWeightTotal_ErrorClass: 'form-control',
    // notes:true,
    // notes_ErrorClass: 'form-control',
    //accessorial: true,
    //accessorial_ErrorClass: 'text-danger d-none'
    
    pickupAccessorials:true,
    deliveryAccessorials:true,
    pickupAccessorials_ErrorClass: 'text-danger d-none',
    deliveryAccessorials_ErrorClass: 'text-danger d-none',

    
  }];

  const [quoteTotalDim, setQuoteTotalDim] = useState(initialInputs);
  const [totalDimRequired, setTotalDimRequired] = useState(initialRequired);

  const onValidateDimTotal = (name, value) => {
    var errState = {};
    var status = false;
    Object.entries(totalDimRequired).forEach(([key]) => {
      var errname = key + "_ErrorClass";
      if( errname==="accessorial_ErrorClass" ){
        if (key === name && value) {
          errState = { [name]: true, [errname]: 'text-danger d-none' };
          status = true;
        } else if (key === name && !value) {
          errState = { [name]: false, [errname]: 'text-danger' };
          status = true;
        }
      }else{
        if (key === name && value) {
          errState = { [name]: true, [errname]: 'form-control is-invalid' };
          status = true;
        } else if (key === name && !value) {
          errState = { [name]: false, [errname]: 'form-control is-valid' };
          status = true;
        }
      }
    })
    if (status)
      setTotalDimRequired({ ...totalDimRequired, ...errState });
    // console.log(totalDimRequired);
  }


  const onChangeDimTotal = (name, value) => {
    setQuickBook({ ...quickBook, [name]: value });
    //  console.log(name + ': setquoteTotalDim =' + value);
  }

  const updateDimTotals = async (totals) => {
    setQuickBook({ ...quickBook, ...totals });
    //  console.log(name + ': setquoteTotalDim =' + value);

    let errState = {};
    if (isNumberAndNotZero(totals.totalPcs)) {
      errState.totalPcs = false
      errState.totalPcs_ErrorClass = 'form-control is-valid'
    } else {
      errState.totalPcs = true
      errState.totalPcs_ErrorClass = 'form-control is-invalid'
    }

    if (isNumberAndNotZero(totals.grossWeight)) {
      errState.grossWeight = false
      errState.grossWeight_ErrorClass = 'form-control is-valid'
    } else {
      errState.grossWeight = true
      errState.grossWeight_ErrorClass = 'form-control is-invalid'
    }

    if (isNumberAndNotZero(totals.dimWeightTotal)) {
      errState.dimWeightTotal = false
      errState.dimWeightTotal_ErrorClass = 'form-control is-valid'
    } else {
      errState.dimWeightTotal = true
      errState.dimWeightTotal_ErrorClass = 'form-control is-invalid'
    }

    setTotalDimRequired({ ...totalDimRequired, ...errState });
    //console.log("Copied  Total "+JSON.stringify(totalDimRequired));
  }

  const onSubmitTotDimensions = () => {
    const copy = { ...totalDimRequired };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    // console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = null;
        if( errname==="pickupAccessorials_ErrorClass" || errname==="deliveryAccessorials_ErrorClass"){
            errState = { [key]: true, [errname]: 'text-danger' };
        }else{
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }
        status = true;
        Object.assign(errObject, errState);
        //  console.log("Total Error obj "+JSON.stringify(errObject));
      } else {

      }
    })
    if (status) {
      setTotalDimRequired({ ...totalDimRequired, ...errObject });
       console.log("Copied  Total "+JSON.stringify(totalDimRequired));
    }

    if (totalDimRequired.totalPcs || totalDimRequired.grossWeight || totalDimRequired.dimWeightTotal || totalDimRequired.pickupAccessorials || totalDimRequired.deliveryAccessorials ) {
      validationReqFlag = true;
    }

    return validationReqFlag;
  }

  // useEffect(() => {
  //   var accessorialLen = quoteTotalDim.accessorial.length;
  //   var errState = {}
  //   if (accessorialLen === 0) {
  //     errState.accessorial = true
  //     errState.accessorial_ErrorClass = 'text-danger d-none'
  //   } else {
  //     errState.accessorial = false
  //     errState.accessorial_ErrorClass = 'text-danger d-none'
  //   }

  //   setTotalDimRequired({ ...totalDimRequired, ...errState });
  // }, [quoteTotalDim.accessorial]);// eslint-disable-line

  useEffect(() => {
    var accessorialLen = quoteTotalDim.pickupAccessorials.length;
    var errState = {}
    if (accessorialLen === 0) {
      errState.pickupAccessorials = true
      errState.pickupAccessorials_ErrorClass = 'text-danger d-none'
    } else {
      errState.pickupAccessorials = false
      errState.pickupAccessorials_ErrorClass = 'text-danger d-none'
    }
    accessorialLen = quoteTotalDim.deliveryAccessorials.length;
    if (accessorialLen === 0) {
      errState.deliveryAccessorials = true
      errState.deliveryAccessorials_ErrorClass = 'text-danger d-none'
    } else {
      errState.deliveryAccessorials = false
      errState.deliveryAccessorials_ErrorClass = 'text-danger d-none'
    }

    setTotalDimRequired({ ...totalDimRequired, ...errState });
  }, [quoteTotalDim.pickupAccessorials, quoteTotalDim.deliveryAccessorials]);// eslint-disable-line

  // useEffect(() => {

  //     setDataFields(dataFieldValues);
  //     console.log("This is in use effect" + dataFields);
  // }, []);


  const updateTotalDimensionsState = async (data) => {
    //console.log("quoteTotalDim="+JSON.stringify(data));
    setQuoteTotalDim({ ...quickBook, ...data });
  }

  const updateTotalDimensionsReqState = async (data) => {
    //console.log("quoteShippingReq="+JSON.stringify(data));
    setTotalDimRequired({ ...totalDimRequired, ...data });
  }

  const clearDimTotals = () => {
    setQuoteTotalDim(initialInputs);
    setTotalDimRequired(initialRequired);
  }

  //"6" - "6 Labels/Page
  //"4" - "6*4 Label"
  //"F" -"Full Page Label"

  // const { state } = useLocation();

  //const quickBookRes = state.quoteRes;
  //const quickBookRes ={};
  // const state = new useLocation();
  // quickBookRes = state.quickBook;

  const onChangeBookHandler = (name, inValue) => {
    //var result = mapedFields.find(eachitem => String(eachitem.field).trim() === String(name).trim());
    var updateValues = { [name]: inValue };
    // console.log(updateValues);
    
    //console.log(name[0].trim()+"="+value);    
    if (name[0].trim() === "lineHaul" ){
       // console.log("onChangePrefill is called lineHaul is =",value);    
        updateValues = { [name]: inValue };
        if(inValue === "lineHaul"){
            updateValues = Object.assign(updateValues, { fffFrom: true, fffTo:false, "lineHaulServiceType": "1", "lineHaulServiceDisable": false ,localpud: false, lineHaulLh: true,roadRunner: false,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "", terminalFrom:"", terminalTo:""});
        }else if(inValue === "localpu"){
            updateValues = Object.assign(updateValues, { fffFrom: true, fffTo:true, "lineHaulServiceType": "1", "lineHaulServiceDisable": true ,localpud: true, lineHaulLh: false,roadRunner: false,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "",terminalFrom:"", terminalTo:""});
        }else if(inValue === "NH"){
            updateValues = Object.assign(updateValues, { fffFrom: true, fffTo:false, "lineHaulServiceType": "1", "lineHaulServiceDisable": false ,localpud: false, lineHaulLh: false,roadRunner: true,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "",terminalFrom:"", terminalTo:""});
        }
    }
    if ( name[0].trim() === "lineHaulServiceType" && inValue === '1'){
      updateValues = Object.assign(updateValues, { fffFrom: true, fffTo:false,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "", terminalFrom:"", terminalTo:""})
    }
    if ( name[0].trim() === "lineHaulServiceType" && inValue === '2'){
      updateValues = Object.assign(updateValues, { fffFrom: false, fffTo:false,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "", terminalFrom:"", terminalTo:""})
    }
    if ( name[0].trim() === "lineHaulServiceType" && inValue === '3'){
      updateValues = Object.assign(updateValues, { fffFrom: false, fffTo:true,shiCompanyName: "",shiAddress: "",shiCity: "", shiState: "", shiZipcode: "", shiContact: "", shiPhone: "",consiCompanyName: "",consiAddress: "", consiCity: "", consiState: "", consiZipcode: "", consiContact: "", consiPhone: "", terminalFrom:"", terminalTo:""})
    }
    //console.log("console.log(updateValues);",updateValues);
    if (name[0].trim() === "terminalFrom" && inValue) {
      const addrFrom = fetchAddress(quickBook.lineHaul, quickBook.fffFrom, inValue);
      //console.log("addrFrom,addrFrom",addrFrom)
      updateValues = Object.assign(updateValues, { "shipFromDetails": addrFrom, "shipFrom": addrFrom.PostalCode, "shipCodeFromEntry": false,shiCompanyName: addrFrom.CompanyName,shiAddress: addrFrom.Address1,shiCity: addrFrom.City, shiState: addrFrom.State, shiZipcode: addrFrom.PostalCode, shiContact: addrFrom.Contact, shiPhone: addrFrom.PhoneNumber, shiTerminal : addrFrom.AirportCode, })
      validateAddress("Ship");
    }
    if (name[0].trim() === "terminalTo" && inValue) {
      const addrTo = fetchAddress(quickBook.lineHaul, quickBook.fffTo, inValue);
      //console.log("addrTo,addrTo",addrTo)
      updateValues = Object.assign(updateValues, { "shipToDetails": addrTo, "shipTo": addrTo.PostalCode, "shipCodeToEntry": false ,consiCompanyName: addrTo.CompanyName,consiAddress: addrTo.Address1, consiCity: addrTo.City, consiState: addrTo.State, consiZipcode: addrTo.PostalCode, consiContact: addrTo.Contact, consiPhone: addrTo.PhoneNumber, consiTerminal : addrTo.AirportCode,})
      validateAddress("Consi");
    }
    setQuickBook({ ...quickBook, ...updateValues });
    //console.log(JSON.stringify(quickBook));
  }
  const fetchAddress = (lineHaul, ffe, code) => {
    if (lineHaul === "lineHaul") {
      return lineHaulTermDetails.find(eachobj => eachobj.AirportCode === code)
    }else if (lineHaul === "NH") {
      return roadRunnerTermDetails.find(eachobj => eachobj.AirportCode === code)
    }else if (lineHaul === "localpu")  {
      return fffTermDetails.find(eachobj => eachobj.AirportCode === code)
    }
    return {};
  }
  const toggleTerminals = async (lineHaul, selectedValue, termFrom, termTo, shipFrom, shipTo, shipFromDetails, shipToDetails, shipCodeFromEntry, shipCodeToEntry, resetWithValues) => {
    //console.log(JSON.stringify(selectedValue)+"terminalState"+ JSON.stringify(lineHaul));
    var terminalState = {},terminalStateReq = {};
    if (lineHaul === "lineHaul"  || lineHaul === "NH") { // ==="true"
      // console.log("current shippoing statue"+selectedValue);
      if (selectedValue == "1"){ // Terminal to Door
        terminalState = { "terminalFromShow": true, "terminalToShow": false, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": "0", "fffFrom" : true, "fffTo" : false , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails 
                        , "disableTerminalFromSel" : true , "disableTerminalToSel" : false }
      }else if (selectedValue == "2"){ // Door to Door
        terminalState = { "terminalFromShow": false, "terminalToShow": false, "lineHaul": lineHaul, "terminalFrom": "0" , "terminalTo": "0", "fffFrom" : false, "fffTo" : false , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
      }else if (selectedValue == "3"){ // Door to Terminal 
        terminalState = { "terminalFromShow": false, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": "0", "terminalTo": termTo, "fffFrom" : false, "fffTo" : true, "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails 
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : true }
      }else if (selectedValue == "4"){ // Terminal to Terminal
        terminalState = { "terminalFromShow": true, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": termTo, "fffFrom" : true, "fffTo" : true, "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
      
      //     console.log("terminalState"+ JSON.stringify(quoteShipping));
    }
  } else if (lineHaul === "localpu") { // ==="false"
        terminalState = {  "terminalFromShow": true, "terminalToShow": true, "lineHaul": lineHaul, "terminalFrom": termFrom, "terminalTo": termTo, "fffFrom" : true, "fffTo": true , "shipTo": shipTo, "shipFrom": shipFrom, "shipFromDetails": shipFromDetails, "shipToDetails": shipToDetails
                        , "disableTerminalFromSel" : false , "disableTerminalToSel" : false }
    } 
    //console.log("shipFromDetails == "+ JSON.stringify(shipFromDetails));
    //console.log("shipToDetails == "+ JSON.stringify(shipToDetails));
    if( resetWithValues ){
    }else{
      if(termFrom == "0" || termFrom == "" ){
        terminalState = Object.assign(terminalState, { "shipFrom": "", "shipCodeFromEntry" : true})
        terminalStateReq = Object.assign(terminalStateReq, { "shipFrom": true, "shipFrom_ErrorClass": "form-control"})
        
      }
      if(termTo == "0" || termTo == "" ){
        terminalState = Object.assign(terminalState, { "shipTo": "" , "shipCodeToEntry" : true})
        terminalStateReq = Object.assign(terminalStateReq, { "shipTo": true, "shipTo_ErrorClass": "form-control"})
      }
    }
    // else if (!lineHaul) {
    //   terminalState = {  "shipTo": "", "shipFrom": "", "shipFromDetails": {}, "shipToDetails": {}, "lineHaul": false, "terminalFrom": "0", "terminalTo": "0", "fffFrom" : true, "fffTo": true }
    // }
    setQuickBook({ ...quickBook, ...terminalState });
    setQuickBookRequired({ ...quickBookRequired, ...terminalStateReq })
  }

  const updateMutipleValues = async (totals) => {
    setQuickBook({ ...quickBook, ...totals });
    //console.log("=="+JSON.stringify(totals))
    }

  const updateQuickBookObject = async (address) => {
    setQuickBook({ ...quickBook, ...address });
    var errState = {};
    var status = false;
    Object.entries(address).forEach(([name,eachVal]) => {
      let value = eachVal ? false:true
     // console.log("name--",name,"-value--",value)
    //Object.entries(quickBookRequired).forEach(([key]) => {
      var key = name;
      
      var errname = key + "_ErrorClass";
      if( errname==="shiState_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none' });
          status = true;
        }
      }else if( errname==="consiState_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none' });
          status = true;
        }
      }else if( errname==="shiCompanyName_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger', 'shiCompanyNameAc_ErrorClass' : 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none', 'shiCompanyNameAc_ErrorClass' : 'text-danger d-none'  });
          status = true;
        }
      }else if( errname==="consiCompanyName_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger', 'consiCompanyNameAc_ErrorClass' : 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none', 'consiCompanyNameAc_ErrorClass' : 'text-danger d-none'  });
          status = true;
        }
      }else if( key==="shiContact" || key==="shiPhone" || key==="consiContact" || key==="consiPhone" ){ // as these not not mandatory

      }else{
        if (key.trim() === name.trim() && value) {
          Object.assign( errState , { [name]: true, [errname]: 'form-control is-invalid' });
          status = true;
        } else if (key.trim() === name.trim() && !value) {
          Object.assign( errState , { [name]: false, [errname]: 'form-control is-valid' });
          status = true;
        }
    }
  })
    if (status)
      setQuickBookRequired({ ...quickBookRequired, ...errState });      
  }
  const stateList = [{value: "AL", label: "Alabama - (AL)"},
  //{value: "AK", label: "Alaska - (AK)"},
  {value: "AZ", label: "Arizona - (AZ)"},
  {value: "AR", label: "Arkansas - (AR)"},
  {value: "BC", label: "British Columbia, Canada - (BC)"},
  {value: "CA", label: "California - (CA)"},
  {value: "CO", label: "Colorado - (CO)"},
  {value: "CT", label: "Connecticut - (CT)"},
  {value: "DE", label: "Delaware - (DE)"},
  {value: "DC", label: "District of Columbia - (DC)"},
  {value: "FL", label: "Florida - (FL)"},
  {value: "GA", label: "Georgia - (GA)"},
  {value: "HI", label: "Hawaii - (HI)"},
  {value: "ID", label: "Idaho - (ID)"},
  {value: "IL", label: "Illinois - (IL)"},
  {value: "IN", label: "Indiana - (IN)"},
  {value: "IA", label: "Iowa - (IA)"},
  {value: "KS", label: "Kansas - (KS)"},
  {value: "KY", label: "Kentucky - (KY)"},
  {value: "LA", label: "Louisiana - (LA)"},
  {value: "ME", label: "Maine - (ME)"},
  {value: "MD", label: "Maryland - (MD)"},
  {value: "MA", label: "Massachusetts - (MA)"},
  {value: "MI", label: "Michigan - (MI)"},
  {value: "MN", label: "Minnesota - (MN)"},
  {value: "MS", label: "Mississippi - (MS)"},
  {value: "MO", label: "Missouri - (MO)"},
  {value: "MT", label: "Montana - (MT)"},
  {value: "NE", label: "Nebraska - (NE)"},
  {value: "NV", label: "Nevada - (NV)"},
  {value: "NH", label: "New Hampshire - (NH)"},
  {value: "NJ", label: "New Jersey - (NJ)"},
  {value: "NM", label: "New Mexico - (NM)"},
  {value: "NY", label: "New York - (NY)"},
  {value: "NC", label: "North Carolina - (NC)"},
  {value: "ND", label: "North Dakota - (ND)"},
  {value: "OH", label: "Ohio - (OH)"},
  {value: "OK", label: "Oklahoma - (OK)"},
  {value: "OR", label: "Oregon - (OR)"},
  {value: "PA", label: "Pennsylvania - (PA)"},
  // {value: "PR", label: "Puerto Rico - (PR)"},
  {value: "RI", label: "Rhode Island - (RI)"},
  {value: "SC", label: "South Carolina - (SC)"},
  {value: "SD", label: "South Dakota - (SD)"},
  {value: "TN", label: "Tennessee - (TN)"},
  {value: "TX", label: "Texas - (TX)"},
  {value: "UT", label: "Utah - (UT)"},
  {value: "VT", label: "Vermont - (VT)"},
  {value: "VA", label: "Virginia - (VA)"},
  // {value: "VI", label: "Virgin Islands - (VI)"},
  {value: "WA", label: "Washington - (WA)"},
  {value: "WV", label: "West Virginia - (WV)"},
  {value: "WI", label: "Wisconsin - (WI)"},
  {value: "WY", label: "Wyoming - (WY)"},
  ];
  //"6" - "6 Labels/Page
  //"4" - "6*4 Label"
  //"F" -"Full Page Label"
  const labelStyleList =[
    {value: "6", label: "6 Labels/Page"},
    {value: "4", label: "6*4 Label"},
    {value: "F", label: "Full Page Label"},
  ];
  const labelCountList =[
    {value: "1", label: "1-One"},
    {value: "2", label: "2-Two"},
    {value: "3", label: "3-Three"},
    {value: "4", label: "4-Four"},
    {value: "5", label: "5-Five"},
    {value: "6", label: "6-Six"},
    {value: "7", label: "7-Seven"},
    {value: "8", label: "8-Eight"},
    {value: "9", label: "9-Nine"},
    {value: "10", label: "10-Ten"},
  ];
  const packageTypeList = [{value: "Bag", label: "Bag"},
  {value: "Barrel", label: "Barrel"},
  {value: "Bundle", label: "Bundle"},
  {value: "Bucket", label: "Bucket"},
  {value: "Bale", label: "Bale"},
  {value: "Basket", label: "Basket"},
  {value: "Bulkhead", label: "Bulkhead"},
  {value: "Box", label: "Box"},
  {value: "Chest", label: "Chest"},
  {value: "Coil", label: "Coil"},
  {value: "Carton", label: "Carton"},
  {value: "Cylinder", label: "Cylinder"},
  {value: "Drum", label: "Drum"},
  {value: "Keg", label: "Keg"},
  {value: "Piece(s)", label: "Piece(s)"},
  {value: "Package", label: "Package"},
  {value: "Pail", label: "Pail"},
  {value: "Pallet", label: "Pallet"},
  {value: "Rack", label: "Rack"},
  {value: "Reel", label: "Reel"},
  {value: "Roll", label: "Roll"},
  {value: "Skid", label: "Skid"},
  {value: "Tube", label: "Tube"},
  {value: "Trailer", label: "Trailer"},
  {value: "Tote", label: "Tote"},
  {value: "Trunk", label: "Trunk"},
  {value: "Crate", label: "Crate"}];
  const [quickBookRequired, setQuickBookRequired] = useState({
    shiCompanyName: true,
    shiCompanyName_ErrorClass: 'form-control',
    shiCompanyNameAc_ErrorClass: 'form-control',
    shiAddress: true,
    shiAddress_ErrorClass: 'form-control',
    shiCity: true,
    shiCity_ErrorClass: 'form-control',
    //shiState: true,
    //shiState_ErrorClass: 'text-danger d-none',
    shiZipcode:true,
    shiZipcode_ErrorClass: 'form-control',
    consiCompanyName: true,
    consiCompanyName_ErrorClass: 'form-control',
    consiCompanyNameAc_ErrorClass: 'form-control',
    consiAddress: true,
    consiAddress_ErrorClass: 'form-control',
    consiCity: true,
    consiCity_ErrorClass: 'form-control',
    //consiState: true,
    //consiState_ErrorClass: 'text-danger d-none',
    consiZipcode:true,
    consiZipcode_ErrorClass: 'form-control',
    pickupInfo: true,
    pickupInfo_ErrorClass: 'form-control',
    piReadyTime: true,
    piReadyTime_ErrorClass: 'form-control',
    piCloseTime: true,
    piCloseTime_ErrorClass: 'form-control',
    deliInfo: true,
    deliInfo_ErrorClass: 'form-control',
    deliReadyTime: true,
    deliReadyTime_ErrorClass: 'form-control',
    deliCloseTime: true,
    deliCloseTime_ErrorClass: 'form-control',
    hawb: true,
    hawb_ErrorClass: 'form-control',
    additionalEmails:true,
    additionalEmails_ErrorClass: 'form-control',
    deliveryAccessorials: true,
    pickupAccessorials:true,
    deliveryAccessorials_ErrorClass : 'form-control',
    pickupAccessorials_ErrorClass : 'form-control',
    // mawb: true,
    // mawb_ErrorClass: 'form-control',
    // refNo: true,
    // refNo_ErrorClass: 'form-control'
  });

  const onConfirmBooking = () => {
    const copy = { ...quickBookRequired };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
     // console.log(key,value)
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = {};
       
        if (value && quickBook[key]) {
          errState = { [key]: false, [errname]: 'form-control is-valid' };
        } else {
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
          status=true;
          console.log("key-",key,"value-",quickBook[key])
        }
        Object.assign(errObject, errState);
      }
    })
   
      setQuickBookRequired({ ...quickBookRequired, ...errObject });
    return status;
  }

  const validateAddress = (type) => {
    let copy;
    if(type === "Ship"){
      copy ={  shiCompanyName: quickBookRequired.shiCompanyName,
        shiAddress: quickBookRequired.shiAddress,
        shiCity: quickBookRequired.shiCity,
        //shiState: quickBookRequired.shiState,
        shiZipcode: quickBookRequired.shiZipcode, 
      }
    }else if(type === "Consi"){
      copy = {
        consiCompanyName: quickBookRequired.consiCompanyName,
    consiAddress: quickBookRequired.consiAddress,
    consiCity: quickBookRequired.consiAddress,
    //consiState: quickBookRequired.consiState,
    consiZipcode: quickBookRequired.consiZipcode, 
      }
    }

    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
      var errState = null;//{ [key]: true, [errname]: 'form-control is-invalid' };
    Object.entries(copy).forEach(([key, value]) => {
      var errname = key + "_ErrorClass";
      var errState = {};//{ [key]: true, [errname]: 'form-control is-invalid' };
      if (value && quickBook[key]) {
        errState = { [key]: true, [errname]: 'form-control is-invalid' };
        status=true;
      } else {
        errState = { [key]: false, [errname]: 'form-control is-valid' };
      }
      Object.assign(errObject, errState);
        /*if( errname==="shiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="consiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="shiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'shiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else if( errname==="consiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'consiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else{
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }*/
      //  status = true;
       // Object.assign(errObject, errState);
        // console.log("address Error obj "+JSON.stringify(errObject));
      //}
    })
    //if (status) {
      setQuickBookRequired({ ...quickBookRequired, ...errObject });
    
    return status;
  }

  const onValidateBookHandler = (name, value) => {
    var errState = {};
    var status = false;
    Object.entries(quickBookRequired).forEach(([key]) => {
      var errname = key + "_ErrorClass";
      if (key === name && value) {
        errState = { [name]: true, [errname]: 'form-control is-invalid' };
        status = true;
      } else if (key === name && !value) {
        errState = { [name]: false, [errname]: 'form-control is-valid' };
        status = true;
      }
    })
    if (status)
      setQuickBookRequired({ ...quickBookRequired, ...errState });
    //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
  }

  const updateQuoteRequiredState = (newObject) => {
    
    // console.log("newObj="+JSON.stringify(newObject));
    setQuickBookRequired({ ...quickBookRequired, ...newObject });
  }

  
  const lineHaulServiceTypeList = [{ value: "1", label: "Terminal to Door" },
    { value: "2", label: "Door to Door" },
    { value: "3", label: "Door to Terminal" },
    // { value: "4", label: "Terminal to Terminal" }
    ];

    const setRoadRunnerTermDetailsList = (lineHaul) => {
      setRoadRunnerTermDetails(lineHaul);
    }
  
    const setLineHualTermDetailsList = (lineHaul) => {
      setLineHaulTermDetails(lineHaul);
    }
  
    const setfffTermDetailsList = (carriers) => {
      setfffTermDetails(carriers);
    }
    const setAccessorialDetailsList = (accessorial) => {
      setAccessorialDetails(accessorial);
    }
  
  return {
    onChangeBookHandler,
    updateMutipleValues,
    onConfirmBooking,
    onValidateBookHandler,
    updateQuoteRequiredState,
    
    validateAddress,
    updateQuickBookObject,
    setRoadRunnerTermDetailsList,setLineHualTermDetailsList,setfffTermDetailsList,setAccessorialDetailsList,toggleTerminals,
    onChangeDimTotal,
    onValidateDimTotal,
    onSubmitTotDimensions,
    updateDimTotals,
    updateTotalDimensionsState,
    updateTotalDimensionsReqState,
    clearDimTotals,
    quoteTotalDim,
    totalDimRequired,
    quickBook,
    stateList,
    quickBookRequired,
    packageTypeList,
    labelStyleList,
    labelCountList,
    lineHaulServiceTypeList,
    fffTermDetails,roadRunnerTermDetails,lineHaulTermDetails,accessorialDetails
  }
}
export default DataBooknow;