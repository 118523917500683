import { useEffect, useState } from 'react';
import {userLookUpByUserName,userLookUpByCustCode} from '../../../restAPI/UserAPI';
import { useSelector } from 'react-redux';
const DataUserLookup = () => {
    const  [filtersInput, setFiltersInput] = useState({
        userLookupInput : '',
        showTable: false
    });

    const [filtersReq,setFiltersReq] = useState({
        userLookupInput:    true,
        userLookupInput_ErrorClass : 'form-control'
    });

    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);

    const onSubmitSearch = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";
                var errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        }else{
            setFiltersInput({...filtersInput,showTable: false});
            loadReportData(filtersInput.userLookupInput);
           // console.log(filtersInput.userLookupInput);
        }
            
    }

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = "form-control";
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }
      const userProfileData = useSelector((state) => state.usr)
    const loadReportData = (userCustCode) => {
        setShowDataLoading(true);

         if(!userCustCode)
         userCustCode = userProfileData.custCode;
         else
         userCustCode = userCustCode;
        userLookUpByCustCode(userCustCode)
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            })
            .catch(err=>{
            setReportData([]);
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            })
    }

    const handleInputChange = (name, value) => {
        setFiltersInput({...filtersInput, [name]: value});
    }

    // useEffect(() => {
    //     setShowDataLoading(true);
    //     loadReportData();
    // }, [])
    

    return {
        handleInputChange,
        onSubmitSearch,
        onValidateFilters,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading
    }
}

export default DataUserLookup;