import React,{useEffect} from "react";
import { copyStyles,extractStyles } from '../../../utils/ValidationScripts';
import DataSales from  './Data/DataSales'
import SalesSopData from "./SalesSopData";


const SalesSopReport = () => {
    const { sopData, sopReq, sopList, handleInputChange, updateSOPObject, updateSOPList, createNewSOP, onValidateSop, onSubmitSopDetails, clearFormData, clearFormCss } = DataSales();
  
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const handlePrintClick = () => {
        const printableElements = document.getElementById('print-area').innerHTML;
        var w = window.open('', "_blank");
        let cssStyles = extractStyles(window.document);
        const orderHtml = `<html><head><title></title>` +cssStyles+ `</head><body>` + printableElements + `</body></html>`
          w.document.write(orderHtml);
            setTimeout(function() {
                    w.window.print();
                    w.window.focus();
                    w.window.close();  
            }, 1000);    
      };
    const sopReport = () =>{
        let sales=[];
        sopList.forEach(sop => {
            sales.push( <SalesSopData key={sales.length+1} sop={sop} sopReq={sopReq} onChangeSop={handleInputChange} editSOP={false} newSOP={false} onValidateSop={onValidateSop} />);
            sales.push(<div key={sales.length+100}><div className="row mb-2 pagebreak"> </div> <hr /> <h4 className="mb-2 pageHead text-uppercase text-center">FREIGHT FORCE CUSTOMER SOP </h4> <hr /> </div>);
          })
        return sales;
    }
    return (

        <div className="card card-custom">
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase"></h4>
                        <button className="btn btn-secondary pull-center ml-auto" type="button"  onClick={handlePrintClick}>
                        <i className='fa fa-print'></i> Print</button> 
                </div>
         
            {/* <div className={sopStatus ?'alert alert-danger text-center':'alert alert-success text-center'}>
                 <h6>{sopErrorMessage} </h6>
            </div> */}
            <div id="print-area">
            {/* <hr /> <h4 className="mb-2 pageHead text-uppercase text-center">FREIGHT FORCE CUSTOMER SOP </h4> <hr />
            {sopReport()} */}
            {
              sopList.map((sop, index) => {
                return (
                  <div key={index}>
                    <hr /> <h4 className="mb-2 pageHead text-uppercase text-center">FREIGHT FORCE CUSTOMER SOP </h4> <hr />
                    <SalesSopData key={index} sop={sop} sopReq={sopReq} onChangeSop={handleInputChange} editSOP={false} newSOP={false} onValidateSop={onValidateSop} />
                    <div className="row mb-2 pagebreak"> </div>
                  </div>
                )
              })
            }
            </div>
        </div>
         
        
    );
}

export default SalesSopReport;