import { useState, useEffect } from 'react';

const DataAutoFill = () => {
    //console.log("DataAutoFill");
    
    const  [initialInputs] = [{
        companyName: '',
        email: '',
        phone: '',
        typeOfMove: "1",
        typeOfMoveName: "",
        serviceType: "1",
        serviceTypeName: "",
        lineHaul: "localpu",
        localpud: true,
        lineHaulLh: false,
        roadRunner: false,
        lineHaulServiceType: "1",
        lineHaulServiceTypeName: "",
        lineHaulServiceDisable: true,
        additionalEmails: ''

    }];
    const [initialRequired] = useState({

        typeOfMove: true,
        typeOfMove_ErrorClass: 'form-control',
        serviceType: true,
        serviceType_ErrorClass: 'form-control',
        additionalEmails_ErrorClass: 'form-control',
        //lineHaul:true,
        //lineHaul_ErrorClass:'form-control',
    });

    const [quotePreFill, setQuotePreFil] = useState(initialInputs);
    const [preFillRequired, setPreFillRequired] = useState(initialRequired);

    //    const quoteFormKey = [ "typeOfMove","serviceType","lineHaul","lineHaulServiceType","freightForceFacility","companyName","email","phone","shipFrom","shipTo","pickupInfo","deliveryInfo","specialPickup","specialDelivery","totalPcs","grossWeight","dimWeightTotal","accessorial","notes","hazardouesMaterial"];

    const typeOfMoveList = [ { value: "1", label: "Pickup & Drop" },
    { value: "2", label: "Recover & Delivery" },
    { value: "7", label: "Pickup & Delivery" },
    ];

    // { value: "1", label: "Pickup" },
    // { value: "2", label: "Delivery" },
    // { value: "7", label: "Pickup & Delivery" },
   

    const serviceTypeList = [{ value: "1", label: "Airport Drop/Recovery" },
    { value: "2", label: "CFS Drop/Recovery" },
    { value: "3", label: "Commercial" },
    { value: "4", label: "Commercial Inside" },
    { value: "5", label: "Commercial White Glove" },
    { value: "6", label: "Convention Center" },
    // { value: "7", label: "Exclusive Use- Same Day" },
    { value: "8", label: "Ground Service Provider Drop/Recovery" },
    // { value: "9", label: "Hot Shot Service" },
    { value: "10", label: "Residential Inside" },
    { value: "11", label: "Residential Threshold" },
    { value: "12", label: "Residential White Glove" },
    { value: "13", label: "Tradeshow" },
    ];
    const lineHaulServiceTypeList = [{ value: "1", label: "Terminal to Door" },
    { value: "2", label: "Door to Door" },
    { value: "3", label: "Door to Terminal" },
    // { value: "4", label: "Terminal to Terminal" }
    ];
    const packageTypeList = [{value: "Bag", label: "Bag"},
    {value: "Barrel", label: "Barrel"},
    {value: "Bundle", label: "Bundle"},
    {value: "Bucket", label: "Bucket"},
    {value: "Bale", label: "Bale"},
    {value: "Basket", label: "Basket"},
    {value: "Bulkhead", label: "Bulkhead"},
    {value: "Box", label: "Box"},
    {value: "Chest", label: "Chest"},
    {value: "Coil", label: "Coil"},
    {value: "Carton", label: "Carton"},
    {value: "Cylinder", label: "Cylinder"},
    {value: "Drum", label: "Drum"},
    {value: "Keg", label: "Keg"},
    {value: "Piece(s)", label: "Piece(s)"},
    {value: "Package", label: "Package"},
    {value: "Pail", label: "Pail"},
    {value: "Pallet", label: "Pallet"},
    {value: "Rack", label: "Rack"},
    {value: "Reel", label: "Reel"},
    {value: "Roll", label: "Roll"},
    {value: "Skid", label: "Skid"},
    {value: "Tube", label: "Tube"},
    {value: "Trailer", label: "Trailer"},
    {value: "Tote", label: "Tote"},
    {value: "Trunk", label: "Trunk"},
    {value: "Crate", label: "Crate"}];

    const onChangePreFill = (name, value) => {
        var updateValues = { [name]: value };
        //console.log(name[0].trim()+"="+value);    
        if (name[0].trim() === "lineHaul" ){
           // console.log("onChangePrefill is called lineHaul is =",value);    
            updateValues = { [name]: value };
            if(value === "lineHaul"){
                updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": false ,localpud: false, lineHaulLh: true,roadRunner: false});
            }else if(value === "localpu"){
                updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": true ,localpud: true, lineHaulLh: false,roadRunner: false});
            }else if(value === "NH"){
                updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": false ,localpud: false, lineHaulLh: false,roadRunner: true});
            }
        }
        
        /*
        if (name[0].trim() === "lineHaul" && value) {
            //if( value === "true" ){
                updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": false });
            // }else if( value === "false" ){
            //     updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": true });
            // }
        } else if (name[0].trim() === "lineHaul" && !value) {
            updateValues = Object.assign(updateValues, { "lineHaulServiceType": "1", "lineHaulServiceDisable": true });
        }
        */
        setQuotePreFil({ ...quotePreFill, ...updateValues });
    }
    const onValidatePreFill = (name, value) => {
        var errState = {};
        var status = false;
        Object.entries(preFillRequired).forEach(([key]) => {
            var errname = key + "_ErrorClass";
            if (key === name && value) {
                errState = { [name]: true, [errname]: 'form-control is-invalid' };
                status = true;
            } else if (key === name && !value) {
                errState = { [name]: false, [errname]: 'form-control is-valid' };
                status = true;
            }
        })
        if (status)
            setPreFillRequired({ ...preFillRequired, ...errState });
        //console.log(preFillRequired);
    }

   /* const onSubmitAutoFill = () => {
        const copy = { ...preFillRequired };
        var status = false;
        let errObject = {};


        //console.log("Copy ofautofill"+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if (!key.includes("_ErrorClass") && value) {
                var errname = key + "_ErrorClass";
                var errState = { [key]: true, [errname]: 'form-control is-invalid' };
                status = true;
                Object.assign(errObject, errState);
                // console.log("Autofill Error obj "+JSON.stringify(errObject));
            }
        })
        if (status) {
            setPreFillRequired({ ...preFillRequired, ...errObject });
            // console.log("Copied  autofill "+JSON.stringify(preFillRequired));
        }
    }
*/
    useEffect(() => {
        let updateValues = {};
        let result={};
        //console.log("quotePreFill.lineHaul=",quotePreFill.lineHaul);
        if ((quotePreFill.lineHaul === "lineHaul" || quotePreFill.lineHaul === "NH") && quotePreFill.lineHaulServiceType) {
            result = lineHaulServiceTypeList.find(item => item.value === quotePreFill.lineHaulServiceType);
            updateValues = Object.assign(updateValues, { "lineHaulServiceTypeName": result.label });
        } else {
            updateValues = Object.assign(updateValues, { "lineHaulServiceTypeName": "" });
        }
        if (quotePreFill.typeOfMove) {
             result = typeOfMoveList.find(item => item.value === quotePreFill.typeOfMove);
            updateValues = Object.assign(updateValues, { "typeOfMoveName": result.label });
        }
        if (quotePreFill.serviceType) {
             result = serviceTypeList.find(item => item.value === quotePreFill.serviceType);
            updateValues = Object.assign(updateValues, { "serviceTypeName": result.label });
        }

        setQuotePreFil({ ...quotePreFill, ...updateValues });
    }, [quotePreFill.lineHaulServiceType, quotePreFill.lineHaul, quotePreFill.typeOfMove, quotePreFill.serviceType]);// eslint-disable-line



    const updatePreFillState = async (data) => {
        //console.log("quotePreFill="+JSON.stringify(data));
        setQuotePreFil({ ...quotePreFill, ...data });
    }

    const updatePreFillReqState = async (data) => {
        //console.log("quotePreFillReq="+JSON.stringify(data));
        setPreFillRequired({ ...preFillRequired, ...data });
    }

    const clearPreFill = () => {
        setQuotePreFil(initialInputs);
        setPreFillRequired(initialRequired);
    }

    return {
        onChangePreFill,
        onValidatePreFill,
        updatePreFillState,
        updatePreFillReqState,
        clearPreFill,
        quotePreFill,
        preFillRequired,
        typeOfMoveList,
        serviceTypeList,
        lineHaulServiceTypeList,
        packageTypeList,
    }




}
export default DataAutoFill;