import React, { useEffect } from 'react'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { buildAccessorialCharges } from './TariffTabsScripts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const AccessorialCharges = (props) => {
    const acData = props.surchargeRate;
    props.setTabsHtmlData( 3, buildAccessorialCharges(acData) );

    useEffect(() => {
        let table = $("#accTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : acData,
            "dom": 
                "<'row'<'col-lg-3 col-md-4'l><'col-lg-5 col-md-8 text-right'B><'col-lg-4 col-md-6 offset-lg-0 offset-md-6'f>>" +
                "<'row'<'col-lg-12'tr>>" +
                "<'row'<'col-md-5'i><'col-md-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'AccessorialCharges',
                  title: 'Accessorial Charges',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'AccessorialCharges',
                  title: 'Accessorial Charges',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'AccessorialCharges',
                  title: 'Accessorial Charges',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'AccessorialCharges',
                  title: 'Accessorial Charges',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                { "data" : "Service_Description" },
                { "data" : "Min" },
                { "data" : "Rate" },
                { "data" : "surtype" }
            ],
            "columnDefs": [{
                targets: 0,
                width: "50%"
            }, {
                targets: 1,
                width: "17%",
                className: "text-center"
            }, {
                targets: 2,
                width: "17%",
                className: "text-center"
            }, {
                targets: 3,
                width: "16%",
                className: "text-center"
            }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[acData]);

  return (
    <div className="row mb-4">
        <div className="col-md-10 offset-1">
            <div id="dataAcctable" className='mt-4'>
            <table id="accTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Service Description</th>
                        <th>Min</th>
                        <th>Rate</th>
                        <th>Calculated by</th>
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
            </table>
            </div>
        </div>
    </div>	
  )
}

export default AccessorialCharges