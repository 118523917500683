import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, removeUser, validateUser } from '../../store/actions/actionTypes'
//import { Navigate } from 'react-router-dom';
import { getLocalStorageOrDefault } from '../utils/ValidationScripts';

import { login } from '../../components/services/restAPI/UserAPI';
import DataProfile from '../../components/services/quickQuote/Data/DataProfile';
import { Navbar } from 'react-bootstrap';


const TopBar = () => {

  const dispatch = useDispatch()

  const { userProfile, updateUserProfile, updateUserProfileObject } = DataProfile();

  const navigate = useNavigate();

  const userSession = getLocalStorageOrDefault("userProfile", {});

  // console.log("userSession="+JSON.stringify(userSession));

  const [data, setData] = useState({
    lusername: '',
    lpassword: '',
    isValid: false,
    lToken: ''
  });

  /*
      useEffect(() => {
          let mounted = true;
                if (mounted) {
                  if(userSession.user){
                      setData({
                          lusername : userSession.username || '' ,
                          lpassword : '',
                          isValid : userSession.isValid ? true : false,
                          lToken : userSession.token || ''
                      })
                  }
                }
             
            return () => mounted = false;
        
        }, [])
        */

  //const userProfile = useSelector((state) => state.usr)
  //console.log("value fo redux "+userProfile.userName); //"--"+pass+" -- "+token+" -- "+isLogin)

  //console.log("data="+JSON.stringify(data))

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    lusername: '',
    lpassword: ''
  });
  const [errorCss, setErrorCss] = useState({
    lusername: '',
    lpassword: ''
  });

  const handleSignUp = () =>{

    navigate("/signUp");
  }
  const recoverPass = () =>{
    navigate("/recoverPass");
  }
  const changePassword = () =>{
    navigate("/changePassword");
  }
  
  const handleInputChange = (e) => {
    let id = e.target.name;
    let val = e.target.value;
    setData({ ...data, [id]: val });

    //if( Object.keys(errors).includes(id) ){
    if (val === '') {
      errorCss[id] = 'is-invalid';
    } else {
      errors[id] = '';
      errorCss[id] = ''; //is-valid
    }
    //}
  }

  const setInvalid = () => {
    setErrors({
      lusername: 'Invalid Username',
      lpassword: 'Invalid Password'
    });
    setErrorCss({
      lusername: 'is-invalid',
      lpassword: 'is-invalid'
    });
  }

  const clearForm = () => {
    setData({
      lusername: '',
      lpassword: '',
      isValid: false,
      lToken: ''
    });
    setErrors({
      lusername: '',
      lpassword: ''
    });
    setErrorCss({
      lusername: '',
      lpassword: ''
    });
  }

  const handleFormSubmit = () => {
    let _errors = {}, _errorCss = {};
    let lusername = data.lusername;
    let lpassword = data.lpassword;

    if (!lusername) {
      _errors.lusername = "Required";
      _errorCss.lusername = 'is-invalid';
    }
    if (!lpassword) {
      _errors.lpassword = "Required";
      _errorCss.lpassword = 'is-invalid';
    }

    if (Object.keys(_errors).length === 0) {
      setIsLoading(true);
      login(lusername, lpassword, updateUserProfileObject)
        .then(response => {
          //console.log("userlogin Error response " + JSON.stringify(response))
          if (response instanceof Error) {
            //console.log("userlogin Error response " + JSON.stringify(response))
         setInvalid();
          } else {
            if (!response.user.isValid) {
              setInvalid();
            //  console.log("!isvalid ");
            } else {
              clearForm();
              //setIsLoading(true);
              //updateUserProfileObject(response);
            //  console.log("true ");
              // updateUserProfileObject(response);
              //  console.log("After update the response to userData "+JSON.stringify(userProfile))
              var respUserProfile = response.user;
              respUserProfile = Object.assign(respUserProfile, { "token": response.token })
              // console.log("After response "+JSON.stringify(response.user))
              updateUserProfileObject(respUserProfile);
              dispatch(validateUser(respUserProfile));
              navigate("/welcome");
              
            }
          }
        }).catch(error =>{
          setInvalid();
          if(error.response.status!==401)
            navigate("/maintenance");
        })
        .finally(r => {
          setIsLoading(false);
        })

      //if( Object.keys(errors).length===0 ){
    } else {
      setErrors(_errors);
      setErrorCss(_errorCss);
    }
  }

  const userProf = useSelector((state) => state.usr)
  const isValid = userProf.isValid;
  const userToken = isValid;


  const logout = () => {
      dispatch(removeUser());
      dispatch(logoutUser())
  }

  useEffect(() => {
    //"use strict";

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }
    /**
   * Easy event listener function
   */
    //  const on = (type, el, listener, all = false) => {
    //   let selectEl = select(el, all)
    //   if (selectEl) {
    //     if (all) {
    //       selectEl.forEach(e => e.addEventListener(type, listener))
    //     } else {
    //       selectEl.addEventListener(type, listener)
    //     }
    //   }
    // }
  
    /**
     * Mobile nav toggle
     */
    // on('click', '.mobile-nav-toggle', function(e) {
    //   select('#navbar').classList.toggle('navbar-mobile')
    //   this.classList.toggle('fas-bars')
    //   this.classList.toggle('fa-x')
    // })
    // on('click', '.lev1, .last-level-menu', function(e) {
    //   // select('#navbar').classList.contains('navbar-mobile')    
    //   if (select('#navbar').classList.contains('navbar-mobile')) {
    //     //  e.preventDefault()
    //       let navbar = select('.navbar')
    //        navbar.classList.remove('navbar-mobile')
    //       let navbarToggle = select('.mobile-nav-toggle')
    //        navbarToggle.classList.toggle('fa-x')
    //        navbarToggle.classList.toggle('fas-bars')
    //      }
    //  }, true)
    //     /**
    //  * Mobile nav dropdowns activate
    //  */
    // on('click', '.navbar ul li.dropdown a.dropdown-toggle', function(e) {
    //  // on('click', '.listing', function(e) {
    //   //if (select('#navbar').classList.contains('navbar-mobile')) {
    //     //e.preventDefault()
    //     this.nextElementSibling.classList.toggle('dropdown-active')
      
    // //  }
    // }, true)
   
  }, 
  
  [])


  return (
    // ======= Top Bar ======= 
   
    <div id="topbar" className="fixed-top d-flex align-items-center ">
      <div className="container d-flex justify-content-end" >
        {/* d-flex  */}
        {userToken ? 
        <>
          <div className="contact-info d-flex align-items-center">
            <span className=''>
              Welcome back to
              <b> {userProf.username} </b>
              &nbsp;|&nbsp;
              <Link to="/" onClick={logout} >Logout</Link>
              &nbsp; &nbsp; <label style={{cursor: 'pointer'}} onClick={changePassword} >Change Password</label>
            </span> 
            </div>
            </>
            : 
            <div className="contact-info d-flex align-items-center">
        <form className="needs-validation" name="loginForm"  >
          <div className="row">
            <div className="col col-sm-3 col-md-4">
              <input type="text" maxLength={30} name='lusername' id="lusername" onChange={handleInputChange} placeholder='Username'
                className={`form-control ${errorCss.lusername}`} alt={errors.lusername} title={errors.lusername}
                required />
              {/* {errors.lusername && <div className='text-danger text-login-err-msg pl-2'> {errors.lusername} </div>} */}
            </div>
            <div className="col col-sm-3 col-md-4 pl-1 mb-2 mb-md-0">
              <input type="password" maxLength={30} id="lpassword" name='lpassword' onChange={handleInputChange} placeholder='Password'
                className={`form-control ${errorCss.lpassword} `} alt={errors.lpassword} title={errors.lpassword}
                required />
              {/* {errors.lpassword && <div className='text-danger text-login-err-msg pl-2'> {errors.lpassword} </div>} */}
            </div>
            <div className="col col-sm-3 col-md-2 pl-1 mb-2 mb-md-0">
              <button className="btn btn-primary btn-block" type="button" onClick={handleFormSubmit}>
                {isLoading && <i className='fas fa-spinner fa-spin'></i>  }
                Login
                </button>
            </div>
            <div className="col col-sm-3 col-md-2 pl-1">
              <button className="btn btnSignUp btn-block" onClick={handleSignUp} type="button">Signup</button>
            </div>
          </div>
          <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4"></div>
          <div className="col-sm-4"  ><label className="QuoteInfo" style={{cursor: 'pointer', marginTop:'2px'}} onClick={recoverPass}>Forgot Password</label></div>
          </div>
        </form>
        </div>
        }
       
        </div>
       
    </div>
    
  );
};

export default TopBar;