import { useEffect, useState } from 'react';
import { useSelector,useDispatch } from "react-redux";
import {getCarrierInformation,updateCarrierInformation} from "../../../restAPI/UtilsRest";
import { coverageData } from '../../../../../store/actions/actionTypes';
const DataEditCoverage = () => {
    const stateList = [{value: "AL", label: "Alabama - (AL)"},
    {value: "AK", label: "Alaska - (AK)"},
    {value: "AZ", label: "Arizona - (AZ)"},
    {value: "AR", label: "Arkansas - (AR)"},
    {value: "CA", label: "California - (CA)"},
    {value: "CO", label: "Colorado - (CO)"},
    {value: "CT", label: "Connecticut - (CT)"},
    {value: "DE", label: "Delaware - (DE)"},
    {value: "DC", label: "District of Columbia - (DC)"},
    {value: "FL", label: "Florida - (FL)"},
    {value: "GA", label: "Georgia - (GA)"},
    {value: "HI", label: "Hawaii - (HI)"},
    {value: "ID", label: "Idaho - (ID)"},
    {value: "IL", label: "Illinois - (IL)"},
    {value: "IN", label: "Indiana - (IN)"},
    {value: "IA", label: "Iowa - (IA)"},
    {value: "KS", label: "Kansas - (KS)"},
    {value: "KY", label: "Kentucky - (KY)"},
    {value: "LA", label: "Louisiana - (LA)"},
    {value: "ME", label: "Maine - (ME)"},
    {value: "MD", label: "Maryland - (MD)"},
    {value: "MA", label: "Massachusetts - (MA)"},
    {value: "MI", label: "Michigan - (MI)"},
    {value: "MN", label: "Minnesota - (MN)"},
    {value: "MS", label: "Mississippi - (MS)"},
    {value: "MO", label: "Missouri - (MO)"},
    {value: "MT", label: "Montana - (MT)"},
    {value: "NE", label: "Nebraska - (NE)"},
    {value: "NV", label: "Nevada - (NV)"},
    {value: "NH", label: "New Hampshire - (NH)"},
    {value: "NJ", label: "New Jersey - (NJ)"},
    {value: "NM", label: "New Mexico - (NM)"},
    {value: "NY", label: "New York - (NY)"},
    {value: "NC", label: "North Carolina - (NC)"},
    {value: "ND", label: "North Dakota - (ND)"},
    {value: "OH", label: "Ohio - (OH)"},
    {value: "OK", label: "Oklahoma - (OK)"},
    {value: "OR", label: "Oregon - (OR)"},
    {value: "PA", label: "Pennsylvania - (PA)"},
    {value: "PR", label: "Puerto Rico - (PR)"},
    {value: "RI", label: "Rhode Island - (RI)"},
    {value: "SC", label: "South Carolina - (SC)"},
    {value: "SD", label: "South Dakota - (SD)"},
    {value: "TN", label: "Tennessee - (TN)"},
    {value: "TX", label: "Texas - (TX)"},
    {value: "UT", label: "Utah - (UT)"},
    {value: "VT", label: "Vermont - (VT)"},
    {value: "VA", label: "Virginia - (VA)"},
    {value: "VI", label: "Virgin Islands - (VI)"},
    {value: "WA", label: "Washington - (WA)"},
    {value: "WV", label: "West Virginia - (WV)"},
    {value: "WI", label: "Wisconsin - (WI)"},
    {value: "WY", label: "Wyoming - (WY)"},
    ];
    const data = useSelector((state) => state.coverage);
    const dispatch = useDispatch();
    const [filtersInput, setFiltersInput] = useState({
        station_code:'',
        station_id:'',
        station_name:'',
        address : '',
        csz : '',
        region : '',
        phone : '',
        fax :'',
        manager_name:'',
        email_address:'',
        emerg_phone:'',
        longitude:0,
        latitude :0,
        showStatus : false,
        statusDesc : '',
        errorStatus:false,
    });
    const [initialData] = [{
        "station_code":'',
        "station_id":'',
        "station_name":'',
        "address" : '',
        "csz" : '',
        "region" : '',
        "phone" : '',
        "fax" :'',
        "manager_name":'',
        "email_address":'',
        "emerg_phone":'',
        "longitude":0,
        "latitude" :0,
    }];
    
    const [filtersInputData, setFiltersInputData] = useState(initialData);
    const [filtersInputRequired, setFiltersInputRequired] = useState({
        station_code:true,        
        region : true,
        manager_name:true,
        email_address:true,
        emerg_phone:true,
        longitude:true,
        latitude :true,
        station_code_ErrorClass: 'form-control',
        region_ErrorClass:  'form-control',
        manager_name_ErrorClass: 'form-control',
        email_address_ErrorClass: 'form-control',
        emerg_phone_ErrorClass: 'form-control',
        longitude_ErrorClass: 'form-control',
        latitude_ErrorClass:  'form-control',
    });


    const [loading, setLoading] = useState(false);
    const [showDataLoading, setShowDataLoading] = useState(false);

    
    const handleSearch = () =>{
      var  addrObj=data.find(({ station_id }) => station_id === filtersInput.station_code.toUpperCase());
        //console.log("seach object is "+JSON.stringify(addrObj));
        setFiltersInput({ ...filtersInput, ...addrObj });
        var errState = {};
        var status = false;
        Object.entries(addrObj).forEach(([key,value]) => {
            Object.entries(filtersInputRequired).forEach(([errkey]) => {
              var errname = errkey + "_ErrorClass";
              if (key === errkey && value) {
                Object.assign(errState,{ [errkey]: false, [errname]: 'form-control is-valid' })
              } else if (key === errkey && !value) {
                Object.assign(errState,{ [errkey]: true, [errname]: 'form-control is-invalid' })
              }
            })
        });
        setFiltersInputRequired({ ...filtersInputRequired, ...errState });
        //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
    }


    const handleInputChange = (name, value) => {
            setFiltersInput({ ...filtersInput, [name]: value });
    }

    const updateAddress = () => {
        const copy = { ...filtersInputRequired };
        var errState = {};
        var status = false;
        Object.entries(filtersInput).forEach(([key,value]) => {
            Object.entries(filtersInputRequired).forEach(([errkey]) => {
              var errname = errkey + "_ErrorClass";
              if (key === errkey && value) {
                Object.assign(errState,{ [errkey]: false, [errname]: 'form-control is-valid' })
              } else if (key === errkey && !value) {
                status = true;
                Object.assign(errState,{ [errkey]: true, [errname]: 'form-control is-invalid' })
              }
            })
        });
        setFiltersInputRequired({ ...filtersInputRequired, ...errState });
    
    if(!status){
        updateCarrierInformation(filtersInput).then(reponseDate => {
            if (reponseDate instanceof Error) {
                console.log("Error in loading updateCarrierInformation")
                setFiltersInput({ ...filtersInput, statusDesc: reponseDate})   
            } else {
                if(reponseDate.includes("susccess")){
                 setFiltersInput({ ...filtersInput, statusDesc: reponseDate})   
                }
               // console.log("data updateCarrierInformation"+JSON.stringify(reponseDate))
               getData();
               setTimeout(() => {
                setFiltersInput({ ...filtersInput, statusDesc: ''})   
              }, 5000);
            }
        })
        .catch(err => {
            //setData(err);
            console.log("Error in loading updateCarrierInformation")
        })
    }
        }
    const resetErrStatus = () => {
        setFiltersInput({ ...filtersInput, errorStatus: false, showStatus: false,"statusDesc" :""});
    }

    const getData = () => {
        getCarrierInformation().then(reponseDate => {
            if (reponseDate instanceof Error) {
                console.log("Error in loading getCarrierInformation")
            } else {
               // console.log("data getCarrierInformation"+JSON.stringify(reponseDate))
               dispatch(coverageData(reponseDate));
            }
        })
        .catch(err => {
            //setData(err);
            console.log("Error in loading getCarrierInformation")
        })
        }
        const onValidateCoverage = (name, value) => {
            var errState = {};
            var status = false;
            Object.entries(filtersInputRequired).forEach(([key]) => {
              var errname = key + "_ErrorClass";
              if (key === name && value) {
                errState = { [name]: true, [errname]: 'form-control is-invalid' };
                status = true;
              } else if (key === name && !value) {
                errState = { [name]: false, [errname]: 'form-control is-valid' };
                status = true;
              }
            })
            if (status)
            setFiltersInputRequired({ ...filtersInputRequired, ...errState });
            //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
          }
        
    return {
        handleInputChange,
        resetErrStatus,
        handleSearch,
        updateAddress,
        onValidateCoverage,
        filtersInput,
        filtersInputRequired,
        loading,
        stateList,
    }
}

export default DataEditCoverage;