import React, { useEffect } from 'react'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { buildAirportTransferCharges } from './TariffTabsScripts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const AirportTransfer = (props) => {
    const atData = props.airportTransferRate;
    props.setTabsHtmlData( 4, buildAirportTransferCharges(atData) );

    useEffect(() => {
        let table = $("#airportTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : '',//atData, // bcz of client data is worng so want to show static values
            "dom": 
                "<'row'<'col-lg-3 col-md-4'l><'col-lg-5 col-md-8 text-right'B><'col-lg-4 col-md-6 offset-lg-0 offset-md-6'f>>" +
                "<'row'<'col-lg-12'tr>>" +
                "<'row'<'col-md-5'i><'col-md-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'AirportTransfer',
                  title: 'Airport Transfer',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'AirportTransfer',
                  title: 'Airport Transfer',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'AirportTransfer',
                  title: 'Airport Transfer',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'AirportTransfer',
                  title: 'Airport Transfer',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
//These are other data received from service
            //"AREAFROM": "LAX",
			//"WEIGHTBREAKVALUE": 100.0,
			//"WEIGHTAMOUNT": 2.0,
			//"CAPBREAKVALUE": 1.0,
            "language": {
                "emptyTable": "(See Airport Drop and Recovery Rate On Accessorial Charges Tab)"
              },

            "columns": [
                { "data" : "MINAMOUNT" },
                { "data" : "WEIGHTAMOUNT" , "render": function (data, type, row) {
                    var display = '';
                    //var anchorUrl = reporturlVal+"&cfsCode="+cfsCode+"&fileNo="+row["fileNo"];
                    var lab = '<label class="tutorialTextRed" >'+display+' </label>';
                    return lab;
                  }},
                { "data" : "CAPAMOUNT" }
            ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "33.5%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[atData]);

  return (
    <div className="row mb-4">
        <div className="col-md-10 offset-1">
            <div id="dataAirportTable" className='mt-4'>
                <table id="airportTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                    <thead>
                        <tr>
                            <th>Minimum</th>
                            <th>Amount Per 100 lbs</th>
                            <th>Cap</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default AirportTransfer