import  { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getTariffInfo,getTariffRate,getAccessorials,getSpecialRates } from '../../../restAPI/TariffRatesAPI';

const DataTariffUpdatePortal = () => {
    const initialData = {
        airportCode:'',
        stationId : '',
        companyName : '',
        address1 : '',
        address2 : '' ,
        city : '',
        state : '',
        postalCode : '',
        phoneNumber : '' ,
        emergencyPhone : '',
        fax : '',
        manager : '',
        email : '',
        region :'',
        latitude:'',
        longitude:'',
    };

    const header = {"termCode": "",
                    "manager": "",
                    "emergencyPhone": "",
                    "stationName": "",
                    "inv_add1": "",
                    "inv_add2": "",
                    "csz": "",
                    "inv_phone": "",
                    "inv_fax": "",
                    "wkdy_hours": "",
                    "sat_hours": "",
                    "sun_hours": ""};
    const expirationDate ={"expireDate": ""};

    const tariffRate = { area: '',
                        min: 0,
                        lbs100: 0,
                        lbs500: 0,
                        lbs1000: 0,
                        lbs12000: 0,
                        lbs3000: 0,
                        lbs5000: 0,
                        lbs10000: 0,
                        lbs15000: 0,
                        lbs20000: 0,
                        capRate: 0,
                        newMin: 0,
                        newLbs100: 0,
                        newLbs500: 0,
                        newLbs1000: 0,
                        newLbs12000: 0,
                        newLbs3000: 0,
                        newLbs5000: 0,
                        newLbs10000: 0,
                        newLbs15000: 0,
                        newLbs20000: 0,
                        newCapRate: 0};

    const   surchargeRate = {
        code: '',
        description: '',
        chargeType: '',
        requireAuthorization: false,
        rate: 0,
        minimum: 0,
        maximum: 0,
        perLb1: 0,
        perLb2: 0,
        perLbRate1: 0,
        perLbRate2: 0,
        baseAmount: 0
                            };
    const specialRate = {
                        "terminal": "",
                        "zone": "",
                        "currentRate": {
                        "moN_FRI_0000_0759": 0,
                        "moN_FRI_0800_1700": 0,
                        "moN_FRI_1701_2359": 0,
                        "saturday": 0,
                        "sunday": 0,
                        "holiday": 0
                        },
                        "updatedRate": {
                        "moN_FRI_0000_0759": 0,
                        "moN_FRI_0800_1700": 0,
                        "moN_FRI_1701_2359": 0,
                        "saturday": 0,
                        "sunday": 0,
                        "holiday": 0
                        }
                        };
    const airportTransferRate = {
                                "AREAFROM": "",
                                "MINISHIP": 0.0,
                                "MINAMOUNT": 0.0,
                                "WEIGHTBREAKVALUE": 0.0,
                                "WEIGHTAMOUNT": 0.0,
                                "CAPBREAKVALUE": 0.0,
                                "CAPAMOUNT": 0.0
                            };
    const citiesServed ={
                            "zip": "",
                            "city": "",
                            "state": "",
                            "zone": ""
                        };
    const notes = {
                        "tariff": "",
                        "special_delivery": "",
                        "accessorial": "",
                        "airport_transfer": "",
                        "linehaul": "",
                        "special_transfer": "",
                        "cities_served": "",
                    };
    const [headerData,setHeaderData] = useState(header);
    const [expirationData,setExpirationData] = useState(expirationDate);
    const [notesData,setNotesData] = useState(notes);
    const [tariffRateData,setTariffRateData] = useState([tariffRate]);
    const [surchargeRateData,setSurchargeRateData] = useState([surchargeRate]);
    const [specialRateData,setSpecialRateData] = useState([specialRate]);
    const [citiesServedData,setCitiesServedData] = useState([citiesServed]);
     const [stationInfoData, setStationInfoData] = useState(initialData);
     const [tariffRatesLoading, setTariffRatesLoading] = useState(false);
     const [tariffInfoLoading, setTariffInfoLoading] = useState(false);
     const [surchargeLoading, setSurchargeLoading] = useState(false);
     const [specialLoading, setSpecialLoading] = useState(false);
     
     const setLoading = (type,value) =>{
        if(type === "TariffRates")
        setTariffRatesLoading(value);
        else if(type === "TariffInfo")
        setTariffInfoLoading(value);
        else if(type === "Surcharge")
        setSurchargeLoading(value);
        else if(type === "Special")
        setSpecialLoading(value);
        else if(type === "StationInfo")
        setStationInfoData(value);
     }
    let userProfileData = useSelector((state) => state.usr)
    const  [filtersInput, setFiltersInput] = useState({
        airportcode : '',
        showTable: false
    });

    const [filtersReq,setFiltersReq] = useState({
        airportcode:    true,
        airportcode_ErrorClass : 'form-control',
    });

    const [airportCodesList,setAirportCodesList] = useState([
        {value : "***", label : "***"}
    ]);

    const handleStationInfoData = (name, value) => {
        setStationInfoData({...stationInfoData, [name]: value});
    }
    const onSubmitSearch = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";
                var errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        }else{
            
            setFiltersInput({...filtersInput, showTable: false});
           //loadTariffInfo();
           //loadTariffRates();
           //loadAccessorials();
        }
    }

    const onValidateTariff = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
            if(key === name && value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
            status=true;
            }else if(key === name && !value) {
                errState = {[name]: false,[errname]: _css+''};
            status=true;
            }
        //   })
        if(status)
        setFiltersReq({...filtersReq, ...errState});
    }

    const loadTariffInfo = () => {
        
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        
        setTariffInfoLoading(true);
        getTariffInfo(filtersInput.airportcode,userProfileData.custCode,userProfileData.custCode)
        .then(responseData =>{
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
    
            } else {
                 //   console.log("responseData of tariff Info ",JSON.stringify(reportData))
                    setStationInfoData(responseData);
            }
            setFiltersInput({...filtersInput, showTable: true});
        }).catch(err=>{
            setFiltersInput({...filtersInput, showTable: false});
        }).finally(err =>{
            setTariffInfoLoading(false);
        });
        
    }

    const loadTariffRates = () => {
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        
        setTariffRatesLoading(true);
        getTariffRate(filtersInput.airportcode,userProfileData.custCode,userProfileData.custCode)
        .then(responseData =>{
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
    
            } else {
                   // console.log("responseData of tariffRates ",JSON.stringify(reportData))
                    setTariffRateData(responseData);
            }
            setFiltersInput({...filtersInput, showTable: true});
        }).catch(err=>{
            setFiltersInput({...filtersInput, showTable: false});
        }).finally(err =>{
            setTariffRatesLoading(false);
        });
    }

    const loadAccessorials = () => {
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        
        setSurchargeLoading(true);
        getAccessorials(filtersInput.airportcode,userProfileData.custCode,userProfileData.custCode)
        .then(responseData =>{
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
    
            } else {
                   // console.log("responseData of Accessorials ",JSON.stringify(reportData))
                    setSurchargeRateData(responseData);
            }
            setFiltersInput({...filtersInput, showTable: true});
        }).catch(err=>{
            setFiltersInput({...filtersInput, showTable: false});
        }).finally(err =>{
            setSurchargeLoading(false);
        });
    }

    const loadSpecailrates = () => {
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        
        setSpecialLoading(true);
        getSpecialRates(filtersInput.airportcode,userProfileData.custCode,userProfileData.custCode)
        .then(responseData =>{
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });    
            } else {
                   // console.log("responseData of Accessorials ",JSON.stringify(reportData))
                   setSpecialRateData(responseData);
            }
            setFiltersInput({...filtersInput, showTable: true});
        }).catch(err=>{
            setFiltersInput({...filtersInput, showTable: false});
        }).finally(err =>{
            setSpecialLoading(false);
        });
    }

    const onChangeTariff = (name, value) => {
       // console.log("selected valus name"+name+"--"+value);
        setFiltersInput({...filtersInput, [name]: value});
    }

     useEffect(() => {
        const aircodeList = localStorage.getItem("airportCodeList");
        if (aircodeList && aircodeList !== 'undefined') {
            setAirportCodesList(JSON.parse(aircodeList));
        }  
    //     
    //     loadTariffInfo("default");
     }, [])
    
     const descriptionList = [{ value: "1", label: "Airport Drop/Recovery" },
    { value: "2", label: "CFS Drop/Recovery" },
    { value: "3", label: "Commercial" },
    { value: "4", label: "Commercial Inside" },
    { value: "5", label: "Commercial White Glove" },
    { value: "6", label: "Convention Center" },
     { value: "7", label: "Exclusive Use- Same Day" },
    { value: "8", label: "Ground Service Provider Drop/Recovery" },
     { value: "9", label: "Hot Shot Service" },
    { value: "10", label: "Residential Inside" },
    { value: "11", label: "Residential Threshold" },
    { value: "12", label: "Residential White Glove" },
    { value: "13", label: "Tradeshow" },
    ];
    const chargeTypeList = [{ value: "1", label: "Terminal to Door" },
    { value: "2", label: "Door to Door" },
    { value: "3", label: "Door to Terminal" },
    { value: "4", label: "Terminal to Terminal" }
    ];

    return {
        onChangeTariff,
        onSubmitSearch,
        onValidateTariff,
        loadTariffInfo,
        loadTariffRates,
        loadAccessorials,
        loadSpecailrates,
        setLoading,
        handleStationInfoData,
        filtersInput,
        filtersReq,
        airportCodesList,
        stationInfoData,
        tariffRateData,
        surchargeRateData,
        specialRateData,
        tariffRatesLoading,
        tariffInfoLoading,
        surchargeLoading,
        specialLoading,
        descriptionList,
        chargeTypeList,
    }
}

export default DataTariffUpdatePortal