import React  from "react";
import { NavLink } from "react-router-dom";


const ApprovedMotorCarrierMenu = () => {
    const handleMenuClick = (e) => {
        e.preventDefault();
        return false;
    }
    return (
        <nav id="sidebar">
            {/* <!--side--> */}
        <div className="custom-menu">
            <button type="button" id="sidebarCollapse" className="btn btn-toggle">
                <i className="fas fa-bars"/>
                <span className="sr-only">Toggle Menu</span>
            </button>
        </div>
            <ul className="list-unstyled components">
				<li>
                    <NavLink to="/amc/corporateDirectory" title="Corporate Directory">
                        <i className="fas fa-phone-square-alt"/>
                        <span>Corporate Directory  </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/amc/coverage" title="Approved Motor Carrier Directory">
                        <i className="fas fa-address-book"/> 
                        <span>Approved Motor Carrier Directory </span>
                    </NavLink>
                </li>
                
                <li>
                    <NavLink to="/amc/salesSopReport" title="SOP Report">
                        <i className="fas fa-file-invoice"/>
                        <span>SOP Report </span>
                    </NavLink>
                </li>
                <li>
                    <a href="https://ms.freightforce.com/download/Crown_imaging_software_update.zip" title="Download CROWN Imaging Software" 
                        download="https://ms.freightforce.com/download/Crown_imaging_software_update.zip">
                        <i className="fas fa-download"/>
                        <span>Download CROWN Imaging Software </span>
                    </a>
                </li>
                <li>
                    <NavLink to="/amc/tsa" title="How to Access TSA Information">
                        <i className="fas fa-info-circle"/>
                        <span>How to Access TSA Information </span>
                    </NavLink>
                </li>
                <li>
                    {/* <a href="https://ms.freightforce.com/EmailPOD.aspx" target="_new" title="Quick Guide"> */}
                    <NavLink to="/amc/quickGuide" title="How to Access TSA Information">
                        <i className="fas fa-map"/>
                        <span>Quick Guide </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/amc/emailCrown" title="How to Email from Crown">
                        <i className="fas fa-envelope"/>
                        <span>How to Email from Crown </span>
                    </NavLink>
                </li>
                {/* <li>
                    <a href="https://ms.freightforce.com/TariffUpdate.aspx" target="_new" title="Tariff Update Portal">
                    <i className="fas fa-laptop"/>
                        <span>Tariff Update Portal </span>
                    </a>
                </li> */}
            </ul>
            {/* <!--sidebar--> */}
    </nav>
    );
}

export default ApprovedMotorCarrierMenu;