import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {  compose } from 'redux';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'
//import {  createStore, applyMiddleware, compose ,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './store/reducer/userprofileReducer';
import dimReducer from './store/reducer/dimensionReducer';
import quickQuoteReducer from './store/reducer/quickQuoteReducer';
import totDimReducer from './store/reducer/totDimReducer';
import coverageReducer from './store/reducer/coverageReducer';
import tariffUpdateReducer from './store/reducer/tariffUpdateReducer';
const root = ReactDOM.createRoot(document.getElementById('root'));

// const rootReducer = combineReducers({
//   usr: userReducer,
  
// });

// custom logger for the redux
const logger = store => {
  return next => {
      return action => {
          // console.log('[Middleware] Dispatching', action);
          const result = next(action);
          // console.log('[Middleware] next state', store.getState());
          return result;
      }
  }
};


//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = configureStore({
  reducer: {
    usr: userReducer,
    dim : dimReducer,
    qqt : quickQuoteReducer,
    totDim: totDimReducer,
    coverage: coverageReducer,
    tariff:tariffUpdateReducer,
    
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false}).concat(logger),
    thunk, composeEnhancers,
    
});
//const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));

root.render(<Provider store={store}><App /> </Provider>
  
  // The below code is commented bcz component is rendering two times to avoid This will happen in development environment only
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

axios.interceptors.request.use(
  config => {
      let userProfileData = JSON.parse(localStorage.getItem("userProfile"));
      if( userProfileData && userProfileData.token ){
            config.headers.Authorization =  `Bearer ${userProfileData.token}`;
      }
          return config;
      },
      error => {
          return error;
      }
  );
  