import React, { useEffect, useState } from 'react'
import { useNavigate} from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {fetchQuoteHistoryById,fetchBookedHistoryById} from '../restAPI/QuickQuoteRest';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;



const QuoteBookHistory = (props) => {
    const navigate = useNavigate();
    const [tableData] = useState(props.reportData);
    var table = null;
  
    const  loadQuote = (quoteId)=>{
      alert(quoteId);
    }
    useEffect(() => {
      
      //if( !props.showDataLoading ) {
      table = $("#history").DataTable({  // eslint-disable-line

          "responsive": true,
          "pageLength": 10,
          "processing": true,
          "scrollX": true,
          "order": [],
          // "scrollY": '200px',
	        "autoWidth": false,
	        "bAutoWidth": false,
          "data" : tableData,
          "dom": 
              "<'row'<'col-lg-3 col-md-4'l><'col-lg-5 col-md-8 text-right'B><'col-lg-3 col-md-6 offset-lg-1 offset-md-6 text-right'f>>" +
              "<'row'<'col-md-12'tr>>" +
              "<'row'<'col-md-5'i><'col-md-7'p>>",
          "buttons": [
            {
                extend:    'copyHtml5',
                className: 'btn btn-primary btn-sm',
                text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                filename: 'History',
                title: 'Quote Booked History',
                titleAttr: 'Copy'
            },
            {
                extend:    'excelHtml5',
                className: 'btn btn-primary  btn-sm',
                text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                filename: 'History',
                title: 'Quote Booked History',
                titleAttr: 'Excel',
                exportOptions: {
                   modifier: {
                       order: 'current',
                       page: 'all',
                       selected: false
                   }
                },
                
            },
            /*{
                extend:    'csvHtml5',
                className: 'btn btn-primary  btn-sm',
                text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                filename: 'History',
                title: 'Quote Booked History',
                titleAttr: 'CSV'
            },*/
            {
                extend:    'pdfHtml5',
                text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                className: 'btn btn-primary  btn-sm',
                filename: 'History',
                title: 'Quote Booked History',
                titleAttr: 'PDF',
                orientation: 'landscape',
                pageSize: 'LEGAL',
                customize: function(doc) {
                    doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                 }  
            }
        ], 

         

          "lengthMenu": [
            [10, 20, 30, 50, -1],
            [10, 20, 30, 50, "All"],
          ],
          "columns": [

            { "data" : "quoteId" , "render": function (data, type, row) {
              var display = row["quoteId"];
              //var anchorUrl = reporturlVal+"&cfsCode="+cfsCode+"&fileNo="+row["fileNo"];
              var a = '<span class="quoteIdHis btn-link" >'+display+' </span>';
              var bookId = row["bookedOrderId"];
              if(bookId)
              a = display;
              return a;
            }},
            { "data" : "bookedOrderId"  , "render": function (data, type, row) {
              var display = row["bookedOrderId"];
              //var anchorUrl = reporturlVal+"&cfsCode="+cfsCode+"&fileNo="+row["fileNo"];
              var a = '<span class="bookIdHis btn-link" >'+display+' </span>';
              return a;
            }},
            { "data" : "status",  },
            { "data" : "createDate" },
            { "data" : "totalPieces" },
            { "data" : "grossWeight" },
            { "data" : "dimWeight" },
            { "data" : "totalCharges" , "render": function (data, type, row) {
              var display = row["totalCharges"];
              //var anchorUrl = reporturlVal+"&cfsCode="+cfsCode+"&fileNo="+row["fileNo"];
              return "$ "+display.toFixed(2);
            }},
            ],
          
          "bDestroy": true, // to fix canoot reinitialise datatable
          "initComplete": function(settings, json) {
              }
        });

        $('#history tbody').on('click','tr td span.quoteIdHis', function (event) {
          // , 'td.dt-control'
          var tr = $(this).closest('tr');
          var row = table.row(tr);
          var row_data = table.row( tr ).data() ;
          getQuoteHistoryData(row_data);
        
        });

        $('#history tbody').on('click','tr td span.bookIdHis', function (event) {
          // , 'td.dt-control'
          var tr = $(this).closest('tr');
          var row = table.row(tr);
          var row_data = table.row( tr ).data() ;
          getBookHistoryData(row_data);
        
        });
  }, [props.showDataLoading]) // eslint-disable-line
    
  const getQuoteHistoryData =(row_data) =>{
    // console.log(row_data)
    fetchQuoteHistoryById(row_data.quoteId).then(responseDate => {
      if (responseDate instanceof Error) {
        // console.log("erro object is fetchBookedHistoryById " + JSON.stringify(responseDate))
        //setErrorStatus({ restError: true, restErrorMessage: responseDate.message });
      } else {
      navigate("/freightQuote/quoteProposed", { state: { quoteHis: true , quoteHisData:responseDate } });
      }
    })
  }

  const getBookHistoryData =(row_data) =>{
    // console.log(row_data)
    fetchBookedHistoryById(row_data.bookedOrderId).then(responseDate => {
      if (responseDate instanceof Error) {
        // console.log("error object is  fetchBookedHistoryById " + JSON.stringify(responseDate))
        //setErrorStatus({ restError: true, restErrorMessage: responseDate.message });
      } else {
      navigate("/freightQuote/bookConfirm", { state: { quoteHis: true , quoteBookData:responseDate } });
      }
    })
  }
  
  
  return (
    // <DataTable tableHeaders={tableHeaders} tableData={tableData} />
         <div id="datatable">
  <table id="history" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
      <thead>
          <tr>
              <th>Quote ID</th>
              <th>Booked Order</th>
              <th>Status</th>
              <th>Quote/Order Date</th>
              <th>Total Pieces</th>
              <th>Gross Weight (LBS)</th>
              <th>Dim Weight</th>
              <th>Charges</th>
          </tr>
      </thead>
      <tbody>
          {/* {
            props.showDataLoading && 
            <tr>
              <td valign="top" colSpan={11} className="dataTables_empty">
                <i className='fas fa-spinner fa-spin'></i> Loading...
              </td>
            </tr> 
          } */}
      </tbody>
    </table>
    </div>    
)
}

export default QuoteBookHistory;