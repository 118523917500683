export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    }
};

export const updateIntialState = ( updatedValues) => {
    
    return [...updatedValues]
   
};