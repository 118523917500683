import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import InputElement from "../../../formControls/InputElement";
import DataEditCoverage from "./Data/DataEditCoverage";


const EditCoverage = () => {
    const { handleInputChange, resetErrStatus, handleSearch, updateAddress, onValidateCoverage, filtersInput, filtersInputRequired, loading, stateList } = DataEditCoverage();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTimeout(() => {
            resetErrStatus();
        }, 3000);
    }, [filtersInput.showStatus]);


    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'} />}
            <div className="card card-custom">
                <div className="d-flex">
                    <h4 className="mb-2 pageHead text-uppercase">Update Converage Details</h4>
                </div>
                {/* <div className="row">
                    rd={JSON.stringify(reportData)}
                </div>
                <div className="row">
                    sd={JSON.stringify(serverData)}
                </div> */}
                <form>

                    <div className="row mb-3" >
                        <div className="col-md-4 mt-1 text-right"><label>Enter the Station Code </label></div>
                        <div className="col-md-4">               <InputElement id="station_code" name="station_code" value={filtersInput.station_code || ''} className="form-control"
                            onChange={handleInputChange}  
                            placeholder='Station Code' /></div>
                        <div className="col-md-4">             <button className="btn btn-primary btnTextSize float-left" onClick={handleSearch} type="button">

                            &nbsp; Search</button></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label>Station Name</label>
                            <InputElement id="station_name" name="station_name" value={filtersInput.station_name || ''} className={filtersInputRequired.station_name_ErrorClass}
                                onChange={handleInputChange} readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='Station Name' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>Station Code</label>
                            <InputElement id="station_id" name="station_id" value={filtersInput.station_id || ''} className={filtersInputRequired.station_id_ErrorClass}
                                onChange={handleInputChange} readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='Station Code' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                       
                        <div className="col-md-4 ">
                            <label>Phone</label>
                            <InputElement id="phone" name="phone" value={filtersInput.phone || ''} className={filtersInputRequired.phone_ErrorClass}
                                onChange={handleInputChange}  readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='Phone' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                       
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4 ">
                            <label>Address</label>
                            <InputElement id="address" name="address" value={filtersInput.address || ''} className={filtersInputRequired.address_ErrorClass}
                                onChange={handleInputChange}  readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='Address' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>City State Zip</label>
                            <InputElement id="csz" name="csz" value={filtersInput.csz || ''} className={filtersInputRequired.csz_ErrorClass}
                                onChange={handleInputChange}  readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='City State Zip' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>Fax</label>
                            <InputElement id="fax" name="fax" value={filtersInput.fax || ''} className={filtersInputRequired.fax_ErrorClass}
                                onChange={handleInputChange} readOnly onInvalid={onValidateCoverage} validation="required"
                                placeholder='Fax' />  
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="colorRed"> Edit gray fields in Crown</label>
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-4">
                            <label>Manager Name</label>
                            <InputElement id="manager_name" name="manager_name" value={filtersInput.manager_name || ''} className={filtersInputRequired.manager_name_ErrorClass}
                                onChange={handleInputChange} onInvalid={onValidateCoverage} validation="required"
                                placeholder='Manager/Security Coordinator' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    <div className="col-md-4">
                            <label>Region</label>
                            <InputElement id="region" name="region" value={filtersInput.region || ''} className={filtersInputRequired.region_ErrorClass}
                                onChange={handleInputChange} onInvalid={onValidateCoverage} validation="required"
                                placeholder='Region' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>

                       
                        
                        <div className="col-md-4">
                            <label>Email</label>
                            <InputElement id="email_address" name="email_address" value={filtersInput.email_address || ''} className={filtersInputRequired.email_address_ErrorClass}
                                onChange={handleInputChange} onInvalid={onValidateCoverage} validation="required"
                                placeholder='Email' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-4 "><label>Latitude</label><InputElement id="latitude" name="latitude" value={filtersInput.latitude || ''} className={filtersInputRequired.latitude_ErrorClass}
                            onChange={handleInputChange}  onInvalid={onValidateCoverage} validation="required"
                            placeholder='Latitude' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4"><label>Longitude</label> <InputElement id="longitude" name="longitude" value={filtersInput.longitude || ''} className={filtersInputRequired.longitude_ErrorClass}
                            onChange={handleInputChange} onInvalid={onValidateCoverage} validation="required"
                            placeholder='Longitude' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4 "><label>Emergency Phone</label>   <InputElement id="emerg_phone" name="emerg_phone" value={filtersInput.emerg_phone || ''} className={filtersInputRequired.emerg_phone_ErrorClass}
                            onChange={handleInputChange} onInvalid={onValidateCoverage} validation="required"
                            placeholder='Emergency Phone' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row mb-3" >
                        <div className="col-md-8"><label className="boldBlack">{filtersInput.statusDesc}</label></div>
                        <div className="col-md-4  text-center">  <button className="btn btn-primary btnTextSize" onClick={updateAddress} type="button">
                            &nbsp; Update</button></div>
                    </div>
                </form>
                
            </div>
        </div>
    );
}


export default EditCoverage;