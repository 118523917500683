import { useEffect, useState } from 'react'

const DataSpecialEdit = () => {
  
    const  [initData] = [{
        terminal: '',
        zone: '',
        new_moN_FRI_0000_0759: '',
        new_moN_FRI_0800_1700: '',
        new_moN_FRI_1701_2359: '',
        new_saturday: '',
        new_sunday: '',
        new_holiday: ''
        
    }];
    const [initDataReq] = [{
        terminal_ErrorClass : 'form-control',
        zone_ErrorClass : 'form-control',
        new_moN_FRI_0000_0759_ErrorClass : 'form-control',
        new_moN_FRI_0800_1700_ErrorClass: 'form-control',
        new_moN_FRI_1701_2359_ErrorClass: 'form-control',
        new_saturday_ErrorClass: 'form-control',
        new_sunday_ErrorClass: 'form-control',
        terminal: false,
        zone: false,
        new_moN_FRI_0000_0759: true,
        new_moN_FRI_0800_1700: true,
        new_moN_FRI_1701_2359: true,
        new_saturday: true,
        new_sunday: true,
        new_holiday: true,
    }];

    const [formEditData, setFormEditData] = useState(initData);

    const [formEditDataReq, setFormEditDataReq] = useState(initDataReq);

    const onSubmitEditSpecial = () => {
        const copy = {...formEditDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
       // console.log("copy",copy);
        Object.entries(copy).forEach(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                
                var errname=key+"_ErrorClass";
                //var _css = "form-control";
                var errState = errState;
               // console.log(key,value,errname)
                if( errname==="newrequireAuthorization_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'custom-control-input is-invalid' };
                }else{
                    errState = { [key]: true, [errname]: 'form-control is-invalid' };
                }
                status = true;
                Object.assign(errObject,errState);
            }
        })
        if(status){
            setFormEditDataReq({...formEditDataReq,...errObject});
        } 

        let reqObj = { ...formEditDataReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        validationReqFlag = Object.values(reqObj).includes(true);
        return validationReqFlag;
            
    }

    const onValidateEditSpecial = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if( errname==="newrequireAuthorization_ErrorClass" ){
            _css = 'custom-control-input';
        }
        if( errname==="description_ErrorClass" || errname==="chargeType_ErrorClass" ){
            if (value) {
              errState = { [name]: true, [errname]: 'text-danger' };
              status = true;
            } else if (!value) {
              errState = { [name]: false, [errname]: 'text-danger d-none' };
              status = true;
            }
          }else{
            if(value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
            }else if(!value) {
                errState = {[name]: false,[errname]: _css+''};
                status=true;
            }
        }
        if(status)
            setFormEditDataReq({...formEditDataReq, ...errState});
      }

    const handleEditInputChange = (name, value) => {
        setFormEditData({...formEditData, [name]: value});
    }

    const clearEditSpecialForm = () => {
        setFormEditData(initData);
        setFormEditDataReq(initDataReq);
        //console.log("fd r",formEditData)
    }

    const setFormEditDataObj = (_obj) => {
        // change the upatedRate attributes based on response we get
        var newData = {};
        if(_obj?.updatedRate){
            newData = { 
                terminal: _obj?.terminal,
                zone: _obj?.zone,
                new_moN_FRI_0000_0759: _obj?.updatedRate?.moN_FRI_0000_0759,
                new_moN_FRI_0800_1700: _obj?.updatedRate?.moN_FRI_0800_1700,
                new_moN_FRI_1701_2359: _obj?.updatedRate?.moN_FRI_1701_2359,
                new_saturday: _obj?.updatedRate?.saturday,
                new_sunday: _obj?.updatedRate?.sunday,
                new_holiday: _obj?.updatedRate?.holiday,
            }
            setFormEditData({...formEditData,...newData});
        }
    }

    return {
        formEditData,
        formEditDataReq,
        handleEditInputChange,
        onSubmitEditSpecial,
        onValidateEditSpecial,
        clearEditSpecialForm,
        setFormEditDataObj
    }
}

export default DataSpecialEdit;