import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getOpenClose } from '../../../restAPI/TariffRatesAPI';

const DataTariffManagement = () => {
    const openClose = { terminal: '',
                        isOpen: false
                        };
    const [openCloseData,setOpenCloseData] = useState([openClose]);
    const [openCloseLoading, setOpenCloseLoading] = useState(false);
    const  [filtersInput, setFiltersInput] = useState({
        airportcode : '',
        showTable: false
    });
    let userProfileData = useSelector((state) => state.usr)
    const loadOpenClose = () => {
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
        setOpenCloseLoading(true);
        getOpenClose()
        .then(responseData =>{
            if (responseData instanceof Error) {
                // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
    
            } else {
                   // console.log("responseData of tariffRates ",JSON.stringify(reportData))
                    setOpenCloseData(responseData);
            }
            setFiltersInput({...filtersInput, showTable: true});
        }).catch(err=>{
            setFiltersInput({...filtersInput, showTable: false});
        }).finally(err =>{
            setOpenCloseLoading(false);
        });
    }
    return {
        openCloseData,
        openCloseLoading,
        loadOpenClose
    }
}

export default DataTariffManagement