import React from "react";
import InputElement from "../../formControls/InputElement";
const BookLayer1 = (props) => {

    const ffffrom = () =>{
        return props.quickQuote.fffFrom ?  <div className="col-md-3 custom-control custom-checkbox">
                    <InputElement type="checkbox" className="custom-control-input" id="fffFrom" name="fffFrom"
                        checked={props.quickQuote.fffFrom}
                    // onChange={props.onChangeBookHandler} 
                    />
                    <label className="custom-control-label" htmlFor="fffFrom">Freight Force Facility</label>
                </div>
                :
                <div className="col-md-3"></div>
    }
    
    const fffTo = () => {
        return props.quickQuote.fffTo ?   <div className="col-md-3 custom-control custom-checkbox">
                    <InputElement type="checkbox" className="custom-control-input" id="fffTo" name="fffTo"
                        checked={props.quickQuote.fffTo}
                    // onChange={props.onChangeBookHandler}
                    />
                    <label className="custom-control-label" htmlFor="fffTo">Freight Force Facility</label>
                </div>
                :
                <div className="col-md-3"></div>
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-3 mb-3 text-bold">
                    <h5>SHIPPER INFORMATION</h5>
                </div>
                
                { ffffrom()/* <div className="col-md-3 mt-3 d-inline">
                        <InputElement  type="checkbox" className="chkboxSize" id="fffFrom" name="fffFrom" checked={props.quickQuote.fffFrom} onChange={props.onChangeBookHandler} />
                         <div className="d-inline align-top">   Freight Force Facility  </div>
                </div> */}
                <div className="col-md-3 mb-3 text-bold">
                    <h5>CONSIGNEE INFORMATION</h5>
                </div>
                

                {fffTo()/* <div className="col-md-3 mt-3 d-inline">
                        <InputElement  type="checkbox" className="chkboxSize" id="fffTo" name="fffTo" checked={props.quickQuote.fffTo} onChange={props.onChangeBookHandler} />
                         <div className="d-inline align-top">   Freight Force Facility  </div>
                </div> */}
            </div>
            
        </div>
    );
}

export default BookLayer1;