import { useEffect, useState } from 'react'

const DataTariffEdit = () => {
  
    const  [initData] = [{
        //description : '',
       // chargeType : '',
       newmin : '',
       newlbs100: '',
       newlbs500: '',
       newlbs1000: '',
       newlbs2000: '',
       newlbs3000: '',
       newlbs5000: '',
       newlbs10000: '',
       newlbs15000: '',
       newlbs20000: '',
       newcapRate: ''
    }];
    const [initDataReq] = [{
        newmin_ErrorClass: 'form-control',
        newlbs100_ErrorClass: 'form-control',
        newlbs500_ErrorClass: 'form-control',
        newlbs1000_ErrorClass: 'form-control',
        newlbs2000_ErrorClass: 'form-control',
        newlbs3000_ErrorClass: 'form-control',
        newlbs5000_ErrorClass: 'form-control',
        newlbs10000_ErrorClass: 'form-control',
        newlbs15000_ErrorClass: 'form-control',
        newlbs20000_ErrorClass: 'form-control',
        newcapRate_ErrorClass: 'form-control',
        newmin : true,
        newlbs100: true,
        newlbs500: true,
        newlbs1000: true,
        newlbs2000: true,
        newlbs3000: true,
        newlbs5000: true,
        newlbs10000: true,
        newlbs15000: true,
        newlbs20000: true,
        newcapRate: true
    }];

    const [formEditData, setFormEditData] = useState(initData);

    const [formEditDataReq, setFormEditDataReq] = useState(initDataReq);

    const onSubmitEditTariff = () => {
        const copy = {...formEditDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
       // console.log("copy",copy);
        Object.entries(copy).forEach(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                
                var errname=key+"_ErrorClass";
                //var _css = "form-control";
                var errState = errState;
               // console.log(key,value,errname)
                if( errname==="newrequireAuthorization_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'custom-control-input is-invalid' };
                }else{
                    errState = { [key]: true, [errname]: 'form-control is-invalid' };
                }
                status = true;
                Object.assign(errObject,errState);
            }
        })
        if(status){
            setFormEditDataReq({...formEditDataReq,...errObject});
        } 

        let reqObj = { ...formEditDataReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        validationReqFlag = Object.values(reqObj).includes(true);
        return validationReqFlag;
            
    }

    const onValidateEditTariff = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if( errname==="newrequireAuthorization_ErrorClass" ){
            _css = 'custom-control-input';
        }
        if( errname==="description_ErrorClass" || errname==="chargeType_ErrorClass" ){
            if (value) {
              errState = { [name]: true, [errname]: 'text-danger' };
              status = true;
            } else if (!value) {
              errState = { [name]: false, [errname]: 'text-danger d-none' };
              status = true;
            }
          }else{
            if(value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
            }else if(!value) {
                errState = {[name]: false,[errname]: _css+''};
                status=true;
            }
        }
        if(status)
            setFormEditDataReq({...formEditDataReq, ...errState});
      }

    const handleEditInputChange = (name, value) => {
        setFormEditData({...formEditData, [name]: value});
    }

    const clearEditTariffForm = () => {
        setFormEditData(initData);
        setFormEditDataReq(initDataReq);
        //console.log("fd r",formEditData)
    }

    const setFormEditDataObj = (_obj) => {
        // change the upatedTariffs attributes based on response we get
        var newData={};
        if(_obj?.updatedTariffs){
        newData = { newmin : _obj?.updatedTariffs?.min, 
                newlbs100: _obj?.updatedTariffs?.lbs100, 
                newlbs500: _obj?.updatedTariffs?.lbs500, 
                newlbs1000: _obj?.updatedTariffs?.lbs1000, 
                newlbs2000: _obj?.updatedTariffs?.lbs2000, 
                newlbs3000: _obj?.updatedTariffs?.lbs3000, 
                newlbs5000: _obj?.updatedTariffs?.lbs5000, 
                newlbs10000: _obj?.updatedTariffs?.lbs10000, 
                newlbs15000: _obj?.updatedTariffs?.lbs15000, 
                newlbs20000: _obj?.updatedTariffs?.lbs20000, 
                newcapRate: _obj?.updatedTariffs?.capRate 
                }
        setFormEditData({...formEditData,...newData});
        }
    }

    return {
        formEditData,
        formEditDataReq,
        handleEditInputChange,
        onSubmitEditTariff,
        onValidateEditTariff,
        clearEditTariffForm,
        setFormEditDataObj
    }
}

export default DataTariffEdit;