import React ,{useEffect} from "react";
import flightjpg from '../../../assets/img/flight_1.jpg'
import verootpng from '../../../assets/img/logo_veroot.png'

const Tsa = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    return (
        <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">TSA</h4>
                        </div>
						<div className="row">
							<div className="col-md-5 mb-4">
							<img src={flightjpg} className="img-fluid"/>
							</div>
							<div className="col-md-7 text-justify">
							<p>Thank you for selecting the network of TSA qualified Freight Force Approved Motor Carriers for your air freight shipments. Recognizing the need to establish these motor carriers as Authorized Representatives, Veroot has created a portal to allow you to easily obtain the TSA information required as part of that process.</p>
							<p><strong>Please note:</strong> the Freight Force Approved Motor Carriers are the companies responsible for being the TSA compliant party between the IAC and motor carrier. All SSI documents are signed by the motor carriers on our approved list. Freight Force is not authorized to view or sign these documents. Additionally, the Freight Force name should not be included as part of the approved motor carriers name on any TSA documents. The Approved Motor Carrier is the company performing the cartage work for the IAC.</p>
							<p>The individual Freight Force Approved Motor Carriers utilize the service provided to update and maintain their current TSA information.</p>
							<p>Please note that Freight Force does not have access to and has not reviewed the information supplied by the individual Freight Force Approved Carriers to Veroot.</p>
							<p>Click on Veroot below to access the Veroot web page. Also below you will find a link to the Security Coordinator contact information for each Freight Force Approved Motor Carriers, should you need to contact them.</p>
							<div className="col-md-4 clear pl-0 mb-2">
							<a href="https://www.veroot.com/freightforce" target="_blank" rel="noopener noreferrer"><img src={verootpng} className="img-fluid"/></a>
							</div>
							<p><a href="http://ms.freightforce.com/TSA.aspx" target="_blank" rel="noopener noreferrer"><strong>Carrier TSA Contact Information</strong></a><br/>
<strong><a href="https://ms.freightforce.com/general/pdfForms/TSA_Information_StepbyStep.pdf" target="_blank" rel="noopener">TSA Instructions</a></strong></p>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Tsa;