import axios from "axios";

export const getNewsFeed = () => {
    
    
    return axios.get(process.env.REACT_APP_NEWSFEED_URL)
        .then(response => {
            //console.log("REACT_APP_NEWSFEED_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_NEWSFEED_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in getNewsFeed ");
        })
}

export const addNewsFeed = (id, description) => {
    
    const postData = {
        "id": id,
        "description": description,
        
    }
    return axios.post(process.env.REACT_APP_ADD_NEWSFEED_URL, postData)
        .then(response => {
            //console.log("REACT_APP_ADD_NEWSFEED_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_ADD_NEWSFEED_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in addNewsFeed ");
        })
}

export const updateNewsFeed = (id,description) => {
    
    const postData = {
        "id" : id,
        "description": description,
        
    }
    return axios.put(process.env.REACT_APP_UPDATE_NEWSFEED_URL, postData)
        .then(response => {
            // console.log("REACT_APP_UPDATE_NEWSFEED_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_UPDATE_NEWSFEED_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in updateNewsFeed ");
        })
}

export const deleteNewsFeed = (id, description) => {
    
    const postData = { data :{
        "id" : id,
        "description": description}
    }
    return axios.delete(process.env.REACT_APP_DELETE_NEWSFEED_URL, postData)
        .then(response => {
            //console.log("REACT_APP_DELETE_NEWSFEED_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_DELETE_NEWSFEED_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in deleteNewsFeed ");
        })
}
