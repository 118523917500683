import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import SelectElement from '../../../formControls/SelectElement'
import Accessorials from './accessorials/Accessorials';
import DataTariffUpdatePortal from './Data/DataTariffUpdatePortal';
import AirportTransfer from './airportTransfer/AirportTransfer';
import CitiesServed from './CitiesServed';
import LinehaulRate from './linehaulRates/LinehaulRate';
import Notes from './Notes';
import SpecialCharges from './specialRates/SpecialCharges';
import StationInfo from './stationInfo/StationInfo';
import TariffRate from './tariffRates/TariffRate';
import TariffUpdatePortalHome from './TariffUpdatePortalHome';
//import { useSelector, useDispatch} from 'react-redux';
//import {tariffCodeUpdate} from '../../../../store/actions/actionTypes'
const TariffUpdatePortal = () => {
    
const { onChangeTariff,onSubmitSearch,onValidateTariff,loadTariffInfo,loadTariffRates,loadAccessorials,loadSpecailrates,setLoading,
    handleStationInfoData,
    filtersInput,filtersReq,airportCodesList,stationInfoData, tariffRateData,surchargeRateData,specialRateData,
    tariffRatesLoading, tariffInfoLoading,surchargeLoading, specialLoading, 
    descriptionList,chargeTypeList, } = DataTariffUpdatePortal();
    //const dispatch = useDispatch();
    // const selectedTariff = () =>{
    //     onChangeTariff();
    //     dispatch(tariffCodeUpdate(filtersInput.airportcode));
    // }

  return (
    <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Tariff Update Portal </h4>
                    </div>
                    <form>
                    <div className="col-md-8 px-0">
                        <div className="row mb-3">
                            <div className="col-md-4 ">
                                <label htmlFor="acNo">Select Station for Tariff Update </label>
                                <SelectElement id="airportcode" name="airportcode" value={filtersInput.airportcode || ''} 
                                    className={filtersReq.airportcode_ErrorClass} optionsData={airportCodesList}
                                    onChange={onChangeTariff} onInvalid={onValidateTariff} validation="required" 
                                    placeholder='- Choose Airport -' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 pt-4">
                                <button className="btn btn-primary btn-block pull-right" type="button" onClick={onSubmitSearch} > SELECT <i className="fas fa-check-circle"></i> </button>
                            </div>
                        </div>
                    </div>
                    
                </form>
                    <hr className="mb-3"/>
                    {filtersInput.airportcode &&
                    <div className="row mb-3 mt-3 tabsDiv">
                        <div className="col-md-12">
                            <Tabs defaultActiveKey="tab-home" >
                                <Tab  eventKey="tab-home" title="Home">
                                    <TariffUpdatePortalHome />
                                </Tab>
                                <Tab eventKey="tab-stationInfo" title="Station Info">
                                    <StationInfo tariff={stationInfoData} restFunCall={loadTariffInfo} airCode={filtersInput.airportcode} loading={tariffInfoLoading} setLoad={setLoading} handleInputChange={handleStationInfoData}/>
                                </Tab>
                                <Tab eventKey="tab-tariffRate" title="Tariff Rate">
                                    <TariffRate tariff={tariffRateData} restFunCall={loadTariffRates} airCode={filtersInput.airportcode} loading={tariffRatesLoading} setLoad={setLoading}/>
                                </Tab>
                                <Tab eventKey="tab-accessorials" title="Accessorials">
                                    <Accessorials tariff={surchargeRateData} restFunCall={loadAccessorials} airCode={filtersInput.airportcode}  loading={surchargeLoading} descriptionList={descriptionList} chargeTypeList={chargeTypeList} setLoad={setLoading}/>
                                </Tab>
                                <Tab eventKey="tab-specialCharges" title="Special Charges">
                                    <SpecialCharges tariff={specialRateData} restFunCall={loadSpecailrates} airCode={filtersInput.airportcode}  loading={specialLoading} setLoad={setLoading}/>
                                </Tab>
                                <Tab eventKey="tab-airportTransfer" title="Airport Transfer">
                                    <AirportTransfer tariff={specialRateData} restFunCall={loadSpecailrates} airCode={filtersInput.airportcode}  loading={specialLoading} setLoad={setLoading}/>
                                </Tab>
                                <Tab eventKey="tab-linehaulRates" title="Linehaul Rates">
                                <LinehaulRate tariff={tariffRateData} restFunCall={loadTariffRates} airCode={filtersInput.airportcode} loading={tariffRatesLoading} setLoad={setLoading}/>
                                </Tab>
                                <Tab eventKey="tab-citiesServed" title="Cities Served">
                                    <CitiesServed />
                                </Tab>
                                <Tab eventKey="tab-notes" title="Notes">
                                    <Notes />
                                </Tab>
                            </Tabs>
                            
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
  )
}

export default TariffUpdatePortal