import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const InvoiceDatatable = (props) => {
    
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
      const navigate = useNavigate();
    useEffect(() => {
        let table = $("#invoicesTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : props.reportData,
            "dom": 
                "<'row'<'col-lg-3 col-md-5'l><'col-lg-5 col-md-7 text-right'B><'col-lg-4 col-md-8 offset-lg-0 offset-md-4'f>>" +
                "<'row'<'col-lg-12'tr>>" +
                "<'row'<'col-md-5'i><'col-md-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'Invoice',
                  title: 'Customer Invoice',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'Invoice',
                  title: 'Customer Invoice',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'Invoice',
                  title: 'Customer Invoice',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'Invoice',
                  title: 'Customer Invoiced',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            
            "columns": [
                { "data" : "Invoice",
                    "render": function (data, type, row) {
                        let _html = "<span class='btn-link invoiceDet' id='invoice_"+data+"'>"+data+"</span>";
                        return _html;
                    }
                },
                { "data" : "HAWB" },
                { "data" : "InvoiceDate" },
                { "data" : "ShipDate" },
                { "data" : "Territory" },
                { "data" : "Type" },
                { "data" : "Weight" },
                { "data" : "Amount" },
               // { "data" : "invoice_id" }
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });

    $('#invoicesTbl tbody').on('click' ,'tr td span.invoiceDet' , function (event) {
        var tr = $(this).closest('tr');
        let row_data = table.row( tr ).data() ;
        // console.log(JSON.stringify(row_data));
        let invoiceNo = (row_data.Invoice).trim();
        props.fetchInvoiceDetailsData(invoiceNo, navigate);
    });

    },[props.showDataLoading]);
    return (
        
            <div id="datatable">
            <table id="invoicesTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Invoice</th>
                        <th>HAWB</th>
                        <th>Invoice Date</th>
                        <th>Ship Date</th>
                        <th>Territory</th>
                        <th>Type</th>
                        <th>Weight</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            </div>
       
    );
}

export default InvoiceDatatable;