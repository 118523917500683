import { data } from 'jquery';
import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { HashLoader } from 'react-spinners';
import DatePickerElement from '../formControls/DatePickerElement';
import InputElement from '../formControls/InputElement';
import SelectElement from '../formControls/SelectElement';
import TextareaElement from '../formControls/TextareaElement';
import { signUpForm } from '../services/restAPI/UtilsRest';
import DataSignUp from './DataSignUp';


function SignUp() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);

    const { handleInputChange, onSubmitForm, onValidate, clearForm, getAccountInfo, handleInputChangeNumeric, handleInputChangeNumericPh, handleInputChangeAlphaNumeric, handleInputChangeAlpha, addPhFormat, setErrorState, setInputData
        , baseStation, dataInput, dataInputReq, timeZoneList, pswdMatch, emailMatch } = DataSignUp();
    const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
    const [loading, setLoading] = useState(false);

    const [dataRequired, setDataRequired] = useState({

        plen: 'text-danger',
        plow: 'text-danger',
        pup: 'text-danger',
        pnum: 'text-danger',
        psp: 'text-danger',
        pborder: 'col-md-12 mt-2 mb-2 passBorderRed'

    })
    const validatePassword = () => {
    
        var p = dataInput.password;
        var errMes = '';
        var errMess = {}
        var errState = true;

        if (p.search(/[a-z]/) < 0) {
            errMes = "Password must contain at least one letter.";
            errState = false;
            Object.assign(errMess, { ['plow']: 'text-danger' });
        } else {
            Object.assign(errMess, { ['plow']: 'text-success' });
        }
        if (p.search(/[A-Z]/) < 0) {
            errMes = "Password must contain at least one Upper letter.";
            errState = false;
            Object.assign(errMess, { ['pup']: 'text-danger' });
        } else {
            Object.assign(errMess, { ['pup']: 'text-success' });
        }
        if (p.search(/[0-9]/) < 0) {
            errMes = "Password must contain at least one digit.";
            errState = false;
            Object.assign(errMess, { ['pnum']: 'text-danger' });
        } else {
            Object.assign(errMess, { ['pnum']: 'text-success' });
        }
        if (p.search(/[(!*@#$%^&+=)]/) < 0) {
            errMes = "Password must contain at least one (!*@#$%^&+=) special characters";
            errState = false;
            Object.assign(errMess, { ['psp']: 'text-danger' });
        } else {
            Object.assign(errMess, { ['psp']: 'text-success' });
        }

        if (p.length < 8) {
            errMes = "Password must be at least 8 characters";
            errState = false;
            Object.assign(errMess, { ['plen']: 'text-danger' });
        } else {
            Object.assign(errMess, { ['plen']: 'text-success' });
        }
        let errObject={};
        if (!errState) {
            Object.assign(errMess, { ['newpassCS']: 'form-control is-invalid' });
            Object.assign(errMess, { ['newpasMes']: 'required.' });
            Object.assign(errMess, { ['pborder']: 'col-md-12 mt-2 mb-2 passBorderRed' });
            errObject.password_ErrorClass = 'form-control is-invalid'
            errObject.password=true;
        }
        else {
            Object.assign(errMess, { ['newpassCS']: 'form-control is-valid' });
            Object.assign(errMess, { ['newpasMes']: '' });
            Object.assign(errMess, { ['pborder']: 'col-md-12 mt-2 mb-2 passBorderGreen' });
            errObject.password_ErrorClass = 'form-control'
            errObject.password=false;
        }
        setDataRequired({ ...dataRequired, ...errMess })
        setErrorState(errObject);
        return errState;
    }
    const submitForm = () => {
        let isValidationReq = onSubmitForm();
        // alert("Coming Soon......")
        let errObj = [];

        if (!isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            signUpForm(dataInput)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        setSaveMessage({ showMsg: true, status: 'error', message: 'Error in submission of Signup Form' });

                    } else {
                        setSaveMessage({ showMsg: true, status: 'message', message: dRes });

                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(r => {
                    setLoading(false);
                })
            setTimeout(() => {
                clearForm();
                setSaveMessage({ showMsg: false, status: '', message: '' });
            }, 5000);
        }

    }

    const clearPage = () => {
        setSaveMessage({ showMsg: false, status: '', message: '' });
        clearForm();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    const [passwordType, setPasswordType] = useState("password");
    const [confpasswordType, setConfPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    const toggleConfPassword = () => {
        if (confpasswordType === "password") {
            setConfPasswordType("text")
        } else {
            setConfPasswordType("password")
        }
    }

    return (
        <section className="mt-180 mb-4">
            <div className={loading ? 'container parentDisable' : 'container'}>
                {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'} />}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom">
                            <div className="d-flex">
                                <h4 className="mb-2 pageHead text-uppercase">New Sign Up Form</h4>
                            </div>
                            <form className="needs-validation">
                                {saveMessage.showMsg &&
                                    <div className="row">
                                        <div className="col-md-12 text-center text-bold">
                                            {saveMessage.status === "error" ?
                                                <div className="alert alert-danger">
                                                    <i className="fas fa-times-circle"></i>
                                                    {saveMessage.message}
                                                </div>
                                                :
                                                <div className="alert alert-primary">
                                                    {saveMessage.message}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="row mb-3">
                                    <div className="col-md-3 mt-2 text-right ">
                                        <label htmlFor="acNo" className="align-right">Enter Account Number </label>
                                    </div>
                                    <div className="col-md-3">
                                        <InputElement id="acNo" name="acNo" value={dataInput.acNo || ''} className={dataInputReq.acNo_ErrorClass}
                                            onChange={handleInputChange} onInvalid={onValidate} validation="required"
                                            placeholder='Account Number' />
                                        <div className="invalid-feedback">
                                            required.
                                    </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-primary btn-block pull-left" type="button"
                                            onClick={getAccountInfo}
                                        >Continue... &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                    </div>
                                </div>
                                <hr className="mb-3" />
                                <div className='container-fluid backgroundlight'>
                                    <h5 className="mb-3">Your Company Contact Information</h5>
                                </div>
                                <hr className="mb-3" />
                                <div className="row mb-3">
                                    <div className="col-md-4 ">
                                        <label htmlFor="companyName">Company Name</label>
                                        <InputElement id="companyName" name="companyName" value={dataInput.companyName || ''}
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='Company Name' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="address">Address</label>
                                        <InputElement id="address" name="address" value={dataInput.address || ''}
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='Company Address' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="city">City</label>
                                        <InputElement id="city" name="city" value={dataInput.city || ''} className="form-control"
                                            onChange={handleInputChange} maxLength="25" placeholder='City' />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4 ">
                                        <label htmlFor="state">State</label>
                                        <InputElement id="state" name="state" value={dataInput.state || ''}
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='State' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="zip">ZipCode</label>
                                        <InputElement id="zip" name="zip" value={dataInput.zip || ''}
                                            //   onChange={handleInputChangeAlphaNumeric}  
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='ZipCode' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="tel">TelePhone</label>
                                        <InputElement id="tel" name="tel" value={dataInput.tel || ''} className="form-control"
                                            //    onChange={handleInputChangeNumericPh}  validation="phoneNo" onInvalid={addPhFormat}
                                            onChange={handleInputChange} maxLength="25" placeholder='Telephone' />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4 ">
                                        <label htmlFor="fax">Fax</label>
                                        <InputElement id="fax" name="fax" value={dataInput.fax || ''}
                                            //   onChange={handleInputChangeNumeric}    validation="isNumberAndNotZero"
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='Fax' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="countryCode">Country Code</label>
                                        <InputElement id="countryCode" name="countryCode" value={dataInput.countryCode || ''}
                                            //    onChange={handleInputChangeAlpha} 
                                            onChange={handleInputChange} maxLength="25" className="form-control" placeholder='Country Code' />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="compAdmin">Company administrator</label>
                                        <InputElement id="compAdmin" name="compAdmin" value={dataInput.compAdmin || ''} className="form-control"
                                            onChange={handleInputChange} maxLength="25" placeholder='Company administrator' />
                                    </div>
                                </div>

                                <hr className="mb-3" />
                                <div className='container-fluid backgroundlight'>
                                    <h5 className="mb-3">New Account Registration for User</h5>
                                </div>
                                <hr className="mb-3" />
                                <div className="row mb-3">
                                    <div className="col-md-4 ">
                                        <label htmlFor="firstName">First Name  *</label>
                                        <InputElement id="firstName" name="firstName" value={dataInput.firstName || ''}
                                            onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.firstName_ErrorClass}
                                            placeholder='Your First Name' maxLength="50" />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="lastName">Last Name  *</label>
                                        <InputElement id="lastName" name="lastName" value={dataInput.lastName || ''}
                                            onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.lastName_ErrorClass}
                                            placeholder='Your Lastname' maxLength="50" />

                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="userName">Username  *</label>
                                        <InputElement id="userName" name="userName" value={dataInput.userName || ''}
                                            onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.userName_ErrorClass}
                                            placeholder='Username' maxLength="50" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row mb-3">
                                            <div className="col-md-6 ">
                                                <label htmlFor="password">Password  *</label>
                                                <div className="input-group mb-3">
                                                    <InputElement id="password" name="password" type={passwordType} value={dataInput.password || ''}
                                                        onChange={handleInputChange} onInvalid={validatePassword} validation="required" className={dataInputReq.password_ErrorClass}
                                                        placeholder='Password' maxLength="50" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" onClick={togglePassword}>
                                                            {passwordType === "password" ? <i className="fas fa-eye" id="show_eye"></i> :
                                                                <i className="fas fa-eye-slash" id="hide_eye"></i>}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="confirmPass">Confirm Password  *</label>
                                                <div className="input-group mb-3">
                                                    <InputElement id="confirmPass" name="confirmPass" type={confpasswordType} value={dataInput.confirmPass || ''}
                                                        onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.confirmPass_ErrorClass}
                                                        placeholder='Confirm Password' maxLength="50" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" onClick={toggleConfPassword}>
                                                            {confpasswordType === "password" ? <i className="fas fa-eye" id="show_eye"></i> :
                                                                <i className="fas fa-eye-slash" id="hide_eye"></i>}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="email">Email  *</label>
                                                <InputElement id="email" name="email" value={dataInput.email || ''}
                                                    onChange={handleInputChange} onInvalid={onValidate} validation="email" className={dataInputReq.email_ErrorClass}
                                                    placeholder='Email' maxLength="50" />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label htmlFor="confEmail">Confirm Email  *</label>
                                                <InputElement id="confEmail" name="confEmail" value={dataInput.confEmail || ''}
                                                    onChange={handleInputChange} onInvalid={onValidate} validation="email" className={dataInputReq.confEmail_ErrorClass}
                                                    placeholder='Confirm Email' maxLength="50" />

                                            </div>

                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="secQues">Secure Question  *</label>
                                                <InputElement id="secQues" name="secQues" value={dataInput.secQues || ''}
                                                    onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.secQues_ErrorClass}
                                                    placeholder='Secure Question' />

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="secAns">Secure Answer  *</label>
                                                <InputElement id="secAns" name="secAns" value={dataInput.secAns || ''}
                                                    onChange={handleInputChange} onInvalid={onValidate} validation="required" className={dataInputReq.secAns_ErrorClass}
                                                    placeholder='Secure Answer' />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6 ">
                                                <label htmlFor="timeZone">Time Zone</label>
                                                <SelectElement id="timeZone" name="timeZone" value={dataInput.timeZone || ''}
                                                    className="form-control"
                                                    optionsData={timeZoneList}
                                                    onChange={handleInputChange}
                                                    placeholder='- Choose Time Zone -' />

                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="clBaseStation">Closest Base Station:</label>
                                                <SelectElement id="clBaseStation" name="clBaseStation" value={dataInput.clBaseStation || ''}
                                                    className="form-control"
                                                    optionsData={baseStation}
                                                    onChange={handleInputChange}
                                                    placeholder='- Closest Base Station -' />
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-4 ">
                                    <div className="row" >
                                        <div className={dataRequired.pborder} >
                                            <span className={dataRequired.plen}> Password MUST have at least 8 Characters</span><br></br>
                                            <span className={dataRequired.plow}> Password MUST have at least 1 lowercase letter</span><br></br>
                                            <span className={dataRequired.pup}> Password MUST have at least 1 UPPERCASE letter</span><br></br>
                                            <span className={dataRequired.pnum}> Password MUST have at least 1 number</span><br></br>
                                            <span className={dataRequired.psp}> Password MUST have at least 1 of the following special character (!*@#$%^&+=)</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>&nbsp;</label>
                                        <div>
                                            {pswdMatch === true ?
                                                <span className='text-success'><i className='fa fa-check-circle'></i> Password and Confirm Password match</span>
                                                :
                                                pswdMatch === false ?
                                                    <span className='text-danger'><i className='fa fa-times-circle'></i> Password and Confirm Password doesnot match</span>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label>&nbsp;</label>
                                        <div>
                                            {emailMatch === true ?
                                                <span className='text-success'><i className='fa fa-check-circle'></i> Email and Confirm Email match</span>
                                                :
                                                emailMatch === false ?
                                                    <span className='text-danger'><i className='fa fa-times-circle'></i> Email and Confirm Email doesnot match</span>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                        <br></br><br></br><br></br>
                                        <h6 className="backgroundlight">Note: * = Required fields.</h6>
                                        
                                    </div>
                                </div>
                                <hr className="mb-3" />

                                <div className="row">
                                        <div className='container-fluid backgroundlight'>
                                            <h6 className="mt-0 mb-0">Please select item(s) you would like to be notified.</h6>
                                        </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4 ">
                                        <div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="fuelUpdates" name="fuelUpdates"
                                                checked={dataInput.fuelUpdates}
                                                onChange={handleInputChange} />
                                            <label className="form-check-label" htmlFor="fuelUpdates" >
                                                Notify by email all Fuel Surcharge Updates
											  </label>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="tariffUpdates" name="tariffUpdates"
                                                checked={dataInput.tariffUpdates}
                                                onChange={handleInputChange} />
                                            <label className="form-check-label" htmlFor="tariffUpdates" >
                                                Notify by email all Tariff Updates
											  </label>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="ffUpdates" name="ffUpdates"
                                                checked={dataInput.ffUpdates}
                                                onChange={handleInputChange} />
                                            <label className="form-check-label" htmlFor="ffUpdates" >
                                                Notify by email all Freight Force Updates
											  </label>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-3" />
                                <div className="row mb-2">
                                    <div className="col-md-3 mt-2 text-right ">

                                    </div>
                                    <div className="col-md-3">

                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-primary btn-block pull-left" type="button"
                                            onClick={clearForm}
                                        >Clear Form &nbsp; </button>
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-primary btn-block pull-right" type="button"
                                            onClick={submitForm}
                                        >Submit &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                    </div>
                                </div>
                                <hr className="mb-3" />
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default SignUp