import React from 'react'
import InputElement from '../../formControls/InputElement'
import SelectElement from '../../formControls/SelectElement'
import { NavLink } from 'react-router-dom';
import { useSelector} from 'react-redux'

const TariffRateReport =  (props) => {
    const userProfile = useSelector((state) => state.usr)
    const handleMenuClick = (e) => {
        e.preventDefault();
        return false;
    }
  return (
    <>
        <form>
            <div className="col-lg-8 col-md-12 px-0">
                <div className="row mb-3">
                    <div className="col-md-3 ">
                        <label htmlFor="acNo">Airport Code</label>
                        <SelectElement id="airportcode" name="airportcode" value={props.filtersInput.airportcode || ''} 
                            className={props.filtersReq.airportcode_ErrorClass} optionsData={props.airportCodesList}
                            onChange={props.onChangeTariff} onInvalid={props.onValidateTariff} validation="required" 
                            placeholder='- Choose Airport -' />
                        <small className={props.filtersReq.airportcode_ErrorClass}>
                            required.
                        </small>
                    </div>
                    { (userProfile && userProfile.permissionsLevel &&  userProfile.permissionsLevel !== 1 &&  userProfile.permissionsLevel !== 2 ) ?
                    <div className="col-md-3">
                        <label htmlFor="accountId">Account ID</label>
                        <InputElement id="accountId" name="accountId" value={props.filtersInput.accountId || ''} className={props.filtersReq.accountId_ErrorClass}
                            onChange={props.onChangeTariff} onInvalid={props.onValidateTariff} 
                            placeholder='Account ID' />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    :''}
                    <div className="col-md-3 pt-4">
                        <button className="btn btn-primary btn-block pull-right" type="button" onClick={props.onSubmitSearch}> SELECT <i className="fas fa-check-circle"></i> </button>
                    </div>
                    <div className="col-md-3 pt-4 mt-1">
                        <NavLink to="/coverage"  onClick={handleMenuClick} className="splTxt"> <i className="fas fa-question-circle"></i> Published Rates</NavLink>
                    </div>
                </div>
            </div>
        </form>
    </>
  )
}

export default TariffRateReport