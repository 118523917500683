import React,{useEffect} from 'react'
import formjpg from '../../../assets/img/img_forms.jpg'
import { NavLink } from 'react-router-dom';

const Forms = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">FORMS</h4>
                        </div>
						<div className="row">
                            <div className="col-md-5 ">
							<img src={formjpg} className="img-fluid" alt='FORMS'/>
							</div>
							<div className="col-md-7 text-justify">
							<p>
                                Freight Force has a complete library of forms to choose from, just click and go.  If there's something you don't see, please, call our sales department at 714.995.9300 ext 40060
                            </p>
                            <ul>
                                <li>Credit Application: For credit approval complete the attached forms, sign and return for process.</li>
                                <li>Claim Form: Complete all information, attach necessary paperwork and return.</li>
                                <li>Cartage Service Conditions</li>
                                <li>Truckload Service Conditions</li>
                                <li>Air Cargo Terms & Conditions</li>
                            </ul>
                            <p>Freight Force:  Separate terms and conditions for cartage, truckload, and air cargo freight.</p>
                            <div className="row">
								<div className="col-md-2 p-0 grayCard">
                                <a href="http://ms.freightforce.com/general/pdfForms/FFCANApp_Kit.pdf" target="_blank">
									<i className="fas fa-download my-3"></i>
									<span>Credit <br/>Application</span>
                                </a>
								</div>
								<div className="col-md-3 p-0 grayCard">
                                <a href="http://ms.freightforce.com/general/pdfForms/AirCargoTermsAndConditions.pdf" target="_blank">
									<i className="fas fa-download my-3"></i>
									<span>Air Cargo <br/>Terms & Condition</span>
                                </a>
								</div>
								<div className="col-md-2 p-0 grayCard">
                                <a href="http://ms.freightforce.com/general/pdfForms/Service_Conditions.pdf" target="_blank">
									<i className="fas fa-download my-3"></i>
									<span>Cartage Service <br/>Conditions</span>
                                </a>
								</div>
								<div className="col-md-3 p-0 grayCard">
                                <a href="http://ms.freightforce.com/general/pdfForms/Truckload_Service_Conditions.pdf" target="_blank">
									<i className="fas fa-download my-3 "></i>
									<span>Truckload <br/>Service Conditions</span>
                                </a>
								</div>
								<div className="col-md-2 p-0 grayCard">
                                    <NavLink to="/forms/cargoClaim" title="Cargo Claim Form">
                                    <i className='fas fa-file my-3'></i> 
                                    <span>Cargo Claim Form</span>
                                    </NavLink>
								</div>
							</div>
                            {/* <ul style={{'list-style':'none'}}>
                                <li><a href="http://ms.freightforce.com/general/pdfForms/FFCANApp_Kit.pdf" target="_blank">
                                    <i className='fa fa-download'></i> &nbsp; Credit Application</a></li>
                                <li><a href="http://ms.freightforce.com/general/pdfForms/AirCargoTermsAndConditions.pdf" target="_blank">
                                    <i className='fa fa-download'></i> &nbsp; Air Cargo Terms & Conditions</a></li>
                                <li><a href="http://ms.freightforce.com/general/pdfForms/Service_Conditions.pdf" target="_blank">
                                    <i className='fa fa-download'></i> &nbsp; Cartage Service Conditions</a></li>
                                <li><a href="http://ms.freightforce.com/general/pdfForms/Truckload_Service_Conditions.pdf" target="_blank">
                                    <i className='fa fa-download'></i> &nbsp; Truckload Service Conditions</a></li>
                                <li>
                                    <NavLink to="/forms/cargoClaim" title="Cargo Claim Form">
                                        <i className='fa fa-file'></i> &nbsp; Cargo Claim Form
                                    </NavLink>
                                    </li>
                            </ul> */}
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Forms