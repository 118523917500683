import React from "react";

const PreviewPDF = (props) => {
    let loadFileUrl = '';
    let loadFileTitle = '';
    let fileKey = props.fileKey;
    if( fileKey ){
        if( fileKey==="tsa" ){
            loadFileUrl = 'https://ms.freightforce.com/general/pdfForms/TSA_Information_StepbyStep.pdf';
            loadFileUrl += "#toolbar=1&navpanes=1&scrollbar=1&download=1";
            loadFileTitle = 'How to Access TSA Information';
        }else if( fileKey==="emailCrown" ){
            loadFileUrl = 'https://ms.freightforce.com/general/pdfForms/Crown2CrownInstructions.pdf';
            loadFileUrl += "#toolbar=1&navpanes=1&scrollbar=1&download=1";
            loadFileTitle = "How to Email from Crown";
        }else if( fileKey==="quickGuide" ){
            loadFileUrl = 'https://ms.freightforce.com/EmailPOD.aspx';
            loadFileUrl += "#toolbar=1&navpanes=1&scrollbar=1&download=1";
            loadFileTitle = "Quick Guide";
        }
    }
    return (
        <div className="card card-custom">
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase">
                    {loadFileTitle}
                </h4>
            </div>
        <iframe src={loadFileUrl} title={loadFileTitle} height={500}></iframe>
        </div>
    );
}

export default PreviewPDF;