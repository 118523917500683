import React, { useEffect, useState } from 'react'
import DataTariffManagement from '../Data/DataTariffManagement';
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../../formControls/InputElement";
import { processLinkCustomerAccountReq } from "../../../restAPI/CorporateCenterLinkRest";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const ViewUserAccess = () => {
  const {handleInputChange, onSubmitForm, onValidateFilters, loadReportData, filtersInput, clearForm, filtersReq, reportData, showDataLoading} = DataTariffManagement();

    const [loading, setLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); // eslint-disable-line
    const [saveMessage, setSaveMessage] = useState({ showMsg : false, status : '' });
  
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const saveLinkAccount = () =>{
        let isValidationReq = onSubmitForm();

        if (!isValidationReq) {
            setLoading(true);
            processLinkCustomerAccountReq(filtersInput)
            .then(linkCaRes => {
                if (linkCaRes instanceof Error) {
                    // console.log("erro objecct is final = " + JSON.stringify(linkCaRes))
                    setErrorStatus({ restError: true, restErrorMessage: linkCaRes.message });
                    setSaveMessage({ showMsg : true, status : 'fail' });
                } else {
                    setErrorStatus({ restError: false, restErrorMessage: '' });
                    // console.log("linkCaRes in final==" + JSON.stringify(linkCaRes));
                    // dispatch(setQuickQuote(quoteReqRes));
                    setSaveMessage({ showMsg : true, status : 'success' });
                    loadReportData();
                    // clearForm();
                }
                setLoading(false);
                setTimeout(()=>{
                    setSaveMessage({ showMsg : false, status : '' });
                    clearForm();
                },2000);
            })
        }
    }

    useEffect(() => {
        let table = $("#tariffUserAccess").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : reportData,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                { "data" : "link" },
                { "data" : "customerId" },
                { "data" : "company" },
                { "data" : "link" },
                { "data" : "customerId" },
                
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    // "className": "text-center",
                    // "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[reportData]);

    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'}/>}
        <div className="card card-custom">
        {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
        <div id="datatable">
            <table id="tariffUserAccess" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Station</th>
                        <th>Tariff Update</th>
                        <th>Select User</th>
                        {/* <th>Edit</th>
                        <th>Zone</th>
                        <th>Min</th>
                        <th>New Min</th>
                        <th>100</th>
                        <th>New 100</th>
                        <th>500</th>
                        <th>New 500</th>
                        <th>1000</th>
                        <th>New 1000</th>
                        <th>2000</th>
                        <th>New 2000</th>
                        <th>3000</th>
                        <th>New 3000</th>
                        <th>5000</th>
                        <th>New 5000</th>
                        <th>10000</th>
                        <th>New 10000</th>
                        <th>15000</th>
                        <th>New 15000</th>
                        <th>20000</th>
                        <th>New 20000</th>
                        <th>Cap</th>
                        <th>New Cap</th> */}
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        {/* <hr className="mb-3"/>
            <div className="d-flex">
                <h5 className="mb-3">Add New Zone</h5>
            </div>
					<form className="needs-validation" >
              { saveMessage.showMsg &&
              <div className="row mb-3">
                  <div className="col-md-12 text-center text-bold">
                      { saveMessage.status==="fail" &&
                          <div className="alert alert-danger">
                              <i className="fas fa-times-circle"></i> Error in save
                          </div>
                      }
                      { saveMessage.status==="success" &&
                          <div className="alert alert-success">
                              <i className="fas fa-check-circle"></i> Data save success
                          </div>
                      }
                  </div>
              </div>
                  }
      <div className="row mb-3">
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
          <div className="col-md-1">
              <label htmlFor="from">Zone</label>
              <InputElement type="text" id="zone" name="zone" 
                  onChange={handleInputChange} 
                  />
              <div className="invalid-feedback">
                  required.
              </div>
          </div>
        
      </div>
      <div className="row">
          <div className="col-md-2 offset-8">
              <button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={clearForm}><i className="fas fa-times-circle"></i> Cancel</button>
          </div>
          <div className="col-md-2 ">
              <button className="btn btn-primary btn-block pull-right" type="button" onClick={saveLinkAccount}>Save &nbsp;<i className="fas fa-check-circle"></i>  </button>
          </div>
      </div> 
  </form> */}
        </div>
        </div>
        )
}

export default ViewUserAccess;