import React, { useState } from 'react';
import { ListGroup, ListGroupItem, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AppLogo from '../../assets/img/logo_freightforce.png';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showAboutUs, setshowAboutUs] = useState(false);
    const [showService, setshowService] = useState(false);
    const [showQuoteBook, setshowQuoteBook] = useState(false);
    const [showTariffMenu, setTariffMenu] = useState(false);
    const userProfile = useSelector((state) => state.usr)

    const handleMenuClick = (e) => {
        // e.preventDefault();
        // return false;
    }

    const handleTariffMenu = (e) => {
        setTariffMenu(!showTariffMenu);
    }
    const handleMobileMenu = (e) => {
        setShowMobileMenu(!showMobileMenu);
    }

    const handleAboutUsMenu = (e) => {
        // console.log(!showAboutUs)
        setshowAboutUs(!showAboutUs);
    }
    const handleServiceMenu = (e) => {
        //console.log(!showService)
        setshowService(!showService);
    }
    const handleQuoteBookMenu = (e) => {
        // console.log(!showQuoteBook)
        setshowQuoteBook(!showQuoteBook);
    }

    const closeMobileMenu = (e) => {
        // setshowQuoteBook(false);
        // setshowService(false);
        setShowMobileMenu(false);


    }



    // if(isMobile){
    //     return <div>Mobile</div>
    // }

    return (
        <header id="header" className="fixed-top d-flex align-items-center ">
            {/* <!-- Start Header --> */}
            <div className="container d-flex align-items-center justify-content-between menusize"> 
                {/* <!-- Uncomment below if you prefer to use an image logo --> */}
                <NavLink to="/" className="logo">
                    <img src={AppLogo} alt="Freight Force" className="img-fluid" />
                </NavLink>
                <nav id="navbar" className={['navbar', showMobileMenu ? 'navbar-mobile' : ''].join(' ')}>
                    {userProfile.username ?
                        <ul>
                           
                            {userProfile.menuPermissions.includes("Quick Quote") ?

                                <li className={showQuoteBook ? 'dropdown' : 'dropdown'}>
                                    <NavLink className="nav-link dropdown-toggle"  to="" title="Quote/Booking" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Quote/Booking &nbsp;<i className="fa fa-chevron-down"></i>
                                </NavLink>
                                    {/* <a className="nav-link" onClick={handleQuoteBookMenu}>
                                        Quote/Booking &nbsp;<i className="fa fa-chevron-down"></i>
                                    </a> */}
                                    <ul className={showQuoteBook ? 'dropdown-active' : ''}>
                                        <li>
                                            <NavLink className="nav-link " to="/freightQuote" onClick={closeMobileMenu} title="Quote/Booking">
                                                Quick Quote/Booking
                                    </NavLink>
                                        </li>
                                        <li>
                                            <NavLink className="nav-link " to="/history" onClick={closeMobileMenu} title="History">
                                                History
                                    </NavLink>
                                        </li>
                                    </ul>
                                </li> : ''}
                                {userProfile.menuPermissions.includes("Coverage") ?
                                <li>
                                    <NavLink className="nav-link " to="/freightDirectBook" onClick={closeMobileMenu} title="Direct Book">
                                        Direct Book
                                </NavLink>
                                </li> : ''}
                            {userProfile.menuPermissions.includes("Tracking") ?
                                <li>
                                    <NavLink className="nav-link " to="/freightTracking" onClick={closeMobileMenu} title="Freight Tracking">
                                        Freight Tracking
                                </NavLink>
                                </li> : ''}

                            {userProfile.menuPermissions.includes("Coverage") ?
                                <li>
                                    <NavLink className="nav-link " to="/coverage" onClick={closeMobileMenu} title="Coverage">
                                        Coverage
                                </NavLink>
                                </li> : ''}
                            {userProfile.menuPermissions.includes("Carrier") ?
                                <li >
                                    <NavLink className="nav-link " to="/carrierDirectory" onClick={closeMobileMenu} title="Carrier Directory">
                                        Carrier Directory
                                </NavLink>
                                </li> : ''}
                            {userProfile.menuPermissions.includes("LineHaul Schedule") ?
                                <li >
                                    <NavLink className="nav-link " to="/regionalLineHaul" onClick={closeMobileMenu} title="Regional LineHaul">
                                        LineHaul Schedule
                                </NavLink>
                                </li> : ''}
                            {userProfile.menuPermissions.includes("Corporate") ?
                                <li >
                                    <NavLink className="nav-link " to="/corporate" onClick={closeMobileMenu} title="Corporate">
                                        Corporate
                                </NavLink>
                                </li> : ''}

                            {userProfile.menuPermissions.includes("Web Account Admin") ?
                                <li >
                                    <NavLink className="nav-link " to="/webAccount" onClick={closeMobileMenu} title="Web Account Admin">
                                        Web Account Admin
                                </NavLink>
                                </li> : ''}
                            {userProfile.menuPermissions.includes("Approved Motor Carrier") ?
                                <li>
                                    <NavLink className="nav-link " to="/amc" onClick={closeMobileMenu} title="Approved Motor Carrier">
                                        Approved <br/> Motor Carrier
                                </NavLink>

                                </li> : ''}

                            {userProfile.menuPermissions.includes("Tariff") ?

                                <li className={showTariffMenu ? 'dropdown' : 'dropdown'}>
                                    <a className="nav-link" onClick={handleTariffMenu}>
                                        {/* Tariff / Rates */}
                                        PU&D Rates /<br/> Linehaul Rates
                                        &nbsp;<i className="fa fa-chevron-down"></i>
                                    </a>
                                    <ul className={showTariffMenu ? 'dropdown-active' : ''}>
                                        <li>
                                            <a href={process.env.REACT_APP_LINUHAUL_RATES} title="I-5 Corridor/Nationwide Linehaul Rates" >I-5 Corridor/Nationwide Linehaul Rates</a>
                                        </li>
                                        <li>
                                            <NavLink className="nav-link " to="/tariffRate" onClick={closeMobileMenu} title="PU & D Rates">
                                                {/* Tariff Rate */}
                                                PU & D Rates
    </NavLink>
                                        </li>
                                    </ul>
                                </li> : ''}

                            <li>
                                <NavLink className="nav-link " to="/fuelSurcharge" onClick={closeMobileMenu} title="Fuel Surcharge">
                                    Fuel Surcharge
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/forms" onClick={closeMobileMenu} title="Forms">
                                    Forms
                                </NavLink>
                            </li>
                        </ul>
                        :
                        <ul>
                            <li>
                                <NavLink className="nav-link " to="/" onClick={closeMobileMenu} title="Home">
                                    Home
                                </NavLink>
                            </li>
                            <li className={showAboutUs ? 'dropdown' : 'dropdown'}>
                                {/* <a className="nav-link dropdown-toggle"  onClick={handleAboutUsMenu} title="About Us" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    About Us &nbsp;<i className="fa fa-chevron-down"></i>
                                </a> */}
                                <a className="nav-link" onClick={handleAboutUsMenu}>
                                    About Us &nbsp;<i className="fa fa-chevron-down"></i>
                                </a>
                                <ul className={showAboutUs ? 'dropdown-active' : ''}>
                                    <li>
                                        <NavLink className="nav-link " to="/aboutUs/ourStory" onClick={closeMobileMenu} title="Our Story">
                                            Our Story
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/aboutUs/ourExecutives" onClick={closeMobileMenu} title="Our Executives">
                                            Our Executives
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/aboutUs/ourMission" onClick={closeMobileMenu} title="Our Mission">
                                            Our Mission
                                    </NavLink>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/tsa" onClick={closeMobileMenu} title="TSA">
                                    TSA
                                </NavLink>
                            </li>
                            {/* <li className="dropdown">
                                <NavLink className="nav-link dropdown-toggle"  to="/services" onClick={handleMenuClick} title="Services" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Services &nbsp;<i className="fa fa-chevron-down"></i>
                                </NavLink> */}
                            <li className={showService ? 'dropdown' : 'dropdown'}>
                                <a className="nav-link" onClick={handleServiceMenu}>
                                    Service &nbsp;<i className="fa fa-chevron-down"></i>
                                </a>
                                <ul className={showService ? 'dropdown-active' : ''}>
                                    <li>
                                        <NavLink className="nav-link " to="/services/cartage" onClick={closeMobileMenu} title="Cartage">
                                            Cartage
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/services/nationwideLineHaul" onClick={closeMobileMenu} title="Regional LineHaul">
                                            Nationwide LineHaul
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/services/residential" onClick={closeMobileMenu} title="Residential">
                                            Residential
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/aboutUs/retailDistribution" onClick={closeMobileMenu} title="Retail Distributions">
                                            Retail Distribution
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/services/whiteGlove" onClick={closeMobileMenu} title="White Glove">
                                            White Glove
                                    </NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-link " to="/services/i5Corridor" onClick={closeMobileMenu} title="I-5 Corridor">
                                            I-5 Corridor
                                    </NavLink>
                                    </li>

                                </ul>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/coverage" onClick={closeMobileMenu} title="Coverage">
                                    Coverage
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/contactUs" onClick={closeMobileMenu} title="Contact Us">
                                    Contact Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/fuelSurcharge" onClick={closeMobileMenu} title="Fuel Surcharge">
                                    Fuel Surcharge
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link " to="/forms" onClick={closeMobileMenu} title="Forms">
                                    Forms
                                </NavLink>
                            </li>
                        </ul>
                    }
                    <i className="fas fa-bars mobile-nav-toggle" onClick={handleMobileMenu}></i>
                </nav>
                {/* <!-- .navbar --> */}
            </div>
            {/* <!-- End Header --> */}
        </header>
    );
};

export default Header;