import { useState } from "react";
const DataBooking = () => {

  
  const [quickQuote, setQuickQuote] = useState({
    shipperFFF: "",
    consigneeFFF: "",
    shiCompanyName: "",
    consiCompanyName: "",
    shiAddress: "",
    consiAddress: "",
    shiCity: "",
    shiState: "",
    shiZipcode: "",
    shiContact: "",
    shiPhone: "",
    consiCity: "",
    consiState: "",
    consiZipcode: "",
    consiContact: "",
    consiPhone: "",
    pickupInfo: "",
    specialPickup: false,
    piReadyTime: "",
    piCloseTime: "",
    deliInfo: "",
    specialDelivery: false,
    deliReadyTime: "",
    deliCloseTime: "",
    totalPcs: "",
    grossWeight: "",
    dimWeightTotal: "",
    hawb: "",
    mawb: "",
    refNo: "",
    originZip: "",
    destinationZip: "",
    msg: "",
    weight: 0,
    qty: 0,
    fsc: 0,
    fsc_rate: 0,
    freight_charge: 0,
    quoteId: "",
    shipCodeFromEntry: true,
    shipCodeToEntry: true,
    fffFrom: false,
    fffTo: false,
    isRoadRunner : false,
    rrBolNumber : "",
    pronumber: "",
    // hazContact:"",
    // hazContact:"",
    // hazName:"",
    // printLabel: false,
    // numOfLabel:0,
    // labelStyle:"",
    // billToCon:false,
    // billToShi:false,
    // billToThi:false,
    // billToReq:false,
  });

  //"6" - "6 Labels/Page
  //"4" - "6*4 Label"
  //"F" -"Full Page Label"

  // const { state } = useLocation();

  //const quickQuoteRes = state.quoteRes;
  //const quickQuoteRes ={};
  // const state = new useLocation();
  // quickQuoteRes = state.quickQuote;

  const onChangeBookHandler = (name, inValue) => {
    //var result = mapedFields.find(eachitem => String(eachitem.field).trim() === String(name).trim());
    var updateValues = { [name]: inValue };
    // console.log(updateValues);
    setQuickQuote({ ...quickQuote, ...updateValues });
    //console.log(JSON.stringify(quickQuote));
   
    

  }
  const updateDimTotals = async (totals) => {
    setQuickQuote({ ...quickQuote, ...totals });
    //console.log("=="+JSON.stringify(totals))
    updateQuoteRequiredStateFromHis(totals.shipCodeFromEntry, totals.shipCodeToEntry);
  }

  const updateQuickQuoteObject = async (address) => {
    setQuickQuote({ ...quickQuote, ...address });
    var errState = {};
    var status = false;
    Object.entries(address).forEach(([name,eachVal]) => {
      let value = eachVal ? false:true
     // console.log("name--",name,"-value--",value)
    //Object.entries(quickQuoteRequired).forEach(([key]) => {
      var key = name;
      
      var errname = key + "_ErrorClass";
      if( errname==="shiState_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none' });
          status = true;
        }
      }else if( errname==="consiState_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none' });
          status = true;
        }
      }else if( errname==="shiCompanyName_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger', 'shiCompanyNameAc_ErrorClass' : 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none', 'shiCompanyNameAc_ErrorClass' : 'text-danger d-none'  });
          status = true;
        }
      }else if( errname==="consiCompanyName_ErrorClass" ){
        if( value ){
          Object.assign( errState , { [name]: true, [errname]: 'text-danger', 'consiCompanyNameAc_ErrorClass' : 'text-danger' });
          status = true;
        }else{
          Object.assign( errState , { [name]: false, [errname]: 'text-danger d-none', 'consiCompanyNameAc_ErrorClass' : 'text-danger d-none'  });
          status = true;
        }
      }else if( key==="shiContact" || key==="shiPhone" || key==="consiContact" || key==="consiPhone" ){ // as these not not mandatory

      }else{
        if (key.trim() === name.trim() && value) {
          Object.assign( errState , { [name]: true, [errname]: 'form-control is-invalid' });
          status = true;
        } else if (key.trim() === name.trim() && !value) {
          Object.assign( errState , { [name]: false, [errname]: 'form-control is-valid' });
          status = true;
        }
    }
  })
    if (status)
      setQuickQuoteRequired({ ...quickQuoteRequired, ...errState });      
  }
  const stateList = [{value: "AL", label: "Alabama - (AL)"},
  //{value: "AK", label: "Alaska - (AK)"},
  {value: "AZ", label: "Arizona - (AZ)"},
  {value: "AR", label: "Arkansas - (AR)"},
  {value: "BC", label: "British Columbia, Canada - (BC)"},
  {value: "CA", label: "California - (CA)"},
  {value: "CO", label: "Colorado - (CO)"},
  {value: "CT", label: "Connecticut - (CT)"},
  {value: "DE", label: "Delaware - (DE)"},
  {value: "DC", label: "District of Columbia - (DC)"},
  {value: "FL", label: "Florida - (FL)"},
  {value: "GA", label: "Georgia - (GA)"},
  {value: "HI", label: "Hawaii - (HI)"},
  {value: "ID", label: "Idaho - (ID)"},
  {value: "IL", label: "Illinois - (IL)"},
  {value: "IN", label: "Indiana - (IN)"},
  {value: "IA", label: "Iowa - (IA)"},
  {value: "KS", label: "Kansas - (KS)"},
  {value: "KY", label: "Kentucky - (KY)"},
  {value: "LA", label: "Louisiana - (LA)"},
  {value: "ME", label: "Maine - (ME)"},
  {value: "MD", label: "Maryland - (MD)"},
  {value: "MA", label: "Massachusetts - (MA)"},
  {value: "MI", label: "Michigan - (MI)"},
  {value: "MN", label: "Minnesota - (MN)"},
  {value: "MS", label: "Mississippi - (MS)"},
  {value: "MO", label: "Missouri - (MO)"},
  {value: "MT", label: "Montana - (MT)"},
  {value: "NE", label: "Nebraska - (NE)"},
  {value: "NV", label: "Nevada - (NV)"},
  {value: "NH", label: "New Hampshire - (NH)"},
  {value: "NJ", label: "New Jersey - (NJ)"},
  {value: "NM", label: "New Mexico - (NM)"},
  {value: "NY", label: "New York - (NY)"},
  {value: "NC", label: "North Carolina - (NC)"},
  {value: "ND", label: "North Dakota - (ND)"},
  {value: "OH", label: "Ohio - (OH)"},
  {value: "OK", label: "Oklahoma - (OK)"},
  {value: "OR", label: "Oregon - (OR)"},
  {value: "PA", label: "Pennsylvania - (PA)"},
  // {value: "PR", label: "Puerto Rico - (PR)"},
  {value: "RI", label: "Rhode Island - (RI)"},
  {value: "SC", label: "South Carolina - (SC)"},
  {value: "SD", label: "South Dakota - (SD)"},
  {value: "TN", label: "Tennessee - (TN)"},
  {value: "TX", label: "Texas - (TX)"},
  {value: "UT", label: "Utah - (UT)"},
  {value: "VT", label: "Vermont - (VT)"},
  {value: "VA", label: "Virginia - (VA)"},
  // {value: "VI", label: "Virgin Islands - (VI)"},
  {value: "WA", label: "Washington - (WA)"},
  {value: "WV", label: "West Virginia - (WV)"},
  {value: "WI", label: "Wisconsin - (WI)"},
  {value: "WY", label: "Wyoming - (WY)"},
  ];
  //"6" - "6 Labels/Page
  //"4" - "6*4 Label"
  //"F" -"Full Page Label"
  const labelStyleList =[
    {value: "6", label: "6 Labels/Page"},
    {value: "4", label: "6*4 Label"},
    {value: "F", label: "Full Page Label"},
  ];
  const labelCountList =[
    {value: "1", label: "1-One"},
    {value: "2", label: "2-Two"},
    {value: "3", label: "3-Three"},
    {value: "4", label: "4-Four"},
    {value: "5", label: "5-Five"},
    {value: "6", label: "6-Six"},
    {value: "7", label: "7-Seven"},
    {value: "8", label: "8-Eight"},
    {value: "9", label: "9-Nine"},
    {value: "10", label: "10-Ten"},
  ];
  const packageTypeList = [{value: "Bag", label: "Bag"},
  {value: "Barrel", label: "Barrel"},
  {value: "Bundle", label: "Bundle"},
  {value: "Bucket", label: "Bucket"},
  {value: "Bale", label: "Bale"},
  {value: "Basket", label: "Basket"},
  {value: "Bulkhead", label: "Bulkhead"},
  {value: "Box", label: "Box"},
  {value: "Chest", label: "Chest"},
  {value: "Coil", label: "Coil"},
  {value: "Carton", label: "Carton"},
  {value: "Cylinder", label: "Cylinder"},
  {value: "Drum", label: "Drum"},
  {value: "Keg", label: "Keg"},
  {value: "Piece(s)", label: "Piece(s)"},
  {value: "Package", label: "Package"},
  {value: "Pail", label: "Pail"},
  {value: "Pallet", label: "Pallet"},
  {value: "Rack", label: "Rack"},
  {value: "Reel", label: "Reel"},
  {value: "Roll", label: "Roll"},
  {value: "Skid", label: "Skid"},
  {value: "Tube", label: "Tube"},
  {value: "Trailer", label: "Trailer"},
  {value: "Tote", label: "Tote"},
  {value: "Trunk", label: "Trunk"},
  {value: "Crate", label: "Crate"}];
  const [quickQuoteRequired, setQuickQuoteRequired] = useState({
    shiCompanyName: true,
    shiCompanyName_ErrorClass: 'form-control',
    shiCompanyNameAc_ErrorClass: 'text-danger d-none',
    shiAddress: true,
    shiAddress_ErrorClass: 'form-control',
    shiCity: true,
    shiCity_ErrorClass: 'form-control',
    shiState: true,
    shiState_ErrorClass: 'text-danger d-none',
    consiCompanyName: true,
    consiCompanyName_ErrorClass: 'form-control',
    consiCompanyNameAc_ErrorClass: 'text-danger d-none',
    consiAddress: true,
    consiAddress_ErrorClass: 'form-control',
    consiCity: true,
    consiCity_ErrorClass: 'form-control',
    consiState: true,
    consiState_ErrorClass: 'text-danger d-none',
    pickupInfo: true,
    pickupInfo_ErrorClass: 'form-control',
    piReadyTime: true,
    piReadyTime_ErrorClass: 'form-control',
    piCloseTime: true,
    piCloseTime_ErrorClass: 'form-control',
    deliInfo: true,
    deliInfo_ErrorClass: 'form-control',
    deliReadyTime: true,
    deliReadyTime_ErrorClass: 'form-control',
    deliCloseTime: true,
    deliCloseTime_ErrorClass: 'form-control',
    hawb: true,
    hawb_ErrorClass: 'form-control',
    // mawb: true,
    // mawb_ErrorClass: 'form-control',
    // refNo: true,
    // refNo_ErrorClass: 'form-control'
  });

  const onConfirmBooking = () => {
    const copy = { ...quickQuoteRequired };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = errState;
        //{ [key]: true, [errname]: 'form-control is-invalid' };
        if( errname==="shiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="consiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="shiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'shiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else if( errname==="consiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'consiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else{
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }
        status = true;
        Object.assign(errObject, errState);
        // console.log("Total Error obj "+JSON.stringify(errObject));
      }
    })
    if (status) {
      setQuickQuoteRequired({ ...quickQuoteRequired, ...errObject });
      // console.log("Copied  Total "+JSON.stringify(totalDimRequired));
    }

    let reqObj = { ...quickQuoteRequired };
    if(reqObj){
    Object.entries(reqObj).forEach(([key, value]) => {
      if (key.includes("_ErrorClass")) {
        delete reqObj[key]
      }
    })
    }
    // console.log("c==="+JSON.stringify(reqObj))
    validationReqFlag = Object.values(reqObj).includes(true);
    // console.log("validationReqFlag=" + validationReqFlag)
    return validationReqFlag;
  }

  const validateAddress = (type) => {
    let copy;
    if(type === "Ship"){
      copy ={  shiCompanyName: quickQuoteRequired.shiCompanyName,
        shiAddress: quickQuoteRequired.shiAddress,
        shiCity: quickQuoteRequired.shiCity,
        shiState: quickQuoteRequired.shiState,
      }
    }else if(type == "Consi"){
      copy = {
        consiCompanyName: quickQuoteRequired.consiCompanyName,
    consiAddress: quickQuoteRequired.consiAddress,
    consiCity: quickQuoteRequired.consiAddress,
    consiState: quickQuoteRequired.consiState,
      }
    }

    
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = null;//{ [key]: true, [errname]: 'form-control is-invalid' };
        if( errname==="shiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="consiState_ErrorClass" ){
          errState = { [key]: true, [errname]: 'text-danger' };
        }else if( errname==="shiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'shiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else if( errname==="consiCompanyName_ErrorClass" ){
          errState = { [key]: true, [errname]: 'form-control is-invalid', 'consiCompanyNameAc_ErrorClass' : 'text-danger' };
        }else{
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }
        status = true;
        Object.assign(errObject, errState);
        // console.log("address Error obj "+JSON.stringify(errObject));
      }
    })
    if (status) {
      setQuickQuoteRequired({ ...quickQuoteRequired, ...errObject });
      // console.log("Copied address object "+JSON.stringify(quickQuoteRequired));
      Object.assign({copy, errObject});
    }

    let reqObj = { ...copy };
    if(reqObj){
    Object.entries(reqObj).forEach(([key, value]) => {
      if (key.includes("_ErrorClass")) {
        delete reqObj[key]
      }
    })
    }
    // console.log("address validation test ==="+JSON.stringify(reqObj))
    validationReqFlag = Object.values(reqObj).includes(true);
    // console.log("validationReqFlag=" + validationReqFlag)
    return validationReqFlag;
  }

  const onValidateBookHandler = (name, value) => {
    var errState = {};
    var status = false;
    //Object.entries(quickQuoteRequired).forEach(([key]) => {
      var errname = name + "_ErrorClass";
      if(  errname==="shiState_ErrorClass" ){
        if( value ){
          errState = { [name]: true, [errname]: 'text-danger' };
          status = true;
        }else{
          errState = { [name]: false, [errname]: 'text-danger d-none' };
          status = true;
        }
      }else if(  errname==="consiState_ErrorClass" ){
        if( value ){
          errState = { [name]: true, [errname]: 'text-danger' };
          status = true;
        }else{
          errState = { [name]: false, [errname]: 'text-danger d-none' };
          status = true;
        }
      }else if(   errname==="shiCompanyName_ErrorClass" ){
        if( value ){
          errState = { [name]: true, [errname]: 'form-control is-invalid', 'shiCompanyNameAc_ErrorClass' : 'text-danger' };
          status = true;
        }else{
          errState = { [name]: false, [errname]: 'form-control is-valid', 'shiCompanyNameAc_ErrorClass' : 'text-danger d-none'  };
          status = true;
        }
      }else if(   errname==="consiCompanyName_ErrorClass" ){
        if( value ){
          errState = { [name]: true, [errname]: 'form-control is-invalid', 'consiCompanyNameAc_ErrorClass' : 'text-danger' };
          status = true;
        }else{
          errState = { [name]: false, [errname]: 'form-control is-valid', 'consiCompanyNameAc_ErrorClass' : 'text-danger d-none'  };
          status = true;
        }
      }else{
        if ( value) {
          errState = { [name]: true, [errname]: 'form-control is-invalid' };
          status = true;
        } else if ( !value) {
          errState = { [name]: false, [errname]: 'form-control is-valid' };
          status = true;
        }
      }
    //})
    if (status)
      setQuickQuoteRequired({ ...quickQuoteRequired, ...errState });
    //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
  }

  const updateQuoteRequiredState = (shipCodeFromEntry, shipCodeToEntry) => {
    let shipperDetails = [], consigneeDetails = [];

    if (shipCodeFromEntry === true) {
      shipperDetails = {
        shiCompanyName: true,
        shiAddress: true,
        shiCity: true,
        shiState: true
      }
    } else {
      shipperDetails = {
        shiCompanyName: false,
        shiAddress: false,
        shiCity: false,
        shiState: false
      }
    }

    if (shipCodeToEntry === true) {
      consigneeDetails = {
        consiCompanyName: true,
        consiAddress: true,
        consiCity: true,
        consiState: true,
      }
    } else {
      consigneeDetails = {
        consiCompanyName: false,
        consiAddress: false,
        consiCity: false,
        consiState: false,
      }
    }

    let pickupDetails = {
      pickupInfo: false,
      piReadyTime: false,
      piCloseTime: false,
      deliInfo: false,
      deliReadyTime: false,
      deliCloseTime: false,
      //refNo: false,
    }

    let newObject = { ...shipperDetails, ...consigneeDetails, ...pickupDetails };
    // console.log("newObj="+JSON.stringify(newObject));
    setQuickQuoteRequired({ ...quickQuoteRequired, ...newObject });
  }

  const updateQuoteRequiredStateFromHis = (shipCodeFromEntry, shipCodeToEntry) => {
    let shipperDetails = [], consigneeDetails = [];
// console.log("::"+shipCodeFromEntry+"="+shipCodeToEntry)
    if (shipCodeFromEntry === true) {
      shipperDetails = {
        shiCompanyName: (quickQuote.shiCompanyName ? false : true),
        shiAddress: (quickQuote.shiAddress ? false : true),
        shiCity: (quickQuote.shiCity ? false : true),
        shiState: (quickQuote.shiState ? false : true)
      }
    } else {
      shipperDetails = {
        shiCompanyName: false,
        shiAddress: false,
        shiCity: false,
        shiState: false
      }
    }

    if (shipCodeToEntry === true) {
      consigneeDetails = {
        consiCompanyName: (quickQuote.shiCompanyName ? false : true),
        consiAddress: (quickQuote.consiAddress ? false : true),
        consiCity: (quickQuote.consiCity ? false : true),
        consiState: (quickQuote.consiState ? false : true),
      }
    } else {
      consigneeDetails = {
        consiCompanyName: false,
        consiAddress: false,
        consiCity: false,
        consiState: false,
      }
    }

    let pickupDetails = {
      pickupInfo: false,
      piReadyTime: false,
      piCloseTime: false,
      deliInfo: false,
      deliReadyTime: false,
      deliCloseTime: false,
      hawb: (quickQuote.hawb ? false : true),
      //validation removed
      // mawb: (quickQuote.mawb ? false : true),
     // refNo: (quickQuote.refNo ? false : true),
    }

    let newObject = { ...shipperDetails, ...consigneeDetails, ...pickupDetails };
    // console.log("newObj="+JSON.stringify(newObject));
    setQuickQuoteRequired({ ...quickQuoteRequired, ...newObject });
  }

  return {
    onChangeBookHandler,
    updateDimTotals,
    onConfirmBooking,
    onValidateBookHandler,
    updateQuoteRequiredState,
    updateQuoteRequiredStateFromHis,
    validateAddress,
    updateQuickQuoteObject,
    quickQuote,
    stateList,
    quickQuoteRequired,
    packageTypeList,
    labelStyleList,
    labelCountList
  }
}
export default DataBooking;