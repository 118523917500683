import React from "react";
import InputElement from "../../formControls/InputElement";
import SelectElement from "../../formControls/SelectElement";


const QuotePreFill = (props) => {
    return (
        <div>
            <div className="row">
                {/* {"linehaul="+props.quotePreFill.lineHaul+ "  linehaulrr="+props.quotePreFill.roadRunner+ "  linehaullocalpu="+props.quotePreFill.localpud+" linehaullh="+props.quotePreFill.lineHaulLh } */}
                <div className="col-lg-6 col-md-12 mb-3">
                    <div className="row">
                        <div className="col-md-4 mb-3 mb-lg-0">
                            <InputElement id="companyName" className={props.preFillRequired.companyName_ErrorClass} value={props.userProfile.companyName || ''} readOnly />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4 mb-3 mb-lg-0">
                            <InputElement id="email" onChange={props.onChangePreFill} className={props.preFillRequired.email_ErrorClass} value={props.userProfile.email || ''} readOnly />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <InputElement id="phone" onChange={props.onChangePreFill} className={props.preFillRequired.phone_ErrorClass} value={props.userProfile.phone || ''} readOnly />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 mb-3">
                    <div className="row">
                    <div className="col-md-5 minusMarginTop">
                        <label>Operations Email<span className="inv-feedback">(Must enter an email)</span></label>
                        <InputElement id="additionalEmails" onChange={props.onChangePreFill} className={props.preFillRequired.additionalEmails_ErrorClass} value={props.quotePreFill.additionalEmails} maxLength="50" placeholder="Operations Email" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-12 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label>Type of Move</label>
                            <SelectElement id="typeOfMove" name="typeOfMove" value={props.quotePreFill.typeOfMove || ''} optionsData={props.typeOfMoveList} onInvalid={props.onValidatePreFill}
                                className={props.preFillRequired.typeOfMove_ErrorClass} onChange={props.onChangePreFill} validation="required" />
                            <div className="invalid-feedback">
                                required.
                            </div>

                        </div>
                        
                        <div className="col-md-6" hidden={true}>
                            <label>Description of Service</label>
                            <SelectElement id="serviceType" name="serviceType" value={props.quotePreFill.serviceType || ''} optionsData={props.serviceTypeList} onInvalid={props.onValidatePreFill}
                                className={props.preFillRequired.serviceType_ErrorClass} onChange={props.onChangePreFill} validation="required" />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label></label>
                            {/* <div className="custom-control custom-checkbox mt-4 pt-2">
                                <InputElement type="checkbox" className="custom-control-input" id="lineHaul" name="lineHaul" 
                                checked={props.quotePreFill.lineHaul} onChange={props.onChangePreFill} />
                                <label className="custom-control-label" htmlFor="lineHaul">Line Haul</label>
                            </div> */}
                            <div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="lineHaulLpud" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangePreFill} value="localpu" onInvalid={props.onValidatePreFill}
                                    checked={props.quotePreFill.localpud} 
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulLpud">Local PU & D</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="lineHaulLh" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangePreFill} value="lineHaul" onInvalid={props.onValidatePreFill}
                                    checked={props.quotePreFill.lineHaulLh}
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulLh">I5-Corridor</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <InputElement type="radio" id="lineHaulrr" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangePreFill} value='NH' onInvalid={props.onValidatePreFill}
                                    checked={props.quotePreFill.roadRunner}
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulrr">Nationwide Linehaul</label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Linehaul Service Type</label>
                            <SelectElement id="lineHaulServiceType" name="lineHaulServiceType" value={props.quotePreFill.lineHaulServiceType || ''} 
                                optionsData={props.lhServiceTypes}
                                //optionsData={props.lineHaulServiceTypeList}
                                disabled={props.quotePreFill.lineHaulServiceDisable} onChange={props.onChangePreFill}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quotePreFill)}</div>
            <div style={{ marginTop: 20 }}>{JSON.stringify(props.preFillRequired)}</div> */}
            <hr className="mb-3" />
            <div>
            {props.quotePreFill.localpud ?<div className="local"> Local PU & D Quote</div>:''} {props.quotePreFill.lineHaulLh ? <div className="I5">I5-Corridor Quote</div>:''} {props.quotePreFill.roadRunner? <div className="nationwide">Nationwide Linehaul Quote</div>:''}
            </div>
        </div>
    );
}

export default QuotePreFill;