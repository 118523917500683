import React,{useEffect} from "react";
import { GridLoader } from "react-spinners";
import DataHistory from "./Data/DataHistory";

import QuoteBookHistory from "./QuoteBookHistory";

const History = () => {
    const {handleInputChange,   filtersInput,  reportData, showDataLoading} = DataHistory();
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    return (
        <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-2 pageHead text-uppercase">Quote And Booking History</h4>
                        </div>
                        <hr/>
                        {
                        showDataLoading && 
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center"> 
                                    <GridLoader  color={'#1E73BE'} loading={showDataLoading} />
                                </div>
                            </div>
                        }
                        {
                            filtersInput.showTable &&  
                            <QuoteBookHistory reportData={reportData} filtersInput={filtersInput} showDataLoading={showDataLoading} />
                        }
                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(rpData)}</div> */}
                    </div>
                </div>
            </div>

    );
}

export default History;