import React, { useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import TextareaElement from "../../../formControls/TextareaElement";
import DataNewsFeed from "./Data/DataNewsFeed";


const NewsFeed = () => {
    const { handleInputChange, resetErrStatus,editNews, deleteNews, openAddNews, cancelAddNews, updateNews, cancelNews, clearNews, saveAddNews, filtersInput, filtersReq, reportData, showDataLoading , serverData, loading } = DataNewsFeed();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setTimeout(()=>{
            resetErrStatus();
        },3000);
    }, [filtersInput.showStatus]);


    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'}/>}
            <div className="card card-custom">
                <div className="d-flex">
                    <h4 className="mb-2 pageHead text-uppercase">News Feed</h4>
                </div>
                {/* <div className="row">
                    rd={JSON.stringify(reportData)}
                </div>
                <div className="row">
                    sd={JSON.stringify(serverData)}
                </div> */}
                <form>
                    {filtersInput.showStatus ?
                    <div className="row">
                            <div className={filtersInput.errorStatus===true ?  "col-md-12 mt-2 alert  text-center text-danger alert-danger" 
                                                                    : "col-md-12 mt-2 alert  text-center text-success alert-success"}  >
                                {filtersInput.errorStatus===false &&
                                    <i className="fas fa-check-circle pr-1" aria-hidden="true"></i>
                                } 
                                {filtersInput.statusDesc} 
                            </div>
                    </div>
                    :''}
                    <div className="row">
                    <table className="newsTable table table-bordered table-striped">
                        <thead className="thead-light">
                            <tr>
                                <th className="newsDesc newsHead" scope="col">News Feed Description</th>
                                <th scope="col" className="newsHead" >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.map((x, i) => {
                                return (<tr key={"newsfeed" + i}>
                                    <td>
                                        {(x.showTextArea) ?
                                            <TextareaElement  className="form-control newsfeedFocus" name={x.textAreaId} id={x.textAreaId} onChange={handleInputChange} rows="5" placeholder='Enter News Feed Here' value={x.Description} />
                                            :
                                            <p>{x.Description}</p>
                                        }
                                    </td>
                                    <td>
                                        {(x.showTextArea) ?
                                            <button className="btn btn-success  float-center newsBtnSpc" type="button" id={x.updateId} onClick={() => { updateNews(i); }} >Update </button>
                                            :
                                            <button className="btn btn-info  float-center newsBtnSpc" type="button" id={x.updateId} onClick={() => { editNews(i); }} >Edit </button>
                                        }
                                        {(x.showTextArea) ?
                                            <button className="btn btn-outline-secondary active float-center newsBtnSpc" type="button" id={x.deleteId} onClick={() => { clearNews(i); }} >Clear </button>
                                            :
                                            <button className="btn btn-danger  float-center newsBtnSpc" type="button" id={x.deleteId} onClick={() => { deleteNews(i); }} >Delete </button>
                                        }
                                        {(x.showTextArea) &&
                                            <button className="btn btn-warning  float-center newsBtnSpc" type="button" id={x.updateId} onClick={() => { cancelNews(i); }} >Cancel </button>
                                        }
                                    </td>
                                </tr>);
                            })}
                            <tr>
                                <td>
                                    {showDataLoading ?
                                        <TextareaElement className={filtersReq.newsFeedInput_ErrorClass} id="news" name="news" rows="5" 
                                        onChange={handleInputChange} value={filtersInput.news || ''} placeholder='Enter News Feed Here'
                                        />
                                        :
                                        <p className="text-primary"> Click on Add News Feed to Create a News Feed</p>
                                    }
                                </td>
                                <td>
                                    {showDataLoading ?
                                    <>
                                        <button className="btn btn-success btnTextSize float-center newsBtnSpc" type="button" onClick={() => { saveAddNews(); }} >Save News Feed </button>
                                        <button className="btn btn-warning  float-center newsBtnSpc" type="button" onClick={() => { cancelAddNews(); }} >Cancel News Feed </button>
                                    </>
                                        :
                                        <button className="btn btn-primary btnTextSize float-center newsBtnSpc" type="button" onClick={() => { openAddNews(); }} >Add News Feed </button>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    );
}


export default NewsFeed;