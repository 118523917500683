import { EmailJSResponseStatus } from 'emailjs-com';
import { useEffect, useState } from 'react';
import { HashLoader } from 'react-spinners';
import InputElement from '../formControls/InputElement';
import { requestSecQuestion, requestSecQuestionAns, requestValidateOTP, requestChangePass } from '../services/restAPI/UserAPI';


function RecoverPass() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, []);
    const [dataInput, setDataInput] = useState({
        userName: '',
        email: '',
        secMessState: true,
        secQueShow: false,
        secMessage: '',
        secQuestion: '',
        secAnsShow: false,
        secAnsState: true,
        secAnsMessage: '',
        secAns: '',
        emailSecShow: false,
        emailSecState: true,
        emailSecMessage: '',
        emailSecCode: '',
        changePassShow: false,
        newpass: '',
        newpass1: '',
        newpassState: true,
        newpassMessage: '',

    });
    const [dataRequired, setDataRequired] = useState({
        userNameCS: 'form-control',
        emailCS: 'form-control',
        secQuestionCS: 'form-control',
        secAnsCS: 'form-control',
        emailSecCodeCS: 'form-control',
        newpassCS: 'form-control',
        newpass1CS: 'form-control',
        newpassMes : 'required.',
        newpasMes : 'required.',
        newPassMesCS :'',
        plen : 'text-danger',
        plow : 'text-danger',
        pup : 'text-danger',
        pnum : 'text-danger',
        psp : 'text-danger',
        pborder : 'col-md-6 mt-2 mb-2 passBorderRed'

    })
    const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
    const [loading, setLoading] = useState(false);



    const handleInputChange = (name, value) => {
        setDataInput({ ...dataInput, [name]: value });
    }
    
    const getSecureQuestion = () => {

        let isValidationReq = true;
        if (!dataInput.userName || !dataInput.email)
            isValidationReq = false;
        // alert("Coming Soon......")
        let errObj = [];

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestSecQuestion(dataInput.userName, dataInput.email)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['secMessState']: false });
                        Object.assign(response, { ['secMessage']: 'Please contact customer support' });

                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                        //console.log('dRes==>',JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.response === "VALID" && dRes.secureQ !== "") {
                            //console.log('dRes==>',JSON.stringify(dRes));
                            Object.assign(response, { ['secQueShow']: true });
                            Object.assign(response, { ['secQuestion']: dRes.secureQ });
                            Object.assign(response, { ['secMessState']: true });
                            Object.assign(response, { ['secMessage']: '' });
                        } else {
                            Object.assign(response, { ['secMessState']: false });
                            Object.assign(response, { ['secMessage']: 'Please enter correct Username and email' });
                            Object.assign(error, { ['userNameCS']: 'form-control is-invalid' });
                            Object.assign(error, { ['emailCS']: 'form-control is-invalid' });
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(error => {
                    var response = {};                  
                    if(error.response.status === 400){
                        //console.log("error",JSON.stringify(error.response.status))
                        Object.assign(response, { ['secMessState']: false });
                        Object.assign(response, { ['secMessage']: 'Please enter correct Username and email' });
                        setDataInput({ ...dataInput, ...response });
    
                    }
                    //console.log("catch error in err block",JSON.stringify(error));
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(error => {
                    setLoading(error);
                })

        }

    }

    const getSecureAnswer = () => {

        let isValidationReq = true;
        if (!dataInput.secAns)
            isValidationReq = false;

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestSecQuestionAns(dataInput.email, dataInput.userName, dataInput.secAns)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['secAnsState']: false });
                        Object.assign(response, { ['secAnsMessage']: 'Please contact customer support' });
                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                       // console.log('dRes==>', JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.response === "true" || dRes.response === true) {
                            Object.assign(response, { ['secAnsState']: true });
                            Object.assign(response, { ['emailSecShow']: true });
                        } else {
                            Object.assign(response, { ['secAnsState']: false });
                            Object.assign(response, { ['secAnsMessage']: 'Please enter correct answer for security question' });
                            Object.assign(error, { ['secAnsCS']: 'form-control is-invalid' });
                            Object.assign(response, { ['emailSecShow']: false });
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(err => {
                    setLoading(false);
                })

        }
    }


    const getemailAnswer = () => {

        let isValidationReq = true;
        if (!dataInput.secAns)
            isValidationReq = false;

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestValidateOTP(dataInput.email, dataInput.userName, dataInput.emailSecCode)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['emailSecState']: false });
                        Object.assign(response, { ['emailSecMessage']: 'Please contact customer support' });
                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                        //console.log('dRes==>', JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.isValid === "true" || dRes.isValid === true) {
                            
                            Object.assign(response, { ['emailSecState']: true });
                            Object.assign(response, { ['changePassShow']: true });
                            Object.assign(error, { ['emailSecCodeCS']: 'form-control is-valid' });
                        } else {
                            Object.assign(response, { ['emailSecMessage']: "Security code expired or is invalid. Please wait 15 minutes or try again" });
                            Object.assign(error, { ['emailSecCodeCS']: 'form-control is-invalid' });
                            Object.assign(response, { ['emailSecState']: false });
                            Object.assign(response, { ['changePassShow']: true });
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(err => {
                    setLoading(false);
                })

        }
    }

    const createNewPassword = () => {

        let isValidationReq = true;
        if (!dataInput.secAns)
            isValidationReq = false;

        if (isValidationReq) {
            setLoading(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            requestChangePass(dataInput.email, dataInput.userName, dataInput.emailSecCode, dataInput.newpass1)
                .then(dRes => {
                    if (dRes instanceof Error) {
                        Object.assign(response, { ['newpassState']: false });
                        Object.assign(response, { ['newpassMessage']: 'Please contact customer support' });
                    } else {
                        //  {"response":"VALID","secureQ":"Dr who"}
                        //console.log('dRes==>', JSON.stringify(dRes));
                        var response = {};
                        var error = {};
                        if (dRes.isPasswordChanged === "SUCCESS" ) {
                            Object.assign(response, { ['newpassState']: true });
                            Object.assign(response, { ['newpassMessage']: 'Password Changed Sucessfully' });
                            Object.assign(error, { ['newPassMesCS']: 'text-success text-center' });
                        } else {
                            Object.assign(response, { ['newpassMessage']: 'Unable to change the password' });
                            Object.assign(response, { ['newpassState']: false });
                            Object.assign(error, { ['newPassMesCS']: 'text-danger text-center' });
                            
                        }
                        setDataInput({ ...dataInput, ...response });
                        setDataRequired({ ...dataRequired, ...error });
                    }
                }).catch(err => {
                    // console.log("error  in calling signUpForm  " + JSON.stringify(err))
                }).finally(err => {
                    setLoading(false);
                })

        }
    }

    const validateUserName = () => {
        var errState = {};
        if (!dataInput.userName) {
            Object.assign(errState, { ['userNameCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['userNameCS']: 'form-control is-valid' });
        }

        setDataRequired({ ...dataRequired, ...errState })
    }
    const validateEmail = () => {
        var errState = {};
        if (!dataInput.email) {
            Object.assign(errState, { ['emailCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['emailCS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
    }
    const validatesecAns = () => {
        var errState = {};
        if (!dataInput.secAns) {
            Object.assign(errState, { ['secAnsCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['secAnsCS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
    }
    const validateEmailSecCode = () => {
        var errState = {};
        if (!dataInput.emailSecCode) {
            Object.assign(errState, { ['emailSecCodeCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['emailSecCodeCS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
    }

    const validatePassword = () => {
        var p = dataInput.newpass;
        var errMes='';
        var  errMess={}
        var errState=true;
        
        if (p.search(/[a-z]/) < 0) {
            errMes="Password must contain at least one letter.";
            errState=false;
            Object.assign(errMess, { ['plow']: 'text-danger' });
        }else{
            Object.assign(errMess, { ['plow']: 'text-success' });
        }
        if (p.search(/[A-Z]/) < 0) {
            errMes="Password must contain at least one Upper letter.";
            errState=false;
            Object.assign(errMess, { ['pup']: 'text-danger' });
        }else{
            Object.assign(errMess, { ['pup']: 'text-success' });
        }
        if (p.search(/[0-9]/) < 0) {
            errMes="Password must contain at least one digit."; 
            errState=false;
            Object.assign(errMess, { ['pnum']: 'text-danger' });
        }else{
            Object.assign(errMess, { ['pnum']: 'text-success' });
        }
        if (p.search(/[(!*@#$%^&+=)]/) < 0) {
            errMes="Password must contain at least one (!*@#$%^&+=) special characters"; 
            errState=false;
            Object.assign(errMess, { ['psp']: 'text-danger' });
        }else{
            Object.assign(errMess, { ['psp']: 'text-success' });
        }
        
        if (p.length < 8) {
            errMes="Password must be at least 8 characters"; 
            errState=false;
            Object.assign(errMess, { ['plen']: 'text-danger' });
        }else{
            Object.assign(errMess, { ['plen']: 'text-success' });
        }

        if(!errState){
            Object.assign(errMess, { ['newpassCS']: 'form-control is-invalid' });
            Object.assign(errMess, { ['newpasMes']: 'required.' });
            Object.assign(errMess, { ['pborder']: 'col-md-6 mt-2 mb-2 passBorderRed' });
        }
        else{
            Object.assign(errMess, { ['newpassCS']: 'form-control is-valid' });
            Object.assign(errMess, { ['newpasMes']: '' });
            Object.assign(errMess, { ['pborder']: 'col-md-6 mt-2 mb-2 passBorderGreen' });
            
        }
        setDataRequired({ ...dataRequired,  ...errMess })
        return errState;
    }

    const validateNewPass = () => {
        var errState = {};
        if (!dataInput.newpass) {
            Object.assign(errState, { ['newpassCS']: 'form-control is-invalid' });
        } else {
            Object.assign(errState, { ['newpassCS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
    }
    const validateNewPass1 = () => {
        var errState = {};
        if (!dataInput.newpass1) {
            Object.assign(errState, { ['newpass1CS']: 'form-control is-invalid' });
            Object.assign(errState, { ['newpassMes']: 'required.' });
        } else if(dataInput.newpass !== dataInput.newpass1){
            Object.assign(errState, { ['newpassMes']: 'Password does not match' });
            Object.assign(errState, { ['newpass1CS']: 'form-control is-invalid' });
        }else {
            Object.assign(errState, { ['newpassMes']: '' });
            Object.assign(errState, { ['newpass1CS']: 'form-control is-valid' });
        }
        setDataRequired({ ...dataRequired, ...errState })
        
    }


    const clearPage = () => {
        setSaveMessage({ showMsg: false, status: '', message: '' });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }


    return (
        <section className="mt-180 mb-4">
            <div className={loading ? 'container parentDisable' : 'container'}>
                {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'} />}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom">
                            <div className="d-flex">
                                <h4 className="mb-2 pageHead ">Password Recovery Form</h4>
                            </div>
                            <form className="needs-validation">
                                <div className="row mb-3">
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <label htmlFor="email">User Name</label>
                                        <InputElement id="userName" name="userName" value={dataInput.userName || ''} className={dataRequired.userNameCS}
                                            onChange={handleInputChange} onInvalid={validateUserName}  validation="required" placeholder='User Name' />
                                        <div className="invalid-feedback">
                                        required.
                                        </div>
                                    </div>
                                    <div className="col-md-3 ">
                                        <label htmlFor="email">Email</label>
                                        <InputElement id="email" name="email" value={dataInput.email || ''} className={dataRequired.emailCS}
                                            onChange={handleInputChange} onInvalid={validateEmail} validation="required" placeholder='Registered email' />
                                        <div className="invalid-feedback">
                                            required.
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-4">
                                        <button className="btn btn-primary btn-block pull-left" type="button"
                                            onClick={getSecureQuestion}
                                        >Continue... &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                    </div>
                                </div>
                                <hr className="mb-3" />
                                { dataInput.secMessState ? ''
                                    : <div className="row mb-3">
                                        <div className="col-sm-6">
                                            <label className="passErrMsg">{dataInput.secMessage} </label>
                                        </div>
                                    </div>}
                                    {dataInput.secAnsState  ? ''
                                    : <div className="row mb-3">
                                        <div className="col-sm-6">
                                            <label className="passErrMsg">{dataInput.secMessage} </label>
                                        </div>
                                    </div>}
                                {dataInput.secQueShow ?
                                    <>
                                        <div className="row mb-3">
                                            <div className='container-fluid backgroundlight'>
                                                <h5 className="mt-1">Please Answer the security question</h5>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-6">
                                                <label className="Labelbold15Blue">{dataInput.secQuestion} </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <InputElement id="secAns" name="secAns" value={dataInput.secAns || ''} className={dataRequired.secAnsCS}
                                                    onChange={handleInputChange} onInvalid={validatesecAns} validation="required" placeholder='Security Question Answer' />
                                            </div>
                                            <div className="col-sm-2">
                                                <button className="btn btn-primary btn-block pull-left" type="button"
                                                    onClick={getSecureAnswer}
                                                >Validate... &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                            </div>
                                        </div>
                                        {dataInput.secAnsState ? ''
                                            : <div className="row mb-3">
                                                <div className="col-sm-6">
                                                    <label className="passErrMsg">{dataInput.secAnsMessage} </label>
                                                </div>
                                            </div>}
                                        {dataInput.emailSecShow ?
                                            <>
                                                <div className="row mb-3">
                                                    <div className='container-fluid backgroundlight'>
                                                        <h5 className="mt-1">Please enter the code received for the registered email address</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <InputElement id="emailSecCode" name="emailSecCode" value={dataInput.emailSecCode || ''} className={dataRequired.emailSecCodeCS}
                                                            onChange={handleInputChange} onInvalid={validateEmailSecCode} validation="required" placeholder='Code received for email' />
                                                        <div className="invalid-feedback">
                                                            required.
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 ">
                                                        <button className="btn btn-primary btn-block pull-left" type="button"
                                                            onClick={getemailAnswer}
                                                        >Validate... &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                                    </div>
                                                </div></>
                                            : <></>}
                                        {dataInput.emailSecState ? ''
                                            : <div className="row mb-3">
                                                <div className="col-sm-6">
                                                    <label className="passErrMsg">{dataInput.emailSecMessage} </label>
                                                </div>
                                            </div>}
                                        {dataInput.changePassShow ?
                                            <>
                                                <div className="row mb-3">
                                                    <div className='container-fluid backgroundlight'>
                                                        <h5 className="mt-1">Update New Password</h5>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <label htmlFor="newpass">New Password</label>
                                                        <InputElement type="password" id="newpass" name="newpass" value={dataInput.newpass || ''} className={dataRequired.newpassCS}
                                                            onChange={handleInputChange} onBlur={validatePassword} onInvalid={validatePassword} validation="required" placeholder='New Password' />
                                                        <div className="invalid-feedback" >
                                                        {dataRequired.newpasMes}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label htmlFor="newpass1">Re-enter New password</label>
                                                        <InputElement type="password" id="newpass1" name="newpass1" value={dataInput.newpass1 || ''} className={dataRequired.newpass1CS}
                                                            onChange={handleInputChange} onInvalid={validateNewPass1} validation="required" placeholder='Re-enter New Password' />
                                                        <div className="invalid-feedback">
                                                            {dataRequired.newpassMes}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3 mt-4">
                                                        <button className="btn btn-primary btn-block pull-left" type="button"
                                                            onClick={createNewPassword}
                                                        >Update Password &nbsp;<i className="fas fa-check-circle"></i>  </button>
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                <div className={dataRequired.pborder} >
                                                    <span  className={dataRequired.plen}> Password MUST have at least 8 Characters</span><br></br>
                                                    <span  className={dataRequired.plow}> Password MUST have at least 1 lowercase letter</span><br></br>
                                                    <span  className={dataRequired.pup}> Password MUST have at least 1 UPPERCASE letter</span><br></br>
                                                    <span  className={dataRequired.pnum}> Password MUST have at least 1 number</span><br></br>
                                                    <span  className={dataRequired.psp}> Password MUST have at least 1 of the following special character (!*@#$%^&+=)</span>
                                                </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-12">
                                                        <h5 className={dataRequired.newPassMesCS}> {dataInput.newpassMessage}</h5>
                                                    </div>
                                                </div>
                                                </>
                                            : ''}
                                    </>
                                    :
                                    <hr className="mb-3" />
                                }
                                
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default RecoverPass;