import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GridLoader } from "react-spinners";
import DataShipping from '../components/services/quickQuote/Data/DataShipping';
import DataTerminals from '../components/services/quickQuote/Data/DataTerminals';
import { fffTermList, lhTermList,rrAccessorialList,rrTermList } from "../components/services/restAPI/QuickQuoteRest";
import {tariffAirportCode} from "../components/services/restAPI/TariffRatesAPI";

const Welcome = (props) => {
  const { onChangeShip, onValidateShip, toggleTerminals, onSubmitShipDetails, updateQuoteShippingState, updateQuoteShippingReqState
    , setLineHualTermDetailsList, setRoadRunnerTermDetailsList, setfffTermDetailsList, setAccessorialDetailsList, setRRAccessorialDetailsList, clearShipping
    , quoteShipping, shippingRequired,  accessorialDetails,rrAccessorialDetails } = DataShipping();
    const { setLineHualTermOptsList, setfffTermOptsList, setAccessorialOptsList,setRoadRunnerTermOptsList,
      roadRunnerTermOpts,lineHualTermOpts, fffTermOpts, accessorialOpts } = DataTerminals();
    const userProfile = useSelector((state) => state.usr)
  
    let [loading, setLoading] = useState(true);
 
   
  useEffect(() => {
    // let mounted = true;
    const lhTerm = localStorage.getItem("lhTermList");
    const fffTerm = localStorage.getItem("fffTermList");
    const rrTerm = localStorage.getItem("rrTermList");
    const aircodeList = localStorage.getItem("airportCodeList");
    //const rrAccessList = localStorage.getItem("rrAccessorialList");
    //console.log("aircodeList "+aircodeList+" lhTerm"+lhTerm+"  fffTerm"+fffTerm)
   
    //rrAccessList === null || || rrAccessList ==="[]"
    if(lhTerm === null || fffTerm === null || rrTerm === null|| aircodeList === null || 
      lhTerm === "[]" || fffTerm === "[]" || rrTerm === "[]" ||aircodeList === "[]"  ){
      if (fffTerm === null || fffTerm === "[]") {
        fffTermList()
          .then(fffList => {
            //console.log("fffTerm in contianer"+JSON.stringify(fffList))
            localStorage.setItem("fffTermList", JSON.stringify(fffList));
            setfffTermDetailsList(fffList);
            setfffTermOptsList(fffList);
          }).catch(err => {
            localStorage.setItem("fffTermList", "[]");
          })
      }
           
      if (lhTerm === null || lhTerm === "[]") {
        lhTermList()
          .then(lhTList => {
            //console.log("linehaul in contianer"+JSON.stringify(lhTList))
            localStorage.setItem("lhTermList", JSON.stringify(lhTList));
            setLineHualTermDetailsList(lhTList);
            setLineHualTermOptsList(lhTList);
          }).catch(err => {
            localStorage.setItem("lhTermList", "[]");
          })
      }
     
      if (rrTerm === null|| rrTerm === "[]") {
        rrTermList()
          .then(rrList => {
            //console.log("Road runner in welcome termlist"+JSON.stringify(rrList))
            localStorage.setItem("rrTermList", JSON.stringify(rrList));
            setRoadRunnerTermDetailsList(rrList);
            setRoadRunnerTermOptsList(rrList);
          }).catch(err => {
            localStorage.setItem("rrTermList", "[]");
          })
      }
      
      if (aircodeList === null || aircodeList === "[]") {
        tariffAirportCode().then(responseData => {
          let _reportData = [];
          if (responseData instanceof Error) {
            // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
            //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
          } else {
            responseData.forEach(function (item, index) {
              // console.log(item);
              _reportData.push({ "value": item.AirportCode, "label": item.AirportCode })
            });
            localStorage.setItem("airportCodeList", JSON.stringify(_reportData));
          }
          // console.log(JSON.stringify(responseData.tariffRate))
        }).catch(err => {
          localStorage.setItem("airportCodeList", "[]");
        })
        //return () => mounted = false;
      }
   
      // if (rrAccessList === null || rrAccessList === "[]") {
      //   rrAccessorialList()
      //     .then(rrAccList => {
      //       //console.log("response of rrAccessorialList welcomepage"+JSON.stringify(rrAccList))
      //       localStorage.setItem("rrAccessorialList", JSON.stringify(rrAccList));
      //       setRRAccessorialDetailsList(rrAccList);
      //     }).catch(err => {
      //       localStorage.setItem("rrAccessorialList", "[]");
      //     })
      // }
      setLoading(false);
    }else{
      setLoading(false);
    }
    
  }, [])// eslint-disable-line
  
    
    
    
    
   
   /*  
      useEffect(() => {
        let mounted = true;
        if (mounted) {
          fffTermList()
            .then(fffList => {
                //console.log("fffTerm in contianer"+JSON.stringify(fffList))
                localStorage.setItem("fffTermList", JSON.stringify(fffList));
                setfffTermDetailsList(fffList);
                setfffTermOptsList(fffList);
            })
          return () => mounted = false;
        }
      }, [])// eslint-disable-line
    
     
    
    //  setTimeout(()=>{
    //    setLoading(false);
    //  },1000)
 
      */
      

  return (
    <div className={loading ? 'container parentDisable' : ''}>
    {loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'}/> }
        <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Welcome, {userProfile.username} !</h4>
                    </div>
                    <form>
                        <br/>
                        {/* <h6>Logged in as : {userProfile.userName}  </h6> */}
                    </form>
              
                </div>
            </div>
        </div>
        </div>
  )
}

export default Welcome