import axios from "axios";


export const fuelSurcharge = () => {
     return axios.get(process.env.REACT_APP_FUELSURCHARGE_URL)
        .then(response => {
           // console.log("REACT_APP_FUELSURCHARGE_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_FUELSURCHARGE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in fuelSurcharge ");
        })
        
}
export const fuelSurchargeLinehaul = () => {
    return axios.get(process.env.REACT_APP_FUELSURCHARGE_LINEHAUL_URL)
        .then(response => {
            // console.log("REACT_APP_FUELSURCHARGE_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_FUELSURCHARGE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in fuelSurcharge ");
        })
    }

export const zipCodeService = (zipcode) => {
    var param = "?zipcode="+zipcode;
    return axios.get(process.env.REACT_APP_ZIPCODE_URL+param)
       .then(response => {
          // console.log("REACT_APP_ZIPCODE_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api REACT_APP_ZIPCODE_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in zipCodeService ");
       })

}

export const historicalFsc = () => {
    return axios.get(process.env.REACT_APP_HISTORICAL_FSC_URL)
       .then(response => {
          // console.log("REACT_APP_HISTORY_FSC_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api REACT_APP_HISTORY_FSC_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in historical fsc ");
       })
}


export const getBaseStation = () => {
    return axios.get(process.env.REACT_APP_BASESTATIONS_URL)
       .then(response => {
          // console.log("REACT_APP_HISTORY_FSC_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api REACT_APP_HISTORY_FSC_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in historical fsc ");
       })
}




export const sendEmail = (mailObj) => {
    return axios.post(process.env.REACT_APP_SEND_MAIL_URL, mailObj)
       .then(response => {
          // console.log("REACT_APP_SEND_MAIL_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api REACT_APP_SEND_MAIL_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in sendemail");
       })
}


export const processCargoClaim = (ccObj, attachments) => {
    let claimReq = buildClaimRequest(ccObj, attachments);
    // console.log(claimReq,claimReq)
    return axios.post(process.env.REACT_APP_CARGO_CLAIM_URL, claimReq)
       .then(response => {
        //   console.log("REACT_APP_CARGO_CLAIM_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
        //    console.log("Error in calling api REACT_APP_CARGO_CLAIM_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in processCargoClaim");
       })
}


export const shipmentAcceptReject = (orderId,isAccepted,reason) => {
    let postData = { 
        "orderid" : orderId,
        "isAccepted" : isAccepted,
        "reason" : +reason+"",
      };
    return axios.post(process.env.REACT_APP_SHIPMENT_ACCEPT_REJECT, postData)
       .then(response => {
        //   console.log("REACT_APP_SHIPMENT_ACCEPT_REJECT response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
        //    console.log("Error in calling api REACT_APP_SHIPMENT_ACCEPT_REJECT"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in shipmentAcceptReject");
       })
}

export const shipmentDecrypt = (shipmentDecrypt) => {
    let postData = { 
        "enc" : shipmentDecrypt,
       
      };
    return axios.post(process.env.REACT_APP_SHIPMENT_DECRYPT, postData)
       .then(response => {
        //   console.log("REACT_APP_SHIPMENT_DECRYPT response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
        //    console.log("Error in calling api REACT_APP_SHIPMENT_DECRYPT"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in shipmentDecrypt");
       })
}

export const signUpForm = (dataInput) => {
    let postData = {
            "custCode": dataInput.acNo,
            "companyName": dataInput.companyName,
            "companyAddress": dataInput.address,
            "companyCity": dataInput.city,
            "companyState": dataInput.state,
            "companyZip": dataInput.zip,
            "companyPhone": dataInput.tel,
            "companyFax": dataInput.fax,
            "companyCountry": dataInput.countryCode,
            "companyAdministrator": dataInput.compAdmin,
            "firstName": dataInput.firstName,
            "lastName": dataInput.lastName,
            "userName": dataInput.userName,
            "password": dataInput.password,
            "email": dataInput.email,
            "secureQuestion": dataInput.secQues,
            "secureAnswer": dataInput.secAns,
            "notifyFuelSurcharge": dataInput.fuelUpdates ? true : false,
            "notifyTariffUpdates": dataInput.tarifUpdates ? true : false,
            "notifyFreightforceUpdates": dataInput.ffUpdates ? true : false,
            "timezone": dataInput.timeZone,
            "baseStation": dataInput.clBaseStation,
            "managerId": dataInput.acNo,
            "accountLevel": dataInput.acNo
    } 
    return axios.post(process.env.REACT_APP_SIGNUP_URL, postData)
       .then(response => {
        //   console.log("REACT_APP_SIGNUP_URL response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
        //    console.log("Error in calling api REACT_APP_SIGNUP_URL"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in signUpForm");
       })
}


const buildClaimRequest = (cc, attachments) => {
    let _dt = new Date(cc.serviceDate).toISOString();
    let req = {
        "billNow": cc.billNo,
        "claimNo": cc.claimNo,
        "serviceDate": _dt,
        "company": cc.company,
        "contactPerson": cc.contactPerson,
        "streetAddress": cc.streetAddress,
        "city": cc.city,
        "state": cc.state,
        "zipcode": cc.zipcode,
        "phone": cc.phone,
        "fax": cc.fax,
        "email": cc.email,
        "claimType": cc.claimType==="Other" ? cc.claimStatement : cc.claimType,
        "totalAmtClaimed": cc.totalAmtClaimed,
        "claimDesc": cc.claimDesc,
        "docVendorInvoice": cc.docVendorInvoice ? true : false,
        "docBillLoading": cc.docBillLoading ? true : false, 
        "docRepairInvoice": cc.docRepairInvoice ? true : false,
        "docPaidForBill": cc.docPaidForBill ? true : false,
        "docDiscountedSale": cc.docDiscountedSale ? true : false,
        "docInspReport": cc.docInspReport ? true : false,
        "docDeliveryReciept": cc.docDeliveryReciept ? true : false,
        "docShortageDamage": cc.docShortageDamage ? true : false,
        "docOther1Desc": cc.docOther1Desc ? true : false,
        "docOther2Desc": cc.docOther2Desc ? true : false,
        "documents": attachments
      }
    //   console.log("ccreq",req)
    return req;
}



export const getCarrierInformation = () => {
    return axios.get(process.env.REACT_APP_LINE_CARRIER_INFORMATION)
       .then(response => {
          // console.log("REACT_APP_LINE_CARRIER_INFORMATION getCarrierInformation response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api getCarrierInformation REACT_APP_LINE_CARRIER_INFORMATION"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in getCarrierInformation ");
       })
}

export const updateCarrierInformation = (filtersInput) => {
    const postData = {
        "airportCode": filtersInput.station_id,
        "emergencyPhone": filtersInput.emerg_phone,
        "email": filtersInput.email_address,
        "manager": filtersInput.manager_name,
        "region": filtersInput.region,
        "latitude": filtersInput.latitude,
        "longitude": filtersInput.longitude
      }
    return axios.put(process.env.REACT_APP_LINE_UPDATE_CARRIER_INFORMATION,postData)
       .then(response => {
          // console.log("REACT_APP_LINE_UPDATE_CARRIER_INFORMATION updateCarrierInformation response "+JSON.stringify(response.data))
           return response.data;
       })
       .catch(error => {
           // console.log("Error in calling api updateCarrierInformation REACT_APP_LINE_UPDATE_CARRIER_INFORMATION"+JSON.stringify(error));
           return error;
       })
       .finally(() => {
        //    console.log("Finally call in updateCarrierInformation ");
       })
}
