import { useState } from "react";

import { validateNumber } from '../../../utils/ValidationScripts'
const DataDimensions = () => {

  //packageType : "",packageType_ErrorClass:"form-control" removed from below line
  const [dimensionList, setDimensionList] = useState([{ qty: 0, weight: 0, length: 0, width: 0, height: 0, description: "" , qty_ErrorClass: "form-control", weight_ErrorClass: "form-control", length_ErrorClass: "form-control", width_ErrorClass: "form-control", height_ErrorClass: "form-control"  }]);
  const [errorStatus, setErrorStatus] = useState(false)
  const [totalDimensions, setTotalDimensions] = useState({ ttlPcs: 0, grsWt: 0, ttlDimWt: 0});
  //Set all the dimenions rows and its value into state
  const [showRoadRunner, setShowRoadRunner] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...dimensionList];
    if (name !== 'description' && name!= "packageType") {
      if (validateNumber(e)) {
        list[index][name] = parseInt(value);
        setDimensionList(list);
        updateSum(list);
      }else{
        list[index][name] = "0";
      //  console.log("index"+index +" name "+ name)
        setDimensionList(list);
        updateSum(list);
      }
      validateZero();
    } else {
      list[index][name] = value;
      setDimensionList(list);
      // if(name === "packageType"){
      //   validateZero();
      // }
    }
  };

  const setRoadRunner = (flag)=> {
    setShowRoadRunner(flag)
  }
  // Remove one row of Dimenstions from model 
  const handleRemoveDimensions = (index) => {
    const list = [...dimensionList];
    list.splice(index, 1);
    setDimensionList(list);
    updateSum(list);
  };

  const handleRemoveAllDimensions = () => {
    const list = [...dimensionList];
    list.splice(1, list.length);
    //packageType : "", packageType_ErrorClass:"form-control"  removed from below list
    setDimensionList([{ qty: 0, weight: 0, length: 0, width: 0, height: 0, description: "" , qty_ErrorClass: "form-control", weight_ErrorClass: "form-control", length_ErrorClass: "form-control", width_ErrorClass: "form-control", height_ErrorClass: "form-control" }]);
    setTotalDimensions({ ttlPcs: 0, grsWt: 0, ttlDimWt: 0 });
  }
  const updateSum = (list) => {
    var totDimWeight = 0, totWeight = 0, totQuantity = 0, totGrossWt = 0;
    var qty = 0, weight = 0, length = 0, width = 0, height = 0, rowDimWeight = 0;
    var quoteLinehaul = false;
    var my_dim = 250;
    if(showRoadRunner)
      my_dim = 200;
    // console.log('quoteLineHaul='+quoteLinehaul+"::my_dim="+my_dim);
    // var my_dim = (o_airport == "YVR" || d_airport == "YVR") ? 196 : 250;
    list.map((eachdim) => {
      qty = eachdim.qty;
      weight = eachdim.weight;
      length = eachdim.length;
      width = eachdim.width;
      height = eachdim.height;

      totQuantity = Number(totQuantity) + Number(qty);
      totWeight = Number(totWeight) + Number(weight);
      totGrossWt += Number(qty) * Number(weight);
      qty = (qty.length == 0) ? 1 : qty;
      rowDimWeight += (Number(qty) * ((Number(length) * Number(width) * Number(height)) / my_dim));
      // totDimWeight=(parseFloat(eachdim.length)+parseFloat(eachdim.width)+parseFloat(eachdim.height))/100;
    })

    totDimWeight = Math.ceil(rowDimWeight);//(parseFloat(length)+parseFloat(width)+parseFloat(height))/100;
    //const totalSum = {ttlPcs:parseInt(totQuantity) ,grsWt:parseFloat(totWeight).toFixed(2) , ttlDimWt:parseFloat(totDimWeight).toFixed(2)};
    // const totalSum = { ttlPcs: parseInt(totQuantity), grsWt: parseInt(totWeight), ttlDimWt: parseInt(totDimWeight) };
    const totalSum = { ttlPcs: parseInt(totQuantity), grsWt: parseInt(totGrossWt), ttlDimWt: parseInt(totDimWeight) };
    
    setTotalDimensions({ ...totalDimensions, ...totalSum });
  }

  // Add one row of Dimenstions from model 
  const handleAddDimensions = () => {
    //console.log("add dimensions -- "+JSON.stringify(dimensionList))
    //packageType : "" , packageType_ErrorClass:"form-control" //removed from below list
    setDimensionList([...dimensionList, { qty: 0, weight: 0, length: 0, width: 0, height: 0, description: "" , qty_ErrorClass: "form-control", weight_ErrorClass: "form-control", length_ErrorClass: "form-control", width_ErrorClass: "form-control", height_ErrorClass: "form-control" }]);
    updateSum(dimensionList);
  };
  const validateZero = () =>{
    const errlist = [...dimensionList];
    var errorStatus = false;
    dimensionList.map( (eachdim,i) => {
     
      if(eachdim.qty === 0){
      errlist[i]["qty_ErrorClass"]= 'form-control is-invalid';
      errorStatus=true;
      }else
        errlist[i]["qty_ErrorClass"]= 'form-control';
      if(eachdim.weight === 0){
      errlist[i]["weight_ErrorClass"]= 'form-control is-invalid';
      errorStatus=true;
      }else
        errlist[i]["weight_ErrorClass"]= 'form-control';
      if(eachdim.length === 0){
      errlist[i]["length_ErrorClass"]= 'form-control is-invalid';
      errorStatus=true;
      }else
        errlist[i]["length_ErrorClass"]= 'form-control';
      if(eachdim.width === 0){
      errlist[i]["width_ErrorClass"]= 'form-control is-invalid';
      errorStatus=true;
      }else
        errlist[i]["width_ErrorClass"]= 'form-control';
      if(eachdim.height === 0){
      errlist[i]["height_ErrorClass"]= 'form-control is-invalid';
      errorStatus=true;
      }else
        errlist[i]["height_ErrorClass"]= 'form-control';

        // should add this if mandatory 
        // if( showRoadRunner &&  eachdim.packageType === ""){
        //   errlist[i]["packageType_ErrorClass"]= 'form-control is-invalid';
        //   errorStatus=true;
        //   }else
        //     errlist[i]["packageType_ErrorClass"]= 'form-control';
            
    }
    )
    setErrorStatus(errorStatus);
    return errorStatus;
  }

  const copyDimensions = (dimList) => {
    if (dimList)
      setDimensionList([...dimList]);
    updateSum(dimList);
  };

  const updateRoadRunnerValue = (val) => {
    setShowRoadRunner(val);
  }

  const updateDimTotalsSt = (list, lineHaulSelVal, updateTotalDims) => {
    var totDimWeight = 0, totWeight = 0, totQuantity = 0, totGrossWt = 0;
    var qty = 0, weight = 0, length = 0, width = 0, height = 0, rowDimWeight = 0;
    var quoteLinehaul = false;
    var my_dim = 0;

      if( lineHaulSelVal==="NH" ){ //  Nationwide Linehaul
        my_dim = 200;
      }else if( lineHaulSelVal==="lineHaul" ){ // I5
        my_dim = 250;
      }else if( lineHaulSelVal==="localpu" ){ // Local PU & D
        my_dim = 250;
      }
    
    //console.log(lineHaulSelVal,'quoteLineHaul='+quoteLinehaul+"::my_dim="+my_dim);
    // var my_dim = (o_airport == "YVR" || d_airport == "YVR") ? 196 : 250;
    list.map((eachdim) => {
      qty = eachdim.qty;
      weight = eachdim.weight;
      length = eachdim.length;
      width = eachdim.width;
      height = eachdim.height;

      totQuantity = Number(totQuantity) + Number(qty);
      totWeight = Number(totWeight) + Number(weight);
      totGrossWt += Number(qty) * Number(weight);
      qty = (qty.length == 0) ? 1 : qty;
      rowDimWeight += (Number(qty) * ((Number(length) * Number(width) * Number(height)) / my_dim));
      // totDimWeight=(parseFloat(eachdim.length)+parseFloat(eachdim.width)+parseFloat(eachdim.height))/100;
    })

    totDimWeight = Math.ceil(rowDimWeight);//(parseFloat(length)+parseFloat(width)+parseFloat(height))/100;
    //const totalSum = {ttlPcs:parseInt(totQuantity) ,grsWt:parseFloat(totWeight).toFixed(2) , ttlDimWt:parseFloat(totDimWeight).toFixed(2)};
    // const totalSum = { ttlPcs: parseInt(totQuantity), grsWt: parseInt(totWeight), ttlDimWt: parseInt(totDimWeight) };
    const totalSum = { ttlPcs: parseInt(totQuantity), grsWt: parseInt(totGrossWt), ttlDimWt: parseInt(totDimWeight) };
    //console.log("totalSum",totalSum,my_dim);
    setTotalDimensions({ ...totalDimensions, ...totalSum });

    const totSum ={totalPcs:parseInt(totQuantity),
                    grossWeight:parseInt(totGrossWt),
                    dimWeightTotal:parseInt(totDimWeight) }
    updateTotalDims(totSum);
  }

  return {
    handleAddDimensions,
    handleRemoveDimensions,
    handleRemoveAllDimensions,
    handleInputChange,
    copyDimensions,
    updateRoadRunnerValue,
    setRoadRunner,
    updateDimTotalsSt,
    dimensionList,
    errorStatus,
    totalDimensions
  }
}


export default DataDimensions;