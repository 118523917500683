import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { useState } from "react";
import { GridLoader } from "react-spinners";
import { setQuickQuote } from "../../../store/actions/actionTypes";
import { addDimensions } from '../../../store/actions/actionTypes';
import { copyStyles, extractStyles } from "../../utils/ValidationScripts";
import DataAutoFill from "./Data/DataAutoFill";
import jsPDF from "jspdf";
import { quickQuote } from "../restAPI/QuickQuoteRest";
const QuoteProposed = (props) => {

	const { onChangePreFill, onValidatePreFill, updatePreFillState, updatePreFillReqState, clearPreFill
		, quotePreFill, preFillRequired, typeOfMoveList, serviceTypeList, lineHaulServiceTypeList, packageTypeList } = DataAutoFill();

	const navigate = useNavigate();
	// const { location } = useLocation();  //passed value from Navigator
	//const quickQuote = state.quickQuote; //passed value from Navigator
	let quickQuote = useSelector((state) => state.qqt);  // reducer call
	//console.log("resos",JSON.stringify(quickQuote))
	const [loading, setLoading] = useState(false);
	const navState = useLocation();
	const dispatch = useDispatch();
	const _dim = [];
	const [hideQuoteDiv, setHideQuoteDiv] = useState(false);


	const listOfAccessorials = [];
	const listOfAccessorialsAmt = [];
	const listOfPickupAccessorials = [];
	const listOfPickupAccessorialsAmt = [];
	const listOfDeliveryAccessorials = [];
	const listOfDeliveryAccessorialsAmt = [];
	let accessorialDesc = '', accessorialAmts = '', accessorialTotalAmt = 0.00, amt = 0.00;
	let pickupAccessorialDesc = '', pickupAccessorialAmts = '', pickupAccessorialTotalAmt = 0.00;
	let deliveryAccessorialDesc = '', deliveryAccessorialAmts = '', deliveryAccessorialTotalAmt = 0.00;

	let quoteHis = false;
	//console.log("quickQuote="+JSON.stringify(quickQuote))

	if (navState.state && navState.state.quoteHis) {
		quoteHis = navState.state.quoteHis;
		quickQuote = navState.state.quoteHisData;
		quickQuote = { ...quickQuote, ...{ isFromHistory: true } };

		//console.log("quickQuote from history="+JSON.stringify(quickQuote));
	}

	const contiueBooking = () => {
		// console.log("q in p="+JSON.stringify(quickQuote));
		setLoading(true);
		dispatch(setQuickQuote(quickQuote));
		if (!quoteHis) {
			navigate("/freightQuote/freightBooking");
		} else {
			dispatch(addDimensions(_dim));
			navigate("/freightQuote/freightBooking", { state: { quoteHis: quoteHis } });
		}

	}

	const goBack = () => {
		if (!quoteHis) {
			navigate("/freightQuote", { state: { backStatus: true } });
		} else {
			// window.history.go(-1)
			navigate("/history")
		}
	}

	useEffect(() => {
		
			window.history.pushState(null, document.title, window.location.href);
			window.addEventListener('popstate', function (event) {
				if (!quoteHis) {
					navigate("/freightQuote", { state: { backStatus: true } });
				} else {
					// window.history.go(-1)
					navigate("/history")
				}
			});
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

	}, []) // eslint-disable-line

	/*
	useEffect(() => {
		//console.log("quickQuote when refresh",JSON.stringify(quickQuote));
		// page when clicked refresh button
		if (!quickQuote?.customer?.companyName) {
			// alert("can be redirected")
			// page was just refreshed:
			setLoading(true)
			setHideQuoteDiv(true)
			//alert("Donot refresh the page. Changes done will be lost.")
			window.location.href = '/freightQuote';
		}
	}, []) // eslint-disable-line
*/

	let _custCompanyName = '', _custEmail = '', _custPhone = ''
		, _shipperCompanyName = '', _shipperAddr1 = '', _shipperAddr2 = '', _shipperCity = '', _shipperState = '', _shipperPostalCode = '', _shipperPhone = '', _shipperContact = '', _shipperReady = '', _shipperClose = ''
		, _consigneeCompanyName = '', _consigneeAddr1 = '', _consigneeAddr2 = '', _consigneeCity = '', _consigneeState = '', _consigneePostalCode = '', _consigneePhone = '', _consigneeContact = '', _consigneeReady = '', _consigneeClose = ''
		, _pickupDate = '', _deliveryDate = '', _shipeerTerminal = '', _consigneeTerminal = ''
		, _fromZip = '', _toZip = '', _serviceType = '', _moveType = '', _lineHaulServiceType = '', _comments = '', _quoteId = ''
		, _pounds = '', _pieces = '', _pallets = '', _cbm = '', _shipperNotes = '', _consigneeNotes = ''
		, _freightCharge = 0.00, _fuelSurcharge = 0.00, _totalCharges = 0.00, _discountCharges = 0.00
		, _westCoastSurchargeFee = 0.00, _crossDockFee = 0.00, _dropFee = 0.00, _yvrBorderFee = 0.00, _yvrCrossFee = 0.00
		, _accSerType = '', _isLinehaul = '', _isRoadRunner = false , _nationWideAccDesc = '', _nationWideAcc =false, _nationWideAccAmt=0.00
		, _pCity = '', _dCity = '', _pickup_FreightCharge = 0.00, _pickup_FSC = 0.00, _pickup_FscRate = 0.00, _total_Pickup = 0.00
		, _delivery_FreightCharge = 0.00, _delivery_FSC = 0.00, _delivery_FscRate = 0.00, _total_Delivery = 0.00, _lineHaulPrice = 0.00,recoverFromCarrier='',dropToCarrier='';

	if (quickQuote) {
		if (quoteHis) {
			//console.log("Road runner histroy value"+JSON.stringify(quickQuote))
			_isLinehaul = quickQuote.isLinehaul;
			_isRoadRunner = quickQuote.isRoadRunner;
			//console.log("_isLinehaul",_isLinehaul)
			if (quickQuote.customer) {
				let c = quickQuote.customer;
				_custCompanyName = c.companyName;
				_custEmail = c.email;
				_custPhone = c.phone;
				//_custPhone = c.phone && c.phone.length>9 ? "("+c.phone.substring(0, 3)+") "+ c.phone.substring(4, 7) + c.phone.substring(7) : c.phone;
			}
			const fffTermList = JSON.parse(localStorage.getItem("fffTermList"));
			const lhTermList = JSON.parse(localStorage.getItem("lhTermList"));
			const rrTermList = JSON.parse(localStorage.getItem("rrTermList"));

			if (quickQuote.shipper) {
				let s = quickQuote.shipper;
				_shipperCompanyName = s.companyName;
				_shipperAddr1 = s.address1;
				_shipperAddr2 = s.address2;
				_shipperCity = s.city;
				_shipperState = s.state;
				_shipperPostalCode = s.postalCode;
				_shipperPhone = s.phone;
				_shipperContact = s.contact;
				_fromZip = s.postalCode;
				if (s.terminal)
					_shipeerTerminal = s.terminal;
				else {
					let shipperInfoFound;
					if (_isRoadRunner) {
						shipperInfoFound = rrTermList.find(eachobj => eachobj.PostalCode === s.postalCode)
						if (shipperInfoFound)
							quickQuote.shipper.terminal = shipperInfoFound.AirportCode;
					} else {
						shipperInfoFound = lhTermList.find(eachobj => eachobj.PostalCode === s.postalCode)
						if (!shipperInfoFound) {
							shipperInfoFound = fffTermList.find(eachobj => eachobj.PostalCode === s.postalCode)
						}
						if (shipperInfoFound)
							quickQuote.shipper.terminal = shipperInfoFound.AirportCode;
					}
					//_shipeerTerminal = shipperInfoFound.AirportCode;
				}

			}
			if (quickQuote.consignee) {
				let c = quickQuote.consignee;
				_consigneeCompanyName = c.companyName;
				_consigneeAddr1 = c.address1;
				_consigneeAddr2 = c.address2;
				_consigneeCity = c.city;
				_consigneeState = c.state;
				_consigneePostalCode = c.postalCode;
				_consigneePhone = c.phone;
				_consigneeContact = c.contact;
				_toZip = c.postalCode;
				if (c.terminal)
					_consigneeTerminal = c.terminal;
				else {
					let consigneeInfoFound;
					if (_isRoadRunner || _isRoadRunner) {
						consigneeInfoFound = rrTermList.find(eachobj => eachobj.PostalCode === c.postalCode)
						if (consigneeInfoFound)
							quickQuote.consignee.terminal = consigneeInfoFound.AirportCode;
					} else {
						let consigneeInfoFound = lhTermList.find(eachobj => eachobj.PostalCode === c.postalCode)
						if (!consigneeInfoFound) {
							consigneeInfoFound = fffTermList.find(eachobj => eachobj.PostalCode === c.postalCode)
						}
						if (consigneeInfoFound)
							quickQuote.consignee.terminal = consigneeInfoFound.AirportCode;
					}
					//_consigneeTerminal  = consigneeInfoFound.AirportCode;
				}

			}
			if (quickQuote.rateQuote) {
				let q = quickQuote.rateQuote;
				_quoteId = q.quoteId;
				//console.log("freightchage",JSON.stringify(quickQuote.rateQuote));
				_freightCharge = parseFloat(q.freight_charge).toFixed(2);
				_fuelSurcharge = parseFloat(q.fsc).toFixed(2);
				_pCity = (q.pCity ? q.pCity : '');
				_dCity = (q.dCity ? q.dCity : '');
				_pickup_FreightCharge = (q.pickup_FreightCharge ? parseFloat(q.pickup_FreightCharge).toFixed(2) : 0.00);
				_pickup_FSC = (q.pickup_FSC ? parseFloat(q.pickup_FSC).toFixed(2) : 0.00);
				_pickup_FscRate = (q.pickup_FscRate ? parseFloat(q.pickup_FscRate).toFixed(2) : 0.00);
				_total_Pickup = (q.total_Pickup ? parseFloat(q.total_Pickup).toFixed(2) : 0.00);
				_delivery_FreightCharge = (q.delivery_FreightCharge ? parseFloat(q.delivery_FreightCharge).toFixed(2) : 0.00);
				_delivery_FSC = (q.delivery_FSC ? parseFloat(q.delivery_FSC).toFixed(2) : 0.00);
				_delivery_FscRate = (q.delivery_FscRate ? parseFloat(q.delivery_FscRate).toFixed(2) : 0.00);
				_total_Delivery = (q.total_Delivery ? parseFloat(q.total_Delivery).toFixed(2) : 0.00);
				//accessorialTotalAmt = (q.accessorialPrice ? parseFloat(q.accessorialPrice).toFixed(2) : 0.00);
				//_totalCharges = (q.netTotalPrice ? parseFloat(q.netTotalPrice).toFixed(2) : 0.00);
				_discountCharges = (q.discountPrice ? parseFloat(q.discountPrice).toFixed(2) : 0.00);
				_lineHaulPrice = (q.lineHaulPrice ? parseFloat(q.lineHaulPrice).toFixed(2) : 0.00);
				if (_isLinehaul || _isRoadRunner) {
					_westCoastSurchargeFee = (q.westCoastSurchargeFee ? parseFloat(q.westCoastSurchargeFee).toFixed(2) : 0.00);
					_crossDockFee = (q.crossDockFee ? parseFloat(q.crossDockFee).toFixed(2) : 0.00);
					_dropFee = (q.dropFee ? parseFloat(q.dropFee).toFixed(2) : 0.00);
					_yvrBorderFee = (q.yvrBorderFee ? parseFloat(q.yvrBorderFee).toFixed(2) : 0.00);
					_yvrCrossFee = (q.yvrCrossFee ? parseFloat(q.yvrCrossFee).toFixed(2) : 0.00);
				}


			}

			if (quickQuote.shipment) {
				let s = quickQuote.shipment;
				if (s.serviceType) {
					let serviceTyp = serviceTypeList.find(eachobj => eachobj.value === s.serviceType);
					_serviceType = serviceTyp.label
				}
				if (s.moveType) {
					let moveTyp = typeOfMoveList.find(eachobj => eachobj.value === s.moveType);
					_moveType = moveTyp.label;
				}
				if (s.linehaulServiceType && s.linehaulServiceType != 0) {
					let lineHaulSerTyp = lineHaulServiceTypeList.find(eachobj => eachobj.value === s.linehaulServiceType);
					_lineHaulServiceType = lineHaulSerTyp.label;
				}
				//_comments = s.notes;
				//recoverFromCarrier=s.recoverFromCarrier;
				//dropToCarrier=s.dropToCarrier;
				_shipperNotes = s.shipperNotes;
				_consigneeNotes = s.consigneeNotes;
				_pounds = s.weight;
				_cbm = s.dimWeight;
				_pieces = s.qty;
				_pallets = s.qty;
				_accSerType = s.accSerType;
				_shipperReady = s.pickupOpenTime;
				_shipperClose = s.pickupCloseTime;
				_consigneeReady = s.deliveryOpenTime;
				_consigneeClose = s.deliveryCloseTime;
				if (s.dimList) {
					s.dimList.forEach(eachDim => {
						_dim.push({ qty: eachDim.qty, weight: eachDim.weight || 0, length: eachDim.length, width: eachDim.width, height: eachDim.height, description: eachDim.description, qty_ErrorClass: "form-control", weight_ErrorClass: "form-control", length_ErrorClass: "form-control", width_ErrorClass: "form-control", height_ErrorClass: "form-control" });
					})
				}

				if (s.accessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.accessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
							if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
							}
						listOfAccessorials.push(eachAcc.description + "- $" + amt);
						if (_accSerType === "both" && !_isLinehaul && !_isRoadRunner)
							amt = amt * 2;

						amt = parseFloat(amt).toFixed(2);
						listOfAccessorialsAmt.push("$" + amt)
						accessorialTotalAmt = Number(accessorialTotalAmt) + Number(amt);
						//console.log("tot",accessorialTotalAmt,"amt",amt)
						}
					})
					accessorialDesc = listOfAccessorials.join(" , ");
					accessorialAmts = listOfAccessorialsAmt.join(" + ");
					accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);
				}
				if (s.pickupAccessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.pickupAccessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
						if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
						}
						listOfPickupAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
						amt = parseFloat(amt).toFixed(2);
						listOfPickupAccessorialsAmt.push("$" + amt)
						pickupAccessorialTotalAmt = Number(pickupAccessorialTotalAmt) + Number(amt);
						//console.log("tot",pickupAccessorialTotalAmt,"amt",amt)
					}
					})
					pickupAccessorialDesc = listOfPickupAccessorials.join(" , ");
					pickupAccessorialAmts = listOfPickupAccessorialsAmt.join(" + ");
					pickupAccessorialTotalAmt = parseFloat(pickupAccessorialTotalAmt).toFixed(2);
				}
				if (s.deliveryAccessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.deliveryAccessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
						if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
						}
						listOfDeliveryAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
						amt = parseFloat(amt).toFixed(2);
						listOfDeliveryAccessorialsAmt.push("$" + amt)
						deliveryAccessorialTotalAmt = Number(deliveryAccessorialTotalAmt) + Number(amt);
						//console.log("tot",deliveryAccessorialTotalAmt,"amt",amt)
					}
					})
					deliveryAccessorialDesc = listOfDeliveryAccessorials.join(" , ");
					deliveryAccessorialAmts = listOfDeliveryAccessorialsAmt.join(" + ");
					deliveryAccessorialTotalAmt = parseFloat(deliveryAccessorialTotalAmt).toFixed(2);
				}
				accessorialTotalAmt = Number(pickupAccessorialTotalAmt) + Number(deliveryAccessorialTotalAmt);
				accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);
			}
			if (_isLinehaul || _isRoadRunner)
				_totalCharges = (Number(_total_Pickup) + Number(_total_Delivery) + Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt) + Number(_westCoastSurchargeFee) + Number(_crossDockFee) + Number(_dropFee) + Number(_yvrBorderFee) + Number(_yvrCrossFee) + Number(_nationWideAccAmt)).toFixed(2);
			else
				_totalCharges = ((Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt) + Number(_lineHaulPrice)) - (Number(_discountCharges))).toFixed(2);


		} else if (!quoteHis) {
			_isLinehaul = quickQuote.isLinehaul;
			_isRoadRunner = quickQuote.isRoadRunner;
			if (quickQuote.customer) {
				let c = quickQuote.customer;
				_custCompanyName = c.companyName;
				_custEmail = c.email;
				_custPhone = c.phone && c.phone.length > 9 ? "(" + c.phone.substring(0, 3) + ") " + c.phone.substring(4, 7) + c.phone.substring(7) : c.phone;
			}
			if (quickQuote.shipper) {
				let s = quickQuote.shipper;
				_shipperCompanyName = s.companyName;
				_shipperAddr1 = s.address1;
				_shipperAddr2 = s.address2;
				_shipperCity = s.city;
				_shipperState = s.state;
				_shipperPostalCode = s.postalCode;
				_shipperPhone = s.phone;
				_shipperContact = s.contact;
				_shipperReady = '09:00';
				_shipperClose = '15:00';
				_fromZip = s.postalCode;
			}
			if (quickQuote.consignee) {
				let c = quickQuote.consignee;
				_consigneeCompanyName = c.companyName;
				_consigneeAddr1 = c.address1;
				_consigneeAddr2 = c.address2;
				_consigneeCity = c.city;
				_consigneeState = c.state;
				_consigneePostalCode = c.postalCode;
				_consigneePhone = c.phone;
				_consigneeContact = c.contact;
				_consigneeReady = '09:00';
				_consigneeClose = '15:00';
				_toZip = c.postalCode;
			}
			if (quickQuote.addValue) {
				let a = quickQuote.addValue;
				_serviceType = a.serviceTypeName;
				_moveType = a.typeOfMoveName;
				_lineHaulServiceType = a.lineHaulServiceTypeName;
				_accSerType = a.accSerType;

			}
			if (quickQuote.quote) {
				_quoteId = quickQuote.quote.quoteId;
				if (quickQuote.quote.rateQuote) {
					//console.log("freightchage",JSON.stringify(quickQuote.rateQuote));
					let q = quickQuote.quote.rateQuote;
					_freightCharge = parseFloat(q.freight_charge).toFixed(2);
					_fuelSurcharge = parseFloat(q.fsc).toFixed(2);
					_pCity = (q.pCity ? q.pCity : '');
					_dCity = (q.dCity ? q.dCity : '');
					_pickup_FreightCharge = (q.pickup_FreightCharge ? parseFloat(q.pickup_FreightCharge).toFixed(2) : 0.00);
					_pickup_FSC = (q.pickup_FSC ? parseFloat(q.pickup_FSC).toFixed(2) : 0.00);
					_pickup_FscRate = (q.pickup_FscRate ? parseFloat(q.pickup_FscRate).toFixed(2) : 0.00);
					_total_Pickup = (q.total_Pickup ? parseFloat(q.total_Pickup).toFixed(2) : 0.00);
					_delivery_FreightCharge = (q.delivery_FreightCharge ? parseFloat(q.delivery_FreightCharge).toFixed(2) : 0.00);
					_delivery_FSC = (q.delivery_FSC ? parseFloat(q.delivery_FSC).toFixed(2) : 0.00);
					_delivery_FscRate = (q.delivery_FscRate ? parseFloat(q.delivery_FscRate).toFixed(2) : 0.00);
					_total_Delivery = (q.total_Delivery ? parseFloat(q.total_Delivery).toFixed(2) : 0.00);
					//accessorialTotalAmt = (q.accessorialPrice ? parseFloat(q.accessorialPrice).toFixed(2) : 0.00);
					_totalCharges = (q.netTotalPrice ? parseFloat(q.netTotalPrice).toFixed(2) : 0.00);
					_discountCharges = (q.discountPrice ? parseFloat(q.discountPrice).toFixed(2) : 0.00);
					_lineHaulPrice = (q.lineHaulPrice ? parseFloat(q.lineHaulPrice).toFixed(2) : 0.00);
					if (_isLinehaul || _isRoadRunner) {
						_westCoastSurchargeFee = (q.westCoastSurchargeFee ? parseFloat(q.westCoastSurchargeFee).toFixed(2) : 0.00);
						_crossDockFee = (q.crossDockFee ? parseFloat(q.crossDockFee).toFixed(2) : 0.00);
						_dropFee = (q.dropFee ? parseFloat(q.dropFee).toFixed(2) : 0.00);
						_yvrBorderFee = (q.yvrBorderFee ? parseFloat(q.yvrBorderFee).toFixed(2) : 0.00);
						_yvrCrossFee = (q.yvrCrossFee ? parseFloat(q.yvrCrossFee).toFixed(2) : 0.00);
					}
				}
			}
			if (quickQuote.shipment) {
				let s = quickQuote.shipment;
				//_comments = s.notes;
				//recoverFromCarrier=s.recoverFromCarrier;
				//dropToCarrier=s.dropToCarrier;
				_shipperNotes = s.shipperNotes;
				_consigneeNotes = s.consigneeNotes;
				_pounds = s.weight;
				_cbm = s.dimWeight;
				_pieces = s.qty;
				_pallets = s.qty;
				if (_quoteId === null) _quoteId = "CONTACT SALES";
				console.log("s.accessorials",s.accessorials);
				if (s.accessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.accessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
						if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
						}

						listOfAccessorials.push(eachAcc.description + "- $" + amt);
						if (_accSerType === "both" && !_isLinehaul && !_isRoadRunner)
							amt = amt * 2;

						amt = parseFloat(amt).toFixed(2);
						listOfAccessorialsAmt.push("$" + amt)
						accessorialTotalAmt = Number(accessorialTotalAmt) + Number(amt);
						}
					})
					accessorialDesc = listOfAccessorials.join(" , ");
					accessorialAmts = listOfAccessorialsAmt.join(" + ");
					accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);
				}
				console.log("s.pickupAccessorials",s.pickupAccessorials);
				if (s.pickupAccessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.pickupAccessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
						if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
						}
						listOfPickupAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
						amt = parseFloat(amt).toFixed(2);
						listOfPickupAccessorialsAmt.push("$" + amt);
						pickupAccessorialTotalAmt = Number(pickupAccessorialTotalAmt) + Number(amt);
						//console.log("tot",pickupAccessorialTotalAmt,"amt",amt)
					}
					})
					pickupAccessorialDesc = listOfPickupAccessorials.join(" , ");
					pickupAccessorialAmts = listOfPickupAccessorialsAmt.join(" + ");
					pickupAccessorialTotalAmt = parseFloat(pickupAccessorialTotalAmt).toFixed(2);
				}
				console.log("s.deliveryAccessorials",s.deliveryAccessorials);
				if (s.deliveryAccessorials && (!_quoteId.includes("CONTACT SALES") || !_quoteId.includes("contact sales"))) {
					s.deliveryAccessorials.forEach(eachAcc => {
						if((eachAcc.code).trim() ==='GRDROP'){
							_nationWideAccDesc = eachAcc.description;
							_nationWideAcc =true;
							_nationWideAccAmt=eachAcc.amount;
						}else
						{
						if (eachAcc.surType) {
							if ((eachAcc.surType).trim() === 'FLAT')
								amt = eachAcc.amount;
							else if ((eachAcc.surType).trim() === 'PERPOUND') {
								if (eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
									amt = eachAcc.min;
								else
									amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
							}
						}
						listOfDeliveryAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
						amt = parseFloat(amt).toFixed(2);
						listOfDeliveryAccessorialsAmt.push("$" + amt)
						deliveryAccessorialTotalAmt = Number(deliveryAccessorialTotalAmt) + Number(amt);
						//console.log("tot",deliveryAccessorialTotalAmt,"amt",amt)
						}
					})
					deliveryAccessorialDesc = listOfDeliveryAccessorials.join(" , ");
					deliveryAccessorialAmts = listOfDeliveryAccessorialsAmt.join(" + ");
					deliveryAccessorialTotalAmt = parseFloat(deliveryAccessorialTotalAmt).toFixed(2);
				}
				accessorialTotalAmt = Number(pickupAccessorialTotalAmt) + Number(deliveryAccessorialTotalAmt);
				accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);
			}
			if (_isLinehaul || _isRoadRunner)
				_totalCharges = (Number(_total_Pickup) + Number(_total_Delivery) + Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt) + Number(_westCoastSurchargeFee) + Number(_crossDockFee) + Number(_dropFee) + Number(_yvrBorderFee) + Number(_yvrCrossFee) +  Number(_nationWideAccAmt)).toFixed(2);
			else if (!_isRoadRunner)
				_totalCharges = ((Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt) + Number(_lineHaulPrice)) - (Number(_discountCharges))).toFixed(2);
		}
	}



	const handlePrintClick = () => {
		const printableElements = document.getElementById('print-area').innerHTML;
		var w = window.open('', "_blank");
		let cssStyles = extractStyles(window.document);
		const orderHtml = `<html><head><title></title>` + cssStyles + `</head><body>` + printableElements + `</body></html>`
		w.document.write(orderHtml);
		setTimeout(function () {
			w.window.print();
			w.window.focus();
			w.window.close();
		}, 1000);

	};

	return (
		<section className="mt-180 mb-4" id="print-area">
			{(_quoteId.includes("CONTACT SALES") || _quoteId.includes("contact sales")) ?
				<div className="col-md-12 alert alert-danger text-center text-danger QuoteLabel" id="invprintAll">
					<div className="row">
						<div className="col-md-12 mt-2 ">
							<h6> <i className="fas fa-check-circle" aria-hidden="true"></i> PLEASE {_quoteId} - <span className="text-success">  Mon - Fri. 7:30am - 4pm PST </span> -  <span className="text-primary"> 714.995.9300 ext 40060 </span></h6>
						</div>
					</div>
				</div>
				: ''
			}
			<div className={loading ? 'container parentDisable' : ''}>
				{loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'} />}
				<div className={hideQuoteDiv ? "row d-none" : "row"} >
					<div className="col-md-6 order-md-1 pl-0" >
						<div className="card card-custom">
							<div className="d-flex">
								<h4 className="mb-3 pageHead">Shipment Details</h4>
							</div>
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md mt-2">
												<label className="QuoteLabel">Company-Name:</label>
											</div>
											<div className="col-md mt-2">
												<label className="QuoteLabelData"> {_custCompanyName} </label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md-6 mt-2">
												<label className="QuoteLabel"> Email:</label>
											</div>
											<div className="col-md-6 mt-2">
												<label className="QuoteLabelData">  {_custEmail}</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row" >
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md-6 mt-2">
												<label className="QuoteLabel"> Phone No:</label>
											</div>
											<div className="col-md-6 mt-2">
												<label className="QuoteLabelData">  {_custPhone}</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row" >
									<div className="col-md-12 mb-2">
										<div className="row quoteOut" >
											<div className="col-md-6 pb-2">
												<div className="row">
													<div className="col-md-12 mt-2">
														<label className="QuoteLabel"> Shipper Information</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 mb-2 QuoteTableData">
														{_shipperCompanyName} {_shipperCompanyName && <br />}
														{_shipperAddr1} {_shipperAddr1 && ","} {_shipperAddr2} {_shipperAddr2 && <br />}
														{_shipperCity} {_shipperCity && ","} {_shipperState} {_shipperState && ","} {_shipperPostalCode}

													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Phone:</label>
													</div>

													<div className="col-md-8 ">
														<label className="QuoteTableData">{_shipperPhone}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 ">
														<label className="QuoteTableLabel"> Contact: </label>
													</div>
													<div className="col-md-8 " >
														<label className="QuoteTableData"> {_shipperContact}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 ">
														<label className="QuoteTableLabel">Ready:</label>
													</div>
													<div className="col-md-8 " >
														<label className="QuoteTableData"> {_shipperReady} </label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 ">
														<label className="QuoteTableLabel">Close:</label>
													</div>
													<div className="col-md-8 " >
														<label className="QuoteTableData"> {_shipperClose} </label>
													</div>
												</div>
											</div>
											<div className="col-md-6 ">

												<div className="row">
													<div className="col-md-12 mt-2">
														<label className="QuoteLabel"> Consignee Information</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 mb-2 QuoteTableData" >
														{_consigneeCompanyName} {_consigneeCompanyName && <br />}
														{_consigneeAddr1} {_consigneeAddr1 && ","} {_consigneeAddr2} {_consigneeAddr2 && <br />}
														{_consigneeCity} {_consigneeCity && ","} {_consigneeState} {_consigneeState && ","} {_consigneePostalCode}
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 ">
														<label className="QuoteTableLabel"> Phone:</label>
													</div>
													<div className="col-md-8 ">
														<label className="QuoteTableData">{_consigneePhone}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 " >
														<label className="QuoteTableLabel"> Contact:</label>
													</div>
													<div className="col-md-8 ">
														<label className="QuoteTableData"> {_consigneeContact}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 ">
														<label className="QuoteTableLabel">Ready:</label>
													</div>
													<div className="col-md-8 " >
														<label className="QuoteTableData">{_consigneeReady}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4 " >
														<label className="QuoteTableLabel">Close: </label>
													</div>
													<div className="col-md-8 " >
														<label className="QuoteTableData">{_consigneeClose} </label>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut " >
											<div className="col-md-12 mb-3">
												<div className="row" >
													<div className="col-md-6 mt-2">
														<label className="QuoteLabel">From Zip</label>
													</div>

													<div className="col-md-6 mt-2">
														<label className="QuoteLabel">To Zip</label>
													</div>
												</div>

												<div className="row">
													<div className="col-md-6 QuoteLabelDataLarge">
														{_fromZip}
													</div>
													<div className="col-md-6 QuoteLabelDataLarge">
														{_toZip}
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
								<div className="row quoteOut" >
									<div className="col-md-12 mt-2 mb-2">
										<div className="row" hidden={true}>
											<div className="col-md-6 mt-2">
												<label className="QuoteTableLabel"> Service Type: </label>
											</div>
											<div className="col-md-6 mt-2">
												<label className="QuoteLabelData">  {_serviceType} </label>
											</div>
										</div>
										<div className="row" hidden={true}>
											<div className="col-md-6">
												<label className="QuoteTableLabel"> Move Type: </label>
											</div>
											<div className="col-md-6 ">
												<label className="QuoteLabelData">  {_moveType} </label>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6 ">
												<label className="QuoteTableLabel"> LineHaul Service Type: </label>
											</div>
											<div className="col-md-6 ">
												<label className="QuoteLabelData">  {_lineHaulServiceType} </label>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6 ">
												<label className="QuoteTableLabel">Shipper Notes:</label>
											</div>
											<div className="col-md-6">
												<label className="QuoteLabelData">{_shipperNotes}</label>
											</div>

										</div>
										<div className="row">
											<div className="col-md-6 ">
												<label className="QuoteTableLabel">Consignee Notes:</label>
											</div>
											<div className="col-md-6">
												<label className="QuoteLabelData">{_consigneeNotes}</label>
											</div>

										</div>
										{/* <div className="row">
											<div className="col-md-6 ">
												<label className="QuoteTableLabel">Recover from Carrier:</label>
											</div>
											<div className="col-md-6">
												<label className="QuoteLabelData">{recoverFromCarrier}</label>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6 ">
												<label className="QuoteTableLabel">Drop to Carrier:</label>
											</div>
											<div className="col-md-6">
												<label className="QuoteLabelData">{dropToCarrier}</label>
											</div>

										</div> */}

									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6 order-md-1 pr-0">
						<div className="card card-custom">
							<div className="row">
								<div className="col-md-9 d-flex">
									{(_quoteId.includes("CONTACT SALES") || _quoteId.includes("contact sales"))?
										<h4 className="mb-3 pageHead colorRed"> PLEASE {_quoteId}</h4>
										:
										<h4 className="mb-3 pageHead">QUOTE ID &nbsp;   :  &nbsp;&nbsp; {_quoteId}</h4>}
								</div>
								<div className="col-md-3 excludeInPrint">
									<button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={handlePrintClick}>Print &nbsp;<i className="fas fa-print"></i> </button>
								</div>
							</div>
							{/* <div className="d-flex">
								<h4 className="mb-3 pageHead">QUOTE ID &nbsp;   :  &nbsp;&nbsp;   {_quoteId}</h4>
							</div> */}
							<div className="col-md-12">
								<div className="row quoteOut" >
									<div className="col-md-12">
										<div className="row">
											<div className="col-md-3 mt-2 mb-2 text-center brdrRight">
												<div className="QuoteLabel mb-2">Pounds</div>
												<div className="QuoteLabelDataLarge">{_pounds}</div>
											</div>
											<div className="col-md-3 mt-2 mb-2 text-center brdrRight">
												<div className="QuoteLabel mb-2">Pieces</div>
												<div className="QuoteLabelDataLarge">{_pieces}</div>
											</div>
											<div className="col-md-3 mt-2 mb-2 text-center brdrRight">
												<div className="QuoteLabel mb-2">Dim Weight</div>
												<label className="QuoteLabelDataLarge">{_cbm}</label>
											</div>
											<div className="col-md-3 mt-2 mb-2 text-center">
												<div className="QuoteLabel mb-2">Pallets</div>
												<label className="QuoteLabelDataLarge">{_pallets}</label>
											</div>
										</div>


										<div className="row">
											<div className="col-md-3 text-center">

											</div>
											<div className="col-md-3 text-center">

											</div>
											<div className="col-md-3 text-center">

											</div>
											<div className="col-md-3 text-center">

											</div>
										</div>
									</div>
								</div>
								<hr />



								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md-12 mb-2">
												<div className="row">
													<div className="col-md-12 mt-2">
														<label className="QuoteLabel "> Accessorials Details:</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6" >
														<label className="QuoteTableLabel"> Pickup Accessorials:</label>

													</div>
													<div className="col-md-6 QuoteTableData" >
														{pickupAccessorialDesc}
													</div>
												</div>
												<div className="row">
													<div className="col-md-6" >
														<label className="QuoteTableLabel"> Delivery Accessorials:</label>

													</div>
													<div className="col-md-6 QuoteTableData" >
														{deliveryAccessorialDesc}
													</div>
												</div>
												{(!_isRoadRunner) &&
												<>
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Pickup Charge:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> {pickupAccessorialAmts} </label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Delivery Charge:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> {deliveryAccessorialAmts} </label>
													</div>
												</div>
												</>
												}
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Total:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${accessorialTotalAmt} </label>
													</div>
												</div>
												{/* <p><strong>Note:</strong> Accessorial(s) apply to {_accSerType === "both" ? "Pickup and Delivery" : _accSerType} </p> */}
											</div>
										</div>
									</div>
								</div>
								{(_isLinehaul || _isRoadRunner) &&
									<div className="row">
										<div className="col-md-12 mb-3">
											<div className="row quoteOut" >
												<div className="col-md-12 mb-3">
													<div className="row">
														<div className="col-md-12 mt-2">
															<label className="QuoteLabel "> {(_isRoadRunner) ?"Pickup and Delivery Details":"LineHaul Details:"}</label>
														</div>
														<div className="col-md-6 mt-2">
															<label className="QuoteLabel"> Pickup  &ensp;&ensp;&ensp;&ensp; <span className="QuoteTableData">{_pCity}</span></label>
														</div>
														<div className="col-md-6 mt-2">
															<label className="QuoteLabel">  Delivery   &ensp;&ensp;&ensp;&ensp; <span className="QuoteTableData">{_dCity}</span></label>
														</div>
													</div>
													<div className="row">
														<div className="col-md-4">
															<label className="QuoteTableLabel"> Pickup Freight Charge:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_pickup_FreightCharge}</label>
														</div>
														<div className="col-md-4">
															<label className="QuoteTableLabel"> Delivery Freight Charge:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_delivery_FreightCharge}</label>
														</div>
													</div>
													<div className="row">
														<div className="col-md-4">
															<label className="QuoteTableLabel"> Pickup Fuel Surcharge:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_pickup_FSC}</label>
														</div>
														<div className="col-md-4">
															<label className="QuoteTableLabel"> Delivery Fuel Surcharge:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_delivery_FSC}</label>
														</div>
													</div>
													<div className="row">
														<div className="col-md-4">
															<label className="QuoteTableLabel">  Total Charges:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_total_Pickup}</label>
														</div>
														<div className="col-md-4">
															<label className="QuoteTableLabel"> Total Charges:</label>
														</div>
														<div className="col-md-2">
															<label className="QuoteTableData"> ${_total_Delivery}</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md-12 mb-3">
												<div className="row">
													<div className="col-md-12 mt-2">
														<label className="QuoteLabel"> Charges:</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel">{(_isLinehaul || _isRoadRunner) ? "LineHaul " : "Freight "} Charge:</label>
													</div>
													<div className="col-md-6">

														<label className="QuoteTableData"> ${_freightCharge}</label>
													</div>
												</div>
												{/* {(_isRoadRunner && _lineHaulPrice!== 0)  &&
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel">LineHaul Charge:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> ${_lineHaulPrice}</label> 												
												</div>
												</div>} */}

												{/* <div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> West Coast Surcharge Fee:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> $</label>
												</div>
											</div> */}
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Fuel Surcharge:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_fuelSurcharge}</label>
													</div>
												</div>

												{(_isLinehaul || _isRoadRunner) && _westCoastSurchargeFee !== 0.00 &&
													<div className="row">
														<div className="col-md-6">
															<label className="QuoteTableLabel"> West Coast Surcharge Fee:</label>
														</div>
														<div className="col-md-6">
															<label className="QuoteTableData"> ${_westCoastSurchargeFee}</label>
														</div>
													</div>
												}
												{(_isLinehaul || _isRoadRunner) && _crossDockFee !== 0.00 &&
													<div className="row">
														<div className="col-md-6">
															<label className="QuoteTableLabel"> Cross Dock Fee:</label>
														</div>
														<div className="col-md-6">
															<label className="QuoteTableData"> ${_crossDockFee}</label>
														</div>
													</div>
												}
												{(_isLinehaul || _isRoadRunner) && _dropFee !== 0.00 &&
													<div className="row">
														<div className="col-md-6">
															<label className="QuoteTableLabel"> Drop Fee:</label>
														</div>
														<div className="col-md-6">
															<label className="QuoteTableData"> ${_dropFee}</label>
														</div>
													</div>
												}
												{(_isLinehaul || _isRoadRunner) && _yvrBorderFee !== 0.00 &&
													<div className="row">
														<div className="col-md-6">
															<label className="QuoteTableLabel"> YVR Border Fee:</label>
														</div>
														<div className="col-md-6">
															<label className="QuoteTableData"> ${_yvrBorderFee}</label>
														</div>
													</div>
												}
												{(_isLinehaul || _isRoadRunner) && _yvrCrossFee !== 0.00 &&
													<div className="row">
														<div className="col-md-6">
															<label className="QuoteTableLabel"> YVR Cross Fee:</label>
														</div>
														<div className="col-md-6">
															<label className="QuoteTableData"> ${_yvrCrossFee}</label>
														</div>
													</div>
												}
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Accessorial Total:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${accessorialTotalAmt} </label>
													</div>
												</div>
												{(_nationWideAcc) &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> {_nationWideAccDesc}:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${parseFloat(_nationWideAccAmt).toFixed(2)} </label>
													</div>
												</div>
												}
											</div>
										</div>
									</div>
								</div>




								<div className="row" >
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md mt-2">
												<label className="QuoteLabel"> Total Charges:</label>
											</div>
											<div className="col-md mt-2">
												<label className="QuoteLabelDataLarge"> ${_totalCharges} </label>
											</div>
										</div>
									</div>
								</div>
								<div className="row " id="invprintAll">
									<div className="col-md-3 offset-md-3 pr-0">
										<button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={goBack}><i className="fas fa-arrow-alt-circle-left"></i> BACK</button>
									</div>
									{/* <div className="col-md-3 pr-0">
										<button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={handlePrintClick}>Print &nbsp;<i className="fas fa-print"></i> </button>
									</div> */}
									<div className="col-md-6 pr-0">
										{(_quoteId.includes("CONTACT SALES") || _quoteId.includes("contact sales")) ?
											<button className="btn btn-secondary btn-block pull-right" type="button" disabled onClick={contiueBooking}>Continue Booking &nbsp;<i className="fas fa-arrow-alt-circle-right"></i> </button>
											:
											<button className="btn btn-primary btn-block pull-right" type="button" onClick={contiueBooking}>Continue Booking &nbsp;<i className="fas fa-arrow-alt-circle-right"></i> </button>
										}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
export default QuoteProposed;