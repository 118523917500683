import { useEffect, useState } from 'react'

const DataAccessorialEdit = () => {
  
    const  [initData] = [{
        //description : '',
       // chargeType : '',
       newrequireAuthorization : '',
       newrate: '',
       newminimum: '',
       newmaximum: '',
       newperLbRate1: '',
       newperLbRate2: '',
       newbaseAmount: '',
       newdeInterval: '',
       newcalcDeInterval: '',
    }];
    const [initDataReq] = [{
        newrequireAuthorization_ErrorClass : 'custom-control-input',
        newrate_ErrorClass: 'form-control',
        newminimum_ErrorClass: 'form-control',
        newmaximum_ErrorClass: 'form-control',
        newperLbRate1_ErrorClass: 'form-control',
        newperLbRate2_ErrorClass: 'form-control',
        newbaseAmount_ErrorClass: 'form-control',
        newdeInterval_ErrorClass: 'form-control',
        newcalcDeInterval_ErrorClass: 'form-control',
        newrequireAuthorization : true,
        newrate: true,
        newminimum: true,
        newmaximum: true,
        newperLbRate1: true,
        newperLbRate2: true,
        newbaseAmount: true,
        newdeInterval: true,
        newcalcDeInterval: true
         
    }];

    const [formEditData, setFormEditData] = useState(initData);

    const [formEditDataReq, setFormEditDataReq] = useState(initDataReq);

    const onSubmitEditAccessorial = () => {
        const copy = {...formEditData};
        const errCopy = {...formEditDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            if( key==="newrequireAuthorization" ){
                _css = 'custom-control-input';
            }
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
            setFormEditDataReq({...formEditDataReq,...errObject});
            validationReqFlag = true;
        } 
        return validationReqFlag;
            
    }

    const onValidateEditAccessorial = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if( errname==="newrequireAuthorization_ErrorClass" ){
            _css = 'custom-control-input';
        }
        if( errname==="description_ErrorClass" || errname==="chargeType_ErrorClass" ){
            if (value) {
              errState = { [name]: true, [errname]: 'text-danger' };
              status = true;
            } else if (!value) {
              errState = { [name]: false, [errname]: 'text-danger d-none' };
              status = true;
            }
          }else{
            if(value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
            }else if(!value) {
                errState = {[name]: false,[errname]: _css+''};
                status=true;
            }
        }
        if(status)
            setFormEditDataReq({...formEditDataReq, ...errState});
      }

    const handleEditInputChange = (name, value) => {
        setFormEditData({...formEditData, [name]: value});
    }

    const clearEditAccessorialForm = () => {
        setFormEditData(initData);
        setFormEditDataReq(initDataReq);
        //console.log("fd r",formEditData)
    }

    const setFormEditDataObj = (_obj) => {
        // change the upatedTariffs attributes based on response we get
        // update the if condition based on api output and change the assignments
        //if(_obj?.updatedTariffs){
            var newData = {};
            newData = { newrequireAuthorization : _obj?.newrequireAuthorization, 
                newrate: _obj?.newrate, 
                newminimum: _obj?.newminimum, 
                newmaximum: _obj?.newmaximum, 
                newperLbRate1: _obj?.newperLbRate1, 
                newperLbRate2: _obj?.newperLbRate2, 
                newbaseAmount: _obj?.newbaseAmount, 
                newdeInterval: _obj?.newdeInterval, 
                newcalcDeInterval: _obj?.newcalcDeInterval, 
            };
            setFormEditData({...formEditData,...newData});
          //  }
    }

    return {
        formEditData,
        formEditDataReq,
        handleEditInputChange,
        onSubmitEditAccessorial,
        onValidateEditAccessorial,
        clearEditAccessorialForm,
        setFormEditDataObj
    }
}

export default DataAccessorialEdit;