import React,{useEffect} from 'react'
import pic_dennis from '../../../assets/img/pic_dennis.jpg'
import pic_eric from '../../../assets/img/pic_eric.jpg'
import pic_jessie from '../../../assets/img/pic_jessie.jpg'
import pic_sue from '../../../assets/img/pic_sue.jpg'

const OurExecutives = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">Our Executives</h4>
                        </div>
						<div className="row">
							<div className="col-lg-2 col-md-4 ">
								<div className="exeCard">
									<img src={pic_sue} className="img-fluid" alt='Sue Beattie' />
									<div className="exeHead mt-1">
										<h6>Sue Beattie</h6>
										CEO, of Freight Force, Inc.
									</div>
								</div>
							</div>
							<div className="col-lg-10 col-md-8 text-justify exeDesc">
								<p><strong>Sue</strong> has over twenty-five years of transportation and logistics experience.  Over the last twenty years she’s held several positions with Mainfreight, Inc. and previously with Target Logistic Services; from Vice President, Senior Vice President and Vice President, Administration.  Sue has extensive knowledge in all areas of the transportation industry from negotiating with international partners, formulating agreements, handling all policies and procedures including Human Resources and legal contracts.  She has held positions with other leading transportation logistics providers; including Panalpina, and Profit Freight Systems (now Agility). She graduated from the University of John Moore’s, Liverpool, England, UK with a B.A. in Business Administration.
								</p>
							</div>
							</div>
							<hr className="mb-3"/>
							<div className="row">
							<div className="col-lg-10 col-md-8 text-justify exeDesc">
									<p><strong>Dennis Kuhlman</strong>, Chief Operations Officer, is a seasoned transportation executive with over 35 years of industry experience. Dennis’s background includes holding key upper management positions within multi modes of the transportation industry to include assignments with Integrator, Truck Brokerage, and Freight Forwarding operations. These executive assignments included responsibilities for Facilities, Operations and Sales. Career highlights include being a member of the UPS Airline planning team along with creation and implementation of the Entertainment Media Logistics Model and the Aerospace Logistics Group vertical providing value added services within the freight forwarding environment.</p>
								</div>
								<div className="col-lg-2 col-md-4">
									<div className="exeCard">
										<img src={pic_dennis} className="img-fluid" alt='Dennis Kuhlman' />
										<div className="exeHead mt-1">
											<h6>Dennis Kuhlman</h6>
											 Chief Operations Officer
										</div>
									</div>
								</div>
							</div>
							<hr className="mb-3"/>
							<div className="row">
								<div className="col-lg-10 col-md-8 text-justify exeDesc">
									<p><strong>Jessie OBryan</strong>, has over 15 years of experience in accounting. She graduated from California State University of Fullerton with a B.A. in Business Administration with a concentration in Finance. She was hired originally by Freight Force as a Staff Accountant 10 years ago and has been promoted over the years to Senior Accountant, Accounting Manager and now
	holds the position of the Controller.
									</p>
								</div>
								<div className="col-lg-2 col-md-4">
									<div className="exeCard">
										<img src={pic_jessie} className="img-fluid" alt='Jessie OBryan' />
										<div className="exeHead mt-1">
											<h6>Jessie OBryan</h6>
											Controller
										</div>
									</div>
								</div>
							</div>
							<hr className="mb-3"/>
							<div className="row">
								<div className="col-lg-2 col-md-4">
									<div className="exeCard">
										<img src={pic_eric} className="img-fluid" alt='Eric Carlson' />
										<div className="exeHead mt-1">
											<h6>Eric Carlson</h6>
											 Director-Operations
										</div>
									</div>
								</div>
								<div className="col-lg-10 col-md-8 text-justify exeDesc">
									<p><strong>Eric</strong> has over 25 years of experience in Transportation including Domestic Airfreight, Expedited Logistics and supply chain management. He has held various positions as a director and general manager in both operations and sales, with other leading companies including Forward Air, R+L Global Logistics and AIT Worldwide Logistics. Eric recently held the position of Midwest Regional Sales Manager with Freight Force and now Director, Operations. He Graduated from Regis University, Denver. CO with a degree in Economics & Political Science.
									</p>
								</div>
							</div>
							
						</div>
                    </div>
                </div>
            </div>
    </section>
  )
}

export default OurExecutives