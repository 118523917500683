export const VALIDATEUSER = 'VALIDATEUSER';
export const REMOVEUSER ='REMOVEUSER';
export const LOGOUTUSER = 'LOGOUTUSER';
export const AUTOREFRESHTOKEN = "AUTOREFRESHTOKEN";
export const ADDDIMENSIONS = "ADDDIMENSIONS";
export const REMOVEDIMENSIONS = "REMOVEDIMENSIONS";
export const ADDQUICKQUOTE = "ADDQUICKQUOTE";
export const ADDTOTALDIMENSIONS="ADDTOTALDIMENSIONS";
export const COVERAGELIST="COVERAGELIST";
export const TARIFFCODEUPDATE="TARIFFCODEUPDATE";

export const validateUserResult = (userProfile) => {
    return {
        type: VALIDATEUSER,
        userProfile: userProfile,
        
    };
};
export const removeUser = () => {
    return {
        type: REMOVEUSER,
    };
};

export const autoRefershToken = (token,userName,isValid) =>{
    return {
        type: AUTOREFRESHTOKEN,
        token: token,
        userName: userName,
        isValid: isValid,
    }
}

export const onRefershSetToken = () =>{
    return (dispatch, getState) => {
        let isValid=false;
        let token=localStorage.getItem("token");
        let userName = localStorage.getItem("username");
        let userProfile = JSON.parse(localStorage.getItem("userProfile"));
        if(token && userName)
        isValid=true;

        dispatch(autoRefershToken(token,userName,isValid))
        dispatch(validateUserResult(userProfile));
    }
}
export const validateUser = ( userProfile) => {
    return (dispatch, getState) => {
        // rest call to validate user
      //  validate = () => {
            let tokenTime = new Date().getTime();
            localStorage.setItem("tokenTime",tokenTime);
            localStorage.setItem("userProfile",JSON.stringify(userProfile));
            dispatch(validateUserResult(userProfile));
      //  };
    }
};

export const logout = () => {
    return {
        type: LOGOUTUSER
    }
}
    export const logoutUser = () =>{
        return (dispatch, getState) => {
            localStorage.clear();
            dispatch(logout());
            dispatch(removeUser());
        }
    }
export const coverageData = (coverageList)=>{
    return {
        type: COVERAGELIST,
        coverageList:coverageList
        
    };
}

export const tariffCodeUpdate = (airportCode)=>{
    return {
        type: TARIFFCODEUPDATE,
        airportCode:airportCode
        
    };
}

export const addDimensions = (dimenstionList)=>{
    return {
        type: ADDDIMENSIONS,
        dimenstionList:dimenstionList
        
    };
}
export const removeDimensions = ()=>{
    return {
        type: REMOVEDIMENSIONS,
    };
}
export const setQuickQuote = (quickQuote)=>{
    return {
        type: ADDQUICKQUOTE,
        quickQuote:quickQuote
        
    };
}
export const addTotalDimensions = (totDimObj)=>{
    return {
        type: ADDTOTALDIMENSIONS,
        totDimObj:totDimObj
        
    };
}