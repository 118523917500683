import React, {  useState, useEffect } from "react";
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate ,useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { processBookNowReq } from "../restAPI/BookQuoteRest";
import { setQuickQuote } from "../../../store/actions/actionTypes";
import DirectBook1 from "./DirectBook1"
import DirectBook2 from "./DirectBook2"
import DirectBook3 from "./DirectBook3"
import DirectBook4 from "./DirectBook4"
import RRLayer from "./RRLayer"
import DataDirectBook from "./data/DataDirectBook"
import DataTerminals from "../quickQuote/Data/DataTerminals";
import DataDimTotals from "../quickQuote/Data/DataDimTotals";
import { fffTermList, lhTermList, rrTermList, accessorialList, quickQuote, rrAccessorialList } from "../restAPI/QuickQuoteRest";
import moment from "moment";

const FreightBooknow = (props) => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const { onChangeBookHandler,  updateMutipleValues,onConfirmBooking, onValidateBookHandler, 
        updateQuoteRequiredState, validateAddress,updateQuickBookObject,setRoadRunnerTermDetailsList,setLineHualTermDetailsList,setfffTermDetailsList, setAccessorialDetailsList,toggleTerminals,
        onChangeDimTotal,    onValidateDimTotal,    onSubmitTotDimensions,    updateDimTotals,    updateTotalDimensionsState,    updateTotalDimensionsReqState,    clearDimTotals,    quoteTotalDim,    totalDimRequired,
        quickBook, stateList, quickBookRequired, packageTypeList, labelStyleList,labelCountList,lineHaulServiceTypeList,fffTermDetails,roadRunnerTermDetails,lineHaulTermDetails,accessorialDetails } = DataDirectBook();
        const { setLineHualTermOptsList, setfffTermOptsList, setAccessorialOptsList,setRoadRunnerTermOptsList,
            roadRunnerTermOpts,lineHualTermOpts, fffTermOpts, accessorialOpts } = DataTerminals();
           
        
     const navState = useLocation();
    const dispatch = useDispatch();
    let userProfileData = useSelector((state) => state.usr)
    let quickQuoteRes = useSelector((state) => state.qqt);
    const dimensionList = useSelector((state) => state.dim);
    let isShipperInfoEdit ,isConsigneeInfoEdit , specialPickup, specialDelivery, dimWeightTotal ,totalPcs ,grossWeight, fffFrom = false, fffTo = false
    , hawb , mawb ,  ref, isRoadRunner;
    let quoteHis = false , isQuoteHis = false;
    let shipperInfoFound = null,consigneeInfoFound = null;
    const [clearAllDims, setClearAllDims] = useState("false");
    const [showAddDimMsg, setShowAddDimMsg] = useState(false);
    let backStatus = false;
    if (navState.state){
        backStatus = navState.state.backStatus;
      }
     //console.log("qqr from selector ="+JSON.stringify(quickBookRes));
     useEffect(() => {
        const aircodeList = localStorage.getItem("airportCodeList");
        const lhTerm = localStorage.getItem("lhTermList");
        const fffTerm = localStorage.getItem("fffTermList");
        const rrTerm = localStorage.getItem("rrTermList");
        //const rrAccessList = localStorage.getItem("rrAccessorialList");
        
        // console.log("linehual",JSON.stringify(lineHualTermOpts))
        // console.log("ffftremila",JSON.stringify(fffTermOpts))
        // console.log("accessorial",JSON.stringify(accessorialOpts))
        //rrAccessList === null || rrAccessList ==="[]" // removed from below 
        if(lhTerm === null || fffTerm === null || rrTerm === null|| aircodeList === null || 
          lhTerm === "[]" || fffTerm === "[]" || rrTerm === "[]" ||aircodeList === "[]"  ){
            if (fffTerm === null || fffTerm === "[]") {
              fffTermList()
                .then(fffList => {
                  //console.log("fffTerm in contianer"+JSON.stringify(fffList))
                  localStorage.setItem("fffTermList", JSON.stringify(fffList));
                  setfffTermDetailsList(fffList);
                  setfffTermOptsList(fffList);
                }).catch(err => {
                  localStorage.setItem("fffTermList", "[]");
                })
            }
            if (lhTerm === null || lhTerm === "[]") {
              lhTermList()
                .then(lhTList => {
                  //console.log("linehaul in contianer"+JSON.stringify(lhTList))
                  localStorage.setItem("lhTermList", JSON.stringify(lhTList));
                  setLineHualTermDetailsList(lhTList);
                  setLineHualTermOptsList(lhTList);
                }).catch(err => {
                  localStorage.setItem("lhTermList", "[]");
                })
            }
            if (rrTerm === null || rrTerm === "[]") {
              rrTermList()
                .then(rrList => {
                  console.log("Road runner in welcome termlist"+JSON.stringify(rrList))
                  localStorage.setItem("rrTermList", JSON.stringify(rrList));
                  setRoadRunnerTermDetailsList(rrList);
                  setRoadRunnerTermOptsList(rrList);
                }).catch(err => {
                  localStorage.setItem("rrTermList", "[]");
                })
            }
            // const rrAccessList = localStorage.getItem("rrAccessorialList");
            // if (rrAccessList === null || rrAccessList === "[]") {
            //   rrAccessorialList()
            //     .then(rrAccList => {
            //       //console.log("response of rrAccessorialList welcomepage"+JSON.stringify(rrAccList))
            //       localStorage.setItem("rrAccessorialList", JSON.stringify(rrAccList));
            //       setRRAccessorialDetailsList(rrAccList);
            //     }).catch(err => {
            //       localStorage.setItem("rrAccessorialList", "[]");
            //     })
            // }
            setLoading(false);
        }else{
            setLoading(false);
        }
    
        const fffTermLists = localStorage.getItem("fffTermList");
        if (fffTermLists !==null && fffTermLists !== "[]") {
          setfffTermDetailsList(JSON.parse(fffTermLists));
          setfffTermOptsList(JSON.parse(fffTermLists));
        }
    
        const accessorialLists = localStorage.getItem("accessorialList");
            if (accessorialLists !==null && accessorialLists !== "[]") {
            setAccessorialDetailsList(JSON.parse(accessorialLists));
            setAccessorialOptsList(JSON.parse(accessorialLists));
          }
          const lhTermLists = localStorage.getItem("lhTermList");
          if (lhTermLists !==null && lhTermLists !== "[]") {
            setLineHualTermDetailsList(JSON.parse(lhTermLists));
            setLineHualTermOptsList(JSON.parse(lhTermLists));
          }
    
          const rrTermLists = localStorage.getItem("rrTermList");
          if (rrTermLists !==null && rrTermLists !== "[]") {
            setRoadRunnerTermDetailsList(JSON.parse(rrTermLists));
            setRoadRunnerTermOptsList(JSON.parse(rrTermLists));
          }
       }, [])// eslint-disable-line
    //console.log(" after Setting state "+JSON.stringify(quickBook))

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); // eslint-disable-line

    
    const confirmBookNow = () => {

      let isValidationReqBooking = onConfirmBooking();
      

      if (!isValidationReqBooking) {
       
          setLoading(true);
          processBookNowReq(userProfileData,quickQuoteRes,dimensionList, quoteTotalDim,quickBook,  attachments,accessorialDetails)
              .then(bookReqRes => {
                  if (bookReqRes instanceof Error) {
                       console.log("error object is final = ",bookReqRes)
                      setErrorStatus({ restError: true, restErrorMessage: bookReqRes.response.data.Message });
                      setTimeout(() => {
                          setErrorStatus({ restError: false, restErrorMessage: "" });
                        }, 25000);
                  } else {
                      //setErrorStatus({ restError: false, restErrorMessage: '' });
                      //console.log("Direct book response =="  ,bookReqRes);
                      dispatch(setQuickQuote(bookReqRes.responseData));
                      navigate("/confirmDirectBook", {  replace: true, state: { quickQuote: bookReqRes.responseData, sendEmailStatus:true} }); //{ quickQuote:props.quickQuote}});
                  }
                  setLoading(false);
              })
          // if( res.status==="success" ){
          //     navigate("/bookConfirm", { state: {quickQuote : res.responseData,  }}); //{ quickQuote:props.quickQuote}});
          // }else{
          //     navigate("/bookConfirm", { state: {quickQuote : res.responseData }}); //{ quickQuote:props.quickQuote}});
          // }
      }
  }

    let attachmentList = [];
    const [attachments, setAttachments] = useState([{fileName: null, data: ""}]);
    
      const readAllfiles = (e) => {
        let fileList = e.target.files;
            for(let i=0;i<fileList.length;i++){
                let file = fileList[i];
                 new Promise(resolve => {
                    let baseURL = "";
                    let reader = new FileReader();
                    reader.readAsDataURL(file);    
                    // on reader load somthing...
                    reader.onload = () => {
                      baseURL = reader.result;
                      resolve(baseURL);
                    };
                  }).then(result =>{ 
                    if(result.indexOf("base64,") !== -1)
                    result = result.substring(result.indexOf("base64,")+7);
                    attachmentList.push({fileName:file.name, data:result }
                    );
                            //console.log(attachmentList);
                            setAttachments([...attachmentList])
                }).catch(err =>{console.log(err)})
            }
      };
     
    const clearSupportingDocs = () => {
        document.getElementById("supportingDocs").value="";
        setAttachments([]);
    }

    const [lhServiceTypes, setLhServiceTypes] = useState([]);

  useEffect(() => {
    let lhList = [];
    if( quickBook.lineHaul == "NH" ){
        lhList = lineHaulServiceTypeList.filter(obj => obj.value !=4 );
    }else{
        lhList = lineHaulServiceTypeList;
    }
    setLhServiceTypes(lhList);
  }, [quickBook.lineHaul])

  useEffect(() => {
    let mounted = true;
    //console.log(mounted+"came to use effect of accessoriallist "+quoteShipping.shiZipcode+",,,,"+quoteShipping.terminalFrom);
    let zipCode ='';
    let paramType = '';
    if (mounted) {
      //&& quotePreFill.lineHaul != "NH" removed from below
      if(quickBook.shiZipcode && quickBook.shiZipcode.length>=3 ){
          if(quickBook.terminalFrom.length >= 3){
          zipCode = quickBook.terminalFrom;
          paramType = "T";
          }else{
          zipCode = quickBook.shiZipcode;
          paramType = "Z";
          }
      accessorialList(zipCode,paramType)
        .then(accList => {
            //console.log("accessorialList "+JSON.stringify(accList))
            localStorage.setItem("accessorialList", JSON.stringify(accList));
            let selectacc = [];
            if (quickBook.lineHaul === "NH"){
              if(accList !== "null"){
                accList?.forEach((eachrow) => {
                  if((eachrow.code).trim() !=='GROUND CARRIER DROP (PU FF)'){
                   selectacc.push(eachrow);
                  }
                })
              }
              //console.log("Selected-- accfor NH",selectacc)
              setAccessorialDetailsList(selectacc);
            setAccessorialOptsList(selectacc);
            }else{
             // console.log("Selected-- ",accList)
            setAccessorialDetailsList(accList);
            setAccessorialOptsList(accList);
            }
            if(accList.length === 1){
              setErrorStatus({ restError: true, restErrorMessage: "Invalid Postal Code ! " });
            }else{
              setErrorStatus({ restError: false, restErrorMessage: "" });
            }
            setTimeout(() => {
              setErrorStatus({ restError: false, restErrorMessage: "" });
            }, 9000);
        })
      }
   
      return () => mounted = false;
    }
  }, [quickBook.shiZipcode,quickBook.terminalFrom])// eslint-disable-line

  useEffect(() => {
    let objVal={}
   // console.log("fffFrom useeffect")
    Object.assign( objVal , { ["isShipperInfoEdit"]: !quickBook.fffFrom,["isConsigneeInfoEdit"]: !quickBook.fffTo });
    updateMutipleValues(objVal)
  }, [quickBook.fffFrom,quickBook.fffTo])
  
 /* useEffect(() => {
    let objVal={}
    console.log(quickBook.lineHaulServiceType,"came to linehaurservicetype,",quickBook.fffFrom," and fffto",quickBook.fffTo)
    if ( quickBook.lineHaulServiceType === 2){
        objVal = Object.assign(objVal, { fffFrom: false, fffTo:false})
      }
      if ( quickBook.lineHaulServiceType === 3){
        objVal = Object.assign(objVal, { fffFrom: true, fffTo:false})
      }
      updateDimTotals(objVal)
  }, [quickBook.lineHaulServiceType])*/

  const getTerminalFromOptions =()=>{
    if(quickBook.lineHaul === "lineHaul")
    return lineHualTermOpts;
    else if(quickBook.lineHaul === "localpu")
    return  fffTermOpts;
    else if(quickBook.lineHaul === "NH")
    return roadRunnerTermOpts;
  }
  const getTerminalToOptions =()=>{
    if(quickBook.lineHaul === "lineHaul")
    return lineHualTermOpts;
    else if(quickBook.lineHaul === "localpu")
    return  fffTermOpts;
    else if(quickBook.lineHaul === "NH")
    return roadRunnerTermOpts;

  }

    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'}/>}
            <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-3 pageHead text-uppercase">Book Now Without Quote</h4>
                           
                        </div>
                        <form className="needs-validation" name="booking" >
                            <DirectBook1 quickBook={quickBook} onChangeBookHandler={onChangeBookHandler} terminalToOptions={getTerminalToOptions()}  terminalFromOptions={getTerminalFromOptions()} 
                                isShipperInfoEdit={isShipperInfoEdit}  lhServiceTypes={lhServiceTypes}
                                onValidateBookHandler={onValidateBookHandler} quickBookRequired={quickBookRequired} lineHaulServiceTypeList={lineHaulServiceTypeList} />
                            <DirectBook2 quickBook={quickBook} stateList={stateList} onChangeBookHandler={onChangeBookHandler}
                                isShipperInfoEdit={isShipperInfoEdit} 
                                onValidateBookHandler={onValidateBookHandler} quickBookRequired={quickBookRequired} errorStatus={errorStatus}
                                validateAddress = {validateAddress} updateAddress={updateQuickBookObject} isRoadRunner={isRoadRunner}/>
                            {/* {isRoadRunner &&
                            <RRLayer quickBook={quickBook} onChangeBookHandler={onChangeBookHandler}
                            isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit}
                            onValidateBookHandler={onValidateBookHandler} quickBookRequired={quickBookRequired}
                            labelStyleList={labelStyleList} labelCountList={labelCountList} />
                            } */}
                            <DirectBook3 quickBook={quickBook} onChangeBookHandler={onChangeBookHandler}
                                onValidateBookHandler={onValidateBookHandler} quickBookRequired={quickBookRequired} />
                            <DirectBook4 quickBook={quickBook} onChangeBookHandler={onChangeBookHandler} onValidateBookHandler={onValidateBookHandler} onChangeDimTotal={onChangeDimTotal} onValidateDimTotal={onValidateDimTotal} 
                quoteTotalDim={quoteTotalDim}  backStatus={backStatus} updateDimTotals={updateDimTotals} totalDimRequired={totalDimRequired} optionsData={accessorialOpts}
                 clearAllDims={clearAllDims} showAddDimMsg={showAddDimMsg} isRoadRunner={quickBook.roadRunner} packageTypeList={packageTypeList}
                lineHaulSelVal={quickBook.lineHaul} quickBookRequired={quickBookRequired} confirmBookNow={confirmBookNow} readFileData={readAllfiles}
                                />
                            
                        </form>

                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(quickBook)}</div> */}
                        <hr/>
                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(quickBookRequired)}</div>  */}
                    </div>

                </div>
            </div>
        </div>
    );


}

export default FreightBooknow;