import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import InputElement from "../../formControls/InputElement";
import TextareaElement from "../../formControls/TextareaElement";
import Dimensions from "./Dimensions";
import { useNavigate } from "react-router-dom"

const QuoteDimTotal = (props) => {
    //console.log("QuoteDimTotal");
    const [pickupSelectChkbox, setPickupSelectchkbox] = useState({ optionSelected: null });
    const [deliverySelectChkbox, setDeliverySelectchkbox] = useState({ optionSelected: null });
    const navigate = useNavigate();
    const handleSelectChangePickup = (selected) => {
        
        let noneIndexFound = selected.findIndex(x => x.value ==="****NONE****");

        if(selected.length>1 && noneIndexFound>-1)
            selected.splice(noneIndexFound,1);

            setPickupSelectchkbox({
            optionSelected: selected
        });
        props.onChangeDimTotal('pickupAccessorials', selected);
    };
    const handleSelectChangeDelivery = (selected) => {
        
        let noneIndexFound = selected.findIndex(x => x.value ==="****NONE****");

        if(selected.length>1 && noneIndexFound>-1)
            selected.splice(noneIndexFound,1);

            setDeliverySelectchkbox({
            optionSelected: selected
        });
        props.onChangeDimTotal('deliveryAccessorials', selected);
    };

    
    useEffect(() => {
        let mounted = true;
      
        if (mounted) {
            setDeliverySelectchkbox({
                optionSelected:  null
            });
            setPickupSelectchkbox({
                optionSelected:  null
            });
        
          return () => mounted = false;
        }
      }, [props.quoteShipping.shipFrom,props.quoteShipping.terminalFrom])// eslint-disable-line
    // useEffect(() => {
    //     if (props.quoteTotalDim.accessorial && (props.quoteTotalDim.accessorial).length > 0) {
    //         setSelectchkbox({
    //             optionSelected: props.quoteTotalDim.accessorial
    //         });

    //     }
    // }, [props.quoteTotalDim.accessorial])
    useEffect(() => {
        if (props.quoteTotalDim.pickupAccessorials && (props.quoteTotalDim.pickupAccessorials).length > 0) {
            setPickupSelectchkbox({
                optionSelected: props.quoteTotalDim.pickupAccessorials
            });

        }
    }, [props.quoteTotalDim.pickupAccessorials])
    useEffect(() => {
        if (props.quoteTotalDim.deliveryAccessorials && (props.quoteTotalDim.deliveryAccessorials).length > 0) {
            setDeliverySelectchkbox({
                optionSelected: props.quoteTotalDim.deliveryAccessorials
            });

        }
    }, [props.quoteTotalDim.deliveryAccessorials])

    const [modelState, setModelState] = useState({ show: false });

    const handleModalState = () => {
        setModelState({ show: !modelState.show })
    }

    useEffect(() => {
        toggleDimensions();
    }, [props.quoteTotalDim.totalPcs,props.quoteTotalDim.grossWeight,props.quoteTotalDim.dimWeightTotal])

    useEffect(() => {
        if( props.clearAllDims === "true" ){
            setDeliverySelectchkbox({
                optionSelected:  null
            });
            setPickupSelectchkbox({
                optionSelected:  null
            });
        }
    }, [props.clearAllDims]);

    const toggleDimensions = () =>{
        if( props.quoteTotalDim.totalPcs !== '' && props.quoteTotalDim.grossWeight !== '' && props.quoteTotalDim.dimWeightTotal !== '')
        return <div className="col-lg-6 col-md-12">
        <div className="row mb-1">
            <div className="col-md-4">
                <label>Total Pcs</label>
                <InputElement id="totalPcs" name="totalPcs"
                    className={props.totalDimRequired.totalPcs_ErrorClass}
                    value={props.quoteTotalDim.totalPcs || ''}
                    onChange={props.onChangeDimTotal}
                    onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true}
                    placeholder='Total Pcs' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
            <div className="col-md-4">
                <label>Gross Weight</label>
                <InputElement id="grossWeight" name="grossWeight" className={props.totalDimRequired.grossWeight_ErrorClass} value={props.quoteTotalDim.grossWeight || ''} onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true} 
                placeholder='Gross Weight' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
            <div className="col-md-4">
                <label>Dim Weight Total</label>
                <InputElement id="dimWeightTotal" name="dimWeightTotal" className={props.totalDimRequired.dimWeightTotal_ErrorClass} value={props.quoteTotalDim.dimWeightTotal || ''} onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true} 
                 placeholder='Dim Weigh Total' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 text-right">
                <Link to="" onClick={() => handleModalState()} className="dimLink text-uppercase" data-toggle="modal" data-target="#detDimensions">Click here to edit detailed
                    Pcs, Lbs, Dims
                </Link>
            </div>
        </div>
    </div>
    else
    return <div className="col-md-6 my-3">
            <div className="row vertical-center">
            <button className="btn btn-primary btn-block pull-right transformNone" type="button" data-toggle="modal" data-target="#detDimensions" onClick={() => handleModalState()}>
                Add Pcs, Lbs, Dims
            </button>
            {props.showAddDimMsg && 
            <div className="text-danger" id="addDimensionsMsgDiv">
                Add Pcs, Lbs, Dims
            </div>
            }
        </div>
        </div>
        
    }

    return (
        <>
            <div >
                {/* <div className="row mt-3 mb-3"></div> */}
                <div className="row">
                    {toggleDimensions()}
                    <div className="col-md-3 mb-3 mb-lg-0 mt-3">
                            
                                <label className="boldBlack"> Pickup Accessorial Application </label>
                                <label className="QuoteHead">(Must select One or None)</label>
                                <ReactSelect
                                    id="pickupAccessorials"
                                    options={props.optionsData}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSelectChangePickup}
                                    allowSelectAll={true}
                                    value={pickupSelectChkbox.optionSelected}
                                // className={props.totalDimRequired.accessorial_ErrorClass} 
                                />

                                <small className={props.totalDimRequired.pickupAccessorials_ErrorClass}>
                                    required.
                                </small>
                                {/* {JSON.stringify(props.optionsData)} */}
                            
                    </div>
                    <div className="col-md-3 mt-3 mb-lg-0 mt-3">

                        <label className="boldBlack"> Delivery Accessorial Application </label>
                                <label className="QuoteHead">(Must select One or None)</label>
                                <ReactSelect
                                    id="deliveryAccessorials"
                                    options={props.optionsData}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSelectChangeDelivery}
                                    allowSelectAll={true}
                                    value={deliverySelectChkbox.optionSelected}
                                // className={props.totalDimRequired.accessorial_ErrorClass} 
                                />

                                <small className={props.totalDimRequired.deliveryAccessorials_ErrorClass}>
                                    required.
                                </small>

                            {/* <label></label>
                            <div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="pickup" name="accSerType"  className="custom-control-input"
                                    onChange={props.onChangeDimTotal} value="pickup" 
                                    checked={props.quoteTotalDim.accSerType === "pickup"}
                                    //onInvalid={props.onValidatePreFill}
                                    // validation="requiredRadio" className={props.totalDimRequired.accSerType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="pickup">Pickup</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="delivery" name="accSerType"  className="custom-control-input"
                                    onChange={props.onChangeDimTotal} value="delivery" 
                                    checked={props.quoteTotalDim.accSerType === "delivery"}
                                    // onInvalid={props.onValidatePreFill}
                                    // validation="requiredRadio" className={props.totalDimRequired.accSerType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="delivery">Delivery</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="both" name="accSerType"  className="custom-control-input"
                                    onChange={props.onChangeDimTotal} value="both" 
                                    checked={props.quoteTotalDim.accSerType === "both"}
                                    // onInvalid={props.onValidatePreFill}
                                    // validation="requiredRadio" className={props.totalDimRequired.accSerType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="both">Both</label>
                            </div>
                            </div> */}
                            {/* {props.isRoadRunner && <>
                            <hr className="mt15b0"></hr>
                            <div >
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="hazadousYes" name="hazardouesMaterial"  className="custom-control-input"
                                    onChange={props.onChangeDimTotal} value="true"
                                    checked={props.quoteTotalDim.hazardouesMaterial === "true"}
                                    //onInvalid={props.onValidatePreFill}
                                    // validation="requiredRadio" className={props.totalDimRequired.accSerType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="hazadousYes">Hazardous Yes</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="hazadousNo" name="hazardouesMaterial"  className="custom-control-input"
                                    onChange={props.onChangeDimTotal} value="false"
                                    checked={props.quoteTotalDim.hazardouesMaterial === "false"}
                                    // onInvalid={props.onValidatePreFill}
                                    // validation="requiredRadio" className={props.totalDimRequired.accSerType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="hazadousNo">Hazardous No</label>
                            </div>
                            </div>
                            </>} */}
                        </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label>Shipper Notes</label>
                        <TextareaElement id="shipperNotes" name="shipperNotes" rows="2" value={props.quoteTotalDim.shipperNotes || ''} className={props.totalDimRequired.notes_ErrorClass}
                            onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="required" placeholder='Shipper Notes' />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Consignee Notes</label>
                        <TextareaElement id="consigneeNotes" name="consigneeNotes" rows="2" value={props.quoteTotalDim.consigneeNotes || ''} className={props.totalDimRequired.notes_ErrorClass}
                            onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="required" placeholder='Consignee Notes' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                    <div className="row">
                            <div className="col-md-6 offset-md-6">
                            <button className="btn btn-outline-primary " type="button" onClick={props.clearQuoteForm}>
                                        {/* <i className="fas fa-times-circle"></i> */}
                                         CLEAR </button>
                                <button className="btn btn-primary btnTextSize float-right" type="button" onClick={props.submitValidation}>Get Quick
                                    Quote &nbsp;&nbsp;
                                    {/* <i className="fas fa-list-ul"></i> */}
                                    </button>
                            </div>
                            </div>
                    </div>
                </div>
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quoteTotalDim)}</div>
            <div style={{ marginTop: 20 }}>{JSON.stringify(props.totalDimRequired)}</div> */}
            </div>
            <Dimensions onChange={props.updateDimTotals} isDimensionsInfoEdit={true} isBooking={props.backStatus} showModel={modelState.show} handleModalState={() => handleModalState()}
                clearAllDims={props.clearAllDims} packageTypeList={props.packageTypeList}
                isRoadRunner={props.isRoadRunner} lineHaulSelVal={props.lineHaulSelVal} />
        </>
    );
}
export default QuoteDimTotal;