import React from "react";
import { GridLoader } from "react-spinners";
import DataFilters from "./Data/DataFilters";
import TrackingReport from "./TrackingReport";
import TrackingReportData from "./TrackingReportData";

const FreightTracking = () => {
    const {handleInputChange, onSubmitSearch, onValidateFilters, filtersInput, filtersReq, reportData, showDataLoading} = DataFilters();

    return (
        <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-2 pageHead text-uppercase">Freight Tracking</h4>
                            {/* <button className="btn btn-secondary pull-right ml-auto" type="submit">Refresh Last 24 Hrs.</button> */}
                        </div>
                        
                        <TrackingReport handleInputChange={handleInputChange} onSubmitSearch={onSubmitSearch} filtersInput={filtersInput} 
                            filtersReq={filtersReq} onValidateFilters={onValidateFilters}  />
                        <hr/>
                        {
                        showDataLoading && 
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center"> 
                                    <GridLoader  color={'#1E73BE'} loading={showDataLoading} />
                                </div>
                            </div>
                        }
                        {
                            filtersInput.showTable &&  
                            <TrackingReportData reportData={reportData} filtersInput={filtersInput} showDataLoading={showDataLoading} />
                        }
                        
                        
                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(rpData)}</div> */}
                    </div>
                </div>
            </div>

    );
}

export default FreightTracking;