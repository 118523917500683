import axios from "axios";
import moment from "moment";


export const trackingApiKey = (apiKey) => {
    //?custcode=1026&sType=hawb&uid=-1
   var param = "?apiKey="+apiKey
    return axios.get(process.env.REACT_APP_TRACKING_TOKEN_URL+param)
        .then(response => {
           // console.log("REACT_APP_TRACKING_TOKEN_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_TOKEN_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking Token ");
        })
}

export const trackingHomeApiKey = (trackType,trackId) => {
    //?custcode=1026&sType=hawb&uid=-1
   var param = "/"+trackType+"/"+trackId;
    return axios.get(process.env.REACT_APP_TRACKING_HOME_URL+param)
        .then(response => {
           // console.log("REACT_APP_TRACKING_TOKEN_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_TOKEN_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking Token ");
        })
}

function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

export const trackingByDate = (custcode,sType,sDate,eDate,hawb,mult,uid) => {
    //need to work -- based on conditon send the parameter
    //?custcode=(integer)
    //sType =(Stirng) hawb, date, mult = multiple waybills &
    //sDate =(String) 
    //eDate =(String)
    //hawb =(String) HouseAirWay bill
    //mult = (String) List of HAWB, comma delimited
    //uid = Integer default value = -1
 //   console.log("hawb"+hawb+"mult"+mult+"uid"+uid+"sDate"+sDate+"eDate"+eDate)
    // console.log(convert(sDate));
    hawb = hawb.trim();
    mult = mult.trim();
    var param = "?custcode="+custcode+"&uid="+uid+"&stype="+sType;
    if((sType==="hawb" || sType==="nationwide")  && hawb)
        param = param+"&hawb="+hawb.trim();
    if(sType==="mult" && mult)
        param = param+"&mult="+mult;
    if(sType==="date" && sDate && eDate){
        let _sDate = sDate ? moment(new Date(sDate)).format('MM/DD/YYYY') : '';
        let _eDate = eDate ? moment(new Date(eDate)).format('MM/DD/YYYY') : '';
        param = param+"&sDate="+_sDate+"&eDate="+_eDate;
    }
    
    // console.log("param"+param);
   return axios.get(process.env.REACT_APP_TRACKING_BYDATE_URL+param)
        .then(response => {
            //console.log("REACT_APP_TRACKING_BYDATE_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_BYDATE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Tracking By Date ");
        })
}

export const trackShipmentList = (custcode,userId) => {
  // for testing hard coded
 
   var postData = {
    "custCode" : parseInt(custcode),  //1026
    "userId" : userId  //0
  }
//   console.log("trackShipmentList postData:"+JSON.stringify(postData))
    return axios.post(process.env.REACT_APP_TRACKING_SHIPMENTLIST_URL, postData)
        .then(response => {
            //console.log("REACT_APP_TRACKING_SHIPMENTLIST_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_SHIPMENTLIST_URL "+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call of Tracking shipment list on Load of Tracking ");
        })
}

export const trackingStatusHistory = (shipId,type) => {
    //?shipID=int 
    // type = (String) Corresponds to shipment MoveType: PU, DEL, PUDEL
   var param = "?shipID="+shipId+"&type="+type
    return axios.get(process.env.REACT_APP_TRACKING_STATUS_HISTORY_URL+param)
        .then(response => {
            //console.log("REACT_APP_TRACKING_STATUS_HISTORY_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_STATUS_HISTORY_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking Status History ");
        })
}

export const trackingCharges = (shipId) => {
    //?shipID=int 
   var param = "?shipID="+shipId;
    return axios.get(process.env.REACT_APP_TRACKING_CHARGES_URL+param)
        .then(response => {
            //console.log("REACT_APP_TRACKING_CHARGES_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_CHARGES_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking Charges ");
        })
}

export const trackingDocuments = (shipId,imageType) => {
    //?shipid=int 
    //imageType =int Default value 0
   var param = "?shipid="+shipId+"&imageType=0";
    return axios.get(process.env.REACT_APP_TRACKING_DOCUMENTS_URL+param)
        .then(response => {
            //console.log("REACT_APP_TRACKING_DOCUMENTS_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_DOCUMENTS_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking Documents ");
        })
}

export const trackingHasInvoice = (shipId) => {
    //?shipid=int 
   var param = "?shipid="+shipId;
    return axios.get(process.env.REACT_APP_TRACKING_HASINVOICE_URL+param)
        .then(response => {
            //console.log("REACT_APP_TRACKING_HASINVOICE_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TRACKING_HASINVOICE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in Tracking has Invoice ");
        })
}