import axios from "axios";


export const processBookQuoteReq = (quickQuoteRes, quickQuote, isShipperInfoEdit, isConsigneeInfoEdit, quoteHis , attachments,isQuoteHis) => {

    const postData = buildBookQuoteReq(quickQuoteRes, quickQuote, isShipperInfoEdit, isConsigneeInfoEdit, quoteHis, attachments, isQuoteHis);

    var resp = [];

    //console.log("reqeust for booking-- "+JSON.stringify(postData))
    return axios.post(process.env.REACT_APP_BOOK_URL, postData)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
            resp = { status: "success", "responseData": resData }
            return resp;
        })
        .catch(error => {
            //  console.log("Error in calling api/Quote"+JSON.stringify(error));
            // shall be replaced after valid RESTAPI response. temporarily adding req as resp
            resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}

export const buildBookQuoteReq = (quickQuoteRes, quickQuote, isShipperInfoEdit, isConsigneeInfoEdit, quoteHis, attachments,isQuoteHis) => {
    const qqres = quickQuoteRes;
    const qq = quickQuote;
    var shipperInfo = null;
    var consigneeInfo = null;
    //console.log("quickQuoteRes="+(JSON.stringify(quickQuoteRes)));
    //console.log("quickQuote="+(JSON.stringify(quickQuote)));
    // console.log("scfe="+(isShipperInfoEdit));
    // console.log("scte="+isConsigneeInfoEdit);
    if (isShipperInfoEdit === true) {

        shipperInfo = {
            "companyName": qq.shiCompanyName,
            "address1": qq.shiAddress,
            "address2": "",
            "city": qq.shiCity,
            "state": qq.shiState,
            "postalCode": qq.shiZipcode,
            "country": "USA",
            "phone": qq.shiPhone,
            "email": "",
            "contact": qq.shiContact,
            "terminal":'',
        }
    } else {

        shipperInfo = {
            "companyName": qqres.shipper.companyName,
            "address1": qqres.shipper.address1,
            "address2": qqres.shipper.address2,
            "city": qqres.shipper.city,
            "state": qqres.shipper.state,
            "postalCode": qqres.shipper.postalCode,
            "country": "USA",
            "phone": qq.shiPhone, //qqres.shipper.phone,
            "email": qqres.shipper.email,
            "contact": qq.shiContact, //qqres.shipper.contact,
            "terminal": qqres.shipper.terminal,
        }
    }
    if (isConsigneeInfoEdit === true) {

        consigneeInfo = {
            "companyName": qq.consiCompanyName,
            "address1": qq.consiAddress,
            "address2": "",
            "city": qq.consiCity,
            "state": qq.consiState,
            "postalCode": qq.consiZipcode,
            "country": "USA",
            "phone": qq.consiPhone,
            "email": "",
            "contact": qq.consiContact,
            "terminal":'',
        }
    } else {

        consigneeInfo = {
            "companyName": qqres.consignee.companyName,
            "address1": qqres.consignee.address1,
            "address2": qqres.consignee.address2,
            "city": qqres.consignee.city,
            "state": qqres.consignee.state,
            "postalCode": qqres.consignee.postalCode,
            "country": "USA",
            "phone": qq.consiPhone, //qqres.consignee.phone,
            "email": qqres.consignee.email,
            "contact": qq.consiContact, //qqres.consignee.contact,
            "terminal":qqres.consignee.terminal,
        }
    }
    
    const pickUpReady=new Date(qq.piReadyTime);
    const pickUpClose=new Date(qq.piCloseTime);
    const deliReady=new Date(qq.deliReadyTime);
    const deliClose=new Date(qq.deliCloseTime);
    const pickopenTime = new Date(new Date(qq.pickupInfo).setHours(pickUpReady.getHours(),pickUpReady.getMinutes(),0,0)).toISOString();
    const pickcloseTime = new Date(new Date(qq.pickupInfo).setHours(pickUpClose.getHours(),pickUpClose.getMinutes(),0,0)).toISOString();
    const deliopenTime = new Date(new Date(qq.deliInfo).setHours(deliReady.getHours(),deliReady.getMinutes(),0,0)).toISOString();
    const delicloseTime = new Date(new Date(qq.deliInfo).setHours(deliClose.getHours(),deliClose.getMinutes(),0,0)).toISOString();
    var customer = {
            "custCode": qqres.customer.custCode,
            "companyName": qqres.customer.companyName,
            "address1": qqres.customer.address1,
            "address2": qqres.customer.address2,
            "city": qqres.customer.city,
            "state": qqres.customer.state,
            "postalCode": qqres.customer.postalCode,
            "country": qqres.customer.country,
            "phone": qqres.customer.phone,
            "email": qqres.customer.email,
            "contact": qqres.customer.contact,
            "userName": qqres.customer.userName,
            "userid": qqres.customer.userid+"",
            "additionalEmails": qqres.customer.additionalEmails
    }

    let lineHaul = false;
    let isRoadRunner = false;
    if(quoteHis ){
        // if(qqres.lineHaul ==='lineHaul')
        //     lineHaul = true;
        // else if(qqres.lineHaul ==='localpu')
        //     lineHaul = false;
        // else if(qqres.lineHaul ==='NH')
        isRoadRunner = qqres.isRoadRunner;
        lineHaul = qqres.isLinehaul;
    }else{
        if(qqres.addValue.lineHaul ==='lineHaul')
            lineHaul = true;
        else if(qqres.addValue.lineHaul ==='localpu')
            lineHaul = false;
        else if(qqres.addValue.lineHaul ==='NH')
        isRoadRunner = true;
    }
    if(!isRoadRunner) 
        isRoadRunner=quickQuoteRes.isRoadRunner;
  ///  console.log("hisotry = "+isQuoteHis +" qqres.quote.quoteId "+ qqres?.quote?.quoteId +" qqres?.rateQuote.quoteId "+ qqres?.rateQuote?.quoteId)
    var req = {
        "customer" : customer,
        "custCode": qqres.customer.custCode,
        "uid": "",
        // "userid " : qqres.customer.userid+"",
        "isLinehaul": lineHaul,
        "isRoadRunner": isRoadRunner,
        "quoteId": !isQuoteHis ? qqres.quote.quoteId : qqres.rateQuote.quoteId,
        "shipper": shipperInfo,
        "consignee": consigneeInfo,
        "shipmentInfo": {
            "serviceType": quoteHis ? qqres.shipment.serviceType : qqres.addValue.serviceType,
            "moveType": quoteHis ? qqres.shipment.moveType : qqres.addValue.typeOfMove,
            "linehaulServiceType": qqres.shipment.linehaulServiceType,
            "weight": quoteHis ? qqres.shipment.weight : qqres.shipment.weight,
            "pickupDate": qq.pickupInfo,
            "pickupOpenTime": pickopenTime,
            "pickupCloseTime": pickcloseTime,
            "deliveryDate": qq.deliInfo,
            "deliveryOpenTime": deliopenTime,
            "deliveryCloseTime": delicloseTime,
            //"notes": qq.notes,
            "shipperNotes": qq.shipperNotes,
            "consigneeNotes": qq.consigneeNotes,
            "isSpecialPickup": qq.specialPickup,
            "isSpecialDelivery": qq.specialDelivery,
            "dimList": qqres.shipment.dimList,
            "accessorials": qqres.shipment.accessorials,
            "pickupAccessorials" : qqres.shipment.pickupAccessorials,
            "deliveryAccessorials" : qqres.shipment.deliveryAccessorials,
            "qty": qqres.shipment.qty,
            "hawb": qq.hawb,
            "mawb": qq.mawb,
            "custReference": qq.refNo,
           // "accSerType" : qqres.accSerType ? qqres.accSerType : qqres.addValue.accSerType
            "accSerType" : quoteHis ? qqres.shipment.accSerType : qqres.addValue.accSerType
        },
        "finalMileServiceType": quoteHis ? qqres.shipment.serviceType : qqres.addValue.serviceType,
        "moveType": quoteHis ? qqres.shipment.moveType : qqres.addValue.typeOfMove,
        "linehaulServiceType": quoteHis ? qqres.shipment.lineHaulServiceType : qqres.addValue.lineHaulServiceType,
        "bookingQuote": {
            "quoteId": !isQuoteHis ? qqres?.quote?.quoteId : qqres?.rateQuote?.quoteId,
            "originZip": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.originZip ,
            "destinationZip": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.destinationZip ,
            "msg": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.msg ,
            "weight": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.weight ,
            "qty": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.qty ,
            "cbm": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.fbm ,
            "fsc": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.fsc ,
            "fsc_rate": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.fsc_rate ,
            "freight_charge": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.freight_charge,
            "pCity": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.pCity ? quickQuoteRes.quote.rateQuote.pCity : '',
            "dCity": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.dCity ? quickQuoteRes.quote.rateQuote.dCity : '',
            "pickup_FreightCharge": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.pickup_FreightCharge ? quickQuoteRes.quote.rateQuote.pickup_FreightCharge : 0,
            "pickup_FSC": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.pickup_FSC ? quickQuoteRes.quote.rateQuote.pickup_FSC : 0,
            "pickup_FscRate": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.pickup_FscRate ? quickQuoteRes.quote.rateQuote.pickup_FscRate : 0,
            "total_Pickup": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.total_Pickup ? quickQuoteRes.quote.rateQuote.total_Pickup : 0,
            "delivery_FreightCharge": quickQuoteRes.quote && quickQuoteRes.quote.delivery_FreightCharge ? quickQuoteRes.quote.delivery_FreightCharge : 0,
            "delivery_FSC": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.delivery_FSC ? quickQuoteRes.quote.rateQuote.delivery_FSC : 0 ,
            "delivery_FscRate": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.delivery_FscRate ? quickQuoteRes.quote.rateQuote.delivery_FscRate : 0,
            "total_Delivery": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.freight_charge ? quickQuoteRes.quote.rateQuote.freight_charge : 0,
            "lineHaulPrice": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.freight_charge ? quickQuoteRes.quote.rateQuote.lineHaulPrice : 0,
            "discountPrice": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.freight_charge ? quickQuoteRes.quote.rateQuote.discountPrice : 0,
            "accessorialPrice": quickQuoteRes.quote && quickQuoteRes.quote.rateQuote.freight_charge ? quickQuoteRes.quote.rateQuote.accessorialPrice : 0,
          },
          "attachments" : attachments,
    }

    // console.log("quoteHis",quoteHis);
    // console.log("qqres",qqres);
    // console.log("quickQuoteRes",quickQuoteRes);
    // console.log("req",req)
    return req;
}



export const processBookNowReq = (userProfileData,quickQuoteRes,dimensionsList, quoteTotalDim, quickBook,  attachments,accessorialDetails) => {

    const postData = buildBookNowReq(userProfileData,quickQuoteRes,dimensionsList, quoteTotalDim, quickBook,  attachments,accessorialDetails);

    var resp = [];

    console.log("request for booknow -- "+JSON.stringify(postData))
    return axios.post(process.env.REACT_APP_BOOKNOW_URL, postData)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
            resp = { status: "success", "responseData": resData }
            return resp;
        })
        .catch(error => {
              console.log("Error in Direct booking ",error);
            // shall be replaced after valid RESTAPI response. temporarily adding req as resp
            resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}


export const buildBookNowReq = (userProfileData,quickQuoteRes,dimensionsList, quoteTotalDim, quickBook,  attachments,accessorialDetails) => {
    const qqres = quickQuoteRes;
    const qb = quickBook;
    let accessorials = [];
    let pickupAccessorials = [];
    let deliveryAccessorials = [];

    if (quickBook.accessorial) {
        accessorials = quickBook.accessorial.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    if (quickBook.pickupAccessorials) {
        pickupAccessorials = quickBook.pickupAccessorials.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    if (quickBook.deliveryAccessorials) {
        deliveryAccessorials = quickBook.deliveryAccessorials.map(eachobj =>
            accessorialDetails.find(findObj => findObj.description === eachobj.value)
        )
    }
    var shipperInfo = null;
    var consigneeInfo = null;
        shipperInfo = {
            "companyName": qb.shiCompanyName,
            "address1": qb.shiAddress,
            "address2": "",
            "city": qb.shiCity,
            "state": qb.shiState,
            "postalCode": qb.shiZipcode,
            "country": "USA",
            "phone": qb.shiPhone,
            "email": qb.email,
            "contact": qb.shiContact,
            "terminal":qb.shiTerminal,
        }
        consigneeInfo = {
            "companyName": qb.consiCompanyName,
            "address1": qb.consiAddress,
            "address2": "",
            "city": qb.consiCity,
            "state": qb.consiState,
            "postalCode": qb.consiZipcode,
            "country": "USA",
            "phone": qb.consiPhone,
            "email": qb.email,
            "contact": qb.consiContact,
            "terminal":qb.consiTerminal,
        }
    const pickUpReady=new Date(qb.piReadyTime);
    const pickUpClose=new Date(qb.piCloseTime);
    const deliReady=new Date(qb.deliReadyTime);
    const deliClose=new Date(qb.deliCloseTime);
    const pickopenTime = new Date(new Date(qb.pickupInfo).setHours(pickUpReady.getHours(),pickUpReady.getMinutes(),0,0)).toISOString();
    const pickcloseTime = new Date(new Date(qb.pickupInfo).setHours(pickUpClose.getHours(),pickUpClose.getMinutes(),0,0)).toISOString();
    const deliopenTime = new Date(new Date(qb.deliInfo).setHours(deliReady.getHours(),deliReady.getMinutes(),0,0)).toISOString();
    const delicloseTime = new Date(new Date(qb.deliInfo).setHours(deliClose.getHours(),deliClose.getMinutes(),0,0)).toISOString();
    var customer = {
            "custCode": userProfileData.custCode,
            "companyName": userProfileData.companyName,
            "address1": userProfileData.address1,
            "address2": userProfileData.address2,
            "city": userProfileData.city,
            "state": userProfileData.state,
            "postalCode": userProfileData.postalCode,
            "country": userProfileData.country,
            "phone": userProfileData.phone,
            "email": userProfileData.email,
            "contact": userProfileData.contact,
            "userName": userProfileData.userName,
            "userid": userProfileData.userid+"",
            "additionalEmails": qb.additionalEmails
    }

    let dimList= [];
    dimensionsList.map((d, j) => {
        let eachdim = { 
        "qty": d.qty,
        "weight": d.weight,
        "length": d.length,
        "width": d.width,
        "height": d.height,
        "description": d.description,
        "packageType": d.description,
        }
        dimList.push(eachdim);
    })
    let lineHaul = false;
    let isRoadRunner = false;
    let shipmentType ="";
        if(qb.lineHaul ==='lineHaul'){
            lineHaul = true;
            shipmentType='I';
        }else if(qb.lineHaul ==='localpu'){
            lineHaul = false;
            shipmentType='L';
        }else if(qb.lineHaul ==='NH'){
                isRoadRunner = true;
                shipmentType='N';
        }
    var req = {
        "customer" : customer,
        "shipper": shipperInfo,
        "consignee": consigneeInfo,
        "attachments" : attachments,
        "shipmentType": shipmentType,
        "shipment": {
            "dimWeight": qb.dimWeightTotal,
            "weight": qb.grossWeight, //quoteHis ? qqres.shipment.weight : qqres.shipment.weight,
            "serviceType": qb.lineHaulServiceType, //quoteHis ? qqres.shipment.serviceType : qqres.addValue.serviceType,
            "pickupDate": qb.pickupInfo,
            "pickupOpenTime": pickopenTime,
            "pickupCloseTime": pickcloseTime,
            "deliveryDate": qb.deliInfo,
            "deliveryOpenTime": deliopenTime,
            "deliveryCloseTime": delicloseTime,
            //"notes": qq.notes,
            "shipperNotes": qb.shipperNotes,
            "consigneeNotes": qb.consigneeNotes,
            "isSpecialPickup": qb.specialPickup,
            "isSpecialDelivery": qb.specialDelivery,
            "dimList": dimList,
            "accessorials": accessorials,
            "pickupAccessorials" : pickupAccessorials,
            "deliveryAccessorials" : deliveryAccessorials,
            "qty": qb.totalPcs,
            "hawb": qb.hawb,
            "mawb": qb.mawb,
            "custReference": qb.refNo,
          }
    }
    
    //  console.log("userProfileData",userProfileData); 
    //  console.log("customer",customer);
    //  console.log("shipperInfo",shipperInfo);
    //  console.log("consigneeInfo",consigneeInfo);
    //  console.log("quickBook",quickBook);
    //  console.log("dimList",dimList);
    //  console.log("req",req)
    return req;
}
// export const testRespData = (reqData) => {
//     var resp = { ...reqData, ["bookingNumber"]: "FFQB1234" };
//     return resp;
// }

export const getAddress = (userId) =>{
    let data ="?userid="+userId;
    return axios.get(process.env.REACT_APP_GET_ADDR_URL+data)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
           // resp = { status: "success", "responseData": resData }
            return resData;
        })
        .catch(error => {
           // resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}

export const saveAddress = (data) =>{
    return axios.post(process.env.REACT_APP_SAVE_ADDR_URL,data)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
           // resp = { status: "success", "responseData": resData }
            return resData;
        })
        .catch(error => {
           // resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}

export const updateAddress = (data) =>{
    return axios.put(process.env.REACT_APP_UPDATE_ADDR_URL,data)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
           // resp = { status: "success", "responseData": resData }
            return resData;
        })
        .catch(error => {
            //resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}

export const deleteAddress = (addressId) =>{
    var delAddr = {data : { "addressId":addressId} }
    return axios.delete(process.env.REACT_APP_DELETE_ADDR_URL,delAddr)
        .then(response => {
            const resData = response.data;
            // console.log("resData in s="+resData);
           // resp = { status: "success", "responseData": resData }
            return resData;
        })
        .catch(error => {
            //resp = { status: "fail", "responseData": error }
            return error;
        })
        .finally(() => {
            // console.log("Finally call in api/Quote ");
        })
}