import React from 'react'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const GeneratePdf = () => {

    const createPDF = async () => {   
        const pdf = new jsPDF("portrait", "pt", "a4"); 
        const data = await html2canvas(document.querySelector("#pdf"));
        const img = data.toDataURL("image/png");  
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("test.pdf");
    };
    
  return (
    <section className='mt-180 mb-4'>
        <div className='container'>
            <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom"  id='pdf'>
                        <div className="d-flex">
                            <h4 className="mb-3 pageHead text-uppercase">Test Generate PDF</h4>
                            <hr/>
                        </div>
                        <div>
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Abcd</td>
                                        <td>abcd@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Efgh</td>
                                        <td>efgh@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Ijkl</td>
                                        <td>ijkl@gmail.com</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <button type='button' className='btn btn-warning' onClick={createPDF} >Test PDF</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default GeneratePdf