import React  from "react";
import flightjpg from '../../../assets/img/flight_1.jpg'
import verootpng from '../../../assets/img/logo_veroot.png'

const Veroot = () => {
    return (
        <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
			<div className="col-md-3 order-md-1">
                    <div className="card card-custom">
					<h5 className="pageHead">Do You <img src={verootpng} className="vrootSide"/> ?</h5>
                        <p>Veroot's Real-Time Compliance Dashboard is used by hundreds of IACS to help automate the entire TSA Compliance Process. Want to learn more? Click here to navigate to the Veroot homepage and see why so many IACs and Agents use Veroot! </p>                   
						<h5>Veroot:</h5>
						<ul>
							<li>Centralized web-based</li>
							<li>IACMS Data Pre-formatted</li>
							<li>TSA Letters e-signed</li>
							<li>Training records</li>
							<li>Learning Management</li>
							<li>FMCSA Compliance</li>
							<li>Nationwide TSA Search and Much More!</li>
							<li>Used by 4200+ IACs and AR's</li>
						</ul>
						<p>You can also click on the orange "Support" tab or call Veroot's Help Desk at: 1-440-879-8370</p>
					</div>
                </div>
				<div className="col-md-9 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">Freight Force Carrier TSA Roster Request</h4>
                        </div>
						<form className="needs-validation">
						<div className="row mb-1">
                                <div className="col-md-12 ">
                                    <p className="mb-0">Please input your information below to receive the selected Freight Force Carrier TSA Roster(s).</p>
                                </div>
                            </div>
							<hr className="mb-3"/>
							<div className="row mb-3">
								<div className="col-md-12">
									<h5>Freight Force Carrier TSA Data Request</h5>
								</div>
							</div>
							<div className="row mb-3">
                                <div className="col-md-6 ">
                                    <label htmlFor="acNo">Your Company Name</label>
                                    <input type="text" className="form-control" id="acNo" placeholder="" value=""
                                        required/>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-6">
                                    <label htmlFor="waybillNo">IAC Number</label>
                                    <input type="text" className="form-control" id="waybillNo" placeholder=""
                                        value="" required/>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>

							
                            <div className="row mb-3">
                                <div className="col-md-4 ">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" placeholder="" value=""
                                        required/>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" placeholder=""
                                        value="" required/>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								 <div className="col-md-4 ">
                                    <label htmlFor="phone">Phone Number</label>
                                    <input type="tel" pattern=".{10}" className="form-control" id="validationCustom08" placeholder="" oninput="check(this)" required/>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
							<div className="row mb-2">
								<div className="col-md-12 ">
                                    <p className="mb-0">Select Freight Force Carriers</p>
                                </div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-12">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="all" value="option1"/>
									  <label className="form-check-label" htmlFor="all">ALL FREIGHT FORCE LOCATIONS</label>
									</div> 
								</div>
								
							</div>
							<div className="row mb-1">
								<div className="col-md-6 ">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="abe" value="option1"/>
									  <label className="form-check-label" htmlFor="abe">ABE - Blue Eagle Logistics</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="atl" value="option1"/>
									  <label className="form-check-label" htmlFor="atl">ATL - DCB Logistics</label>
									</div> 
								</div>
							</div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="avp" value="option1"/>
									  <label className="form-check-label" htmlFor="avp">AVP - Blue Eagle Logistics</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="bdl" value="option1"/>
									  <label className="form-check-label" htmlFor="bdl">BDL - Joneser's Express Transportation</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BFL" value="option1"/>
									  <label className="form-check-label" htmlFor="BFL">BFL - Valley Transportation Inc.</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BNA" value="option1"/>
									  <label className="form-check-label" htmlFor="BNA">BNA - Red Arrow Delivery Service</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BOS" value="option1"/>
									  <label className="form-check-label" htmlFor="BOS">BOS - Pioneer Freight Systems Inc</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BUF" value="option1"/>
									  <label className="form-check-label" htmlFor="BUF">BUF - Air Dispatch</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BWI" value="option1"/>
									  <label className="form-check-label" htmlFor="BWI">BWI - Coastal Air Handling & Cons.</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="BWI" value="option1"/>
									  <label className="form-check-label" htmlFor="BWI">BWI - Silver Lion Trade Services</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="CLE" value="option1"/>
									  <label className="form-check-label" htmlFor="CLE">CLE - KSS Cartage, LLC</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="CLT" value="option1"/>
									  <label className="form-check-label" htmlFor="CLT">CLT - Guardian Logistics</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="DEN" value="option1"/>
									  <label className="form-check-label" htmlFor="DEN">DEN - Lone Star Logistics</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="DFW" value="option1"/>
									  <label className="form-check-label" htmlFor="DFW">DFW – E & A Transpros, Inc.</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="DSM" value="option1"/>
									  <label className="form-check-label" htmlFor="DSM">DSM - Bolton Logistics LLC</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="DTW" value="option1"/>
									  <label className="form-check-label" htmlFor="DTW">DTW - Accurate Expediting Inc.</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="EWR" value="option1"/>
									  <label className="form-check-label" htmlFor="EWR">EWR - All Cartage Express</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="ELP" value="option1"/>
									  <label className="form-check-label" htmlFor="ELP">ELP - Ortiz Transportation</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="FAT" value="option1"/>
									  <label className="form-check-label" htmlFor="FAT">FAT - Valley Transportation Inc.</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="FLL" value="option1"/>
									  <label className="form-check-label" htmlFor="FLL">FLL - Florida Freight</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="GEG" value="option1"/>
									  <label className="form-check-label" htmlFor="GEG">GEG - Victory Final Mile LLC</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="HNL" value="option1"/>
									  <label className="form-check-label" htmlFor="HNL">HNL-Pacific Isle Delivery/Sun Isle West</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="IAD" value="option1"/>
									  <label className="form-check-label" htmlFor="IAD">IAD - Silver Lion Trade Services</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="IAH" value="option1"/>
									  <label className="form-check-label" htmlFor="IAH">IAH - B. C. S. Inc</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="IND" value="option1"/>
									  <label className="form-check-label" htmlFor="IND">IND - Direct Delivery Today LLC</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="JAX" value="option1"/>
									  <label className="form-check-label" htmlFor="JAX">JAX - DNL Logistics Inc.</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="JFK" value="option1"/>
									  <label className="form-check-label" htmlFor="JFK">JFK - JFK Cartage & Convention Svcs</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="LAS" value="option1"/>
									  <label className="form-check-label" htmlFor="LAS">LAS - MKS Services LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="JFK" value="option1"/>
									  <label className="form-check-label" htmlFor="JFK">JFK - JFK Cartage & Convention Svcs</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="LAS" value="option1"/>
									  <label className="form-check-label" htmlFor="LAS">LAS - MKS Services LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="LAX" value="option1"/>
									  <label className="form-check-label" htmlFor="LAX">LAX - Richard Roosen Trucking</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="LIT" value="option1"/>
									  <label className="form-check-label" htmlFor="LIT">LIT - On Time Logistics, LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MCI" value="option1"/>
									  <label className="form-check-label" htmlFor="MCI">MCI - Spin Freight Services Inc</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MCO" value="option1"/>
									  <label className="form-check-label" htmlFor="MCO">MCO - Distributors Transport</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MEM" value="option1"/>
									  <label className="form-check-label" htmlFor="MEM">MEM - In House Logistics</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MIA" value="option1"/>
									  <label className="form-check-label" htmlFor="MIA">MIA - Florida Freight</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MKE" value="option1"/>
									  <label className="form-check-label" htmlFor="MKE">MKE - Custom Express</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MSP" value="option1"/>
									  <label className="form-check-label" htmlFor="MSP">MSP - A & H Cartage Inc</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="ORD" value="option1"/>
									  <label className="form-check-label" htmlFor="ORD">ORD - E & A Transpros</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="MSY" value="option1"/>
									  <label className="form-check-label" htmlFor="MSY">MSY - Allstar Freight Services LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="PDX" value="option1"/>
									  <label className="form-check-label" htmlFor="PDX">PDX - River City Rush Delivery</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="PHL" value="option1"/>
									  <label className="form-check-label" htmlFor="PHL">PHL - Moore Foggs</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="PHX" value="option1"/>
									  <label className="form-check-label" htmlFor="PHX">PHX - Goldstar Freight Services, LLC</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="PIT" value="option1"/>
									  <label className="form-check-label" htmlFor="PIT">PIT - Pitt Express Systems</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="RDU" value="option1"/>
									  <label className="form-check-label" htmlFor="RDU">RDU - Guardian Logistics Solutions</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="RIC" value="option1"/>
									  <label className="form-check-label" htmlFor="RIC">RIC - Silver Lion Trade Services LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="RNO" value="option1"/>
									  <label className="form-check-label" htmlFor="RNO">RNO - Sierra Airfreight Express (RNO)</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="ROC" value="option1"/>
									  <label className="form-check-label" htmlFor="ROC">ROC - Air Dispatch</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="RSW" value="option1"/>
									  <label className="form-check-label" htmlFor="RSW">RSW - D&M Transportation & Storage</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SAN" value="option1"/>
									  <label className="form-check-label" htmlFor="SAN">SAN - Commercial Transport</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SAT" value="option1"/>
									  <label className="form-check-label" htmlFor="SAT">SAT - R & R Air Freight & Distribution</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SDF" value="option1"/>
									  <label className="form-check-label" htmlFor="SDF">SDF - Fast Response Transport</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SEA" value="option1"/>
									  <label className="form-check-label" htmlFor="SEA">SEA - Action Courier Services Inc</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SFO" value="option1"/>
									  <label className="form-check-label" htmlFor="SFO">SFO - Ryan's Express</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SLC" value="option1"/>
									  <label className="form-check-label" htmlFor="SLC">SLC - Advanced Delivery Service</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="04" value="option1"/>
									  <label className="form-check-label" htmlFor="04">SMF -Network Delivery Systems</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="STL" value="option1"/>
									  <label className="form-check-label" htmlFor="STL">STL - Transport Express</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="SWF" value="option1"/>
									  <label className="form-check-label" htmlFor="SWF">SWF - Lightning Express</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-3">
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="TPA" value="option1"/>
									  <label className="form-check-label" htmlFor="TPA">TPA - Myers Freight, Inc</label>
									</div> 
								</div>
								<div className="col-md-6">
                                   <div className="form-check form-check-inline">
									  <input className="form-check-input" type="checkbox" id="TUS" value="option1"/>
									  <label className="form-check-label" htmlFor="TUS">TUS - Goldstar Freight Services, LLC</label>
									</div> 
								</div>
                            </div>
							<div className="row mb-1">
								 <div className="col-md-12">
                                    <label htmlFor="msg">Message</label>
									<textarea className="form-control" id="msg" rows="3"></textarea>
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 pt-2 offset-9">
                                    <button className="btn btn-primary btn-block pull-right" type="submit">Send Request &nbsp;</button>
                                </div>
                            </div>
							
							<hr className="mb-3"/>
							
							<div className="row">
								<div className="col-md-12">
									<h5>Terms of Use / Disclaimer:</h5>
									<p className="text-justify">Please note that Veroot is used by individual Freight Force Carriers to store, maintain, and update TSA Roster information. Veroot is not responsible for the accuracy of the data maintained by the Freight Force Carrier. You agree to indemnify Veroot Inc. from any financial penalties, TSA penalties , litigation, or tort of any kind that may result as a transmission of this information. It is fully understood the Veroot system serves merely as a method to expedite the transmission Freight Force Carrier employee roster information that is maintained on Veroot. Freight Force Carrier roster data is only shared with Freight Force Carrier IAC Clients who use Freight Force Carriers as Authorized Representatives, and that have a need to know under the requirements of 49CFR.
</p>
<p className="mb-0"> <strong>Need help? You can click on the orange "Support" tab or call Veroot's Help Desk at: 1-440-879-8370</strong></p>
								</div>
							</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Veroot;