import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {isNumberAndNotZero, isValidAlpha, isValidAlphaNumeric, isValidEmail, isValidInteger, isValidPhoneNo, validateNumberDecimal, validatePassword} from '../utils/ValidationScripts'
const InputElementMsg = (props) => {
    
    const [displayMsg, setDisplayMsg] = useState('required');
    const [msgDivClassname, setMsgDivClassname] = useState('invalid-feedback');
    
    const updateValueToState = (e)=>{

        if(props.type === "checkbox" ){
            props.onChange([props.id],e.target.checked);
        }
        else if(props.type === "radio" ){
            props.onChange([props.name],e.target.value);
        }else{
            props.onChange([props.id],e.target.value);
        }
        validation(e);
    }
    // Iterate the properties with key and values.
        // Object.entries(props).map(([key, value]) => (
        //      console.log(key +"-"+value)
        // ))

    const validation = (e)=>{
         //console.log("inputlement for validation "+[props.id])
        if( !props.onInvalid )
            return false;

        let enteredValue = e.target.value;
        let isValidationRequired = props.validation;
        let eleId = props.id;
        let eleName = props.name;
        let errFlag = false;
        let _dispMsg = null;

        if( isValidationRequired ){
            if ( enteredValue ){
                if( isValidationRequired === "required"){
                    if(!validatePassword(enteredValue)){
                        errFlag = true;
                        _dispMsg = "required";
                    }else{
                        errFlag = false;
                    }
                }else if( isValidationRequired === "email"){
                    //email validation function call
                    //... we will add the n validations.
                    if(isValidEmail(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Invalid Email ID";
                    }
                }else if( isValidationRequired === "phoneNo"){
                    if(isValidPhoneNo(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Invalid Phone No.";
                    }
                }else if( isValidationRequired === "not-zero" ){
                    if(isNumberAndNotZero(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Zero not accepted";
                    }
                }else if( isValidationRequired === "requiredRadio"){
                    if(!validatePassword(enteredValue)){
                        errFlag = true;
                    }else{
                        errFlag = false;
                    }
                }else if( isValidationRequired === "alpha-numeric" ){
                    if(isValidAlphaNumeric(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Accepts only alphabets and numbers";
                    }
                }else if( isValidationRequired === "alpha" ){
                    if(isValidAlpha(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Accepts only alphabets";
                    }
                }else if( isValidationRequired === "integerOnly" ){
                    if(isValidInteger(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Accepts only numbers";
                    }
                }else if( isValidationRequired === "decimal" ){
                    if(validateNumberDecimal(enteredValue)){
                        errFlag = false;
                    }else{
                        errFlag = true;
                        _dispMsg = "Accepts only decimal numbers";
                    }
                }
                // else if( isValidationRequired === "fax" ){
                //     if(validateNumberDecimal(enteredValue)){
                //         errFlag = false;
                //     }else{
                //         errFlag = true;
                //         _dispMsg = "Accepts only decimal numbers";
                //     }
                // }
            }else{
                errFlag = true;
                _dispMsg = "Required";
            }

            if( props.type==="radio" ){
                props.onInvalid(eleName, errFlag);
            }else{
                props.onInvalid(eleId, errFlag);
            }

            if( errFlag ){
                setDisplayMsg(_dispMsg);
                setMsgDivClassname("invalid-feedback")
            }else{
                setMsgDivClassname("valid-feedback")
            }

        }
    }

    

        return (
            <>
                <input 
                    {...props}
                    onChange = {updateValueToState}
                    onBlur = {validation}
                    title={displayMsg} alt={displayMsg} 
                />
                <div className={msgDivClassname}> {displayMsg} </div>
            </>   
        );
}

InputElementMsg.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool
    //resetDependents: PropTypes.array
};


InputElementMsg.defaultProps = {
    type: 'text',
    label: '',
    id: '',
    className: 'form-control',
    placeholder: '',
    readOnly:false
    //resetDependents: ''
};

export default InputElementMsg;
