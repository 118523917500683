import React,{useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { HashLoader } from 'react-spinners';
import InputElement from "../../../../formControls/InputElementMsg";
import DataSpecialEdit from '../Data/DataSpecialEdit';
const SpecialRatesEditModel = (props) => {
    const { formEditData, formEditDataReq, handleEditInputChange,  onSubmitEditSpecial, onValidateEditSpecial, clearEditSpecialForm, setFormEditDataObj } = DataSpecialEdit();
    useEffect(() => {
        // console.log("in use")
        clearEditSpecialForm();
        setFormEditDataObj(props.editData);
        //setFormEditDataObj(prevData => ([...prevData, ...props.editData])); // if use state is not effected use this (trail and error)
      },[props.editData])
      
      const handleEdit = () => {
        //  console.log("fd1",formEditData);
         var isValidationReqEditTariff = onSubmitEditSpecial();
        //  console.log("new acc Record edit:"+JSON.stringify(formEditData))
             
         if (!isValidationReqEditTariff) {
            props.updateRecord(formEditData);
         }
      }

      const closeEdit = () => {
        clearEditSpecialForm();
        props.handleClose();
      }
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}
                size="lg" // for large modal box
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"} // not to close popup when  mouse clicked outside of popup
                keyboard={false} // not to close popup on escape key press
            >
                {props.innerLoading &&
                    <div className="modalLoadingOverlay">
                        <div className="modalLoadingSpinner">
                            <HashLoader color={'#1E73BE'} loading={props.innerLoading} size={35} />
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    <Modal.Title>Edit SpecialRates for {props?.editData?.zone} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                     {/* <div>{JSON.stringify(formEditData)}</div>    */}
                    <Form id="specialEdit">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Current  M-F 0000-0759 : &emsp;&emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_0000_0759}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current  M-F 0800-1700 &emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_0800_1700}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current  M-F 1701-2359 : &emsp;<strong className="embossed">{props?.editData?.currentRate?.moN_FRI_1701_2359}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <label> New M-F 0000 - 0759 : </label>
                            </div>
                            <div className="col-md-2">    <InputElement id="new_moN_FRI_0000_0759" name="new_moN_FRI_0000_0759" 
                            value={formEditData.new_moN_FRI_0000_0759} className={formEditDataReq.new_moN_FRI_0000_0759_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New M-F 0000-0759' />
                            </div>
                            <div className="col-md-2">
                                <label>New M-F 0800 - 1700 :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_moN_FRI_0800_1700" name="new_moN_FRI_0800_1700" 
                                value={formEditData.new_moN_FRI_0800_1700} className={formEditDataReq.new_moN_FRI_0800_1700_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New M-F 0800-1700' />
                            </div>
                            <div className="col-md-2">
                                <label> New M-F 1701 - 2359 : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_moN_FRI_1701_2359" name="new_moN_FRI_1701_2359" 
                                value={formEditData.new_moN_FRI_1701_2359} className={formEditDataReq.new_moN_FRI_1701_2359_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New M-F 1701-2359' />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                <label>Current  Saturday : &emsp;<strong className="embossed">{props?.editData?.currentRate?.saturday}</strong></label>
                            </div>
                            <div className="col-md-4 ">
                                <label>Current Sunday : &emsp;<strong className="embossed">{props?.editData?.currentRate?.sunday}</strong>  </label>
                            </div>
                            <div className="col-md-4 ">
                                <label>Current Holiday : &emsp;<strong className="embossed">{props?.editData?.currentRate?.holiday}</strong>  </label>
                            </div>

                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-2">
                                <label>New Saturday :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_saturday" name="new_saturday" className={formEditDataReq.new_saturday_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New Saturday' value={formEditData.new_saturday}/>
                            </div>
                            <div className="col-md-2">
                                <label>New Sunday : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_sunday" name="new_sunday" className={formEditDataReq.new_sunday_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New Sunday' value={formEditData.new_sunday}/>
                            </div>
                            <div className="col-md-2">
                                <label>New Holiday :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="new_holiday" name="new_holiday" className={formEditDataReq.new_holiday_ErrorClass}
                                    onChange={handleEditInputChange} validation="decimal" onInvalid={onValidateEditSpecial}
                                    placeholder='New Holiday' value={formEditData.new_holiday}/>
                            </div>

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <div className="pr200px">
                        { props.resStatus && 
                        <h6 className={props.resStatus}>{props.resMessage} </h6>
                    }
                    </div>
                    <Button variant="danger" onClick={closeEdit}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SpecialRatesEditModel