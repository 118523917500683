import {  useState } from 'react'

const DataAirportTransferNew = () => {
  
    const  [initData] = [{
        new_station : '',
        new_min_amt : '',
        new_per_lbs : '',
        new_rate: '',
        new_cap_amt: '',
    }];
    const [initDataReq] = [{
        new_station_ErrorClass : 'form-control',
        new_min_amt_ErrorClass : 'form-control',
        new_per_lbs_ErrorClass : 'form-control',
        new_rate_ErrorClass : 'form-control',
        new_cap_amt_ErrorClass : 'form-control',
        new_station : true,
        new_min_amt : true,
        new_per_lbs : true,
        new_rate: true,
        new_cap_amt: true
    }];

    const [formData, setFormData] = useState(initData);

    const [formDataReq, setFormDataReq] = useState(initDataReq);

    const [showSaveDataLoading, setSaveShowDataLoading] = useState(false);

    const onSubmitNew = () => {
        const copy = {...formData};
        const errCopy = {...formDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
            setFormDataReq({...formDataReq,...errObject});
            validationReqFlag = true;
        } 
        return validationReqFlag;
            
    }

    const onValidateNew = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if(key === name && value) {
            errState = {[name]: true,[errname]: _css+' is-invalid'};
            status=true;
        }else if(key === name && !value) {
            errState = {[name]: false,[errname]: _css+''};
            status=true;
        }
        if(status)
            setFormDataReq({...formDataReq, ...errState});
      }

    const handleInputChange = (name, value) => {
        setFormData({...formData, [name]: value});
    }

    const clearNewForm = () => {
        setFormData(initData);
        setFormDataReq(initDataReq);
    }

    const setFormDataObj = (_obj) => {
        //console.log("fd",formData)
        let res_obj = { ...formData, ..._obj}
        setFormData(res_obj);
        //console.log("res",res_obj)
    }

    return {
        formData,
        formDataReq,
        handleInputChange,
        onSubmitNew,
        onValidateNew,
        clearNewForm,
        setFormDataObj
    }
}

export default DataAirportTransferNew;