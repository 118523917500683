import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputElement from "../../formControls/InputElement";
import TextareaElement from "../../formControls/TextareaElement";
import Dimensions from "../quickQuote/Dimensions";

const BookLayer4 = (props) => {
    const [modelState, setModelState] = useState({ show: false });
    const handleModalState = () => {
        setModelState({ show: !modelState.show })
    }

    const navigate = useNavigate();
    const goBack = () => {
		if( props.quoteHis ){
            navigate("/freightQuote/quoteProposed", { state: { quoteHis: props.quoteHis , quoteHisData: props.quoteHisData } });
        }else{
            navigate("/freightQuote/quoteProposed"); 
        }
	}

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-md-6 mb-6 text-bold">
                        <h6>DIMENSIONS INFO</h6>
                    </div>
                    <div className="col-md-6 mb-6 text-bold">
                        <h6>ORDER INFO</h6>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2 mb-2">
                        <label >Total Pcs.</label>
                        <div className="input-group">
                            <InputElement id="totalPcs" name="totalPcs" value={props.quickQuote.totalPcs} onChange={props.onChangeBookHandler}
                                readOnly={!props.isDimensionsInfoEdit} placeholder='Total Pcs.' />
                        </div>
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >Gross Weight (lbs)</label>
                        <InputElement id="grossWeight" name="grossWeight" value={props.quickQuote.grossWeight} onChange={props.onChangeBookHandler}
                            readOnly={!props.isDimensionsInfoEdit} placeholder='Gross Weight' />
                        <div className="invalid-feedback">
                            Valid Address is required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >Dim Weight Total</label>
                        <InputElement id="dimWeightTotal" name="dimWeightTotal" value={props.quickQuote.dimWeightTotal} onChange={props.onChangeBookHandler}
                            readOnly={!props.isDimensionsInfoEdit}  placeholder='Dim Weight Total' />
                        <div className="invalid-feedback">
                            Valid Address is required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >HAWB</label>
                        <InputElement id="hawb" name="hawb" value={props.quickQuote.hawb} onChange={props.onChangeBookHandler}
                            validation="alpha-numeric" className={props.quickQuoteRequired.hawb_ErrorClass} onInvalid={props.onValidateBookHandler}
                            placeholder='HAWB' maxLength='20'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >MAWB</label>
                        <InputElement id="mawb" name="mawb" value={props.quickQuote.mawb} onChange={props.onChangeBookHandler}
                             //validation="alpha-numeric" 
                             //className={props.quickQuoteRequired.mawb_ErrorClass} 
                             //onInvalid={props.onValidateBookHandler}
                            className='form-control'
                            placeholder='MAWB' maxLength='20'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >Ref #</label>
                        <InputElement id="refNo" name="refNo" value={props.quickQuote.refNo} onChange={props.onChangeBookHandler}
                            className='form-control'
                            //validation="required" 
                            //className={props.quickQuoteRequired.refNo_ErrorClass} 
                            //onInvalid={props.onValidateBookHandler}
                            placeholder='Ref #'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-6 mb-2">
                        {/* <Link to="" onClick={() => handleModalState()} className="dimLink text-uppercase" data-toggle="modal" data-target="#detDimensions">Click here for detailed Dimensions</Link> */}
                        <button type="button" className="btn btn-link dimLink text-uppercase" onClick={() => handleModalState()} data-toggle="modal" data-target="#detDimensions"> Click here for detailed Dimensions </button>
                    </div>
                    
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3 mb-lg-0">
                    <label>shipperNotes</label>
                        <TextareaElement id="shipperNotes" name="shipperNotes" rows="2" value={props.quickQuote.shipperNotes || ''} className='form-control'
                            onChange={props.onChangeBookHandler}  placeholder='Shipper Notes' />
                    </div>
                    <div className="col-md-6 mb-3 mb-lg-0">
                    <label>Consignee Notes</label>
                        <TextareaElement id="consigneeNotes" name="consigneeNotes" rows="2" value={props.quickQuote.consigneeNotes || ''} className='form-control'
                            onChange={props.onChangeBookHandler}  placeholder='Consignee Notes' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3 mb-lg-0">
                    <label >Supporting Documents <sub>(Max. size accepted : 20 Mb)</sub></label>
                    <div className="input-group mb-3">
                        <input type="file" name="file" className="form-control-InputFile" onChange={props.readFileData} multiple
                            id="supportingDocs" aria-describedby="supportingDocsClear" aria-label="Upload" />
                        <button className="btn btnInpFile" type="button" id="supportingDocsClear"
                            onClick={props.clearSupportingDocs}><i className="fas fa-times-circle"></i></button>
                    </div>
                    </div>
                    <div className="col-md-6 mb-3 mb-lg-0 input-group alignCenter">
                    <div className="col-md-2"></div>
                        <div className="col-md-4">
                        <button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={goBack}><i className="fas fa-arrow-alt-circle-left"></i> BACK</button>
                        </div>
                        <div className="col-md-6">
                        <button className="btn btn-primary btn-block pull-right" type="button" onClick={props.confirmBooking}> Book Now <i className="fas fa-check-circle"></i> </button>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
            </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quickQuote)}</div> */}         
            <Dimensions  isDimensionsInfoEdit={props.isDimensionsInfoEdit} isBooking={true} 
            isRoadRunner={props.isRoadRunner} showModel={modelState.show} packageTypeList={props.packageTypeList}
            handleModalState={() => handleModalState()} />
            {/* removed onchange as the below function is present in the parent component */
            /* onChange={props.updateDimTotals} */}
        </>
    );
}

export default BookLayer4;