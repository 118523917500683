import React,{useEffect}  from "react";
import turckjpg from '../../../assets/img/truck.jpg'
const RegionalLineHaul = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    return (
		<section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">NATIONWIDE LINEHAUL</h4>
                        </div>
						<div className="row">
							<div className="col-lg-5 col-md-12 ">
							<img src = {turckjpg} className="img-fluid mb-4" alt="Regional Linehaul" />
							</div>
							<div className="col-lg-7 col-md-12 text-justify">
							<p>Freight Force now offers cross country linehaul service that aligns with approved motor carrier network. Complete offering of pickup and delivery service for every origin and destination linehaul lane pairs. Nationwide Linehaul Service options include Door to Terminal, Terminal to Door and Door to Door services. Customer service provided on a local personal level within our carrier network versus having to work through a national call center. Nationwide Linehaul shipments are booked directly from our website.</p>
							
							<p> <strong><a href="mailto:linehaul@freightforce.com">linehaul@freightforce.com</a> or Click on the link below for the Nationwide Linehaul Lanes, Transit Times, and Rates.</strong></p>
                            <li><i className="fas fa-chevron-right"></i><a href={process.env.REACT_APP_LINUHAUL_RATES_NONLOGIN} title="Nationwide LineHaul Schedule" target="_blank">Nationwide LineHaul Schedule</a></li>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default RegionalLineHaul;