import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import InputElement from "../../formControls/InputElement";
import TextareaElement from "../../formControls/TextareaElement";
import Dimensions from "../quickQuote/Dimensions";

const DirectBook4 = (props) => {
    const [pickupSelectChkbox, setPickupSelectchkbox] = useState({ optionSelected: null });
    const [deliverySelectChkbox, setDeliverySelectchkbox] = useState({ optionSelected: null });
    const navigate = useNavigate();
    const handleSelectChangePickup = (selected) => {
        
        let noneIndexFound = selected.findIndex(x => x.value ==="****NONE****");

        if(selected.length>1 && noneIndexFound>-1)
            selected.splice(noneIndexFound,1);

            setPickupSelectchkbox({
            optionSelected: selected
        });
        props.onChangeDimTotal('pickupAccessorials', selected);
    };
    const handleSelectChangeDelivery = (selected) => {
        
        let noneIndexFound = selected.findIndex(x => x.value ==="****NONE****");

        if(selected.length>1 && noneIndexFound>-1)
            selected.splice(noneIndexFound,1);

            setDeliverySelectchkbox({
            optionSelected: selected
        });
        props.onChangeDimTotal('deliveryAccessorials', selected);
    };

    
   
    useEffect(() => {
        if (props.quickBook.pickupAccessorials && (props.quickBook.pickupAccessorials).length > 0) {
            setPickupSelectchkbox({
                optionSelected: props.quickBook.pickupAccessorials
            });

        }
    }, [props.quickBook.pickupAccessorials])
    useEffect(() => {
        if (props.quickBook.deliveryAccessorials && (props.quickBook.deliveryAccessorials).length > 0) {
            setDeliverySelectchkbox({
                optionSelected: props.quickBook.deliveryAccessorials
            });

        }
    }, [props.quickBook.deliveryAccessorials])

    const [modelState, setModelState] = useState({ show: false });

    const handleModalState = () => {
        setModelState({ show: !modelState.show })
    }

    useEffect(() => {
        toggleDimensions();
    }, [props.quickBook.totalPcs,props.quickBook.grossWeight,props.quickBook.dimWeightTotal])

    useEffect(() => {
        if( props.clearAllDims === "true" ){
            setDeliverySelectchkbox({
                optionSelected:  null
            });
            setPickupSelectchkbox({
                optionSelected:  null
            });
        }
    }, [props.clearAllDims]);

    const toggleDimensions = () =>{
        if( props.quickBook.totalPcs !== '' && props.quickBook.grossWeight !== '' && props.quickBook.dimWeightTotal !== '')
        return <div className="col-lg-6 col-md-12">
        <div className="row mb-1">
            <div className="col-md-4">
                <label>Total Pcs</label>
                <InputElement id="totalPcs" name="totalPcs"
                    className={props.totalDimRequired.totalPcs_ErrorClass}
                    value={props.quickBook.totalPcs || ''}
                    onChange={props.onChangeDimTotal}
                    onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true}
                    placeholder='Total Pcs' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
            <div className="col-md-4">
                <label>Gross Weight</label>
                <InputElement id="grossWeight" name="grossWeight" className={props.totalDimRequired.grossWeight_ErrorClass} value={props.quickBook.grossWeight || ''} onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true} 
                placeholder='Gross Weight' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
            <div className="col-md-4">
                <label>Dim Weight Total</label>
                <InputElement id="dimWeightTotal" name="dimWeightTotal" className={props.totalDimRequired.dimWeightTotal_ErrorClass} value={props.quickBook.dimWeightTotal || ''} onChange={props.onChangeDimTotal} onInvalid={props.onValidateDimTotal} validation="not-zero" readOnly={true} 
                 placeholder='Dim Weigh Total' />
                <div className="invalid-feedback">
                    required.
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 text-right">
                <Link to="" onClick={() => handleModalState()} className="dimLink text-uppercase" data-toggle="modal" data-target="#detDimensions">Click here to edit detailed
                    Pcs, Lbs, Dims
                </Link>
            </div>
        </div>
    </div>
    else
    return <div className="col-md-6 my-3">
            <div className="row vertical-center">
            <button className="btn btn-primary btn-block pull-right transformNone" type="button" data-toggle="modal" data-target="#detDimensions" onClick={() => {handleModalState();}}>
                Add Pcs, Lbs, Dims
            </button>
            {props.showAddDimMsg && 
            <div className="text-danger" id="addDimensionsMsgDiv">
                Add Pcs, Lbs, Dims
            </div>
            }
        </div>
        </div>
        
    }


    return (
        <>
            <div>
                <div className="row">
                    <div className="col-md-6 mb-6 text-bold">
                        <h6>DIMENSIONS INFO</h6>
                    </div>
                    <div className="col-md-6 mb-6 text-bold">
                        <h6>ORDER INFO</h6>
                    </div>
                </div>
                
                <div className="row">
                {toggleDimensions()}
                    <div className="col-md-2 mb-2">
                        <label >HAWB</label>
                        <InputElement id="hawb" name="hawb" value={props.quickBook.hawb} onChange={props.onChangeBookHandler}
                            validation="alpha-numeric" className={props.quickBookRequired.hawb_ErrorClass} onInvalid={props.onValidateBookHandler}
                            placeholder='HAWB' maxLength='20'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >MAWB</label>
                        <InputElement id="mawb" name="mawb" value={props.quickBook.mawb} onChange={props.onChangeBookHandler}
                             validation="alpha-numeric" 
                             className={props.quickBookRequired.mawb_ErrorClass} 
                             onInvalid={props.onValidateBookHandler}
                            placeholder='MAWB' maxLength='20'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-md-2 mb-2">
                        <label >Ref #</label>
                        <InputElement id="refNo" name="refNo" value={props.quickBook.refNo} onChange={props.onChangeBookHandler}
                            validation="required" 
                            className={props.quickBookRequired.refNo_ErrorClass} 
                            onInvalid={props.onValidateBookHandler}
                            placeholder='Ref #'
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-md-6 mb-3 mb-lg-0 pt-4">
                    <label >Supporting Documents <sub>(Max. size accepted : 20 Mb)</sub></label>
                    <div className="input-group mb-3">
                        <input type="file" name="file" className="form-control-InputFile" onChange={props.readFileData} multiple
                            id="supportingDocs" aria-describedby="supportingDocsClear" aria-label="Upload" />
                        <button className="btn btnInpFile" type="button" id="supportingDocsClear"
                            onClick={props.clearSupportingDocs}><i className="fas fa-times-circle"></i></button>
                    </div>
                    </div>
                    <div className="col-md-3 mb-3 mb-lg-0 mt-3">
                            
                                <label className="boldBlack"> Pickup Accessorial Application </label>
                                <label className="QuoteHead">(Must select One or None)</label>
                                <ReactSelect
                                    id="pickupAccessorials"
                                    options={props.optionsData}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSelectChangePickup}
                                    allowSelectAll={true}
                                    value={pickupSelectChkbox.optionSelected}
                                // className={props.totalDimRequired.accessorial_ErrorClass} 
                                />

                                <small className={props.quickBook.pickupAccessorials_ErrorClass}>
                                    required.
                                </small>
                                {/* {JSON.stringify(props.optionsData)} */}
                            
                    </div>
                    <div className="col-md-3 mt-3 mb-lg-0 mt-3">

                        <label className="boldBlack"> Delivery Accessorial Application </label>
                                <label className="QuoteHead">(Must select One or None)</label>
                                <ReactSelect
                                    id="deliveryAccessorials"
                                    options={props.optionsData}
                                    styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    onChange={handleSelectChangeDelivery}
                                    allowSelectAll={true}
                                    value={deliverySelectChkbox.optionSelected}
                                // className={props.totalDimRequired.accessorial_ErrorClass} 
                                />

                                <small className={props.quickBook.deliveryAccessorials_ErrorClass}>
                                    required.
                                </small>

                        </div>
                </div>

                <div className="row">
                    
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3 mb-lg-0">
                    <label>shipperNotes</label>
                        <TextareaElement id="shipperNotes" name="shipperNotes" rows="2" value={props.quickBook.shipperNotes || ''} className='form-control'
                            onChange={props.onChangeBookHandler}  placeholder='Shipper Notes' />
                    </div>
                    <div className="col-md-6 mb-3 mb-lg-0">
                    <label>Consignee Notes</label>
                        <TextareaElement id="consigneeNotes" name="consigneeNotes" rows="2" value={props.quickBook.consigneeNotes || ''} className='form-control'
                            onChange={props.onChangeBookHandler}  placeholder='Consignee Notes' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3 mb-lg-0">
                    </div>
                    <div className="col-md-6 mb-3 mb-lg-0 input-group alignCenter">
                    <div className="col-md-2"></div>
                        <div className="col-md-4">
                        
                        </div>
                        <div className="col-md-6 pt-3">
                        <button className="btn btn-primary btn-block pull-right" type="button" onClick={props.confirmBookNow}> Book Now <i className="fas fa-check-circle"></i> </button>
                        </div>
                    </div>
                </div>
                <hr className="mb-3" />
            </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quickBook)}</div> */}         
            <Dimensions onChange={props.updateDimTotals}  isDimensionsInfoEdit={props.quickBook.isDimensionsInfoEdit} isBooking={true} 
            isRoadRunner={props.isRoadRunner} showModel={modelState.show} packageTypeList={props.packageTypeList}
            handleModalState={() => handleModalState()} />
            {/* removed onchange as the below function is present in the parent component */
            /* onChange={props.updateDimTotals} */}
        </>
    );
}

export default DirectBook4;