import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility'
//import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    customer: {
        custCode: "",
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        phone: "",
        email: "",
        contact: ""
    },
    shipper: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        phone: "",
        email: "",
        contact: ""
    },
    consignee: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        phone: "",
        email: "",
        contact: ""
    },
    shipment: {
        weight: 0,
        pickupDate: "",
        pickupOpenTime: "",
        pickupCloseTime: "",
        deliveryDate: "",
        deliveryOpenTime: "",
        deliveryCloseTime: "",
        //notes: "",
        shipperNotes: "",
        consigneeNotes: "",
        isSpecial: false,
            dimList: [{
                    qty: 0,
                    length: 0,
                    weight:0,
                    width: 0,
                    height: 0,
                    description: ""
                }],
            accessorials: [{
                code: "",
                description: "",
                amount: 0
            }],
        qty: 0
    },
    rateQuote: { 
        originZip:"",
        destinationZip:"",
        msg:"",
        weight:0,
        qty:0,
        cbm:0,
        fsc:0,
        fsc_rate:0,
        freight_charge:0
    },
    quoteId:"",
    
};

const reducer =  ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADDQUICKQUOTE:
            return updateObject(state, action.quickQuote);
        default:
            return state
    }
};

export default reducer;