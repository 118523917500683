import { useState } from 'react';

const DataCargoClaim = () => {
    const stateList = [{value: "AL", label: "Alabama - (AL)"},
  {value: "AK", label: "Alaska - (AK)"},
  {value: "AZ", label: "Arizona - (AZ)"},
  {value: "AR", label: "Arkansas - (AR)"},
  {value: "CA", label: "California - (CA)"},
  {value: "CO", label: "Colorado - (CO)"},
  {value: "CT", label: "Connecticut - (CT)"},
  {value: "DE", label: "Delaware - (DE)"},
  {value: "DC", label: "District of Columbia - (DC)"},
  {value: "FL", label: "Florida - (FL)"},
  {value: "GA", label: "Georgia - (GA)"},
  {value: "HI", label: "Hawaii - (HI)"},
  {value: "ID", label: "Idaho - (ID)"},
  {value: "IL", label: "Illinois - (IL)"},
  {value: "IN", label: "Indiana - (IN)"},
  {value: "IA", label: "Iowa - (IA)"},
  {value: "KS", label: "Kansas - (KS)"},
  {value: "KY", label: "Kentucky - (KY)"},
  {value: "LA", label: "Louisiana - (LA)"},
  {value: "ME", label: "Maine - (ME)"},
  {value: "MD", label: "Maryland - (MD)"},
  {value: "MA", label: "Massachusetts - (MA)"},
  {value: "MI", label: "Michigan - (MI)"},
  {value: "MN", label: "Minnesota - (MN)"},
  {value: "MS", label: "Mississippi - (MS)"},
  {value: "MO", label: "Missouri - (MO)"},
  {value: "MT", label: "Montana - (MT)"},
  {value: "NE", label: "Nebraska - (NE)"},
  {value: "NV", label: "Nevada - (NV)"},
  {value: "NH", label: "New Hampshire - (NH)"},
  {value: "NJ", label: "New Jersey - (NJ)"},
  {value: "NM", label: "New Mexico - (NM)"},
  {value: "NY", label: "New York - (NY)"},
  {value: "NC", label: "North Carolina - (NC)"},
  {value: "ND", label: "North Dakota - (ND)"},
  {value: "OH", label: "Ohio - (OH)"},
  {value: "OK", label: "Oklahoma - (OK)"},
  {value: "OR", label: "Oregon - (OR)"},
  {value: "PA", label: "Pennsylvania - (PA)"},
  {value: "PR", label: "Puerto Rico - (PR)"},
  {value: "RI", label: "Rhode Island - (RI)"},
  {value: "SC", label: "South Carolina - (SC)"},
  {value: "SD", label: "South Dakota - (SD)"},
  {value: "TN", label: "Tennessee - (TN)"},
  {value: "TX", label: "Texas - (TX)"},
  {value: "UT", label: "Utah - (UT)"},
  {value: "VT", label: "Vermont - (VT)"},
  {value: "VA", label: "Virginia - (VA)"},
  {value: "VI", label: "Virgin Islands - (VI)"},
  {value: "WA", label: "Washington - (WA)"},
  {value: "WV", label: "West Virginia - (WV)"},
  {value: "WI", label: "Wisconsin - (WI)"},
  {value: "WY", label: "Wyoming - (WY)"},
  ];
    const  [inputs] = [{
        billNo : '',
        claimNo : '',
        serviceDate : '',
        company : '',
        contactPerson : '',
        streetAddress : '',
        city : '',
        state : '',
        zipcode : '',
        phone : '',
        fax : '',
        email : '',
        claimType : '',
        claimStatement : '',
        totalAmtClaimed : '',
        claimDesc : '',
        // supportingDocs : '',
        docVendorInvoice : false,
        docBillLoading : false,
        docRepairInvoice : false,
        docPaidForBill : false,
        docDiscountedSale : false,
        docInspReport : false,
        docDeliveryReciept : false,
        docShortageDamage : false,
        docOther1 : false,
        docOther1Desc : '',
        docOther2 : false,
        docOther2Desc : '',
        uploadedDocs : false,
        claimDescReadonly : true,
        other1DescReadonly : false,
        other2DescReadonly : false,
    }];

    const [inputsReq] = [{
        billNo : true,
        claimNo : true,
        serviceDate : true,
        company : true,
        contactPerson : true,
        streetAddress : true,
        city : true,
        state : true,
        zipcode : true,
        phone : true,
        fax : true,
        email : true,
        claimType : true,
        claimStatement : false,
        totalAmtClaimed : true,
        claimDesc : true,
        supportingDocs : false,
        docVendorInvoice : false,
        docBillLoading : false,
        docRepairInvoice : false,
        docPaidForBill : false,
        docDiscountedSale : false,
        docInspReport : false,
        docDeliveryReciept : false,
        docShortageDamage : false,
        docOther1 : false,
        docOther1Desc : '',
         docOther2 : false,
        docOther2Desc : '',
        // uploadedDocs : true,
        billNo_ErrorClass : 'form-control',
        claimNo_ErrorClass : 'form-control',
        serviceDate_ErrorClass : 'form-control',
        company_ErrorClass : 'form-control',
        contactPerson_ErrorClass : 'form-control',
        streetAddress_ErrorClass : 'form-control',
        city_ErrorClass : 'form-control',
        state_ErrorClass : 'text-danger d-none',
        zipcode_ErrorClass : 'form-control',
        phone_ErrorClass : 'form-control',
        fax_ErrorClass : 'form-control',
        email_ErrorClass : 'form-control',
        claimType_ErrorClass : 'custom-control-input',
        claimStatement_ErrorClass : 'form-control',
        totalAmtClaimed_ErrorClass : 'form-control',
        claimDesc_ErrorClass : 'form-control',
        // supportingDocs_ErrorClass : 'form-control',
        // docVendorInvoice_ErrorClass : 'form-check-input',
        // docBillLoading_ErrorClass : 'form-check-input',
        // docRepairInvoice_ErrorClass : 'form-check-input',
        // docPaidForBill_ErrorClass : 'form-check-input',
        // docDiscountedSale_ErrorClass : 'form-check-input',
        // docInspReport_ErrorClass : 'form-check-input',
        // docDeliveryReciept_ErrorClass : 'form-check-input',
        // docShortageDamage_ErrorClass : 'form-check-input',
        // docOther1_ErrorClass : 'form-check-input',
        docOther1Desc_ErrorClass : 'form-control form-inline',
        // docOther2_ErrorClass : 'form-check-input',
        docOther2Desc_ErrorClass : 'form-control form-inline',
        // uploadedDocs_ErrorClass : 'form-control',
    }];

    const [dataInput, setDataInput] = useState(inputs);
    const [dataInputReq, setDataInputReq] = useState(inputsReq);

    const onSubmitForm = () => {
        const copy = {...dataInputReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                // var _css = (key==="claimType" ? "custom-control-input" : (key.startsWith("doc") ? "form-check-input" : "form-control"));
                let _css = '';
                if( key==="claimType" ){
                    _css = 'custom-control-input';
                }else if( key.startsWith("doc") ){
                    if( key.includes("Desc")){
                        _css = 'form-control form-inline';
                    }else{
                        _css = 'form-check-input';
                    }
                }else{
                    _css = 'form-control';
                }
                var errState = null;
                if( errname==="state_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'text-danger' };
                }else{
                    errState = {[key]: true,[errname]: _css+' is-invalid'};
                }
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setDataInputReq({...dataInputReq,...errObject});
        } 

        // setShowDataLoading(true);
        // loadReportData();
        let reqObj = { ...dataInputReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        // console.log("c="+JSON.stringify(reqObj))
        validationReqFlag = Object.values(reqObj).includes(true);
        // console.log("validationReqFlag=" + validationReqFlag)
        return validationReqFlag;
            
    }

    const onValidate = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            // var _css = (key==="claimType" ? "custom-control-input" : "form-control");
            let _css = '';
            if( key==="claimType" ){
                _css = 'custom-control-input';
            }else if( key.startsWith("doc") ){
                if( key.includes("Desc")){
                    _css = 'form-control form-inline';
                }else{
                    _css = 'form-check-input';
                }
            }else{
                _css = 'form-control';
            }

            if( errname==="state_ErrorClass" ){
                if ( value ) {
                    errState = { [name]: true, [errname]: 'text-danger' };
                    status = true;
                }else{ 
                    errState = { [name]: false, [errname]: 'text-danger d-none' };
                    status = true;
                }
            }else{
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
            }
            if(status)
            setDataInputReq({...dataInputReq, ...errState});
      }

    const handleInputChange = (name, value) => {
        let obj = {}, objReq = {};
            obj[name] = value;
        if( name[0]==="claimType" ){
            obj['claimStatement'] = '';
            if(value){
                objReq['claimType'] = false
                objReq['claimType_ErrorClass'] = 'custom-control-input'

            }else{
                objReq['claimType'] = true
                objReq['claimType_ErrorClass'] = 'custom-control-input is-invalid'
            }
            if(value==="Other"){
                obj['claimDescReadonly'] = false
                objReq['claimStatement'] = true
            }else{
                obj['claimDescReadonly'] = true
                objReq['claimStatement'] = false
            }
        }else if( name[0]==="docOther1" ){
            obj['docOther1Desc'] = '';
            if( value===true ){
                obj['other1DescReadonly'] = false
                objReq['docOther1Desc'] = true
            }else{
                obj['other1DescReadonly'] = true
                objReq['docOther1Desc'] = false
            }
        }else if( name[0]==="docOther2" ){
            obj['docOther2Desc'] = '';
            if( value===true ){
                obj['other2DescReadonly'] = false
                objReq['docOther2Desc'] = true
            }else{
                obj['other2DescReadonly'] = true
                objReq['docOther2Desc'] = false
            }
        }else if( name[0]==="docOther1Desc" ){
            if( value ){
                objReq['docOther1Desc'] = false
                objReq['docOther1Desc_ErrorClass'] = 'form-control'

            }else{
                objReq['docOther1Desc'] = true
                objReq['docOther1Desc_ErrorClass'] = 'form-control is-invalid'
            }
        }else if( name[0]==="docOther2Desc" ){
            if( value ){
                objReq['docOther2Desc'] = false
                objReq['docOther2Desc_ErrorClass'] = 'form-control'

            }else{
                objReq['docOther2Desc'] = true
                objReq['docOther2Desc_ErrorClass'] = 'form-control is-invalid'
            }
        }else if( name[0]==="claimStatement" ){
            if( value ){
                objReq['claimStatement'] = false
                objReq['claimStatement_ErrorClass'] = 'form-control'
            }else{
                objReq['claimStatement'] = true
                objReq['claimStatement_ErrorClass'] = 'form-control is-invalid'
            }
        }
        // setDataInput({...dataInput, [name]: value});
        setDataInput({...dataInput, ...obj});
        setDataInputReq({...dataInputReq, ...objReq});

    }

    const clearForm = () => {
        setDataInput(inputs);
        setDataInputReq(inputsReq);
    }

    return {
        handleInputChange,
        onSubmitForm,
        onValidate,
        clearForm,
        dataInput,
        dataInputReq,
        stateList
    }
}

export default DataCargoClaim;