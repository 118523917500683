import { useEffect,useRef } from 'react';
import { useSelector } from 'react-redux';
import { copyStyles,extractStyles } from '../../utils/ValidationScripts';
//import data from '../restAPI/coverage.json'
import PrintList from './PrintList'
// import { useReactToPrint } from 'react-to-print';


const CarrierDirectory = () => {
  const componentRef = useRef(null);
  const data = useSelector((state) => state.coverage);
  //console.log("data getCarrierInformation"+JSON.stringify(data))

  useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  let dataList=[];
    for (var i = 0; i < data.length; i+=3){
        let addr1={},addr2={},addr3={},addr4={};
        if(i<data.length)
        addr1=data[i];
        if((i+1)<data.length)
        addr2=data[i+1];
        if((i+2)<data.length)
        addr3=data[i+2];
        dataList.push(<PrintList key={i} addr1={addr1} addr2={addr2} addr3={addr3}  />);
    } 

    const handlePrintClick = () => {
      const printableElements = document.getElementById('print-area').innerHTML;
      var w = window.open('', "_blank");
      let cssStyles = extractStyles(window.document);
      const orderHtml = `<html><head><title></title>` +cssStyles+ `</head><body id='cdPrintWin' style='background: none !important;'>` + printableElements + `</body></html>`
        w.document.write(orderHtml);
          setTimeout(function() {
                  w.window.print();
                  w.window.focus();
                  w.window.close();  
          }, 1000);      
    };

    // const handlePrint = useReactToPrint({
    //   content: () => componentRef.current,
    // });
    
    const userProfile = useSelector((state) => state.usr)
        let sectionClassname = "mt-180 mb-4";
        let divClassname = "container";
        if( userProfile.isValid ) { //login pages
            sectionClassname = '';
            divClassname = '';
        }

    return (
        <section className={sectionClassname} ref={componentRef}>
        <div className={divClassname}>
          <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Carrier Directory</h4>
                        <button className="btn btn-secondary pull-right ml-auto" type="button"  onClick={handlePrintClick}>
                        <i className='fa fa-print'></i> Print</button> 
                    </div>
                    <form>
                    <div  id='print-area'>
                      <table width='100%' id="carrierDirectoryTbl">
                        <thead>
                            <tr>
                                <td>
                                  <div className='col-md-12 text-center'>
                                  <label id="QuoteLabel20" >Freight Force, Inc.</label>
                                  <br/>
                                  <label id="QuoteLabel" > 
                                    14445 Alondra Blvd. La Mirada, CA 90638  ● Tel: (714) 995-9300  ● Fax: (714) 995-9330 
                                    <br/>
                                    http://www.freightforce.com
                                  </label>
                                  </div>
                                </td>
                              </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>
                                {/* <div className="col-md-12 order-md-1"> */}
                                <div className="row mb-3 p-2" >
                                {dataList} 
                                </div>
                                {/* </div> */}
                              </td>
                            </tr>
                        </tbody>
                      </table>
                      </div>
                    </form>
                    {/* <div className="container">
             <div className="card card-custom">    
             <div className="row">
             <div className="col-md-3"></div>
            <div className="col-md-6 p-2">
            <div className="row p-2  m-6 justify-content-between"  >
           <div> <label id="QuoteLabel20" >Freight Force, Inc.</label> </div>
           <div> <a id="QuoteLabel15Black" onClick={window.print}>Print</a> </div>
            </div>
            <label id="QuoteLabel" >                   14445 Alondra Blvd. La Mirada, CA 90638  ● Tel: (714) 995-9300  ● Fax: (714) 995-9330 </label> <br/>
            <label id="QuoteLabel" >                      http://www.freightforce.com</label>
            </div>
            </div> 
            
            
                    {dataList}
          
            </div>
       </div> */}
            </div>
      </div>
      </div>
      </div>
      </section>
    );
}



export default CarrierDirectory;