import React,{useEffect} from 'react'
import whiteGloveImg from '../../../assets/img/img_whiteGlove.jpg'

const WhiteGlove = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">WHITE GLOVE</h4>
                        </div>
						<div className="row">
							<div className="col-lg-5 col-md-12">
							<img src={whiteGloveImg} className="img-fluid mb-4" alt='WhiteGlove'/>
							</div>
							<div className="col-lg-7 col-md-12 text-justify">
							<p>Freight Force's network of approved motor carriers provides the specialized services you need. They can receive your business-to-business or business-to-consumer deliveries at their docks, or recover them from your carrier, then verify inventory, appraise damages and communicate the results within one hour.
Our motor carriers will follow any setup, installation or custom operating procedures you require. Date-/time-specific appointments can be made with the consignee in accordance with your specifications or the recipient's.</p>
							<p>Our motor carriers will provide the appropriate equipment required to complete the task including lift gate, pallet jack, blankets, tools, and a team of two, three or four men. They can also provide inside delivery, unpacking, assembly, inspection and placement, as well as removal, disposal or return of any debris or merchandise per your instructions. Our motor carriers can provide you with acknowledgement of the customer’s satisfaction.</p>
<p>Attention to details like this, is why Freight Force's network of approved motor carriers is a premier White Glove service provider for delivery of computers and electronics, furniture and appliances, flat-screen TVs and home theater systems, gym equipment, outside patio outfitting, and more.
<br/>
To arrange White Glove service for your next important delivery, call your local Freight Force motor carrier for assistance, or contact the Freight Force Corporate Sales and Marketing Department at <a href="mailto:sales@freightforce.com">sales@freightforce.com</a>.</p>
							
								
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhiteGlove