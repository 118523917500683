import moment from 'moment';
import React,{useEffect} from 'react'
import { useState } from 'react';
import { HashLoader } from 'react-spinners';
import DatePickerElement from '../../formControls/DatePickerElement';
import InputElement from '../../formControls/InputElement';
import SelectElement from '../../formControls/SelectElement';
import TextareaElement from '../../formControls/TextareaElement';
import { processCargoClaim } from '../../services/restAPI/UtilsRest';
import DataCargoClaim from './Data/DataCargoClaim'

function CargoClaimForm() {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const {handleInputChange,onSubmitForm,onValidate,clearForm,dataInput,dataInputReq,stateList} = DataCargoClaim();
	const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([{fileName: null, data: ""}]);

    const submitForm = () => {
        let isValidationReq = onSubmitForm();
        if (!isValidationReq) {
            setLoading(true);
            processCargoClaim(dataInput, attachments)
                .then(dRes => {
                    // console.log("dr=",dRes)
                    if(dRes instanceof Error){
                        setSaveMessage({showMsg: true, status: 'error', message: 'Error in submission'});

                    }else{
                        setSaveMessage({showMsg: true, status: 'message', message: 'Cargo Claim Submitted successfully !'});

                    }
                })
            setTimeout(()=>{
                clearForm();
                setLoading(false);
		        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                // setSaveMessage({showMsg: false, status: '', message: ''});
            },2000);
        }
      }
    
      const clearPage = () => {
            setSaveMessage({showMsg: false, status: '', message: ''});
            clearForm();
	        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

      }

      let attachmentList = [];
      
        const readAllfiles = (e) => {
          let fileList = e.target.files;
              for(let i=0;i<fileList.length;i++){
                  let file = fileList[i];
                   new Promise(resolve => {
                      let baseURL = "";
                      let reader = new FileReader();
                      reader.readAsDataURL(file);    
                      // on reader load somthing...
                      reader.onload = () => {
                        baseURL = reader.result;
                        resolve(baseURL);
                      };
                    }).then(result =>{ 
                      if(result.indexOf("base64,") !== -1)
                      result = result.substring(result.indexOf("base64,")+7);
                      attachmentList.push({fileName:file.name, data:result }
                      );
                              //console.log(attachmentList);
                              setAttachments([...attachmentList])
                  }).catch(err =>{console.log(err)})
              }
        };
       
      const clearSupportingDocs = () => {
          document.getElementById("uploadedDocs").value="";
          setAttachments([]);
      }
  

  return (
    <section className="mt-180 mb-4">
        <div className={loading ? 'container parentDisable' : 'container'}>
            {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'}/>}
            <div className="row">
				<div className="col-md-12">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-2 pageHead text-uppercase">Cargo Claim</h4>
                        </div>
						<form className="needs-validation">
                            {saveMessage.showMsg &&
                                <div className="row">
                                    <div className="col-md-12 text-center text-bold">
                                        {saveMessage.status === "error" ?
                                            <div className="alert alert-danger">
                                                <i className="fas fa-times-circle"></i> 
                                                {saveMessage.message}
                                            </div>
                                            :
                                            <div className="alert alert-primary">
                                                {saveMessage.message}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="row mb-3">
                                <div className="col-md-4 ">
                                    <label htmlFor="acNo">Freight Bill Number</label>
                                    <InputElement id="billNo" name="billNo" value={dataInput.billNo || ''} className={dataInputReq.billNo_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Freight Bill Number' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label htmlFor="waybillNo">Your Claim Number</label>
                                    <InputElement id="claimNo" name="claimNo" value={dataInput.claimNo || ''} className={dataInputReq.claimNo_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Your Claim Number' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label htmlFor="servDate">Service Date</label>
                                    <DatePickerElement id="serviceDate" name="serviceDate" onChange={handleInputChange} 
                                        //minDate={new Date()}
                                        value={dataInput.serviceDate}
                                        selected={dataInput.serviceDate}
                                        onInvalid={onValidate}
                                        validation="required"
                                        className={dataInputReq.serviceDate_ErrorClass}
                                        //maxDate={moment(new Date()).add(6, 'month').toDate()}
                                         />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
							<hr className="mb-3"/>
							 <div className="d-flex">
                            <h5 className="mb-3">Claimant Information</h5>
                        </div>
						<div className="row mb-3">
                                <div className="col-md-4 ">
                                    <label>Company</label>
                                    <InputElement id="company" name="company" value={dataInput.company || ''} className={dataInputReq.company_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Company' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label>Contact Person</label>
                                    <InputElement id="contactPerson" name="contactPerson" value={dataInput.contactPerson || ''} className={dataInputReq.contactPerson_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Contact Person' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label>Street Address</label>
                                    <InputElement id="streetAddress" name="streetAddress" value={dataInput.streetAddress || ''} className={dataInputReq.streetAddress_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Street Address' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
							<div className="row mb-3">
                                <div className="col-md-4 ">
                                    <label>City</label>
                                    <InputElement id="city" name="city" value={dataInput.city || ''} className={dataInputReq.city_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='City' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label>State</label>
                                    <SelectElement id="state" name="state" value={dataInput.state || ''} 
                                        className={dataInputReq.state_ErrorClass} 
                                        optionsData={stateList}
                                        onChange={handleInputChange} onInvalid={onValidate} validation="required" 
                                        placeholder='- Choose State -' />
                                    <small className={dataInputReq.state_ErrorClass} >
                                        required.
                                    </small>
                                </div>
								<div className="col-md-4">
                                    <label>Zip Code</label>
                                    <InputElement id="zipcode" name="zipcode" value={dataInput.zipcode || ''} className={dataInputReq.zipcode_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Zip code' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
							<div className="row mb-3">
                                <div className="col-md-4 ">
                                    <label>Phone</label>
                                    <InputElement id="phone" name="phone" value={dataInput.phone || ''} className={dataInputReq.phone_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Phone' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label>Fax</label>
                                    <InputElement id="fax" name="fax" value={dataInput.fax || ''} className={dataInputReq.fax_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Fax' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
								<div className="col-md-4">
                                    <label>Email</label>
                                    <InputElement id="email" name="email" value={dataInput.email || ''} className={dataInputReq.email_ErrorClass}
                                        onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                        placeholder='Email' />
                                    <div className="invalid-feedback">
                                        required.
                                    </div>
                                </div>
                            </div>
							<hr className="mb-3"/>
                            <div className="row mb-1">
								<div className="col-md-6 border-right">
									<div className="d-flex">
										<h5 className="mb-3">Statement of Claim</h5>
									</div>
                                    <div className="row mb-3">
                                        <div className="col-md-12">
                                            <div className="custom-control custom-radio custom-control-inline col-md-3">
                                                {/* <input type="radio" id="shortage" name="types" className="custom-control-input"/> */}
                                                <InputElement type="radio" id="claimTypeShortage" name="claimType"  validation="requiredRadio"
                                                onChange={handleInputChange} value="Shortage" onInvalid={onValidate} 
                                                checked={dataInput.claimType === "Shortage"}
                                                className={dataInputReq.claimType_ErrorClass} />
                                                <label className="custom-control-label" htmlFor="claimTypeShortage">Shortage</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline col-md-4">
                                                <InputElement type="radio" id="claimTypeVisDamage" name="claimType"  validation="requiredRadio"
                                                onChange={handleInputChange} value="Visible Damage" onInvalid={onValidate} 
                                                checked={dataInput.claimType === "Visible Damage"}
                                                className={dataInputReq.claimType_ErrorClass} />
                                                <label className="custom-control-label" htmlFor="claimTypeVisDamage">Visible Damage</label>
                                            </div>
											<div className="custom-control custom-radio custom-control-inline col-md-4">
                                                <InputElement type="radio" id="claimTypeConDamage" name="claimType"  validation="requiredRadio"
                                                onChange={handleInputChange} value="Concealed Damage" onInvalid={onValidate} 
                                                checked={dataInput.claimType === "Concealed Damage"}
                                                className={dataInputReq.claimType_ErrorClass} />
                                                <label className="custom-control-label" htmlFor="claimTypeConDamage">Concealed Damage</label>
                                            </div>
                                        </div>
									</div>
									<div className="row mb-3">
										<div className="col-md-2">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <InputElement type="radio" id="claimTypeOther" name="claimType"  validation="requiredRadio"
                                                onChange={handleInputChange} value="Other" 
                                                // onInvalid={onValidate} 
                                                checked={dataInput.claimType === "Other"}
                                                className={dataInputReq.claimType_ErrorClass} /> 
                                                <label className="custom-control-label" htmlFor="claimTypeOther">Other</label>
                                            </div>
										</div>
                                        <div className="col-md-6">
                                            <InputElement type="text" id="claimStatement" name="claimStatement" value={dataInput.claimStatement || ''} 
                                                className={dataInputReq.claimStatement_ErrorClass} readOnly={dataInput.claimDescReadonly}
                                                onChange={handleInputChange} 
                                                //onInvalid={onValidate} 
                                                placeholder='Other Claim Statement' />
											<div className="invalid-feedback">
												required.
											</div>
										</div>
									</div>
                                    <div className="row mb-3">
										<div className="col-md-8">
											<label>Total Amount Claimed</label>
											<InputElement type="text" id="totalAmtClaimed" name="totalAmtClaimed" value={dataInput.totalAmtClaimed || ''} className={dataInputReq.totalAmtClaimed_ErrorClass}
                                                onChange={handleInputChange} onInvalid={onValidate}  validation="required"
                                                placeholder='Total Amount Claimed' />
											<div className="invalid-feedback">
												required.
											</div>
										</div>
									</div>
									<div className="row">
									<div className="col-md-12">
										<label className='text-justify'>Explain in detail how you determined the claim amount. List the number and description of the goods, the nature and extent of loss or damage, the invoice cost, and other pertinent details.
                                        </label>
                                        <TextareaElement id="claimDesc" name="claimDesc" rows="2" value={dataInput.claimDesc || ''} className={dataInputReq.claimDesc_ErrorClass}
                                            onChange={handleInputChange} onInvalid={onValidate} placeholder='Description' validation="required" />
										<div className="invalid-feedback">
											required.
										</div>
									</div>
                            </div>
								</div>
								<div className="col-md-6">
									<div className="d-flex">
										<h5 className="mb-3">Supporting Documents</h5>
									</div>
									
										<div>
									<div className="row mb-1"> 
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docVendorInvoice" name="docVendorInvoice"
                                                checked={dataInput.docVendorInvoice}
                                                onChange={handleInputChange}  />
									        <label className="form-check-label" htmlFor="docVendorInvoice" >
												Original Vendor Invoice
											  </label>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docBillLoading" name="docBillLoading"
                                                checked={dataInput.docBillLoading}  
                                                onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docBillLoading">
												Copy of Bill of Lading
											  </label>
											</div>
										</div>
									</div>
									<div className="row mb-1"> 
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docRepairInvoice" name="docRepairInvoice"
                                                checked={dataInput.docRepairInvoice} 
                                                onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docRepairInvoice">
												Original Repair Invoice
											  </label>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docPaidForBill" name="docPaidForBill"
                                                checked={dataInput.docPaidForBill} 
                                                onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docPaidForBill">
												Copy of Paid Freight Bill
											  </label>
											</div>
										</div>
									</div>
									<div className="row mb-1"> 
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docDiscountedSale" name="docDiscountedSale"
                                                checked={dataInput.docDiscountedSale} 
                                                onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docDiscountedSale">
												Record of Discounted Sale
											  </label>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docInspReport" name="docInspReport"
                                            checked={dataInput.docInspReport} 
                                            onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docInspReport">
												Inspection Report
											  </label>
											</div>
										</div>
									</div>
									<div className="row mb-1"> 
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docDeliveryReciept" name="docDeliveryReciept"
                                            checked={dataInput.docDeliveryReciept} 
                                            onChange={handleInputChange}   />
											  <label className="form-check-label" htmlFor="docDeliveryReciept">
												Consignee Copy of Delivery Receipt	
											  </label>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                            <InputElement type="checkbox" className="form-check-input" id="docShortageDamage" name="docShortageDamage"
                                            checked={dataInput.docShortageDamage} 
                                            onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docShortageDamage">
												Description of Shortage or Damage
											  </label>
											</div>
										</div>
									</div>
									<div className="row mb-1"> 
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
											 <InputElement type="checkbox" className="form-check-input" id="docOther1" name="docOther1"
                                                checked={dataInput.docOther1} 
                                                onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docOther1">
												Other1 &nbsp;
                                                <InputElement id="docOther1Desc" name="docOther1Desc" value={dataInput.docOther1Desc || ''} className={dataInputReq.docOther1Desc_ErrorClass}
                                                    onChange={handleInputChange} readOnly={dataInput.other1DescReadonly} 
                                                    // onInvalid={onValidate}
                                                    placeholder='Other1 Description' />
											  </label>
											</div>
										</div>
										<div className="col-md-6 mb-3">
											<div className="form-check form-check-inline">
                                               <InputElement type="checkbox" id="docOther2" name="docOther2" className="form-check-input"
                                                    checked={dataInput.docOther2} 
                                                    onChange={handleInputChange}  />
											  <label className="form-check-label" htmlFor="docOther2">
												Other2 &nbsp;
                                                <InputElement type="text" id="docOther2Desc" name="docOther2Desc" value={dataInput.docOther2Desc || ''} className={dataInputReq.docOther2Desc_ErrorClass}
                                                    onChange={handleInputChange} readOnly={dataInput.other2DescReadonly} 
                                                    // onInvalid={onValidate}
                                                    placeholder='Other2 Description' />
											  </label>
											</div>
										</div>
									</div>
									<hr className="mb-3"/>
										<div className="row">
											<div className="col-md-6 mt-1">
												Please Upload Supporting Documents: 
											</div>
                                            <div className="col-md-6 mt-1">
                                            {/* <InputElement type="file" id="uploadedDocs" name="uploadedDocs" value={dataInput.uploadedDocs || ''} className="form-control"
                                                onChange={handleInputChange} 
                                                placeholder='Upload File' /> */}
                                                <div className="input-group mb-3">
                                                    <input type="file" name="file" className="form-control-InputFile" onChange={readAllfiles} multiple
                                                        id="uploadedDocs" aria-describedby="uploadedDocsClear" aria-label="Upload" />
                                                    <button className="btn btnInpFile" type="button" id="uploadedDocsClear"
                                                        onClick={clearSupportingDocs}><i className="fas fa-times-circle"></i></button>
                                                </div>
											</div>
										</div>
								</div>							
								</div>
						</div>
                        <hr className="mb-3"/>
                            <div className="row pt-1">
										<div className="offset-6 col-md-3">
											<button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={clearPage} ><i className="fas fa-times-circle"></i>  Clear Form</button>
										</div>
										<div className="col-md-3">
											<button className="btn btn-primary btn-block pull-right" type="button" 
                                            onClick={submitForm}
                                            >Submit Claim &nbsp;<i className="fas fa-check-circle"></i>  </button>
										</div>
									</div>
                                    {/* {JSON.stringify(dataInput)} */}
                                    {/* {JSON.stringify(dataInputReq)} */}

							<hr className="mb-3"/>
							<div className="row">
								<div className="col-md-12">
                                    <p>Please email supporting documentation to: <a href="mailto:claimsdept@freightforce.com">claimsdept@freightforce.com</a><br/>
or mail to: P.O. BOX 1058 La Mirada, CA. 90637-1058</p>

<h6>Freight Force, Inc.</h6>
14445 Alondra Blvd.<br/>
La Mirada, CA 90638
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
  )
}

export default CargoClaimForm