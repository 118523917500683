import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { HashLoader } from 'react-spinners';
import InputElement from "../../../../formControls/InputElementMsg";
import InputEle from "../../../../formControls/InputElement";
import { useEffect } from 'react';
import DataAccessorialEdit from '../Data/DataAccessorialEdit';
const AccessorialsEditModel = (props) => {

    const { formEditData, formEditDataReq, handleEditInputChange,  onSubmitEditAccessorial, onValidateEditAccessorial, clearEditAccessorialForm, setFormEditDataObj } = DataAccessorialEdit();

    
      useEffect(() => {
        // console.log("in use")
        clearEditAccessorialForm();
        setFormEditDataObj(props.editData);
                //setFormEditDataObj(prevData => ([...prevData, ...props.editData])); // if use state is not effected use this (trail and error)
      },[props.editData])
      
      const handleEdit = () => {
          //console.log("fd1",JSON.stringify(formEditData));
         var isValidationReqEditAccessorial = onSubmitEditAccessorial();
          console.log("edit acc Record validation:"+isValidationReqEditAccessorial)
             
         if (!isValidationReqEditAccessorial) {
            props.updateRecord(formEditData);
         }
      }

      const closeEdit = () => {
        clearEditAccessorialForm();
        props.handleClose();
      }

    return (
        <>
            <Modal show={props.show} onHide={closeEdit}
                size="lg" // for large modal box
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"} // not to close popup when  mouse clicked outside of popup
                keyboard={false} // not to close popup on escape key press
            >
                {props.innerLoading &&
                    <div className="modalLoadingOverlay">
                        <div className="modalLoadingSpinner">
                            <HashLoader color={'#1E73BE'} loading={props.innerLoading} size={35} />
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    <Modal.Title>Edit Accessorials for {props?.editData?.code} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>{JSON.stringify(formEditData)}</div>  */}
                    <Form id="accessEdit">
                        <div className="row">
                            <div className="col-md-4">
                                <label> Current Require Authorization : &emsp;&emsp;<strong className="embossed">{props?.editData?.requireAuthorization}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label> Current Rate : &emsp;<strong className="embossed">{props?.editData?.rate}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label> Current Minimum : &emsp;<strong className="embossed">{props?.editData?.minimum}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                            <label> New Req Auth :</label> 
                            </div>
                            <div className="col-md-2 dflex">
                                <div className="custom-control custom-radio custom-control-inline">
                                    <InputEle type="radio" id="newrequireAuthorizationYes" name="newrequireAuthorization"  
                                        checked={formEditData.newrequireAuthorization === "Yes"} value="Yes"
                                        onChange={handleEditInputChange}  validation="requiredRadio" onInvalid={onValidateEditAccessorial}
                                        className={formEditDataReq.newrequireAuthorization_ErrorClass} />
                                    <label className="custom-control-label" htmlFor="newrequireAuthorizationYes">Yes</label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <InputEle type="radio" id="newrequireAuthorizationNo" name="newrequireAuthorization"  
                                        checked={formEditData.newrequireAuthorization === "No"} value="No"
                                        onChange={handleEditInputChange}  validation="requiredRadio" onInvalid={onValidateEditAccessorial}
                                        className={formEditDataReq.newrequireAuthorization_ErrorClass} />
                                    <label className="custom-control-label" htmlFor="newrequireAuthorizationNo">No</label>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <label> New Rate :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newrate" name="newrate" className={formEditDataReq.newrate_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='New Rate' value={formEditData.newrate}/>
                            </div>
                            <div className="col-md-2">
                                <label> New Minimum :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newminimum" name="newminimum" className={formEditDataReq.newminimum_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='New Minimum' value={formEditData.newminimum}/>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                <label> Current Maximum :&emsp;<strong className="embossed">{props?.editData?.maxmium}</strong></label>
                            </div>
                            <div className="col-md-4 ">
                                <label>PreLb1 :&emsp;<strong className="embossed">{props?.editData?.perLb1}</strong>&emsp; PerLbRate1 :&emsp; <strong className="embossed">{props?.editData?.perLbRate1}</strong>  </label>
                            </div>
                            <div className="col-md-4 ">
                                <label>PreLb2 :&emsp; <strong className="embossed">{props?.editData?.perLb2}</strong>&emsp; PerLbRate2 :&emsp; <strong className="embossed">{props?.editData?.perLbRate2}</strong>  </label>
                            </div>

                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-2 pr-0">
                                <label>New Maximum : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newmaximum" name="newmaximum" className={formEditDataReq.newmaximum_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='New Maximum' value={formEditData.newmaximum}/>
                            </div>
                            <div className="col-md-2 pr-0">
                                <label> New  PerLb Rate1 :  </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newperLbRate1" name="newperLbRate1" className={formEditDataReq.newperLbRate1_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='PerLb Rate 1' value={formEditData.newperLbRate1} />
                            </div>
                            <div className="col-md-2 pr-0">
                                <label>New PreLb Rate2 :  </label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newperLbRate2" name="newperLbRate2" className={formEditDataReq.newperLbRate2_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='PerLb Rate 2' value={formEditData.newperLbRate2}/>
                            </div>

                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                <label>Current Base Amount :&emsp; <strong className="embossed">{props?.editData?.baseAmount}</strong></label>
                            </div>
                            <div className="col-md-4 ">
                                <label>Current Detention Interval :&emsp;<strong className="embossed">{props?.editData?.deInterval}</strong></label>
                            </div>
                            <div className="col-md-4 ">
                                <label>Current Calc Detention Interval :&emsp;<strong className="embossed">{props?.editData?.calcDeInterval}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-2">
                                <label>New Base Amount:</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newbaseAmount" name="newbaseAmount" className={formEditDataReq.newbaseAmount_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='Base Amount' value={formEditData.newbaseAmount}/>
                            </div>
                            <div className="col-md-2">
                                <label>New Detention Interval :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newdeInterval" name="newdeInterval" className={formEditDataReq.newdeInterval_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='Detention Interval' value={formEditData.newdeInterval}/>
                            </div>
                            <div className="col-md-2">
                                <label>New Calc Detention Interval :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElement id="newcalcDeInterval" name="newcalcDeInterval" 
                                    className={formEditDataReq.newcalcDeInterval_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditAccessorial}
                                    placeholder='Calc Detention Interval' value={formEditData.newcalcDeInterval}/>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                        
                    <Button variant="danger" onClick={closeEdit}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AccessorialsEditModel