import axios from 'axios';
import {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {getLinkCustomer,getLinkCarrier} from './../../../restAPI/CorporateCenterLinkRest'
const DataLinkCustomerAccounts = () => {
    const  [initFiltersInput] = [{
        accountType : '',
        from : '',
        to : '',
        showTable: false
    }];

    const [initFiltersReq] = [{
        accountType:    true,
        accountType_ErrorClass : 'custom-control-input',
        from:    true,
        from_ErrorClass : 'form-control',
        to:    true,
        to_ErrorClass : 'form-control',
    }];

    const [filtersInput, setFiltersInput] = useState(initFiltersInput);
    const [filtersReq, setFiltersReq] = useState(initFiltersReq);

    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);

    const onSubmitForm = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = (key==="accountType" ? "custom-control-input" : "form-control");
                var errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        } 

        // setShowDataLoading(true);
        // loadReportData();
        let reqObj = { ...filtersReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        // console.log("c="+JSON.stringify(reqObj))
        validationReqFlag = Object.values(reqObj).includes(true);
        // console.log("validationReqFlag=" + validationReqFlag)
        return validationReqFlag;
            
    }

    

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = (key==="accountType" ? "custom-control-input" : "form-control");
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }

      let userProfileData = useSelector((state) => state.usr)
      const loadReportData = () => {
        //   let custCode = userProfileData.custCode;
        //   let userId = userProfileData.userId;
          let accessLevel = userProfileData.permissionsLevel;
          
            getLinkCustomerData();
          
          setFiltersInput({...filtersInput, showTable: true});
      }

      const getLinkCustomerData = () =>{
        getLinkCustomer()
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
               // console.log(JSON.stringify(reponseDate))
                setReportData([...reponseDate]);
                
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const handleInputChange = (name, value) => {
        setFiltersInput({...filtersInput, [name]: value});
    }

    const clearForm = () => {
        setFiltersInput(initFiltersInput);
        setFiltersReq(initFiltersReq);
    }

    useEffect(() => {
        refreshData();
        // console.log("in..")
    }, [])

    const refreshData = () => {
        setShowDataLoading(true);
        loadReportData();
    }

    return {
        handleInputChange,
        onSubmitForm,
        onValidateFilters,
        loadReportData,
        refreshData,
        filtersInput,
        clearForm,
        filtersReq,
        reportData,
        showDataLoading
        
    }
}

export default DataLinkCustomerAccounts;