import React ,{useEffect} from "react";
import { useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";
import corridorImg from '../../../assets/img/img_i5corridor.jpg'

const I5Corridor = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
      const userProfile = useSelector((state) => state.usr)
      const navigate = useNavigate();
      const validate = () =>{
        userProfile.isValid ? navigate("/freightQuote") :  navigate("/loginRequired")
      }
      
      
    return (
        <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">I-5 CORRIDOR</h4>
                        </div>
						<div className="row">
							<div className="col-lg-5 col-md-12 mb-4">
							<img src={corridorImg} className="img-fluid" alt="I-5 Corridor" />
							</div>
							<div className="col-lg-7 col-md-12 text-justify">
							<p>Don't rely on national ground road feeder providers to get your shipments in and out of Southern California, the Pacific Northwest and Vancouver Canada. The Freight Force I-5 Corridor Linehaul runs daily Monday through Friday giving you the single carrier option to route directly to and from our Approved Motor Carrier locations in SAN, LAX, SFO, PDX, SEA, and YVR. </p>
                            <p>Book your I-5 Corridor shipment directly from our website 
                                <a href="http://www.freightforce.com" target="_blank" rel="noopener"> www.freightforce.com </a> securing your capacity up and down the West Coast. When your shipments ride the I-5 with Freight Force you have the peace of mind that your needed services of drop, recovery, pickup or delivery are secured and handled solely by Freight Force. For schedules and rates go to the Services tab on the Freight Force home page and click on “Regional Linehaul”.</p>
							
							<p>
                                <u> For more information: </u>
                                <br/>
                                Email: <a href="mailto:linehaul@freightforce.com"> linehaul@freightforce.com </a>
                                <br/>
                                Phone: (714) 995-9300
                                <br/>
                                <a href="" onClick={validate}  target="_blank" rel="noopener">Click here for quote</a>
                            </p>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default I5Corridor;