import React, {  useState, useEffect } from "react";
import { useSelector , useDispatch} from 'react-redux';
import { useNavigate ,useLocation } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { processBookQuoteReq } from "../restAPI/BookQuoteRest";
import { setQuickQuote } from "../../../store/actions/actionTypes";
import BookLayer1 from "./BookLayer1"
import BookLayer2 from "./BookLayer2"
import BookLayer3 from "./BookLayer3"
import BookLayer4 from "./BookLayer4"
import RRLayer from "./RRLayer"
import DataBooking from "./data/DataBooking"
import moment from "moment";

const FreightBooking = (props) => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const { onChangeBookHandler, updateDimTotals, onConfirmBooking, onValidateBookHandler, 
        updateQuoteRequiredState, updateQuoteRequiredStateFromHis,validateAddress,updateQuickQuoteObject, 
        quickQuote, stateList, quickQuoteRequired, packageTypeList, labelStyleList,labelCountList } = DataBooking();
    let quickQuoteRes = useSelector((state) => state.qqt);
    const navState = useLocation();
    const dispatch = useDispatch();
    
    let isShipperInfoEdit ,isConsigneeInfoEdit , specialPickup, specialDelivery, dimWeightTotal ,totalPcs ,grossWeight, fffFrom = false, fffTo = false
    , hawb , mawb ,  ref, isRoadRunner;
    let quoteHis = false , isQuoteHis = false;
    let shipperInfoFound = null,consigneeInfoFound = null;
     //console.log("qqr from selector ="+JSON.stringify(quickQuoteRes));
     const fffTermList = JSON.parse(localStorage.getItem("fffTermList"));
     const lhTermList = JSON.parse(localStorage.getItem("lhTermList"));
     const rrTermList = JSON.parse(localStorage.getItem("rrTermList"));
    if (navState.state && navState.state.quoteHis ){
     //console.log("came from  quoteHistory is =");
        quoteHis = navState.state.quoteHis;
        isQuoteHis = true;
       // console.log("quoteHis is ="+JSON.stringify(quoteHis));
        // quickQuoteRes = navState.state.quoteBookData;
        specialPickup = quickQuoteRes.shipment.isSpecialPickup;
        specialDelivery = quickQuoteRes.shipment.isSpecialDelivery;
        dimWeightTotal = quickQuoteRes.shipment.dimWeight;

        let spcode = quickQuoteRes.shipper.postalCode;
        let cpcode = quickQuoteRes.consignee.postalCode;
        
        if(quickQuoteRes.isRoadRunner){
            isRoadRunner = true;
            shipperInfoFound = rrTermList.find(eachobj => eachobj.PostalCode === spcode);
            consigneeInfoFound = rrTermList.find(eachobj => eachobj.PostalCode === cpcode);
        }else{
            shipperInfoFound = lhTermList.find(eachobj => eachobj.PostalCode === spcode);
            consigneeInfoFound = lhTermList.find(eachobj => eachobj.PostalCode === cpcode);
            if( !shipperInfoFound ){
                shipperInfoFound = fffTermList.find(eachobj => eachobj.PostalCode === spcode)
            }
            if( !consigneeInfoFound ){
                consigneeInfoFound = fffTermList.find(eachobj => eachobj.PostalCode === cpcode)
            }
        }

        //console.log("linhaul"+JSON.stringify(lhTermList))
        isShipperInfoEdit = ( shipperInfoFound ? false : true );
        isConsigneeInfoEdit = ( consigneeInfoFound ? false : true );

        let isLineHaul = quickQuoteRes.isLinehaul;
        if( isLineHaul ){
            fffFrom = false;
            fffTo = false;
        }else{
            if( shipperInfoFound ){
                fffFrom = true;
            }else{
                fffFrom = false;
            }
            if( consigneeInfoFound ){
                fffTo = true;
            }else{
                fffTo = false;
            }
        }

        totalPcs = quickQuoteRes.shipment.qty;
        grossWeight = quickQuoteRes.shipment.weight;
        hawb = quickQuoteRes.shipment.hawb;
        mawb = quickQuoteRes.shipment.mawb;
        ref = quickQuoteRes.shipment.custReference;
      }else{
        isQuoteHis = false;
        isShipperInfoEdit = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.shipCodeFromEntry : '';
        isConsigneeInfoEdit = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.shipCodeToEntry : '';
        specialDelivery = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.specialDelivery : '';
        specialPickup = quickQuoteRes && quickQuoteRes.shipment ? quickQuoteRes.shipment.isSpecial : '';
        dimWeightTotal = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.dimWeightTotal : '';
        fffFrom = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.fffFrom : '';
        fffTo = quickQuoteRes && quickQuoteRes.addValue ? quickQuoteRes.addValue.fffTo : '';
        //totalPcs = quickQuoteRes && quickQuoteRes.quote ? quickQuoteRes.quote.rateQuote.qty : '';
        totalPcs = quickQuoteRes && quickQuoteRes.shipment ? quickQuoteRes.shipment.qty : '';
        //grossWeight = quickQuoteRes && quickQuoteRes.quote ? quickQuoteRes.quote.rateQuote.weight : '';
        grossWeight = quickQuoteRes && quickQuoteRes.shipment ? quickQuoteRes.shipment.weight : '';
        if(quickQuoteRes.isRoadRunner) 
        isRoadRunner = true;
      }
    
    
    
    const isDimensionsInfoEdit = false;
    // console.log("quickQuoteRes="+JSON.stringify(quickQuoteRes));
    // console.log("quickQuote in fb="+JSON.stringify(quickQuote));

    useEffect(() => {
        let mounted = true;
       // console.log("in ue.."+mounted)
        if (mounted) {    
            let shipCmpName = quickQuoteRes?.shipper?.companyName;
            let consCmpName = quickQuoteRes?.consignee?.companyName;
            updateDimTotals({
                shiCompanyName: (navState?.state?.quoteHis && !shipCmpName && !isShipperInfoEdit)? shipperInfoFound.CompanyName : quickQuoteRes.shipper.companyName,
                consiCompanyName: (navState?.state?.quoteHis && !consCmpName && !isConsigneeInfoEdit)? consigneeInfoFound.CompanyName : quickQuoteRes.consignee.companyName,
                shiAddress:  (navState?.state?.quoteHis && !shipCmpName && !isShipperInfoEdit)? shipperInfoFound.Address1 : quickQuoteRes.shipper.address1,
                consiAddress: (navState?.state?.quoteHis && !consCmpName && !isConsigneeInfoEdit)? consigneeInfoFound.Address1 : quickQuoteRes.consignee.address1,
                shiCity: (navState?.state?.quoteHis && !shipCmpName && !isShipperInfoEdit)? shipperInfoFound.City :quickQuoteRes.shipper.city,
                shiState: (navState?.state?.quoteHis && !shipCmpName && !isShipperInfoEdit)? shipperInfoFound.State :quickQuoteRes.shipper.state,
                shiZipcode: quickQuoteRes.shipper.postalCode,
                shiContact:quickQuoteRes.shipper.contact,
                shiPhone:quickQuoteRes.shipper.phone,
                consiCity: (navState?.state?.quoteHis && !consCmpName && !isConsigneeInfoEdit)? consigneeInfoFound.City : quickQuoteRes.consignee.city,
                consiState: (navState?.state?.quoteHis && !consCmpName && !isConsigneeInfoEdit)? consigneeInfoFound.State : quickQuoteRes.consignee.state,
                consiZipcode: quickQuoteRes.consignee.postalCode,
                consiContact:quickQuoteRes.consignee.contact,
                consiPhone:quickQuoteRes.consignee.phone,
               // notes: quickQuoteRes.shipment.notes,
                shipperNotes: quickQuoteRes.shipment.shipperNotes,
                consigneeNotes: quickQuoteRes.shipment.consigneeNotes,
                pickupInfo: quickQuoteRes.shipment.pickupDate ? new Date(quickQuoteRes.shipment.pickupDate) : new Date(),
                specialPickup: specialPickup,
                piReadyTime: new Date(quickQuoteRes.shipment.pickupOpenTime),
                piCloseTime: new Date(quickQuoteRes.shipment.pickupCloseTime),
                //deliInfo: quickQuoteRes.shipment.deliveryDate ? new Date(quickQuoteRes.shipment.deliveryDate) : moment(new Date()).add(1, 'days').toDate(),
                deliInfo: moment(new Date(quickQuoteRes.shipment.pickupDate ? new Date(quickQuoteRes.shipment.pickupDate) : new Date())).add(3, 'days').toDate(),
                specialDelivery: specialDelivery,
                deliReadyTime: new Date(quickQuoteRes.shipment.deliveryOpenTime),
                deliCloseTime: new Date(quickQuoteRes.shipment.deliveryCloseTime),
                totalPcs:  totalPcs,
                grossWeight: grossWeight,
                dimWeightTotal: dimWeightTotal,
                // hawb: quickQuoteRes.rateQuote.fsc,
                // mawb: quickQuoteRes.rateQuote.fsc,
                // refNo: quickQuoteRes.quoteId,
                fffFrom: fffFrom,
                fffTo: fffTo,
                shipCodeFromEntry:isShipperInfoEdit,
                shipCodeToEntry:isConsigneeInfoEdit	,
                hawb : hawb,
                mawb : mawb,
                refNo : ref        
            });

        }
        return () => mounted = false;
    }, [])// eslint-disable-line

    /*
    useEffect(() => {
        if (quickQuoteRes.shipment.pickupDate) {
            // console.log("isShipperInfoEdit="+isShipperInfoEdit)
            // console.log("isConsigneeInfoEdit="+isConsigneeInfoEdit)
            updateQuoteRequiredState(isShipperInfoEdit, isConsigneeInfoEdit);
        }
    }, [quickQuoteRes.shipment.pickupDate])// eslint-disable-line
*/
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event)
        {
            if( quoteHis ){
                navigate("/freightQuote/quoteProposed", { state: { quoteHis: quoteHis , quoteHisData: quickQuoteRes } });
            }else{
                navigate("/freightQuote/quoteProposed"); 
            }
        });
            
    }, []) // eslint-disable-line


    //console.log(" after Setting state "+JSON.stringify(quickQuote))

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); // eslint-disable-line

    useEffect(() => {
        // page when clicked refresh button
		if( !quickQuoteRes.customer.companyName ){
			// alert("can be redirected")
            setLoading(true)
			// setHideQuoteDiv(true)
			//alert("Donot refresh the page. Changes done will be lost.")
			window.location.href = '/freightQuote';
		}
	}, []) // eslint-disable-line


    const confirmBooking = () => {

        let isValidationReqBooking = onConfirmBooking();

        if (!isValidationReqBooking) {
            setLoading(true);
            processBookQuoteReq(quickQuoteRes, quickQuote, isShipperInfoEdit, isConsigneeInfoEdit, quoteHis, attachments,isQuoteHis)
                .then(quoteReqRes => {
                    if (quoteReqRes instanceof Error) {
                        // console.log("error object is final = " + JSON.stringify(quoteReqRes.response))
                        setErrorStatus({ restError: true, restErrorMessage: quoteReqRes.response.data });
                        setTimeout(() => {
                            setErrorStatus({ restError: false, restErrorMessage: "" });
                          }, 25000);
                    } else {
                        //setErrorStatus({ restError: false, restErrorMessage: '' });
                        //console.log("booking response is==" + JSON.stringify(quoteReqRes.responseData));
                        dispatch(setQuickQuote(quoteReqRes.responseData));
                        navigate("/freightQuote/bookConfirm", { state: { quickQuote: quoteReqRes.responseData, sendEmailStatus:true} }); //{ quickQuote:props.quickQuote}});
                    }
                    setLoading(false);
                })
            // if( res.status==="success" ){
            //     navigate("/bookConfirm", { state: {quickQuote : res.responseData,  }}); //{ quickQuote:props.quickQuote}});
            // }else{
            //     navigate("/bookConfirm", { state: {quickQuote : res.responseData }}); //{ quickQuote:props.quickQuote}});
            // }
        }
    }

    let attachmentList = [];
    const [attachments, setAttachments] = useState([{fileName: null, data: ""}]);
    
      const readAllfiles = (e) => {
        let fileList = e.target.files;
            for(let i=0;i<fileList.length;i++){
                let file = fileList[i];
                 new Promise(resolve => {
                    let baseURL = "";
                    let reader = new FileReader();
                    reader.readAsDataURL(file);    
                    // on reader load somthing...
                    reader.onload = () => {
                      baseURL = reader.result;
                      resolve(baseURL);
                    };
                  }).then(result =>{ 
                    if(result.indexOf("base64,") !== -1)
                    result = result.substring(result.indexOf("base64,")+7);
                    attachmentList.push({fileName:file.name, data:result }
                    );
                            //console.log(attachmentList);
                            setAttachments([...attachmentList])
                }).catch(err =>{console.log(err)})
            }
      };
     
    const clearSupportingDocs = () => {
        document.getElementById("supportingDocs").value="";
        setAttachments([]);
    }

    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'}/>}
            <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-3 pageHead text-uppercase">Quote and Book Online</h4>
                        </div>
                        <form className="needs-validation" name="booking" >
                            <BookLayer1 quickQuote={quickQuote} onChangeBookHandler={onChangeBookHandler}
                                isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit}
                                onValidateBookHandler={onValidateBookHandler} quickQuoteRequired={quickQuoteRequired} />
                            <BookLayer2 quickQuote={quickQuote} stateList={stateList} onChangeBookHandler={onChangeBookHandler}
                                isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit} 
                                onValidateBookHandler={onValidateBookHandler} quickQuoteRequired={quickQuoteRequired} errorStatus={errorStatus}
                                validateAddress = {validateAddress} updateAddress={updateQuickQuoteObject} isRoadRunner={isRoadRunner}/>
                            {/* {isRoadRunner &&
                            <RRLayer quickQuote={quickQuote} onChangeBookHandler={onChangeBookHandler}
                            isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit}
                            onValidateBookHandler={onValidateBookHandler} quickQuoteRequired={quickQuoteRequired}
                            labelStyleList={labelStyleList} labelCountList={labelCountList} />
                            } */}
                            <BookLayer3 quickQuote={quickQuote} onChangeBookHandler={onChangeBookHandler}
                                isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit}
                                onValidateBookHandler={onValidateBookHandler} quickQuoteRequired={quickQuoteRequired} />
                            <BookLayer4 quickQuote={quickQuote} updateDimTotals={updateDimTotals} onChangeBookHandler={onChangeBookHandler}
                                isShipperInfoEdit={isShipperInfoEdit} isConsigneeInfoEdit={isConsigneeInfoEdit}
                                isDimensionsInfoEdit={isDimensionsInfoEdit} confirmBooking={confirmBooking}
                                onValidateBookHandler={onValidateBookHandler} quickQuoteRequired={quickQuoteRequired} readFileData={readAllfiles}
                                clearSupportingDocs={clearSupportingDocs} isRoadRunner={isRoadRunner} packageTypeList={packageTypeList}
                                quoteHis={quoteHis} quoteHisData={quickQuoteRes}
                                />
                            
                        </form>

                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(quickQuote)}</div> */}
                        <hr/>
                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(quickQuoteRequired)}</div>  */}
                    </div>

                </div>
            </div>
        </div>
    );


}

export default FreightBooking;