import React, { useState } from 'react'
import InputElement from '../../../formControls/InputElement';
import TextareaElement from '../../../formControls/TextareaElement';
import DataNotes from './Data/DataNotes';

const Notes = () => {

  const {notesData, notesDataReq, handleInputChange, onValidate, clearForm, onSubmit} = DataNotes();

  const [editNotes, setEditNotes] = useState(false);
  
  const changeEdit = () =>{
    setEditNotes(true);
  }
  const cancelEdit = () =>{
    setEditNotes(false);
    clearForm();
  }
  const submitNotes = ()  =>{
    let isValidationReq = onSubmit();
    if( !isValidationReq ){

    }
  }

  
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mt-4 sopBox2">
          <div className="row">
            <div className="col-md-6">
                <label htmlFor="station">Station</label>
                {!editNotes ?
                <p>{notesData.station}</p> :
                <InputElement id="station" name="station" value={notesData.station || ''} className={notesDataReq.station_ErrorClass}
                onChange={handleInputChange} onInvalid={onValidate} validation="required"
                placeholder='Station' />}
            </div>
          </div>
          <div className="row mt-3">
                <div className="col-md-6">
                    <label htmlFor="tariffNote">Tariff Note</label>
                    {!editNotes ?
                    <p>{notesData.tariffNote}</p> :
                    <TextareaElement id="tariffNote" name="tariffNote" rows="6" value={notesData.tariffNote || ''} className={notesDataReq.tariffNote_ErrorClass}
                    onChange={handleInputChange} onInvalid={onValidate} validation="required" placeholder='Tariff Note' />      }
              </div>
                <div className="col-md-6">
                    <label htmlFor="splDeliveryNote">Special Delivery Note</label>
                    {!editNotes ?
                    <p>{notesData.splDeliveryNote}</p>:
                    <TextareaElement id="splDeliveryNote" name="tariffNote" rows="6" value={notesData.splDeliveryNote || ''} className={notesDataReq.splDeliveryNote_ErrorClass}
                    onChange={handleInputChange} onInvalid={onValidate} validation="required" placeholder='Special Delivery Note' />      }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-6">
                    <label htmlFor="accessorialNote">Accessorial Note</label>
                    {!editNotes ?
                    <p>{notesData.accessorialNote}</p>:
                    <TextareaElement id="accessorialNote" name="accessorialNote" rows="6" value={notesData.accessorialNote || ''} className={notesDataReq.accessorialNote_ErrorClass}
                    onChange={handleInputChange} onInvalid={onValidate} validation="required"
                    placeholder='Accessorial Note' />}
                </div>
                <div className="col-md-6">
                    <label htmlFor="airportTransferNote">Airport Transfer Note</label>
                    {!editNotes ?
                    <p>{notesData.airportTransferNote}</p>:
                    <TextareaElement id="airportTransferNote" name="airportTransferNote" rows="6" value={notesData.airportTransferNote || ''} className={notesDataReq.airportTransferNote_ErrorClass}
                    onChange={handleInputChange} onInvalid={onValidate} validation="required"
                    placeholder='Airport Transfer Note' />}
                </div>
            </div>
                  
            <div className="row mt-3">
                <div className="col-md-6">
                    <label htmlFor="linehaulNote">Linehaul Note</label>
                    {!editNotes ?
                    <p>{notesData.linehaulNote}</p>:
                    <TextareaElement id="linehaulNote" name="linehaulNote" rows="6" value={notesData.linehaulNote || ''} className={notesDataReq.linehaulNote_ErrorClass}
                    onChange={handleInputChange} onInvalid={onValidate} validation="required"
                    placeholder='Linehaul Note' />}
                </div>
                <div className="col-md-6">
                    <label htmlFor="citiesServedNote">Cities Served Note</label>
                    {!editNotes ?
                    <p>{notesData.citiesServedNote} </p>
                    :
                    <TextareaElement id="citiesServedNote" name="citiesServedNote" rows="6" value={notesData.citiesServedNote || ''} className={notesDataReq.citiesServedNote_ErrorClass}
                      onChange={handleInputChange} onInvalid={onValidate} validation="required" placeholder='Procedures' />      }
                </div>
            </div>
            <div className="row mt-3 mb-3">
                {!editNotes ?
                <div className="col-md-2">
                    <button className="btn btn-primary btn-block btn-sm" onClick={changeEdit} type="button" >
                       Edit &nbsp;<i className="fas fa-edit"></i>  </button>
                </div>
                :  
                <>
                <div className="col-md-2">
                  <button className="btn btn-primary btn-block btn-sm" type="button" onClick={submitNotes}> Update &nbsp;<i className="fas fa-edit"></i>  </button>
                </div>
                <div className="col-md-2">
                  <button className="btn btn-secondary btn-block btn-sm p-1" type="button" onClick={cancelEdit}> Cancel &nbsp;<i className="fas fa-times"></i>  </button>
                </div>
                </>
                }
            </div>
        </div>
      </div>
      <hr/>
      <div className="row">
        <div className="col-md-12 text-justify mt-4">
          <strong>
            *** Please note the following Tariff information is hard coded on the Report and cannot be changed on this page ***  
          </strong>
          <p>
            Fuel surcharge may apply.
          </p>
          <p>
          Check <a href='http://www.freightforce.com' target="_new"> www.freightforce.com </a> for current rates or for updates and/or changes to rates and cites serviced.
          All shipments may be dimmed.
          </p>
          <p>
          Published rates and areas are for either a pickup at the shipper and transport back to the Freight Force Inc. contracted agent station dock, or for an airport area recovery from an inbound carrier and delivery to the consignee only. The published rates are not for direct shipper to consignee service. Additional accessorials may apply including transfers, drops, fuel surcharge, etc. If FF's contracted agent does not perform the pickup and a shipment is transfered into the warehouse/dropped by an outside carrier, an Area A or appropriate carrier address area charge based on zip code will apply for the delivery to the airport rather than an airport drop charge.
          </p>
          <p>
          Rates calculated per housebill. Regular deliveries are at carrier convenience by 17:00.
          </p>
          <p>
          If available, Tractor Trailer Surcharge may apply to shipments that are: Over 10,000 lbs,
          </p>
          <p>
          or Greater than 96" tall, or 300" long, or 92" wide, or require an ICC Bumper.
        </p>
      </div>
    </div>
  </div>
  )
}

export default Notes