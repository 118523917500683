import React, { useEffect } from 'react'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { buildTariffCharges } from './TariffTabsScripts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const TariffCharges = (props) => {
    const tData = props.tariffRate;
    props.setTabsHtmlData( 1, buildTariffCharges(tData) );

    useEffect(() => {
        let table = $("#tariffTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
              "autoWidth": false,
              "bAutoWidth": false,
            "data" : tData,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'TariffCharges',
                  title: 'Tariff Charges',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'TariffCharges',
                  title: 'Tariff Charges',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'TariffCharges',
                  title: 'Tariff Charges',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'TariffCharges',
                  title: 'Tariff Charges',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],


            "columns": [
                { "data" : "areato" },
                { "data" : "RATEMIN" },
                { "data" : "RATE100" },
                { "data" : "RATE500" },
                { "data" : "RATE1000" },
                { "data" : "RATE2000" },
                { "data" : "RATE3000" },
                { "data" : "RATE5000" },
                { "data" : "RATE10000" },
                { "data" : "RATE15000" },
                { "data" : "RATE20000" },
                { "data" : "RATECAP" }
            ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "8.3%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[tData]);

  return (
    <div className="row mb-4">
        <div className="col-md-10 offset-1">
            <div id="dataTariffTable" className='mt-4'>
            <table id="tariffTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Area</th>
                        <th>Min</th>
                        <th>100</th>
                        <th>500</th>
                        <th>1000</th>
                        <th>2000</th>
                        <th>3000</th>
                        <th>5000</th>
                        <th>10000</th>
                        <th>15000</th>
                        <th>20000</th>
                        <th>CapRate</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            </div>
        </div>
    </div>
  )
}

export default TariffCharges