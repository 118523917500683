import React,{useState,useEffect} from 'react'
import InputElement from '../../formControls/InputElement';
import { shipmentAcceptReject,shipmentDecrypt } from '../restAPI/UtilsRest';

const CarrierConfirmation = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		const queryParams = new URLSearchParams(window.location.search)
  		const shipment = queryParams.get("shipmentCode")
		shipmentDecode(shipment);
	  }, []);
	  const  [filtersInput, setFiltersInput] = useState({
        orderId : '',
        status: false,
		reason: '',
    });
	const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); 
	const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
	const shipmentAccept = () =>{
		// console.log(JSON.stringify(filtersInput))
		setFiltersInput({...filtersInput,"status":true})
		shipmentAccRej(true);
	}
	const shipmentReject = () =>{
		shipmentAccRej(false);
	}

	  const shipmentAccRej = (status) =>{
		shipmentAcceptReject(filtersInput.bookingNumber,status,filtersInput.reason)
			.then(response => {
				if (response instanceof Error) {
					// console.log("error object in Accept reject of shipment " + JSON.stringify(response))
					//setErrorStatus({ restError: true, restErrorMessage: response.message });
					//setReportData([]);
					setErrorStatus({ restError: true, restErrorMessage: response.message });
					setSaveMessage({ showMsg: true, status: 'error', message: response.message });

					  } else {
					///setReportData([...response]);
					setErrorStatus({ restError: false, restErrorMessage: '' });
					setSaveMessage({ showMsg: true, status: 'msg', message: response });

					if(status){
						// console.log("shipment Accepted is successfull");
					}else{
						// console.log("shipment Reject is successfull");
					}
					}                           
		})  
		.catch(err => {
			// console.log("error  in calling Shipment Accept reject " + JSON.stringify(err))
		})
		setTimeout(()=>{
			setSaveMessage({ showMsg : false, status : '', message: '' });
		},5000);
	}
		const shipmentDecode = (decryptCode) =>{
			shipmentDecrypt(decryptCode)
				.then(response => {
					if (response instanceof Error) {
						// console.log("error object in decryptment of the shipment " + JSON.stringify(response))
						//setErrorStatus({ restError: true, restErrorMessage: response.message });
						//setReportData([]);
						  } else {
						///setReportData([...response]);

						// console.log("Shipment is decrypted"+response.bookingNumber);
						setFiltersInput({...filtersInput, ...response})
						// console.log("after setting  is decrypted"+JSON.stringify(filtersInput));
						}                           
			})  
			.catch(err => {
				// console.log("error  in calling Shipment shipmentDecode " + JSON.stringify(err))
			})
	  }
	  const handleInputChange = (name, value) => {
		//console.log("selected valus name"+name+"--"+value);
		 setFiltersInput({...filtersInput, [name[0]]: value});
	 }
 console.log(JSON.stringify(filtersInput))
  return (
    <section className="mt-180 mb-4">
        <div className="container">
		<form >
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead">Notice To Customer</h4>
                        </div>
						<div className="row">
							<div className="col-lg-6 col-md-12 mb-3 border-right">
								<div className="row">
									<div className="col-md-4 mb-2">
										<button className="btn btn-success btn-block" type="button" onClick={shipmentAccept} > ACCEPTED </button>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<table>
											<tr>
												<td>
												<label className='QuoteLabel'>Online Order Number  </label>
												</td>
												<td>
												<label className='QuoteLabel'>	: &nbsp;&nbsp;{filtersInput.bookingNumber}</label>
												</td>
											</tr>
											<tr>
												<td>
												<label className='QuoteLabel'>Customer Code  </label>
												</td>
												<td>
												<label className='QuoteLabel'>	: &nbsp;&nbsp;{filtersInput.customer? filtersInput.customer.custCode?filtersInput.customer.custCode:'' :''}</label>
												</td>
											</tr>
											<tr>
												<td>
												<label className='QuoteLabel'>Company Name  </label>
												</td>
												<td>
												<label className='QuoteLabel'>	: &nbsp;&nbsp;{filtersInput.customer? filtersInput.customer.companyName?filtersInput.customer.companyName:'':''}</label>
												</td>
											</tr>
										</table>

									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div>Confirmation that you will action the shipment same day as booked.</div>
										<div className='QuoteLabel text-left text-primary'><u className="colorRed">Shipment MUST also be accepted from the EDI transmission sent to your Crown dispatch screen </u></div>
									</div>
								</div>
							</div>
							
							<div className="col-lg-6 col-md-12 mb-3">
								<div className="row">
									<div className="col-md-4 mb-2">
										<button className="btn btn-danger btn-block" type="button" onClick={shipmentReject} > NOT ACCEPTED </button>
									</div>
								</div>
								<div className="row">
									<div className="col-md-5 mb-1">
										<label className='QuoteLabel'>Reason Order was not Accepted : </label>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pl-5">
											<div className="custom-control custom-radio">
												<InputElement type="radio" name='reason' value="Incomplete Rate" 
												   onChange={handleInputChange} 
												   checked={filtersInput.reason === "Incomplete Rate"}
												id='reason_inc_rate' className='custom-control-input' />
												<label className="custom-control-label" htmlFor="reason_inc_rate">INCOMPLETE RATE</label>
											</div>
											<div className="custom-control custom-radio">
												<InputElement type="radio" name='reason' value="No Service Available Today" 
												onChange={handleInputChange} 
												checked={filtersInput.reason === "No Service Available Today"}
												id='reason_service_not_avail' className='custom-control-input' />
												<label className="custom-control-label" htmlFor="reason_service_not_avail">NO SERVICE AVAILABLE TODAY</label>
											</div>
											<div className="custom-control custom-radio">
												<InputElement type="radio" name='reason' value="Please call for options on this request" 
												onChange={handleInputChange} 
												checked={filtersInput.reason === "Please call for options on this request"}
												id='reason_call_for_options' className='custom-control-input' />
												<label className="custom-control-label" htmlFor="reason_call_for_options">PLEASE CALL FOR OPTIONS ON THIS REQUEST</label>
											</div>
									</div>
								</div>
							</div>
						</div>	 
                    </div>
                </div>
            </div>
			{saveMessage.showMsg &&
                    <div className="row mb-3">
                        <div className="col-md-12 text-center text-bold">
                            {saveMessage.status === "error" ?
                                <div className="alert alert-danger">
                                    <i className="fas fa-times-circle"></i> 
                                    {saveMessage.message}
                                </div>
                                :
                                <div className="alert alert-primary">
                                    {saveMessage.message}
                                </div>
                            }
                            {/* {saveMessage.status === "success" &&
                                <div className="alert alert-success">
                                    <i className="fas fa-check-circle"></i> Data save success
                                </div>
                            } */}
                        </div>
                    </div>
                }
			</form>
        </div>
    </section>
  )
}

export default CarrierConfirmation