import  { useEffect } from "react";
import { logoutUser, removeUser } from './store/actions/actionTypes';
import {  useDispatch} from 'react-redux'

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];


const AppLogout = ({ children }) => {
  let timer;
  const dispatch = useDispatch();
useEffect(() => {
  Object.values(events).forEach((item) => {
    window.addEventListener(item, () => {
      resetTimer();
      handleTimer();
    });
  });
}, []);

const resetTimer = () => {
  if (timer) clearTimeout(timer);
};

const handleTimer = () => {
  timer = setTimeout(() => {
    resetTimer();
    Object.values(events).forEach((item) => {
      window.removeEventListener(item, resetTimer);
    });
    logoutAction();
  }, 900000); // 15 mins logout time //600000 -- is 10 mins 
};

const logoutAction = () => {
  //localStorage.clear();
  if(localStorage.getItem("userProfile")!==null){
  dispatch(removeUser());
   dispatch(logoutUser())
    //var hostname= window.location.hostname;
  var origin = window.location.origin;
  //var port = window.location.port;
   window.location.href = origin;
  }
  //window.location.pathname = "/";
  
};
  return children;
};

export default AppLogout;