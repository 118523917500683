import React,{useEffect} from 'react'
import residentialImg from '../../../assets/img/Residentual-Delivery.jpg'

const Residential = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">RESIDENTIAL</h4>
                        </div>
						<div className="row mb-3">
							<div className="col-md-12 text-justify">
							<p>Freight Force, Inc.'s network of approved motor carriers provides residential service capabilities in more than 64 major markets covering the majority of zip codes in the U. S.   These delivery services include curbside, threshold, or first dry spot, and in many cases, room of choice options for all weight deliveries. Appointments can be made for residential deliveries by the approved Freight Force motor carrier with flexible delivery times.</p>
							<p>Two-man delivery teams are available and we can also offer complete packing and unpacking capabilities.  Tracking is available for our Residential Delivery services either via email or by logging onto the Freight Force Home Page at 
							 <a href="http://www.freightforce.com" target="_blank" rel="noopener"> www.freightforce.com</a>.</p>
							<p className="mb-1">When you choose the Freight Force network of approved carriers for your residential shipping needs you are guaranteed the following:
							</p>
								<ul className="regList">
									<li>The highest level of service standards</li>
									<li>A well trained professional staff</li>
									<li>A highly competitive rate</li>
								</ul>
							
							</div>
						</div>
						<div className="row">
						<div className="col-lg-6 col-md-12">
							<img src={residentialImg} className="img-fluid mb-4" alt='Residential'/>
							</div>
							<div className="col-lg-6 col-md-12">
							<h6>Complete list of services available:</h6>
							<ul className="regList">
								<li>Curbside</li>
								<li>First Dry Location outside of home</li>
								<li>Threshold</li>
								<li>Room of Choice inside of home</li>
								<li>Week day deliveries between 08:00 and 17:00</li>
								<li>Weekend deliveries between 08:00 and 17:00</li>
								<li>4 hour delivery windows</li>
								<li>Same Day Deliveries</li>
								<li>Trucks equipped with:
									<ul>
										<li>Lift gates</li>
										<li>Pallet jacks</li>
										<li>Appliance dollies</li>
									</ul>
								</li>
								<li>Cargo Vans available where street access is restricted</li>
								<li>Extra Man deliveries</li>
								<li>Unpack and Debris Removal</li>
								<li>Appliance and Television Deliveries</li>
								<li>Furniture Deliveries including light assembly
									<ul>
										<li>Drivers carry tools</li>
									</ul>	
								</li>
							</ul>
							<p>Contact the local Freight Force approved motor carrier or the Sales and Marketing department at the Freight Force Corporate office at <a href="mailto:sales@freightforce.com">sales@freightforce.com</a>.</p>
							</div>
							
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Residential