import React from "react";
import InputElement from "../../formControls/InputElement";
import DatePickerElement from "../../formControls/DatePickerElement";
import TimeElement from "../../formControls/TimeElement";
import moment from "moment";
const DirectBook3 = (props) => {
    return (
        <div>

            <div className="row">
                <div className="col-md-2 mb-3 text-bold">
                    <h6>PICKUP INFO</h6>
                </div>
                <div className="col-md-4 mb-3 text-bold">
                    <h6>PICKUP WINDOW</h6>
                </div>
                <div className="col-md-2 mb-3 text-bold">
                    <h6>DELIVERY INFO</h6>
                </div>
                <div className="col-md-4 mb-3 text-bold">
                    <h6>DELIVERY WINDOW</h6>
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 mb-2">
                    <label >Pickup Info</label>
                    <DatePickerElement id="pickupInfo" name="pickupInfo"
                        minDate={new Date()}
                        value={props.quickBook.pickupInfo}
                        selected={props.quickBook.pickupInfo}
                        onChange={props.onChangeBookHandler} checkDateValidation={true}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.pickupInfo_ErrorClass}
                        maxDate={moment(new Date()).add(7, 'days').toDate()}
                    />

                    <div className="invalid-feedback">
                        required.
                    </div>
                    {/* <div className="custom-control custom-checkbox mt-2">
                        <InputElement type="checkbox" className="custom-control-input" id="specialPickup" name="specialPickup"
                            checked={props.quickBook.specialPickup}
                            onChange={props.onChangeBookHandler} />
                        <label className="custom-control-label" htmlFor="specialPickup">Special Pickup</label>
                    </div> */}
                    {/* <div>
                        <InputElement  type="checkbox" className="chkboxSize" id="specialPickup" name="specialPickup" 
                        checked={props.quickBook.specialPickup} onChange={props.onChangeBookHandler} />
                         <div className="d-inline align-top">   Special Pickup </div>
                        </div> */}
                </div>
                <div className="col-md-2 mb-2">
                    <label >Ready Time</label>
                    <TimeElement id="piReadyTime" name="piReadyTime"
                        value={props.quickBook.piReadyTime ? new Date(props.quickBook.piReadyTime) : null}
                        selected={props.quickBook.piReadyTime ? new Date(props.quickBook.piReadyTime) : null}
                        onChange={props.onChangeBookHandler}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.piReadyTime_ErrorClass}
                        minTime={new Date().setHours(8, 0, 0, 0)}
                        maxTime={props.quickBook.piCloseTime ? 
                            moment(new Date(props.quickBook.piCloseTime)).subtract(5, 'minutes').toDate()
                             : new Date().setHours(17, 0, 0, 0)}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label >Close Time</label>
                    <TimeElement id="piCloseTime" name="piCloseTime"
                        value={props.quickBook.piCloseTime ? new Date(props.quickBook.piCloseTime) : null}
                        selected={props.quickBook.piCloseTime ? new Date(props.quickBook.piCloseTime) : null}
                        onChange={props.onChangeBookHandler}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.piCloseTime_ErrorClass} 
                        minTime={props.quickBook.piReadyTime ? 
                            moment(new Date(props.quickBook.piReadyTime)).add(5, 'minutes').toDate()
                             : new Date().setHours(8, 5, 0, 0)}
                        maxTime={new Date().setHours(17, 5, 0, 0)}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label >Delivery Info</label>
                    <DatePickerElement id="deliInfo" name="deliInfo"
                        minDate={props.quickBook.pickupInfo ?
                            moment(new Date(props.quickBook.pickupInfo)).toDate()
                            : moment(new Date()).add(1, 'days').toDate()}
                        value={props.quickBook.deliInfo}
                        selected={props.quickBook.deliInfo}
                        onChange={props.onChangeBookHandler} checkDateValidation={true}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.deliInfo_ErrorClass}
                        maxDate={moment(new Date()).add(6, 'month').toDate()}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                    {/* <div className="custom-control custom-checkbox mt-2">
                        <InputElement type="checkbox" className="custom-control-input" id="specialDelivery" name="specialDelivery"
                            checked={props.quickBook.specialDelivery}
                            onChange={props.onChangeBookHandler} />
                        <label className="custom-control-label" htmlFor="specialDelivery" >Special Delivery</label>
                    </div> */}
                    {/* <div>
                        <InputElement  type="checkbox" className="chkboxSize" id="specialDelivery" name="specialDelivery" checked={props.quickBook.specialDelivery} onChange={props.onChangeBookHandler} />
                         <div className="d-inline align-top">   Special Delivery </div>
                    </div> */}

                </div>
                <div className="col-md-2 mb-2">
                    <label >Ready Time</label>
                    <TimeElement id="deliReadyTime" name="deliReadyTime"
                        value={props.quickBook.deliReadyTime ? new Date(props.quickBook.deliReadyTime) : null}
                        selected={props.quickBook.deliReadyTime ? new Date(props.quickBook.deliReadyTime) : null}
                        onChange={props.onChangeBookHandler}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.deliReadyTime_ErrorClass}
                        minTime={new Date().setHours(8, 0, 0, 0)}
                        maxTime={props.quickBook.deliCloseTime ? 
                            moment(new Date(props.quickBook.deliCloseTime)).subtract(5, 'minutes').toDate()
                             : new Date().setHours(17, 0, 0, 0)}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label >Close Time</label>
                    <TimeElement id="deliCloseTime" name="deliCloseTime"
                        value={props.quickBook.deliCloseTime ? new Date(props.quickBook.deliCloseTime) : null}
                        selected={props.quickBook.deliCloseTime ? new Date(props.quickBook.deliCloseTime) : null}
                        onChange={props.onChangeBookHandler}
                        validation="required"
                        onInvalid={props.onValidateBookHandler}
                        className={props.quickBookRequired.deliCloseTime_ErrorClass}
                        minTime={props.quickBook.deliReadyTime ? 
                            moment(new Date(props.quickBook.deliReadyTime)).add(5, 'minutes').toDate()
                             : new Date().setHours(8, 5, 0, 0)}
                        maxTime={new Date().setHours(17, 5, 0, 0)}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </div>
            {/* <div style={{ marginTop: 20 }}>{JSON.stringify(props.quickBookRequired)}</div> */}
            <hr className="mb-3" />
        </div>
    );
}

export default DirectBook3;