import * as actionTypes from '../actions/actionTypes'
import {updateIntialState} from '../utility'
//import { createReducer } from '@reduxjs/toolkit'

const initialState = [{
    qty: 0, 
    weight: 0, 
    length: 0 ,
     width: 0, 
     height: 0,
     //packageType : "", packageType_ErrorClass:"form-control"
     description: ""
     , qty_ErrorClass: "form-control", weight_ErrorClass: "form-control", length_ErrorClass: "form-control", width_ErrorClass: "form-control", height_ErrorClass: "form-control"
}];

const reducer = //createReducer(initialState, { UPDATE_ITEM: 
    ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ADDDIMENSIONS:
            return updateIntialState(action.dimenstionList);
        case actionTypes.REMOVEDIMENSIONS:
            return updateIntialState(initialState);
        default:
            return state
    }
};
//})


export default reducer;