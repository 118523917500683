import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { HashLoader } from 'react-spinners';
import InputElement from "../../../../formControls/InputElement";
const LinehaulEditModel = (props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}
                size="lg" // for large modal box
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"} // not to close popup when  mouse clicked outside of popup
                keyboard={false} // not to close popup on escape key press
            >
                {props.innerLoading &&
                    <div className="modalLoadingOverlay">
                        <div className="modalLoadingSpinner">
                            <HashLoader color={'#1E73BE'} loading={props.innerLoading} size={35} />
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    <Modal.Title>Edit tariff rates for {props?.editData?.area} zone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>{JSON.stringify(props.formData)}</div>   */}
                    <Form id="tariffEdit">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Current Min : &emsp;&emsp;&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.min}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 100 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs100}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 500 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs500}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3">
                        <div className="col-md-4 dflex">
                                <label>New Min :</label>
                                <InputElement id="min" name="min" value={props?.editData?.updatedTariffs?.min || ''} className={props?.editData?.updatedTariffs?.min_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Min Value' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 100 : </label>
                                <InputElement id="lbs100" name="lbs100" value={props?.editData?.updatedTariffs?.lbs100 || ''} className={props?.editData?.updatedTariffs?.lbs100_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 100' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 500 : </label>
                                <InputElement id="lbs500" name="lbs500" value={props?.editData?.updatedTariffs?.lbs500 || ''} className={props?.editData?.updatedTariffs?.lbs500_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 500' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 ">
                                <label>Current Lbs 1000 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs1000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 2000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs2000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 3000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs3000}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3" >
                        <div className="col-md-4 dflex">
                                <label>New Lbs 1000 :</label>
                                <InputElement id="lbs1000" name="lbs1000" value={props?.editData?.updatedTariffs?.lbs1000 || ''} className={props?.editData?.updatedTariffs?.lbs1000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 1000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 2000 :</label>
                                <InputElement id="lbs2000" name="lbs2000" value={props?.editData?.updatedTariffs?.lbs2000 || ''} className={props?.editData?.updatedTariffs?.lbs2000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 2000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 3000 </label>
                                <InputElement id="lbs3000" name="lbs3000" value={props?.editData?.updatedTariffs?.lbs3000 || ''} className={props?.editData?.updatedTariffs?.lbs3000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 3000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            
                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                    <label>Current Lbs 5000 :&emsp; <strong className="embossed">{props?.editData?.currentTariffs?.lbs5000}</strong></label>
                                </div>
                                <div className="col-md-4 ">
                                    <label>Current Lbs 10000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs10000}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    <label>Current Lbs 15000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs15000}</strong></label>
                                </div>
                            </div>
                        <div className="row mb-3" >
                        <div className="col-md-4 dflex">
                                <label>New Lbs 5000 </label>
                                <InputElement id="lbs5000" name="lbs5000" value={props?.editData?.updatedTariffs?.lbs5000 || ''} className={props?.editData?.updatedTariffs?.lbs5000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 5000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 10000 :</label>
                                <InputElement id="lbs10000" name="lbs10000" value={props?.editData?.updatedTariffs?.lbs10000 || ''} className={props?.editData?.updatedTariffs?.lbs10000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 10000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Lbs 15000 :</label>
                                <InputElement id="lbs15000" name="lbs15000" value={props?.editData?.updatedTariffs?.lbs15000 || ''} className={props?.editData?.updatedTariffs?.lbs15000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 15000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 ">
                                <label>Current Lbs 20000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs20000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Cap Rate:&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.capRate}</strong></label>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                        <div className="row mb-3" >
                        <div className="col-md-4 dflex">
                                <label>New Lbs 20000 :</label>
                                <InputElement id="lbs20000" name="lbs20000" value={props?.editData?.updatedTariffs?.lbs20000 || ''} className={props?.editData?.updatedTariffs?.lbs20000_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Lbs 20000' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4 dflex">
                                <label>New Cap Rate:</label>
                                <InputElement id="capRate" name="capRate" value={props?.editData?.updatedTariffs?.capRate || ''} className={props?.editData?.updatedTariffs?.capRate_ErrorClass}
                                    onChange={props.handleOnchange}
                                    placeholder='New Cap Rate' />
                                <div className="invalid-feedback">
                                    required.
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                        <hr />

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={props.handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default LinehaulEditModel