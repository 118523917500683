import React from 'react';
import { NavLink } from 'react-router-dom';
import PDF from "../../../assets/downloads/CorporateDirectory.pdf";




const CorporateMenu = () => {

   

    return (
        
        <nav id="sidebar">
            {/* <!--side--> */}
        <div className="custom-menu">
            <button type="button" id="sidebarCollapse" className="btn btn-toggle">
               
                 <i className="fa-solid fa-bars"/>
                {/* <i class="fas fa-bars"></i> */}
                <span className="sr-only">Toggle Menu</span>
            </button>
        </div>
            <ul className="list-unstyled components">
                <li>
                    <NavLink to="/corporate/linkCustomerAc" title="Link Customer Accounts">
                        <i className="fas fa-user-lock"/> 
                        <span>Link Customer Accounts </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/linkAppMotorCarriers" title="Link Approved Motor Carriers">
                        <i className="fas fas fa-truck"/>
                        <span>Link Approved Motor Carriers </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/corporateDirectory" title="Corporate Directory">
                        <i className="fas fa-address-book"/>
                        <span>Corporate Directory  </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/invoices" title="Invoices">
                    {/* <i className="fa-solid fa-file-invoice" /> */}
                    <i className="fa-solid fa-file-invoice"></i>
                        <span>Invoices </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/salesSop" title="Sales SOP">
                        <i className="fas fa-file-invoice-dollar"/>
                        {/* <i class="fas fa-file-invoice-dollar"></i> */}
                        
                        <span>Sales SOP </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/userLookup" title="User Lookup">
                        <i className="fas fa-user"/>
                        <span>User Lookup </span>
                    </NavLink>
                </li>
                
                <li>
                    <NavLink to="/corporate/webAccount" title="Web Account Admin">
                        <i className="fas fa-user-cog"/>
                        <span>Web Account Admin </span>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/corporate/tariffManagement" title="Tariff Update Portal Management">
                        <i className="fas fa-dollar-sign"/>
                        <span>Carrier Update Portal Management </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/tariffUpdatePortal" title="Tariff Update Portal">
                        <i className="fas fa-money-bill-alt"/>
                        <span>Carrier Update Portal </span>
                    </NavLink>
                </li>  */}
                <li>
                    <NavLink to="/corporate/newsFeed" title="News Feed">
                    <i className="fas fa-newspaper"/>
                        <span>News Feed</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/corporate/editCoverage" title="Update Coverage Address">
                    <i className="fas fa-edit"/>
                        <span>Edit Coverage Details</span>
                    </NavLink>
                </li>
            </ul>
    </nav>
    
    );
};

export default CorporateMenu;