import React from 'react';
import logo_ameca from "../../assets/img/logo_aemca.png";
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Freight Force</h4>
            <ul>
              <li>
                <NavLink to="/" title="Home">
                <i className="fas fa-chevron-right"></i>Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/aboutUs/ourStory" title="About Us">
                <i className="fas fa-chevron-right"></i>About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/tsa" title="TSA">
                <i className="fas fa-chevron-right"></i>TSA
                </NavLink>
              </li>
              <li>
                <NavLink to="/coverage" title="Coverage">
                <i className="fas fa-chevron-right"></i>Coverage
                </NavLink>
              </li>
              <li>
                <NavLink to="/contactUs" title="Contact Us">
                <i className="fas fa-chevron-right"></i>Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              {/* <li><i className="fas fa-chevron-right"></i><a href={process.env.REACT_APP_LINUHAUL_RATES} title="LineHaul Schedule/Rates" target="_blank">LineHaul Schedule/Rates</a></li> 
              <li><i className="fas fa-chevron-right"></i><a href={process.env.REACT_APP_LINUHAUL_RATES} title="Nationwide LineHaul Schedule/Rates" target="_blank">Nationwide LineHaul Schedule/Rates</a></li> */}
               <li><i className="fas fa-chevron-right"></i><a href={process.env.REACT_APP_HOLIDAY_SCHEDULE} title="Holiday Schedule" target="_blank">Holiday Schedule</a></li> 
              {/* <li><i className="fas fa-chevron-right"></i><a href="#" title="Fuel Surcharge">Fuel Surcharge</a></li>
              <li><i className="fas fa-chevron-right"></i><a href="#" title="Cargo Claim">Cargo Claim</a></li>
              <li><i className="fas fa-chevron-right"></i><a href="#" title="Carrier Directory">Carrier Directory</a></li> */}
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              14445 Alondra Blvd<br/>
			La Mirada, CA 90638</p>
			<p>
              <strong>Phone:&nbsp;</strong>(714) 995.9300<br/>
              <strong>Fax:&nbsp;</strong>(714) 995.9330</p>
			   <div className="social-links mt-3">
				   <a href="https://www.facebook.com/Freight-Force-Inc-351158015565385/?modal=admin_todo_tour" className="fb" target="_blank"><i className="fab fa-facebook-f"></i></a>
				   <a href="https://www.instagram.com/freightforce/" className="insta" target="_blank"><i className="fab fa-instagram"></i></a>
				   <a href="https://twitter.com/FreightForce" className="twtr" target="_blank"><i className="fab fa-twitter"></i></a>
           <a href="https://www.linkedin.com/company/freightforce" className="lnkd" target="_blank"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 footer-info">
            <h4>Other Info</h4>
            <div className="img-fluid aemca mt-1">
				<a href="https://aemca.org/" target="_blank" title="AEMCA"><img src={logo_ameca} alt="LOGO_AMECA" /></a>
			</div>
			<div className="row mt-3">
				<div className="col-xs-5 p-0">
					<div className="infoLnks">
							<a href="https://www.weather.gov/" target="_blank" title="Weather Radar"><i className="fas fa-globe-americas infWtr mb-1"></i>Weather Radar</a>
					</div>
				</div>
				<div className="col-xs-5 p-0">
					<div className="infoLnks">	
							<a href="http://ms.freightforce.com/general/pdfForms/WebsiteInstructionsRevised7_6_17.pdf" target="_blank" title="Website Tutorial"><i className="fas fa-question-circle infTut mb-1"></i>Website Tutorial</a>
					</div>
				</div>
			</div>
          </div> */}

        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        Copyright &copy; 2023 Freight Force. All Rights Reserved
      </div>
    </div>
  </footer>
    );
};

export default Footer;