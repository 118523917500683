import React from 'react'
import emailjs from 'emailjs-com'
import { useRef } from 'react'
import PrintBol from '../services/quickQuote/PrintBol';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { sendEmail } from '../services/restAPI/UtilsRest';
import { useState } from 'react';

//Ref = 
const SendEmail = () => {
    const formRef = useRef();

    /*
    const sendEmail = (e) => {
        e.preventDefault();

        // console.log(formRef.current)

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            formRef.current,
            process.env.REACT_APP_PUBLIC_KEY
        ).then(
            result => console.log(result.text),
            error => console.log(error.text)
        )
        
        // e.target.reset();
    }
    */

    const bolRef = useRef(null);

    const emailBolPdf = () => {
		html2canvas( document.getElementById("print-bol-area"), { quality: 0.95 } ).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("potrait", "pt", "legal"); 
			const imgProperties = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
			pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

			var htmlout = pdf.output();
			//let base64 = 'data:application/pdf;base64,'+ Base64.encode(htmlout);
			let base64 = btoa(htmlout);
            // console.log("htmlout",htmlout)
            // console.log("base64",base64)
			let email = ''; // quickQuote.customer && quickQuote.customer.email ? quickQuote.customer.email:'';
			let addemail = 'sowmya.santoshi@stgusa.com'; //quickQuote.customer && quickQuote.customer.additionalEmails ? quickQuote.customer.additionalEmails:'';
			let bolNo =  '6543'; // quickQuoteRes && quickQuoteRes.bookingNumber ? quickQuoteRes.bookingNumber :'';
			let compName = 'Test Company Name'; // quickQuote.customer && quickQuote.customer.companyName ? quickQuote.customer.companyName : '';
			let filename = "BOL-"+bolNo+".pdf";
			let messBody = "Hi testing " +compName;
			let sub = "BOL for Order "+ bolNo;
            
		let mailObj = {
			"toRecipients": email && addemail ? email+","+addemail :  email  ? email : addemail ? addemail :'',
			"ccRecipients": "",
			"bccRecipients": "",
			"subject": sub,
			"messageBody": messBody,
			"attachments": [
				{
				  "data": base64,
				  "fileName":filename
				}
			  ]
		};
		// console.log("reqeust data",JSON.stringify(mailObj))

        
		sendEmail(mailObj).then(eRes => {
			if (eRes instanceof Error) {
                alert("Error : "+eRes.result.emailResponse)
				//setSaveMessage({showMsg: true, status: 'error', message: eRes.result.emailResponse});
			} else {
                alert("Success : "+eRes.result.emailResponse)
				//setSaveMessage({showMsg: true, status: 'message', message: eRes.result.emailResponse});
			}
		})
		 
			
		})
		//alert("Coming Soon..",base64);
		
	}

    const [b64Src, setB64Src] = useState('');

    const downloadBolPdf = () => {  
		setTimeout(() => {
			const pdf = new jsPDF("portrait", "pt", "legal", true); 
			const pdfElement = document.getElementById('print-bol-area'); 
			pdf.html(pdfElement, { width: 610, //  width: fit-content;		block-size: fit-content;
							//	html2canvas: { scale: 0.70, },
								callback: function (_pdf) {
									// _pdf.save('MyPdfFile.pdf')
									_pdf.save("BOL-"+quickQuoteRes.bookingNumber+".pdf");
								 },
									  windowWidth: 1200,
									})
		}, 2000);
    };
     
    let quickQuote = {"custCode":"1026","uid":"2663","isLinehaul":false,"customer":{"custCode":"1026","companyName":"RADIANT TEST","address1":"RADIANT TEST","address2":"1234 TEST BLVD","city":"CARSON","state":"","postalCode":"90746","country":"US","phone":"310-555-7777","email":"stguidev@stgusa.com","contact":"STGDEV STGDEV","userName":null,"userid":"2663","additionalEmails":""},"shipper":{"companyName":"","address1":"","address2":"","city":"","state":"","postalCode":"90210","country":"","phone":"","email":"","contact":"","terminal":""},"consignee":{"companyName":"","address1":"","address2":"","city":"","state":"","postalCode":"90220","country":"","phone":"","email":"","contact":"","terminal":""},"shipment":{"weight":42,"serviceType":"1","moveType":"1","pickupDate":null,"pickupOpenTime":"1970-01-01T03:00:00Z","pickupCloseTime":"1970-01-01T12:00:00Z","deliveryDate":null,"deliveryOpenTime":"1970-01-01T03:00:00Z","deliveryCloseTime":"1970-01-01T12:00:00Z","notes":"","isSpecialPickup":false,"isSpecialDelivery":false,"dimList":[{"qty":2,"weight":21,"length":23243,"width":45,"height":4,"description":""}],"accessorials":[{"code":"AIRWAY BILL PREP (INTERNATIONAL)","description":"AIRWAY BILL PREP (INTERNATIONAL)","amount":17.5,"min":17.5,"surType":"FLAT"}],"accSerType":"pickup","qty":2,"hawb":null,"mawb":null,"custReference":null,"applyAccessorialsTo":null},"moveType":"1","linehaulServiceType":"1","quote":{"quoteId":"6148","rateQuote":{"quoteId":"6148","originZip":"","destinationZip":"","terminal":null,"msg":"","weight":42,"qty":2,"cbm":0,"fsc":13.76,"fsc_rate":43,"freight_charge":32,"accessorialList":[]}},"ediStatus":"","emailStatus":{"result":{"emailResponse":"SUCCESS"},"id":35,"exception":null,"status":5,"isCanceled":false,"isCompleted":true,"isCompletedSuccessfully":true,"creationOptions":0,"asyncState":null,"isFaulted":false}};
    let quickQuoteRes = {"customer":{"custCode":"1026","companyName":"RADIANT TEST","address1":"RADIANT TEST","address2":"1234 TEST BLVD","city":"CARSON","state":"","postalCode":"90746","country":"US","phone":"310-555-7777","email":"stguidev@stgusa.com","contact":"STGDEV STGDEV","userName":null,"userid":"2663","additionalEmails":""},"finalMileServiceType":null,"custCode":"1026","uid":"","bookingNumber":"1006","isLinehaul":false,"shipper":{"companyName":"Sphere","address1":"SUITE 110","address2":"","city":"SOMERSET","state":"AK","postalCode":"18505","country":"USA","phone":"","email":"","contact":"","terminal":""},"consignee":{"companyName":"Test","address1":"add","address2":"","city":"city","state":"AZ","postalCode":"90220","country":"USA","phone":"","email":"","contact":"","terminal":""},"shipment":{"weight":42,"serviceType":"1","moveType":"1","pickupDate":"2023-01-25T08:53:42.410Z","pickupOpenTime":"2023-01-25T03:00:00Z","pickupCloseTime":"2023-01-25T12:00:00Z","deliveryDate":"2023-01-26T08:53:42.410Z","deliveryOpenTime":"2023-01-26T03:00:00Z","deliveryCloseTime":"2023-01-26T12:00:00Z","notes":"","isSpecialPickup":false,"isSpecialDelivery":false,"dimList":[{"qty":2,"weight":21,"length":23243,"width":45,"height":4,"description":""}],"accessorials":[{"code":"AIRWAY BILL PREP (INTERNATIONAL)","description":"AIRWAY BILL PREP (INTERNATIONAL)","amount":17.5,"min":17.5,"surType":"FLAT"}],"accSerType":"pickup","qty":2,"hawb":"121","mawb":null,"custReference":null,"applyAccessorialsTo":null},"moveType":null,"linehaulServiceType":null,"rateQuote":{"quoteId":"6148","originZip":"","destinationZip":"","msg":"","weight":42,"qty":2,"fsc":13.76,"fsc_rate":43,"freight_charge":32,"accessorialList":[]},"ediStatus":"SUCCESS!"}

  return (
    <section className='mt-180 mb-4'>
        <div className='container'>
            <div className="row">
                <div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex">
                            <h4 className="mb-3 pageHead text-uppercase">Test Email Sending using EmailJs</h4>
                            <hr/>
                        </div>
                        <form ref={formRef} onSubmit={sendEmail}>
                            <input type="text" name='email_from_address' value="sowmya@gmail.com" readOnly />
                            <input type="text" name='email_from_name' value="Sowmya" readOnly />
                            <input type="text" name='reply_to' value="sowmya@gmail.com" readOnly />
                            <input type="text" name='email_subject' value="Test Message " readOnly />

                            <div>
                                <label>Name</label>
                                <input type="text" name='email_to_name' />
                            </div>
                            <div>
                                <label>Email ID</label>
                                <input type="text" name='email_to_address' />
                            </div>
                            <div>
                                <label>Message</label>
                                <input type="text" name='email_message_body' />
                            </div>
                            <button className='btn btn-warning' type='submit'>Test Email</button>
                        </form>

                        <button className="btn btn-outline-primary  float-right mr-2" type="button" onClick={emailBolPdf}><i className="fas fa-envelope"></i> EMAIL BOL</button>
                        <button className="btn btn-outline-primary  float-right mr-2" type="button" onClick={downloadBolPdf}><i className="fas fa-envelope"></i> PRINT BOL</button>
						
                        <div id="print-bol-area"  ref={bolRef}> 
                            <PrintBol bookingRespData={quickQuoteRes} quickQuoteData={quickQuote} b64Src={b64Src}></PrintBol>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default SendEmail