import React, { useState,useEffect } from 'react'
import { HashLoader } from "react-spinners";
import InputElement from '../../../../formControls/InputElement';
import DataStationInfo from '../Data/DataStationInfo';

const StationInfo = (props) => {
  
  const {stationInfoData, stationInfoDataReq,responseStatus,responseMessage,  onValidate, clearForm, onSubmit, updateStationInfoData,saveTariffInfo } = DataStationInfo();
  
  useEffect(()=>{
    props.restFunCall();
  },[props.airCode])
  
  const [editInfo, setEditInfo] = useState(false);
  
  const changeEdit = () =>{
    setEditInfo(true);
  }
  const cancelEdit = () =>{
    setEditInfo(false);
    clearForm();
  }
  
  return (
    <>
    <hr className="tabhr"/>
    <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'}/>}
    <div className="container">
        <div className="row">
          <div className="col-md-12 mt-4 sopBox2">
            <div className="row">
              <div className="col-md-4">
                  <label htmlFor="stationId">Station ID</label>
                  {!editInfo ?
                  <p>{props.tariff?.airportCode}</p> :
                  <InputElement id="stationId" name="stationId" value={props.tariff?.airportCode || ''} className={stationInfoDataReq.airportCode_ErrorClass}
                  onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                  placeholder='Station ID' />}
              </div>
              <div className="col-md-8">
              { responseStatus && 
                <h6 className={responseStatus}>{responseMessage} </h6>
            }
            </div>
            </div>
            <div className="row mt-3">
                  <div className="col-md-4">
                      <label htmlFor="companyName">Company</label>
                      {!editInfo ?
                      <p>{props.tariff?.companyName}</p> :
                      <InputElement id="companyName" name="companyName" value={props.tariff?.companyName || ''} className={stationInfoDataReq.companyName_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required" placeholder='Company' />      }
                </div>
                  <div className="col-md-4">
                      <label htmlFor="address1">Address 1</label>
                      {!editInfo ?
                      <p>{props.tariff?.address1}</p>:
                      <InputElement id="address1" name="address1" value={props.tariff?.address1 || ''} className={stationInfoDataReq.address1_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required" placeholder='Address 1' />      }
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="address2">Address 2</label>
                      {!editInfo ?
                      <p>{props.tariff?.address2}</p>:
                      <InputElement id="address2" name="address2" value={props.tariff?.address2 || ''} className={stationInfoDataReq.address2_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                      placeholder='Address 2' />}
                  </div>
              </div>
              <div className="row mt-3">
                  <div className="col-md-4">
                      <label htmlFor="city">City</label>
                      {!editInfo ?
                      <p>{props.tariff?.city}</p>:
                      <InputElement id="city" name="city" value={props.tariff?.city || ''} className={stationInfoDataReq.city_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                      placeholder='City' />}
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="state">State</label>
                      {!editInfo ?
                      <p>{props.tariff?.state}</p>:
                      <InputElement id="state" name="state" value={props.tariff?.state || ''} className={stationInfoDataReq.state_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                      placeholder='State' />}
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="postalCode">Zipcode</label>
                      {!editInfo ?
                      <p>{props.tariff?.postalCode} </p>
                      :
                      <InputElement id="postalCode" name="postalCode" value={props.tariff?.postalCode || ''} className={stationInfoDataReq.postalCode_ErrorClass}
                        onChange={props.handleInputChange} onInvalid={onValidate} validation="required" placeholder='Zipcode' />      }
                  </div>
              </div>
              <div className="row mt-3">
                  <div className="col-md-4">
                      <label htmlFor="phoneNumber">Phone</label>
                      {!editInfo ?
                      <p>{props.tariff?.phoneNumber}</p>:
                      <InputElement id="phoneNumber" name="phoneNumber" value={props.tariff?.phoneNumber || ''} className={stationInfoDataReq.phoneNumber_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                      placeholder='Phone' />}
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="emergencyPhone">Emergncy Phone</label>
                      {!editInfo ?
                      <p>{props.tariff?.emergencyPhone} </p>
                      :
                      <InputElement id="emergencyPhone" name="emergencyPhone" value={props.tariff?.emergencyPhone || ''} className={stationInfoDataReq.emergencyPhone_ErrorClass}
                        onChange={props.handleInputChange} onInvalid={onValidate} validation="required" placeholder='Emergncy Phone' />      }
                  </div>
              </div>
              <div className="row mt-3">
                  <div className="col-md-4">
                      <label htmlFor="fax">Fax</label>
                      {!editInfo ?
                      <p>{props.tariff?.fax}</p>:
                      <InputElement id="fax" name="fax" value={props.tariff?.fax || ''} className={stationInfoDataReq.fax_ErrorClass}
                      onChange={props.handleInputChange} 
                      placeholder='Fax' />}
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="manager">Manager Name</label>
                      {!editInfo ?
                      <p>{props.tariff?.manager}</p>:
                      <InputElement id="manager" name="manager" value={props.tariff?.manager || ''} className={stationInfoDataReq.manager_ErrorClass}
                      onChange={props.handleInputChange} onInvalid={onValidate} validation="required"
                      placeholder='Manager Name' />}
                  </div>
                  <div className="col-md-4">
                      <label htmlFor="email">Email</label>
                      {!editInfo ?
                      <p>{props.tariff?.email} </p>
                      :
                      <InputElement id="email" name="email" value={props.tariff?.email || ''} className={stationInfoDataReq.email_ErrorClass}
                        onChange={props.handleInputChange} onInvalid={onValidate} validation="required" placeholder='Email' />      }
                  </div>
              </div>
              <div className="row mt-3 mb-3">
                  {!editInfo ?
                  <div className="col-md-2">
                      <button className="btn btn-primary btn-block btn-sm" onClick={changeEdit} type="button" >
                        Edit &nbsp;<i className="fas fa-edit"></i>  </button>
                  </div>
                  :  
                  <>
                  <div className="col-md-2">
                    <button className="btn btn-primary btn-block btn-sm" type="button" onClick={()=>saveTariffInfo(props.tariff)}> Save &nbsp;<i className="fas fa-edit"></i>  </button>
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-secondary btn-block btn-sm p-1" type="button" onClick={cancelEdit}> Cancel &nbsp;<i className="fas fa-times"></i>  </button>
                  </div>
                  </>
                  }
              </div>
          </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default StationInfo