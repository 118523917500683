import { filter } from 'jszip';
import { useState } from 'react'

const DataNotes = () => {
    const [initialData] = [{
        station : 'ABE',
        tariffNote : 'ABE',
        splDeliveryNote : 'ABE',
        accessorialNote : 'ABE' ,
        airportTransferNote : 'ABE',
        linehaulNote : 'ABE',
        citiesServedNote : 'ABE'
    }];
    const [initialDataReq] = [{
        station : true,
        tariffNote : true,
        splDeliveryNote : true,
        accessorialNote : true ,
        airportTransferNote : true,        
        linehaulNote : true,
        citiesServedNote : true,
        station_ErrorClass : 'form-control',
        tariffNote_ErrorClass : 'form-control',
        splDeliveryNote_ErrorClass : 'form-control',
        accessorialNote_ErrorClass : 'form-control' ,
        airportTransferNote_ErrorClass  : 'form-control',
        linehaulNote_ErrorClass : 'form-control',
        citiesServedNote_ErrorClass : 'form-control'
      }];

      const [notesData, setNotesData] = useState(initialData);
      const [notesDataReq, setNotesDataReq] = useState(initialDataReq);

      const handleInputChange = (name, value) => {
        setNotesData({...notesData, [name]: value});
    }

    const onValidate = (name, value) => {
        var errState = {};
        var status = false;
        Object.entries(notesDataReq).forEach(([key]) => {
          var errname = key + "_ErrorClass";
          if (key === name && value) {
            errState = { [name]: true, [errname]: 'form-control is-invalid' };
            status = true;
          } else if (key === name && !value) {
            errState = { [name]: false, [errname]: 'form-control is-valid' };
            status = true;
          }
        })
        if (status)
          setNotesDataReq({ ...notesDataReq, ...errState });
        //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
      }

      const onSubmit = () => {
        const copy = {...notesDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass")  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";//(key==="accountType" ? "custom-control-input" : );
                var errState = null;
                if(notesData[key]){
                    errState = {[key]: false,[errname]: _css};
                    status = true;
                }else{
                    errState = {[key]: true,[errname]: _css+' is-invalid'};
                    status = true;
                }
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setNotesDataReq({...notesDataReq,...errObject});
        } 

        // setShowDataLoading(true);
        // loadReportData();
        let reqObj = { ...notesDataReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        // console.log("c="+JSON.stringify(reqObj))
        validationReqFlag = Object.values(reqObj).includes(true);
        // console.log("validationReqFlag=" + validationReqFlag)
        return validationReqFlag;
            
    }

    const clearForm = () => {
        setNotesData(initialData);
        setNotesDataReq(initialDataReq);
    } 

  return {
    notesData , notesDataReq, handleInputChange, onValidate, clearForm, onSubmit
  }
}

export default DataNotes