import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const MapContainer = (props) => {

  const [ selected, setSelected ] = useState({});
  let selectedLocation = props.selectedLocation;
  const mapKey=process.env.REACT_APP_MAP_API_KEY
  const regionMarkerStyles = {
    "Northeast": { "path": "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                  "fillColor": 'blue'    , "strokeColor": '#fff' } ,
    "Southeast": { "path": "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                  "fillColor": 'orange'  , "strokeColor": '#fff' } ,
    // "Southwest": { "path": "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
    //               "fillColor": 'yellow'  , "strokeColor": '#fff' } ,
    "West":      { "path": "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                  "fillColor": 'green'   , "strokeColor": '#fff' } ,
    "Midwest":   { "path": "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                  "fillColor": 'red'     , "strokeColor": '#fff' } 
  }
    const locationsList = props.locationsList;
    //  console.log("="+locationsList.length)

  const defaultCenter = {
    lat: 39.7659044,
    lng: -104.8150018
  }

  const onSelect = item => {
    setSelected(item);
  }

  const mapStyles = {
    marginTop: "-20px",
    height: "50vh",
    width: "100%"
  }

  useEffect(()=>{
    if(selectedLocation){
      setSelected(selectedLocation);
    } else{
      setSelected(null);
    }
  },[selectedLocation])

  /*
  const [ currentPosition, setCurrentPosition ] = useState({});
  const markerRef = useRef(null);
  const success = (position) => {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;
    const currentPosition = {
      lat: latitude,
      lng: longitude
    }
    setCurrentPosition(currentPosition);
  }

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng})
  };

  const footer = (
    <div className="footer">
      <div className="inner-footer">
      <span className="location-text">Choose location and press</span>
      <button type="button"   onClick={() => getLocation(currentPosition)}>
        Next
      </button>
      </div>
    </div>
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  })
*/
  

     return (
    <>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={mapKey}
      >
        <GoogleMap
          id='coverage-map'
          mapContainerStyle={mapStyles}
          // draggable={true}
          zoom={4}
          center={defaultCenter} 
          //{currentPosition.lat ? currentPosition : defaultCenter}
        >
          {
            locationsList ?
            locationsList.map((item,index) => {
              // console.log("reg="+JSON.stringify(regionMarkerStyles[item.Region].fillColor))
              return (
              <Marker 
              key={index}
              position={{lat: item.latitude, lng: item.longitude}}
              onClick={() => onSelect(item)}
              // options={{icon: 'https://i.imgur.com/9G5JOp8.png'}}
              icon={{
                path: regionMarkerStyles[item.region]?.path,
                // url : 'https://i.imgur.com/9G5JOp8.png',
                fillColor: regionMarkerStyles[item.region]?.fillColor,
                fillOpacity: 5,
                strokeColor: regionMarkerStyles[item.region]?.strokeColor,
                strokeWeight: 1,
                scale: 2,

              }}
              // another way of placing icons styles
              // onLoad={marker => {
              //   const customIcon = (opts) => Object.assign({
              //     path: regionMarkerStyles[item.Region].path,
              //     fillColor: regionMarkerStyles[item.Region].fillColor,
              //     fillOpacity: 5,
              //     strokeColor: regionMarkerStyles[item.Region].strokeColor,
              //     strokeWeight: 1,
              //     scale: 2,
              //   }, opts);
              
              //   marker.setIcon(customIcon({
              //     //fillColor: location.lat === props.address.lat && location.lng === props.address.lng ?'red':'blue',
              //     // fillColor: regionMarkerStyles[item.Region].fillColor, //'blue',
              //     // strokeColor: 'white'
              //   }));
              // }}
              />
              )
            }) : null
          }
           
          {
            selected.latitude ?
            (
              <InfoWindow
              position={{lat: selected.latitude, lng: selected.longitude }}
              onCloseClick={() => setSelected({})} >
              <div className="infowindow">
                <div className='font-weight-bold text-primary'>{selected.station_name}</div> 
                <div className='text-sm'>
                {selected.address} <br/>
                {selected.csz} <br/>
                {selected.station_id} <br/>
                Region : {selected.region} <br/>
                {selected.phone} <br/>
                {selected.fax} <br/>
                {selected.email_address} <br/>
                Manager/Security Coordinator <br/>
                {selected.manager_name} <br/>
                {selected.emerg_phone}
                </div>
              </div>
            </InfoWindow>
            ) : null
          }
        </GoogleMap>
      </LoadScript>
      {/* {
        isAdding ?
        footer :
        null
      } */}
    </>
     )
  }

export default MapContainer;