import React,{useEffect} from 'react'

const NationalSales = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">National Sales Team</h4>
                        </div>
							<div className="row mb-3">
								<div className="col-md-6">
									<div className="nSales">
									<h6>Director of Sales:</h6>
									<h5>Dennis Kuhlman</h5>
									<p>Phone: (714) 995-9300 ext 40060<br/>
                                    Email: <a href="mailto:dennis.kuhlman@freightforce.com" target="_blank">dennis.kuhlman@freightforce.com</a></p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="nSales">
									<h6>Western Regional Sales Manager:</h6>
									<h5>Joanne Chavez</h5>
									<p>Phone: (818) 613-7645<br/>
										Email: <a href="mailto:joannec@freightforce.com" target="_blank">joannec@freightforce.com</a></p>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-6">
									<div className="nSales">
									<h6>Midwest Regional Sales Manager:</h6>
									<h5>Keith Engelmann</h5>
									<p>Phone: (847) 477-5628<br/>
								Email: 		<a href="mailto:keithe@freightforce.com" target="_blank">keithe@freightforce.com</a></p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="nSales">
									<h6>Inside Sales Supervisor:</h6>
									<h5>Justin Buby</h5>
									<p>Phone: 310-953-7837<br/>
							Email: 			<a href="mailto:justinb@freightforce.com" target="_blank"> justinb@freightforce.com</a></p>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-6">
									<div className="nSales">
									<h6> Inside Sales Coordinator:</h6>
									<h5>Cheryl Rockwell</h5>
									<p>Phone: (714) 995-9300 ext 40063<br/>
										Email: <a href="mailto:cherylr@freightforce.com" target="_blank">cherylr@freightforce.com</a></p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="nSales">
									<h6>Inside Sales Adminstrator:</h6>
									<h5>Patrycja Jasinska</h5>
									<p>Phone: (714) 995-9300 ext 40061<br/>
                                    Email: <a href="mailto:patrycjaj@freightforce.com" target="_blank">patrycjaj@freightforce.com</a></p>
									</div>
								</div>
							</div>
							<div className="row mb-0">
								<div className="col-md-6">
									<div className="nSales">
									<h6>Southeast Sales Manager:</h6>
									<h5>Judy Taylor</h5>
									<p>Phone: (678) 340-6604<br/>
								Email: 		<a href="mailto:judyt@freightforce.com" target="_blank">judyt@freightforce.com</a></p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="nSales">
									<h6>Northeast Sales Manager:</h6>
									<h5>Brian Maurer</h5>
									<p>Phone: (908) 397-4960<br/>
										Email: <a href="mailto: brianm@freightforce.com" target="_blank"> brianm@freightforce.com</a></p>
									</div>
								</div>
							</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default NationalSales