
export const buildInvoiceDetailsHtml = (invData, hawbTotal, invTotal) => {
    let invHtml = '';
    if( invData && invData.length>0 ){
        invData.map(x => {
            let tdData1 = x['BOL'] || '';
            let tdData2 = x['Airport'] || '';
            let tdData3 = x['OrderId'] || '';
            let tdData4 = x['DeliveryDate'] || '';
            let tdData5 = x['Weight'] || '';
            let tdData6 = x['Pieces'] || '';
            let tdData7 = x['DimWeight'] || '';
            let tdData8 = x['Units'] || '';
            let tdData9 = x['Rate'] || '';
            let tdData10 = x['Amount'] || '';

            invHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                            "<td>"+tdData4+"</td>"+
                            "<td>"+tdData5+"</td>"+
                            "<td>"+tdData6+"</td>"+
                            "<td>"+tdData7+"</td>"+
                            "<td>"+tdData8+"</td>"+
                            "<td>"+tdData9+"</td>"+
                            "<td>"+tdData10+"</td>"+
                        "</tr>";

        });
    }else{
        invHtml += "<tr>"+
                      "<td colspan='10'>No Data available.</td>"+
                      "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr> '+
        ' <td>BOL</td> '+
        ' <td>Airport</td> '+
        ' <td>Order</td> '+
        ' <td>Delivery Date</td> '+
        ' <td>Weight</td> '+
        ' <td>Pieces</td> '+
        ' <td>Dim Weight</td> '+
        ' <td>Units</td> '+
        ' <td>Rate</td> '+
        ' <td>Amount</td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+invHtml+' '+
        // '     <tr> '+
        // '         <th colspan=9 className="text-right font-weight">HAWB Total</th> '+
        // '         <th className="text-right font-weight">$'+hawbTotal+'</th> '+
        // '     </tr> '+
        // '     <tr> '+
        // '         <th colSpan=9 className="text-right">Invoice Total</th> '+
        // '         <th className="text-right font-weight finBill">$'+invTotal+'</th> '+
        // '     </tr> '+
        ' </tbody> '+
        ' </table> '
    )
}