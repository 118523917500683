import React from "react";
import { useEffect } from "react";
import DataInvoices from "./Data/DataInvoices";
import { GridLoader, HashLoader } from "react-spinners";
import moment from "moment";
import InvoiceDatatable from "./InvoiceDatatable";
import InputElement from "../../../formControls/InputElement";
import DatePickerElement from "../../../formControls/DatePickerElement";
import { useSelector } from "react-redux";



const Invoices = () => {
    const {handleInputChange, onSubmitSearch, onValidateFilters, clearSearch, fetchInvoiceDetailsData
            , filtersInput, filtersReq, reportData, showDataLoading, reportDetailsData, showDetailsDataLoading} = DataInvoices();

   
    const isEdit = false; 
   

    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const showElements = () =>{
        if(filtersInput.invoiceType === "I"){
            return   <div className="col-md-4 ">
                        <label htmlFor="invNo">Invoice Number</label>
                        <InputElement type="text" id="invNo" name="invNo" 
                                onChange={handleInputChange} placeholder="Enter Invoice #"
                                value={filtersInput.invNo || ''}
                                className={filtersReq.invNo_ErrorClass} 
                                validation="required" onInvalid={onValidateFilters}
                                />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
        }else if(filtersInput.invoiceType === "H"){
            return <div className="col-md-4">
                        <label htmlFor="waybillNo">Waybill Number</label>
                            <InputElement type="text" id="waybillNo" name="waybillNo" 
                                onChange={handleInputChange} placeholder="Enter Waybill #"
                                value={filtersInput.waybillNo || ''}
                                className={filtersReq.waybillNo_ErrorClass} 
                                validation="required" onInvalid={onValidateFilters}
                                />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
        }else if(filtersInput.invoiceType === "D"){
            return <>
                <div className="col-md-4">
                    <label htmlFor="startDate">Start Date</label>
                    <DatePickerElement id="startDate" name="startDate" onChange={handleInputChange}
                        // minDate={new Date()}
                        value={filtersInput.startDate} 
                        selected={filtersInput.startDate}
                        onInvalid={onValidateFilters}
                        validation="required"
                        className={filtersReq.startDate_ErrorClass}
                        maxDate={filtersInput.endDate ?
                            moment(new Date(filtersInput.endDate)).subtract(1, 'days').toDate()
                            : moment(new Date()).add(6, 'month').toDate()} />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-4">
                    <label htmlFor="endDate">End Date</label>
                    <DatePickerElement id="endDate" name="endDate" onChange={handleInputChange}
                        checkDateValidation={true}
                        //minDate={props.quoteShipping.pickupInfo} 
                        //minDate={props.quoteShipping.deliveryDateMin}
                        minDate={filtersInput.startDate ?
                            moment(new Date(filtersInput.startDate)).add(1, 'days').toDate()
                            : moment(new Date()).add(1, 'days').toDate()}
                        selected={filtersInput.endDate}
                        value={filtersInput.endDate}
                        onInvalid={onValidateFilters}
                        validation="required"
                        className={filtersReq.endDate_ErrorClass}
                        maxDate={moment(new Date()).add(6, 'month').toDate()} />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </>
        }
    }
   
    return (
        <div className={showDetailsDataLoading ? 'container parentDisable' : ''}>
		      {showDetailsDataLoading && <GridLoader color={'#1E73BE'} loading={showDetailsDataLoading} size={35} className={'loadSpin'}/> }
        <div className="card card-custom">
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase">Invoice Lookup by</h4>
            </div>
            <form className="needs-validation" >
            <div className="row mb-1">
                        <div className="col-md-12">
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="invoiceTypeWaybillNo" name="invoiceType" 
                                    onChange={handleInputChange} value="H" onInvalid={onValidateFilters}
                                    checked={filtersInput.invoiceType === "H"}
                                    validation="requiredRadio"
                                    className={filtersReq.invoiceType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="invoiceTypeWaybillNo">Waybill Number</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="invoiceTypeDateRange" name="invoiceType" 
                                    onChange={handleInputChange} value="D" onInvalid={onValidateFilters}
                                    checked={filtersInput.invoiceType === "D"}
                                    validation="requiredRadio"
                                    className={filtersReq.invoiceType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="invoiceTypeDateRange">Date Range</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="invoiceTypeInvNo" name="invoiceType" 
                                    onChange={handleInputChange} value="I" onInvalid={onValidateFilters}
                                    checked={filtersInput.invoiceType === "I"}
                                    validation="requiredRadio"
                                    className={filtersReq.invoiceType_ErrorClass} />
                                <label className="custom-control-label" htmlFor="invoiceTypeInvNo">Invoice Number</label>
                            </div>
                
                        </div>
                </div>
                <hr className="mb-3"/>
                <div className="row mb-3">
                    <div className="col-md-4 ">
                        <label htmlFor="acNo">Account Number</label>
                            <InputElement type="text" id="acNo" name="acNo" 
                                onChange={handleInputChange} 
                                value={filtersInput.acNo}
                                className={filtersReq.acNo_ErrorClass} 
                                validation="required" onInvalid={onValidateFilters}
                                />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    
                       {showElements()}
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-5 pt-2 offset-lg-6 offset-md-2">
                        <button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={clearSearch}><i className="fas fa-times-circle"></i>  Clear Form</button>
                    </div>
                    <div className="col-lg-3 col-md-5 pt-2">
                        <button className="btn btn-primary btn-block pull-right" type="button" onClick={onSubmitSearch}>Search &nbsp;<i className="fas fa-search"></i>  </button>
                    </div>
                </div>
                
            </form>

            <hr/>
        {
            showDataLoading && 
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center"> 
                    <HashLoader  color={'#1E73BE'} loading={showDataLoading} />
                </div>
            </div>
        }
        {
            filtersInput.showTable &&  
            <InvoiceDatatable reportData={reportData} fetchInvoiceDetailsData={fetchInvoiceDetailsData} showDataLoading={showDataLoading}/>
        }
         
        </div>
        </div>
    );
}

export default Invoices;