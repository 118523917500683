import axios from "axios";


export const login = (userName,password,updateUserProfileObject) => {
    return new Promise((resolve, reject) => {
    const postData = {
        "username": userName,
        "password": password
    }
    return axios.post(process.env.REACT_APP_LOGIN_URL, postData)
        .then(response => {
            // console.log("Logged in user Response "+JSON.stringify(response.data))
            /*if(response.data && response.data.user && response.data.user.isValid){
                var userProfile = response.data.user;
                userProfile = Object.assign(userProfile, { "token": response.data.token })
                updateUserProfileObject(userProfile);
            }*/
            resolve( response.data);
            
        })
        .catch(error => {
             //console.log("Error in calling api REACT_APP_LOGIN_URL"+JSON.stringify(error));
             reject(error);
        })
        // .finally(() => {
        //     // console.log("Finally call in LoginAPI ");
        // })
    });
}

export const userLookUpByUserName = (userName) => {
    
    const param = "?username=" +userName;
       
    return axios.get(process.env.REACT_APP_USER_LOOKUP_USERNAME_URL+param)
        .then(response => {
            // console.log("Logged REACT_APP_USER_LOOKUP_USERNAME_URL Response "+JSON.stringify(response.data))
            
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_USER_LOOKUP_USERNAME_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in userLookUpByUserName ");
        })
}

export const userLookUpByCustCode = (custcode) => {
    
    const param = "?custcode=" +custcode;
       
    return axios.get(process.env.REACT_APP_USER_LOOKUP_CUSTCODE_URL+param)
        .then(response => {
            // console.log("Logged REACT_APP_USER_LOOKUP_CUSTCODE_URL Response "+JSON.stringify(response.data))
            
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_USER_LOOKUP_CUSTCODE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in userLookUpByCustCode ");
        })
}

export const userStatusUpdate = (userIdList,statusList) => {
    var postArrayList = [];
    
    for (let i = 0; i < userIdList.length; i++) {
        // console.log(userIdList[i]+"="+statusList[i]);
        const postData = {
            "userId": userIdList[i],
            "updateToStatus": statusList[i]
        }
        postArrayList.push(postData)
    }
    
    return axios.post(process.env.REACT_APP_USER_STATUS_UPDATE_URL, postArrayList)
        .then(response => {
            // console.log("Logged in user REACT_APP_USER_STATUS_UPDATE_URL Response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_USER_STATUS_UPDATE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in LoginAPI ");
        })
}

export const requestSecQuestion = (
    userName,email) => {
    const param = "?email=" +email+"&username="+userName;
    return new Promise((resolve, reject) => {
        axios.get(process.env.REACT_APP_PR_SECURITY_QUESTION+param)
        .then(response => {
            // console.log("Logged REACT_APP_PR_SECURITY_QUESTION Response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_PR_SECURITY_QUESTION"+JSON.stringify(error));
             reject(error);
        })
        .finally(() => {
            // console.log("Finally call in requestSecQuestion ");
        })
});
    }

export const requestSecQuestionAns = (email,username,secureAns) => {    
        const postData = {
            "email": email,
            "username": username,
            "secureAnswer": secureAns
        }
    
    return axios.post(process.env.REACT_APP_PR_SECURITY_QUESTION_ANS, postData)
        .then(response => {
            // console.log("Logged in user REACT_APP_PR_SECURITY_QUESTION_ANS Response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_PR_SECURITY_QUESTION_ANS"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in requestSecQuestionAns ");
        })
}


export const requestValidateOTP = (email,username,secureCode) => {    
    const postData = {
        "email": email,
        "username": username,
        "securityCode": secureCode
    }

return axios.post(process.env.REACT_APP_PR_SECURITY_VALIDATE_OTP, postData)
    .then(response => {
        // console.log("Logged in user REACT_APP_PR_SECURITY_VALIDATE_OTP Response "+JSON.stringify(response.data))
        return response.data;
    })
    .catch(error => {
        // console.log("Error in calling api REACT_APP_PR_SECURITY_VALIDATE_OTP"+JSON.stringify(error));
        return error;
    })
    .finally(() => {
        // console.log("Finally call in requestValidateOTP ");
    })
}

export const requestValidateOldPass = (email,username,oldPassword) => {    
    const postData = {
        "email": email,
        "username": username,
        "oldPassword": oldPassword
    }

return axios.post(process.env.REACT_APP_PR_SECURITY_VALIDATE_OLD_PASS, postData)
    .then(response => {
        // console.log("Logged in user REACT_APP_PR_SECURITY_VALIDATE_OLD_PASS Response "+JSON.stringify(response.data))
        return response.data;
    })
    .catch(error => {
        // console.log("Error in calling api REACT_APP_PR_SECURITY_VALIDATE_OLD_PASS"+JSON.stringify(error));
        return error;
    })
    .finally(() => {
        // console.log("Finally call in requestValidateOldPass ");
    })
}

export const requestChangePass = (email,username,secureCode,newPassword) => {    
    const postData = {
        "email": email,
        "username": username,
        "newPassword": newPassword,
        "securityCode": secureCode
    }

return axios.post(process.env.REACT_APP_PR_SECURITY_CHANGE_PASS, postData)
    .then(response => {
        // console.log("Logged in user REACT_APP_PR_SECURITY_CHANGE_PASS Response "+JSON.stringify(response.data))
        return response.data;
    })
    .catch(error => {
        // console.log("Error in calling api REACT_APP_PR_SECURITY_CHANGE_PASS"+JSON.stringify(error));
        return error;
    })
    .finally(() => {
        // console.log("Finally call in requestChangePass ");
    })
}