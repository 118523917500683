import './DatePickerElement.css';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { validateRequired } from '../utils/ValidationScripts';

const DatePickerElement = (props)=> {

        const datepickerRef = useRef(null);
    function handleClickDatepickerIcon() {
        const datepickerElement = datepickerRef.current;
        // console.log("datepickerElement = ", datepickerElement);
        datepickerElement.setFocus(true);
      }
      const updateValueToState = (date)=>{
        //let formatted_dt = formatDate(date);
        props.onChange([props.id], date);
        //setStartDate(date);
        validation(date);
    }

    const validation = (date)=>{
        //console.log("inputlement for validation "+[props.id]+"::"+date)
        if(props.validation === "required"){
            if(!validateRequired(date)){
                props.onInvalid(props.id,true);
            }else{
                props.onInvalid(props.id,false);
            }
        }
    }

    // to disable user input
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
        return (
            <div className="input-group-append">
            <DatePicker 
            // ="form-control form-control-inactive" 
            {...props}
            selected={props.selected} 
            placeholderText={props.placeholder || "MM/DD/YY"} 
            dropdownMode="select" 
            showMonthDropdown={true} 
            showYearDropdown={true} 
            onChange={(date) => {updateValueToState(date);}}
            onChangeRaw={handleDateChangeRaw}
            isClearable={true}
            // onChange={(date) => {setStartDate(date);updateValueToState();}}
            ref={datepickerRef} />
            <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"  onClick={() => handleClickDatepickerIcon()}>
                    <i className="fas fa-calendar-alt" ></i>
                </span>
            </div>
            </div>
          
        );
    
}

DatePickerElement.defaultProps = {
    className: "form-control",
    dateFormat: 'MM/dd/yyyy',
    placeholder: "MM/dd/yyyy",
    // selected: new Date()
};
export default DatePickerElement;
