import moment from "moment";
import React from "react";
import DatePickerElement from "../../../formControls/DatePickerElement";
import InputElement from '../../../formControls/InputElement'
import TextareaElement from '../../../formControls/TextareaElement'
const SalesSopData = (props) => {
   var  editSOP = false;

   const _dateCreated = props.sop.DateCreated ? moment(new Date(props.sop.DateCreated)).format('MM/DD/YYYY') : '';
   const _startDate = props.sop.StartDate ? moment(new Date(props.sop.StartDate)).format('MM/DD/YYYY') : '';
   const _endDate = props.sop.EndDate ? moment(new Date(props.sop.EndDate)).format('MM/DD/YYYY') : '';

    if(props.newSOP || props.editSOP)
        editSOP=true;
    return (
        
        <>
            <div className="sopBox2" >
                <div className="row mb-2">
                    <div className="col-md-4">
                        <label htmlFor="deptNo">Department Number</label>
                        {!editSOP ?
                        <p>{props.sop.DepartmentNumber}</p> :
                        <InputElement id="DepartmentNumber" name="DepartmentNumber" value={props.sop.DepartmentNumber || ''} className={props.sopReq.DepartmentNumber_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Department Number' />}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="sopNo">SOP Number</label>
                        {!editSOP ?
                        <p>{props.sop.SOPNumber}</p> :
                        <InputElement id="SOPNumber" name="SOPNumber" value={props.sop.SOPNumber || ''} className={props.sopReq.SOPNumber_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='SOP Number' />}
                    </div>
                    <div className="col-md-4 ">
                        <label htmlFor="acNo">Account Number</label>
                        {!editSOP ?
                        <p>{props.sop.AccountNumber}</p>:
                        <InputElement id="AccountNumber" name="AccountNumber" value={props.sop.AccountNumber || ''} className={props.sopReq.AccountNumber_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Account Number' />}
                    </div>
                </div>
                {!editSOP ? <hr /> :''}
                <div className="row  mb-2">
                    <div className="col-md-4">
                        <label htmlFor="custNo">Customer Number</label>
                        {!editSOP ?
                        <p>{props.sop.CustomerName}</p>:
                        <InputElement id="CustomerName" name="CustomerName" value={props.sop.CustomerName || ''} className={props.sopReq.CustomerName_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Customer Number' />}
                    </div>
                    <div className="col-md-4 ">
                        <label htmlFor="custCntName">Customer Contact Name</label>
                        {!editSOP ?
                        <p>{props.sop.CustomerContactName}</p>:
                        <InputElement id="CustomerContactName" name="CustomerContactName" value={props.sop.CustomerContactName || ''} className={props.sopReq.CustomerContactName_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Customer Contact Name' />}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="projectName">Project Name</label>
                        {!editSOP ?
                        <p>{props.sop.ProjectName}</p>:
                        <InputElement id="ProjectName" name="ProjectName" value={props.sop.ProjectName || ''} className={props.sopReq.ProjectName_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Project Name' />}
                    </div>
                </div>
                {!editSOP ? <hr /> :''}
               
                <div className="row mb-2">
                    <div className="col-md-4">
                        <label htmlFor="dateCreat">Date Created</label>
                        {!editSOP ?
                        <p>{_dateCreated}</p>:
                        <DatePickerElement id="DateCreated" name="DateCreated" onChange={props.onChangeSop} 
                            minDate={new Date()}
                            value={props.sop.DateCreated}
                            selected={props.sop.DateCreated}
                            onInvalid={props.onValidateSop}
                            validation="required"
                            className={props.sopReq.DateCreated_ErrorClass}
                            maxDate={props.sop.StartDate ?
                                moment(new Date(props.sop.StartDate)).subtract(1, 'days').toDate()
                                : moment(new Date()).add(6, 'month').toDate()} />
                    }
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="startDate">Start Date</label>
                        {!editSOP ?
                        <p>{_startDate}</p> :
                        
                        <DatePickerElement id="StartDate" name="StartDate" onChange={props.onChangeSop} 
                            minDate={props.sop.DateCreated ?
                                moment(new Date(props.sop.DateCreated)).add(1, 'days').toDate()
                                : moment(new Date()).add(1, 'days').toDate()}
                            value={props.sop.StartDate}
                            selected={props.sop.StartDate}
                            onInvalid={props.onValidateSop}
                            validation="required"
                            className={props.sopReq.StartDate_ErrorClass}
                            maxDate={props.sop.EndDate ?
                                moment(new Date(props.sop.EndDate)).subtract(1, 'days').toDate()
                                : moment(new Date()).add(6, 'month').toDate()} />
                        }
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="endDate">End Date</label>
                        {!editSOP ?
                        <p>{_endDate}</p> :
                        <DatePickerElement id="EndDate" name="EndDate" onChange={props.onChangeSop} 
                            minDate={props.sop.StartDate ?
                                moment(new Date(props.sop.StartDate)).add(1, 'days').toDate()
                                : moment(new Date()).add(1, 'days').toDate()}
                            value={props.sop.EndDate}
                            selected={props.sop.EndDate}
                            onInvalid={props.onValidateSop}
                            validation="required"
                            className={props.sopReq.EndDate_ErrorClass}
                            maxDate={moment(new Date()).add(6, 'month').toDate()} />
                        }
                    </div>
                </div>
                {!editSOP ? <hr /> :''}
                <div className="row mb-2">
                    <div className="col-md-4">
                        <label htmlFor="ffLocation">Freight Force Locations</label>
                        {!editSOP ?
                        <p>{props.sop.FreightForceLocations}</p> :
                        <InputElement id="FreightForceLocations" name="FreightForceLocations" value={props.sop.FreightForceLocations || ''} className={props.sopReq.FreightForceLocations_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='Freight Force Locations' />}
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="ffSalesCont">FF Sales Contact</label>
                        {!editSOP ?
                        <p>{props.sop.FFSalesContact}</p> :
                        <InputElement id="FFSalesContact" name="FFSalesContact" value={props.sop.FFSalesContact || ''} className={props.sopReq.FFSalesContact_ErrorClass}
                        onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required"
                        placeholder='FF Sales Contact' />}
                    </div>

                </div>
                {!editSOP ? <hr /> :''}
                <div className="row mb-2">
                    <div className="col-md-12">
                        <label htmlFor="ffLocation">General Description</label>
                        {!editSOP ?
                        // <ul className="list">
                        <p>{props.sop.GeneralDescription} </p>
                        :
                          <TextareaElement id="GeneralDescription" name="GeneralDescription" rows="6" value={props.sop.GeneralDescription || ''} className={props.sopReq.GeneralDescription_ErrorClass}
                          onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required" placeholder='General Description' />      }
                    </div>
                </div>
                {!editSOP ? <hr /> :''}
                <div className="row mb-2">
                    <div className="col-md-12">
                        <label htmlFor="ffLocation">Procedures</label>
                        {!editSOP ?
                        // <ul className="list">
                        <p>{props.sop.Proceedures} </p>
                        :
                        <TextareaElement id="Proceedures" name="Proceedures" rows="6" value={props.sop.Proceedures || ''} className={props.sopReq.Proceedures_ErrorClass}
                          onChange={props.onChangeSop} onInvalid={props.onValidateSop} validation="required" placeholder='Procedures' />      }
                    </div>
                </div>
            </div>
            </>
            );
        }

            export default SalesSopData;