/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useEffect} from 'react';
import DataDimensions from './Data/DataDimensions'
import { useSelector, useDispatch} from 'react-redux';
import { addDimensions } from '../../../store/actions/actionTypes';
import SelectElement from "../../formControls/SelectElement";
 const Dimensions = (props)=>{
    const {handleAddDimensions,handleRemoveDimensions, handleRemoveAllDimensions, handleInputChange,
            copyDimensions, updateRoadRunnerValue,setRoadRunner, updateDimTotalsSt, dimensionList, errorStatus, totalDimensions} = DataDimensions();
    
        const quickQuoteDim = useSelector((state) => state.dim);

        const isDimensionsInfoEdit = props.isDimensionsInfoEdit;

        useEffect(() => {
                let mounted = true;
                if(mounted) {
                    if(props.isBooking){
                        copyDimensions(quickQuoteDim);
                    }
                }
                return () => mounted = false;
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, []) 

    useEffect(() => {
        if( props.clearAllDims === "true" ){
            handleRemoveAllDimensions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.clearAllDims]) 

    
    useEffect(() => {
        updateRoadRunnerValue(props.isRoadRunner);
        setRoadRunner(props.isRoadRunner);
       // copyDimensions(dimensionList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isRoadRunner]) 

    useEffect(() => {
    //console.log("ttlPcs=",totalDimensions.ttlPcs)
      if(totalDimensions.ttlPcs>0){
        updateDimTotalsSt(dimensionList, props.lineHaulSelVal, props.onChange);
      }
    }, [props.lineHaulSelVal])
    

    //console.log("value fo redux "+userProfile.userName); //"--"+pass+" -- "+token+" -- "+isLogin)
    const dispatch = useDispatch()
    //console.log("data="+JSON.stringify(data))

   	const updateDimTotals = () =>{
        
        dispatch(addDimensions(dimensionList));
        //updateDimTotalsSt(dimensionList, props.lineHaulSelVal, props.onChange);
        const totSum ={totalPcs:totalDimensions.ttlPcs,
            grossWeight:totalDimensions.grsWt,
            dimWeightTotal:totalDimensions.ttlDimWt}
            if( props.onChange ){
                props.onChange(totSum);
            }
    }
    
    const Quantity = "qty";
    const Weight = "weight";
    const Length = "length";
    const Width = "width";
    const Height = "height";
    const Description = "description";
    //const PackageType = "packageType";
    const styleTH3Per = {
        width: '3%'
      };
      const styleTH12Per = {
        width: '12%'
      };
      const styleTH30Per = {
        width: '24%'
      };
    //   const styleTH10Per = {
    //     width: '10%'
    //   };
      const styleTH13Per = {
        width: '13%'
      };

      const labelFont = {
        fontweight: 'bold',
        fontSize: 14,
        color:'#000000',
      };
      
      
      

    return(
        <div  className="modal fade" id="detDimensions" data-backdrop="static" data-keyboard="false" 
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Dimensions</h5>
                    <button type="button" className="close" onClick={updateDimTotals} data-dismiss="modal" aria-label="Close">
                        {/* <span aria-hidden="true">&times;</span> */}
                        <i className="fa fa-times-circle"></i>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="dimBody">
                        <table className="table table-borderless table-striped brdrTbl" id="dimTbl">
                            <thead className='darkHead'>
                                <tr>
                                    <th scope="col" style={styleTH3Per}> </th>
                                    <th scope="col" style={styleTH12Per}>Quantity</th>
                                    <th scope="col" style={styleTH13Per}>Weight (Lbs.)</th>
                                    <th scope="col" style={styleTH12Per}>Length (In.)</th>
                                    <th scope="col" style={styleTH12Per}>Width (In.)</th>
                                    <th scope="col" style={styleTH12Per}>Height (In.)</th>
                                    <th scope="col" style={styleTH30Per}>Description</th>
                                    <th scope="col" style={styleTH12Per}> </th>
                                </tr>
                            </thead>
                            <tbody>

                            { dimensionList.map((x, i) => {
                                return(<tr key={"dimension"+i}>
                                    <td  style={styleTH3Per}>{i+1}</td>
                                    <td style={styleTH12Per}><input type="text"  className={x.qty_ErrorClass}  pattern="[0-9]*" id={Quantity} value={x.qty || 0} name={Quantity}  onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
                                    <td style={styleTH13Per}><input type="text"  className={x.weight_ErrorClass}  pattern="[0-9]*" id={Weight} value={x.weight || 0} name={Weight} onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
                                    <td style={styleTH12Per}><input type="text"  className={x.length_ErrorClass}  pattern="[0-9]*" id={Length} value={x.length || 0} name={Length} onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
                                    <td style={styleTH12Per}><input type="text"  className={x.width_ErrorClass}  pattern="[0-9]*" id={Width} value={x.width || 0} name={Width} onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
                                    <td style={styleTH12Per}>
                                        <input type="text"  className={x.height_ErrorClass}  pattern="[0-9]*" id={Height} value={x.height || 0} name={Height} onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
                                    {/* {props.isRoadRunner &&
                                        <td style={styleTH12Per}>
                                            <select className={x.packageType_ErrorClass} onChange={(e) => handleInputChange(e, i)} name={PackageType} id={PackageType}  disabled={!isDimensionsInfoEdit}
                                                value={x.packageType || ""}>
                                                <option value="" key="-1">-Choose-</option>
                                                {props.packageTypeList.map((index, key)=> {
                                                    return (<option value={index.value} key={key}>{index.label}</option>);
                                                })}
                                            </select>
                                        </td>
                                    }        */}
                                    <td style={styleTH30Per}><input type="text"  className="form-control"  pattern="[0-9]*" id={Description} name={Description} value={x.description || ''} onChange={(e) => handleInputChange(e, i)} readOnly={!isDimensionsInfoEdit} /></td>
									<td style={styleTH12Per}> {isDimensionsInfoEdit && dimensionList.length !== 1 && <a className='mnsIco' title='Remove' onClick={() => {handleRemoveDimensions(i);}} ><i className='fa fa-minus-circle' ></i></a>}
                                    {isDimensionsInfoEdit && dimensionList.length - 1 === i && <a className="plusIco" title='Add' onClick={() => {handleAddDimensions();}}><i className="fa fa-plus-circle" aria-hidden="true"></i></a>}</td>
                                </tr>);
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(dimensionList)}</div> */}
                </div>

                {isDimensionsInfoEdit &&
                <div className="modal-footer">
                    <div className="col-md-12">
                        <div className="row mb-1">
                            <div className="col-md-4">
                                <label style={labelFont}>Total Pcs</label>
                                <input type="text" style={labelFont} className="form-control" id="ttlPcs" placeholder="" value={totalDimensions.ttlPcs || 0} readOnly/>
                            </div>
                            <div className="col-md-4">
                                <label style={labelFont}>Gross Weight</label>
                                <input type="text" style={labelFont} className="form-control" id="grsWt" placeholder="" value={totalDimensions.grsWt || 0} readOnly/>
                            </div>
                            <div className="col-md-4">
                                <label style={labelFont}>Dim Weight Total</label>
                                <input type="text" style={labelFont} className="form-control" id="ttlDimWt" placeholder="" value={totalDimensions.ttlDimWt || 0} readOnly/>
                            </div>
                        </div>
                    </div>  
                    <div className="col-md-12">
                        <div className="row mb-1">
                           
                            <div className="col-lg-3 col-md-4 offset-lg-6 offset-md-4">
                                <button type="button" className="btn btn-outline-primary btn-block pull-right" onClick={() => {handleRemoveAllDimensions();}}><i className="fas fa-times-circle"></i>  REMOVE ALL</button>
                            </div>
                            <div className="col-md-3 col-lg-3 col-md-4 ">
                            <button type="button" className="btn btn-primary btn-block pull-right" onClick={()=>{updateDimTotals();setTimeout(() => {updateDimTotals();},2000)}} data-dismiss={(!errorStatus)?"modal":""} aria-label="Close">OK &nbsp;<i className="fas fa-check-circle"></i> </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    );
}

export default Dimensions;