import React, { useEffect, useState } from 'react'
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../../formControls/InputElementMsg";

import SpecialRatesEditModel from "./SpecialRatesEditModel"
import {createSpecialRates,updateSpecialRates} from "../../../restAPI/TariffRatesAPI"
import DataSpecialNew from '../Data/DataSpecialNew';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
window.JSZip = jzip;

const SpecialCharges = (props) => {

    const { formData, formDataReq, handleInputChange,  onSubmitNewSpecial, onValidateNewSpecial, clearNewSpecialForm, setFormDataObj } = DataSpecialNew();
    useEffect(() => {
        props.restFunCall();
        setFormDataObj({terminal:props.airCode});
    }, [props.airCode])

    useEffect(() => {
        let table = $("#specialChargesTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
            "scrollCollapse": true,
            "paging": false,
            "bPaginate": false,
            //"autoWidth": true,
            "width": "100%",
            "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data": props.tariff,
            "dom":
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
                {
                    extend: 'copyHtml5',
                    className: 'btn btn-primary btn-sm',
                    text: '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Copy'
                },
                {
                    extend: 'excelHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text: '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Excel',
                    exportOptions: {
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: false
                        }
                    },

                },
                /*{
                    extend:    'csvHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'CSV'
                },*/
                {
                    extend: 'pdfHtml5',
                    text: '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                    className: 'btn btn-primary  btn-sm',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'PDF',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    customize: function (doc) {
                        doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                    }
                }
            ],
            "lengthMenu": [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
            ],
            "columns": [
                {
                    // className: 'dt-control', width: "35%",
                    orderable: false,
                    data: null, visible: true, "className": "notexport",
                    defaultContent: "<span class='specialedit'  alt='Edit Row' title='Edit Row'><i class='fa fa-pencil'/></span>",
                },
                { "data": "zone" },
                { "data": "currentRate.moN_FRI_0000_0759" },
                { "data": "updatedRate.moN_FRI_0000_0759" },
                {
                    "data": "currentRate.moN_FRI_0800_1700",
                    //   "render": function (data, type, row) {
                    //   if(data === true) return "Y";
                    //   if(data === false) return "N";
                    // }
                },
                { "data": "updatedRate.moN_FRI_0800_1700", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.moN_FRI_1701_2359" },
                { "data": "updatedRate.moN_FRI_1701_2359", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.saturday" },
                { "data": "updatedRate.saturday", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.sunday" },
                { "data": "updatedRate.sunday", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.holiday" },
                { "data": "updatedRate.holiday", "defaultContent": "<i>0</i>" },

            ],
            "columnDefs": [
                // { "width": "25%", "targets": 2 },
                //    { "targets": "_all", }
            ],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function (settings, json) {
                //jQuery(jQuery.fn.dataTable.tables(true)).DataTable().columns.adjust().draw();
            }
        });
        $('#specialChargesTbl tbody').on('click', 'tr td span.specialedit', function (event) {
            // , 'td.dt-control'
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var row_data = table.row(tr).data();
            // console.log(JSON.stringify(row_data))
            handleShow(row_data)
        });
    }, [props.tariff]);
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState([]);
    //const [formData, setFormData] = useState([]);
    const [showAddNew, setshowAddNew] = useState(false);
    let [innerLoading, setInnerLoading] = useState(false);
    const [responseMessage,setResponseMessage] = useState();
    const [responseStatus,setResponseStatus] = useState();

    
    const handleClose = () => setShow(false);
    const handleShow = (rowData) => {
          setEditData(rowData)
          setShow(true);
    }
  
    const handleOnchange = (name, value) => {
        setEditData({ ...editData, [name]: value });
    }
    const createNewRecord = () =>{
        
        console.log("create SpecialCharnages Save"+JSON.stringify(formData))
        var isValid = onSubmitNewSpecial();
        if (!isValid) {
            props.setLoad("Special",true);
            createSpecialRates(formData)
            .then(responseData =>{
                console.log("responseData=",responseData)
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                    //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
        
                } else {
                    //    console.log("responseData of create new Special rate ",JSON.stringify(responseData))
                    setResponseMessage(responseData);
                    setResponseStatus("alert alert-success text-center");
                }
            }).catch(err=>{
                    //console.log("err",err)
                    //err.error? = 404 || err? = 500
                    let emsg = err.error ? err.error.message : err.message;
                    setResponseMessage(emsg);
                    setResponseStatus("alert alert-danger text-center");
            }).finally(err =>{
                props.restFunCall();
                props.setLoad("Special",false);
            });
        setTimeout(()=>{
			setResponseMessage('');
            setResponseStatus('');
            setshowAddNew(!showAddNew);
		 },6000)
        }
      }
      const updateRecord = (formEditData) => {
        setInnerLoading(true);
        //console.log("fed1",formEditData)
        updateSpecialRates(formEditData,editData?.zone,props.airCode).then(responseData =>{
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " ,responseData)
                    setResponseMessage(responseData);
                    setResponseStatus("alert alert-success text-center");
                } else {
                    console.log("responseData of update tariff rate ",JSON.stringify(responseData))
                    if(responseData.includes("UNSUCCESSFUL")){
                        setResponseMessage(responseData);
                        setResponseStatus("alert alert-danger text-center");
                      }else{
                        setResponseMessage(responseData);
                      setResponseStatus("alert alert-success text-center");
                      }
                }
            }).catch(err=>{
                console.log("err",err)
                    let emsg = err.error ? err.error.message : err.message;
                   setResponseMessage(emsg);
                    setResponseStatus("alert alert-danger text-center");
            }).finally((o) =>{
                setInnerLoading(false);
            });
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
            },6000)
      }
    
    const clearNewTariff = () => {
        setshowAddNew(!showAddNew);
        clearNewSpecialForm();
    }
    return (
        <>
    <hr className="tabhr"/>
        <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'} />}
            <div className="card card-custom">
                {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
                <div id="datatable">
                    <table id="specialChargesTbl" className="display" style={{ width: "100%", border: "1px solid #ccc" }}>
                        <thead>
                            <tr>
                                <th>Edit</th>
                                <th>Zone</th>
                                <th>M-F 0000-0759</th>
                                <th>New M-F 0000-0759</th>
                                <th>M-F 0800-1700</th>
                                <th>New M-F 0800-1700</th>
                                <th>M-F 1701-2359</th>
                                <th>New M-F 1701-2359</th>
                                <th>SAT</th>
                                <th>New SAT</th>
                                <th>SUN</th>
                                <th>New SUN</th>
                                <th>Holiday</th>
                                <th>New Holiday</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                <SpecialRatesEditModel show={show} handleShow={handleShow} handleClose={handleClose} 
                    editData={editData} updateRecord={updateRecord}
                    resMessage={responseMessage} resStatus={responseStatus}
                    innerLoading={innerLoading} />
                <hr className="mb-3" />
                {!showAddNew ?
                    <div className="col-sm-3">
                        <button className="btn btn-primary btn-block" type="button" id='addNewZone' onClick={() => { setshowAddNew(!showAddNew) }}>
                            Add Special Rates</button>
                    </div> :
                    <>
                    { responseStatus && 
                        <h6 className={responseStatus}>{responseMessage} </h6>
                    }
                    <fieldset>
                        <legend>Add Special Rates</legend>
                        {/* <div>{JSON.stringify(formDataReq)}</div> */}
                        <div className="row mb-3">
                            <div className="col-md-4 ">
                                <label> New Zone : </label>
                                <InputElement id="zone" name="zone" className={formDataReq.zone_ErrorClass}
                                    onChange={handleInputChange}  onInvalid={onValidateNewSpecial}
                                    placeholder='Zone' />
                            </div>
                            <div className="col-md-4 ">
                                <label> New M-F 0000 - 0759 : </label>
                                <InputElement id="moN_FRI_0000_0759" name="moN_FRI_0000_0759" className={formDataReq.moN_FRI_0000_0759_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New M-F 0000-0759' />
                            </div>
                            <div className="col-md-4 ">
                                <label>New M-F 0800 - 1700 :</label>
                                <InputElement id="moN_FRI_0800_1700" name="moN_FRI_0800_1700" className={formDataReq.moN_FRI_0800_1700_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New M-F 0800-1700' />
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-md-4 ">
                                <label> New M-F 1701 - 2359 : </label>
                                <InputElement id="moN_FRI_1701_2359" name="moN_FRI_1701_2359" className={formDataReq.moN_FRI_1701_2359_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New M-F 1701-2359' />
                            </div>
                            <div className="col-md-4 ">
                                <label>New Saturday :</label>
                                <InputElement id="saturday" name="saturday" className={formDataReq?.saturday_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New Saturday' />
                            </div>
                            <div className="col-md-4 ">
                                <label>New Sunday : </label>
                                <InputElement id="sunday" name="sunday" className={formDataReq?.sunday_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New Sunday' />
                            </div>
                        </div>

                        <div className="row mb-3" >

                            <div className="col-md-4 ">
                                <label>New Holiday :</label>
                                <InputElement id="holiday" name="holiday" className={formDataReq?.holiday_ErrorClass}
                                    onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewSpecial}
                                    placeholder='New Holiday' />
                            </div>

                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-8">
                                {/* <h6 className={responseStatus}>{responseMessage} </h6> */}
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary btn-block" type="button" id='cancelSpecailCharges' onClick={clearNewTariff}>
                                    Cancel</button>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary btn-block" type="button" id='saveSpecailCharges' onClick={createNewRecord}>
                                    Save</button>
                            </div>
                        </div>
                    </fieldset>
                </>
                }
            </div>
        </div>
        </>
    )
}


export default SpecialCharges