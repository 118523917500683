import React, { useEffect ,useState} from "react";
import { HashLoader } from "react-spinners";

import InputElement from "../../../formControls/InputElement";
import { processWebAccountReq } from "../../restAPI/CorporateCenterLinkRest";
import DataWebAccount from "./Data/DataWebAccount";
import WebAccDatatable from "./WebAccDatatable";
// import WebAccountDatatable from './WebAccountDatatable'


const WebAccount = () => {
    const { handleInputChange, onSubmitSearch, filterData,handleSelectedUID, onValidateFilters,clearForm,loadReportData, refreshData,selectedUID,filtersInput, filtersReq, reportData, showDataLoading } = DataWebAccount();
    const [filterType,setFilterType] = useState();
    const [loading, setLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); // eslint-disable-line
    const [saveMessage, setSaveMessage] = useState({ showMsg : false, status : '' });
    const [reloadData, setReloadData] = useState("");

    const [showFunctionTable,setShowFunctionTable] = useState(false);
    const [showOptions, setShowOptions] = useState({
        validate : false,   activate : false,    suspend : false,    close : false,     admin : false
    })
    useEffect( ()=>{
        let accType = filtersInput.accountType;
        let _showOptions  = {};
        let showTable = false;
        if( accType === "R" ){ // Reg.
            _showOptions = { validate : true,    activate : false,   suspend : false,    close : false,    admin : false }
        }else if( accType === "AR" ){ // Activation/Reactivation
            _showOptions = { validate : false,   activate : false,   suspend : false,    close : false,    admin : false }
        }else if( accType === "A" ){ // Activate
            _showOptions = { validate : false,   activate : false,   suspend : true,     close : true,     admin : true  }
        }else if( accType === "S" ){ // Suspended
            _showOptions = { validate : false,   activate : true,    suspend : false,    close : true,     admin : false }
        }else if( accType === "C" ){ // Closed
            _showOptions = { validate : false,   activate : false,   suspend : false,    close : false,    admin : false }
        }else if( accType === "V" ){ // Need Activation / validation
            _showOptions = { validate : false,   activate : true,    suspend : false,    close : false,    admin : false }
        }
        showTable = Object.values(_showOptions).includes(true);
        setShowFunctionTable(showTable);
        setShowOptions({...showOptions, ..._showOptions});
        setFilterType(accType);
    },[filtersInput.accountType]);
    
    const isChecked = (selectedValues) => {
        let selectedIds =  Object.keys(selectedValues)
         handleInputChange("selectedIds", selectedIds);
         
     }

     const saveWebAccData = () => {
        let isValidationReq = onSubmitSearch();

        if (!isValidationReq) {
            setLoading(true);
            processWebAccountReq(filtersInput)
            .then(res => {
                if (res instanceof Error) {
                    // console.log("erro objecct is final = " + JSON.stringify(res))
                    setErrorStatus({ restError: true, restErrorMessage: res.title });
                    setSaveMessage({ showMsg : true, status : 'fail' });
                } else {
                    // setErrorStatus({ restError: false, restErrorMessage: '' });
                    //  console.log("res in final==" + JSON.stringify(res));
                    // dispatch(setQuickQuote(quoteReqRes));
                    if( res.userUpdateStatus==="SUCCESSFUL" )
                        setSaveMessage({ showMsg : true, status : 'success' });
                    else 
                        setSaveMessage({ showMsg : true, status : 'fail' });
                }
                setLoading(false);
                setTimeout(()=>{
                    setSaveMessage({ showMsg : false, status : '' });
                    clearForm();
                    //document.querySelector('#webAccontTbl').drag();
                    var event = new MouseEvent('dblclick', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                      });
                    document.getElementById('webAccontTbl').dispatchEvent(event);
                    // loadReportData();
                    if(!(res instanceof Error)){
                        setReloadData("true");
                    }
                },2000);
            })
        }
    }

    useEffect(() => {
        // console.log("reloadData",reloadData)
        if( reloadData ){
            setReloadData("")
            setShowFunctionTable(false)
            refreshData();
        }
    }, [reloadData]);


return (
    <div className={loading ? 'container parentDisable' : ''}>
            {loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'}/>}
    <div className="card card-custom">
        <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Web Account Administration</h4>
        </div>
        <form className="needs-validation">
            <div className="row mb-1">
                {/* <>{JSON.stringify(filtersReq)}</>
                <>{JSON.stringify(filtersInput)}</> */}
                <div className="col-md-12">
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="registeredAccounts" name="accountType"
                            onChange={handleInputChange} value="R"
                           // onClick={filterData}
                            checked={filtersInput.accountType === "R"}

                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="registeredAccounts">Registered By User</label>
                    </div>
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="reActiveAccounts" name="accountType"
                            onChange={handleInputChange} value="AR"
                            checked={filtersInput.accountType === "AR"}
                           // onClick={filterData}
                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="reActiveAccounts">Activation/ Re-Activation</label>
                    </div>
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="activeAccounts" name="accountType"
                            onChange={handleInputChange} value="A"
                            checked={filtersInput.accountType === "A"}
                           // onClick={filterData}
                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="activeAccounts">Active Accounts</label>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="suspendAccounts" name="accountType"
                            onChange={handleInputChange} value="S"
                            checked={filtersInput.accountType === "S"}
                          //  onClick={filterData}
                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="suspendAccounts">Suspended Accounts</label>
                    </div>
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="closedAccounts" name="accountType"
                            onChange={handleInputChange} value="C"
                            checked={filtersInput.accountType === "C"}
                           // onClick={filterData}
                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="closedAccounts">Closed Accounts</label>
                    </div>
                    <div className="col-md-3 custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="needActivationAccounts" name="accountType"
                            onChange={handleInputChange} value="V"
                            checked={filtersInput.accountType === "V"}
                           // onClick={filterData}
                            className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="needActivationAccounts">Need Activation</label>
                    </div>
                </div>
            </div>


            <hr />
            {
                showDataLoading ?
               //!filtersInput.showTable ?
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                        <HashLoader color={'#1E73BE'} loading={showDataLoading} />
                    </div>
                </div>
            // }
            // {    filtersInput.showTable &&
             :   <WebAccDatatable reportData={reportData} handleSelectedUID={handleSelectedUID} selectedUID={selectedUID} handleInputChange={handleInputChange} filterType={filterType} filtersInput={filtersInput} showDataLoading={showDataLoading} />
                // <WebAccountDatatable reportData={reportData} filtersInput={filtersInput} showDataLoading={showDataLoading} ischecked={isChecked}/>
            }
            <hr />
            {showFunctionTable && 
            <>
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase">Select a function below:</h4>
            </div>
            { saveMessage.showMsg &&
                <div className="row mb-3">
                    <div className="col-md-12 text-center text-bold">
                        { saveMessage.status==="fail" &&
                            <div className="alert alert-danger">
                                <i className="fas fa-times-circle"></i> Error in saving the data
                            </div>
                        }
                        { saveMessage.status==="success" &&
                            <div className="alert alert-success">
                                <i className="fas fa-check-circle"></i> Data save success
                            </div>
                        }
                    </div>
                </div>
            }
            <div className="row mb-1">
                <div className="col-md-12">
                    {showOptions.validate &&
                    <div className="custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="actionValidateAccount" name="actionType"
                            onChange={handleInputChange} value="V" onInvalid={onValidateFilters} validation="requiredRadio" 
                            checked={filtersInput.actionType === "V"}
                            className={filtersReq.actionType_ErrorClass} />
                        <label className="custom-control-label" htmlFor="actionValidateAccount">Validate</label>
                    </div>
                    }
                    {showOptions.activate &&
                    <div className="custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="actionActivateAccount" name="actionType"
                            onChange={handleInputChange} value="A" onInvalid={onValidateFilters} validation="requiredRadio" 
                            checked={filtersInput.actionType === "A"}
                            className={filtersReq.actionType_ErrorClass} />
                        <label className="custom-control-label" htmlFor="actionActivateAccount">Activate</label>
                    </div>
                    }
                    {showOptions.suspend &&
                    <div className="custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="actionSuspendAccount" name="actionType"
                            onChange={handleInputChange} value="S" onInvalid={onValidateFilters} validation="requiredRadio" 
                            checked={filtersInput.actionType === "S"}
                            className={filtersReq.actionType_ErrorClass} />
                        <label className="custom-control-label" htmlFor="actionSuspendAccount">Suspend Account</label>
                    </div>
                    }
                    {showOptions.close &&
                    <div className="custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="actionCloseAccount" name="actionType"
                            onChange={handleInputChange} value="C" onInvalid={onValidateFilters} validation="requiredRadio" 
                            checked={filtersInput.actionType === "C"}
                            className={filtersReq.actionType_ErrorClass} />
                        <label className="custom-control-label" htmlFor="actionCloseAccount">Close Account</label>
                    </div>
                    }
                    {showOptions.admin &&
                    <div className="custom-control custom-radio custom-control-inline">
                        <InputElement type="radio" id="actionAdminAccount" name="actionType"
                            onChange={handleInputChange} value="Admin" onInvalid={onValidateFilters} validation="requiredRadio" 
                            checked={filtersInput.actionType === "Admin"}
                            className={filtersReq.actionType_ErrorClass} />
                        <label className="custom-control-label" htmlFor="actionAdminAccount">Assign as new Administrator</label>
                    </div>
                    }
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-4 col-lg-3 ">
                    <label htmlFor="selectedUID" >Selected User UID:</label>
                </div>
                <div className="col-md-4 col-lg-3 ">
                    <InputElement type="text" id="selectedUID" name="selectedUID"
                        onChange={handleInputChange} validation="required" className={filtersReq.selectedUID_ErrorClass}
                        value={filtersInput.selectedUID || ''} onInvalid={onValidateFilters} readOnly={true}
                    />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </div>
            <div className="row  mb-3">
                <div className="col-md-4 col-lg-3">
                    <label htmlFor="reason" >Reason (Optional):</label>
                </div>
                <div className="col-md-4 col-lg-3">
                    <InputElement type="text" id="reason" name="reason"
                        onChange={handleInputChange}
                        value={filtersInput.reason || ''}
                    />
                </div>
                <div className="col-md-4 col-lg-3">
                    <button className="btn btn-primary btn-block pull-right" type="button" onClick={saveWebAccData}>Update Now</button>
                </div>
            </div>
            </>
            }

        </form>
        {/* <div>{JSON.stringify(filtersInput)}</div> */}
    </div>
    </div>
);
}

export default WebAccount;