import React, { useEffect } from "react";
import { GridLoader, HashLoader } from "react-spinners";
import DataLinkApprovedMotorCarriers from "./Data/DataLinkApprovedMotorCarriers";
import InputElement from "../../../formControls/InputElement";
import { useState } from "react";
import { addLinkCarrier, removeLinkCarrier } from '../../restAPI/CorporateCenterLinkRest'
import LinkApprovedMcDataTable from './LinkApprovedMcDataTable';

const LinkApprovedMotorCarriers = () => {
    const { handleInputChange, onSubmitForm, onValidateFilters, loadReportData, refreshData, filtersInput, clearForm, filtersReq, reportData, showDataLoading } = DataLinkApprovedMotorCarriers();

    const [loading, setLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' }); // eslint-disable-line
    const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
    const [reloadData, setReloadData] = useState("");

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const saveLinkAmc = () => {
        let isValidationReq = onSubmitForm();

        if (!isValidationReq) {
            setLoading(true);
            if (filtersInput.accountType === "addLinkAc") {
                addLinkCarrier(filtersInput.from, filtersInput.to)
                    .then(linkCaRes => {
                        if (linkCaRes instanceof Error) {
                            // console.log("erro objecct is final = " + JSON.stringify(linkCaRes))
                            setErrorStatus({ restError: true, restErrorMessage: linkCaRes.message });
                            setSaveMessage({ showMsg: true, status: 'error', message: linkCaRes.message });
                        } else {
                            setErrorStatus({ restError: false, restErrorMessage: '' });
                            // console.log("linkCaRes in final==" + JSON.stringify(linkCaRes));
                            // dispatch(setQuickQuote(quoteReqRes));
                            setSaveMessage({ showMsg: true, status: 'msg', message: linkCaRes });
                            // loadReportData();
                            // clearForm();
                        }
                        setLoading(false);
                        setTimeout(()=>{
                            setSaveMessage({ showMsg : false, status : '', message: '' });
                            clearForm();
                            if(linkCaRes==="Accounts have been linked"){
                                setReloadData("true");
                            }
                        },2000);
                    })
            } else if (filtersInput.accountType === "delLinkAc") {
                removeLinkCarrier(filtersInput.from, filtersInput.to)
                    .then(linkCaRes => {
                        if (linkCaRes instanceof Error) {
                            // console.log("erro objecct is final = " + JSON.stringify(linkCaRes))
                            setErrorStatus({ restError: true, restErrorMessage: linkCaRes.message });
                            setSaveMessage({ showMsg: true, status: 'error', message: linkCaRes.message });
                        } else {
                            setErrorStatus({ restError: false, restErrorMessage: '' });
                            // console.log("linkCaRes in final==" + JSON.stringify(linkCaRes));
                            // dispatch(setQuickQuote(quoteReqRes));
                            setSaveMessage({ showMsg: true, status: 'msg', message: linkCaRes });
                            // loadReportData();
                            // clearForm();
                        }
                        setLoading(false);
                        setTimeout(()=>{
                            setSaveMessage({ showMsg : false, status : '', message: '' });
                            clearForm();
                            if(linkCaRes.indexOf("sucessfully removed")!==-1){
                                setReloadData("true");
                            }
                        },2000);
                    })
            }
        }
    }

    useEffect(() => {
        // console.log("reloadData",reloadData)
        if( reloadData ){
            refreshData();
            setReloadData("")
        }
    }, [reloadData]);

    return (
        <div className={loading ? 'container parentDisable' : ''}>
            {loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'}/>}
            
        <div className="card card-custom">
            <div className="d-flex">
                <h4 className="mb-2 pageHead text-uppercase">Link Approved Motor Carriers</h4>
            </div>
            {
                showDataLoading ?
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-center">
                            <HashLoader color={'#1E73BE'} loading={showDataLoading} />
                        </div>
                    </div>
                    :
                    <LinkApprovedMcDataTable reportData={reportData} showDataLoading={showDataLoading} />
            }
            <hr className="mb-3" />
            <div className="d-flex">
                <h5 className="mb-3">Perform Link Approved Motor Carriers</h5>
            </div>
            <form className="needs-validation" >
                {saveMessage.showMsg &&
                    <div className="row mb-3">
                        <div className="col-md-12 text-center text-bold">
                            {saveMessage.status === "error" ?
                                <div className="alert alert-danger">
                                    <i className="fas fa-times-circle"></i> 
                                    {saveMessage.message}
                                </div>
                                :
                                <div className="alert alert-primary">
                                    {saveMessage.message}
                                </div>
                            }
                            {/* {saveMessage.status === "success" &&
                                <div className="alert alert-success">
                                    <i className="fas fa-check-circle"></i> Data save success
                                </div>
                            } */}
                        </div>
                    </div>
                }
                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="addLinkAc" name="linkAc"
                                className="custom-control-input" />
                            <InputElement type="radio" id="accountTypeAddLinkAc" name="accountType"
                                onChange={handleInputChange} value="addLinkAc" onInvalid={onValidateFilters}
                                checked={filtersInput.accountType === "addLinkAc"}
                                validation="requiredRadio"
                                className={filtersReq.accountType_ErrorClass} />

                            <label className="custom-control-label" htmlFor="accountTypeAddLinkAc">Add Link Account</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="delLinkAc" name="linkAc"
                                className="custom-control-input" />
                            <InputElement type="radio" id="accountTypeDelLinkAc" name="accountType"
                                onChange={handleInputChange} value="delLinkAc" onInvalid={onValidateFilters}
                                checked={filtersInput.accountType === "delLinkAc"}
                                validation="requiredRadio"
                                className={filtersReq.accountType_ErrorClass} />
                            <label className="custom-control-label" htmlFor="accountTypeDelLinkAc">Delete Link Account</label>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-4 col-md-6">
                        <label htmlFor="from">From</label>
                        <InputElement type="text" id="from" name="from"
                            onChange={handleInputChange}
                            value={filtersInput.from || ''} maxLength="25"
                            className={filtersReq.from_ErrorClass}
                            validation="required" onInvalid={onValidateFilters}
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <label htmlFor="to">To</label>
                        <InputElement type="text" id="to" name="to"
                            onChange={handleInputChange}
                            value={filtersInput.to || ''} maxLength="25"
                            className={filtersReq.to_ErrorClass}
                            validation="required" onInvalid={onValidateFilters}
                        />
                        <div className="invalid-feedback">
                            required.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 offset-lg-2 offset-md-4" >
                        <button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={clearForm}><i className="fas fa-times-circle"></i> Cancel</button>
                    </div>
                    <div className="col-lg-3 col-md-4 ">
                        <button className="btn btn-primary btn-block pull-right" type="button" onClick={saveLinkAmc}>Save &nbsp;<i className="fas fa-check-circle"></i>  </button>
                    </div>
                </div>
            </form>
        </div>
        </div>
    );
}

export default LinkApprovedMotorCarriers;