import React, { useEffect, useState } from 'react'
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../../formControls/InputElement";
import LinehaulEditModel from "./LinehaulEditModel";
import {createTariffRate} from "../../../restAPI/TariffRatesAPI"
import DataTariffNew from '../Data/DataTariffNew';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const LinehaulRate = (props) => {

    const { formData, formDataReq, handleInputChange,  onSubmitNewTariff, onValidateNewTariff, clearNewTariffForm, setFormDataObj } = DataTariffNew();

    useEffect(()=>{
        props.restFunCall();
        //setFormData({ ...formData, carrier: props.airCode });
        setFormDataObj({carrier: props.airCode });
      },[props.airCode])

    useEffect(() => {
        
        let table = $("#linehaulRateTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : props.tariff,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                {
                    // className: 'dt-control', width: "35%",
                    orderable: false,
                    data: null,  visible: true, "className": "notexport",
                    defaultContent: "<span class='tariffedit'  alt='Edit Row' title='Edit Row'><i class='fa fa-pencil'/></span>" ,  
                },
                { "data" : "area" },
                { "data" : "currentTariffs.min"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.min"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs100"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs100"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs500"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs500"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs1000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs1000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs2000" ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs2000" ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs3000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs3000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs5000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs5000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs10000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs10000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs15000" ,"defaultContent": "<i>0</i>" },
                { "data" : "updatedTariffs.lbs15000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.lbs20000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.lbs20000"  ,"defaultContent": "<i>0</i>"},
                { "data" : "currentTariffs.capRate"  ,"defaultContent": "<i>0</i>"},
                { "data" : "updatedTariffs.capRate"  ,"defaultContent": "<i>0</i>"}
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    // "className": "text-center",
                    // "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            },
            
    });

    $('#linehaulRateTbl tbody').on('click', 'tr td span.tariffedit', function (event) {
        // , 'td.dt-control'
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        var row_data = table.row(tr).data();
       // console.log(JSON.stringify(row_data))
       handleShow(row_data)
    });
  // Activate an inline edit on click of a table cell
    // table.on('click', 'tbody td.row-edit', function (e) {
    //     editor.inline(table.cells(this.parentNode, '*').nodes(), {
    //         submitTrigger: -2,
    //         submitHtml: '<i class="fa fa-play"/>'
    //     });
    //});
    
    },[props.tariff]);

      const [show, setShow] = useState(false);
	  const [editData, setEditData] = useState([]);
	  //const [formData, setFormData] = useState([]);
	  const [showAddNew, setshowAddNew] = useState(false);
	  let [innerLoading, setInnerLoading] = useState(false);
      const [responseMessage,setResponseMessage] = useState();
      const [responseStatus,setResponseStatus] = useState();

      
	  const handleClose = () => setShow(false);
	  const handleShow = (rowData) => {
			setEditData(rowData)
			setShow(true);
	  }

      const handleOnchange = (name,value) => {
		setEditData({ ...editData, [name]: value });
	  }

      const createNewRecord = () =>{

        var isValidationReqNewTariff = onSubmitNewTariff();
        //console.log("Line Haul Reate Save"+JSON.stringify(formData))
            
        if (!isValidationReqNewTariff) {
            props.setLoad("TariffRates",true);
            createTariffRate(formData).then(responseData =>{
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " ,responseData)
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                } else {
                    console.log("responseData of create new tariff rate ",JSON.stringify(responseData))
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                }
            }).catch(err=>{
                //console.log("err",err)
                   setResponseMessage(err.message);
                    setResponseStatus("alert alert-danger text-center");
            }).finally((o) =>{
                props.restFunCall();
                props.setLoad("TariffRates",false);
            });
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
                setshowAddNew(!showAddNew);
            },6000)
      }
    }

	const handleSubmit = () => {
		setInnerLoading(true);
		console.log("fd1",formData)
		//submit ajax call here
		setTimeout(()=>{
			setInnerLoading(false);
			setShow(false);
		 },1000)
	}

    
    const clearNewTariff = () => {
        setshowAddNew(!showAddNew);
        clearNewTariffForm();
    }


    return (
        <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'}/>}
        <div className="card card-custom">
        {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
        <div id="datatable">
            <table id="linehaulRateTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Edit</th>
                        <th>Zone</th>
                        <th>Min</th>
                        <th>New Min</th>
                        <th>100</th>
                        <th>New 100</th>
                        <th>500</th>
                        <th>New 500</th>
                        <th>1000</th>
                        <th>New 1000</th>
                        <th>2000</th>
                        <th>New 2000</th>
                        <th>3000</th>
                        <th>New 3000</th>
                        <th>5000</th>
                        <th>New 5000</th>
                        <th>10000</th>
                        <th>New 10000</th>
                        <th>15000</th>
                        <th>New 15000</th>
                        <th>20000</th>
                        <th>New 20000</th>
                        <th>Cap</th>
                        <th>New Cap</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <LinehaulEditModel show={show} handleShow={handleShow} handleClose={handleClose} 
			editData={editData} formData={formData}
			handleOnchange={handleOnchange} handleSubmit={handleSubmit}
			innerLoading={innerLoading} />
        <hr className="mb-3"/>
            {!showAddNew ?
            <div className="col-sm-2">
                <button className="btn btn-primary btn-block" type="button" id='addNewZone' onClick={()=>{setshowAddNew(!showAddNew)}}>
                Add New Zone</button> 
            </div> : 
            <>
            { responseStatus && 
                <h6 className={responseStatus}>{responseMessage} </h6>
            }
        <fieldset>
            <legend>Add New Zone</legend>
            {/* <h3 className="mb-3 pageHead alignCenter"></h3> */}
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label> Area :</label>
                            <InputElement id="area" name="area" className={formDataReq.area_ErrorClass}
                                onChange={handleInputChange}  validation="required" onInvalid={onValidateNewTariff}
                                placeholder='Area' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label> Min :</label>
                            <InputElement id="newMin" name="newMin" className={formDataReq.newMin_ErrorClass}
                                onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                                placeholder='Min Value' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label>Lbs 100 : </label>
                            <InputElement id="newLbs100" name="newLbs100" className={formDataReq.newLbs100_ErrorClass}
                                onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                                placeholder='Lbs 100' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>                       
                    </div>
                    <div className="row mb-3" >
                    <div className="col-md-4">
                            <label>Lbs 500 : </label>
                            <InputElement id="newLbs500" name="newLbs500" className={formDataReq.newLbs500_ErrorClass}
                                onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                                placeholder='Lbs 500' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4 "><label>Lbs 1000 :</label>
                        <InputElement id="newLbs1000" name="newLbs1000" className={formDataReq.newLbs1000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 1000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4"><label>Lbs 2000 :</label> 
                        <InputElement id="newLbs2000" name="newLbs2000" className={formDataReq.newLbs2000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 2000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="row mb-3" >
                    <div className="col-md-4"><label>Lbs 3000 :</label> 
                        <InputElement id="newLbs3000" name="newLbs3000" className={formDataReq.newLbs3000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 3000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    <div className="col-md-4"><label>Lbs 5000 :</label> 
                        <InputElement id="newLbs5000" name="newLbs5000" className={formDataReq.newLbs5000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 5000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4 "><label>Lbs 10000 :</label>
                        <InputElement id="newLbs10000" name="newLbs10000" className={formDataReq.newLbs10000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 10000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="row mb-3" >
                    
                    <div className="col-md-4"><label>Lbs 15000 :</label> 
                        <InputElement id="newLbs15000" name="newLbs15000" className={formDataReq.newLbs15000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff} 
                            placeholder='Lbs 15000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4 "><label>Lbs 20000 :</label>
                        <InputElement id="newLbs20000" name="newLbs20000" className={formDataReq.newLbs20000_ErrorClass}
                            onChange={handleInputChange}  validation="integerOnly" onInvalid={onValidateNewTariff}
                            placeholder='Lbs 20000' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                        <div className="col-md-4"><label>Cap Rate:</label> 
                        <InputElement id="newCapRate" name="newCapRate" className={formDataReq.newCapRate_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewTariff}
                            placeholder='Cap Rate' />
                            <div className="invalid-feedback">
                                required.
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-8">
                            
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary btn-block" type="button" id='savenewzone' onClick={clearNewTariff}>
                                Cancel</button> 
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary btn-block" type="button" id='savenewzone' onClick={createNewRecord}>
                                Save</button> 
                        </div>
                    </div>
                </fieldset>
            </>
            }
      
        </div>
        </div>
    
        )
}

export default LinehaulRate