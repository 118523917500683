import { useState } from 'react';

const DataTerminals = () => {

    const [lineHualTermOpts, setLineHualTermOpts] = useState([]);
    const [roadRunnerTermOpts, setRoadRunnerTermOpts] = useState([]);
    const [fffTermOpts, setFffTermOpts] = useState([]);
    const [accessorialOpts, setAccessorialOpts] = useState([]);




    const setLineHualTermOptsList = (lineHaul) => {
        let selectObj = [];
        lineHaul.forEach((eachrow) => {
            //  console.log({"value":eachrow.id,"label":eachrow.name}) ;
            selectObj.push({ "value": eachrow.AirportCode, "label": eachrow.AirportCode });
        })
        setLineHualTermOpts(selectObj);
    }

    const setRoadRunnerTermOptsList = (roadRunner) => {
        let selectObj = [];
        roadRunner.forEach((eachrow) => {
            //  console.log({"value":eachrow.id,"label":eachrow.name}) ;
            selectObj.push({ "value": eachrow.AirportCode, "label": eachrow.AirportCode });
        })
        setRoadRunnerTermOpts(selectObj);
    }

    const setfffTermOptsList = (carriers) => {
        let selectObj = [];
        carriers.forEach((eachrow) => {
            //  console.log({"value":eachrow.id,"label":eachrow.name}) ;
            selectObj.push({ "value": eachrow.AirportCode, "label": eachrow.AirportCode });
        })
        setFffTermOpts(selectObj);
    }

    const setAccessorialOptsList = (accessorial) => {
        let selectObj = [];
        //console.log(accessorial+"acc"+JSON.stringify(accessorial))
        if(accessorial !== "null"){
        accessorial?.forEach((eachrow) => {
            //  console.log({"value":eachrow.id,"label":eachrow.name}) ;
            selectObj.push({ "value": eachrow.description, "label": eachrow.description });
        })
        setAccessorialOpts(selectObj);
        }
    }



    return {
        setLineHualTermOptsList,
        setfffTermOptsList,
        setAccessorialOptsList,
        setRoadRunnerTermOptsList,
        roadRunnerTermOpts,
        lineHualTermOpts,
        fffTermOpts,
        accessorialOpts
    }
}
export default DataTerminals;