import React,{useState,useEffect} from "react";
import InputElement from "../../formControls/InputElement";
import SelectElement from "../../formControls/SelectElement";
import Autocomplete from 'react-autocomplete';
import { saveAddress, updateAddress, getAddress,deleteAddress } from "../restAPI/BookQuoteRest";


const DirectBook2 = (props) => {

    const userProfileData = JSON.parse(localStorage.getItem("userProfile"));
    
    const [addressList,setAddressList] = useState([]);
    const [shiAddrVal,setShiAddrVal] = useState({ val: '' });
    const [consiAddrVal,setConsiAddrVal] = useState({ val: '' });
    const [selShiAddr,setSelShiAddr] = useState({ addressId : 0 });
    const [selConsiAddr,setSelConsiAddr] = useState({ addressId : 0 });
    const [shiStatus,setShiStatus] = useState({ message : '' ,error:'' });
    const [consiStatus,setConsiStatus] = useState({ message : '',error:''});
    const [shipSelAddress,setShipSelAddress] = useState({});
    const [consiSelAddress,setconsiSelAddress] = useState({});
   
    
    const  fetchAddress = () =>{
    getAddress(userProfileData.userid)
    .then(addrList => {
       // console.log("address list loaded "+JSON.stringify(addrList))
        setAddressList(addrList);
    })
    }
 useEffect(() => {
        let mounted = true;
        if (mounted) {
            fetchAddress();
          return () => mounted = false;
        }
      }, [])// eslint-disable-line

    const shipAddress = () => {
        const shipAddr = {
            "addressId":selShiAddr.addressId,
            "userId": userProfileData.userid,
            "name": shiAddrVal.val,
            "address1": props.quickBook.shiAddress,
            "address2": props.quickBook.shiAddress,
            "city": props.quickBook.shiCity,
            "state": props.quickBook.shiState,
            "zipCode": props.quickBook.shiZipcode,
            "contact": props.quickBook.shiContact,
            "phone": props.quickBook.shiPhone ?  props.quickBook.shiPhone.replaceAll("-","") :''
        };
        return shipAddr;
    }
    const consiAddress = () => {
        const consiAddr = {
            "addressId":selConsiAddr.addressId,
            "userId": userProfileData.userid,
            "name": consiAddrVal.val,
            "address1": props.quickBook.consiAddress,
            "address2": props.quickBook.consiAddress,
            "city": props.quickBook.consiCity,
            "state": props.quickBook.consiState,
            "zipCode": props.quickBook.consiZipcode,
            "contact": props.quickBook.consiContact,
            "phone":  props.quickBook.consiPhone ?  props.quickBook.consiPhone.replaceAll("-","") :''
        };
        return consiAddr;
    }

    useEffect(() => {
        setShiAddrVal( {val: ''});
        setConsiAddrVal({val: ''});
      }, [props.quickBook.lineHaulServiceType])
    
    const clearMessage = () =>{
        setTimeout(() => {
           setShiStatus({message:'',error:''});
           setConsiStatus({message:'',error:''});
           fetchAddress();
       }, 3000);
    }
    
    const saveShipperAddr = () => {
        let isValidation = props.validateAddress("Ship");
        //console.log("Address ship validation",isValidation)
        if (!isValidation) {
        const shipAddr = shipAddress();
        saveAddress(shipAddr).then((responseData) => {
            if (responseData instanceof Error) {
                setShiStatus({error:responseData});
            } else {
                setShiStatus({message:responseData});
            }
        }).catch(err => {

        })
    }
    clearMessage();
    }
    
    const saveConsigneeAddr = () => {
        let isValidation = props.validateAddress("Consi");
        //console.log("Address Consi validation",isValidation)
        if (!isValidation) {
        const consiAddr = consiAddress();
        saveAddress(consiAddr).then((responseData) => {
            if (responseData instanceof Error) {
                setConsiStatus({error:responseData});
            } else {
                setConsiStatus({message:responseData});
               
            }
        }).catch(err => {

        })
    }
    clearMessage();
    }
    const updateShipperAddr = () => {
        let isValidation = props.validateAddress("Ship");
        if (!isValidation) {
        const shipAddr = shipAddress();
        updateAddress(shipAddr).then((responseData) => {
            if (responseData instanceof Error) {
                setShiStatus({error:responseData});
            } else {
                setShiStatus({message:responseData});
           
            }
        }).catch(err => {

        })
        }
        clearMessage();
    }
    const updateConsigneeAddr = () => {
        let isValidation = props.validateAddress("Consi");
        if (!isValidation) {
        const consiAddr = consiAddress();
        updateAddress(consiAddr).then((responseData) => {
            if (responseData instanceof Error) {
                setConsiStatus({error:responseData});
            } else {
                setConsiStatus({message:responseData});
               
            }
        }).catch(err => {

        })
    }
    clearMessage();
    }

    const deleteShipAddr = () => {
        if (selShiAddr.addressId) {
            deleteAddress(selShiAddr.addressId).then((responseData) => {
            if (responseData instanceof Error) {
                setShiStatus({error:responseData});
            } else {
                setShiStatus({message:responseData});
            }
        }).catch(err => {

        })
    }
    clearMessage();
    }

    const deleteConsiAddr = () => {
        if (selConsiAddr.addressId) {
            deleteAddress(selConsiAddr.addressId).then((responseData) => {
            if (responseData instanceof Error) {
                setConsiStatus({error:responseData});
            } else {
                setConsiStatus({message:responseData});
            }
        }).catch(err => {

        })
    }
    clearMessage();
    }

    const updateShipCompName = (val)=>{
        props.updateAddress({shiCompanyName:val});
        setSelShiAddr({addressId:0});
       // props.onValidateBookHandler("shiCompanyName",val? false:true);
    }
    const updateConsiCompName = (val)=>{
        props.updateAddress({consiCompanyName:val});
        setSelConsiAddr({addressId:0});
       // props.onValidateBookHandler("consiCompanyName",val? false:true);
    }

    
    const updateShipAddrQuote = (item) =>{
        let shipAd = { shiCompanyName: item.name,
        shiAddress: item.address1,
        shiCity: item.city,
        shiState: item.state,
        shiZipcode: item.zipCode,
        shiContact: item.contact,
        shiPhone: item.phone};
        setSelShiAddr({addressId:item.addressId})
            props.updateAddress(shipAd);
    }

    const updateConsiAddrQuote = (item) =>{
        let consiAd = { 
        consiCompanyName: item.name,
        consiAddress: item.address1,
        consiCity: item.city,
        consiState: item.state,
        consiZipcode: item.zipCode,
        consiContact: item.contact,
        consiPhone: item.phone}
        setSelConsiAddr({addressId:item.addressId})
        props.updateAddress(consiAd);
     
    }
    

    const  renderShipAddr=(shiAddrVal, val) =>{
        return (
            shiAddrVal.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
    }
    const  renderConsiAddr=(consiAddrVal, val) =>{
        return (
            consiAddrVal.name.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );
        }
    return (
        <div>
            <div className="row minusMargins">
                <div className="col-md-6 text-center">
                    <label className="bold14">SHIPPER INFORMATION</label>
                </div>
              
                <div className="col-md-6 text-center">
                    <label className="bold14">CONSIGNEE INFORMATION</label>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                {props.quickBook.isShipperInfoEdit ? <>
                    <label htmlFor="shiCompanyName">Company Name</label> 
                    <div className="autocomplete-wrapper">
                    <Autocomplete
                        items={addressList}
                        getItemValue={item => item.name}
                        shouldItemRender={renderShipAddr}
                        renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                        renderItem={(item, highlighted) =>
                            <div key={item.addressId} 
                                className={highlighted ? 'autocomplete-item-highlight' : 'autocomplete-item' } >
                                {item.name+", "+item.address1+", "+item.city+", "+item.state+", "+item.zipCode }
                            </div>
                        }
                        value={shiAddrVal.val}
                        onChange={(event, val) => {setShiAddrVal({ val });updateShipCompName(val)}}
                        onSelect={(val,item )=> {setShiAddrVal({ val });updateShipAddrQuote(item)}}
                        inputProps={{ style: { width: '100%'  }, placeholder: 'Company Name' }}
                    />
                    </div>
                    <div className="invalid-feedback">
                            required.
                        </div>
                    </>
                   :
                  <>
                 <label htmlFor="shiCompanyName">Company Name</label>
                    <InputElement id="shiCompanyName" name="shiCompanyName" value={props.quickBook.shiCompanyName || ''} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.shiCompanyName_ErrorClass}
                        readOnly={!props.quickBook.isShipperInfoEdit} placeholder='Company Name' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                     </> }
                </div>
                <div className="col-md-6 mb-3">
                {props.quickBook.isConsigneeInfoEdit ? <>
                    <label htmlFor="shiCompanyName">Company Name</label>
                    <div className="autocomplete-wrapper">
                    <Autocomplete
                        items={addressList}
                        getItemValue={item => item.name}
                        shouldItemRender={renderConsiAddr}
                        renderMenu={item => (
                                <div className="dropdown">
                                    {item}
                                </div>
                            )}
                        renderItem={(item, highlighted) =>
                            <div key={item.addressId+item.name} 
                                className={highlighted ? 'autocomplete-item-highlight' : 'autocomplete-item' } >
                                {item.name+", "+item.address1+", "+item.city+", "+item.state+", "+item.zipCode }
                            </div>
                        }
                        value={consiAddrVal.val}
                        onChange={(event, val) => {setConsiAddrVal({ val });updateConsiCompName(val)}}
                        onSelect={(val,item )=> {setConsiAddrVal({ val });updateConsiAddrQuote(item)}}
                        inputProps={{ style: { width: '100%'  }, placeholder: 'Company Name' }}
                    />
                    {/* <div>{JSON.stringify(consiAddrVal)+JSON.stringify(selConsiAddr)}</div> */}
                   
                    {/* <div className="invalid-feedback">
                            required.
                        </div> */}
                        </div>
                    </>
                    :<>
                    <label htmlFor="consiCompanyName">Company Name</label>
                    <InputElement id="consiCompanyName" name="consiCompanyName" value={props.quickBook.consiCompanyName || ''} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.consiCompanyName_ErrorClass}
                        readOnly={!props.quickBook.isConsigneeInfoEdit} placeholder='Company Name' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                    </>}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label htmlFor="shiAddress">Address</label>
                    <InputElement id="shiAddress" name="shiAddress" value={props.quickBook.shiAddress} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.shiAddress_ErrorClass}
                        readOnly={!props.quickBook.isShipperInfoEdit} placeholder='Address' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
                <div className="col-md-6 mb-3">
                    <label htmlFor="consiAddress">Address</label>
                    <InputElement id="consiAddress" name="consiAddress" value={props.quickBook.consiAddress} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.consiAddress_ErrorClass}
                        readOnly={!props.quickBook.isConsigneeInfoEdit} placeholder='Address' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2 mb-2">
                    <label htmlFor="shiCity">City</label>
                    <InputElement id="shiCity" name="shiCity" value={props.quickBook.shiCity} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.shiCity_ErrorClass}
                        readOnly={!props.quickBook.isShipperInfoEdit} placeholder='City' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>

                <div className="col-md-2 mb-2">
                    <label htmlFor="shiState">State</label>
                    <SelectElement id="shiState" name="shiState" value={props.quickBook.shiState || ''} optionsData={props.stateList} onChange={props.onChangeBookHandler}
                        disabled={!props.quickBook.isShipperInfoEdit}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.shiState_ErrorClass}
                        placeholder='Choose State...' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label htmlFor="shiZipcode">Zip</label>
                    <InputElement id="shiZipcode" name="shiZipcode" value={props.quickBook.shiZipcode} onChange={props.onChangeBookHandler}
                        disabled={!props.quickBook.isShipperInfoEdit} placeholder='Zip'
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.shiZipcode_ErrorClass} />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label htmlFor="consiCity">City</label>
                    <InputElement id="consiCity" name="consiCity" value={props.quickBook.consiCity} onChange={props.onChangeBookHandler}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.consiCity_ErrorClass}
                        readOnly={!props.quickBook.isConsigneeInfoEdit} placeholder='City' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label htmlFor="consiState">State</label>
                    <SelectElement id="consiState" name="consiState" value={props.quickBook.consiState || ''} optionsData={props.stateList} onChange={props.onChangeBookHandler}
                        disabled={!props.quickBook.isConsigneeInfoEdit}
                        validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.consiState_ErrorClass}
                        placeholder='Choose State...' />
                    <div className="invalid-feedback">
                            required.
                        </div>
                </div>
                <div className="col-md-2 mb-2">
                    <label htmlFor="consiZipcode">Zip</label>
                    <InputElement id="consiZipcode" name="consiZipcode" value={props.quickBook.consiZipcode} onChange={props.onChangeBookHandler}
                         disabled={!props.quickBook.isConsigneeInfoEdit} placeholder='Zip' 
                         validation="required" onInvalid={props.onValidateBookHandler} className={props.quickBookRequired.consiZipcode_ErrorClass}/>
                   <div className="invalid-feedback">
                            required.
                        </div>
                </div>
            </div>
            {/* {props.isRoadRunner ? 
            <div className="row">
                <div className="col-md-2 mb-3">
                    <label htmlFor="shiContact">Contact</label>
                    <InputElement id="shiContact" name="shiContact" value={props.quickBook.shiContact} onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Contact' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <label htmlFor="shiPhone">Phone</label>
                    <InputElement id="shiPhone" name="shiPhone" value={props.quickBook.shiPhone}  onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Phone' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <label htmlFor="shiPhoneExt">Phone-Extension</label>
                    <InputElement id="shiPhoneExt" name="shiPhoneExt" value={props.quickBook.shiPhoneExt}  onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Phone-Extension' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <label htmlFor="consiContact">Contact</label>
                    <InputElement id="consiContact" name="consiContact" value={props.quickBook.consiContact} onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Contact' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <label htmlFor="consiPhone">Phone</label>
                    <InputElement  id="consiPhone" name="consiPhone" value={props.quickBook.consiPhone}  onChange={props.onChangeBookHandler}
                         className="form-control" placeholder='Phone' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-2 mb-3">
                    <label htmlFor="consiPhoneExt">Phone-Extension</label>
                    <InputElement id="consiPhoneExt" name="consiPhoneExt" value={props.quickBook.consiPhone}  onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Phone-Extension' />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </div>
            : */}
            <div className="row">
                <div className="col-md-3 mb-3">
                    <label htmlFor="shiContact">Contact</label>
                    <InputElement id="shiContact" name="shiContact" value={props.quickBook.shiContact} onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Contact' />
                    {/* <div className="invalid-feedback">
                        required.
                    </div> */}
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="shiPhone">Phone</label>
                    <InputElement id="shiPhone" name="shiPhone" value={props.quickBook.shiPhone}  onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Phone' />
                    {/* <div className="invalid-feedback">
                        required.
                    </div> */}
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="consiContact">Contact</label>
                    <InputElement id="consiContact" name="consiContact" value={props.quickBook.consiContact} onChange={props.onChangeBookHandler}
                         className="form-control"  placeholder='Contact' />
                    {/* <div className="invalid-feedback">
                        required.
                    </div> */}
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="consiPhone">Phone</label>
                    <InputElement  id="consiPhone" name="consiPhone" value={props.quickBook.consiPhone}  onChange={props.onChangeBookHandler}
                         className="form-control" placeholder='Phone' />
                    {/* <div className="invalid-feedback">
                        required.
                    </div> */}
                </div>
            </div>
           {/* } */}
            <div className="row">
            {props.errorStatus.restError ? <div className="alert alert-danger" role="alert">{props.errorStatus.restErrorMessage}  </div> : ''}
                <div className="col-md-2 mb-2"> <label className="alert-success text-center text-success QuoteLabel"> {shiStatus.message} </label></div>
                <div className="col-md-2 mb-2"><label className="alert-danger text-center text-success QuoteLabel"> {shiStatus.error} </label></div>
                <div className="col-md-2 mb-2">
                    {props.quickBook.isShipperInfoEdit ? selShiAddr.addressId ===0 ?
                        <button type="button" onClick={saveShipperAddr} className="btn btn-outline-primary btn-sm float-right">Save <i className="fa-solid fa-address-card"></i></button>
                        : <><button type="button" onClick={deleteShipAddr} className="btn btn-outline-warning btn-sm float-right">Delete <i className="fa-solid fa-trash colorOrange"></i></button>
                        <button type="button" onClick={updateShipperAddr} className="btn btn-outline-primary btn-sm float-left">Update <i className="fa-solid fa-address-card"></i></button></>
                        : ''}
                </div>
                <div className="col-md-2 mb-2"> <label className="alert-success text-center text-success QuoteLabel"> {consiStatus.message} </label></div>
                <div className="col-md-2 mb-2"> <label className="alert-danger text-center text-success QuoteLabel"> {consiStatus.error} </label></div>
                <div className="col-md-2 mb-2">
                    {props.quickBook.isConsigneeInfoEdit ? selConsiAddr.addressId === 0 ?
                        <button type="button" onClick={saveConsigneeAddr} className="btn btn-outline-primary btn-sm float-right">Save <i className="fa-solid fa-address-card"></i></button>
                        : <><button type="button" onClick={deleteConsiAddr} className="btn btn-outline-warning btn-sm float-right">Delete <i className="fa-solid fa-trash colorOrange"></i></button>
                        <button type="button" onClick={updateConsigneeAddr} className="btn btn-outline-primary btn-sm float-left">Update <i className="fa-solid fa-address-card"></i></button></>
                        : ''}
                </div>
            </div>
            <hr className="mb-3" />
        </div>
    );
}

export default DirectBook2;