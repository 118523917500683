import React from 'react';
import PropTypes from 'prop-types';

import {isValidField, validatePassword} from '../utils/ValidationScripts'
const TextareaElement = (props) => {
    const updateValueToState = (e)=>{
            props.onChange([props.id],e.target.value);
    }
    
    const validation = (e)=>{
        if(props.validation === "required"){
            if(!validatePassword(e.target.value)){
                props.onInvalid(props.id,true);
            }else{
                props.onInvalid(props.id,false);
            }
        }
    }

    

        return (
           
                <textarea 
                     {...props}
                     
                     onChange = {updateValueToState}
                     onBlur = {validation}
                ></textarea>
           );
}

TextareaElement.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};


TextareaElement.defaultProps = {
    type: 'text',
    label: '',
    id: '',
    className: 'form-control',
    placeholder: '',
    //resetDependents: ''
};

export default TextareaElement;
