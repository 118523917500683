import React, { useEffect, useState } from 'react'
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../../formControls/InputElementMsg";
import InputEle from "../../../../formControls/InputElement";
import SelectElement from "../../../../formControls/SelectElement";
import AccessorialsEditModel from "./AccessorialsEditModel"
import {createAccessorials,updateAccessorials} from "../../../restAPI/TariffRatesAPI"
import DataAccessorialNew from '../Data/DataAccessorialNew';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const Accessorials = (props) => {
    
    const { formData, formDataReq, handleInputChange,  onSubmitNewAccessorial, onValidateNewAccessorial, clearNewAccessorialForm, setFormDataObj } = DataAccessorialNew();
   

useEffect(()=>{
    props.restFunCall();
    // setFormData({ ...formData, airportCode: props.airCode });
    setFormDataObj({airportCode: props.airCode });
  },[props.airCode])

    useEffect(() => {
        let table = $("#accessorialsTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
            "autoWidth": false,
            "bAutoWidth": false,
              //"autoWidth": true,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : props.tariff,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'Accessorials',
                  title: 'Accessorials',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'Accessorials',
                  title: 'Accessorials',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'LinkCustomerAccounts',
                  title: 'Link Customer Accounts',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'Accessorials',
                  title: 'Accessorials',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                {
                    // className: 'dt-control', width: "35%",
                    orderable: false,
                    data: null,  visible: true, "className": "notexport",
                    defaultContent: "<span class='accessedit'  alt='Edit Row' title='Edit Row'><i class='fa fa-pencil'/></span>" ,  
                },
                { "data" : "code" },
                { "data" : "description", width: '220px'},
                { "data" : "chargeType" },
                { "data" : "requireAuthorization",
                    "render": function (data, type, row) {
                    if(data === true) return "Y";
                    if(data === false) return "N";
                  }
                },
                { "data" : "newRequireAuthorization","defaultContent": "<i></i>" },
                { "data" : "rate" },
                { "data" : "newRate","defaultContent": "<i>0</i>" },
                { "data" : "minimum" },
                { "data" : "newMinimum"  ,"defaultContent": "<i>0</i>"},
                { "data" : "maximum" },
                { "data" : "newMaximum"  ,"defaultContent": "<i>0</i>"},
                { "data" : "perLb1" },
                { "data" : "perLbRate1" ,"defaultContent": "<i>0</i>"},
                { "data" : "newperLbRate1" ,"defaultContent": "<i>0</i>"},
                { "data" : "perLb2" },
                { "data" : "perLbRate2"  ,"defaultContent": "<i>0</i>"},
                { "data" : "newPerLbRate2"  ,"defaultContent": "<i>0</i>"},
                { "data" : "baseAmount" },
                { "data" : "newBaseAmount"  ,"defaultContent": "<i>0</i>"},
                ],
                "columnDefs": [
                   // { "width": "25%", "targets": 2 },
                //    { "targets": "_all", }
                ],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
               //jQuery(jQuery.fn.dataTable.tables(true)).DataTable().columns.adjust().draw();
            }
        });
        $('#accessorialsTbl tbody').on('click', 'tr td span.accessedit', function (event) {
            // , 'td.dt-control'
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var row_data = table.row(tr).data();
           //console.log(JSON.stringify(row_data))
           handleShow(row_data)
        });
    },[props.tariff]);
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState([]);
    const [showAddNew, setshowAddNew] = useState(false);
    let [innerLoading, setInnerLoading] = useState(false);
    const [responseMessage,setResponseMessage] = useState();
    const [responseStatus,setResponseStatus] = useState();
    const handleClose = () => setShow(false);
    const handleShow = (rowData) => {
        if( rowData ) {
        //   console.log("Accessorials rowData",rowData)
          let _obj = [];
          _obj = rowData;
          setEditData(_obj);
          setShow(true);
        }
    }

    // const handleOnchange = (name,value) => {
    //   setEditData({ ...editData, [name]: value });
    // }

    const createNewRecord = () =>{
        var isValidationReqNewAccessorial = onSubmitNewAccessorial();
        //console.log("new accessorial Record Save"+JSON.stringify(formData))
            
        if (!isValidationReqNewAccessorial) {
            props.setLoad("Surcharge",true);
            //console.log("new accessorial  Record to Save"+JSON.stringify(formData))
            createAccessorials(formData)
            .then(responseData =>{
                if (responseData instanceof Error) {
                   //  console.log("error objecct is tariffRates " + JSON.stringify(responseData))
                    //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                } else {
                  //  console.log("responseData of create new accessorials ",JSON.stringify(responseData))
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                }
            }).catch(err=>{
                //console.log("err",err)
                    setResponseMessage(err?.error?.message);
                    setResponseStatus("alert alert-danger text-center");
            }).finally(() =>{
                props.restFunCall();
                props.setLoad("Surcharge",false);
            });
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
                clearNewAccessorial();
            },6000)
        }
      }
    

    const clearNewAccessorial = () => {
        setshowAddNew(!showAddNew);
        clearNewAccessorialForm();
    }

    const updateRecord = (formEditData) => {
        setInnerLoading(true);
        console.log("innerHtml",JSON.stringify(formEditData));
        updateAccessorials(formEditData,editData.code,props.airCode).then(responseData =>{
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " ,responseData)
                    setResponseMessage(responseData);
                    setResponseStatus("alert alert-success text-center");
                } else {
                    //console.log("responseData of update tariff rate ",JSON.stringify(responseData))
                    if(responseData.includes("UNSUCCESSFUL")){
                        setResponseMessage(responseData);
                        setResponseStatus("alert alert-danger text-center");
                      }else{
                        setResponseMessage(responseData);
                      setResponseStatus("alert alert-success text-center");
                      }
                }
            }).catch(err=>{
                //console.log("err",err)
                   setResponseMessage(err);
                    setResponseStatus("alert alert-danger text-center");
            }).finally((o) =>{
                setInnerLoading(false);
            });
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
            },6000)
      }

    return (
        <>
        <hr className="tabhr"/>
        <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'}/>}
        <div className="card card-custom">
        {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
        <div id="datatable">
            <table id="accessorialsTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Edit</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Charge Type</th>
                        <th>Require Authorization</th>
                        <th>New Require Authorization</th>
                        <th>Rate</th>
                        <th>New Rate</th>
                        <th>Minimum</th>
                        <th>New Minimum</th>
                        <th>Maximum</th>
                        <th>New Maximum</th>
                        <th>Per Lb1</th>
                        <th>Per Lb1 Rate</th>
                        <th>New Per Lb1 Rate</th>
                        <th>Per Lb2</th>
                        <th>Per Lb2 Rate</th>
                        <th>New Per Lb2 Rate</th>
                        <th>Base Amount</th>
                        <th>New Base Amount</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <AccessorialsEditModel show={show} handleShow={handleShow} handleClose={handleClose} 
			editData={editData} updateRecord={updateRecord}
			resMessage={responseMessage} resStatus={responseStatus}
			innerLoading={innerLoading} 
            />
            <hr className="mb-3"/>
            {!showAddNew ?
            <div className="col-sm-3">
                <button className="btn btn-primary btn-block" type="button" id='addNewZone' onClick={()=>{setshowAddNew(!showAddNew)}}>
                Add Accessorials</button> 
            </div> : 
            <>
            { responseStatus && 
                <h6 className={responseStatus}>{responseMessage} </h6>
            }
            
            <fieldset>
                <legend>Add Accessorial</legend>
                {/* <h3 className="mb-3 pageHead alignCenter">Add Accessorial</h3> */}
                <div className="row mb-3">
                    <div className="col-md-4 ">
                        <label htmlFor="acNo">Select Description </label>
                        <SelectElement id="description" name="description"  value={formData.description || ''}
                            optionsData={props.descriptionList}
                            onChange={handleInputChange}  validation="required"  onInvalid={onValidateNewAccessorial}
                            placeholder='- Choose Description -' className={formDataReq.description_ErrorClass} />
                        <small className={formDataReq.description_ErrorClass}>
                            required.
                        </small>
                    </div>
                    <div className="col-md-4 ">
                        <label htmlFor="acNo">Select Charge Type </label>
                        <SelectElement id="chargeType" name="chargeType"  value={formData.chargeType || ''}
                            optionsData={props.chargeTypeList}
                            placeholder='- Choose Charge Type -'
                            onChange={handleInputChange}  validation="required"  onInvalid={onValidateNewAccessorial}
                            className={formDataReq.chargeType_ErrorClass}        />
                        <small className={formDataReq.chargeType_ErrorClass}>
                            required.
                        </small>
                    </div>
                    <div className="col-md-4">
                            <label> Require Authorization : </label>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputEle type="radio" id="requireAuthorizationYes" name="requireAuthorization"  
                                    checked={formData.requireAuthorization === "Yes"} value="Yes"
                                    onChange={handleInputChange}  validation="requiredRadio" onInvalid={onValidateNewAccessorial}
                                    className={formDataReq.requireAuthorization_ErrorClass} />
                                <label className="custom-control-label" htmlFor="requireAuthorizationYes">Yes</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputEle type="radio" id="requireAuthorizationNo" name="requireAuthorization"  
                                    checked={formData.requireAuthorization === "No"} value="No"
                                    onChange={handleInputChange}  validation="requiredRadio" onInvalid={onValidateNewAccessorial}
                                    className={formDataReq.requireAuthorization_ErrorClass} />
                                <label className="custom-control-label" htmlFor="requireAuthorizationNo">No</label>
                            </div>
                        </div>
                </div>
                <div className="row mb-3">
                   
                    <div className="col-md-4">
                        <label> Rate : </label>
                        <InputElement id="rate" name="rate" className={formDataReq.rate_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Rate' />
                    </div>
                    <div className="col-md-4">
                        <label> Minimum : </label>
                        <InputElement id="minimum" name="minimum" className={formDataReq.minimum_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Minimum' />
                    </div>
                    <div className="col-md-4">
                        <label> Maximum : </label>
                        <InputElement id="maximum" name="maximum" className={formDataReq.maximum_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Maximum' />
                    </div>
                </div>
                    <div className="row mb-3" >
                    
                        <div className="col-md-4 "><label>PreLbRate1 :  </label>
                        <InputElement id="perLbRate1" name="perLbRate1" className={formDataReq.perLbRate1_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='PerLb Rate 1' />
                        </div>
                        <div className="col-md-4 "><label>PreLbRate2 : </label>
                        <InputElement id="perLbRate2" name="perLbRate2" className={formDataReq.perLbRate2_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='PerLb Rate 2' />
                        </div>
                        <div className="col-md-4"><label>Base Amount :</label> 
                        <InputElement id="baseAmount" name="baseAmount" className={formDataReq.baseAmount_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Base Amount' />
                        </div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-4 "><label>Detention Interval :</label>
                        <InputElement id="deInterval" name="deInterval" className={formDataReq.deInterval_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Detention Interval' />
                        </div>
                        <div className="col-md-4 "><label>Calc Detention Interval :</label>
                        <InputElement id="calcDeInterval" name="calcDeInterval" 
                            className={formDataReq.calcDeInterval_ErrorClass}
                            onChange={handleInputChange}  validation="decimal" onInvalid={onValidateNewAccessorial}
                            placeholder='Calc Detention Interval' />
                        </div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-8">
                            {/* <h6 className={responseStatus}>{responseMessage} </h6> */}
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary btn-block" type="button" id='cancelAccessorial' onClick={clearNewAccessorial}>
                                Cancel</button> 
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-primary btn-block" type="button" id='saveAccessorial' onClick={createNewRecord}>
                                Save</button> 
                                </div>
                    </div>
                </fieldset>
            </>
            }
      
        </div>
        </div>
    </>
        )
}
export default Accessorials