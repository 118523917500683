import { data } from 'jquery';
import { useState,useEffect } from 'react';
import { isNumberAndNotZero, isValidAlpha, isValidAlphaNumeric, isValidEmail, reformatToPhone } from '../utils/ValidationScripts';
import {accountInfo} from '../services/restAPI/CorporateCenterLinkRest';
import {  getBaseStation } from '../services/restAPI/UtilsRest';
const DataSignUp = () => {
    const timeZoneList = [{value :"GMT-12",label: "[GMT-12] International Date Line West"},
    {value :"GMT-11",label: "[GMT-11] Midway Island, Samoa"},
    {value :"GMT-10",label: "[GMT-10] Hawaii"},
    {value :"GMT-09",label: "[GMT-09] Alaska"},
    {value :"GMT-08",label: "[GMT-08] Pacific Time (US & Canada)"},
    {value :"GMT-07",label: "[GMT-07] Mountain Time (US & Canada)"},
    {value :"GMT-06",label: "[GMT-06] Central Time (US & Canada)"},
    {value :"GMT-05",label: "[GMT-05] Eastern Time (US & Canada)"},
    {value :"GMT-04",label: "[GMT-04] Atlantic Time (Canada)"},
    {value :"GMT-03",label: "[GMT-03] Greenland"},
    {value :"GMT-02",label: "[GMT-02] Mid-Atlantic"},
    {value :"GMT-01",label: "[GMT-01] Cape Verdex Is."},
    {value :"GMT",label: "[GMT] Dublin, London, Monrovia"},
    {value :"GMT+01",label: "[GMT+01] Amsterdam, Berlin, Rom, Madrid, Paris, Prague, Vienna"},
    {value :"GMT+02",label: "[GMT+02] Athens, Cairo, Jerusalem, Minsk"},
    {value :"GMT+03",label: "[GMT+03] Baghdad, Kuwait, Moscow"},
    {value :"GMT+04",label: "[GMT+04] Caucasus Standard Time"},
    {value :"GMT+05",label: "[GMT+05] Islamabad, Karachi, Tashkent"},
    {value :"GMT+06",label: "[GMT+06] Almaty, Novosibirsk, Astana, Dhaka"},
    {value :"GMT+07",label: "[GMT+07] Bangkok, Hanoi, Jakarta"},
    {value :"GMT+08",label: "[GMT+08] Beijing, Chongqing, HK, Kuala Lumpur, Singapore, Taipei"},
    {value :"GMT+09",label: "[GMT+09] Osaka, Sapporo, Tokyo, Seoul"},
    {value :"GMT+10",label: "[GMT+10] Brisbane, Melbourne, Sydney, Guam"},
    {value :"GMT+11",label: "[GMT+11] Magadan"},
  ];
    const  [inputs] = [{
        acNo : '',
        companyName : '',
        address : '',
        city : '',
        state : '',
        zip : '',
        tel : '',
        fax : '',
        countryCode : '',
        compAdmin : '',
        firstName : '',
        lastName : '',
        userName : '',
        password : '',
        confirmPass : '',
        email : '',
        confEmail : '',
        secQues : '',
        secAns : '',
        timeZone : '',
        clBaseStation : '',
        fuelUpdates : '',
        tariffUpdates : '',
        ffUpdates : '',
    }];

    const [inputsReq] = [{
        firstName : true,
        lastName : true,
        userName : true,
        password : true,
        confirmPass : true,
        email : true,
        confEmail : true,
        secQues : true,
        secAns : true,
        firstName_ErrorClass : 'form-control',
        lastName_ErrorClass : 'form-control',
        userName_ErrorClass : 'form-control',
        password_ErrorClass : 'form-control',
        confirmPass_ErrorClass : 'form-control',
        email_ErrorClass : 'form-control',
        confEmail_ErrorClass : 'form-control',
        secQues_ErrorClass : 'form-control',
        secAns_ErrorClass : 'form-control',
       // docOther1Desc_ErrorClass : 'form-control form-inline',
        // docOther2_ErrorClass : 'form-check-input',
        // uploadedDocs_ErrorClass : 'form-control',
    }];

    const [dataInput, setDataInput] = useState(inputs);
    const [dataInputReq, setDataInputReq] = useState(inputsReq);
    const [pswdMatch, setPswdMatch] = useState('');
    const [emailMatch, setEmailMatch] = useState('');
    const [baseStation, setBaseStation] = useState([]);
   
    useEffect(() => {
        getBaseStation()
            .then(baseStList => {
                //console.log("baseStationList in contianer"+JSON.stringify(baseStList))   
                let baseStationList = [];                  
                baseStList.map((baseStation, index) => {
                    baseStationList.push({value :baseStation.station,label: baseStation.location})
                });
                setBaseStation(baseStationList);
                }).finally(e=>{
                  //console.log("baseStationList in contianer finally")
                  
                })
            }, []);

    const getAccountInfo = () =>{
        accountInfo(dataInput.acNo)
            .then(responseData => {
            if( responseData instanceof Error ){
               // setReportData([]);
            }else{
                var addr1 = responseData.Address1 || '';
                var addr2 = responseData.Address2 || '';
                setDataInput({...dataInput,  companyName : responseData.CompanyName,
                address : addr1+", "+addr2,
                city : responseData.City,
                state : responseData.State,
                zip : responseData.PostalCode,
                tel : responseData.Phone || '',
                fax : responseData.Phone || '',
                countryCode : responseData.Country || '',
                compAdmin : responseData.UserName})
            }
            }).catch(err => {
        })
    }

    const onSubmitForm = () => {
        const copy = {...dataInputReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                // var _css = (key==="claimType" ? "custom-control-input" : (key.startsWith("doc") ? "form-check-input" : "form-control"));
                let _css =  'form-control';
                var errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        // console.log("Total Error obj ",errObject);
        if( dataInput.email && dataInput.confEmail && isValidEmail(dataInput.email) && isValidEmail(dataInput.confEmail)){
            if( dataInput.email!==dataInput.confEmail ){
                errObject.email_ErrorClass = 'form-control is-invalid'
                errObject.confEmail_ErrorClass = 'form-control is-invalid'
                setEmailMatch(false)
            }else{
                errObject.email_ErrorClass = 'form-control'
                errObject.confEmail_ErrorClass = 'form-control'
                setEmailMatch(true)
            }
        }else{
            setEmailMatch('')
        }

        if( dataInput.password && dataInput.confirmPass){
            if( dataInput.password!==dataInput.confirmPass ){
                errObject.password_ErrorClass = 'form-control is-invalid'
                errObject.confirmPass_ErrorClass = 'form-control is-invalid'
                setPswdMatch(false)
            }else{
                errObject.password_ErrorClass = 'form-control'
                errObject.confirmPass_ErrorClass = 'form-control'
                setPswdMatch(true)
            }
        }else{
            setPswdMatch('')
        }

        if(status){
            setDataInputReq({...dataInputReq,...errObject});
        } 

        // setShowDataLoading(true);
        // loadReportData();
        let reqObj = { ...dataInputReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }

         console.log("c="+JSON.stringify(reqObj))
        validationReqFlag = Object.values(reqObj).includes(true) || !emailMatch || !pswdMatch;
         console.log("validationReqFlag=" + validationReqFlag)
        return validationReqFlag;
            
    }

    const onValidate = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            // var _css = (key==="claimType" ? "custom-control-input" : "form-control");
            let _css =  'form-control';
            
            
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                //  if( key==="claimType" && dataInput.claimType==="Other"){
                //     errState['claimStatement'] = true
                //  }
                status=true;
              }
         //   })

        if(name==="password" || name==="confirmPass"){
            if( dataInput.password && dataInput.confirmPass){
                if( dataInput.password===dataInput.confirmPass){
                    errState.password_ErrorClass = 'form-control'
                    errState.confirmPass_ErrorClass = 'form-control'
                    setPswdMatch(true);
                }else{
                    errState.password_ErrorClass = 'form-control is-invalid'
                    errState.confirmPass_ErrorClass = 'form-control is-invalid'
                    setPswdMatch(false)
                }
            }else{
                setPswdMatch('')
            }
        }else if(name==="email" || name==="confEmail"){
            if( dataInput.email && dataInput.confEmail){
                if( dataInput.email===dataInput.confEmail){
                    errState.email_ErrorClass = 'form-control'
                    errState.confEmail_ErrorClass = 'form-control'
                    setEmailMatch(true)
                }else{
                    errState.email_ErrorClass = 'form-control is-invalid'
                    errState.confEmail_ErrorClass = 'form-control is-invalid'
                    setEmailMatch(false)
                }
            }else{
                setEmailMatch('')
            }
        }
        if(status)
            setDataInputReq({...dataInputReq, ...errState});

            
      }

    const setErrorState =(errState)=>{
        setDataInputReq({...dataInputReq, ...errState});
    }

    const handleInputChange = (name, value) => {
        let obj = {}, objReq = {};
            obj[name] = value;
        setDataInput({...dataInput, ...obj});
        // console.log(JSON.stringify(dataInput))
       // setDataInputReq({...dataInputReq, ...objReq});

    }
    const setInputData=(name,value)=>{
        let obj = {}, objReq = {};
        obj[name] = value;
    setDataInput({...dataInput, ...obj});
    }
    const handleInputChangeNumeric = (name, value) => {
        let obj = {}
        const val = (isNumberAndNotZero(value) || value==="") ? value : dataInput[name];
        obj[name] = val;
        setDataInput({...dataInput, ...obj});
    }

    const handleInputChangeNumericPh = (name, value) => {
        let obj = {}
        const val = (isNumberAndNotZero(value) || value==="") ? value : dataInput[name];
        obj[name] = val;
        setDataInput({...dataInput, ...obj});
    }

    const handleInputChangeAlphaNumeric = (name, value) => {
        let obj = {}
        const val = (isValidAlphaNumeric(value) || value==="") ? value : dataInput[name];
        obj[name] = val;
        setDataInput({...dataInput, ...obj});
    }

    const handleInputChangeAlpha = (name, value) => {
        let obj = {}
        // console.log(value)
        const val = (isValidAlpha(value) || value==="") ? value : dataInput[name];
        obj[name] = val;
        setDataInput({...dataInput, ...obj});
    }

    const clearForm = () => {
        setDataInput(inputs);
        setDataInputReq(inputsReq);
        setEmailMatch('')
        setPswdMatch('')
    }

    const addPhFormat = (name, value) => {
        let obj = {}
        // console.log(value)
        const val = value ? reformatToPhone(value) : "";
        obj[name] = val;
        console.log("v=",val)
        setDataInput({...dataInput, ...obj});
    }

    return {
        handleInputChange,
        onSubmitForm,
        onValidate,
        clearForm,
        getAccountInfo,
        handleInputChangeNumeric,
        handleInputChangeNumericPh,
        handleInputChangeAlphaNumeric,
        handleInputChangeAlpha,
        addPhFormat,
        setErrorState,
        setInputData,
        baseStation,
        dataInput,
        dataInputReq,
        timeZoneList,
        pswdMatch, emailMatch
    }
}

export default DataSignUp;