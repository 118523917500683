import { useState } from 'react'
import { useSelector } from 'react-redux';

import { updateTariffInfo } from '../../../restAPI/TariffRatesAPI';
const DataStationInfo = () => {
    const [initialData] = [{
      airportCode:'',
      stationId : '',
      companyName : '',
      address1 : '',
      address2 : '' ,
      city : '',
      state : '',
      postalCode : '',
      phoneNumber : '' ,
      emergencyPhone : '',
      fax : '',
      manager : '',
      email : '',
      region :'',
      latitude:'',
      longitude:'',
    }];
    const [initialDataReq] = [{
      stationId : true,
      companyName : true,
      address1 : true,
      address2 : true ,
      city : true,
      state : true,
      postalCode : true,
      phoneNumber : true ,
      emergencyPhone : true,
      fax : false,
      manager : true,
      email : true,
      stationId_ErrorClass : 'form-control',
      company_ErrorClass : 'form-control',
      address1_ErrorClass : 'form-control',
      address2_ErrorClass : 'form-control' ,
      city_ErrorClass : 'form-control',
      state_ErrorClass : 'form-control',
      postalCode_ErrorClass : 'form-control',
      phoneNumber_ErrorClass : 'form-control' ,
      emergencyPhone_ErrorClass : 'form-control',
      fax_ErrorClass : 'form-control',
      manager_ErrorClass : 'form-control',
      email_ErrorClass : 'form-control'
      }];

      const [stationInfoData, setStationInfoData] = useState(initialData);
      const [stationInfoDataReq, setStationInfoDataReq] = useState(initialDataReq);
      const [responseMessage,setResponseMessage] = useState();
      const [responseStatus,setResponseStatus] = useState();

      const updateStationInfoData = (stationInfo) => {
          setStationInfoData({...stationInfo});
      }
     

    const onValidate = (name, value) => {
        var errState = {};
        var status = false;
        Object.entries(stationInfoDataReq).forEach(([key]) => {
          var errname = key + "_ErrorClass";
          if (key === name && value) {
            errState = { [name]: true, [errname]: 'form-control is-invalid' };
            status = true;
          } else if (key === name && !value) {
            errState = { [name]: false, [errname]: 'form-control is-valid' };
            status = true;
          }
        })
        if (status)
        setStationInfoDataReq({ ...stationInfoDataReq, ...errState });
        //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
      }

      const onSubmit = (editData) => {
        const copy = {...editData};
        const errCopy = {...stationInfoDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
          setStationInfoDataReq({...stationInfoDataReq,...errObject});
            validationReqFlag = true;
        } 
        return validationReqFlag;
    }

    const clearForm = () => {
      setStationInfoData(initialData);
      setStationInfoDataReq(initialDataReq);
    } 
    let userProfileData = useSelector((state) => state.usr)

    const saveTariffInfo = (editData) => {
      var isValid = onSubmit(editData);
      console.log(isValid)
      if( userProfileData && userProfileData.custCode ){
      }else{
          userProfileData = JSON.parse(localStorage.getItem("userProfile"));
      }
      if(!isValid)
      updateTariffInfo(editData)
      .then(responseData =>{
          if (responseData instanceof Error) {
            setResponseMessage(responseData);
            setResponseStatus("alert alert-success text-center");
  
          } else {
            console.log("responseData of Updated Station info Messsage ==> ",JSON.stringify(responseData))
            if(responseData.includes("UNSUCCESSFUL")){
              setResponseMessage(responseData);
              setResponseStatus("alert alert-danger text-center");
            }else{
              setResponseMessage(responseData);
            setResponseStatus("alert alert-success text-center");
            }
          }
      }).catch(err=>{
        //console.log("err",err)
        setResponseMessage(err);
        setResponseStatus("alert alert-danger text-center");
      }).finally(err =>{
      });
      setTimeout(()=>{
        setResponseMessage('');
        setResponseStatus('');
    },6000)
  }

  return {
    stationInfoData , stationInfoDataReq, responseStatus,responseMessage,
     onValidate, clearForm, onSubmit, updateStationInfoData ,saveTariffInfo
  }
}

export default DataStationInfo