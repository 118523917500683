import React from 'react'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ffLogoB64Data } from '../../../utils/ImageB64';

import { useState } from 'react';
import { copyStyles,extractStyles } from '../../../utils/ValidationScripts';

import InvoiceDetailsDatatable from './InvoiceDetailsDatatable';

const InvoiceDetails = (props) => {
    let ffLogoB64  = ffLogoB64Data();
    let location = useLocation();
    let reportData = location.state.invoiceDetails;
    const [totalAmount, setTotalAmount] = useState(0);
    const [invTableHtml, setInvTableHtml] = useState([]);

    let pageTotal = 0, pageAllTotal = 0;

    
    
    const handlePrintAll = () => {
        let printableElements = document.getElementById('invoiceDiv1').innerHTML;
        printableElements += invTableHtml;
        var w = window.open('', "_blank");
		let cssStyles = extractStyles(window.document);
		const orderHtml = `<html><head><title></title>` +cssStyles+ `</head><body>` + printableElements + `</body></html>`
	  	w.document.write(orderHtml);
        setTimeout(function() {
                w.window.print();
                w.window.focus();
                w.window.close();  
        }, 1000);

      }	

  return (
        <div className="card card-custom">
            <div className="col-md-12 invPrint">
                <div id="invoiceDiv1">
                    <div className="d-flex mb-2">
                        <h4 className="pageHead text-uppercase">Invoice</h4>
                        {/* <button className="btn btn-primary ml-auto" onclick="window.print();">print</button> */}
                        <button className="btn btn-secondary pull-right ml-auto" type="button" id='invprintAll' onClick={handlePrintAll} >
                        <i className='fa fa-print'></i> Print</button> 
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <img  alt="Freight Force" src={ffLogoB64} height='100' width='100' />
                        </div>
                        <div className="col-md-5">
                            <h6>Freight Force, Inc.</h6>
                            14445 Alondra Blvd,<br/>
                            P.O. Box 10580 <br/>
                            La Miranda, CA, 96038.
                        </div>
                        <div className="col-md-5">
                            Ph: 714-995-9300 <br/>
                            Fax: 714-995-9330 <br/>
                            www.freightforce.com <br/>
                            accountinquiries@freightforce.com	
                        </div>
                    </div>
                    <hr/>
                    <div className="row mb-4">
                        <div className="col-md-1">
                            Bill to:
                        </div>
                        <div className="col-md-7">
                            <strong>Pilot Freight Services </strong><br/>
                            2440 Enterprise Drive, Mendota HTS, MN 55120
                        </div>
                        <div className="col-md-4 text-right">
                            Invoice No:&nbsp;<strong>846865</strong><br/>
                            Bill Date:&nbsp;<strong>10/02/2022</strong>
                        </div>
                    </div>
                    </div>
                   <InvoiceDetailsDatatable reportData={reportData} setInvTableHtml={setInvTableHtml}/>
                </div>
            </div>
  )
}

export default InvoiceDetails