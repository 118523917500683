import React, { useEffect, useState } from 'react'
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../../formControls/InputElementMsg";
import AirportTransferEditModel from "./AirportTransferEditModel"
import DataAirportTransferNew from '../Data/DataAirportTransferNew';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
window.JSZip = jzip;

const AirportTransfer = (props) => {

    const { formData, formDataReq, handleInputChange,  onSubmitNew, onValidateNew, clearNewForm, setFormDataObj } = DataAirportTransferNew();

    useEffect(() => {
        props.restFunCall();
        
    }, [props.airCode])

    useEffect(() => {
        let table = $("#airportTransferTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
            "scrollCollapse": true,
            "paging": false,
            "bPaginate": false,
            //"autoWidth": true,
            "width": "100%",
            "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data": props.tariff,
            "dom":
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
                {
                    extend: 'copyHtml5',
                    className: 'btn btn-primary btn-sm',
                    text: '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Copy'
                },
                {
                    extend: 'excelHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text: '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Excel',
                    exportOptions: {
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: false
                        }
                    },

                },
                /*{
                    extend:    'csvHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'CSV'
                },*/
                {
                    extend: 'pdfHtml5',
                    text: '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                    className: 'btn btn-primary  btn-sm',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'PDF',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    customize: function (doc) {
                        doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                    }
                }
            ],
            "lengthMenu": [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
            ],
            "columns": [
                {
                    // className: 'dt-control', width: "35%",
                    orderable: false,
                    data: null, visible: true, "className": "notexport",
                    defaultContent: "<span class='ariportTransferEdit'  alt='Edit Row' title='Edit Row'><i class='fa fa-pencil'/></span>",
                },
                { "data": "zone" },
                { "data": "currentRate.moN_FRI_0000_0759" },
                { "data": "updatedRate.moN_FRI_0000_0759" },
                {
                    "data": "currentRate.moN_FRI_0800_1700",
                    //   "render": function (data, type, row) {
                    //   if(data === true) return "Y";
                    //   if(data === false) return "N";
                    // }
                },
                { "data": "updatedRate.moN_FRI_0800_1700", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.moN_FRI_1701_2359" },
                { "data": "updatedRate.moN_FRI_1701_2359", "defaultContent": "<i>0</i>" },
                { "data": "currentRate.saturday" },
               
            ],
            "columnDefs": [
                // { "width": "25%", "targets": 2 },
                //    { "targets": "_all", }
            ],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function (settings, json) {
                //jQuery(jQuery.fn.dataTable.tables(true)).DataTable().columns.adjust().draw();
            }
        });
        $('#airportTransferTbl tbody').on('click', 'tr td span.ariportTransferEdit', function (event) {
                // , 'td.dt-control'
            var tr = $(this).closest('tr');
            var row = table.row(tr);
            var row_data = table.row(tr).data();
            //console.log("rd",row_data);
            handleShow(row_data)
        });
    }, [props.tariff]);
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState([]);
    //const [formData, setFormData] = useState([]);
    const [showAddNew, setshowAddNew] = useState(false);
    let [innerLoading, setInnerLoading] = useState(false);
    const [responseMessage,setResponseMessage] = useState();
    const [responseStatus,setResponseStatus] = useState();

    const handleClose = () => setShow(false);
    const handleShow = (rowData) => {
        //console.log("Accessorials rowData", rowData)
        setEditData(rowData)
        setShow(true);
    }

    const handleOnchange = (name, value) => {
        setEditData({ ...editData, [name]: value });
    }

    const createNewRecord = () =>{
        //console.log("new Tariff Rate Save"+JSON.stringify(formData));
        var isValidationReqNew = onSubmitNew();
        
            
        if (!isValidationReqNew) {
            //props.setLoad("AirportTransfer",true);
            /*
            createTariffRate(formData).then(responseData =>{
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " ,responseData)
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                } else {
                    console.log("responseData of create new tariff rate ",JSON.stringify(responseData))
                    setResponseMessage(responseData.message);
                    setResponseStatus("alert alert-success text-center");
                }
            }).catch(err=>{
                //console.log("err",err)
                   setResponseMessage(err.error.message);
                    setResponseStatus("alert alert-danger text-center");
            }).finally((o) =>{
                props.restFunCall();
                props.setLoad("TariffRates",false);
            });
            */
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
                setshowAddNew(!showAddNew);
            },6000)
      }
    }

    const updateRecord = (formEditData) => {
        setInnerLoading(true);
        //console.log("fed1",JSON.stringify(formEditData))
        /*
            updateTariffRate(formEditData,editData?.zone,props.airCode).then(responseData =>{
                if (responseData instanceof Error) {
                    //console.log("error objecct is tariffRates " ,responseData)
                    setResponseMessage(responseData);
                    setResponseStatus("alert alert-success text-center");
                } else {
                    console.log("responseData of update tariff rate ",JSON.stringify(responseData))
                    if(responseData.includes("UNSUCCESSFUL")){
                        setResponseMessage(responseData);
                        setResponseStatus("alert alert-danger text-center");
                      }else{
                        setResponseMessage(responseData);
                      setResponseStatus("alert alert-success text-center");
                      }
                }
            }).catch(err=>{
                //console.log("err",err)
                   setResponseMessage(err);
                    setResponseStatus("alert alert-danger text-center");
            }).finally((o) =>{
                setInnerLoading(false);
            });
            */
            setTimeout(()=>{
                setResponseMessage('');
                setResponseStatus('');
            },6000)
      }

    
    const clearNewTariff = () => {
        setshowAddNew(!showAddNew);
        clearNewForm();
    }

    return (
        <>
    <hr className="tabhr"/>
        <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'} />}
            <div className="card card-custom">
                {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
                <div id="datatable">
                    <table id="airportTransferTbl" className="display" style={{ width: "100%", border: "1px solid #ccc" }}>
                        <thead>
                            <tr>
                                <th>Edit</th>
                                <th>Station</th>
                                <th>Min Amount</th>
                                <th>New Min Amount</th>
                                <th>Per LBS</th>
                                <th>Rate</th>
                                <th>New Rate</th>
                                <th>Cap Amount</th>
                                <th>New Cap Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                <AirportTransferEditModel show={show} handleShow={handleShow} handleClose={handleClose}
                    editData={editData} updateRecord={updateRecord}
                    resMessage={responseMessage} resStatus={responseStatus}
                    innerLoading={innerLoading} />
                <hr className="mb-3" />
                {!showAddNew ?
                    <div className="col-sm-3">
                        <button className="btn btn-primary btn-block" type="button" id='addNewZone' onClick={() => { setshowAddNew(!showAddNew) }}>
                            Add Airport Transfer Station</button>
                    </div> :
                    <>
                        { responseStatus && 
                            <h6 className={responseStatus}>{responseMessage} </h6>
                        }
                    <fieldset>
                    <legend>Add Airport Transfer Station</legend>
                        <div className="row mb-3">
                            <div className="col-md-4 ">
                                <label>Station : </label>
                                <InputElement id="new_station" name="new_station" className={formDataReq.new_station_ErrorClass}
                                    onChange={handleInputChange}  validation="required" onInvalid={onValidateNew}
                                    placeholder='Station' />
                            </div>
                            <div className="col-md-4 ">
                                <label>Min Amount: </label>
                                <InputElement id="new_min_amt" name="new_min_amt" className={formDataReq.new_min_amt_ErrorClass}
                                    onChange={handleInputChange} validation="integerOnly" onInvalid={onValidateNew}
                                    placeholder='Min Amount' />
                            </div>
                            <div className="col-md-4 ">
                                <label>Per LBS :</label>
                                <InputElement id="new_per_lbs" name="new_per_lbs" className={formDataReq.new_per_lbs_ErrorClass}
                                    onChange={handleInputChange} validation="integerOnly" onInvalid={onValidateNew}
                                    placeholder='Per LBS' />
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-md-4 ">
                                <label>Rate : </label>
                                <InputElement id="new_rate" name="new_rate" className={formDataReq.new_rate_ErrorClass}
                                    onChange={handleInputChange} validation="integerOnly" onInvalid={onValidateNew}
                                    placeholder='Rate' />
                            </div>
                            <div className="col-md-4 ">
                                <label>Cap Amount :</label>
                                <InputElement id="new_cap_amt" name="new_cap_amt" className={formDataReq.new_cap_amt_ErrorClass}
                                    onChange={handleInputChange} validation="integerOnly" onInvalid={onValidateNew}
                                    placeholder='Cap Amount' />
                            </div>
                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-8"></div>
                            <div className="col-md-2">
                                <button className="btn btn-primary btn-block" type="button" id='cancelAirportTransfer' onClick={clearNewTariff}>
                                    Cancel</button>
                            </div>
                            <div className="col-md-2">
                                <button className="btn btn-primary btn-block" type="button" id='saveAirportTransfer' onClick={createNewRecord}>
                                    Save</button>
                            </div>
                        </div>
                        </fieldset>
                    </>
                }
            </div>
        </div>
    </>
    )
}


export default AirportTransfer