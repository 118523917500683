import axios from "axios";
import {trackingCharges,trackingStatusHistory,trackingDocuments} from '../../restAPI/TrackingAPI'

export const buildEstimateRow = (responseData) => {
    

// `d` is the original data object for the row
if(responseData && responseData.length>0){
    let rowsCount=responseData.length;
   let tabelData = ' <table class="table table-bordered" style="border-width:2px;border-color:#686868;"> '+
    ' <tr> '+
    '   <th colspan="9" class="text-primary text-left"> VIEW ESTIMATES </th> '+
    //'   <td> <button class="btn btn-sm btn-info float-right"><i class="fa fa-print"></i> Print</button> </td> '+
    ' </tr> '+
    ' <tr> '+
    '   <th width="6%" class="text-left">Type</th> '+
    '   <th width="6%">Origin</th> '+
    '   <th width="6%">Pieces</th> '+
    '   <th width="6%">Weight</th> '+
    '   <th width="6%">Dim</th> '+
    '   <th width="10%">Charge Description</th> '+
    '   <th width="6%"></th> '+
    '   <th width="6%">Charges</th> '+
    '   <td width="48%" rowspan="5" class="text-left" style="vertical-align: top;"> '+
    ' 	<b>PLEASE NOTE : </b> '+
    ' 	These are pending charges and are not final charges until the invoice is issued. '+
    ' 	Charges displayed here are subject to change. Additional charges may apply. '+
    ' 	<br/><br/> '+
    ' 	To report a billing, discripency please contact us at : <br/> '+
    ' 	<span class="text-primary bold">accountinquiries@freightforce.com</span> '+
    '   </td> '+
    ' </tr> ';
    let pattern = /^0+(?:\.0+)?$/;
    for(let i=0;i<responseData.length-1;i++){
        tabelData = tabelData +   ' <tr> '+
        '   <td>'+responseData[i].chargeType+'</td> '+
        '   <td>'+responseData[i].origin+'</td> '+
        '   <td>'+responseData[i].pieces+'</td> '+
        '   <td>'+responseData[i].grossWeight+'</td> '+
        '   <td>'+responseData[i].dimWeight+'</td> '+
        '   <td class="text-nowrap">'+responseData[i].description+'</td> '+
        '   <td></td> '+
        '   <td class="text-right">'+Number(responseData[i].chargeTotal).toFixed(2)+'</td> '+
        ' </tr> ';
        if(!pattern.test(responseData[i].baseAmount)){
            rowsCount=rowsCount+1;
            tabelData = tabelData +   '<tr> <td></td> <td></td><td></td><td></td><td></td>'+
                        '<td className="text-nowrap text-right">Base Charge</td>'+
                        '<td className="text-right">'+Number(responseData[i].baseAmount).toFixed(2)+'</td> <td></td></tr>';
        }
        if(!pattern.test(responseData[i].fuelAmount)){
            rowsCount=rowsCount+1;
            tabelData = tabelData +   '<tr> <td></td> <td></td><td></td><td></td><td></td>'+
                        '<td className="text-nowrap text-right">Fuel Charge</td>'+
                        '<td className="text-right">'+Number(responseData[i].fuelAmount).toFixed(2)+'</td> <td></td></tr>';
        }
        if(!pattern.test(responseData[i].specialAmount)){
            rowsCount=rowsCount+1;
            tabelData = tabelData +   '<tr> <td></td> <td></td><td></td><td></td><td></td>'+
                        '<td className="text-nowrap text-right">Special Charge</td>'+
                        '<td className="text-right">'+Number(responseData[i].specialAmount).toFixed(2)+'</td> <td></td></tr>';
        }
    }
    tabelData = tabelData +   ' <tr> '+
    '   <th colspan="7" class="text-right">TOTAL CHARGES</th> '+
    '   <th class="text-right">'+Number(responseData[responseData.length-1].chargeTotal).toFixed(2)+'</th> '+
    ' </tr> '+
    ' </table> ';
    tabelData=tabelData.replace("rowspan=\"5\"", "rowspan=\""+(rowsCount+1)+"\"");
    return (
        tabelData
        //responseData[0].chargeCode
        //responseData[0].rateId 
        //responseData[0].rate
        //responseData[0].calcMethod
        //responseData[0].specialAmount
        
        

       
    )
    }   else{
        return ('<label style="color:red;">Data is not Available </label>')
    }    
}

export const buildStatusHistoryRow = (responseData) => {
    // `d` is the original data object for the row
   // console.log("buildStatusHistoryRow"+responseData)
    var list='';
    if(responseData.length>0){
    for (const eachObj of responseData) {
        var eachRec=' <tr> '+
        '   <td>'+eachObj.Description+'</td> '+
        '   <td>'+eachObj.TimeStamp+'</td> '+
        ' </tr> ';
        list=list+eachRec;
      }
    }
    if(responseData.length>0){
        return (
            ' <table class="table table-bordered" style="width:50%;border-width:2px;border-color:#686868;"> '+
            ' <tr> '+
            '   <th colspan="2" class="text-primary text-left"> STATUS HISTORY </th> '+
            ' </tr> '+
            ' <tr> '+
            '   <th width="15%" class="text-left">Status</th> '+
            '   <th width="15%">Timestamp</th> '+
            ' </tr> '+list+
            ' </table> '
        )
    }else{
        return ('<label style="color:red;">Data is not Available </label>')
    }
    }

    export const buildDocumentsRow = (docsList) => {
        // `d` is the original data object for the row
        let _docHtml =  '';

        if( docsList && docsList.length>0 && docsList!=="NO IMAGES" ){
            _docHtml += "<tr>";
            docsList.map(x => {
                _docHtml +=  "<th class='text-left'><a href='"+x.url+"' target='_blank' class='btn-link text-sm view1Css'>"+x.imageTypeDescription+"</a></th>";

                
            })
                _docHtml += "</tr><tr>";
            docsList.map(x => {
                        if(!(x.url).includes(".pdf")){
                            _docHtml += "<td><img src='"+x.url+"' height='100' width='100' /></td>"
                        }else{
                            _docHtml += "<td><embed src='"+x.url+"' width='100' height='100' /></td>"
                        }
                        //_docHtml += "<td><a href='"+x.url+"' target='_blank' class='btn-link text-sm view1Css'>Click to view</a></td>";
                // if( x.showLinkFunction==="F" ){
                //     _docHtml += "<td><a href='javascript: void(0)' class='btn-link text-sm view1Css'>Click to view</a></td>";
                // }else{
                //     if( x.imageType===1 ){
                //         _docHtml += "<td><a href='"+x.imagePath+"' target='_blank' class='btn btn-link text-sm'>Click to view</a></td>";
                //     }else if( x.documentType===2 ){
                //         _docHtml += "<td><img src='"+x.url+"' height='100' width='100' /></td>"
                //     }
                // }
               
            })
            _docHtml += "</tr>";
        //    _docHtml += "<tr>";
        //    docsList.map(x => {
        //        _docHtml += "<td><a href='"+x.url+"' target='_blank' class='btn-link text-sm view1Css'>Click to view</a></td>";
        //    })
        //    _docHtml += "</tr>";
        }else{
            _docHtml =  "<tr>"+
                        "<td colspan='2'>No images at this time. Please try again later</td>"+
                        "</tr>";
        }

        
            return (
                '  <table id="innertable" class="table table-bordered" style="border-width:2px;border-color:#686868; overflow:scroll;height:100px;"> '+
                ' <tr> '+
                '   <th colspan="2" class="text-primary text-left"> SUPPORTING DOCUMENTS / FINAL INVOICE </th> '+
                ' </tr> '+
                ' '+_docHtml+' '+
                ' </table>'
            )
        }

export  const fetchEstimatesAPI = (row,rowData) =>{
    //ShipID
        trackingCharges(rowData.cfmShipID).then(responseData =>{
            if (responseData instanceof Error) {
                row.child(buildEstimateRow([])).show();
              } else {
                row.child(buildEstimateRow(responseData)).show();
              }
        })
            //  console.log("Finally call in getTermialList");
                
            //return finalData;
       
    
    }

export  const fetchDocumentsAPI = (row,rowData) =>{
    
    let responseData = null, _documents = [];

    trackingDocuments(rowData.ShipID,0).then(responseData =>{
        if (responseData instanceof Error) {
            row.child(buildDocumentsRow([])).show();
          } else {
            row.child(buildDocumentsRow(responseData)).show();
          }

    })
    // axios.get(documentsAPIUrl)
    //     .then(response => {
    //         responseData = response.data;
    //         // console.log("res="+response.data);
    //         //for(let r of responseData ){
    //             // let r = responseData[0];
        //         let _doc = {"documentName":"HAWB"  ,              
        //                     "documentLink": "https://www.freightforce.com/",
        //                     "documentType": "file",
        //                     "showLinkFunction": "F"

        //                 };

        //         _documents.push(_doc);
        //         var _doc1 = {"documentName":"BILLING/INVOICE"  ,              
        //         "documentLink": "https://www.freightforce.com/",
        //         "documentType": "file",
        //         "showLinkFunction": "F"
        //     };

        //         _documents.push(_doc1);
        //         let _doc2 = {"documentName":"POD"  ,              
        //         "documentLink": "https://www.freightforce.com/",
        //         "documentType": "file",
        //         "showLinkFunction": "F"
        //         };
        //     _documents.push(_doc2);

        //    // }  
        //     // console.log("finalData="+JSON.stringify(finalData));
        // })
        // .catch(error => {
        //     //  console.log("Error in calling TerminalList"+error);
        // })
        // .finally( ()=>{
        //     row.child(buildDocumentsRow(_documents)).show();
        // })
    
    }

export  const fetchStatusHistoryAPI = (row,rowData) =>{
    //ShipID //MoveType
    //console.log("ShipfetchStatusHistoryAPI "+JSON.stringify(rowData))
    trackingStatusHistory(rowData.cfmShipID,rowData.MoveType).then(responseData=>{
        
        if (responseData instanceof Error) {
            row.child(buildStatusHistoryRow([])).show();
          } else {
            row.child(buildStatusHistoryRow(responseData)).show();
          }
    })
        
       
    
    }