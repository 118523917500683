import React ,{useState, useEffect, useRef} from "react";
//import data from '../restAPI/coverage.json'
import AddressList from "./AddressList";
import SelectElement from "../../formControls/SelectElement";
import MapContainer from "./MapContainer";
import { useSelector } from "react-redux";


const Coverage = () => {
    
    const data = useSelector((state) => state.coverage);
    //console.log("data getCarrierInformation"+JSON.stringify(data))
    useEffect(() => {
        
            // 👇️ scroll to top on page load
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

	  }, []);

    

    const regOptions= [{value:"All",label:"All"},
                    {value:"Northeast",label:"Northeast"},
                    {value:"Southeast",label:"Southeast"},
                    // {value:"Southwest",label:"Southwest"},
                    {value:"West",label:"West"},
                    {value:"Midwest",label:"Midwest"}];
    const [selectedVal,setSelectedVal] = useState({selVal:"All"});
    const [addressList,setAddressList] = useState([]);
    const focusDiv = useRef();
    
    let addrList = [];
    const [locationsList,setLocationsList] = useState(data);
    const [selectedLocation,setSelectedLocation] = useState([]);
    const handleChange=(name, value )  =>{
        setSelectedVal({[name]: value});
      }
    
    
    useEffect(() => {
        let mounted = true;
            if(mounted) {
                addrList.splice(0, addrList.length); 
                addrList=data.filter( eachObj =>{
                    if(eachObj.region===selectedVal.selVal || selectedVal.selVal === "All")
                    return eachObj;
                })

                setLocationsList(addrList);
                
                //  console.log("Selected Value "+selectedVal.selVal+JSON.stringify(addrList));
                // console.log("::"+locationsList.length)
                 let tempAddrList=[];
                 for (var i = 0; i < addrList.length; i+=3){
                    let addr1={},addr2={},addr3={},addr4={};
                    if(i<addrList.length)
                    addr1=addrList[i];
                    if((i+1)<addrList.length)
                    addr2=addrList[i+1];
                    if((i+2)<addrList.length)
                    addr3=addrList[i+2];
                  
                    tempAddrList.push(<AddressList key={i} addr1={addr1} addr2={addr2} addr3={addr3} showLocation={showLocation} />);
                } 
                
                setAddressList([...tempAddrList]);
                //console.log(JSON.stringify(addressList));
            }
        return () => mounted = false;
      }, [selectedVal.selVal])

      const showLocation = (addr) => {
        // window.scrollTo(0, focusDiv.current.offsetTop) 
        window.scrollTo({
            top: focusDiv.current.offsetTop,
            left: 0,
            behavior: 'smooth'
          });
         //console.log("selected lcoation"+JSON.stringify(addr))  
        setSelectedLocation(addr);
        
      }

        const userProfile = useSelector((state) => state.usr)
        let sectionClassname = "mt-180 mb-4";
        let divClassname = "container";
        if( userProfile.isValid ) { //login pages
            sectionClassname = '';
            divClassname = '';
        }

    return (
        <section className={sectionClassname}>
        <div className={divClassname}>
        <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-3 pageHead text-uppercase">Coverage</h4>
                        <hr/>
                    </div>
                    <form>
                        
                        <div className="row">
                            <div className="col-lg-6 col-md-12 mb-2">
                            <div className="form-group row">

    <label htmlFor="selVal" className="col-md-4 QuoteLabel15Black pt-1">Select Region</label>
    <div className="col-sm-8">

    <SelectElement id="selVal" name="selVal" value={selectedVal.selVal || ''}  optionsData={regOptions} 
                                    className="form-control" onChange={handleChange}  />
    </div>
  </div>
                            </div>
                        </div>
                        <div className="row"  ref={focusDiv}>
                            <div className="col-md-12 mt-4 mb-4">
                                <MapContainer  locationsList={locationsList} selectedLocation={selectedLocation} />
                                {/* <Map /> */}
                            </div>
                        </div>
                        { 
                            addressList 
                            &&
                            <div className="row pl-4 pt-4">
                                {addressList}
                            </div>
                        }
                    </form>
                
                    {/* <div className="container">
                        <div className="row m-2">
                        <div className="col-md-2 p-2">
                            <label id="QuoteLabel" >Select Region</label>
                        </div>
                        <div className="col-md-2">
                            <SelectElement id="selVal" name="selVal" value={selectedVal.selVal || ''}  optionsData={regOptions} 
                            className="form-control" onChange={handleChange}  />
                        </div>
                        </div>
                        <div className="row col-md-4 order-md-1">
                        <hr/>
                        </div>
                        <div className="row">
                                {addressList}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        </div>
        </section>
    );
}

export default Coverage;