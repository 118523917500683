import React,{useEffect} from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { sendEmail } from '../services/restAPI/UtilsRest';
import { isValidEmail, isValidPhoneNo } from '../utils/ValidationScripts';

const ContactUs = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);

	const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });

	const [contactData, setContactData] = useState({
		firstName : "",
		lastName : "",
		phone : "",
		email : "",
		message : ""
	});
	const [errors, setErrors] = useState({
        firstName : "",
		lastName : "",
		phone : "",
		email : "",
		message : ""
    });
    const [errorCss, setErrorCss] = useState({
        firstName : "",
		lastName : "",
		phone : "",
		email : "",
		message : ""
    });
	const changeHandler = (e) => {
		let id = e.target.name
		let val = e.target.value;
		setContactData({ ...contactData , [id] : val });
		
		if( id!=='lastName' ){
			if( val==='' ){
				errorCss[id]='is-invalid';
			}else{
				errors[id]='';
				errorCss[id]='is-valid';
			}

			if( id==='phone' && val ){
				if( isValidPhoneNo(val) ){
					errors[id]='';
					errorCss[id]='is-valid';
				}else{
					errorCss[id]='is-invalid';
				}
			}else if( id==='email' && val ){
				if( isValidEmail(val) ){
					errors[id]='';
					errorCss[id]='is-valid';
				}else{
					errorCss[id]='is-invalid';
				}
			}
		}
	}
	const submitMessage = () => {
		let _errors = {}, _errorCss = {};
        let fname = contactData.firstName;
        let lname = contactData.lastName;
		let phone = contactData.phone;
        let email = contactData.email;
		let msg = contactData.message;

        if( !fname ){
            _errors.firstName = "Required";
            _errorCss.firstName = 'is-invalid';
        }
        if( !phone ){
            _errors.phone = "Required";
            _errorCss.phone = 'is-invalid';
        }else if( !isValidPhoneNo(phone) ){
            _errors.phone = "Invalid";
            _errorCss.phone = 'is-invalid';
        }
		if( !email ){
            _errors.email = "Required";
            _errorCss.email = 'is-invalid';
        }else if( !isValidEmail(email) ){
            _errors.email = "Invalid";
            _errorCss.email = 'is-invalid';
        }
		if( !msg ){
            _errors.message = "Required";
            _errorCss.message = 'is-invalid';
        }

        if( Object.keys(_errors).length===0 ){
			let _html = `<html><body>
							<table width='50%' border=1>
								<tr> 
									<th width='25%' align='left'> Sender Name : </th> 
									<td width='75%'> ${contactData.firstName} ${contactData.lastName} </td> 
								</tr>
								<tr> 
									<th align='left'> Phone : </th> 
									<td> ${contactData.phone} </td> 
								</tr>
								<tr> 
									<th align='left'> Email : </th> 
									<td> ${contactData.email} </td> 
								</tr>
								<tr> 
									<th align='left'> Message : </th> 
									<td> ${contactData.message} </td> 
								</tr>
							</table>
						</body></html>`
            let mailObj = {
				"toRecipients": "itsupport@freightforce.com",
				//"sowmyasanthoshi.a1236@gmail.com",
				"ccRecipients": "",
				"bccRecipients": "",
				"subject": "Contact Us Message from "+contactData.email,
				"messageBody": _html
			};
			// console.log("mailObj",mailObj);
			sendEmail(mailObj).then(eRes => {
				//  console.log("erro objecct is final = " + JSON.stringify(eRes))
				// console.log("erro objecct is final = ",eRes.result.emailResponse);
				if (eRes instanceof Error) {
					// console.log("erro objecct is final = " + JSON.stringify(eRes))
					
					setSaveMessage({showMsg: true, status: 'error', message: eRes.result.emailResponse});
				} else {
					setSaveMessage({showMsg: true, status: 'message', message: eRes.result.emailResponse});
				}
			})
			setTimeout(()=>{
				clearForm();
			},2000);
        }else{
            // setErrors(_errors);
            setErrorCss(_errorCss);
        }
	}
	const clearForm = () => {
		setContactData({
			firstName : "",
			lastName : "",
			phone : "",
			email : "",
			message : "",
			
		});
		setSaveMessage({showMsg : false});
		setErrors({
			firstName : "",
			lastName : "",
			phone : "",
			email : "",
			message : ""
		});
		setErrorCss({
			firstName : "",
			lastName : "",
			phone : "",
			email : "",
			message : ""
		});
	}
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">Contact Us</h4>
                        </div>
							<div className="row">
								<div className="col-lg-9 col-md-12">
								<div>
									<table className="table table-bordered table-striped" id="exeTbl">
										<thead>
											<tr>
												<th>Title</th>
												<th>Name</th>
												<th>Phone</th>
												<th>Email</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>CEO</td>
												<td>Sue Beattie</td>
												<td>714.995.9300 ext 40059</td>
												<td><a href="mailto:sueb@freightforce.com" target="_blank">sueb@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Chief Operations Officer</td>
												<td>Dennis Kuhlman</td>
												<td>714.995.9300 ext 40060</td>
												<td><a href="mailto:dennis.kuhlman@freightforce.com" target="_blank">dennis.kuhlman@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Director, Operations</td>
												<td>Eric Carlson</td>
												<td>714.995.9300 ext 40058</td>
												<td><a href="mailto:ericc@freightforce.com" target="_blank">ericc@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Controller</td>
												<td>Jessie O’Bryan</td>
												<td>714.995.9300 ext 40064</td>
												<td><a href="mailto:jessieo@freightforce.com" target="_blank">jessieo@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Collection Manager</td>
												<td>Dee Anna Glenn</td>
												<td>714.995.9300 ext 40066</td>
												<td><a href="mailto:deeg@freightforce.com" target="_blank">deeg@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Office Manager</td>
												<td>My Vu</td>
												<td>714.995.9300 ext 40073</td>
												<td><a href="mailto:myv@freightforce.com" target="_blank">myv@freightforce.com</a></td>
											</tr>
											<tr>
												<td>Manager, Data Engineer</td>
												<td>Prajna Solanki</td>
												<td>714.995.9300 ext 40083</td>
												<td><a href="mailto:prajna.solanki@stgusa.com" target="_blank">prajna.solanki@stgusa.com</a></td>
											</tr>
											<tr>
												<td>Freight Force Solutions Group</td>
												<td>Justin Buby</td>
												<td>310.953.7837</td>
												<td><a href="mailto:sales@freightforce.com" target="_blank">justinb@freightforce.com</a></td>
											</tr>
											</tbody>
									</table>
									<hr/>
									<table className="table table-bordered table-striped" id="exeTbl">
										<thead>
											<tr>
												<th>Department</th>
												<th>Phone Number</th>
												<th>Hours</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Accounting</td>
												<td>714.995.9300 ext 40064</td>
												<td>Mon - Fri. 8am - 4:30pm PST</td>
											</tr>
											<tr>
												<td>Billing</td>
												<td>714.995.9300 ext 40073</td>
												<td>Mon - Fri. 7:30am - 4pm PST</td>
											</tr>
											<tr>
												<td>Collections</td>
												<td>714.995.9300 ext 40066</td>
												<td>Mon - Fri. 7:30am - 4pm PST</td>
											</tr>
											<tr>
												<td>Sales &amp; Marketing</td>
												<td>714.995.9300 ext 40060</td>
												<td>Mon - Fri. 7:30am - 4pm PST</td>
											</tr>
											<tr>
												<td>IT/MIS</td>
												<td>714.995.9300 ext 40083</td>
												<td>Mon - Fri. 7:00am - 4pm PST</td>
											</tr>
											<tr>
												<td><span className="text-danger">Operations after hour emergencies</span></td>
												<td>
													<NavLink to="/carrierDirectory" title="Carrier Directory">
														<b>Click here for the 24hr number for our Carriers</b>
													</NavLink>
												</td>
												<td></td>
											</tr>
											<tr>
												<td><span className="text-danger">Corporate after hours assistance</span></td>
												<td><span className="text-danger">Eric Carlson, Director of Operations<br/>
												847.477.5628</span></td>
												<td></td>
											</tr>
											<tr>
												<td><span className="text-danger">IT/MIS after hour emergencies</span></td>
												<td><span className="text-danger">714.995.9300 x40083</span></td>
												<td></td>
											</tr>
											</tbody>
									</table>	
								</div>
								</div>
								<div className="col-md-12 col-lg-3">
									<div className="mb-2">
									<h5>Our Location</h5>
										<div className="location">
											<h6>Freight Force, Inc.</h6>
											<i className="fas fa-map-marker-alt"></i>
											<p>14445 Alondra Blvd <br/>
											La Mirada, CA 90638</p>
											<i className="fas fa-fax"></i><p>Tel: (714) 995.9300, <br/>Fax: (714) 995.9330</p>
											<NavLink to="/coverage" title="Coverage">
												<b>Click here for Approved Motor Carriers</b>
											</NavLink>
										</div>
									</div>
									<hr/>
									<div className="mb-2">
									<h5>Send us a Message</h5>
										<form className="needs-validation">
											{saveMessage.showMsg &&
												<div className="row">
													<div className="col-md-12 text-center text-bold">
														{saveMessage.status.trim() === "error" ?
															<div className="alert alert-danger">
																<i className="fas fa-times-circle"></i> 
																{saveMessage.message}
															</div>
															:
															<div className="alert alert-primary">
																{saveMessage.message.trim()==="SUCCESS" ? 
																	"Message sent successfully !" : 
																	saveMessage.message
																}
															</div>
														}
													</div>
												</div>
											}
											 <div className="row mb-2">
												<div className="col-md-12">
													<label htmlFor="fName">First Name <span className='text-danger'>*</span>
													</label>
													<input type="text" id='firstName' name='firstName' onChange={changeHandler} placeholder="First Name" 
														className={`form-control ${errorCss.firstName}`} value={contactData.firstName}
														required maxLength={20} />
													{errors.firstName && <div className='text-danger'> {errors.firstName} </div> }
													<div className="invalid-feedback">
														required.
													</div>
												</div>
											</div>
											 <div className="row mb-2">
												<div className="col-md-12">
													<label htmlFor="lName">Last Name</label>
													<input type="text" id='lastName' name='lastName' onChange={changeHandler} placeholder="First Name" 
														className={`form-control ${errorCss.lastName}`} value={contactData.lastName}
														required maxLength={20} />
													<div className="invalid-feedback">
														required.
													</div>
												</div>
											</div>
											<div className="row mb-2">
												<div className="col-md-12">
													<label htmlFor="phone">Phone <span className='text-danger'>*</span></label>
													<input type="number" id='phone' name='phone' onChange={changeHandler} placeholder="Phone" 
														className={`form-control ${errorCss.phone}`} value={contactData.phone}
														required  maxLength={12} />
													<div className="invalid-feedback">
														required.
													</div>
												</div>
											</div>
											<div className="row mb-2">
												<div className="col-md-12">
													<label htmlFor="email">Email <span className='text-danger'>*</span></label>
													<input type="email" id='email' name='email' onChange={changeHandler} placeholder="Email" 
														className={`form-control ${errorCss.email}`} value={contactData.email}
														required maxLength={35} />
													<div className="invalid-feedback">
														required.
													</div>
												</div>
											</div>
											<div className="row mb-3">
												<div className="col-md-12">
													<label htmlFor="msg">Message <span className='text-danger'>*</span></label>
													<textarea  id='message' name='message' onChange={changeHandler} placeholder="Message" 
														className={`form-control ${errorCss.message}`} rows="3"  value={contactData.message} ></textarea>
													<div className="invalid-feedback">
														required.
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 offset-6">
													<button className="btn btn-primary btn-block" type="button" onClick={submitMessage}>Submit &nbsp;<i className="fas fa-check-circle"></i>  </button>
												</div>
											</div>
											{/* {JSON.stringify(contactData)} */}
										</form>
									</div>
								</div>
							</div>
							<hr className="row mb-3"/>
							<div className="row mb-3">
								<div className="col-md-12">
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.005133399799!2d-118.02546328494412!3d33.889521633735484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2c9c66f10781%3A0x4ea8d2f4e5ee5eb2!2s14445%20Alondra%20Blvd%2C%20La%20Mirada%2C%20CA%2090638%2C%20USA!5e0!3m2!1sen!2sin!4v1665642584467!5m2!1sen!2sin" width="100%" height="300" style={{"border":"0"}} allowFullScreen="" loading="lazy" ></iframe>
								</div>
							</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ContactUs