import React, { useRef } from 'react'
import { ffLogoB64Data } from '../../utils/ImageB64';
import { useEffect } from 'react';
import DataAutoFill from './Data/DataAutoFill';
import JsBarcode from "jsbarcode";


const PrintBol = (props) => {
	let ffLogoB64 = ffLogoB64Data();
	const ref = useRef(null);

	const { onChangePreFill, onValidatePreFill, updatePreFillState, updatePreFillReqState, clearPreFill
		, quotePreFill, preFillRequired, typeOfMoveList, serviceTypeList, lineHaulServiceTypeList, packageTypeList } = DataAutoFill();


	let qqData = props.quickQuoteData;
	let qbData = props.bookingRespData;

	//console.log("qq in pbol",JSON.stringify(qbData));

	let custData = null, shData = null, csData = null, shipmentData = null;

	let wayBill = null, bookingNumber = null, originAriport = null, destinationAriport = null
		, shipperName = null, shipperAddress = null, shipperCity = null, shipperState = null, shipperZip = null, shipperContact = null, shipperTelephone = null, shipperFromTime = null, shipperToTime = null, shipperAccountNo = null
		, consigneeName = null, consigneeAddress = null, consigneeCity = null, consigneeState = null, consigneeZip = null, consigneeContact = null, consigneeTelephone = null, consigneeFromTime = null, consigneeToTime = null
		, customerName = null, customerAddress = null, customerCity = null, customerState = null, customerZip = null, customerAccountNo = null
		, noOfPcs = null, bookedWeight = null, _accessorialAmt = 0.0, _freightCharge = 0.0, _fuelSurcharge = 0.0, _totalCharges = 0.0
		, instructions = null, accessorials = null, accessorialData = [], pickupAccessorials = [], pickupAccessorialData = [], deliveryAccessorials = [], deliveryAccessorialData = [], _accSerType = null
		, _serviceType = '', _lineHaulServiceType = '', _moveType = '', consigneeNotes = '', shipperNotes = '', _dimWeight = '', _weight = '', _qty = ''
		, rateQuote = null, _isLinehaul = false, _pCity = '', _dCity = '', _cbm = '', transitTime = '',custReference = '',mawb=''
		, _pickup_FreightCharge = 0.00, _pickup_FSC = 0.00, _pickup_FscRate = 0.00, _total_Pickup = 0.00, pickupAccessorialAmt = 0.00, deliveryAccessorialAmt = 0.00
		, _westCoastSurchargeFee = 0.00, _crossDockFee = 0.00, _dropFee = 0.00, _yvrBorderFee = 0.00, _yvrCrossFee = 0.00
		, _delivery_FreightCharge = 0.00, _delivery_FSC = 0.00, _delivery_FscRate = 0.00, _total_Delivery = 0.00, _lineHaulPrice = 0.00
		, totalDimWeight = 0.0, dimensionsList = null, _Pronumber = '', _RrBolNumber = '', _isRoadRunner = false;

	if (qbData) {
		// custData = qbData.customer;
		//console.log("printbol data" + JSON.stringify(qbData))
		shData = qbData.shipper;
		csData = qbData.consignee;
		shipmentData = qbData.shipment;
		shipperAccountNo = qbData.custCode;
		customerAccountNo = qbData.custCode;
		_isLinehaul = qbData.isLinehaul;
		_isRoadRunner = qqData.isRoadRunner;
		_Pronumber = qbData.pronumber;
		_RrBolNumber = qbData.rrBolNumber;
		transitTime = qbData.transitTime;
		
		if(qbData?.rateQuote)
		rateQuote = qbData?.rateQuote;
		else
		rateQuote = qbData?.quote?.rateQuote;
		_freightCharge = parseFloat(rateQuote?.freight_charge).toFixed(2);
		_fuelSurcharge = parseFloat(rateQuote?.fsc).toFixed(2);
		_pCity = (rateQuote?.pCity ? rateQuote?.pCity : '');
		_dCity = (rateQuote?.dCity ? rateQuote?.dCity : '');
		_pickup_FreightCharge = (rateQuote?.pickup_FreightCharge ? parseFloat(rateQuote?.pickup_FreightCharge).toFixed(2) : 0.00);
		_pickup_FSC = (rateQuote?.pickup_FSC ? parseFloat(rateQuote?.pickup_FSC).toFixed(2) : 0.00);
		_pickup_FscRate = (rateQuote?.pickup_FscRate ? parseFloat(rateQuote?.pickup_FscRate).toFixed(2) : 0.00);
		_total_Pickup = (rateQuote?.total_Pickup ? parseFloat(rateQuote?.total_Pickup).toFixed(2) : 0.00);
		_delivery_FreightCharge = (rateQuote?.delivery_FreightCharge ? parseFloat(rateQuote?.delivery_FreightCharge).toFixed(2) : 0.00);
		_delivery_FSC = (rateQuote?.delivery_FSC ? parseFloat(rateQuote?.delivery_FSC).toFixed(2) : 0.00);
		_delivery_FscRate = (rateQuote?.delivery_FscRate ? parseFloat(rateQuote?.delivery_FscRate).toFixed(2) : 0.00);
		//_lineHaulPrice = (_pickup_FreightCharge+_pickup_FSC+_pickup_FscRate+_delivery_FreightCharge+_delivery_FSC+_delivery_FscRate);
		_lineHaulPrice = (rateQuote?.lineHaulPrice ? parseFloat(rateQuote?.lineHaulPrice).toFixed(2) : 0.00);
		_total_Delivery = (rateQuote?.total_Delivery ? parseFloat(rateQuote?.total_Delivery).toFixed(2) : 0.00);
		if (_isLinehaul || _isRoadRunner) {
			_westCoastSurchargeFee = (rateQuote?.westCoastSurchargeFee ? parseFloat(rateQuote?.westCoastSurchargeFee).toFixed(2) : 0.00);
			_crossDockFee = (rateQuote?.crossDockFee ? parseFloat(rateQuote?.crossDockFee).toFixed(2) : 0.00);
			_dropFee = (rateQuote?.dropFee ? parseFloat(rateQuote?.dropFee).toFixed(2) : 0.00);
			_yvrBorderFee = (rateQuote?.yvrBorderFee ? parseFloat(rateQuote?.yvrBorderFee).toFixed(2) : 0.00);
			_yvrCrossFee = (rateQuote?.yvrCrossFee ? parseFloat(rateQuote?.yvrCrossFee).toFixed(2) : 0.00);
		}
	}

	if (shipmentData) {
		let s = shipmentData;
		if (s.serviceType) {
			let serviceTyp = serviceTypeList.find(eachobj => eachobj.value === s.serviceType);
			_serviceType = serviceTyp.label
		}
		if (s.moveType) {
			let moveTyp = typeOfMoveList.find(eachobj => eachobj.value === s.moveType);
			_moveType = moveTyp.label;
		}
		if (s.linehaulServiceType && s.linehaulServiceType != 0) {
			let lineHaulSerTyp = lineHaulServiceTypeList.find(eachobj => eachobj.value === s.linehaulServiceType);
			_lineHaulServiceType = lineHaulSerTyp.label;
		}
	}
	if (qqData) {
		custData = qqData.customer;
		originAriport = qqData.addValue ? qqData.addValue.shipFromDetails.AirportCode : '';
		destinationAriport = qqData.addValue ? qqData.addValue.shipToDetails.AirportCode : '';
		totalDimWeight = qqData.addValue ? qqData.addValue.dimWeightTotal : 0.0;
	}

	if (!originAriport) {
		originAriport = shData?.terminal;
	}
	if (!destinationAriport) {
		destinationAriport = csData?.terminal;
	}

	if (shData) {
		shipperName = shData.companyName;
		shipperAddress = shData.address1 + shData.address2;
		shipperCity = shData.city;
		shipperState = shData.state;
		shipperZip = shData.postalCode;
		shipperContact = shData.contact;
		shipperTelephone = shData.phone;
	}

	if (csData) {
		consigneeName = csData.companyName;
		consigneeAddress = csData.address1 + csData.address2;
		consigneeCity = csData.city;
		consigneeState = csData.state;
		consigneeZip = csData.postalCode;
		consigneeContact = csData.contact;
		consigneeTelephone = csData.phone;
	}

	if (custData) {
		customerName = custData.companyName;
		customerAddress = custData.address1 + custData.address2;
		customerCity = custData.city;
		customerState = custData.state;
		customerZip = custData.postalCode;
	}

	if (shipmentData) {
		noOfPcs = shipmentData.qty;
		bookedWeight = shipmentData.weight;
		wayBill = shipmentData.hawb;
		custReference = shipmentData.custReference;
		mawb = shipmentData.mawb;
		if (_isLinehaul) {
			//_Pronumber=qbData.pronumber;
			_RrBolNumber = wayBill;
		}
		bookingNumber = qbData.bookingNumber;
		_isLinehaul = qbData.isLinehaul;
		accessorials = shipmentData.accessorials;
		pickupAccessorials = shipmentData.pickupAccessorials;
		deliveryAccessorials = shipmentData.deliveryAccessorials;
		//instructions = shipmentData.notes;
		shipperNotes = shipmentData.shipperNotes;
		consigneeNotes = shipmentData.consigneeNotes;
		_accSerType = shipmentData.accSerType;
		_cbm = shipmentData.dimWeight;
		dimensionsList = shipmentData.dimList;
		_dimWeight = shipmentData.dimWeight;
		_weight = shipmentData.weight;
		_qty = shipmentData.qty;
		// Depending on timezone, your results will vary
		let shFromTime = new Date(shipmentData.pickupOpenTime);
		let shToTime = new Date(shipmentData.pickupCloseTime);

		let csFromTime = new Date(shipmentData.deliveryOpenTime);
		let csToTime = new Date(shipmentData.deliveryCloseTime);

		shipperFromTime = shFromTime.toLocaleTimeString('en-US', {
			// en-US can be set to 'default' to use user's browser settings
			hour: '2-digit',
			minute: '2-digit',
		});
		shipperToTime = shToTime.toLocaleTimeString('en-US', {
			// en-US can be set to 'default' to use user's browser settings
			hour: '2-digit',
			minute: '2-digit',
		});
		consigneeFromTime = csFromTime.toLocaleTimeString('en-US', {
			// en-US can be set to 'default' to use user's browser settings
			hour: '2-digit',
			minute: '2-digit',
		});
		consigneeToTime = csToTime.toLocaleTimeString('en-US', {
			// en-US can be set to 'default' to use user's browser settings
			hour: '2-digit',
			minute: '2-digit',
		});

		if (accessorials) {
			let amt = 0.0;
			accessorials.forEach(eachAcc => {
				if (eachAcc.surType) {
					if ((eachAcc.surType).trim() === 'FLAT')
						amt = eachAcc.amount;
					else if ((eachAcc.surType).trim() === 'PERPOUND') {
						if (eachAcc.min > (eachAcc.amount * parseInt(shipmentData.weight)))
							amt = eachAcc.min;
						else
							amt = eachAcc.amount * parseInt(shipmentData.weight);
					}
				}
				if (_accSerType === "both" && !_isLinehaul)
					amt = amt * 2;

				amt = parseFloat(amt).toFixed(2);
				_accessorialAmt = Number(_accessorialAmt) + Number(amt);
				//console.log("tot",_accessorialAmt,"amt",amt)
				accessorialData.push({ "amt": amt, "description": eachAcc.description });
			})
			_accessorialAmt = parseFloat(_accessorialAmt).toFixed(2);
		}

		if (pickupAccessorials) {
			let amt = 0.0;
			pickupAccessorials.forEach(eachAcc => {
				if (eachAcc.surType) {
					if ((eachAcc.surType).trim() === 'FLAT')
						amt = eachAcc.amount;
					else if ((eachAcc.surType).trim() === 'PERPOUND') {
						if (eachAcc.min > (eachAcc.amount * parseInt(shipmentData.weight)))
							amt = eachAcc.min;
						else
							amt = eachAcc.amount * parseInt(shipmentData.weight);
					}
				}
				amt = parseFloat(amt).toFixed(2);
				pickupAccessorialAmt = Number(pickupAccessorialAmt) + Number(amt);
				//console.log("tot",_accessorialAmt,"amt",amt)
				pickupAccessorialData.push({ "amt": amt, "description": eachAcc.description });
			})
			pickupAccessorialAmt = parseFloat(pickupAccessorialAmt).toFixed(2);
		}

		if (deliveryAccessorials) {
			let amt = 0.0;
			deliveryAccessorials.forEach(eachAcc => {
				if (eachAcc.surType) {
					if ((eachAcc.surType).trim() === 'FLAT')
						amt = eachAcc.amount;
					else if ((eachAcc.surType).trim() === 'PERPOUND') {
						if (eachAcc.min > (eachAcc.amount * parseInt(shipmentData.weight)))
							amt = eachAcc.min;
						else
							amt = eachAcc.amount * parseInt(shipmentData.weight);
					}
				}
				amt = parseFloat(amt).toFixed(2);
				deliveryAccessorialAmt = Number(deliveryAccessorialAmt) + Number(amt);
				//console.log("tot",_accessorialAmt,"amt",amt)
				deliveryAccessorialData.push({ "amt": amt, "description": eachAcc.description });
			})
			deliveryAccessorialAmt = parseFloat(deliveryAccessorialAmt).toFixed(2);
		}
		_accessorialAmt = Number(pickupAccessorialAmt) + Number(deliveryAccessorialAmt);
		_accessorialAmt = parseFloat(_accessorialAmt).toFixed(2);
		if (_isLinehaul)
			_totalCharges = (Number(_total_Pickup) + Number(_total_Delivery) + Number(_freightCharge) + Number(_fuelSurcharge) + Number(_accessorialAmt) + Number(_westCoastSurchargeFee) + Number(_crossDockFee) + Number(_dropFee) + Number(_yvrBorderFee) + Number(_yvrCrossFee)).toFixed(2);
		else
			_totalCharges = (Number(_freightCharge) + Number(_fuelSurcharge) + Number(_accessorialAmt)).toFixed(2);

	}

	// const cont = useRef(0);


	useEffect(() => {
		//ref.current.removeChild(ref.current.children[0])

		//Refer for options : https://www.npmjs.com/package/jsbarcode
		const canvas = document.createElement("canvas");
		JsBarcode(canvas, bookingNumber, {
			// format: "pharmacode",
			// lineColor: "#0aa",
			width: 2,
			height: 40,
			// font:'arial'
			fontSize: 15,
			margin: 5,
			displayValue: true
		});
		ref.current.appendChild(canvas);
		// cont.current = cont.current + 1;
		// console.log(cont);
	}, [ref]);


	return (
		// <section className="mt-180 mb-4">
		//     <div className="container">
		<div className="row wordSpacing">
			<div className="col-md-12">
				<div className="card card-custom">
					<form className="needs-validation" noValidate id="">
						{/* <div>
								{JSON.stringify(qqData)}
								-----------------
								{JSON.stringify(qbData)}
							</div> */}
						<div className="col-md-12 rcptBOL">
							<div className="row mb-2">
								<div className="col-md-12 p-0 ">
									<div className="divBrdr">
										<div className="row">
											<div className="col-lg-4 text-center mt-2">
												<img src={ffLogoB64} alt="Freight Force" className="imgInlogo" />
												{_isRoadRunner &&
												<><br></br><b>RRTS Bill Freight Force Account #713121</b></>
												}
											</div>
											<div className="col-lg-5">
												<table className="recNoTbl">
													<tbody>
														<tr>
															{(_isLinehaul || _isRoadRunner) ?
																<td rowSpan="2">
																	<label>Order Number</label>
																	<h4>{bookingNumber}</h4>
																</td> :
																<td rowSpan="2">
																	<label>Way Bill</label>
																	<h4>{wayBill}</h4>
																</td>
															}

															{(_isLinehaul || _isRoadRunner) ? ''
																: <td colSpan="2">
																	<label>Number:</label>
																	<h4>{bookingNumber}</h4>
																</td>
															}

															{(_isLinehaul || _isRoadRunner) ?
																<td >
																	<label>HAWB Number:</label>
																	<h4>{wayBill}</h4>
																</td> : ''
															}
															{(_isLinehaul || _isRoadRunner) ?
																<td>
																	<label>LINEHAUL PRO Number:</label>
																	<h4>{_Pronumber}</h4>
																</td> : ''
															}
														</tr>
														<tr>
															<td>
																<label>Origin Airport</label>
																<h4>{originAriport}</h4>
															</td>
															<td>
																<label>Destination Airport</label>
																<h4>{destinationAriport}</h4>
															</td>

														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-md-6 p-0 ">
									<div className="divBrdr">
										<div className="row">
											<div className="col-lg-2 orntSet centAlgn">
												{/* <div className="txtOrnt text-center">Shipper</div> */}
												<div className="text-center">
													S<br />
													H<br />
													I<br />
													P<br />
													P<br />
													E<br />
													R<br />
												</div>

											</div>
											<div className="col-lg-10 pl-0 pt-1">
												<div className="row m-0">
													<div className="col-lg-12 ">
														<label>Name:</label>
														<h4>{shipperName}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-12 ">
														<label>Street Address:</label>
														<h4>{shipperAddress}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-6">
														<label>City</label>
														<h4>{shipperCity}</h4>
													</div>
													<div className="col-lg-6">
														<label>State / ZIP</label>
														<h4>{shipperState} {shipperZip}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-6">
														<label>Contact</label>
														<h4>{shipperContact}</h4>
													</div>
													<div className="col-lg-6">
														<label>Telephone No.</label>
														<h4>{shipperTelephone}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-4">
														<label>From Time</label>
														<h4 className='text-nowrap'>{shipperFromTime}</h4>
													</div>
													<div className="col-lg-4 brdrRight">
														<label>To Time</label>
														<h4 className='text-nowrap'>{shipperToTime}</h4>
													</div>
													<div className="col-lg-4">
														<label>Account No</label>
														<h4>{shipperAccountNo}</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 pr-0 ">
									<div className="divBrdr">
										<div className="row">
											<div className="col-lg-2 orntSet centAlgn">
												{/* <div className="txtOrnt text-center" style={{"writing-mode": "vertical-lr", "text-orientation": "upright"}}>Consignee</div> */}
												<div>
													C<br />
													O<br />
													N<br />
													S<br />
													I<br />
													G<br />
													N<br />
													E<br />
													E
												</div>
											</div>
											<div className="col-lg-10 pl-0 pt-1">
												<div className="row m-0">
													<div className="col-lg-12 ">
														<label>Name:</label>
														<h4>{consigneeName}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-12 ">
														<label>Street Address:</label>
														<h4>{consigneeAddress}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-6">
														<label>City</label>
														<h4>{consigneeCity}</h4>
													</div>
													<div className="col-lg-6">
														<label>State / ZIP</label>
														<h4>{consigneeState} {consigneeZip}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-6">
														<label>Contact</label>
														<h4>{consigneeContact}</h4>
													</div>
													<div className="col-lg-6">
														<label>Telephone No.</label>
														<h4>{consigneeTelephone}</h4>
													</div>
												</div>
												<hr className="my-2" />
												<div className="row m-0">
													<div className="col-lg-6">
														<label>From Time</label>
														<h4>{consigneeFromTime}</h4>
													</div>
													<div className="col-lg-6">
														<label>To Time</label>
														<h4>{consigneeToTime}</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-lg-12 divBrdr">
									<div className="row p-2">
										{(_isLinehaul || _isRoadRunner) ? <>
											<div className="col-lg-3 text-right font-weight-bold">
												Transit Time &nbsp;&nbsp;: &nbsp;&nbsp;
											</div>
											<div className="col-lg-3 ">
												{transitTime}
											</div>
										</> :
											<>
												<div className="col-lg-3 text-right font-weight-bold">
												{(_isLinehaul || _isRoadRunner) ? '': 'Type of Move' }&nbsp;&nbsp;: &nbsp;&nbsp;
												</div>
												<div className="col-lg-3 ">
													{(_isLinehaul || _isRoadRunner) ? '' : _moveType}
												</div>
											</>}
										<div className="col-lg-3 text-right font-weight-bold">
											{(_isLinehaul || _isRoadRunner) ? 'Linehaul Service Type' : ''}	 &nbsp;&nbsp;: &nbsp;&nbsp;
										</div>
										<div className="col-lg-3 ">
											{(_isLinehaul || _isRoadRunner) ? _lineHaulServiceType : ''}
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-lg-12 divBrdr">
									<div className="row p-2">
										<div className="col-lg-3 ">
											<label>Bill To</label>
											<h5>{customerName}</h5>
										</div>
										<div className="col-lg-3">
											<label>Street Address</label>
											<h5>{customerAddress}</h5>
										</div>
										<div className="col-lg-2">
											<label>City</label>
											<h5>{customerCity}</h5>
										</div>
										<div className="col-lg-2 brdrRight">
											<label>State/ZIP</label>
											<h5>{customerState} {customerZip}</h5>
										</div>
										<div className="col-lg-2">
											<label>Account No</label>
											<h5>{customerAccountNo}</h5>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-lg-12 p-0">
									<table className="descTbl" style={{ 'width': '100%', 'border': '1px solid #ccc' }}>
										<thead>
											<tr>
												<th>No. PCS.</th>
												<th>Description</th>
												<th>Length</th>
												<th>Width</th>
												<th>Height</th>
											</tr>
										</thead>
										<tbody>
										{dimensionsList.map((d, j) => {
												return (
											<tr >
												<td className="text-center">{d.qty}</td>
												<td className="text-center">{d.description}</td>
												<td className="text-center">{d.length}</td>
												<td className="text-center">{d.width}</td>
												<td className="text-center">{d.height}</td>
											</tr>
												)})
											}
										</tbody>
										<tfoot>
										<tr>
												<td className="text-center" colSpan={2}>Total Number of Pieces</td>
												<td className="text-center" colSpan={2}>{_qty}</td>
												<td></td>
											</tr>
										<tr>
												<td className="text-center" colSpan={2}>Actual Gross Weight</td>
												<td className="text-center" colSpan={2}>{_weight} LBS</td>
												<td></td>
											</tr>
											<tr>
												<td className="text-center" colSpan={2}>Total Dim Weight</td>
												<td className="text-center" colSpan={2}>{_dimWeight}</td>
												<td></td>
											</tr>
										</tfoot>
										{/* <tfoot>
											<tr>
												<td colSpan={2}>Accessorial Total </td>
												<td className="text-center"><h4>${_accessorialAmt}</h4></td>
												<td colSpan={3}></td>
											</tr>
											<tr>
												<td colSpan={2}>{(_isLinehaul || _isRoadRunner) ? "Linehaul Charge" : "Freight Charge"} </td>
												<td className="text-center"><h4>${_freightCharge}</h4></td>
												<td colSpan={3}></td>
											</tr>
											<tr>
												<td colSpan={2}>Fuel Surcharge </td>
												<td className="text-center"><h4>${_fuelSurcharge}</h4></td>
												<td colSpan={3}></td>
											</tr>
											{(_isLinehaul || _isRoadRunner) && _westCoastSurchargeFee !== 0.00 &&
												<tr>
													<td colSpan={2}>West Coast Surcharge Fee </td>
													<td className="text-center"><h4>${_westCoastSurchargeFee}</h4></td>
													<td colSpan={3}></td>
												</tr>
											}
											{(_isRoadRunner || _isLinehaul) && _crossDockFee !== 0.00 &&
												<tr>
													<td colSpan={2}>Cross Dock Fee </td>
													<td className="text-center"><h4>${_crossDockFee}</h4></td>
													<td colSpan={3}></td>
												</tr>
											}
											{(_isRoadRunner || _isLinehaul) && _dropFee !== 0.00 &&
												<tr>
													<td colSpan={2}>Drop Fee </td>
													<td className="text-center"><h4>${_dropFee}</h4></td>
													<td colSpan={3}></td>
												</tr>
											}
											{(_isRoadRunner || _isLinehaul) && _yvrBorderFee !== 0.00 &&
												<tr>
													<td colSpan={2}>YVR Border Fee </td>
													<td className="text-center"><h4>${_yvrBorderFee}</h4></td>
													<td colSpan={3}></td>
												</tr>
											}
											{(_isRoadRunner || _isLinehaul) && _yvrCrossFee !== 0.00 &&
												<tr>
													<td colSpan={2}>YVR Cross Fee </td>
													<td className="text-center"><h4>${_yvrCrossFee}</h4></td>
													<td colSpan={3}></td>
												</tr>
											}
											{(_isRoadRunner || _isLinehaul) &&
												<>
													<tr>
														<td colSpan={2}>Pickup Charges </td>
														<td className="text-center"><h4>${_total_Pickup}</h4></td>
														<td colSpan={3}></td>
													</tr><tr>
														<td colSpan={2}>Delivery Charges </td>
														<td className="text-center"><h4>${_total_Delivery}</h4></td>
														<td colSpan={3}></td>
													</tr>
												</>
											}
											<tr>
												<td colSpan={2}>Total Charges
												</td>
												<td className="text-center"><h4>${_totalCharges}</h4></td>
												<td colSpan={3}></td>
											</tr>
										</tfoot> */}
									</table>
								</div>
							</div>

							{(_isRoadRunner || _isLinehaul) &&
								<div className="row mb-2" hidden={true}>
									<div className="col-lg-12 p-0">
										<table className="descTbl" style={{ 'width': '100%', 'border': '1px solid #ccc' }}>
											<colgroup>
												<col width='25%'></col>
												<col width='25%'></col>
												<col width='25%'></col>
												<col width='25%'></col>
											</colgroup>
											<thead>
												{/* <tr>
											<td colSpan={4} className='text-center'>LINEHAUL CHARGES</td>
										</tr> */}
												<tr>
													<td colSpan={2} className='text-center'>Linehaul Pickup From &nbsp; : &nbsp; {_pCity} </td>
													<td colSpan={2} className='text-center'>Linehaul Delivery To &nbsp; : &nbsp; {_dCity} </td>
												</tr>
											</thead>
											{/* <tbody>
										<tr>
											<td className='text-left'>Pickup Freight Charge</td>
											<td>${_pickup_FreightCharge}</td>
											<td className='text-left'>Delivery Freight Charge</td>
											<td>${_delivery_FreightCharge}</td>
										</tr>
										<tr>
											<td className='text-left'>Pickup Fuel Surcharge</td>
											<td>${_pickup_FSC}</td>
											<td>Delivery Fuel Surcharge</td>
											<td>${_delivery_FSC}</td>
										</tr>
									</tbody> */}
										</table>
									</div>
								</div>
							}

							<div className="row mb-2">
								<div className="col-lg-12 divBrdr">
									<div className="row p-2">
										<div className="col-lg-12">
											<label>Shipment Information provided by Shipper</label>
											{/* <h5>REF NBRS: &nbsp; <span className="dispThin">_______</span></h5> */}
											<div className="row p-2">
											<table className="descTbl" style={{ 'width': '100%', 'border': '1px solid #ccc' }}>
											<colgroup>
												<col width='33%'></col>
												<col width='33%'></col>
												<col width='33%'></col>
											</colgroup>
											<thead>
											
												<tr>
													<td className='text-center'>HAWB &nbsp; : &nbsp; {wayBill} </td>
													<td className='text-center'>MAWB &nbsp; : &nbsp; {mawb} </td>
													<td className='text-center'>REF# &nbsp; : &nbsp; {custReference} </td>
												</tr>
											</thead>
										</table>
										</div>
										</div>
									</div>
									<hr className="p-0 m-0" />
									<div className="row p-2">
										<div className="col-lg-12 ">
											<h5>Customer Booked Weight:&nbsp;
												<span className="inlineText">{bookedWeight} LBS</span> </h5>

												{(_isRoadRunner)? <h8 className="inlineBackground">ALL SHIPMENTS ARE SUBJECT TO DIM & REWEIGH VERIFICATION AT ORIGIN</h8> :""}
										</div>
										<div className="col-lg-12 ">
										 	<label>(Linehaul Dim 200, First & Final Dim 250)</label>
										</div>
									</div>
									<hr className="p-0 m-0" />
									{/* <div className="row p-2">
										<div className="col-lg-12 ">
											&nbsp;
										</div>
									</div>
									<hr className="p-0 m-0"/> */}
									<div className="row p-2">
										<div className="col-lg-12">
											<h5>Shipper Notes: &nbsp;
												<span className="dispThin">
													{shipperNotes}
												</span>
											</h5>
										</div>
									</div>
									<div className="row p-2">
										<div className="col-lg-12">
											<h5>Consignee Notes: &nbsp;
												<span className="dispThin">
													{consigneeNotes}
												</span>
											</h5>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-lg-8">
									{(_isLinehaul || _isRoadRunner) ? '' :
										<div className="row mb-2">
											<div className="col-lg-12 divBrdr p-2 ">
												<p className="text-justify ">The Goods Described Have been received in Apparent Good Order and All Services Were Performed except as follows:</p>
												<h5>TND</h5>
												{/* <br/>
									<br/> */}
											</div>
										</div>}
									<div className="row mb-2">
										<div className="col-lg-6 pl-0">
											<div className="divBrdr p-2 minH260">
												<p className="text-center"><strong>Valuation Statement</strong></p>
												<p className="text-justify transformNone">Notice: Unless a higher value is declared, the shipper hereby releases the property to a value of $.50 per pound, subject to a $50 minimum. See the transportation contract and service conditions at https://wwww.freightforce.com/ for limitations and declared surcharges.</p>
												<hr className="m-1" />
												<div className="text-center">
													<label>shipper</label>
												</div>
											</div>
										</div>
										<div className="col-lg-6 p-0">
											<div className="divBrdr minH260">
												<div className="row px-2">
													<div className="col-md-12">
														<label>Received By:</label>
														<table style={{ "width": "100%" }}>
															<tbody>
																<tr>
																	<td style={{ "width": "10%" }} valign='top'>BY:</td>
																	{(_isLinehaul || _isRoadRunner) ? '' :
																		<td style={{ "width": "90%" }}>
																			Information Way Bill Only <br />Shipment Not Yet Tendered
																		</td>
																	}
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												<hr className="mb-1" />
												<div className="row px-2">
													<div className="col-md-12 mb-4">
														<table style={{ "width": "100%" }}>
															<tbody>
																<tr>
																	<td style={{ "width": "10%" }}>AT:</td>
																	<td style={{ "width": "50%" }}>
																		<div className="form-check">
																			<input className="form-check-input" type="checkbox" value="" id="rec1" />
																			<label className="form-check-label" htmlFor="rec1">
																				{(_isLinehaul || _isRoadRunner) ? "Shipper Terminal Location" : "Shipper's Address"}
																			</label>
																		</div>
																	</td>
																	{/* <td>
															<div className="form-check">
															  <input className="form-check-input" type="checkbox" value="" id="rec2" />
															  <label className="form-check-label" htmlFor="rec2">
																Hazmat
															  </label>
															</div>
															</td>
														</tr>
														<tr>
														<td></td>
														<td style={{"width":"50%"}}>
															<div className="form-check">
															  <input className="form-check-input" type="checkbox" value="" id="rec3" />
															  <label className="form-check-label" htmlFor="rec3">
																Forward Air Terminal
															  </label>
															</div>
														</td> */}
																	<td>
																		<div className="form-check">
																			<input className="form-check-input" type="checkbox" value="" id="rec4" />
																			<label className="form-check-label" htmlFor="rec4">
																				{(_isLinehaul || _isRoadRunner) ? "Consignee Terminal Location" : "Inbond"}
																			</label>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>

												</div>
												<hr className="mb-1" />
												<div className="row px-2">
													<div className="col-md-6 brdrRight">
														<label>Date</label>
														{/* <h5>15.10.2022</h5> */}
													</div>
													<div className="col-md-6">
														<label>Time</label>
														<h5>&nbsp;</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 pr-0 ">
									<div className="divBrdr minH330">
										<div className="col-md-12 text-center">
											&nbsp;
											<br />
											<br />
											<br />
											<br />
											<br />
										</div>
										<hr className="mb-1" />
										<div className="col-md-12 text-center">
											<label>Printed Last Name</label>
											<br />
											<br />
										</div>
										<hr className="mb-1" />
										<div className="col-md-12 text-center">
											<label>Consignee</label>
											<br />
											<br />
										</div>
										<hr className="mb-1" />
										<div className="row px-2">
											<div className="col-md-6 brdrRight">
												<label>Date</label>
												{/* <h5>15.10.2022</h5> */}
											</div>
											<div className="col-md-6">
												<label>Time</label>
												<h5>&nbsp;</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row divBrdr mb-2">
								{/*  <div className="col-lg-12 divBrdr text-center p-2 ">
                                 <h5 className="transformNone">Service Subject to Broker's Rate Circulars and Rules in Effect On Date of Shipment and Conditions 
                                 of Applicable Contract</h5>
                             </div> */}
							</div>
							<div className="row my-2">
								<div className="col-lg-3 p-0">
									<div ref={ref} id='fileBarcode'></div>
									{/* <Barcode value={genBarcode} height={38} width={2} displayValue={true} fontSize={14} margin={5} marginLeft={0}  /> */}
									{/* options={barcodeOptions}  */}
									{/*
									let barcodeOptions = {
										width: 2,
										height: 100,
										format: "CODE128",
										displayValue: false,
										fontOptions: "",
										font: "monospace",
										textAlign: "center",
										textPosition: "bottom",
										textMargin: 2,
										fontSize: 20,
										background: "#ffffff",
										lineColor: "#000000",
										margin: 10,
										marginTop: undefined,
										marginBottom: undefined,
										marginLeft: undefined,
										marginRight: undefined
									};
									*/}
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		//     </div>
		// </section>
	)
}

export default PrintBol