import { useEffect, useState } from 'react';
import { isNumberAndNotZero } from '../../../utils/ValidationScripts';

const DataDimTotals = () => {
  //console.log("DataDimTotals");
  
  const [initialInputs] = [{
    totalPcs: '',
    grossWeight: '',
    dimWeightTotal: '',
    accessorial: '',
    pickupAccessorials:'',
    deliveryAccessorials:'',
    shipperNotes: '',
    consigneeNotes: '',
    accSerType:'pickup',
    //hazardouesMaterial: 'false',
  }];
  const [initialRequired] = [{
    totalPcs: true,
    totalPcs_ErrorClass: 'form-control',
    grossWeight: true,
    grossWeight_ErrorClass: 'form-control',
    dimWeightTotal: true,
    dimWeightTotal_ErrorClass: 'form-control',
    // notes:true,
    // notes_ErrorClass: 'form-control',
    //accessorial: true,
    //accessorial_ErrorClass: 'text-danger d-none'
    pickupAccessorials:true,
    deliveryAccessorials:true,
    pickupAccessorials_ErrorClass: 'text-danger d-none',
    deliveryAccessorials_ErrorClass: 'text-danger d-none',

    
  }];

  const [quoteTotalDim, setQuoteTotalDim] = useState(initialInputs);
  const [totalDimRequired, setTotalDimRequired] = useState(initialRequired);

  const onValidateDimTotal = (name, value) => {
    var errState = {};
    var status = false;
    Object.entries(totalDimRequired).forEach(([key]) => {
      var errname = key + "_ErrorClass";
      if( errname==="accessorial_ErrorClass" ){
        if (key === name && value) {
          errState = { [name]: true, [errname]: 'text-danger d-none' };
          status = true;
        } else if (key === name && !value) {
          errState = { [name]: false, [errname]: 'text-danger' };
          status = true;
        }
      }else{
        if (key === name && value) {
          errState = { [name]: true, [errname]: 'form-control is-invalid' };
          status = true;
        } else if (key === name && !value) {
          errState = { [name]: false, [errname]: 'form-control is-valid' };
          status = true;
        }
      }
    })
    if (status)
      setTotalDimRequired({ ...totalDimRequired, ...errState });
    // console.log(totalDimRequired);
  }


  const onChangeDimTotal = (name, value) => {
    setQuoteTotalDim({ ...quoteTotalDim, [name]: value });
    //  console.log(name + ': setquoteTotalDim =' + value);
  }

  const updateDimTotals = async (totals) => {
    setQuoteTotalDim({ ...quoteTotalDim, ...totals });
    //  console.log(name + ': setquoteTotalDim =' + value);

    let errState = {};
    if (isNumberAndNotZero(totals.totalPcs)) {
      errState.totalPcs = false
      errState.totalPcs_ErrorClass = 'form-control is-valid'
    } else {
      errState.totalPcs = true
      errState.totalPcs_ErrorClass = 'form-control is-invalid'
    }

    if (isNumberAndNotZero(totals.grossWeight)) {
      errState.grossWeight = false
      errState.grossWeight_ErrorClass = 'form-control is-valid'
    } else {
      errState.grossWeight = true
      errState.grossWeight_ErrorClass = 'form-control is-invalid'
    }

    if (isNumberAndNotZero(totals.dimWeightTotal)) {
      errState.dimWeightTotal = false
      errState.dimWeightTotal_ErrorClass = 'form-control is-valid'
    } else {
      errState.dimWeightTotal = true
      errState.dimWeightTotal_ErrorClass = 'form-control is-invalid'
    }

    setTotalDimRequired({ ...totalDimRequired, ...errState });
    //console.log("Copied  Total "+JSON.stringify(totalDimRequired));
  }

  const onSubmitTotDimensions = () => {
    const copy = { ...totalDimRequired };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    // console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
      if (!key.includes("_ErrorClass") && value) {
        var errname = key + "_ErrorClass";
        var errState = null;
        if( errname==="pickupAccessorials_ErrorClass" || errname==="deliveryAccessorials_ErrorClass"){
            errState = { [key]: true, [errname]: 'text-danger' };
        }else{
          errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }
        status = true;
        Object.assign(errObject, errState);
        //  console.log("Total Error obj "+JSON.stringify(errObject));
      } else {

      }
    })
    if (status) {
      setTotalDimRequired({ ...totalDimRequired, ...errObject });
       console.log("Copied  Total "+JSON.stringify(totalDimRequired));
    }

    if (totalDimRequired.totalPcs || totalDimRequired.grossWeight || totalDimRequired.dimWeightTotal || totalDimRequired.pickupAccessorials || totalDimRequired.deliveryAccessorials ) {
      validationReqFlag = true;
    }

    return validationReqFlag;
  }

  // useEffect(() => {
  //   var accessorialLen = quoteTotalDim.accessorial.length;
  //   var errState = {}
  //   if (accessorialLen === 0) {
  //     errState.accessorial = true
  //     errState.accessorial_ErrorClass = 'text-danger d-none'
  //   } else {
  //     errState.accessorial = false
  //     errState.accessorial_ErrorClass = 'text-danger d-none'
  //   }

  //   setTotalDimRequired({ ...totalDimRequired, ...errState });
  // }, [quoteTotalDim.accessorial]);// eslint-disable-line

  useEffect(() => {
    var accessorialLen = quoteTotalDim.pickupAccessorials.length;
    var errState = {}
    if (accessorialLen === 0) {
      errState.pickupAccessorials = true
      errState.pickupAccessorials_ErrorClass = 'text-danger d-none'
    } else {
      errState.pickupAccessorials = false
      errState.pickupAccessorials_ErrorClass = 'text-danger d-none'
    }
    accessorialLen = quoteTotalDim.deliveryAccessorials.length;
    if (accessorialLen === 0) {
      errState.deliveryAccessorials = true
      errState.deliveryAccessorials_ErrorClass = 'text-danger d-none'
    } else {
      errState.deliveryAccessorials = false
      errState.deliveryAccessorials_ErrorClass = 'text-danger d-none'
    }

    setTotalDimRequired({ ...totalDimRequired, ...errState });
  }, [quoteTotalDim.pickupAccessorials, quoteTotalDim.deliveryAccessorials]);// eslint-disable-line

  // useEffect(() => {

  //     setDataFields(dataFieldValues);
  //     console.log("This is in use effect" + dataFields);
  // }, []);


  const updateTotalDimensionsState = async (data) => {
    //console.log("quoteTotalDim="+JSON.stringify(data));
    setQuoteTotalDim({ ...quoteTotalDim, ...data });
  }

  const updateTotalDimensionsReqState = async (data) => {
    //console.log("quoteShippingReq="+JSON.stringify(data));
    setTotalDimRequired({ ...totalDimRequired, ...data });
  }

  const clearDimTotals = () => {
    setQuoteTotalDim(initialInputs);
    setTotalDimRequired(initialRequired);
  }

  return {
    onChangeDimTotal,
    onValidateDimTotal,
    onSubmitTotDimensions,
    updateDimTotals,
    updateTotalDimensionsState,
    updateTotalDimensionsReqState,
    clearDimTotals,
    quoteTotalDim,
    totalDimRequired
  }




}
export default DataDimTotals;