import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import '../../../assets/css/form-validation.css';
import QuotePreFill from "./QuotePreFill";
import QuoteShipping from "./QuoteShipping";
import QuoteDimTotal from "./QuoteDimTotal";
import DataDimTotals from "./Data/DataDimTotals";
import DataAutoFill from "./Data/DataAutoFill";
import DataShipping from "./Data/DataShipping";
import DataTerminals from "./Data/DataTerminals";
import {  format } from 'date-fns';
import { validateUser, setQuickQuote } from '../../../store/actions/actionTypes';

import GridLoader from "react-spinners/GridLoader";

import { fffTermList, lhTermList, rrTermList, accessorialList, quickQuote, rrAccessorialList } from "../restAPI/QuickQuoteRest";
import DataProfile from "./Data/DataProfile";
const FreightQuote = (props) => {
  const { onChangePreFill, onValidatePreFill,  updatePreFillState, updatePreFillReqState, clearPreFill
    , quotePreFill, preFillRequired, typeOfMoveList , serviceTypeList, lineHaulServiceTypeList,packageTypeList } = DataAutoFill();
    const { onChangeShip, onValidateShip, toggleTerminals, onSubmitShipDetails, updateQuoteShippingState, updateQuoteShippingReqState
    , setLineHualTermDetailsList, setRoadRunnerTermDetailsList, setfffTermDetailsList, setAccessorialDetailsList, clearShipping
    , quoteShipping, shippingRequired,  accessorialDetails  } = DataShipping();
  const { onChangeDimTotal, onValidateDimTotal, onSubmitTotDimensions
    , updateDimTotals,  updateTotalDimensionsState, updateTotalDimensionsReqState, clearDimTotals
    , quoteTotalDim, totalDimRequired } = DataDimTotals();

  const { setLineHualTermOptsList, setfffTermOptsList, setAccessorialOptsList,setRoadRunnerTermOptsList,
    roadRunnerTermOpts,lineHualTermOpts, fffTermOpts, accessorialOpts } = DataTerminals();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navState = useLocation(); //passed value from Navigator
  
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);


   let backStatus = false;
  const [errorStatus, setErrorStatus] = useState({ restError: false, restErrorMessage: '' });
  //console.log("Navigator --"+JSON.stringify(navState))
  if (navState.state){
    backStatus = navState.state.backStatus;
  }
  //passed value from Navigator
  // console.log("Environment Loadded is "+process.env.REACT_APP_ENV);
  // console.log(process.env.REACT_APP_API_URL);
  let userProfileData = useSelector((state) => state.usr)
  const dimensionList = useSelector((state) => state.dim)
  const quickQuoteRes = useSelector((state) => state.qqt);
  let [loading, setLoading] = useState(true);
  const [clearAllDims, setClearAllDims] = useState("false");
  const [showAddDimMsg, setShowAddDimMsg] = useState(false);

  //useEffect(() => {
  //  let mounted = true;
  //  if (mounted) {
   //   dispatch(validateUser(userProfile));
  //  }
  //  return () => mounted = false;
  //}, [userProfile]) // eslint-disable-line

  const clearQuoteForm = () => {
    clearPreFill();
    clearShipping();
    clearDimTotals();
    setClearAllDims("true");
    setShowAddDimMsg(false);

    setTimeout(() => {
      setClearAllDims("false");
    }, 1000);
     
  }

  useEffect(() => {
    let mounted = true;
    //console.log(mounted+"came to use effect of accessoriallist "+quoteShipping.shipFrom+",,,,"+quoteShipping.terminalFrom);
    let zipCode ='';
    let paramType = '';
    if (mounted) {
      //&& quotePreFill.lineHaul != "NH" removed from below
      if(quoteShipping.shipFrom && quoteShipping.shipFrom.length>=3 ){
          if(quoteShipping.terminalFrom.length >= 3){
          zipCode = quoteShipping.terminalFrom;
          paramType = "T";
          }else{
          zipCode = quoteShipping.shipFrom;
          paramType = "Z";
          }
      accessorialList(zipCode,paramType)
        .then(accList => {
            //console.log("accessorialList "+JSON.stringify(accList))
            localStorage.setItem("accessorialList", JSON.stringify(accList));
            let selectacc = [];
            if (quotePreFill.lineHaul === "NH"){
              if(accList !== "null"){
                accList?.forEach((eachrow) => {
                  if((eachrow.code).trim() !=='GROUND CARRIER DROP (PU FF)'){
                   selectacc.push(eachrow);
                  }
                })
              }
              //console.log("Selected-- accfor NH",selectacc)
              setAccessorialDetailsList(selectacc);
            setAccessorialOptsList(selectacc);
            }else{
             // console.log("Selected-- ",accList)
            setAccessorialDetailsList(accList);
            setAccessorialOptsList(accList);
            }
            if(accList.length === 1){
              setErrorStatus({ restError: true, restErrorMessage: "Invalid Postal Code ! " });
            }else{
              setErrorStatus({ restError: false, restErrorMessage: "" });
            }
            setTimeout(() => {
              setErrorStatus({ restError: false, restErrorMessage: "" });
            }, 9000);
        })
      }
    //   else{
    //     if(quotePreFill.lineHaul === "NH"){
    //       const rrAccessList = localStorage.getItem("rrAccessorialList");
    //       if (rrAccessList && rrAccessList !== 'undefined') {
    //         setRRAccessorialDetailsList(JSON.parse(rrAccessList));
    //         setAccessorialOptsList(JSON.parse(rrAccessList));
    //       }
    //   }
    // }
    
    /*
    if (quickQuoteRes?.addValue && count1==0 ) {
      setTimeout(()=>{
        setCount1(count1+1);
      },200);
    }else{
      let req = true, val = '';
      if(quoteShipping.accessorial && quoteShipping.accessorial.length>0){
        req = false;
        val = quoteShipping.accessorial;
      }
      updateTotalDimensionsReqState({...totalDimRequired, ...{accessorial: req}});
      updateTotalDimensionsState({...quoteTotalDim, ...{accessorial: val}});
    }
    */
      return () => mounted = false;
    }
  }, [quoteShipping.shipFrom,quoteShipping.terminalFrom])// eslint-disable-line

  // console.log("clralldims",clearAllDims)

  const validateAdditionalEmails=()=>{
    var errState = {};
    var status=false;
    var addName='additionalEmails_ErrorClass'
   // if(quotePreFill.lineHaul === "NH" ||  quotePreFill.lineHaul === "lineHaul"){
      if(quotePreFill.additionalEmails){
       errState = { [addName]: 'form-control is-valid' };
      }else{
        errState = { [addName]: 'form-control is-invalid' };
        status=true;
      }
    updatePreFillReqState(errState);
   // }
    return status;
  }
  

  const submitValidation = () => {

    //onSubmitAutoFill();
    let isValidationReqShipDetails = onSubmitShipDetails();
    let isValidationReqDimensions = onSubmitTotDimensions();
    let additionalEmails = validateAdditionalEmails();
    if (!isValidationReqShipDetails && !isValidationReqDimensions && !additionalEmails) {
      setShowAddDimMsg(false);
      setLoading(true);
      quickQuote(quoteTotalDim, quotePreFill, quoteShipping, dimensionList, userProfileData
        , preFillRequired, shippingRequired, totalDimRequired, accessorialDetails)
        .then(quoteReqRes => {
          if (quoteReqRes instanceof Error) {
            //console.log("erro objecct is " + JSON.stringify(quoteReqRes))
             //console.log("erro objecct is " + JSON.stringify(quoteReqRes?.response))
             //console.log("erro objecct is " + JSON.stringify(quoteReqRes?.response?.data))
            setErrorStatus({ restError: true, restErrorMessage: quoteReqRes?.response?.data });
            setTimeout(()=>{
              setErrorStatus({ restError: false, restErrorMessage: '' });
            },30000);
          } else {
            setErrorStatus({ restError: false, restErrorMessage: '' });
            dispatch(setQuickQuote(quoteReqRes));
            navigate("/freightQuote/quoteProposed", { state: { quickQuote: quoteReqRes } });
          }
          setLoading(false);
        })
    }else if(isValidationReqDimensions){
      setShowAddDimMsg(true);
    }
  }

  useEffect(() => {
    const aircodeList = localStorage.getItem("airportCodeList");
    const lhTerm = localStorage.getItem("lhTermList");
    const fffTerm = localStorage.getItem("fffTermList");
    const rrTerm = localStorage.getItem("rrTermList");
    //const rrAccessList = localStorage.getItem("rrAccessorialList");
    
    // console.log("linehual",JSON.stringify(lineHualTermOpts))
    // console.log("ffftremila",JSON.stringify(fffTermOpts))
    // console.log("accessorial",JSON.stringify(accessorialOpts))
    //rrAccessList === null || rrAccessList ==="[]" // removed from below 
    if(lhTerm === null || fffTerm === null || rrTerm === null|| aircodeList === null || 
      lhTerm === "[]" || fffTerm === "[]" || rrTerm === "[]" ||aircodeList === "[]"  ){
        if (fffTerm === null || fffTerm === "[]") {
          fffTermList()
            .then(fffList => {
              //console.log("fffTerm in contianer"+JSON.stringify(fffList))
              localStorage.setItem("fffTermList", JSON.stringify(fffList));
              setfffTermDetailsList(fffList);
              setfffTermOptsList(fffList);
            }).catch(err => {
              localStorage.setItem("fffTermList", "[]");
            })
        }
        if (lhTerm === null || lhTerm === "[]") {
          lhTermList()
            .then(lhTList => {
              //console.log("linehaul in contianer"+JSON.stringify(lhTList))
              localStorage.setItem("lhTermList", JSON.stringify(lhTList));
              setLineHualTermDetailsList(lhTList);
              setLineHualTermOptsList(lhTList);
            }).catch(err => {
              localStorage.setItem("lhTermList", "[]");
            })
        }
        if (rrTerm === null || rrTerm === "[]") {
          rrTermList()
            .then(rrList => {
              console.log("Road runner in welcome termlist"+JSON.stringify(rrList))
              localStorage.setItem("rrTermList", JSON.stringify(rrList));
              setRoadRunnerTermDetailsList(rrList);
              setRoadRunnerTermOptsList(rrList);
            }).catch(err => {
              localStorage.setItem("rrTermList", "[]");
            })
        }
        // const rrAccessList = localStorage.getItem("rrAccessorialList");
        // if (rrAccessList === null || rrAccessList === "[]") {
        //   rrAccessorialList()
        //     .then(rrAccList => {
        //       //console.log("response of rrAccessorialList welcomepage"+JSON.stringify(rrAccList))
        //       localStorage.setItem("rrAccessorialList", JSON.stringify(rrAccList));
        //       setRRAccessorialDetailsList(rrAccList);
        //     }).catch(err => {
        //       localStorage.setItem("rrAccessorialList", "[]");
        //     })
        // }
        setLoading(false);
    }else{
        setLoading(false);
    }

    const fffTermLists = localStorage.getItem("fffTermList");
    if (fffTermLists !==null && fffTermLists !== "[]") {
      setfffTermDetailsList(JSON.parse(fffTermLists));
      setfffTermOptsList(JSON.parse(fffTermLists));
    }

    const accessorialLists = localStorage.getItem("accessorialList");
        if (accessorialLists !==null && accessorialLists !== "[]") {
        setAccessorialDetailsList(JSON.parse(accessorialLists));
        setAccessorialOptsList(JSON.parse(accessorialLists));
      }
      const lhTermLists = localStorage.getItem("lhTermList");
      if (lhTermLists !==null && lhTermLists !== "[]") {
        setLineHualTermDetailsList(JSON.parse(lhTermLists));
        setLineHualTermOptsList(JSON.parse(lhTermLists));
      }

      const rrTermLists = localStorage.getItem("rrTermList");
      if (rrTermLists !==null && rrTermLists !== "[]") {
        setRoadRunnerTermDetailsList(JSON.parse(rrTermLists));
        setRoadRunnerTermOptsList(JSON.parse(rrTermLists));
      }
   }, [])// eslint-disable-line


  useEffect(() => {
    // To show the Trermial on ship from and ship To
    //console.log("use effect is called lineHaul is =",quotePreFill.lineHaul);
    //console.log("quickQuoteRes",quickQuoteRes)
    //console.log("addValue",quickQuoteRes.addValue)
    let termTo = '0', termFrom = '0';
    let shipFrom = null, shipTo = null, shipFromDetails = {}, shipToDetails = {};
    let shipCodeFromEntry = true, shipCodeToEntry = true;
    if (quickQuoteRes?.addValue && count==0 ) {
      termTo = quickQuoteRes?.addValue?.terminalTo;
      termFrom = quickQuoteRes?.addValue?.terminalFrom;
      shipFrom = quickQuoteRes?.addValue?.quoteShipping?.shipFrom;
      shipTo = quickQuoteRes?.addValue?.quoteShipping?.shipTo;
      shipFromDetails = quickQuoteRes?.addValue?.quoteShipping?.shipFromDetails;
      shipToDetails = quickQuoteRes?.addValue?.quoteShipping?.shipToDetails;
      shipCodeFromEntry = quickQuoteRes?.addValue?.quoteShipping?.shipCodeFromEntry;
      shipCodeToEntry = quickQuoteRes?.addValue?.quoteShipping?.shipCodeToEntry;
      setTimeout(()=>{
        setCount(count+1);
      },200);
      toggleTerminals(quotePreFill.lineHaul, quotePreFill.lineHaulServiceType, termFrom, termTo, shipFrom, shipTo, shipFromDetails, shipToDetails, shipCodeFromEntry, shipCodeToEntry, true);

    }else{
      toggleTerminals(quotePreFill.lineHaul, quotePreFill.lineHaulServiceType, termFrom, termTo, shipFrom, shipTo, shipFromDetails, shipToDetails, shipCodeFromEntry, shipCodeToEntry, false);
      if(quoteShipping.shipFrom==="" || quoteShipping.shipTo===""){
        setAccessorialDetailsList([]);
        setAccessorialOptsList([]);
      }
    }
    // if(quotePreFill.lineHaul === "NH"){
    //   const rrAccessList = localStorage.getItem("rrAccessorialList");
    //   if (rrAccessList && rrAccessList !== 'undefined') {
    //     setRRAccessorialDetailsList(JSON.parse(rrAccessList));
    //     setAccessorialOptsList(JSON.parse(rrAccessList));
    //   }
      
    // }
    

  }, [quotePreFill.lineHaul, quotePreFill.lineHaulServiceType])// eslint-disable-line

  

  useEffect(() => {
    let mounted = true;

    if (backStatus && mounted) {
      setCount(0);
      setCount1(0);


      navigate(".", { replace: true });
      // console.log("quickQuoteRes="+JSON.stringify(quickQuoteRes))
      let _dimWeightTotal = '', _hazardouesMaterial = '', _fffTo = false, _fffFrom = false, _shipCodeFromEntry = false, _shipCodeToEntry = false;
      let _specialDelivery = '', _terminalFrom = '', _terminalFromShow = false, _terminalTo = '', _terminalToShow = false, _disableTerminalFromSel = false, _disableTerminalToSel = false;
      let _preFillRequired = [], _shippingRequired = [], _totalDimRequired = [], _shipFromDetails = {}, _shipToDetails = {};
      let _accSerType='';
      

      if (quickQuoteRes?.addValue) {
        let _qp = {
          typeOfMove: quickQuoteRes?.addValue?.typeOfMove,
          typeOfMoveName: quickQuoteRes?.addValue?.typeOfMoveName,
          serviceType: quickQuoteRes?.addValue?.serviceType,
          serviceTypeName: quickQuoteRes?.addValue?.serviceTypeName,
          lineHaul: quickQuoteRes?.addValue?.lineHaul,
          lineHaulServiceType: quickQuoteRes.addValue.lineHaulServiceType,
          lineHaulServiceTypeName: quickQuoteRes.addValue.lineHaulServiceTypeName,
          lineHaulServiceDisable: quickQuoteRes.addValue.lineHaulServiceDisable
        }
        updatePreFillState(_qp);

        _dimWeightTotal = quickQuoteRes?.addValue?.dimWeightTotal;
        //_hazardouesMaterial = quickQuoteRes?.addValue?.hazardouesMaterial;
        _specialDelivery = quickQuoteRes?.addValue?.specialDelivery;
        _terminalFrom = quickQuoteRes?.addValue?.terminalFrom;
        _terminalFromShow = quickQuoteRes?.addValue?.terminalFromShow;
        _disableTerminalFromSel = quickQuoteRes?.addValue?.disableTerminalFromSel;
        _terminalTo = quickQuoteRes?.addValue?.terminalTo;
        _terminalToShow = quickQuoteRes?.addValue?.terminalToShow;
        _disableTerminalToSel = quickQuoteRes?.addValue?.disableTerminalToSel;
        _fffTo = quickQuoteRes?.addValue?.fffTo;
        _fffFrom = quickQuoteRes?.addValue?.fffFrom;
        _preFillRequired = quickQuoteRes?.addValue?.preFillRequired;
        _shippingRequired = quickQuoteRes?.addValue?.shippingRequired;
        _totalDimRequired = quickQuoteRes?.addValue?.totalDimRequired;
        _shipCodeFromEntry = quickQuoteRes?.addValue?.shipCodeFromEntry;
        _shipCodeToEntry = quickQuoteRes?.addValue?.shipCodeToEntry;
        _shipFromDetails = quickQuoteRes?.addValue?.shipFromDetails;
        _shipToDetails = quickQuoteRes?.addValue?.shipToDetails;
        _accSerType = quickQuoteRes?.addValue?.accSerType;
        updatePreFillReqState(_preFillRequired);
        updateQuoteShippingReqState(_shippingRequired);
        updateTotalDimensionsReqState(_totalDimRequired)
      }
      if(quickQuoteRes.addValue?.quotePreFill)
      updatePreFillState(quickQuoteRes.addValue.quotePreFill)
      //if(quickQuoteRes.addValue.quoteShipping)
      //updateQuoteShippingState(quickQuoteRes.addValue.quoteShipping)
      
      if (quickQuoteRes?.shipper) {
        let _sp = {
          fffTo: _fffTo,
          fffFrom: _fffFrom,
          shipFrom: quickQuoteRes?.shipper?.postalCode,
          shipFromDetails: _shipFromDetails,
          shipTo: quickQuoteRes?.consignee?.postalCode,
          shipToDetails: _shipToDetails,
          pickupInfo: quickQuoteRes?.shipment?.pickupDate ? (quickQuoteRes?.shipment?.pickupDate).includes("1970")? null : new Date(quickQuoteRes?.shipment?.pickupDate) : null,
          deliveryInfo: quickQuoteRes?.shipment?.deliveryDate? (quickQuoteRes?.shipment?.deliveryDate).includes("1970")? null : new Date(quickQuoteRes?.shipment?.deliveryDate) : null,
          specialPickup: quickQuoteRes?.shipment?.isSpecial,
          specialDelivery: _specialDelivery,
          terminalFrom: _terminalFrom,
          terminalFromShow: _terminalFromShow,
          disableTerminalFromSel: _disableTerminalFromSel,
          terminalTo: _terminalTo,
          terminalToShow: _terminalToShow,
          disableTerminalToSel: _disableTerminalToSel,
          shipCodeFromEntry: _shipCodeFromEntry,
          shipCodeToEntry: _shipCodeToEntry,
        }
        _sp = Object.assign(_sp,quickQuoteRes.addValue.quoteShipping);
        updateQuoteShippingState(_sp);
//        console.log("shipping--->",JSON.stringify(_sp));
      }
      
     // console.log("accessorials--->",JSON.stringify(quickQuoteRes.shipment.accessorials));
      if (quickQuoteRes.shipment) {
        let accessorialList = [];
        if (quickQuoteRes.shipment.accessorials) {
          quickQuoteRes.shipment.accessorials.forEach( (eachobj => { 
                                      if (eachobj.description !== '') 
                                       accessorialList.push({ "value": eachobj.description, "label": eachobj.description } 
                                      )}) );
        }
        let pickupAccessorialList = [];
        if (quickQuoteRes.shipment.pickupAccessorials) {
          quickQuoteRes.shipment.pickupAccessorials.forEach( (eachobj => { 
                                      if (eachobj.description !== '') 
                                      pickupAccessorialList.push({ "value": eachobj.description, "label": eachobj.description } 
                                      )}) );
        }
        let deliveryAccessorialList = [];
        if (quickQuoteRes.shipment.deliveryAccessorials) {
          quickQuoteRes.shipment.deliveryAccessorials.forEach( (eachobj => { 
                                      if (eachobj.description !== '') 
                                      deliveryAccessorialList.push({ "value": eachobj.description, "label": eachobj.description } 
                                      )}) );
        }
        
        let _sp = {
          totalPcs: quickQuoteRes.shipment.qty,
          grossWeight: quickQuoteRes.shipment.weight,
          dimWeightTotal: _dimWeightTotal,
          //quickQuoteRes.addValue.dimWeightTotal || '',
          accessorial: accessorialList,
          pickupAccessorials : pickupAccessorialList,
          deliveryAccessorials : deliveryAccessorialList,
          shipperNotes: quickQuoteRes.shipment.shipperNotes,
          consigneeNotes: quickQuoteRes.shipment.consigneeNotes,
          //hazardouesMaterial: _hazardouesMaterial,
          accSerType : _accSerType
          //quickQuoteRes.addValue.hazardouesMaterial,
        }
        //console.log("jsonrsp",JSON.stringify(quickQuoteRes))
        updateTotalDimensionsState(_sp);
      }
    }
    return () => mounted = false;
  }, []);// eslint-disable-line

  const standardQuoteTutorial =()=>{
      window.open(process.env.REACT_APP_STAND_QUOTE_TUTORIAL,"_blank");
  }
  const linehaulQuoteTutorial =()=>{
    window.open(process.env.REACT_APP_LINE_QUOTE_TUTORIAL,"_blank");
}
  const getTerminalFromOptions =()=>{
    if(quotePreFill.lineHaul === "lineHaul")
    return lineHualTermOpts;
    else if(quotePreFill.lineHaul === "localpu")
    return  fffTermOpts;
    else if(quotePreFill.lineHaul === "NH")
    return roadRunnerTermOpts;
  }
  const getTerminalToOptions =()=>{
    if(quotePreFill.lineHaul === "lineHaul")
    return lineHualTermOpts;
    else if(quotePreFill.lineHaul === "localpu")
    return  fffTermOpts;
    else if(quotePreFill.lineHaul === "NH")
    return roadRunnerTermOpts;

  }

  const [lhServiceTypes, setLhServiceTypes] = useState([]);

  useEffect(() => {
    let lhList = [];
    if( quotePreFill.lineHaul == "NH" ){
        lhList = lineHaulServiceTypeList.filter(obj => obj.value !=4 );
    }else{
        lhList = lineHaulServiceTypeList;
    }
    setLhServiceTypes(lhList);
  }, [quotePreFill.lineHaul])
  

//clearing any values if at all present when comes here after successfully booking quote.
// not clearing form on back button
  useEffect(() => {
    if(backStatus){

    }else{
      // console.log("clearing form...");
      clearQuoteForm();
    }
   
  }, [])
  
  return (
    <div className={loading ? 'container parentDisable' : ''}>
      {loading && <GridLoader color={'#1E73BE'} loading={loading} size={35} className={'loadSpin'}/> }
      <div className="row">
        <div className="col-md-12 order-md-1">
          <div className="card card-custom">
          <div className="d-flex justify-content-between mb-4">
              <div>
              <h4 className="mb-2 pageHead text-uppercase">Quote and Book Online</h4>
              </div>
              <div>
              
              <button type="button"  className="btn btn-outline-primary btn-sm" onClick={standardQuoteTutorial}>Standard Quote Tutorial <i className="fa-solid fa-book"></i></button>
              &emsp; <button type="button"  className="btn btn-outline-primary btn-sm" onClick={linehaulQuoteTutorial}>Linehaul Quote tutorial <i className="fa-solid fa-book"></i></button>
              </div>
            </div>
            {/* <h5 className="mb-3 grayHead text-uppercase">Corporate</h5> */}
            {errorStatus.restError ? <div className="alert alert-danger" role="alert">{errorStatus.restErrorMessage}  </div> : null}
            {/* <div style={{ marginTop: 20 }}>count1={JSON.stringify(count1)}</div>  */}
            <form className="needs-validation" name="quick" autoComplete="false" >
              <QuotePreFill onChangePreFill={onChangePreFill} onValidatePreFill={onValidatePreFill}
                quotePreFill={quotePreFill} preFillRequired={preFillRequired}
                typeOfMoveList={typeOfMoveList} serviceTypeList={serviceTypeList}
                // lineHaulServiceTypeList={lineHaulServiceTypeList} 
                lhServiceTypes={lhServiceTypes} userProfile={userProfileData} />
              <QuoteShipping onChangeShip={onChangeShip} onValidateShip={onValidateShip}
                quoteShipping={quoteShipping} shippingRequired={shippingRequired} terminalFromOptions={getTerminalFromOptions()} terminalToOptions={getTerminalToOptions()} />
              <QuoteDimTotal onChangeDimTotal={onChangeDimTotal} onValidateDimTotal={onValidateDimTotal} submitValidation={submitValidation}
                quoteTotalDim={quoteTotalDim}  quoteShipping={quoteShipping} backStatus={backStatus} updateDimTotals={updateDimTotals} totalDimRequired={totalDimRequired} optionsData={accessorialOpts}
                clearQuoteForm={clearQuoteForm} clearAllDims={clearAllDims} showAddDimMsg={showAddDimMsg} isRoadRunner={quotePreFill.roadRunner} packageTypeList={packageTypeList}
                lineHaulSelVal={quotePreFill.lineHaul} />
            </form>
            <div className="text-center QuoteInfo mb-2 mt-3">
          Charges are estimated based on information given to construct this quote.
Actual charges may be greater with physical verification of shipment dimensions and weight upon tender. Fuel surcharge updated weekly. 
            </div>
            <div className="text-center QuoteHead mb-2 ">
               (Use Freight Force Facility as an origin or destination for Airline or Ground service provider recoveries or drops)
            </div>
          </div>
        </div>
        

        {/* Call the function to add an import */}
        {/* <ImportScript resourceUrl="assets/js/form-validations.js"></ImportScript> */}
      </div>
    </div>
  );
  }



export default FreightQuote;