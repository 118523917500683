import { useEffect, useState } from 'react'

const DataAccessorialNew = () => {
  
    const  [initData] = [{
        description : '',
        chargeType : '',
        requireAuthorization : '',
        rate: '',
        minimum: '',
        maximum: '',
        perLbRate1: '',
        perLbRate2: '',
        baseAmount: '',
        deInterval: '',
        calcDeInterval: '',
    }];
    const [initDataReq] = [{
        description_ErrorClass : 'text-danger d-none',
        chargeType_ErrorClass : 'text-danger d-none',
        requireAuthorization_ErrorClass : 'custom-control-input',
        rate_ErrorClass: 'form-control',
        minimum_ErrorClass: 'form-control',
        maximum_ErrorClass: 'form-control',
        perLbRate1_ErrorClass: 'form-control',
        perLbRate2_ErrorClass: 'form-control',
        baseAmount_ErrorClass: 'form-control',
        deInterval_ErrorClass: 'form-control',
        calcDeInterval_ErrorClass: 'form-control',
        description : true,
        chargeType : true,
        requireAuthorization : true,
        rate: true,
        minimum: true,
        maximum: true,
        perLbRate1: true,
        perLbRate2: true,
        baseAmount: true,
        deInterval: true,
        calcDeInterval: true
         
    }];

    const [formData, setFormData] = useState(initData);

    const [formDataReq, setFormDataReq] = useState(initDataReq);

    const [showSaveDataLoading, setSaveShowDataLoading] = useState(false);

    const onSubmitNewAccessorial = () => {
        const copy = {...formDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
       // console.log("copy",copy);
        Object.entries(copy).forEach(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                
                var errname=key+"_ErrorClass";
                //var _css = "form-control";
                var errState = errState;
               // console.log(key,value,errname)
                if( errname==="description_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'text-danger' };
                }else if( errname==="chargeType_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'text-danger' };
                }else if( errname==="requireAuthorization_ErrorClass" ){
                    errState = { [key]: true, [errname]: 'custom-control-input is-invalid' };
                }else{
                    errState = { [key]: true, [errname]: 'form-control is-invalid' };
                }
                status = true;
                Object.assign(errObject,errState);
            }
        })
        if(status){
            setFormDataReq({...formDataReq,...errObject});
        } 

        let reqObj = { ...formDataReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        validationReqFlag = Object.values(reqObj).includes(true);
        return validationReqFlag;
            
    }

    const onValidateNewAccessorial = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if( errname==="requireAuthorization_ErrorClass" ){
            _css = 'custom-control-input';
        }
        if( errname==="description_ErrorClass" || errname==="chargeType_ErrorClass" ){
            if (value) {
              errState = { [name]: true, [errname]: 'text-danger' };
              status = true;
            } else if (!value) {
              errState = { [name]: false, [errname]: 'text-danger d-none' };
              status = true;
            }
          }else{
            if(value) {
                errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
            }else if(!value) {
                errState = {[name]: false,[errname]: _css+''};
                status=true;
            }
        }
        if(status)
            setFormDataReq({...formDataReq, ...errState});
      }

    const handleInputChange = (name, value) => {
        setFormData({...formData, [name]: value});
    }

    const clearNewAccessorialForm = () => {
        setFormData(initData);
        setFormDataReq(initDataReq);
    }

    const setFormDataObj = (_obj) => {
       // console.log("fd",formData)
        let res_obj = { ...formData, ..._obj}
        setFormData(res_obj);
       // console.log("res",res_obj)
    }

    return {
        formData,
        formDataReq,
        handleInputChange,
        onSubmitNewAccessorial,
        onValidateNewAccessorial,
        clearNewAccessorialForm,
        setFormDataObj
    }
}

export default DataAccessorialNew;