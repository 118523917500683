
import { useState } from 'react';
const DataProfile = () => {
const [userProfile,setUserProfile] = useState({
    "token": '',
        "userName": '',
        "custCode": '',
        "companyName": '',
        "address1": '',
        "address2": '',
        "city": '',
        "state": '',
        "postalCode": '',
        "country": '',
        "phone": '',
        "email": '',
        "contact": '',
        "isValid": false,
        "username": '',
        "userid": '',
        "password": '',
        "firstName": '',
        "lastName": '',
        "emailName": '',
        "permissionsLevel":0,
        "menuPermissions": [
        ],
        "msg": ''
    
})


const updateUserProfile = (name, value) => {
    var updateValues = { [name]: value };
    setUserProfile({ ...userProfile, ...updateValues });
}
const updateUserProfileObject = (valuesSet) => {
    //console.log("received values"+JSON.stringify(valuesSet))
    setUserProfile({
        ...userProfile,
        ...valuesSet
    });
    //console.log("Updated values"+JSON.stringify(userProfile))
}

return {
    userProfile,
    updateUserProfile,
    updateUserProfileObject
}
}
export default DataProfile;
