import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {trackShipmentList,trackingByDate} from '../../restAPI/TrackingAPI'
const DataFilters = () => {
    const  [filtersInput, setFiltersInput] = useState({
        trackingType : '',
        trackingInput : '',
        trackingText : '',
        showTable: false,
        fromDate:'',
        toDate:'',
    });

    const [filtersReq,setFiltersReq] = useState({
        trackingType:    true,
        trackingType_ErrorClass : 'custom-control-input',
        trackingInput:   true,
        trackingInput_ErrorClass : 'form-control',
        toDate_ErrorClass : 'form-control',
        fromDate_ErrorClass : 'form-control',
        trackingText_ErrorClass : 'form-control'
    });

    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);
    //user profile from redux

    let userProfileData = useSelector((state) => state.usr)
    //userprofile from local storage
    //const userProfileData = localStorage.getItem("userProfile");
    //console.log("userprofile from localstorage "+JSON.stringify(userProfileData))
    const onSubmitSearch = () => {
        
        const copy = {...filtersReq};
        var status = false;
        

        //console.log("Copy total "+JSON.stringify(copy));
        //Object.entries(copy).map(([key, value]) => {
            // if(!key.includes("_ErrorClass") && value  ){
            //     var errname=key+"_ErrorClass";
            //     var _css = (key==="trackingType" ? "custom-control-input" : "form-control");
            //     var errState = {[key]: true,[errname]: _css+' is-invalid'};
            //     status = true;
            //     Object.assign(errObject,errState);
            //    // console.log("Total Error obj "+JSON.stringify(errObject));
            // }
        //})
        let errState = {};
        var sdStat = false,edStat=false,inStat=false,txStat=false,typeStat=false;
        if( filtersInput.trackingType ){
            errState=Object.assign(errState,{"trackingType_ErrorClass" : ' custom-control-input'});
            typeStat = false;

            if(filtersInput.trackingType === "hawb" ){
                if(!filtersInput.trackingInput.trim()){
                    errState=Object.assign(errState,{"trackingInput_ErrorClass":' form-control is-invalid'});
                    inStat = true;
                }else{
                    errState=Object.assign(errState,{"trackingInput_ErrorClass":' form-control'});
                    inStat = false;
                }
            }
            if(filtersInput.trackingType === "date" ){
            
                if(!filtersInput.fromDate){
                    errState=Object.assign(errState,{"fromDate_ErrorClass":' form-control is-invalid'});
                    sdStat = true;
                }else{
                    errState=Object.assign(errState,{"fromDate_ErrorClass":' form-control'});
                    sdStat = false;
                }
                if(!filtersInput.toDate){
                    errState=Object.assign(errState,{"toDate_ErrorClass":' form-control is-invalid'});
                    edStat = true;
                }else{
                    errState=Object.assign(errState,{"toDate_ErrorClass":' form-control'});
                    edStat = false;
                }
            }
            if(filtersInput.trackingType === "mult" ){
                if(!filtersInput.trackingText.trim()){
                    errState=Object.assign(errState,{"trackingText_ErrorClass":' form-control is-invalid'});
                    txStat = true;
                }else{
                    errState=Object.assign(errState,{"trackingText_ErrorClass":' form-control'});
                    txStat = false;
                }
            }
        }else{
            errState=Object.assign(errState,{"trackingType_ErrorClass" : ' custom-control-input is-invalid'});
            typeStat = true;
        }
        if(typeStat || sdStat || edStat || inStat || txStat)
        status = true; 
        else
        status = false;
        
        //console.log(status+" Status "+JSON.stringify(errState))

        

        if(status){
            setFiltersReq({...filtersReq,...errState});
        }else{
            //console.log("userprofile from localstorage "+JSON.stringify(userProfileData))
            setFiltersInput({...filtersInput,showTable: false});
            setShowDataLoading(true);
            loadReportData();
        }
    }

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = (key==="trackingType" ? "custom-control-input" : "form-control");
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }

    const loadReportData = (loadType) => {
        // console.log(loadType+" loadtype")
        // console.log("up=",userProfileData)
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
            
        if( userProfileData && userProfileData.custCode ){
            if(loadType === "default"){
                trackShipmentList(userProfileData.custCode,userProfileData.userid).then(response => {
                    setReportData([...response]);
                    setShowDataLoading(false);
                    setFiltersInput({...filtersInput, showTable: true});
                }).catch(err => {
                    setShowDataLoading(false);
                    setReportData([]);
                })
            } 
            else{
                trackingByDate(userProfileData.custCode,filtersInput.trackingType,filtersInput.fromDate,filtersInput.toDate,
                    filtersInput.trackingInput,filtersInput.trackingText, userProfileData.userid)
                    .then(response => {
                        if (response instanceof Error) {
                            console.log("error objecct is trackingByDate " + JSON.stringify(response))
                            //setErrorStatus({ restError: true, restErrorMessage: response.message });
                            setReportData([]);
                              } else {
                            setReportData([...response]);
                            
                            }                           
                            setShowDataLoading(false);
                            setFiltersInput({...filtersInput, showTable: true});
                })  
                .catch(err => {
                    setShowDataLoading(false);
                    setReportData([]);
                    setFiltersInput({...filtersInput, showTable: true});
                })
            }
        }else{
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
        }
        // console.log("in finally"+JSON.stringify(userProfileData));
    }

    const handleInputChange = (name, value) => {
       // console.log("selected valus name"+name+"--"+value);
        setFiltersInput({...filtersInput, [name]: value});
    }

    useEffect(() => {
        
        setShowDataLoading(true);
        loadReportData("default");
    }, [])
    

    return {
        handleInputChange,
        onSubmitSearch,
        onValidateFilters,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading
    }
}

export default DataFilters