import React,{useEffect}  from "react";
import turckjpg from '../../../assets/img/truck.jpg'
const RegionalLineHaul = () => {
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    return (
		<section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">REGIONAL LINEHAUL</h4>
                        </div>
						<div className="row">
							<div className="col-lg-5 col-md-12 ">
							<img src = {turckjpg} className="img-fluid mb-4" alt="Regional Linehaul" />
							</div>
							<div className="col-lg-7 col-md-12 text-justify">
							<p>We can arrange linehaul service from small shipments to full truckloads at competitive service levels and rates to and from many of our locations throughout the country.  Additionally, our network of approved motor carriers offers pick-up and delivery at origin and destination to complement our linehaul service.  Please refer to the Freight Force linehaul schedule for booking information and locations.</p>
							
							<p> <strong><a href="mailto:linehaul@freightforce.com">linehaul@freightforce.com</a> or click the linehaul schedule/rate link below.</strong></p>

							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default RegionalLineHaul;