import React from "react";
import InputElement from "../../formControls/InputElement";
import SelectElement from "../../formControls/SelectElement";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
const DirectBook1 = (props) => {

    const ffffrom = () =>{
        return props.quickBook.fffFrom ?  <div className="col-md-3 custom-control custom-checkbox">
                    <InputElement type="checkbox" className="custom-control-input" id="fffFrom" name="fffFrom"
                        checked={props.quickBook.fffFrom}
                    // onChange={props.onChangeBookHandler} 
                    />
                    <label className="custom-control-label" htmlFor="fffFrom">Freight Force Facility</label>
                </div>
                :
                <div className="col-md-3"></div>
    }
    
    const fffTo = () => {
        return props.quickBook.fffTo ?   <div className="col-md-3 custom-control custom-checkbox">
                    <InputElement type="checkbox" className="custom-control-input" id="fffTo" name="fffTo"
                        checked={props.quickBook.fffTo}
                    // onChange={props.onChangeBookHandler}
                    />
                    <label className="custom-control-label" htmlFor="fffTo">Freight Force Facility</label>
                </div>
                :
                <div className="col-md-3"></div>
    }

    return (
        <div>
            {/* {JSON.stringify(props.quickBook)} */}
                    <div className="row">
                        <div className="col-md-6">
                            <label></label>
                            {/* <div className="custom-control custom-checkbox mt-4 pt-2">
                                <InputElement type="checkbox" className="custom-control-input" id="lineHaul" name="lineHaul" 
                                checked={props.quickBook.lineHaul} onChange={props.onChangeBookHandler} />
                                <label className="custom-control-label" htmlFor="lineHaul">Line Haul</label>
                            </div> */}
                            <div>
                            {/* <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="lineHaulLpud" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangeBookHandler} value="localpu" onInvalid={props.onValidatePreFill}
                                    checked={props.quickBook.localpud} 
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulLpud">Local PU & D</label>
                            </div> */}
                            <div className="custom-control custom-radio custom-control-inline">
                                <InputElement type="radio" id="lineHaulLh" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangeBookHandler} value="lineHaul" onInvalid={props.onValidatePreFill}
                                    checked={props.quickBook.lineHaulLh}
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulLh">I5-Corridor</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline pt-2">
                                <InputElement type="radio" id="lineHaulrr" name="lineHaul"  className="custom-control-input"
                                    onChange={props.onChangeBookHandler} value='NH' onInvalid={props.onValidatePreFill}
                                    checked={props.quickBook.roadRunner}
                                    // validation="requiredRadio" className={filtersReq.invoiceType_ErrorClass} 
                                    />
                                <label className="custom-control-label" htmlFor="lineHaulrr">Nationwide Linehaul</label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label>Linehaul Service Type</label>
                            <SelectElement id="lineHaulServiceType" name="lineHaulServiceType" value={props.quickBook.lineHaulServiceType || ''} 
                                optionsData={props.lhServiceTypes}
                                //optionsData={props.lineHaulServiceTypeList} disabled={props.quickBook.lineHaulServiceDisable}
                                 onChange={props.onChangeBookHandler}
                            />
                        </div>
                        <div className="col-md-3">
                        <label>Operations Email <span className="inv-feedback">(Must enter an email)</span></label>
                                <InputElement id="additionalEmails" name="additionalEmails" onChange={props.onChangeBookHandler} className={props.quickBookRequired.additionalEmails_ErrorClass} 
                                value={props.quickBook.additionalEmails} maxLength="50" placeholder="Operations Email" 
                                validation="alpha-numeric" />
                        <div className="invalid-feedback">
                            required.
                        </div>
                        </div>
                        
                    </div>
                    <div className="row">
                <div className="col-md-3 mb-3">
                    <label htmlFor="fffFrom" className="d-block">Origin Freight Force Facility</label>
                    {(!props.quickBook.terminalFromShow)
                    ?
                    <button className="btn btn-light active btn-disabled" type="button">  No  </button>
                    :
                    <>
                    { props.quickBook.disableTerminalFromSel
                        ?
                        <button className="btn btn-primary btn-disabled" type="button">  YES  </button>
                        :
                        <BootstrapSwitchButton
                            id="fffFrom" name="fffFrom"
                            checked={props.quickBook.fffFrom} disabled={false}
                            onlabel='Yes'
                            offlabel='No'
                            onChange={(checked) => {
                                props.onChangeBookHandler(['fffFrom'],checked)
                            }}
                            onstyle='primary primary-text' 
                            offstyle='dark-gray'
                            width={115} 
                            height={38} 
                        />
                    }
                    </>
                    }
                    {/* disableTerminalFromSel={props.quickBook.disableTerminalFromSel+''} */}
                </div>
                <div className="col-md-3 mb-3" >
                    {/* {props.quickBook.terminalFromShow ? terminalFromToggle() : null} */}
                    <label htmlFor="TerminalFrom">Origin Locations</label>
                    <SelectElement id="terminalFrom" name="terminalFrom" value={props.quickBook.terminalFrom || ''} 
                        optionsData={props.terminalFromOptions} onChange={props.onChangeBookHandler}
                        // disabled={!props.quickBook.terminalFromShow} />
                        placeholder={<div className='colorRed'>Select a Location ...</div>}
                        disabled={props.quickBook.fffFrom===false}
                        />
                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="fffTo" className="d-block">Destination Freight Force Facility</label>
                    {!props.quickBook.terminalToShow
                    ?
                    <button className="btn btn-light active btn-disabled" type="button">  No  </button>
                    :
                    <>
                    { props.quickBook.disableTerminalToSel
                        ?
                        <button className="btn btn-primary btn-disabled" type="button">  YES  </button>
                        :
                        <BootstrapSwitchButton
                            id="fffTo" name="fffTo"
                            checked={props.quickBook.fffTo}
                            disabled={false}
                            onlabel='  Yes  '
                            offlabel='  No  '
                            onChange={(checked) => {
                                props.onChangeBookHandler(['fffTo'],checked)
                            }}
                            onstyle='primary primary-text' 
                            offstyle='dark-gray'
                            width={115}
                            height={38} 
                        />
                    }   
                    </>
                    }
                </div>
                <div className="col-md-3 mb-3">
                    <label htmlFor="TerminalTo">Destination Locations</label>
                    <SelectElement id="terminalTo" name="terminalTo" value={props.quickBook.terminalTo || ''} 
                        optionsData={props.terminalToOptions} onChange={props.onChangeBookHandler}
                        // disabled={!props.quickBook.terminalToShow}  />
                        placeholder={<div className='colorRed'>Select a Location ...</div>}
                        disabled={props.quickBook.fffTo===false}
                        />

                    <div className="invalid-feedback">
                        required.
                    </div>
                </div>
            </div>
            
            
            
        </div>
    );
}

export default DirectBook1;