import React,{useEffect} from 'react'

const LoginRequired = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                        {/* text-uppercase */}
                            <h4 className="pageHead ">You are logged out. Please log in to use this feature.</h4>
                        </div>
							
					</div>
                    </div>
                </div>
            </div>
    </section>
  )
}

export default LoginRequired;