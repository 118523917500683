export const buildTariffCharges = (tariffData) => {
    let tariffHtml = '';
    if( tariffData && tariffData.length>0 ){
        tariffData.map(x => {
            let tdData1 = x['areato'] || '';
            let tdData2 = x['RATEMIN'] || '';
            let tdData3 = x['RATE100'] || '';
            let tdData4 = x['RATE500'] || '';
            let tdData5 = x['RATE1000'] || '';
            let tdData6 = x['RATE2000'] || '';
            let tdData7 = x['RATE3000'] || '';
            let tdData8 = x['RATE5000'] || '';
            let tdData9 = x['RATE10000'] || '';
            let tdData10 = x['RATE15000'] || '';
            let tdData11 = x['RATE20000'] || '';
            let tdData12 = x['RATECAP'] || '';

            tariffHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                            "<td>"+tdData4+"</td>"+
                            "<td>"+tdData5+"</td>"+
                            "<td>"+tdData6+"</td>"+
                            "<td>"+tdData7+"</td>"+
                            "<td>"+tdData8+"</td>"+
                            "<td>"+tdData9+"</td>"+
                            "<td>"+tdData10+"</td>"+
                            "<td>"+tdData11+"</td>"+
                            "<td>"+tdData12+"</td>"+
                            "</tr>";

        });
    }else{
        tariffHtml += "<tr>"+
                      "<td colspan='12'>No Data available.</td>"+
                      "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr class="text-primary"> '+
        ' <td colspan=12>Tariffs -- Release Rate</td> '+
        ' </tr> '+
        ' <tr> '+
        '   <td> Area </td> '+
        '   <td> Min </td> '+
        '   <td> 100 </td> '+
        '   <td> 500 </td> '+
        '   <td> 1000 </td> '+
        '   <td> 2000 </td> '+
        '   <td> 3000 </td> '+
        '   <td> 5000 </td> '+
        '   <td> 10000 </td> '+
        '   <td> 15000 </td> '+
        '   <td> 20000 </td> '+
        '   <td> Cap Rate </td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+tariffHtml+' '+
        ' </tbody> '+
        ' </table> '
    )
}
 
export const buildSpecialCharges = (splData) => {
    let splHtml = '';
    if( splData && splData.length>0 ){
        splData.map(x => {
            let tdData1 = x['AREATO'] || '';
            let tdData2 = x['MON-FRI 0000-0759'] || '';
            let tdData3 = x['MON-FRI 0800-1700'] || '';
            let tdData4 = x['MON-FRI 1701-2359'] || '';
            let tdData5 = x['SATURDAY'] || '';
            let tdData6 = x['SUNDAY'] || '';
            let tdData7 = x['HOLIDAY'] || '';

            splHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                            "<td>"+tdData4+"</td>"+
                            "<td>"+tdData5+"</td>"+
                            "<td>"+tdData6+"</td>"+
                            "<td>"+tdData7+"</td>"+
                        "</tr>";

        });
    }else{
        splHtml += "<tr>"+
                      "<td colspan='7'>No Data available.</td>"+
                      "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr class="text-primary"> '+
        ' <td colspan=7>Special Charges -- Release Rate</td> '+
        ' </tr> '+
        ' <tr> '+
        ' <td>Area</td> '+
        ' <td>MON-FRI <br/> 0000-0759</td> '+
        ' <td>MON-FRI <br/> 0800-1700</td> '+
        ' <td>MON-FRI <br/> 1701-2359</td> '+
        ' <td>SATURDAY</td> '+
        ' <td>SUNDAY</td> '+
        ' <td>HOLIDAY</td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+splHtml+' '+
        ' </tbody> '+
        ' </table> '
    )
}

export const buildAccessorialCharges = (acData) => {
    let acHtml = '';
    if( acData && acData.length>0 ){
        acData.map(x => {
            let tdData1 = x['Service_Description'] || '';
            let tdData2 = null!=x['Min'] ? x['Min'] : '';
            let tdData3 = null!=x['Rate'] ? x['Rate'] : '';
            let tdData4 = x['surtype'] || '';
            acHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                            "<td>"+tdData4+"</td>"+
                        "</tr>";

        });
    }else{
        acHtml += "<tr>"+
                      "<td colspan='4'>No Data available.</td>"+
                      "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr class="text-primary"> '+
        ' <td colspan=4>Accessorial Charges -- Release Rate</td> '+
        ' </tr> '+
        ' <tr> '+
        ' <td>Service Description</td> '+
        ' <td>Min</td> '+
        ' <td>Rate</td> '+
        ' <td>Calculated By</td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+acHtml+' '+
        ' </tbody> '+
        ' </table> '
    )
}

export const buildAirportTransferCharges = (atData) => {
    let atHtml = '';
    if( atData && atData.length>0 ){
        atData.map(x => {
            let tdData1 = x['MINISHIP'] || '';
            let tdData2 = x['MINAMOUNT'] || '';
            let tdData3 = null!=x['CAPAMOUNT'] ? x['CAPAMOUNT'] : '';
            atHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                        "</tr>";

        });
    }else{
        atHtml += "<tr>"+
                      "<td colspan='3'>No Data available.</td>"+
                      "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr class="text-primary"> '+
        ' <td colspan=3>Airport Transfer</td> '+
        ' </tr> '+
        ' <tr> '+
        ' <td>Minimum</td> '+
        ' <td>Amount per 100 lbs</td> '+
        ' <td>Cap</td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+atHtml+' '+
        ' </tbody> '+
        ' </table> '
    )
}

export const buildLocations = (lData) => {
    let lHtml = '';
    if( lData && lData.length>0 ){
        lData.map(x => {
            let tdData1 = x['city'] || '';
            let tdData2 = x['state'] || '';
            let tdData3 = x['zip'] || '';
            let tdData4 = x['zone'] || '';
            lHtml += "<tr>"+
                            "<td>"+tdData1+"</td>"+
                            "<td>"+tdData2+"</td>"+
                            "<td>"+tdData3+"</td>"+
                            "<td>"+tdData4+"</td>"+
                        "</tr>";

        });
    }else{
        lHtml += "<tr>"+
                    "<td colspan='4'>No Data available.</td>"+
                 "</tr>";
    }

    return (
        ' <table class="table table-bordered text-center" style="border-width:2px;border-color:#686868;"> '+
        ' <thead class="font-weight-bold"> '+
        ' <tr class="text-primary"> '+
        ' <td colspan=4>Locations</td> '+
        ' </tr> '+
        ' <tr> '+
        ' <td>City</td> '+
        ' <td>State</td> '+
        ' <td>Zip</td> '+
        ' <td>Zone</td> '+
        ' </tr> '+
        ' </thead> '+
        ' <tbody> '+
        ' '+lHtml+' '+
        ' </tbody> '+
        ' </table> '
    )
}