import React,{useEffect} from "react";
import { GridLoader } from "react-spinners";
import DataTariff from "./Data/DataTariff";
import TariffRateReportData from "./TariffRateReportData";
import TariffRateReport from "./TariffRateReport";

const TariffRate = () => {

    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
    const { onChangeTariff, onSubmitSearch, onValidateTariff, filtersInput, filtersReq, reportData, showDataLoading, airportCodesList } = DataTariff();

    return (
        <div className="row">
            <div className="col-md-12 order-md-1">
                <div className="card card-custom">
                    <div className="d-flex">
                        <h4 className="mb-2 pageHead text-uppercase">Tariff Rate</h4>
                    </div>

                    <TariffRateReport filtersInput={filtersInput} filtersReq={filtersReq} validation="required"
                        onChangeTariff={onChangeTariff} onSubmitSearch={onSubmitSearch} onValidateTariff={onValidateTariff}
                        airportCodesList={airportCodesList}  />
						
                    <hr className="mb-3"/>
                    {
                    showDataLoading && 
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center"> 
                                <GridLoader  color={'#1E73BE'} loading={showDataLoading} />
                            </div>
                        </div>
                    }
                    {
                        filtersInput.showTable &&  
                        <TariffRateReportData reportData={reportData}  />	
                    }
                </div>
            </div>
        </div>
    );
}

export default TariffRate;