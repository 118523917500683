import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { HashLoader } from 'react-spinners';
import InputElementMsg from "../../../../formControls/InputElementMsg";
import DataTariffEdit from '../Data/DataTariffEdit';
import { useEffect } from 'react';
const TariffEditModal = (props) => {

    const { formEditData, formEditDataReq, handleEditInputChange,  onSubmitEditTariff, onValidateEditTariff, clearEditTariffForm, setFormEditDataObj } = DataTariffEdit();

    useEffect(() => {
        clearEditTariffForm();
        setFormEditDataObj(props.editData);
        //setFormEditDataObj(prevData => ([...prevData, ...props.editData])); // if use state is not effected use this (trail and error)
      },[props.editData])
      
      const handleEdit = () => {
        //  console.log("fd1",formEditData);
         var isValidationReqEditTariff = onSubmitEditTariff();
        //  console.log("new acc Record edit:"+JSON.stringify(formEditData))
             
         if (!isValidationReqEditTariff) {
            props.updateRecord(formEditData);
         }
      }

      const closeEdit = () => {
        clearEditTariffForm();
        props.handleClose();
      }

    return (
        <>
            <Modal show={props.show} onHide={closeEdit}
                size="lg" // for large modal box
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"} // not to close popup when  mouse clicked outside of popup
                keyboard={false} // not to close popup on escape key press
            >
                {props.innerLoading &&
                    <div className="modalLoadingOverlay">
                        <div className="modalLoadingSpinner">
                            <HashLoader color={'#1E73BE'} loading={props.innerLoading} size={35} />
                        </div>
                    </div>
                }
                <Modal.Header closeButton>
                    <Modal.Title>Edit tariff rates for {props?.editData?.area} zone</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div>{JSON.stringify(props.formData)}</div>   */}
                    <Form id="tariffEdit">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Current Min : &emsp;&emsp;&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.min}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 100 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs100}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 500 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs500}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-2">
                                <label>New Min :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newmin" name="newmin" className={formEditDataReq.newmin_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Min' />
                                {/* <div className="invalid-feedback">
                                    required.
                                </div> */}
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 100 : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs100" name="newlbs100" className={formEditDataReq.newlbs100_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 100' />
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 500 : </label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs500" name="newlbs500" className={formEditDataReq.newlbs500_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 500' />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 ">
                                <label>Current Lbs 1000 : &emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs1000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 2000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs2000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Lbs 3000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs3000}</strong></label>
                            </div>
                        </div>
                        <div className="row mb-3" >
                            <div className="col-md-2">
                                <label>New Lbs 1000 :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs1000" name="newlbs1000" className={formEditDataReq.newlbs1000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 1000' />
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 2000 :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs2000" name="newlbs2000" className={formEditDataReq.newlbs2000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 2000' />
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 3000 </label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs3000" name="newlbs3000" className={formEditDataReq.newlbs3000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 3000' />
                            </div>
                            
                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                    <label>Current Lbs 5000 :&emsp; <strong className="embossed">{props?.editData?.currentTariffs?.lbs5000}</strong></label>
                                </div>
                                <div className="col-md-4 ">
                                    <label>Current Lbs 10000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs10000}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    <label>Current Lbs 15000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs15000}</strong></label>
                                </div>
                            </div>
                        <div className="row mb-3" >
                        <div className="col-md-2">
                                <label>New Lbs 5000 </label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs5000" name="newlbs5000" className={formEditDataReq.newlbs5000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 5000' />
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 10000 :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs10000" name="newlbs10000" className={formEditDataReq.newlbs10000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 10000' />
                            </div>
                            <div className="col-md-2">
                                <label>New Lbs 15000 :</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs15000" name="newlbs15000" className={formEditDataReq.newlbs15000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 15000' />
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4 ">
                                <label>Current Lbs 20000 :&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.lbs20000}</strong></label>
                            </div>
                            <div className="col-md-4">
                                <label>Current Cap Rate:&emsp;<strong className="embossed">{props?.editData?.currentTariffs?.capRate}</strong></label>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                        <div className="row mb-3" >
                        <div className="col-md-2">
                                <label>New Lbs 20000:</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newlbs20000" name="newlbs20000" className={formEditDataReq.newlbs20000_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Lbs 20000' />
                            </div>
                            <div className="col-md-2">
                                <label>New Cap Rate:</label>
                            </div>
                            <div className="col-md-2">
                                <InputElementMsg id="newcapRate" name="newcapRate" className={formEditDataReq.newcapRate_ErrorClass}
                                    onChange={handleEditInputChange}  validation="decimal" onInvalid={onValidateEditTariff}
                                    placeholder='New Cap Rate' />
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                        {/* <hr /> */}

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pr200px">
                        { props.resStatus && 
                        <h6 className={props.resStatus}>{props.resMessage} </h6>
                    }
                    </div>
                    <Button variant="danger" onClick={closeEdit}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TariffEditModal