import React, { useState,useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux'
import  {onRefershSetToken} from './store/actions/actionTypes'

import TopBar from './components/layout/TopBar';
import RequestNotFound from './components/layout/RequestNotFound';
import Header from './components/layout/Header';
import Login from './authentication/Login';
import Welcome from './authentication/Welcome';
import Maintenance from './components/staticPages/Maintenance';
import Logout from './authentication/Logout';
import Coverage from './components/services/coverage/Coverage';
import CarrierDirectory from './components/services/carrierDirectory/CarrierDirectory';
import TariffRate from './components/services/tariffRates/TariffRate';
import FreightTracking from './components/services/tracking/FreightTracking';
import Dimensions from './components/services/quickQuote/Dimensions'
import FreightQuote from './components/services/quickQuote/FreightQuote';
import FreightBooking from './components/services/booking/FreightBooking';
import FreightDirectBook from './components/services/directBook/FreightDirectBook';
import ConfirmDirectBook from './components/services/directBook/ConfirmDirectBook';
import BookConfirm from './components/services/booking/BookConfirm';
import QuoteProposed from './components/services/quickQuote/QuoteProposed';
import RegionalLineHaul from './components/services/linehaul/RegionalLineHaul';
import NationwideLineHaul from './components/services/linehaul/NationwideLineHaul';
import Tsa from './components/services/approvedMotor/Tsa';
import Veroot from './components/services/approvedMotor/Veroot';
import SignUp from './components/staticPages/SignUp';
import RecoverPass from './components/staticPages/RecoverPass';
import ChangePassword from './components/staticPages/ChangePassword';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/bootstrap-datetimepicker.css';
import './assets/css/bootstrap-datetimepicker.min.css';
import './assets/css/multiple-select.css';
import './assets/css/form-validation.css';
import './assets/css/styles.css';
import './App.css';
import CorporateLayout from './components/layout/CorporateLayout';
import CorporateCenter from './components/services/corporateCenter/CorporateCenter';
import LinkApprovedMotorCarriers from './components/services/corporateCenter/linkApprovedMotorCarriers/LinkApprovedMotorCarriers';
import CorporateDirectory from './components/services/corporateCenter/corporateDirectory/CorporateDirectory';
import Invoices from './components/services/corporateCenter/invoices/Invoices';
import SalesSop from './components/services/corporateCenter/salesSop/SalesSop';
import WebAccount from './components/services/corporateCenter/webAccount/WebAccount';
import SalesSopReport from './components/services/corporateCenter/salesSop/SalesSopReport';
import UserLookup from './components/services/corporateCenter/userLookup/UserLookup';
import LinkCustomerAccounts from './components/services/corporateCenter/linkCustomerAccounts/LinkCustomerAccounts';
import ApprovedMotorCarrierLayout from './components/layout/ApprovedMotorCarrierLayout';
import ApprovedMotorCarrier from './components/services/approvedMotor/ApprovedMotorCarrier';
import PreviewPDF from './components/utils/PreviewPDF';
import OurStory from './components/staticPages/aboutUs/OurStory';
import OurMission from './components/staticPages/aboutUs/OurMission';
import OurExecutives from './components/staticPages/aboutUs/OurExecutives';
import I5Corridor from './components/staticPages/services/I5Corridor';
import Cartage from './components/staticPages/services/Cartage';
import WhiteGlove from './components/staticPages/services/WhiteGlove';
import Residential from './components/staticPages/services/Residential';
import ContactUs from './components/staticPages/ContactUs';
import FuelSurcharge from './components/staticPages/FuelSurcharge';
import Forms from './components/staticPages/forms/Forms';
import LoginRequired from './components/staticPages/LoginRequired';

import History from './components/services/history/History';
import CargoClaimForm from './components/staticPages/forms/CargoClaimForm';
import Footer from './components/layout/Footer';
import NationalSales from './components/staticPages/NationalSales';
import NonLoginTrack from './components/services/tracking/NonLoginTrack';
import InvoiceDetails from './components/services/corporateCenter/invoices/InvoiceDetails';
import TariffUpdatePortal from './components/services/corporateCenter/tariffUpdatePortal/TariffUpdatePortal';
import TariffManagement from './components/services/corporateCenter/tariffManagement/TariffManagement';
import AreasServiced from './components/staticPages/services/areasServiced/AreasServiced';


import RetailDistribution from './components/staticPages/aboutUs/RetailDistribution';

import FuelSurchargeHistorical from './components/staticPages/FuelSurchargeHistorical';

import NewsFeed from './components/services/corporateCenter/newsFeed/NewsFeed';
import CarrierConfirmation from './components/services/quickQuote/CarrierConfirmation';
import SendEmail from './components/utils/SendEmail';
import GeneratePdf from './components/utils/GeneratePdf';
import PrintBol from './components/services/quickQuote/PrintBol';
import AppLogout from './AppLogout';
import EditCoverage from './components/services/corporateCenter/editCoverage/EditCoverage';
import {getCarrierInformation} from  './components/services/restAPI/UtilsRest';
import { logoutUser, removeUser,coverageData } from './store/actions/actionTypes';
// import { faUnderline } from '@fortawesome/fontawesome-free-solid';
import DataShipping from './components/services/quickQuote/Data/DataShipping';
import DataTerminals from './components/services/quickQuote/Data/DataTerminals';
import { fffTermList, lhTermList,rrAccessorialList,rrTermList } from "./components/services/restAPI/QuickQuoteRest";
import {tariffAirportCode} from "./components/services/restAPI/TariffRatesAPI";


//import DynamicAddTest from './components/services/testing/DynamicAddTest';
const App = () => {

  const userProfile = useSelector((state) => state.usr)

  const { onChangeShip, onValidateShip, toggleTerminals, onSubmitShipDetails, updateQuoteShippingState, updateQuoteShippingReqState
    , setLineHualTermDetailsList, setRoadRunnerTermDetailsList, setfffTermDetailsList, setAccessorialDetailsList, setRRAccessorialDetailsList,  clearShipping
    , quoteShipping, shippingRequired,  accessorialDetails,rrAccessorialDetails } = DataShipping();
    const { setLineHualTermOptsList, setfffTermOptsList, setAccessorialOptsList,setRoadRunnerTermOptsList,
      roadRunnerTermOpts,lineHualTermOpts, fffTermOpts, accessorialOpts } = DataTerminals();

  const dispatch = useDispatch();

  let sectionClassname = "mt-180 mb-4";
  let divClassname = "container";
  if( !userProfile.isValid ) { // nonlogin pages
    sectionClassname = '';
    divClassname = '';
  }

  useEffect( () =>{
    //console.log("onrefreshsettoken")
    dispatch(onRefershSetToken());
    getData();
  },[])// eslint-disable-line

const getData = () => {
    getCarrierInformation().then(reponseDate => {
        if (reponseDate instanceof Error) {
            console.log("Error in loading getCarrierInformation")
        } else {
           // console.log("data getCarrierInformation"+JSON.stringify(reponseDate))
           dispatch(coverageData(reponseDate));
        }
    })
    .catch(err => {
        //setData(err);
        console.log("Error in loading getCarrierInformation")
    })
    }  
    useEffect(() => {
    const aircodeList = localStorage.getItem("airportCodeList");
    
    //console.log("aircodeList "+aircodeList+" lhTerm"+lhTerm+"  fffTerm"+fffTerm)
    if (aircodeList === null || aircodeList === "[]") {
      tariffAirportCode().then(responseData => {
        let _reportData = [];
        if (responseData instanceof Error) {
          // console.log("error objecct is tariffRates " + JSON.stringify(responseData))
          //setErrorStatus({ restError: true, restErrorMessage: responseData.message });
        } else {
          responseData.forEach(function (item, index) {
            // console.log(item);
            _reportData.push({ "value": item.AirportCode, "label": item.AirportCode })
          });
          localStorage.setItem("airportCodeList", JSON.stringify(_reportData));
        }
        // console.log(JSON.stringify(responseData.tariffRate))
      }).catch(err => {
        localStorage.setItem("airportCodeList", "[]");
      })
      //return () => mounted = false;
    }
    const lhTerm = localStorage.getItem("lhTermList");
    
    if (lhTerm === null || lhTerm === "[]") {
      lhTermList()
        .then(lhTList => {
          //console.log("linehaul in contianer"+JSON.stringify(lhTList))
          localStorage.setItem("lhTermList", JSON.stringify(lhTList));
          setLineHualTermDetailsList(lhTList);
          setLineHualTermOptsList(lhTList);
        }).catch(err => {
          localStorage.setItem("lhTermList", "[]");
        })
    }
    const rrTerm = localStorage.getItem("rrTermList");
    
    if (rrTerm === null || rrTerm === "[]") {
      rrTermList()
        .then(rrList => {
        //  console.log("Road runner in app termlist"+JSON.stringify(rrList))
          localStorage.setItem("rrTermList", JSON.stringify(rrList));
          setRoadRunnerTermDetailsList(rrList);
          setRoadRunnerTermOptsList(rrList);
        }).catch(err => {
          localStorage.setItem("rrTermList", "[]");
        })
    }
    const fffTerm = localStorage.getItem("fffTermList");
    if (fffTerm === null || fffTerm === "[]") {
      fffTermList()
        .then(fffList => {
          //console.log("fffTerm in contianer"+JSON.stringify(fffList))
          localStorage.setItem("fffTermList", JSON.stringify(fffList));
          setfffTermDetailsList(fffList);
          setfffTermOptsList(fffList);
        }).catch(err => {
          localStorage.setItem("fffTermList", "[]");
        })
    }
    // const rrAccessList = localStorage.getItem("rrAccessorialList");
    // if (rrAccessList === null || rrAccessList === "[]") {
    //   rrAccessorialList()
    //     .then(rrAccList => {
    //       //console.log("response of rrAccessorialList appjs"+JSON.stringify(rrAccList))
    //       localStorage.setItem("rrAccessorialList", JSON.stringify(rrAccList));
    //       setRRAccessorialDetailsList(rrAccList);
    //     }).catch(err => {
    //       localStorage.setItem("rrAccessorialList", "[]");
    //     })
    // }
  }, [])// eslint-disable-line




  // const checkForInactivity = () =>{
  //   console.log("checkForInactivity")
  //   const expireTime = localStorage.getItem('expireTime');
  //   if(expireTime < Date.now()){
  //   console.log('came to logout true');
  //   logout();
  //   window.location.href = 'https://www.freightforce.com/'; 
  // }
  // }
  
  // const logout = () =>{
  //   dispatch(removeUser());
  //   dispatch(logoutUser())
  // }
  //   const updateExpireTime = () =>{
  //     console.log('came updateExpireTime');
  //   const expireTime = Date.now() + 600000; //10 mins 5min== 300000
  //   localStorage.setItem("expireTime",expireTime);
  //   }
    
  //   useEffect(()=>{
  //     console.log('came addeventlisters');
  //       updateExpireTime();
  //       window.addEventListener('click',updateExpireTime);
  //       window.addEventListener('keypress',updateExpireTime);
  //       window.addEventListener('scroll',updateExpireTime);
  //       window.addEventListener('mousemove',updateExpireTime);
  //      window.addEventListener("beforeunload", logout);
  //       return()=>{
  //         window.removeEventListener('click',updateExpireTime);
  //         window.removeEventListener('keypress',updateExpireTime);
  //         window.removeEventListener('scroll',updateExpireTime);
  //         window.removeEventListener('mousemove',updateExpireTime);
  //        window.removeEventListener("beforeunload", logout);
  //       }   
      
  //   },[])
    
    // useEffect(()=>{

    //   console.log('came timer for every 10 seconds');
    // const  interval = setInterval(()=>{
    //  checkForInactivity();
    // },10000)
    // return () =>clearInterval(interval);
  
    // },[])

  return (
    <div>
      <BrowserRouter>
        <TopBar />
        {/* <header> */}
          <Header />
        {/* </header> */}
         
        <section className={sectionClassname}>
          <div className={divClassname}>
          <AppLogout> 
            <Routes>          
            { userProfile.isValid ?    <>
                <Route path='/freightQuote' element={<FreightQuote />}></Route>
                <Route path='/freightQuote/freightBooking' element={<FreightBooking />}></Route>
                <Route path='/freightQuote/quoteProposed' element={<QuoteProposed />}></Route>
                <Route path='/freightQuote/bookConfirm' element={<BookConfirm />}></Route>
                <Route path='/freightDirectBook' element={<FreightDirectBook />}></Route>
                <Route path='/confirmDirectBook' element={<ConfirmDirectBook />}></Route>
                <Route path='/history' element={<History />}></Route>
                <Route path='/freightTracking' element={<FreightTracking />}></Route>
                <Route path='/dimensions' element={<Dimensions />}></Route>
                <Route path='/changePassword' element={<ChangePassword />}></Route>
                </>
              :''} 
              { userProfile && userProfile.menuPermissions && userProfile.menuPermissions.includes("Web Account Admin") ?
              <Route path='/webAccount' element={<WebAccount />}></Route>
              :''}
              
              <Route path='/coverage' element={<Coverage />}></Route>
              <Route path='/carrierDirectory' element={<CarrierDirectory />}></Route>
              <Route path='/tariffRate' element={<TariffRate />}></Route>
              <Route path='/logout' element={<Logout />}></Route>
              <Route path='/requestNotFound' element={<RequestNotFound />}></Route>
              <Route path='/loginRequired' element={<LoginRequired />}></Route>
              <Route path='/loginRequired' element={<LoginRequired />}></Route>
              

              {/* <Route path='/approvedMotorMenu' element={<ApprovedMotorMenu />}></Route> */}
              <Route path='/tsa' element={<Tsa />}></Route>
              <Route path='/regionalLineHaul' element={<RegionalLineHaul />}></Route>
              <Route path='/veroot' element={<Veroot />}></Route>
              <Route path='/aboutUs/ourStory' element={<OurStory />}></Route>
              <Route path='/aboutUs/ourMission' element={<OurMission />}></Route>
              <Route path='/aboutUs/ourExecutives' element={<OurExecutives />}></Route>
              <Route path='/aboutUs/retailDistribution' element={<RetailDistribution />}></Route>
              <Route path='/services/cartage' element={<Cartage />}></Route>
              <Route path='/services/whiteGlove' element={<WhiteGlove />}></Route>
              <Route path='/services/residential' element={<Residential />}></Route>
              <Route path='/services/regionalLineHaul' element={<RegionalLineHaul />}></Route>
              <Route path='/services/nationwideLineHaul' element={<NationwideLineHaul />}></Route>
              <Route path='/services/i5Corridor' element={<I5Corridor />}></Route>
              <Route path='/contactUs' element={<ContactUs />}></Route>
              <Route path='/fuelSurcharge' element={<FuelSurcharge />}></Route>
              <Route path='/historicalFsc' element={<FuelSurchargeHistorical />}></Route>
              <Route path='/forms' element={<Forms />}></Route>
              <Route path='/forms/cargoClaim' element={<CargoClaimForm />}></Route>
              <Route path='/nationalSales' element={<NationalSales />}></Route>
              <Route path='/nonLoginTrack' element={<NonLoginTrack />}></Route>
              <Route path='/areasServiced' element={<AreasServiced />}></Route>
              <Route path='/signUp' element={<SignUp />}></Route>
              <Route path='/recoverPass' element={<RecoverPass />}></Route>
              <Route path='/carrierConfirmation' element={<CarrierConfirmation />}></Route>
              <Route path='/testEmail' element={<SendEmail />}></Route>
              <Route path='/testPdf' element={<GeneratePdf />}></Route>
              <Route path='/printBol' element={<PrintBol />}></Route>
              <Route path='/maintenance' element={<Maintenance />}></Route>



{/* If User is logged in, redirecting to welcome page otherwise login page */}
              <Route path="/login" element={ userProfile.isValid ? (  <Navigate to="/welcome" /> ) : ( <Login/> ) }/>
              <Route path='/' element={ userProfile.isValid ? ( <Navigate to="/welcome" /> ) : ( <Login/> ) } />
              <Route path='/welcome' element={ userProfile.isValid ? ( <Welcome /> ) : ( <Login/> ) }></Route>

{/* If none of the above paths match, redirected to requestNotFound  */}
              {/* <Route path="*" element={<Navigate to="/requestNotFound" />} /> */}

              {/* <Route path="/testdatatable" element={<TestDatatable />}></Route>
              <Route path="/tm" element={<TestMap />}></Route> */}


              
              {userProfile && userProfile.menuPermissions && userProfile.menuPermissions.includes("Corporate")?
              <>
              <Route path='/corporate/tariffUpdatePortal' element={<TariffUpdatePortal />}></Route>
              <Route path='/corporate/tariffManagement' element={<TariffManagement />}></Route>
              <React.Fragment>
              <Route element={<CorporateLayout/>}>
                <Route path='/corporate' element={<CorporateCenter />}></Route>
                <Route path='/corporate/linkCustomerAc' element={<LinkCustomerAccounts />}></Route>
                <Route path='/corporate/linkAppMotorCarriers' element={<LinkApprovedMotorCarriers />}></Route>
                <Route path='/corporate/corporateDirectory' element={<CorporateDirectory />}></Route>
                <Route path='/corporate/invoices' element={<Invoices />}></Route>
                <Route path='/corporate/invoices/details' element={<InvoiceDetails />}></Route>
                <Route path='/corporate/salesSop' element={<SalesSop />}></Route>
                <Route path='/corporate/userLookup' element={<UserLookup />}></Route>
                <Route path='/corporate/webAccount' element={<WebAccount />}></Route>
                <Route path='/corporate/newsFeed' element={<NewsFeed />}></Route>
                <Route path='/corporate/editCoverage' element={<EditCoverage />}></Route>
              </Route>
              </React.Fragment>
              </>:''}
              {userProfile && userProfile.menuPermissions && userProfile.menuPermissions.includes("Approved Motor Carrier")?
              <React.Fragment>
              <Route element={<ApprovedMotorCarrierLayout/>}>
                <Route path='/amc' element={<ApprovedMotorCarrier />}></Route>
                <Route path='/amc/corporateDirectory' element={<CorporateDirectory />}></Route>
                <Route path='/amc/coverage' element={<Coverage />}></Route>
                <Route path='/amc/salesSopReport' element={<SalesSopReport />}></Route>
                <Route path='/amc/tsa' element={<PreviewPDF fileKey='tsa' />}></Route>
                <Route path='/amc/emailCrown' element={<PreviewPDF fileKey='emailCrown' />}></Route>
                <Route path='/amc/quickGuide' element={<PreviewPDF fileKey='quickGuide' />}></Route>
              </Route>
              </React.Fragment>
              :''}
              {/*  Implemented material-react table  */}
            

            </Routes>
            </AppLogout> 
          </div>
        </section>
        
        { !userProfile.isValid && <Footer /> }

      </BrowserRouter>

    </div>
  );
};

export default App;