import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AccessorialCharges from './TariffTabs/AccessorialCharges';
import TariffCharges from './TariffTabs/TariffCharges';
import SpecialCharges from './TariffTabs/SpecialCharges';
import AirportTransfer from './TariffTabs/AirportTransfer';
import Locations from './TariffTabs/Locations';
import moment from 'moment/moment';
import { extractStyles } from '../../utils/ValidationScripts';
import { useState } from 'react';
import { ffLogoB64Data } from '../../utils/ImageB64';

const TariffRateReportData = (props) => {

	// console.log("Json vlaue " + JSON.stringify(props));
	let _expDate = props.reportData && props.reportData.expirationDate.expireDate ? 
					moment(new Date(props.reportData.expirationDate.expireDate)).format('MM/DD/YYYY') : '';

	const [tabsHtml1, setTabsHtml1] = useState([]);
	const [tabsHtml2, setTabsHtml2] = useState([]);
	const [tabsHtml3, setTabsHtml3] = useState([]);
	const [tabsHtml4, setTabsHtml4] = useState([]);
	const [tabsHtml5, setTabsHtml5] = useState([]);

	

	const handlePrintAll = () => {
		let printableElements = document.getElementById('tariffReportDiv1').innerHTML;
		printableElements += tabsHtml1;
		printableElements += tabsHtml2;
		printableElements += tabsHtml3;
		printableElements += tabsHtml4;
		printableElements += tabsHtml5;
		printableElements += document.getElementById('tariffReportDiv2').innerHTML;
		//const orderHtml = "<html><head><title></title></head><body>" + printableElements + "</body></html>"
		var w = window.open('', "_blank");
		let cssStyles = extractStyles(window.document);
		const orderHtml = `<html><head><title></title>` +cssStyles+ `</head><body>` + printableElements + `</body></html>`
	  	w.document.write(orderHtml);
		
		  w.document.close();
		  w.window.focus();
		  //The Timeout is ONLY to make Safari work, but it still works with FF, IE & Chrome.       
		   setTimeout(function() {
			  w.window.print();
			  w.window.close();
		  }, 1000);
		
		 //w.window.print();
		// w.window.focus();
		// w.window.close();
	  }	
	
	const setTabsHtmlData = (tabType, innerHtmlText) => {
		switch (tabType) {
			case 1:
				setTabsHtml1(innerHtmlText);
				break;
			case 2:
				setTabsHtml2(innerHtmlText);
				break;
			case 3:
				setTabsHtml3(innerHtmlText);
				break;
			case 4:
				setTabsHtml4(innerHtmlText);
				break;
			case 5:
				setTabsHtml5(innerHtmlText);
				break;
			default:
				break;
		}
	}


	let stationName = '', inv_add1 = '', termCode = '', inv_phone = '', emergencyPhone = '', manager = '', inv_fax = '', wkdy_hours = '', sat_hours='-' , sun_hours = '-';

	if( props.reportData && props.reportData.header ){
		stationName = props.reportData.header.stationName;
		inv_add1 = props.reportData.header.inv_add1;
		termCode = props.reportData.header.termCode;
		inv_phone = props.reportData.header.inv_phone;
		emergencyPhone = props.reportData.header.emergencyPhone;
		manager = props.reportData.header.manager;
		inv_fax = props.reportData.header.inv_fax;
		wkdy_hours = props.reportData.header.wkdy_hours;
		sat_hours = props.reportData.header.sat_hours;
	}

	let ffLogoB64  = ffLogoB64Data();

	return (
		<div className="row">
			<div className='col-md-12 text-right mb-2'>
				<button className="btn btn-secondary pull-right ml-auto" type="button" id='printAll' onClick={handlePrintAll} >
				<i className='fa fa-print'></i> Print</button> 
			</div>
		<div id='print-area-tariff'>
		<div className='tariffReportDiv p-4'>
			<div id="tariffReportDiv1">
			<div className="row">
				<div className="col-md-2">
					<img  alt="Freight Force" src={ffLogoB64} height='100' width='100' />
					{/* src={AppLogo} */}
				</div>
				<div className="col-md-8 text-center apHead">
					<h4>{stationName}</h4>
					<p> {inv_add1}</p>
				</div>
				<div className="col-md-2 text-center pt-4">
					<h5>{termCode}</h5>
				</div>
			</div>
			<div className="row my-2">
				<div className="col-md-12 text-center">
					<div className="wtExp">Weight Rate Expiration Date :&nbsp;<span> {_expDate} </span></div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<p className="mb-0">Phone:  {inv_phone}<br />
						Emergency Phone: {emergencyPhone} <br />
						Manager:  {manager}<br />
						Fax:  {inv_fax}
					</p>
				</div>
				<div className="col-md-6 text-right">
					<p className="mb-0"><strong>Hours</strong> <br />
						Monday through Friday: {wkdy_hours}<br />
						Saturday: {sat_hours}<br />
						Sunday and Holidays: {sun_hours}</p>
				</div>
			</div>
			</div>
			{/* <hr className="mb-3"/> */}
			<div className="row mb-3 mt-3 tabsDiv">
				<div className="col-md-12">
					{/* <TariffCharges tariffData={props.reportData.tariffData} />
									<hr className="mb-3"/>
									<SpecialCharges specialData={props.reportData.specialData} />
									<hr className="mb-3"/>
									<AccessorialCharges accessorialData={props.reportData.accessorialData} />
									<hr className="mb-3"/>
									<AirportTransfer airportTransferData={props.reportData.airportTransferData} />
									<hr className="mb-3"/>
									<Locations locationData={props.reportData.locationData} /> */}

					<Tabs defaultActiveKey="tab-special">
						<Tab eventKey="tab-tariff" title="Tariffs - Release Rate">
							<TariffCharges tariffRate={props.reportData.tariffRate} setTabsHtmlData={setTabsHtmlData} />
						</Tab>
						<Tab eventKey="tab-special" title="Special Charges -- Release Rate">
							<SpecialCharges specialRate={props.reportData.specialRate} setTabsHtmlData={setTabsHtmlData} />
						</Tab>
						<Tab eventKey="tab-accessorial" title="Accessorial Charges -- Release Rate">
							<AccessorialCharges surchargeRate={props.reportData.surchargeRate} setTabsHtmlData={setTabsHtmlData} />
						</Tab>
						<Tab eventKey="tab-airport" title="Airport Transfer">
							<AirportTransfer airportTransferRate={props.reportData.airportTransferRate} setTabsHtmlData={setTabsHtmlData} />
						</Tab>
						<Tab eventKey="tab-locations" title="Cities Served">
							<Locations citiesServed={props.reportData.citiesServed} setTabsHtmlData={setTabsHtmlData} />
						</Tab>
					</Tabs>
				</div>
			</div>
			<div id='tariffReportDiv2'>
			<div className="row mb-3 ">
				<div className="col-md-12 text-justify">

					<h6>Fuel surcharge, Rates and Service areas:</h6>
					<p>Check <a href="http://www.freightforce.com" rel="noreferrer" target="_blank">http://www.freightforce.com </a>for current rates or for updates and/or changes to rates and cites serviced.
						Published rates and areas are for either a pickup at the shipper and transport back to the Freight Force
						Approved Motor Carrier dock, or for an airport area recovery from an inbound carrier and delivery to the consignee only.
						The published rates are not for direct shipper to consignee service.  Additional accessorials may apply including transfers, recoveries, 
						drops, fuel surcharge, etc. Rates are calculated per HAWB. Fuel Surcharge will also apply to all Airline Recoveries, Ground Carrier Drops and or Transfer Accessorials that move on a truck.
						 All shipments may be charged at the dimensional weight.
						Regular deliveries are at Freight Force Approved Motor Carrier’s convenience by 17:00</p>
					<p>If available, a Tractor Trailer Surcharge may apply to shipments that are:</p>
					<ul>
						<li>Over 10,000 lbs or</li>
						<li>Greater than 96" tall, or 288”  long or 94' wide or</li>
						<li>Require and ICC Bumper</li>
					</ul>
					<h6>Shipment Recoveries:</h6>
					<p>Shipment recoveries from an inbound carrier by the Freight Force approved motor carrier,
						excluding consolidations with local deliveries, will be charged at the associated inbound carrier
						zip code rate per the published tariff unless the Freight Force approved motor carrier performs
						a delivery to the shipment's consignee as noted on the shipment HAWB. Any recovery from an Airline will incur an additional charge.</p>

					<h6>Shipments Dropped at the Freight Force Approved Motor Carrier Terminal:</h6>
					<p>Shipments dropped directly to the Freight Force approved motor carrier terminal, which are to
						be subsequently dropped at another carrier location are not considered a transfer or drop and
						will be charged as a delivery to that carrier. The delivery rate charged will be based upon the
						associated carrier's zip code in the current published tariff.
					</p>
					{ props.reportData.notes && 
					<>
					<h5>Notes:</h5> 
					<h6 className="Labelbold15Blue">Tariff:</h6> <p className="ml2">{props.reportData.notes.tariff}</p>
					<h6 className="Labelbold15Blue">Special Delivery:</h6> <p className="ml2">{props.reportData.notes.special_delivery}</p>
					<h6 className="Labelbold15Blue">Accessorial:</h6> <p className="ml2">{props.reportData.notes.accessorial}</p>
					<h6 className="Labelbold15Blue">Airport Transfer:</h6> <p className="ml2"> {props.reportData.notes.airport_transfer}</p>
					<h6 className="Labelbold15Blue">Linehaul:</h6> <p className="ml2">{props.reportData.notes.linehaul}</p>
					<h6 className="Labelbold15Blue">Special Transfer:</h6> <p className="ml2">{props.reportData.notes.special_transfer}</p>
					<h6 className="Labelbold15Blue">Cities Served Transfer:</h6> <p className="ml2">{props.reportData.notes.cities_served}</p>
					</>
					}
				</div>
			</div>
			</div>
		</div>
		</div>
		</div>
	);

}

export default TariffRateReportData