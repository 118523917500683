import {  useState } from 'react'

const DataAirportTransferEdit = () => {
  
    const  [initData] = [{
        new_station : '',
        new_min_amt : '',
        new_per_lbs : '',
        new_rate: '',
        new_cap_amt: '',
    }];
    const [initDataReq] = [{
        new_station_ErrorClass : 'form-control',
        new_min_amt_ErrorClass : 'form-control',
        new_per_lbs_ErrorClass : 'form-control',
        new_rate_ErrorClass : 'form-control',
        new_cap_amt_ErrorClass : 'form-control',
        new_station : true,
        new_min_amt : true,
        new_per_lbs : true,
        new_rate: true,
        new_cap_amt: true
    }];

    const [formEditData, setFormEditData] = useState(initData);

    const [formEditDataReq, setFormEditDataReq] = useState(initDataReq);

    const [showSaveDataLoading, setSaveShowDataLoading] = useState(false);

    const onSubmitEdit = () => {
        const copy = {...formEditData};
        const errCopy = {...formEditDataReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
        Object.entries(copy).map(([key, value]) => {
            var errname=key+"_ErrorClass";
            var _css = "form-control";
            var errState={}
            var isRequired = errCopy[key];
            if(!value && isRequired ){
                errState = {[key]: true,[errname]: _css+' is-invalid'};
                status = true;
            }else{
                errState = {[errname]: _css};
            }
            Object.assign(errObject,errState);
        })
        if(status){
            setFormEditDataReq({...formEditDataReq,...errObject});
            validationReqFlag = true;
        } 
        console.log("validationReqFlag",validationReqFlag)
        return validationReqFlag;
            
    }

    const onValidateEdit = (name, value) => {
        var errState={};
        var status=false;
        var key = name;
        var errname=key+"_ErrorClass";
        var _css = "form-control";
        if(key === name && value) {
            errState = {[name]: true,[errname]: _css+' is-invalid'};
            status=true;
        }else if(key === name && !value) {
            errState = {[name]: false,[errname]: _css+''};
            status=true;
        }
        if(status)
            setFormEditDataReq({...formEditDataReq, ...errState});
      }

    const handleEditInputChange = (name, value) => {
        setFormEditData({...formEditData, [name]: value});
    }

    const clearEditForm = () => {
        setFormEditData(initData);
        setFormEditDataReq(initDataReq);
    }

    const setFormEditDataObj = (_obj) => {
        //console.log("fd",formData)
        let res_obj = { ...formEditData, ..._obj}
        setFormEditData(res_obj);
        //console.log("res",res_obj)
    }

    return {
        formEditData,
        formEditDataReq,
        handleEditInputChange,
        onSubmitEdit,
        onValidateEdit,
        clearEditForm,
        setFormEditDataObj
    }
}

export default DataAirportTransferEdit;