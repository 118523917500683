import React, { useEffect, useState } from 'react'
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import signboard from '../../../../../assets/img/signboard.png'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
window.JSZip = jzip;

const OpenClosePortal = (props) => {

    useEffect(() => {
        props.restFunCall();
    }, [])
 
    const image = () =>{
      return <img src={signboard} alt="Open/Close"/>
  }

    useEffect(() => {
        let table = $("#openCloseProtalTbl").DataTable({  // eslint-disable-line
           "responsive": true,
           "pageLength": 10,
           "processing": true,
           "scrollX": true,
             "autoWidth": false,
             "bAutoWidth": false,
            "data": props.tariffData,
            "dom":
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
                {
                    extend: 'copyHtml5',
                    className: 'btn btn-primary btn-sm',
                    text: '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Copy'
                },
                {
                    extend: 'excelHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text: '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'Excel',
                    exportOptions: {
                        modifier: {
                            order: 'current',
                            page: 'all',
                            selected: false
                        }
                    },

                },
                /*{
                    extend:    'csvHtml5',
                    className: 'btn btn-primary  btn-sm',
                    text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'CSV'
                },*/
                {
                    extend: 'pdfHtml5',
                    text: '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                    className: 'btn btn-primary  btn-sm',
                    filename: 'LinkCustomerAccounts',
                    title: 'Link Customer Accounts',
                    titleAttr: 'PDF',
                    orientation: 'landscape',
                    pageSize: 'LEGAL',
                    customize: function (doc) {
                        doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                    }
                }
            ],
            "lengthMenu": [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
            ],
            "columns": [
              { "data": "terminal" },
              { "data": "isOpen",
              "render": function (data, type, row) {
                var span_ele='';
                if(data === false || data === 'false')
                span_ele = "<span class='stBadge  stOrng'> Closed </span>";
                else if(data === true || data === 'true')
                span_ele = "<span class='stBadge stGrn'> Opened </span>";
               // var _span_ele = "<span class='stBadge " + _css + "'>" + data + "</span>";
                return span_ele;
              }
             },
              {
                  orderable: false,
                  data: null, visible: true, "className": "notexport",
                  "render": function (data, type, row) {
                    var span_ele='';
                    if(data === false || data === 'false')
                    span_ele = "<span class='stBadge  stOrng'> Closed </span>";
                    else if(data === true || data === 'true')
                    span_ele = "<button type='button' class='openCloseEdit btn btn-danger btn-circle btn-sm'>Close</button>";
                   // var _span_ele = "<span class='stBadge " + _css + "'>" + data + "</span>";
                    return span_ele;
                  }
                  
              },
               // { "data": "updatedRate.moN_FRI_0800_1700", "defaultContent": "<i>0</i>" },
               
            ],
            "columnDefs": [
                // { "width": "25%", "targets": 2 },
                //    { "targets": "_all", }
            ],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function (settings, json) {
                //jQuery(jQuery.fn.dataTable.tables(true)).DataTable().columns.adjust().draw();
            }
        });
        $('#openCloseProtalTbl tbody').on('click', 'tr td span.openCloseEdit', function (event) {
                // , 'td.dt-control'
            var tr = $(this).closest('tr');
            //var row = table.row(tr);
            var row_data = table.row(tr).data();
            //console.log("rd",row_data);
            handleShow(row_data)
        });
    }, [props.tariffData]);
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState([]);

    const handleShow = (rowData) => {
        //console.log("Accessorials rowData", rowData)
        setEditData(rowData)
        setShow(true);
    }
    return (
        <>
    <hr className="tabhr"/>
        <div className={props.loading ? 'container parentDisable' : ''}>
            {props.loading && <HashLoader color={'#1E73BE'} loading={props.loading} size={100} className={'loadSpin'} />}
            <div className="card card-custom">
                {/* <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Link Customers</h4>
        </div> */}
                <div id="datatable">
                    <table id="openCloseProtalTbl" className="display" style={{ width: "100%", border: "1px solid #ccc" }}>
                        <thead>
                            <tr>
                                <th>Station</th>
                                <th>Portal Status</th>
                                <th>Open Close Portal</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    </>
    )
}



export default OpenClosePortal