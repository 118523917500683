import React from 'react'
import { useState } from 'react'
import InputElement from '../../../formControls/InputElement';

const TariffUpdatePortalHome = () => {

  const [disableButtons, setDisableButtons] = useState(true);

  const [reopen, setReopen] = useState(false);

  const handleInputChange = (name, value) => {
    setReopen(value);
    if( value ){
      setDisableButtons(false);
    }else{
      setDisableButtons(true);
    }
  }

  return (
    <>
    <hr className="tabhr"/>
      <div className="container mb-4">
        <div className="row">
          <div className="col-md-12 text-justify mt-4">
          <p>
          Welcome back, it's time to update your tariff.  If you have tariff questions, please contact Carleton Henderson at (714) 995-9300 x 231.
          </p>
          <p className="mt-4"> 
          <strong> Tariff Update Procedure : </strong>
          </p>
            <ul className="regList">
              <li>You need to go through each tab and input the new rates if any.</li>
              <li>To change a rate click "EDIT", and input the new rate, and then click "UPDATE" to save.</li>
              <li>If you make a mistake just go back to the tab and make the change.</li>
              <li>If an update is not required, just skip and go to the next tab.</li>
              <li>Upon completion print your new tariffs (use the button "View and Print Summary" below), review and sign each page and fax to (714) 995-9340.</li>
            </ul>
          
          <p>If you make an error and need to roll back to start over, don't worry just click a reset button below on the item you want to start over.</p>
          <p className="mt-4">Please confirm that you want to reset your tariff by clicking on the check box below.</p>
          <div className="custom-control custom-checkbox mt-2 mb-2">
              <InputElement type="checkbox" className="custom-control-input" id="reopen" name="reopen"
                  checked={reopen} 
                  onChange={handleInputChange} />
              <label className="custom-control-label" htmlFor="reopen"><strong>I made a mistake and I want a do over.</strong></label>
          </div>
          <p className="mt-4 text-danger">
            <strong> Warning: If you reset the wrong tariff, there is no recovery from that, you will have to re-enter the changes you made again. </strong>
          </p>
          </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='tariff' disabled={disableButtons} >
               Tariff</button> 
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='splCharges' disabled={disableButtons} >
               Special Charges</button> 
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='accessorials'  disabled={disableButtons} >
               Accessorials</button> 
            </div>
          {/* </div>
          <div className="row mt-2 mb-2"> */}
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='airportTransfer'  disabled={disableButtons} >
               Airport Transfer</button> 
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='linehaul'  disabled={disableButtons} >
               Linehaul</button> 
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary btn-block" type="button" id='citiesServed'  disabled={disableButtons} >
              Cities Served</button> 
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4">
              <button className="btn btn-secondary active btn-block p-1" type="button" id='viewPrintSumm'  >
              <i className='fa fa-print'></i> View and Print summary</button> 
            </div>
          </div>
      </div>
      </>
  )
}

export default TariffUpdatePortalHome