import React, { useEffect } from "react";
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../formControls/InputElement";
import DataUserLookup from "./Data/DataUserLookup";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const UserLookup = () => {
    const {handleInputChange, onSubmitSearch, onValidateFilters, filtersInput, filtersReq, reportData, showDataLoading} = DataUserLookup();
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
      
    useEffect(() => {
        let table = $("#userTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : reportData,
            "dom": 
                    "<'row'<'col-lg-3 col-md-5'l><'col-lg-5 col-md-7 text-right'B><'col-lg-4 col-md-8 offset-lg-0 offset-md-4'f>>" +
                    "<'row'<'col-lg-12'tr>>" +
                    "<'row'<'col-md-5'i><'col-md-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp;Copy',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'Copy',
                  exportOptions: {
                    columns: ':not(.notexport)'
                  }
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp;Excel',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'Excel',
                  exportOptions: {
                    columns: ':not(.notexport)',
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i className="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'CSV',
                  exportOptions: {
                    columns: ':not(.notexport)'
                  }
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp;PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  exportOptions: {
                    columns: ':not(.notexport)'
                  },
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                { "data" : "Status"},
                { "data" : "FirstName"},
                { "data" : "LastName"},
                { "data" : "Username"},
                { "data" : "Password",
                   "render": function (data, type, row) {
                    /*
                    let toggle=false;
                    let invis = '', pass ='',result='',a='';
                    for(let i=0;i<data.length;i++) invis=invis+'*'
                    if (toggle)
                    a = '<span className="showpass '+row['Username']+' btn-link "  >'+result+' </span>';
                    else
                    a = '<span className="showpass '+row['Username']+' btn-link"  >'+invis+' </span>';
                    */

                    let a= "<button type='button' class='btn btn-primary showpass' data-container='body' data-toggle='popover'  data-placement='right'  data-content='"+row['Username']+"'> "+
                    //data-trigger='focus'
                            "<i class='fa fa-eye'></i> </button>"
                    return a;
                } , "className": "notexport" 
                },
                { "data" : "Account_Level"}
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            },
            "drawCallback": function(settings){
                $(".popover").popover('hide');
            }
    });
    var toggle=true;
    $('#userTbl tbody').on('click','tr td button.showpass', function (e) {
        // // , 'td.dt-control'
        // var tr = $(this).closest('tr');
        // var row = table.row(tr);
        // var row_data = table.row( tr ).data() ;
        // if(toggle){
        // $('tr td span.showpass.'+row_data.Username).text(row_data.Password);
        // }
        // else{
        //     let invis='';
        // for(let i=0;i<row_data.Password.length;i++) invis=invis+'*'
        // $('tr td span.showpass.'+row_data.Username).text(invis);
        // }
        // toggle = !toggle;
        
       
        /* hide any open popovers when the anywhere else in the body is clicked */
        $('[data-toggle=popover]').each(function () {
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });

        // $(".popover").popover('hide');

        $('[data-toggle="popover"]').popover()

      });

      
      /***** Dismiss all popovers by clicking outside, don't dismiss if clicking inside the popover content  **************/
        $('body').on('click', function(e) {
            if (typeof $(e.target).data('original-title') == 'undefined' && !$(e.target).parents().is('.popover.show')) {
            $('[data-original-title]').popover('hide');
            }
        });

    },[reportData]);

    return (
        <div className="card card-custom">
        <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">User Lookup</h4>
        </div>
        <form>
            <div className="row  mb-3">
                <div className="col-lg-2 col-md-4 mb-3 mb-lg-0">
                    <InputElement type="text" id="userLookupInput" name="userLookupInput" 
                        onChange={handleInputChange} 
                        value={filtersInput.userLookupInput || ''}
                        className={filtersReq.userLookupInput_ErrorClass} placeholder='Customer Code' 
                        validation="required" onInvalid={onValidateFilters}
                        />
                 
                </div>
                <div className="col-lg-2 col-md-4">
                    <button className="btn btn-primary btn-block pull-right" type="button" onClick={onSubmitSearch}>Search</button>

                </div>
            </div>
        </form>
        <hr/>
        {
            showDataLoading && 
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center"> 
                    <HashLoader  color={'#1E73BE'} loading={showDataLoading} />
                </div>
            </div>
        }
        {
            filtersInput.showTable &&  
            <div id="datatable">
            <table id="userTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Account Level</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            </div>
        }
        </div>
    );
}


export default UserLookup;