import React, { useEffect } from 'react'

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { fetchDocumentsAPI, fetchEstimatesAPI, fetchStatusHistoryAPI } from './Data/TrackingReportDataScripts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require('jszip');
window.JSZip = jzip;


const TrackingReportData = (props) => {
  //const [tableData] = useState(props.reportData);
  const tableData = props.reportData;
  var table = null;
  let showDetailsColumn = (props.showHideDetailsColumn === "hide" ? false : true);

  //console.log("tracking reprost"+JSON.stringify(tableData))
  useEffect(() => {

    //if( !props.showDataLoading ) {
    table = $("#trackTbl").DataTable({  // eslint-disable-line
      // pagingType: "full_numbers",
      "responsive": true,
      "pageLength": 10,
      "processing": true,
      "scrollX": true,
      // "scrollY": 500,
      "width":"100%",
      "autoWidth": false,
      "fixedColumns": true,
      "bAutoWidth": false,
      // scroller: {
      //   loadingIndicator: true
      // },
      "data": tableData,
      "dom":
          "<'row'<'col-lg-3 col-md-5'l><'col-lg-5 col-md-7 text-right'B><'col-lg-4 col-md-8 offset-lg-0 offset-md-4'f>>" +
          "<'row'<'col-lg-12'tr>>" +
          "<'row'<'col-md-5'i><'col-md-7'p>>",
      "buttons": [
        {
          extend: 'copyHtml5',
          className: 'btn btn-primary btn-sm',
          text: '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
          filename: 'FreightTracking',
          title: 'Freight Tracking',
          titleAttr: 'Copy',
          exportOptions: {
            columns: ':not(.notexport)'
          }
        },
        {
          extend: 'excelHtml5',
          className: 'btn btn-primary  btn-sm',
          text: '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
          filename: 'FreightTracking',
          title: 'Freight Tracking',
          titleAttr: 'Excel',
          exportOptions: {
            columns: ':not(.notexport)',
            modifier: {
              order: 'current',
              page: 'all',
              selected: false
            }
          },

        },
        /*{
          extend: 'csvHtml5',
          className: 'btn btn-primary  btn-sm',
          text: '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
          filename: 'FreightTracking',
          title: 'Freight Tracking',
          titleAttr: 'CSV',
          exportOptions: {
            columns: ':not(.notexport)'
          }
        },*/
        {
          extend: 'pdfHtml5',
          text: '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
          className: 'btn btn-primary  btn-sm',
          filename: 'FreightTracking',
          title: 'Freight Tracking',
          titleAttr: 'PDF',
          orientation: 'landscape',
          pageSize: 'LEGAL',
          exportOptions: {
            columns: ':not(.notexport)'
          },
          customize: function (doc) {
            doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
          }
        }
      ],

      // fnRowCallback: function (
      //   nRow,
      //   aData,
      //   iDisplayIndex,
      //   iDisplayIndexFull
      // ) {
      //   var index = iDisplayIndexFull + 1;
      //   $("td:first", nRow).html(index);
      //   return nRow;
      // },

      "lengthMenu": [
        [10, 20, 30, 50, -1],
        [10, 20, 30, 50, "All"],
      ],
      "columns": [
        { "data": "TagID" },
        { "data": "ServiceDate", type: 'date' },
        {
          "data": "Status", width: "20%",
          "render": function (data, type, row) {
            var _css = "";
            switch (data) {
              case 'POD':
                _css = "stPod";
                break;
              case 'Alert':
                _css = "stAlert";
                break;
              case 'On Hand':
                _css = "stOnhand";
                break;
              case 'Out for Delivery':
                _css = "stOutDel";
                break;
              case 'Recovered':
                _css = "stRec";
                break;
              case 'Dispatched':
                _css = "stDis";
                break;
              default:
                break;
            }
            var _span_ele = "<span class='stBadge " + _css + "'>" + data + "</span>";
            return _span_ele;
          }
        },
        { "data": "ControlTerminal" },
        { "data": "Alerted", width: "18%", type: 'date' },
        { "data": "HAWB" },
        { "data": "MAWB" },
        { "data": "PODDateTime", width: "18%", type: 'date' },
        { "data": "PODSigner", width: "18%", },
        { "data": "MoveType" },
        // { "data" : "cfmShipID" ,visible: false,},
        // { "data" : "isInvoiced",visible: false, },
        { "data": "cfmShipID", visible: true, "className": "notexport" },
        // { "data" : "imageList",visible: false, },

        {
          // className: 'dt-control',
          orderable: false,
          data: null, width: "35%", visible: showDetailsColumn, "className": "notexport",
          defaultContent: "<span class='stIco stOrng'  alt='View Estimates' title='View Estimates'><a href='javascript:void(0)'><i class='fa fa-list-alt'></i></a></span> " +
            "<span class='stIco stBlue'  alt='Supporting Documents/Final Invoice' title='Supporting Documents/Final Invoice'><a href='javascript:void(0)'><i class='fa fa-copy'></i></a></span>" +
            "<span class='stIco stGrn'  alt='Status History' title='Status History'><a href='javascript:void(0)'><i class='fa fa-history'></i></a></span>"
        }
      ],
      "columnDefs": [
        {
          "targets": 2, // your case first column
          "className": "text-center",
          "width": "15%"
        },
        {
          "targets": 11, // your case first column
          "className": "text-center",

        }
      ],
      "bDestroy": true, // to fix canoot reinitialise datatable
      "initComplete": function (settings, json) {
      }
    });

    if (showDetailsColumn) { // bind events only if details column is visible
      // console.log("bind")
      var isViewEstOpen = false;
      var isDocOpen = false;
      var isStatHisOpen = false;




      // Add event listener for opening and closing details
      $('#trackTbl tbody').on('click', 'tr td span.stOrng', function (event) {
        // , 'td.dt-control'
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        var row_data = table.row(tr).data();
       // console.log(JSON.stringify(row_data))
        if (isDocOpen || isStatHisOpen) {
          row.child.hide();
          tr.removeClass('shown');
          isDocOpen = false;
          isStatHisOpen = false;
        }

        if (row.child.isShown()) {
          // This row is already open - close it
          isViewEstOpen = false;
          row.child.hide();
          tr.removeClass('shown');
        } else {
          row.child("<div><i class='fas fa-spinner fa-spin'></i> Loading...</div>").show();
          tr.addClass('shown');
          fetchEstimatesAPI(row, row_data);
          isViewEstOpen = true;
          // setTimeout(()=>{
          //   let _data1 = {
          //     "type" : "DEL",
          //     "origin" : "PDX",
          //     "peices" : 1,
          //     "weight" : 515,
          //     "dim" : 416,
          //     "chargesDesc" : 'DELIVERY CHARGE TOTAL',
          //     "charges" : 69.34,
          //     "deliverCharge" : 45.32, 
          //     "fuelCharge" : 24.02,
          //     "totalCharges" : 69.34 
          //   };
          //   Open this row // row.child(format(row.data())).show();
          //   row.child(buildEstimateRow(_data1)).show();
          // }, 2000);
        }
      });



      // Add event listener for opening and closing details
      $('#trackTbl tbody').on('click', 'tr td span.stBlue', function (event) {
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        var row_data = table.row(tr).data();
        if (isViewEstOpen || isStatHisOpen) {
          row.child.hide();
          tr.removeClass('shown');
          isViewEstOpen = false;
          isStatHisOpen = false;
        }
        if (row.child.isShown()) {
          isDocOpen = false;
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('shown');
        } else {
          row.child("<div><i class='fas fa-spinner fa-spin'></i> Loading...</div>").show();
          tr.addClass('shown');
          fetchDocumentsAPI(row, row_data);
          isDocOpen = true;

          // let _documents = [
          //   {"documentName":"Supporting Doc1", "documentLink": "https://www.learningcontainer.com/wp-content/uploads/2019/09/sample-pdf-file.pdf", "documentType": "file"},
          //   {"documentName":"Final Invoice", "documentLink": "https://www.appsloveworld.com/wp-content/uploads/2020/01/SamplePdf1_12mb_6pages.pdf", "documentType": "file"},
          //   {"documentName":"Supporting Images", "documentLink": "https://media.proprofs.com/images/QM/user_images/1826446/1484804036.jpg", "documentType": "image"},
          //   {"documentName":"Supporting Images 1", "documentLink": "/favlogo.png", "documentType": "image"},
          // ]
          // row.child(buildDocumentsRow(_documents)).show();
        }
      });

      // Add event listener for opening and closing details
      $('#trackTbl tbody').on('click', 'tr td span.stGrn', function (event) {
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        var row_data = table.row(tr).data();
        if (isViewEstOpen || isDocOpen) {
          row.child.hide();
          tr.removeClass('shown');
          isDocOpen = false;
          isViewEstOpen = false;
        }
        if (row.child.isShown()) {
          // This row is already open - close it
          isStatHisOpen = false;
          row.child.hide();
          tr.removeClass('shown');

          // tr.find('svg').attr('data-icon', 'plus-circle');

        } else {
          row.child("<div><i class='fas fa-spinner fa-spin'></i> Loading...</div>").show();
          tr.addClass('shown');
          fetchStatusHistoryAPI(row, row_data);
          isStatHisOpen = true;
          // let _statusHistory = {
          //   "alerted" : "08/04/2022 22:33:00",
          //   "onHand" : "08/04/2022 18:00:00",
          //   "outForDelivery" : "08/04/2022 19:45:00",
          //   "pod" : "08/04/2022 20:00:00"
          // }
          // // Open this row
          // row.child(buildStatusHistoryRow(_statusHistory)).show();
          // tr.find('svg').attr('data-icon', 'minus-circle'); // FontAwesome 5
        }
      }); // eslint-disable-line

      // $('#trackTbl tbody').on('click','tr td a.view1Css', function (event) {
      //   alert("Coming Soon..");
      // });
      // console.log("dl="+JSON.stringify(props.showDataLoading));
    }
  }, [props.showDataLoading]) // eslint-disable-line


  return (
    // <DataTable tableHeaders={tableHeaders} tableData={tableData} />
    <div id="datatable">
      <table id="trackTbl" className="display" style={{ width: "100%", border: "1px solid #ccc" }}>
        <thead>
          <tr>
            <th>Tag ID</th>
            <th>Service Date</th>
            <th>Status</th>
            <th>Control Terminal</th>
            <th>Alerted</th>
            <th>HAWB</th>
            <th>MAWB</th>
            <th>POD Date</th>
            <th>POD Signer</th>
            <th>Move Type</th>
            <th>ShipID</th>
            <th>  </th>
          </tr>
        </thead>
        <tbody>
          {/* {
            props.showDataLoading && 
            <tr>
              <td valign="top" colSpan={11} className="dataTables_empty">
                <i className='fas fa-spinner fa-spin'></i> Loading...
              </td>
            </tr> 
          } */}
        </tbody>
      </table>
    </div>
  )
}

export default TrackingReportData