import './DatePickerElement.css';
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { validateRequired } from '../utils/ValidationScripts';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import { faCheckSquare, faCoffee } from '@fortawesome/fontawesome-free-solid'
const TimeElement = (props)=> {

    const [startDate, setStartDate] = useState(new Date('01-01-2022 09:00'));

    const datepickerRef = useRef(null);
    function handleClickDatepickerIcon() {
        const datepickerElement = datepickerRef.current;
        // console.log("datepickerElement = ", datepickerElement);
        datepickerElement.setFocus(true);
      }
      const updateValueToState = (date)=>{
        //let formatted_dt = formatDate(date);
        props.onChange([props.id], date);
        validation(date);
    }

    const validation = (date)=>{
        //console.log("inputlement for validation "+[props.id]+"::"+date)
        if(props.validation === "required"){
            if(!validateRequired(date)){
                props.onInvalid(props.id,true);
            }else{
                props.onInvalid(props.id,false);
            }
        }
    }

    // to disable user input
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

        return (
            <div className="input-group-append">
            <DatePicker className="form-control" 
            {...props}
            
           // onChange={(date) => setStartDate(date)}
            showTimeSelect
            showTimeSelectOnly
            placeholderText="HH:MI AM" 
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            onChangeRaw={handleDateChangeRaw}
            isClearable={true}
            onChange={(date) => {updateValueToState(date);}}
            ref={datepickerRef} />
            <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2"  onClick={() => handleClickDatepickerIcon()}>
                <i className="fa-solid fa-clock" />
                </span>
            </div>
            </div>
          
        );
    
}

TimeElement.defaultProps = {
    className: "form-control",
    dateFormat: 'MM/dd/yyyy',
    placeholder: "MM/dd/yyyy"
};
export default TimeElement;
