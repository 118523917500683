import * as actionTypes from '../actions/actionTypes'
import {updateIntialState,updateObject} from '../utility'
//import { createReducer } from '@reduxjs/toolkit'

const initialState = {};

const reducer = //createReducer(initialState, { UPDATE_ITEM: 
    ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TARIFFCODEUPDATE:
            return updateObject(state,action.airportCode);
        default:
            return state
    }
};
//})


export default reducer;