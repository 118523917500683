import moment from "moment";

export function WebNamesModalValidate(values) {
    let errors = {};
    const { nickname, webname } = values;
  
    if (!nickname) {
      errors.nickname = "Nickname is required";
    } else {
      let isValid = !!nickname.replace(/^\W+|\W+$/g, "");
      if (!isNaN(nickname) || !isValid) {
        errors.nickname = "Please enter valid nickname";
      }
    }
  
    if (!webname) {
      errors.webname = 'Website is required';
    } else if (!validURL(webname)) {
      errors.webname = "Please enter valid URL";
    }
    return errors;
  };
  
  export function validURL(str) {
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  }
  
  
  export const loginValidate = (values) => {
    let errors = {};
    const { email, password } = values;
  
    if (!email && !password) {
      errors.email = "Username and Password can't be blank";
      return errors;
    }
    if (!email) {
      errors.email = "Username/Password can't be blank";
    }
    if (!password) {
      errors.password = "Username/Password can't be blank";
    }
    return errors;
  }

  export const getSetValue = (value,setValue) =>{
   // console.log("value");
    setValue = "validationUpdate";
   // console.log("set/value"+setValue);
  }

  export const validatePassword = (value) => {
    //console.log("validatevalue="+value)
    if (!value) {
        return false;
    }
    return true;
}

export const validateNumberDecimal = (value) => {
  const reg=/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/
  if(reg.test(value)){
    return true;
  }
  return false;
          
}

export const validateNumber = (e) => {

  return e.target.validity.valid ?  true : false;
}

export const isValidSelect = (value) => {
  if (Number(value)!==0) {
    return true;
  }
  return false;
}

export function isValidEmail(str) {
  var res = str.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  return (res !== null)
}

export function isValidPhoneNo(str) {
  var res = str.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
  return (res !== null)
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('/');
}

export function getNextDate(date){
  var new_date = null;
  let date_to_cal = date ? new Date(date) : new Date();
  new_date = moment(date_to_cal).add(1, 'days').toDate();
  return new_date;
}

export const isNumberAndNotZero = (value) => {
  if ( !isNaN(value) && Number(value)!==0) {
    return true;
  }
  return false;
}

export const validateRequired = (value) => {
  //console.log("validatevalue="+value)
  if (!value) {
      return false;
  }
  return true;
}

export const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export const getLocalStorageOrDefault = (key, defaultValue) => {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export const clearLocalStorage = () => {
    localStorage.clear();
}

export const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
      targetDoc.head.appendChild(styleSheet.ownerNode.cloneNode(true))
  })
  // Array.from(sourceDoc.fonts).forEach(font => targetDoc.fonts.add(font))
}

export function isValidAlphaNumeric(str) {
  var res = str.match(/^([a-zA-Z0-9]+)$/);
  return (res !== null)
}

export function isValidAlpha(str) {
  var res = str.match(/^([a-zA-Z]+)$/);
  return (res !== null)
}

export function isValidInteger(str) {
  var res = str.match(/^([0-9]+)$/);
  return (res !== null)
}

export function reformatToPhone(str) {
  var str1 = [];
  let s = str+"";
  str1[0] = s.substring(1,3);
  str1[1] = s.substring(3,6);
  str1[2] = s.substring(6);

  var res = "("+str1[0]+")"+str[1]+"-"+str[2];
  return res
}

export const extractStyles = (sourceDoc) => {
  let stylesCode = '';
  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
     stylesCode += styleSheet.ownerNode.outerHTML;
  })
  return stylesCode ;
}