import moment from "moment";
import {useState,useEffect} from 'react';
import {carrierSOP} from '../../../restAPI/CorporateCenterLinkRest'
const DataSales = (props) => {
   
 const  [Sop] = [{
    "DepartmentNumber": '',
    "SOPNumber": '',
    "AccountNumber": '',
    "CustomerName": '',
    "CustomerContactName": '',
    "ProjectName": '',
    "DateCreated": '',
    "StartDate": '',
    "EndDate": '',
    "FreightForceLocations": '',
    "FFSalesContact": '',
    "GeneralDescription": '',
    "Proceedures": ''
}];

const [initialData] = [{
    "DepartmentNumber": '',
    "SOPNumber": '',
    "AccountNumber": '',
    "CustomerName": '',
    "CustomerContactName": '',
    "ProjectName": '',
    "DateCreated": '',
    "StartDate": '',
    "EndDate": '',
    "FreightForceLocations": '',
    "FFSalesContact": '',
    "GeneralDescription": '',
    "Proceedures": ''
}];

const [initialDataReq] = [{
    DepartmentNumber: true,
    DepartmentNumber_ErrorClass: 'form-control',
    SOPNumber: true,
    SOPNumber_ErrorClass: 'form-control',
    AccountNumber: true,
    AccountNumber_ErrorClass: 'form-control',
    CustomerName: true,
    CustomerName_ErrorClass: 'form-control',
    CustomerContactName: true,
    CustomerContactName_ErrorClass: 'form-control',
    ProjectName: true,
    ProjectName_ErrorClass: 'form-control',
    DateCreated: true,
    DateCreated_ErrorClass: 'form-control',
    StartDate: true,
    StartDate_ErrorClass: 'form-control',
    EndDate: true,
    EndDate_ErrorClass: 'form-control',
    FreightForceLocations: true,
    FreightForceLocations_ErrorClass: 'form-control',
    FFSalesContact: true,
    FFSalesContact_ErrorClass: 'form-control',
    GeneralDescription: true,
    GeneralDescription_ErrorClass: 'form-control',
    Proceedures: true,
    Proceedures_ErrorClass: 'form-control'
}];

const [sopData, setSopData] = useState(initialData);
const [sopReq, setSopReq] = useState(initialDataReq);
const [pageCount, setPageCount] = useState(0);
const [sopList, setSopList] = useState([]); //([Sop]);
const [indexVal, setIndexVal] = useState(0);
const [opType, setOpType] = useState('load');

const handleInputChange = (name, value) => {
    setSopData({...sopData, [name]: value});
}

const updateSOPObject = (eachObj) => {
   const _dateCreated = eachObj.DateCreated ? (new Date(eachObj.DateCreated)) : null;
   const _startDate = eachObj.StartDate ? (new Date(eachObj.StartDate)) : null;
   const _endDate = eachObj.EndDate ? (new Date(eachObj.EndDate)) : null;
    setSopData({...eachObj,...{DateCreated:_dateCreated,StartDate:_startDate,EndDate:_endDate}});
}
const updateSOPList = (soplist) =>{
    setSopList([...soplist]);
}

const updateSOPListByIndex = (eachSopData,currentPage) =>{
  //console.log("indexValue==>"+currentPage);
  let tempList = [...sopList];
  tempList[currentPage] = eachSopData;
  setSopList([...tempList]);
}

const createNewSOP = () =>{
    setSopData({...Sop});

}

const loadDataTable = (_opType) =>{
  if(opType)
    setOpType(_opType)

  carrierSOP()
  .then(responseSopList => {
          if (responseSopList instanceof Error) {
              // console.log(" Carrier SOP  Error response "+JSON.stringify(responseSopList))
          } else {
          updateSOPList(responseSopList);
          setPageCount(responseSopList.length)
          // if(responseSopList.size>0)
          //     setSopData(...responseSopList[0]);
          }
          //console.log("fffTerm in contianer"+JSON.stringify(fffList))
      })
}

useEffect(() => {
  let mounted = true;
  if(opType==="load" && sopList.length>0){
    let eachObj = sopList[0];
    const _dateCreated = eachObj.DateCreated ? (new Date(eachObj.DateCreated)) : null;
    const _startDate = eachObj.StartDate ? (new Date(eachObj.StartDate)) : null;
    const _endDate = eachObj.EndDate ? (new Date(eachObj.EndDate)) : null;
    setSopData({...eachObj,...{DateCreated:_dateCreated,StartDate:_startDate,EndDate:_endDate}});
  }

  return () => mounted = false;
  
}, [sopList])// eslint-disable-line

useEffect(() => {
    let mounted = true;
    loadDataTable('load');
      return () => mounted = false;
    
  }, [])// eslint-disable-line

  const onValidateSop = (name, value) => {
    var errState = {};
    var status = false;
    Object.entries(sopReq).forEach(([key]) => {
      var errname = key + "_ErrorClass";
      if (key === name && value) {
        errState = { [name]: true, [errname]: 'form-control is-invalid' };
        status = true;
      } else if (key === name && !value) {
        errState = { [name]: false, [errname]: 'form-control is-valid' };
        status = true;
      }
    })
    if (status)
      setSopReq({ ...sopReq, ...errState });
    //console.log("onvalidateship Update "+JSON.stringify(shippingRequired));
  }

  const onSubmitSopDetails = () => {


    const copy = { ...sopReq };
    var status = false;
    let errObject = {};
    let validationReqFlag = false;
    //  console.log("Copy total "+JSON.stringify(copy));
    Object.entries(copy).forEach(([key, value]) => {
        
      if (!key.includes("_ErrorClass")) { //&& value
        var errname = key + "_ErrorClass";
        var errState = null;
        if(sopData[key]){
            errState = { [key]: false, [errname]: 'form-control is-valid' };
        }else{
            errState = { [key]: true, [errname]: 'form-control is-invalid' };
        }
        status = true;
        Object.assign(errObject, errState);
        // console.log("Total Error obj "+JSON.stringify(errObject));
      }
    })
    if (status) {
      setSopReq({ ...sopReq, ...errObject });
      // console.log("Copied  Total "+JSON.stringify(totalDimRequired));
    }

    let reqObj = { ...errObject };
    Object.entries(reqObj).forEach(([key, value]) => {
      if (key.includes("_ErrorClass")) {
        delete reqObj[key]
      }
    })

    //  console.log("c="+JSON.stringify(reqObj))
    validationReqFlag = Object.values(reqObj).includes(true);

    return validationReqFlag;
  }

  const clearFormData = () => {
    setSopData(initialData);
}
const clearFormCss = () => {
    setSopReq(initialDataReq);
}
const updatePageCount =(index) =>{
  setPageCount(index);
}
return {
    sopData,
    sopReq,
    sopList,
    pageCount, 
    updatePageCount,
    loadDataTable,
    handleInputChange,
    updateSOPObject,
    updateSOPList,
    updateSOPListByIndex,
    createNewSOP,
    onValidateSop,
    onSubmitSopDetails,
    clearFormData,
    clearFormCss

}

}

export default DataSales;