import React,{useEffect} from 'react'
import cartageImg from  '../../../assets/img/img_cartage.jpg';

const Cartage = () => {
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
	  const handleMenuClick = (e) => {
        e.preventDefault();
        return false;
    }
  return (
    <section className="mt-180 mb-4">
        <div className="container">
            <div className="row">
				<div className="col-md-12 order-md-1">
                    <div className="card card-custom">
                        <div className="d-flex mb-2">
                            <h4 className="pageHead text-uppercase">CARTAGE</h4>
                        </div>
						<div className="row">
							<div className="col-lg-5 col-md-12">
							<img src={cartageImg} className="img-fluid mb-4" alt='Cartage'/>
							</div>
							<div className="col-lg-7 col-md-12 text-justify">
							<p>Freight Force provides a one-stop nationwide solution for the needs of the air forwarder and logistics communities for expedited pickup and delivery in compliance with surface, air and TSA requirements.  Utilizing a network of approved motor carriers domiciled in more than 65+ convenient airport locations Freight Force offers a full range of ground transportation services to the forwarder and logistics communities using vans, straight trucks, trailers and flatbeds.</p>
							<p>The applicable service terms and conditions for this unique service are set forth in the Cartage Service Conditions and Tariff Rates.</p>

							<p>For more information, contact the local Freight Force office or the Sales and Marketing department at the Freight Force Corporate office at <a href="mailto:sales@freightforce.com" target="_blank">sales@freightforce.com</a></p>
							{/* <h5><a href="/fuelSurcharge" target="_new">Click Here for Surcharge</a></h5> */}
							<h6>These services include:</h6>
								<ul className="regList">
									<li>Airline Container Load/Unload</li>
									<li>Airport Drop and Recovery </li>
									<li>Airway Bill Preparation</li>
									<li><strong>Pickup and Deliveries to:</strong>
										<ul>
											<li>Arenas</li>
											<li>Bulk Mail Centers</li>
											<li>Concert Venues</li>
											<li>Convention Centers</li>
											<li>Construction Sites</li>
											<li>Hotels</li>
											<li>Hospital</li>
											<li>Stadiums</li>
											<li>School</li>
											<li>Malls</li>
											<li>Military bases</li>
											<li>Banding</li>
											<li>Blanket wrap</li>
											<li>Crating/Uncrating</li>
											<li>Dock Transfers</li>
											<li>Extra man deliveries</li>
											<li>In Bond Shipment Handling</li>
											<li>Inside Pickups or Deliveries</li>
											<li>Lift gate deliveries</li>
											<li>Over the counter pickup or deliveries</li>
											<li>Pier pickup or deliveries</li>
											<li>Residential Pickup or Deliveries</li>
											<li>Storage</li>
										</ul></li>
								</ul>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Cartage