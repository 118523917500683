import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { historyList } from '../../restAPI/QuickQuoteRest';


const DataHistory = () => {
    const  [filtersInput, setFiltersInput] = useState({
        trackingType : '',
        trackingInput : '',
        trackingText : '',
        showTable: false,
        fromDate:'',
        toDate:'',
    });

   
    const [reportData, setReportData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);
    //user profile from redux

    let userProfileData = useSelector((state) => state.usr)
    //userprofile from local storage
    //const userProfileData = localStorage.getItem("userProfile");
    //console.log("userprofile from localstorage "+JSON.stringify(userProfileData))
   

    

    const loadReportData = (loadType) => {
        // console.log(loadType+" loadtype")
        // console.log("up=",userProfileData)
        if( userProfileData && userProfileData.custCode ){

        }else{
            userProfileData = JSON.parse(localStorage.getItem("userProfile"));
        }
            
        if( userProfileData && userProfileData.custCode ){
            if(loadType === "default"){
                historyList(userProfileData.userid).then(response => {
                    setReportData([...response]);
                    setShowDataLoading(false);
                    setFiltersInput({...filtersInput, showTable: true});
                })
            } 
        }else{
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
        }
        // console.log("in finally"+JSON.stringify(userProfileData));
    }

    const handleInputChange = (name, value) => {
       // console.log("selected valus name"+name+"--"+value);
        setFiltersInput({...filtersInput, [name]: value});
    }

    useEffect(() => {
        
        setShowDataLoading(true);
        loadReportData("default");
    }, [])
    

    return {
        handleInputChange,
        
        filtersInput,
        reportData,
        showDataLoading
    }
}

export default DataHistory;