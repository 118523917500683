import React, { useEffect } from "react";
import { HashLoader } from "react-spinners";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InputElement from "../../../formControls/InputElement";
import DataAreasServiced from "./Data/DataAreasServiced";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const jzip = require( 'jszip');
window.JSZip = jzip;

const AreasServiced = () => {
    const {handleInputChange, handleInputChangeAlphaNumeric, onSubmitSearch, onValidateFilters, filtersInput, filtersReq, reportData, showDataLoading} = DataAreasServiced();
    useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
      
    useEffect(() => {
        let table = $("#userTbl").DataTable({  // eslint-disable-line
            // pagingType: "full_numbers",
            "responsive": true,
            "pageLength": 10,
            "processing": true,
            "scrollX": true,
            // "scrollY": '200px',
              "autoWidth": false,
              "bAutoWidth": false,
            // scroller: {
            //   loadingIndicator: true
            // },
            "data" : reportData,
            "dom": 
                "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-5'i><'col-sm-7'p>>",
            "buttons": [
              {
                  extend:    'copyHtml5',
                  className: 'btn btn-primary btn-sm',
                  text:      '<i class="fas fa-copy"></i> &nbsp;&nbsp; Copy',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'Copy'
              },
              {
                  extend:    'excelHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file-excel"></i> &nbsp;&nbsp; Excel',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'Excel',
                  exportOptions: {
                     modifier: {
                         order: 'current',
                         page: 'all',
                         selected: false
                     }
                  },
                  
              },
              /*{
                  extend:    'csvHtml5',
                  className: 'btn btn-primary  btn-sm',
                  text:      '<i class="fa fa-file"></i> &nbsp;&nbsp; CSV ',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'CSV'
              },*/
              {
                  extend:    'pdfHtml5',
                  text:       '<i class="fa fa-file-pdf"></i> &nbsp;&nbsp; PDF ',
                  className: 'btn btn-primary  btn-sm',
                  filename: 'UserLookup',
                  title: 'User Lookup',
                  titleAttr: 'PDF',
                  orientation: 'landscape',
                  pageSize: 'LEGAL',
                  customize: function(doc) {
                      doc.defaultStyle.fontSize = 6; //<-- set fontsize to 16 instead of 10 
                   }  
              }
          ], 
            "lengthMenu": [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            "columns": [
                { "data" : "AMC" },
                { "data" : "city" },
                { "data" : "state" },
                { "data" : "zip" },
                { "data" : "zone" },
                { "data" : "message" }
                
                ],
            "columnDefs": [
                {
                    "targets": "_all", // your case first column
                    "className": "text-center",
                    "width": "15%"
                }],
            "bDestroy": true, // to fix canoot reinitialise datatable
            "initComplete": function(settings, json) {
            }
    });
    },[reportData]);

    return (
        <section className="mt-180 mb-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 order-md-1">
        <div className="card card-custom">
        <div className="d-flex">
            <h4 className="mb-2 pageHead text-uppercase">Please enter a zip code to view the cities served and the Freight Force Approved Motor Carrier.</h4>
        </div>
        <form>
            <div className="row  mb-3">
                <div className="col-md-4">
                    <InputElement type="text" id="userLookupInput" name="userLookupInput" 
                        onChange={handleInputChange} maxLength="50" placeholder="Enter Zipcode"
                        value={filtersInput.userLookupInput || ''}
                        className={filtersReq.userLookupInput_ErrorClass} 
                        validation="required" onInvalid={onValidateFilters}
                        />
                </div>
                <div className="col-md-2">
                    <button className="btn btn-primary btn-block pull-right" type="button" onClick={onSubmitSearch}>Find</button>

                </div>
            </div>
        </form>
        
        <hr/>
        {
            showDataLoading && 
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center"> 
                    <HashLoader  color={'#1E73BE'} loading={showDataLoading} />
                </div>
            </div>
        }
        {
            filtersInput.showTable &&  
            <div id="datatable">
            <table id="userTbl" className="display" style={{width:"100%", border:"1px solid #ccc"}}>
                <thead>
                    <tr>
                        <th>Approved Motor Carrier</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip Code</th>
                        <th>Zone</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            </div>
        }
        </div>
        </div>
        </div>
        </div>
        </section>
    );
}


export default AreasServiced;