import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from "react";
import { copyStyles, extractStyles } from "../../utils/ValidationScripts";
import { fuelSurcharge, sendEmail } from "../restAPI/UtilsRest";
import PrintBol from "../quickQuote/PrintBol";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { HashLoader } from "react-spinners";
import $ from "jquery";

const ConfirmDirectBook = (props) => {

	const navigate = useNavigate();
	const location = useLocation();  //passed value from Navigator
	
	let dBookRes = location.state ? location.state?.quickQuote : []; //passed value from Navigator
	console.log(dBookRes)
	let sendEmailStatus = location.state ? location.state.sendEmailStatus : false; //passed value from Navigator
	let quickQuote = useSelector((state) => state.qqt);  // reducer call
	const [bolClassName, setBolClassName] = useState("d-none");
	const [loading, setLoading] = useState(false);

	const [saveMessage, setSaveMessage] = useState({ showMsg: false, status: '', message: '' });
	let quoteHis = false;

	if (location.state && location.state.quoteHis) {
		quoteHis = location.state.quoteHis;
		quickQuote = location.state.quoteBookData;
		dBookRes = location.state.quoteBookData;
	}

	const lineHaulServiceTypeList = [{ value: "1", label: "Terminal to Door" },
    { value: "2", label: "Door to Door" },
    { value: "3", label: "Door to Terminal" },
    // { value: "4", label: "Terminal to Terminal" }
    ];

	const listOfAccessorials = [];
	const listOfAccessorialsAmt = [];
	const listOfPickupAccessorials = [];
	const listOfPickupAccessorialsAmt = [];
	const listOfDeliveryAccessorials = [];
	const listOfDeliveryAccessorialsAmt = [];
	let accessorialDesc = '', accessorialAmts = '', accessorialTotalAmt = 0, amt = 0;
	let pickupAccessorialDesc = '', pickupAccessorialAmts = '', pickupAccessorialTotalAmt = 0.00;
	let deliveryAccessorialDesc = '', deliveryAccessorialAmts = '', deliveryAccessorialTotalAmt = 0.00;

	// if (quickQuote && quickQuote.shipment.accessorials) {
	// 	quickQuote.shipment.accessorials.forEach(eachAcc => {
	// 		if(eachAcc.surType){
	// 			if((eachAcc.surType).trim() === 'FLAT')
	// 			amt = eachAcc.amount;
	// 			else if((eachAcc.surType).trim() === 'PERPOUND'){
	// 				if(eachAcc.min > (eachAcc.amount * parseInt(quickQuote.shipment.weight)))
	// 					amt = eachAcc.min;
	// 				else 
	// 					amt = eachAcc.amount * parseInt(quickQuote.shipment.weight);
	// 			}
	// 		}
	// 		listOfAccessorials.push(eachAcc.description)
	// 		listOfAccessorialsAmt.push("$" + amt)
	// 		accessorialTotalAmt += amt;
	// 	})
	// 	accessorialDesc = listOfAccessorials.join(" , ");
	// 	accessorialAmts = listOfAccessorialsAmt.join(" + ");
	// }

	//console.log("dBookRes="+JSON.stringify(dBookRes))
	//console.log("quickQuote="+JSON.stringify(quickQuote))
	let pickupOpenTime = '', pickupCloseTime = '', deliveryOpenTime = '', deliveryCloseTime = '';
	let _custCompanyName = '', _custEmail = '', _custPhone = '', _custAddEmail = ''
		, _shipperCompanyName = '', _shipperAddr1 = '', _shipperAddr2 = '', _shipperCity = '', _shipperState = '', _shipperPostalCode = '', _shipperPhone = '', _shipperContact = '', _shipperReady = '', _shipperClose = ''
		, _consigneeCompanyName = '', _consigneeAddr1 = '', _consigneeAddr2 = '', _consigneeCity = '', _consigneeState = '', _consigneePostalCode = '', _consigneePhone = '', _consigneeContact = '', _consigneeReady = '', _consigneeClose = ''
		//, _pickupDate = '', _deliveryDate=''
		, _fromZip = '', _toZip = '', _serviceType = '', _moveType = '', _lineHaulServiceType = '', _comments = '', _quoteId = ''
		, _pounds = '', _pieces = '', _pallets = '', _cbm = '', _shipperNotes = '', _consigneeNotes = ''
		, _freightCharge = 0.00, _fuelSurcharge = 0.00, _totalCharges = 0.00, _discountCharges = 0.00, _lineHaulPrice
		, _westCoastSurchargeFee = 0.00, _crossDockFee = 0.00, _dropFee = 0.00, _yvrBorderFee = 0.00, _yvrCrossFee = 0.00
		, _accSerType = '', _bookingId = '', _isLinehaul = '', _isRoadRunner = false, transitTime = '', _nationWideAccDesc = '', _nationWideAcc =false, _nationWideAccAmt=0.00
		, _pCity = '', _dCity = '', _pickup_FreightCharge = 0.00, _pickup_FSC = 0.00, _pickup_FscRate = 0.00, _total_Pickup = 0.00
		, _delivery_FreightCharge = 0.00, _delivery_FSC = 0.00, _delivery_FscRate = 0.00, _total_Delivery = 0.00;


	if (dBookRes) {
		//console.log("Response -- BOL--",JSON.stringify(dBookRes))
		
		
		_bookingId = dBookRes?.bookId;
		_isLinehaul = dBookRes?.isLinehaul;
		_isRoadRunner = quickQuote.isRoadRunner;
		transitTime = quickQuote.transitTime;
		if (dBookRes?.customer) {
			let c = dBookRes.customer;
			console.log(c);
			_custCompanyName = c.companyName;
			_custEmail = c.email;
			_custPhone = c.phone && c.phone.length > 9 ? "(" + c.phone.substring(0, 3) + ") " + c.phone.substring(4, 7) + c.phone.substring(7) : c.phone;
			_custAddEmail = c.additionalEmails;
		}
		if (dBookRes?.shipper) {
			let s = dBookRes.shipper;
			console.log(s);
			_shipperCompanyName = s.companyName;
			_shipperAddr1 = s.address1;
			_shipperAddr2 = s.address2;
			_shipperCity = s.city;
			_shipperState = s.state;
			_shipperPostalCode = s.postalCode;
			_shipperPhone = s.phone;
			_shipperContact = s.contact;
			_fromZip = s.postalCode;
		}
		if (dBookRes?.consignee) {
			let c = dBookRes?.consignee;
			console.log(c);
			_consigneeCompanyName = c.companyName;
			_consigneeAddr1 = c.address1;
			_consigneeAddr2 = c.address2;
			_consigneeCity = c.city;
			_consigneeState = c.state;
			_consigneePostalCode = c.postalCode;
			_consigneePhone = c.phone;
			_consigneeContact = c.contact;
			_toZip = c.postalCode;
		}
		
		if (dBookRes?.quote) {
			let q = dBookRes.quote.rateQuote;
			console.log(q);
			_quoteId = q.quoteId;
			_freightCharge = parseFloat(q.freight_charge).toFixed(2);
			_fuelSurcharge = parseFloat(q.fsc).toFixed(2);
			_pCity = (q.pCity ? q.pCity : '');
			_dCity = (q.dCity ? q.dCity : '');
			_pickup_FreightCharge = (q.pickup_FreightCharge ? parseFloat(q.pickup_FreightCharge).toFixed(2) : 0.00);
			_pickup_FSC = (q.pickup_FSC ? parseFloat(q.pickup_FSC).toFixed(2) : 0.00);
			_pickup_FscRate = (q.pickup_FscRate ? parseFloat(q.pickup_FscRate).toFixed(2) : 0.00);
			_total_Pickup = (q.total_Pickup ? parseFloat(q.total_Pickup).toFixed(2) : 0.00);
			_delivery_FreightCharge = (q.delivery_FreightCharge ? parseFloat(q.delivery_FreightCharge).toFixed(2) : 0.00);
			_delivery_FSC = (q.delivery_FSC ? parseFloat(q.delivery_FSC).toFixed(2) : 0.00);
			_delivery_FscRate = (q.delivery_FscRate ? parseFloat(q.delivery_FscRate).toFixed(2) : 0.00);
			_discountCharges = (q.discountPrice ? parseFloat(q.discountPrice).toFixed(2) : 0.00);
			_lineHaulPrice = (q.lineHaulPrice ? parseFloat(q.lineHaulPrice).toFixed(2) : 0.00);
			//accessorialTotalAmt = (q.accessorialPrice ? parseFloat(q.accessorialPrice).toFixed(2) : 0.00);
			_total_Delivery = (q.total_Delivery ? parseFloat(q.total_Delivery).toFixed(2) : 0.00);

			if (_isLinehaul) {
				_westCoastSurchargeFee = (q.westCoastSurchargeFee ? parseFloat(q.westCoastSurchargeFee).toFixed(2) : 0.00);
				_crossDockFee = (q.crossDockFee ? parseFloat(q.crossDockFee).toFixed(2) : 0.00);
				_dropFee = (q.dropFee ? parseFloat(q.dropFee).toFixed(2) : 0.00);
				_yvrBorderFee = (q.yvrBorderFee ? parseFloat(q.yvrBorderFee).toFixed(2) : 0.00);
				_yvrCrossFee = (q.yvrCrossFee ? parseFloat(q.yvrCrossFee).toFixed(2) : 0.00);
			}
		}
		if (dBookRes?.shipment) {
			let s = dBookRes?.shipment;
			_shipperNotes = s.shipperNotes;
			_consigneeNotes = s.consigneeNotes;
			_pounds = s.weight;
			_cbm = s.dimWeight;
			_pieces = s.qty;
			_pallets = s.qty;
			_accSerType = s.accSerType;
			if (s.serviceType && s.serviceType != 0) {
				let lineHaulSerTyp = lineHaulServiceTypeList.find(eachobj => eachobj.value === s.serviceType);
				_lineHaulServiceType = lineHaulSerTyp.label;
			}
			pickupOpenTime = dBookRes?.shipment.pickupOpenTime && (new Date(dBookRes?.shipment.pickupOpenTime)).toLocaleTimeString('en-US', {
				hour12: false,
				// year: 'numeric',
				// month: '2-digit',
				// day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				// second: '0-digit',
			});
			pickupCloseTime = dBookRes?.shipment.pickupCloseTime && (new Date(dBookRes?.shipment.pickupCloseTime)).toLocaleTimeString('en-US', {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});
			deliveryOpenTime = dBookRes?.shipment.deliveryOpenTime && (new Date(dBookRes?.shipment.deliveryOpenTime)).toLocaleTimeString('en-US', {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});
			deliveryCloseTime = dBookRes?.shipment.deliveryCloseTime && (new Date(dBookRes?.shipment.deliveryCloseTime)).toLocaleTimeString('en-US', {
				hour12: false,
				hour: '2-digit',
				minute: '2-digit',
			});

			if (s.accessorials) {
				s.accessorials.forEach(eachAcc => {
					if((eachAcc.code).trim() ==='GRDROP'){
						_nationWideAccDesc = eachAcc.description;
						_nationWideAcc =true;
						_nationWideAccAmt=eachAcc.amount;
					}else
					{
					if (eachAcc.surType) {
						if ((eachAcc.surType).trim() === 'FLAT')
							amt = eachAcc.amount;
						else if ((eachAcc.surType).trim() === 'PERPOUND') {
							if (eachAcc.min > (eachAcc.amount * parseInt(dBookRes?.shipment.weight)))
								amt = eachAcc.min;
							else
								amt = eachAcc.amount * parseInt(dBookRes?.shipment.weight);
						}
					}
					listOfAccessorials.push(eachAcc.description + "- $" + amt);
					if (_accSerType === "both" && !_isLinehaul)
						amt = amt * 2;

					amt = parseFloat(amt).toFixed(2);
					listOfAccessorialsAmt.push("$" + amt)
					accessorialTotalAmt = Number(accessorialTotalAmt) + Number(amt);
				}
				})
				accessorialDesc = listOfAccessorials.join(" , ");
				accessorialAmts = listOfAccessorialsAmt.join(" + ");
				accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);
			}

			if (s.pickupAccessorials) {
				s.pickupAccessorials.forEach(eachAcc => {
					if((eachAcc.code).trim() ==='GRDROP'){
						_nationWideAccDesc = eachAcc.description;
						_nationWideAcc =true;
						_nationWideAccAmt=eachAcc.amount;
					}else
					{
					if (eachAcc.surType) {
						if ((eachAcc.surType).trim() === 'FLAT')
							amt = eachAcc.amount;
						else if ((eachAcc.surType).trim() === 'PERPOUND') {
							if (eachAcc.min > (eachAcc.amount * parseInt(dBookRes?.shipment.weight)))
								amt = eachAcc.min;
							else
								amt = eachAcc.amount * parseInt(dBookRes?.shipment.weight);
						}
					}
					listOfPickupAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
					if (_accSerType === "both" && !_isLinehaul)
						amt = amt * 2;

					amt = parseFloat(amt).toFixed(2);
					listOfPickupAccessorialsAmt.push("$" + amt)
					pickupAccessorialTotalAmt = Number(pickupAccessorialTotalAmt) + Number(amt);
				}
				})
				pickupAccessorialDesc = listOfPickupAccessorials.join(" , ");
				pickupAccessorialAmts = listOfPickupAccessorialsAmt.join(" + ");
				pickupAccessorialTotalAmt = parseFloat(pickupAccessorialTotalAmt).toFixed(2);
			}
		
		if (s.deliveryAccessorials) {
			s.deliveryAccessorials.forEach(eachAcc => {
				if((eachAcc.code).trim() ==='GRDROP'){
					_nationWideAccDesc = eachAcc.description;
					_nationWideAcc =true;
					_nationWideAccAmt=eachAcc.amount;
				}else
				{
				if (eachAcc.surType) {
					if ((eachAcc.surType).trim() === 'FLAT')
						amt = eachAcc.amount;
					else if ((eachAcc.surType).trim() === 'PERPOUND') {
						if (eachAcc.min > (eachAcc.amount * parseInt(dBookRes?.shipment.weight)))
							amt = eachAcc.min;
						else
							amt = eachAcc.amount * parseInt(dBookRes?.shipment.weight);
					}
				}
				listOfDeliveryAccessorials.push(eachAcc.description + "- $" + parseFloat(amt).toFixed(2));
				if (_accSerType === "both" && !_isLinehaul)
					amt = amt * 2;

				amt = parseFloat(amt).toFixed(2);
				listOfDeliveryAccessorialsAmt.push("$" + amt)
				deliveryAccessorialTotalAmt = Number(deliveryAccessorialTotalAmt) + Number(amt);
			}
			})
			deliveryAccessorialDesc = listOfDeliveryAccessorials.join(" , ");
			deliveryAccessorialAmts = listOfDeliveryAccessorialsAmt.join(" + ");
			deliveryAccessorialTotalAmt = parseFloat(deliveryAccessorialTotalAmt).toFixed(2);
		}
		accessorialTotalAmt = Number(pickupAccessorialTotalAmt)+Number(deliveryAccessorialTotalAmt);
		accessorialTotalAmt = parseFloat(accessorialTotalAmt).toFixed(2);

	}
	if (_isLinehaul || _isRoadRunner)
		_totalCharges = (Number(_total_Pickup) + Number(_total_Delivery) + Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt) + Number(_westCoastSurchargeFee) + Number(_crossDockFee) + Number(_dropFee) + Number(_yvrBorderFee) + Number(_yvrCrossFee) + Number(_nationWideAccAmt)).toFixed(2);
	else
		_totalCharges = (Number(_freightCharge) + Number(_fuelSurcharge) + Number(accessorialTotalAmt)).toFixed(2);

}

const closeBooking = () => {
	navigate("/freightQuote");
}

// const trackBooking = () => {
// 	navigate("/freightTracking");
// }

// useEffect(() => {
// 	if (!quoteHis) {
// 		window.history.pushState(null, document.title, window.location.href);
// 		window.addEventListener('popstate', function (event) {
// 			// window.history.pushState(null, document.title, window.location.href);
// 			navigate("/freightQuote", { state: { backStatus: false } });
// 		});
// 	}
// 	window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
// }, []);

useEffect(() => {
	if(sendEmailStatus){
		let originAriport = dBookRes?.shipper?.terminal;
		let destinationAriport = dBookRes?.consignee?.terminal;
		let isLinehaul =_isLinehaul;
		let isRoadRunner = _isRoadRunner;
		let  emailType= "Carrier";
		let bookId = _bookingId;
		if(isRoadRunner)
		emailBolPdf(emailType, bookId, originAriport, destinationAriport, isLinehaul, isRoadRunner);
		navigate(".", { replace: true,  state: { quickQuote: dBookRes, sendEmailStatus:false} })
	}
	// page when clicked refresh button

	// if (!quickQuote?.customer?.companyName) {
	// 	// alert("can be redirected")
	// 	setLoading(true)
	// 	// setHideQuoteDiv(true)
	// 	// alert("Donot refresh the page. Changes done will be lost.")
	// 	window.location.href = '/freightQuote';
	// }
}, []) // eslint-disable-line

const goBack = () => {
	window.history.go(-1)
}

const handlePrintClick = () => {
	const printableElements = document.getElementById('print-bol-area').innerHTML;
	//document.getElementById('print-area').innerHTML;
	var w = window.open('', "_blank");
	let cssStyles = extractStyles(window.document);
	const orderHtml = `<html><head><title></title>` + cssStyles + `</head><body>` + printableElements + `</body></html>`
	w.document.write(orderHtml);
	setTimeout(function () {
		w.window.print();
		w.window.focus();
		w.window.close();
	}, 1000);

};

const bolRef = useRef(null);

const downloadBolPdf = () => {
	setLoading(true);
	setBolClassName("");

	setTimeout(() => {
		const pdf = new jsPDF("portrait", "pt", "legal", true);
		const pdfElement = document.getElementById('print-bol-area');
		pdf.html(pdfElement, {
			width: 610, //  width: fit-content;		block-size: fit-content;
			//	html2canvas: { scale: 0.70, },
			callback: function (_pdf) {
				// _pdf.save('MyPdfFile.pdf')
				_pdf.save("BOL-" + dBookRes?.bookingNumber + ".pdf");
			},
			windowWidth: 1200,
		})
		setLoading(false);
		setBolClassName("d-none");

	}, 500);

};


const emailBolPdf = (emailType=null, bookId = null, originAriport=null, destinationAriport=null, isLinehaul=false, isRoadRunner=false) => {
	setLoading(true);
	setBolClassName("");
	
	setTimeout(() => {
		const pdf = new jsPDF("portrait", "pt", "legal", true);
		// create jsPDF object        
		const pdfElement = document.getElementById('print-bol-area');

		pdf.html(pdfElement, {
			width: 610, //  width: fit-content;	block-size: fit-content;
			//	html2canvas: { scale: 0.70, },
			callback: function (_pdf) {
				// _pdf.save('MyPdfFile.pdf')
				let htmltopdf = _pdf.output();
				let base64 = btoa(htmltopdf);
				let email = _custEmail;
				let addemail = _custAddEmail;
				let bolNo = _bookingId;
				let compName = _custCompanyName;
				let filename = "BOL-" + bolNo + ".pdf";
				let messBody = "Hi  " + compName;
				let sub = "BOL for Order  " + bolNo;

				

				let mailObj = {
					"emailType": emailType === "Carrier" ? emailType : null,
					"originTerminal": emailType ? originAriport : null,
					"destinationTerminal": emailType ? destinationAriport : null,
					"isRoadRunner": emailType ? isRoadRunner : false,
					"isLinehaul" :emailType ? isLinehaul : false,
					"bookId" : emailType ? bookId : null,
					"toRecipients": email && addemail ? email + "," + addemail : email ? email : addemail ? addemail : '',
					"ccRecipients": "",
					"bccRecipients": "",
					"subject": sub,
					"messageBody": messBody,
					"attachments": [
						{
							"data": base64,
							"fileName": filename
						}
					]
				};
				// console.log("reqeust data",JSON.stringify(mailObj))

				sendEmail(mailObj).then(eRes => {
					if (eRes instanceof Error) {
						setSaveMessage({ showMsg: true, status: 'error', message: eRes.result.emailResponse });

					} else {
						setSaveMessage({ showMsg: true, status: 'message', message: eRes.result.emailResponse });
						setTimeout(() => {
							setSaveMessage({ showMsg: false, status: '', message: '' });
						}, 2000);
					}
				})

			},

			windowWidth: 1200,
		})
		//var htmlout = pdf.output();
		//let base64 = 'data:application/pdf;base64,'+ Base64.encode(htmlout);

		setLoading(false);
		setBolClassName("d-none");
	}, 3000);

	//alert("Coming Soon..",base64);


}

return (
	<section className="mt-180 mb-4">
		<div id="print-area" className={loading ? 'container parentDisable' : 'container'}>
			{loading && <HashLoader color={'#1E73BE'} loading={loading} size={100} className={'loadSpin'} />}
			{!quoteHis &&
				<div className="col-md-12 alert alert-success text-center text-success QuoteLabel" id="invprintAll">
					<div className="row">
						<div className="col-md-8 mt-2">
							<h6> <i className="fas fa-check-circle" aria-hidden="true"></i> Booking Confirmed ! </h6>
						</div>
						<div className="col-md-4 ">
							<button className="btn btn-outline-danger  float-right" type="button" onClick={closeBooking}><i className="fas fa-times-circle"></i> CLOSE</button>
							<button className="btn btn-outline-primary  float-right mr-2" type="button" onClick={emailBolPdf}><i className="fas fa-envelope"></i> EMAIL BOL</button>
							<button className="btn btn-outline-primary  float-right mr-2" type="button" onClick={downloadBolPdf}><i className="fas fa-print"></i> PRINT BOL</button>

						</div>
					</div>
				</div>
			}
			{saveMessage.showMsg && <div className="col-md-12 alert alert-success text-center text-success QuoteLabel" > {saveMessage.message} </div>}
			<div className="row" >
				<div className="col-md-6 order-md-1">
					<div className="card card-custom">
						<div className="d-flex">
							<h4 className="mb-3 pageHead">Shipment Details</h4>
						</div>
						<div className="col-md-12">
							<div className="row" >
								<div className="col-md-12 mb-3">
									<div className="row quoteOut" >
										<div className="col-md mt-2">
											<label className="QuoteLabel"> Company-Name:</label>
										</div>
										<div className="col-md mt-2">
											<label className="QuoteLabelData"> {_custCompanyName} </label>
										</div>
									</div>
								</div>
							</div>
							<div className="row" >
								<div className="col-md-12 mb-3">
									<div className="row quoteOut">
										<div className="col-md-6 mt-2">
											<label className="QuoteLabel"> Email:</label>
										</div>
										<div className="col-md-6 mt-2">
											<label className="QuoteLabelData">  {_custEmail}</label>
										</div>
									</div>
								</div>
							</div>
							<div className="row" >
								<div className="col-md-12 mb-3">
									<div className="row quoteOut">
										<div className="col-md-6 mt-2">
											<label className="QuoteLabel"> Phone No:</label>
										</div>
										<div className="col-md-6 mt-2">
											<label className="QuoteLabelData">  {_custPhone} </label>
										</div>
									</div>
								</div>
							</div>
							<div className="row" >
								<div className="col-md-12 mb-3">
									<div className="row quoteOut pb-2" >
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-12 mt-2">
													<label className="QuoteLabel"> Shipper Information</label>
												</div>
											</div>
											<div className="row ">
												<div className="col-md-12 QuoteTableData mb-2" >
													{_shipperCompanyName} <br />
													{_shipperAddr1} , {_shipperAddr2} <br />
													{_shipperCity} , {_shipperState} , {_shipperPostalCode}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel"> Phone:</label>
												</div>
												<div className="col-md-6 QuoteTableData">
													<label className="QuoteTableData">{_shipperPhone}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel"> Contact: </label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> {_shipperContact}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel"> Ready:</label>
												</div>
												<div className="col-md-6 " >
													<label className="QuoteTableData"> {pickupOpenTime}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 QuoteTableLabel" >
													<label className="QuoteTableLabel">Close:</label>
												</div>
												<div className="col-md-6 " >
													<label className="QuoteTableData"> {pickupCloseTime}</label>
												</div>
											</div>
										</div>
										<div className="col-md-6">

											<div className="row">
												<div className="col-md-12 mt-2">
													<label className="QuoteLabel"> Consignee Information</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12 QuoteTableData mb-2" >
													{_consigneeCompanyName} <br />
													{_consigneeAddr1} , {_consigneeAddr2} <br />
													{_consigneeCity} , {_consigneeState} , {_consigneePostalCode}
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel">Phone:</label>
												</div>
												<div className="col-md-6 " >
													<label className="QuoteTableData">{_consigneePhone}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel">Contact:</label>
												</div>
												<div className="col-md-6 "  >
													<label className="QuoteTableData">{_consigneeContact}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel">Ready:</label>
												</div>
												<div className="col-md-6 " >
													<label className="QuoteTableData"> {deliveryOpenTime}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 " >
													<label className="QuoteTableLabel">Close:</label>
												</div>
												<div className="col-md-6 " >
													<label className="QuoteTableData">{deliveryCloseTime}</label>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div className="row quoteOut" >
										<div className="col-md-12 mb-3">
											<div className="row" >
												<div className="col-md-6 mt-2">
													<label className="QuoteLabel">From Zip</label>
												</div>
												<div className="col-md-6 mt-2">
													<label className="QuoteLabel">To Zip</label>
												</div>
											</div>

											<div className="row">
												<div className="col-md-6 QuoteLabelDataLarge" >
													{_fromZip}
												</div>
												<div className="col-md-6 QuoteLabelDataLarge" >
													{_toZip}
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							<div className="row quoteOut" >
								<div className="col-md-12 mt-2 mb-2">
									<div className="row" hidden={true}>
										<div className="col-md-6 mt-2">
											<label className="QuoteTableLabel"> Service Type: </label>
										</div>
										<div className="col-md-6 mt-2">
											<label className="QuoteLabelData">  {_serviceType} </label>
										</div>
									</div>
									<div className="row" hidden={true}>
										<div className="col-md-6">
											<label className="QuoteTableLabel"> Move Type: </label>
										</div>
										<div className="col-md-6">
											<label className="QuoteLabelData">  {_moveType} </label>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<label className="QuoteTableLabel"> Linehaul Service Type: </label>
										</div>
										<div className="col-md-6">
											<label className="QuoteLabelData">  {_lineHaulServiceType} </label>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<label className="QuoteTableLabel">Shipper Notes:</label>
										</div>
										<div className="col-md-6 mt-2">
											<label className="QuoteLabelData">  {_shipperNotes} </label>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<label className="QuoteTableLabel">Consignee Notes:</label>
										</div>
										<div className="col-md-6 mt-2">
											<label className="QuoteLabelData">  {_consigneeNotes} </label>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<label className="QuoteTableLabel">Transit Time:</label>
										</div>
										<div className="col-md-6">
											<label className="QuoteLabelData">  {transitTime} </label>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-6 order-md-1">
					<div className="card card-custom">
						<div className="d-flex">
							<h4 className="mb-3 pageHead text-uppercase">SHIPMENT BOOKING ID: {_bookingId}</h4>
							{/* <h4>&nbsp;&nbsp;&nbsp; WQ3298</h4> */}
						</div>
						<div className="col-md-12">
							<div className="row quoteOut" >
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-3 mt-2 text-center brdrRight">
											<div className="QuoteLabel mb-2">Pounds</div>
											<div className="QuoteLabelDataLarge">{_pounds}</div>
										</div>
										<div className="col-md-3 mt-2 text-center brdrRight">
											<div className="QuoteLabel mb-2">Pieces</div>
											<div className="QuoteLabelDataLarge">{_pieces}</div>
										</div>
										<div className="col-md-3 mt-2 text-center brdrRight">
											<div className="QuoteLabel mb-2">Dim Weight</div>
											<div className="QuoteLabelDataLarge">{_cbm}</div>
										</div>
										<div className="col-md-3 mt-2 text-center brdrRight">
											<div className="QuoteLabel mb-2">Pallets</div>
											<div className="QuoteLabelDataLarge">{_pallets}</div>
										</div>
										{/* <div className="col-md-4 mt-2 text-center">
											<div className="QuoteLabel">Hazardous</div>
											<div className="QuoteLabelDataLarge"> {quickQuote.addValue.hazardouesMaterial}</div>
										</div> */}
									</div>
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-12 mb-2">
									<div className="row quoteOut" >
										<div className="col-md-12 mb-3">
											<div className="row">
												<div className="col-md-12 mt-2">
													<label className="QuoteLabel" > Accessorials Details:</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 QuoteTableLabel">
													<label className="QuoteTableLabel"> Pickup Accessorials:</label>
												</div>
												<div className="col-md-6 QuoteTableData">
													<label className="QuoteTableData"> {pickupAccessorialDesc}</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6 QuoteTableLabel">
													<label className="QuoteTableLabel"> Delivery Accessorials:</label>
												</div>
												<div className="col-md-6 QuoteTableData">
													<label className="QuoteTableData"> {deliveryAccessorialDesc}</label>
												</div>
											</div>
											{(!_isRoadRunner) &&
											<>
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel">Pickup Charge:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> {pickupAccessorialAmts} </label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel">Delivery Charge:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> {deliveryAccessorialAmts} </label>
												</div>
											</div>
											</>
											}
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> Total:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> ${accessorialTotalAmt} </label>
												</div>
											</div>
											{/* <p>Accessorial(s) to apply to {_accSerType === "both" ? "Pickup and Delivery" : _accSerType}</p> */}
										</div>
									</div>
								</div>
							</div>

							{(_isRoadRunner || _isLinehaul) &&
								<div className="row">
									<div className="col-md-12 mb-3">
										<div className="row quoteOut" >
											<div className="col-md-12 mb-3">
												<div className="row">
													<div className="col-md-12 mt-2">
														<label className="QuoteLabel "> {(_isRoadRunner) ?"Pickup and Delivery Details":"LineHaul Details:"}</label>
													</div>
													<div className="col-md-6 mt-2">
														<label className="QuoteLabel"> Pickup  &ensp;&ensp;&ensp;&ensp; <span className="QuoteTableData">{_pCity}</span></label>
													</div>
													<div className="col-md-6 mt-2">
														<label className="QuoteLabel">  Delivery   &ensp;&ensp;&ensp;&ensp; <span className="QuoteTableData">{_dCity}</span></label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Pickup Freight Charge:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_pickup_FreightCharge}</label>
													</div>
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Delivery Freight Charge:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_delivery_FreightCharge}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Pickup Fuel Surcharge:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_pickup_FSC}</label>
													</div>
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Delivery Fuel Surcharge:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_delivery_FSC}</label>
													</div>
												</div>
												<div className="row">
													<div className="col-md-4">
														<label className="QuoteTableLabel">  Total Charges:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_total_Pickup}</label>
													</div>
													<div className="col-md-4">
														<label className="QuoteTableLabel"> Total Charges:</label>
													</div>
													<div className="col-md-2">
														<label className="QuoteTableData"> ${_total_Delivery}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							}

							<div className="row">
								<div className="col-md-12 mb-3">
									<div className="row quoteOut" >
										<div className="col-md-12 mb-3">
											<div className="row">
												<div className="col-md-12 mt-2">
													<label className="QuoteLabel"> Charges:</label>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> {(_isRoadRunner || _isLinehaul) ? "Linehaul " : "Freight "} Charge:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> ${_freightCharge}</label>
												</div>
											</div>
											{/* <div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> West Coast Surcharge Fee:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> $  </label>
												</div>
											</div> */}
											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> Fuel Surcharge:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> ${_fuelSurcharge}</label>
												</div>
											</div>
											{(_isRoadRunner || _isLinehaul) && _westCoastSurchargeFee !== 0.00 &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> West Coast Surcharge Fee:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_westCoastSurchargeFee}</label>
													</div>
												</div>
											}
											{(_isRoadRunner || _isLinehaul) && _crossDockFee !== 0.00 &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Cross Dock Fee:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_crossDockFee}</label>
													</div>
												</div>
											}
											{(_isRoadRunner || _isLinehaul) && _dropFee !== 0.00 &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> Drop Fee:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_dropFee}</label>
													</div>
												</div>
											}
											{(_isRoadRunner || _isLinehaul) && _yvrBorderFee !== 0.00 &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> YVR Border Fee:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_yvrBorderFee}</label>
													</div>
												</div>
											}
											{(_isRoadRunner || _isLinehaul) && _yvrCrossFee !== 0.00 &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> YVR Cross Fee:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_yvrCrossFee}</label>
													</div>
												</div>
											}

											<div className="row">
												<div className="col-md-6">
													<label className="QuoteTableLabel"> Accessorial Total:</label>
												</div>
												<div className="col-md-6">
													<label className="QuoteTableData"> ${accessorialTotalAmt} </label>
												</div>
											</div>
											{(_isRoadRunner && _discountCharges !== 0) &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel">Discount :</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${_discountCharges}</label>
													</div>
												</div>}
												{(_nationWideAcc) &&
												<div className="row">
													<div className="col-md-6">
														<label className="QuoteTableLabel"> {_nationWideAccDesc}:</label>
													</div>
													<div className="col-md-6">
														<label className="QuoteTableData"> ${parseFloat(_nationWideAccAmt).toFixed(2)} </label>
													</div>
												</div>
												}
											<p></p>
										</div>
									</div>
								</div>
							</div>

							<div className="row" >
								<div className="col-md-12 mb-3">
									<div className="row quoteOut" >
										<div className="col-md mt-2">
											<label className="QuoteLabel"> Total Charges:</label>
										</div>
										<div className="col-md mt-2">
											<label className="QuoteLabelDataLarge">  ${_totalCharges} </label>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-8 "></div>
								<div className="col-md-4 ">
									{quoteHis &&
										<button className="btn btn-outline-primary btn-block pull-right" type="button" onClick={goBack}><i className="fas fa-arrow-alt-circle-left"></i> BACK</button>
									}
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{_bookingId &&
			<div id="print-bol-area" className={bolClassName} ref={bolRef}>
				{/* {bolClassName}> */}
				<PrintBol bookingRespData={dBookRes} quickQuoteData={quickQuote}></PrintBol>
			</div>
		}
	</section>
);
}
export default ConfirmDirectBook;
