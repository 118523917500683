import axios from "axios";


export const tariffRates = (terminal,custCode,userid) => {

    var getValue="?terminal="+terminal+"&userid="+userid+"&custCode="+custCode;
    //console.log("tariff rate Values are "+getValue)
    if(terminal)
     return axios.get(process.env.REACT_APP_TARIFF_RATES_URL+getValue)
        .then(response => {
            //console.log("REACT_APP_TARIFF_RATES_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_RATES_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in tariffRates ");
        })
}

export const getTariffInfo = (terminal) => {
    return new Promise((resolve, reject) => {
    var getValue="?airportCode="+terminal;
    //console.log("tariff rate Values are "+getValue)
    if(terminal)
     return axios.get(process.env.REACT_APP_TARIFFINFO+getValue)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const updateTariffInfo = (tariffInfo) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    console.log("tariff rate Values are "+JSON.stringify(tariffInfo));
    const postData = {
        "companyName": tariffInfo?.companyName,
        "address1": tariffInfo?.address1,
        "address2": tariffInfo?.address2,
        "city": tariffInfo?.city,
        "state": tariffInfo?.state,
        "postalCode": tariffInfo?.postalCode,
        "phoneNumber": tariffInfo?.phoneNumber,
        "airportCode": tariffInfo?.airportCode,
        "emergencyPhone": tariffInfo?.emergencyPhone,
        "email": tariffInfo?.email,
        "manager": tariffInfo?.manager,
        "region": tariffInfo?.region,
        //"latitude": 0,
        //"longitude": 0
      }
    if(tariffInfo)
     return axios.put(process.env.REACT_APP_UPDATE_TARIFFINFO,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const getTariffRate = (terminal) => {
    return new Promise((resolve, reject) => {
    var getValue="?carrier="+terminal;
    //console.log("tariff rate Values are "+getValue)
    if(terminal)
     return axios.get(process.env.REACT_APP_TARIFF_TARIFFS+"/"+terminal)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in gettariffrates in the tariff update for a station ");
        })
    });
}

export const createTariffRate = (newRecord) => {
    return new Promise((resolve, reject) => {
    //var getValue="?carrier="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const requestBody = {
        "carrier": newRecord?.carrier,
        "area": newRecord?.area,
        "newMin": newRecord?.newMin? Number(newRecord?.newMin) : Number(0),
        "newLbs100": newRecord?.newLbs100? Number(newRecord?.newLbs100) : Number(0),
        "newLbs500": newRecord?.newLbs500? Number(newRecord?.newLbs500) : Number(0),
        "newLbs1000": newRecord?.newLbs1000? Number(newRecord?.newLbs1000) : Number(0),
        "newLbs2000": newRecord?.newLbs2000? Number(newRecord?.newLbs2000) : Number(0),
        "newLbs3000": newRecord?.newLbs3000? Number(newRecord?.newLbs3000) : Number(0),
        "newLbs5000": newRecord?.newLbs5000? Number(newRecord?.newLbs5000) : Number(0),
        "newLbs10000": newRecord?.newLbs10000? Number(newRecord?.newLbs10000) : Number(0),
        "newLbs15000": newRecord?.newLbs15000? Number(newRecord?.newLbs15000) : Number(0),
        "newLbs20000": newRecord?.newLbs20000? Number(newRecord?.newLbs20000) : Number(0),
        "newCapRate": newRecord?.newCapRate? Number(newRecord?.newCapRate) : Number(0),
      };
    if(newRecord)
     return axios.post(process.env.REACT_APP_TARIFF_TARIFFS,requestBody)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in gettariffrates in the tariff update for a station ");
        })
    });
}

export const updateTariffRate = (tariffRates,zone,stationId) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const postData = {
            "zone": zone,
            "stationID": stationId,
            "newMin": tariffRates?.newMin? Number(tariffRates?.newMin) : Number(0),
            "new100": tariffRates?.newLbs100? Number(tariffRates?.newLbs100) : Number(0),
            "new500": tariffRates?.newLbs500? Number(tariffRates?.newLbs500) : Number(0),
            "new1000": tariffRates?.newLbs1000? Number(tariffRates?.newLbs1000) : Number(0),
            "new2000": tariffRates?.newLbs2000? Number(tariffRates?.newLbs2000) : Number(0),
            "new3000": tariffRates?.newLbs3000? Number(tariffRates?.newLbs3000) : Number(0),
            "new5000": tariffRates?.newLbs5000? Number(tariffRates?.newLbs5000) : Number(0),
            "new10000": tariffRates?.newLbs10000? Number(tariffRates?.newLbs10000) : Number(0),
            "new15000": tariffRates?.newLbs15000? Number(tariffRates?.newLbs15000) : Number(0),
            "new20000": tariffRates?.newLbs20000? Number(tariffRates?.newLbs20000) : Number(0),
            "newCap": tariffRates?.newCapRate? Number(tariffRates?.newCapRate) : Number(0),
      }
    if(tariffRates)
     return axios.put(process.env.REACT_APP_UPDATE_TARIFFS,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const getAccessorials = (terminal) => {
    return new Promise((resolve, reject) => {
    var getValue="?carrier="+terminal;
    //console.log("tariff rate Values are "+getValue)
    if(terminal)
     return axios.get(process.env.REACT_APP_TARIFF_SURCHARGES+"/"+terminal)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in getAccessorials in tariff update for a station ");
        })
    });
}

export const createAccessorials = (newRecord) => {
    return new Promise((resolve, reject) => {
    //var getValue="?carrier="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const postData = {
        "airportCode": newRecord?.airportCode,
        "code": newRecord?.code,
        "description": newRecord?.description,
        "chargeType": newRecord?.chargeType,
        "requireAuthorization": true,
        "rate": newRecord?.rate? Number(newRecord?.rate) : Number(0),
        "minimum": newRecord?.minimum? Number(newRecord?.minimum) : Number(0),
        "maximum":newRecord?.maximum? Number(newRecord?.maximum) : Number(0),
        "perLb1": newRecord?.perLb1? Number(newRecord?.perLb1) : Number(0),
        "perLb2": newRecord?.perLb2? Number(newRecord?.perLb2) : Number(0),
        "perLbRate1": newRecord?.perLbRate1? Number(newRecord?.perLbRate1) : Number(0),
        "perLbRate2": newRecord?.perLbRate2? Number(newRecord?.perLbRate2) : Number(0),
        "baseAmount": newRecord?.baseAmount? Number(newRecord?.baseAmount) : Number(0),
      };
    if(newRecord)
     return axios.post(process.env.REACT_APP_TARIFF_SURCHARGES,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in getAccessorials in tariff update for a station ");
        })
    });
}

export const updateAccessorials = (accesorials,code,airportCode) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    console.log("accesorials are "+JSON.stringfy(accesorials))
    //newdeInterval":"67","newcalcDeInterval":"56" These two are in request
    //newperLb1 newperLb2 are not in UI 
    var reqAuth = false;
    if(accesorials.newrequireAuthorization === 'Yes')
    reqAuth = true;
    const postData = {
            "airportCode": airportCode,
            "code": code,
            //"description": "string",
            //"chargeType": "string",
            "requireAuthorization": reqAuth,
            "rate": accesorials?.newrate? Number(accesorials?.newrate) : Number(0),
            "minimum": accesorials?.newminimum? Number(accesorials?.newminimum) : Number(0),
            "maximum": accesorials?.newmaximum? Number(accesorials?.newmaximum) : Number(0),
            "perLb1": accesorials?.newperLb1? Number(accesorials?.newperLb1) : Number(0),
            "perLb2": accesorials?.newperLb2? Number(accesorials?.newperLb2) : Number(0),
            "perLbRate1": accesorials?.newperLbRate1? Number(accesorials?.newperLbRate1) : Number(0),
            "perLbRate2": accesorials?.newperLbRate2? Number(accesorials?.newperLbRate2) : Number(0),
            "baseAmount": accesorials?.newbaseAmount? Number(accesorials?.newbaseAmount) : Number(0),
      }
    if(accesorials)
     return axios.put(process.env.REACT_APP_UPDATE_SURCHARGES,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const getSpecialRates = (terminal) => {
    return new Promise((resolve, reject) => {
    var getValue="?carrier="+terminal;
    //console.log("getSpecialRates rate Values are "+terminal)
    if(terminal)
     return axios.get(process.env.REACT_APP_TARIFF_SPECIALS+"/"+terminal)
        .then(response => {
            //console.log("REACT_APP_TARIFF_SPECIALS response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_SPECIALS"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in getSpeicalRate in tariff update for a station ");
        })
    });
}

export const createSpecialRates = (newRecord) => {
    return new Promise((resolve, reject) => {
    //var getValue="?carrier="+terminal;
    //console.log("getSpecialRates rate Values are "+terminal)
    const postData = {
        "terminal": newRecord?.terminal,
        "zone":  newRecord?.zone,
        "rate": {
          "moN_FRI_0000_0759": newRecord?.moN_FRI_0000_0759? Number(newRecord?.moN_FRI_0000_0759) : Number(0),
          "moN_FRI_0800_1700": newRecord?.moN_FRI_0800_1700? Number(newRecord?.moN_FRI_0800_1700) : Number(0),
          "moN_FRI_1701_2359": newRecord?.moN_FRI_1701_2359? Number(newRecord?.moN_FRI_1701_2359) : Number(0),
          "saturday": newRecord?.saturday? Number(newRecord?.saturday) : Number(0),
          "sunday": newRecord?.sunday? Number(newRecord?.sunday) : Number(0),
          "holiday": newRecord?.holiday? Number(newRecord?.holiday) : Number(0),
        }
      };
    if(newRecord)
     return axios.post(process.env.REACT_APP_TARIFF_SPECIALS,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF_SPECIALS response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_SPECIALS"+JSON.stringify(error));
            //
            reject(error);
        })
        .finally(() => {
            // console.log("Finally call in getSpeicalRate in tariff update for a station ");
        })
    });
}

export const updateSpecialRates = (specialRates) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const postData = {
        "companyName": "string",
        "address1": "string",
        "address2": "string",
        "city": "string",
        "state": "string",
        "postalCode": "string",
        "phoneNumber": "string",
        "airportCode": "string",
        "emergencyPhone": "string",
        "email": "string",
        "manager": "string",
        "region": "string",
        "latitude": 0,
        "longitude": 0
      }
    if(specialRates)
     return axios.put(process.env.REACT_APP_UPDATE_SPECIALS,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const tariffAirportCode = () => {

   // var getValue="?terminal="+terminal+"&userid="+userid+"&custCode="+custCode;
    //console.log("tariff rate Values are "+getValue)
     return axios.get(process.env.REACT_APP_TARIFF_AIRPORTCODE_URL)
        .then(response => {
            //console.log("REACT_APP_TARIFF_AIRPORTCODE_URL response "+JSON.stringify(response.data))
            return response.data;
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_AIRPORTCODE_URL"+JSON.stringify(error));
            return error;
        })
        .finally(() => {
            // console.log("Finally call in tariffAirportCode ");
        })
}

export const getOpenClose = () => {
    return new Promise((resolve, reject) => {
     return axios.get(process.env.REACT_APP_TARIFF_OPENCLOSE)
        .then(response => {
            //console.log("REACT_APP_TARIFF_OPENCLOSE response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_OPENCLOSE"+JSON.stringify(error));
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in getOpenClose in the tariff update management");
        })
    });
}

export const updateOpenClose = (terminal,status) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const postData = {
            "terminal": terminal,
            "isOpen": status
      }
    if(terminal)
     return axios.put(process.env.REACT_APP_UPDATE_OPENCLOSE,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}

export const getTariffUsers = (status) => {
    return new Promise((resolve, reject) => {
        var param="?filterWithAccess="+status
     return axios.get(process.env.REACT_APP_TARIFF_USERS,param)
        .then(response => {
            //console.log("REACT_APP_TARIFF_USERS response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF_USERS"+JSON.stringify(error));
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in getTariffUsers in the tariff update management");
        })
    });
}

export const updateTariffUsers = (userId,status) => {
    return new Promise((resolve, reject) => {
   // var getValue="?airportCode="+terminal;
    //console.log("tariff rate Values are "+getValue)
    const postData = {
        "id": userId,
        "allowUpdate": status
      }
    if(userId)
     return axios.put(process.env.REACT_APP_UPDATE_OPENCLOSE,postData)
        .then(response => {
            //console.log("REACT_APP_TARIFF response "+JSON.stringify(response.data))
            resolve(response.data);
        })
        .catch(error => {
            // console.log("Error in calling api REACT_APP_TARIFF"+JSON.stringify(error));
            //
            reject({error});
        })
        .finally(() => {
            // console.log("Finally call in tariffinfo for a station ");
        })
    });
}
