import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'
import { isValidSelect } from '../utils/ValidationScripts';
const SelectElement = (props) => {
    const updateValueToState = (e)=>{
         props.onChange([props.id],e.value);
         validation(e);
        
    }
   
    const validation = (e)=>{
        if(props.validation === "required"){
            //console.log("inputlement for validation "+[props.id]+"="+e.value)
            if(isValidSelect(e.value)){
                props.onInvalid(props.id,false);
            }else{
                props.onInvalid(props.id,true);
            }
        }
    }

    
        return (
                <Select type = {props.type}
                        label={props.label}
                        id ={props.id}
                        isDisabled={props.disabled}
                        options = {props.optionsData}
                        value={props.optionsData.filter(function(eachoption) {
                            return eachoption.value === props.value;
                          })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                        onChange={updateValueToState}
                        placeholder={props.placeholder} />
                
        );
        
}

SelectElement.propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
   // optionsData: [],
};


SelectElement.defaultProps = {
    type: 'select',
    label: '',
    id: '',
    className: 'form-control',
    placeholder: '',
    isDisabled: false
  //  optionsData: [],
};

export default SelectElement;
