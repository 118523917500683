import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react'
import {adminUsers,corporateUsers,carrierUsers,customerUsers,accountInfo} from '../../../restAPI/CorporateCenterLinkRest';

const DataWebAccount = () => {
    const  [initFiltersInput] = [{
        accountType : '',
        actionType : '',
        multipage : false,
        selectedUID : [],
        reason : '',
        showTable: false,
        selectedIds: []
       
    }]

    const [initFiltersReq] = [{
        // accountType:    true,
        // accountType_ErrorClass : 'custom-control-input',
        actionType:    true,
        actionType_ErrorClass : 'custom-control-input',
        selectedUID : true,
        selectedUID_ErrorClass : 'form-control'
    }];

    const [filtersInput, setFiltersInput] = useState(initFiltersInput);
    const [filtersReq,setFiltersReq] = useState(initFiltersReq);

    const [reportData, setReportData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [selectedUID, setSelectedUID] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);

    const onSubmitSearch = () => {
        const copy = {...filtersReq};
        var status = false;
        let errObject={};
        let validationReqFlag = false;
      //  console.log("Copy total "+JSON.stringify(copy));
        Object.entries(copy).map(([key, value]) => {
            if(!key.includes("_ErrorClass") && value  ){
                var errname=key+"_ErrorClass";
                var _css = "form-control";
                var errState = null;
                if(key==="actionType"){
                    errState={[key]: true,[errname]: ' custom-control-input is-invalid'};
                }else{
                    errState={[key]: true,[errname]: _css+' is-invalid'};

                }
                status = true;
                Object.assign(errObject,errState);
               // console.log("Total Error obj "+JSON.stringify(errObject));
            }
        })
        if(status){
            setFiltersReq({...filtersReq,...errObject});
        }

        let reqObj = { ...filtersReq };
        if(reqObj){
            Object.entries(reqObj).forEach(([key, value]) => {
                if (key.includes("_ErrorClass")) {
                    delete reqObj[key]
                }
            })
        }
        // console.log("c="+JSON.stringify(reqObj))
        validationReqFlag = Object.values(reqObj).includes(true);
        // console.log("validationReqFlag=" + validationReqFlag)
        return validationReqFlag;
            
    }

    const onValidateFilters = (name, value) => {
        var errState={};
        var status=false;
        //const copy = {...filtersReq};
        //Object.entries(copy).map(([key]) => {
            var key = name;
            var errname=key+"_ErrorClass";
            var _css = (key==="actionType" ? "custom-control-input" : "form-control");
              if(key === name && value) {
                 errState = {[name]: true,[errname]: _css+' is-invalid'};
                status=true;
              }else if(key === name && !value) {
                 errState = {[name]: false,[errname]: _css+''};
                status=true;
              }
         //   })
            if(status)
            setFiltersReq({...filtersReq, ...errState});
      }
    
    //let userProfileData = useSelector((state) => state.usr)
    let userProfileData = JSON.parse(localStorage.getItem("userProfile"));
    const loadReportData = () => {
        
        let custCode = userProfileData.custCode;
        let userId = userProfileData.userId;
        let accessLevel = userProfileData.permissionsLevel;
        
        if(accessLevel === 7){
            getAdminUsers();
        }else if(accessLevel === 6){
            getCorporateUsers();
        }else if(accessLevel === 4){
            getCarrierUsers(userId);
        }else if(accessLevel === 2){
            getCustomerUsers(userId,custCode);
        }
        setFiltersInput({...filtersInput, showTable: true});
    }

    const getAccountInfo = (userId) =>{
        accountInfo(userId)
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
                setFullData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const getAdminUsers = () =>{
        adminUsers()
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
                setFullData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const getCorporateUsers = () =>{
        corporateUsers()
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
                setFullData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const getCarrierUsers = (userId) =>{
        carrierUsers(userId)
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
                setFullData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const getCustomerUsers = (userId,custCode) =>{
        customerUsers(userId,custCode)
            .then(reponseDate => {
            if( reponseDate instanceof Error ){
                setReportData([]);
            }else{
                setReportData([...reponseDate]);
                setFullData([...reponseDate]);
            }
            setShowDataLoading(false);
            setFiltersInput({...filtersInput, showTable: true});
            }).catch(err => {
            setShowDataLoading(false);
            setReportData([]);
        })
    }

    const handleInputChange = (name, value) => {
        // console.log("selva=",value)
        if(name === "selectedIds" ){
            setFiltersInput({...filtersInput, [name]: [...value],["selectedUID"] : [...value]});
            if( value.length>0 ){
                setFiltersReq({...filtersReq, ...{selectedUID : false, selectedUID_ErrorClass : 'form-control'}});
            }else{
                setFiltersReq(initFiltersReq);
                setFiltersReq({...filtersReq, ...{selectedUID : true, selectedUID_ErrorClass : 'form-control'}});
            }
        }
        else{  
            setFiltersInput({...filtersInput, [name]: value});
        }
    }

    const handleSelectedUID = (value) => {
            setSelectedUID(value);
    }

    
    const filterData = () => {
        //let mounted = true;
        const fullDataSet = [...fullData];
      //  console.log(filtersInput.accountType);
        const filteredReportData = fullDataSet.filter((eachRow) => {
           // console.log(eachRow.Status+"="+filtersInput.accountType+"="+(eachRow.Status === filtersInput.accountType))
            return eachRow.Status === filtersInput.accountType; 
        });
       // console.log("filteredReportData="+!filteredReportData+" fullData==>"+filteredReportData.length)
       
        setFiltersInput({...filtersInput, showTable: false,["selectedIds"] : [], ["selectedUID"] : [],multipage : false, actionType:'', reason : ''});
        setShowDataLoading(true);
        setSelectedUID([]);
        setFiltersReq(initFiltersReq);
        if(filtersInput.accountType === 'AR')
        setReportData([...fullDataSet]);
        else
        setReportData([...filteredReportData]);
        setFiltersInput({...filtersInput, showTable: true});
        setShowDataLoading(false);
       // console.log("accountType="+filtersInput.accountType+" fullData==>"+JSON.stringify(reportData))
        //return () => mounted = false;
    }

    useEffect(() => {
        filterData();
    }, [filtersInput.accountType])

    useEffect(() => {
        refreshData()
    }, [])
    
    const clearForm = () => {
        setFiltersInput({...filtersInput, ...{accountType : '', actionType : '', selectedUID : [], reason : '', selectedIds: [] } });
        setFiltersReq(initFiltersReq);
    }

    const refreshData = () => {
        setShowDataLoading(true);
        loadReportData();
    }

    return {
        handleInputChange,
        onSubmitSearch,
        filterData,
        handleSelectedUID,
        onValidateFilters,
        clearForm,
        loadReportData,
        refreshData,
        selectedUID,
        filtersInput,
        filtersReq,
        reportData,
        showDataLoading
    }
}

export default DataWebAccount;