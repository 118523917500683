import React from 'react';
import PropTypes from 'prop-types';

import {isNumberAndNotZero, isValidAlpha, isValidAlphaNumeric, isValidEmail, isValidInteger, isValidPhoneNo, validateNumberDecimal, validatePassword} from '../utils/ValidationScripts'
const InputElement = (props) => {
    const updateValueToState = (e)=>{

        if(props.type === "checkbox" ){
            props.onChange([props.id],e.target.checked);
        }
        else if(props.type === "radio" ){
            props.onChange([props.name],e.target.value);
        }else{
            props.onChange([props.id],e.target.value);
        }
        validation(e);
    }
    // Iterate the properties with key and values.
        // Object.entries(props).map(([key, value]) => (
        //      console.log(key +"-"+value)
        // ))

    const validation = (e)=>{
         //console.log("inputlement for validation "+[props.id])
        if( !props.onInvalid )
            return false;

        if(props.validation === "required"){
            if(!validatePassword(e.target.value)){
                props.onInvalid(props.id,true);
                }else{
                props.onInvalid(props.id,false);
                }
        }else if(props.validation === "email"){
            //email validation function call
            //... we will add the n validations.
            if(isValidEmail(e.target.value)){
                 props.onInvalid(props.id,false);
            }else{
                 props.onInvalid(props.id,true);
            }
        }else if(props.validation === "phoneNo"){
            if(isValidPhoneNo(e.target.value)){
                 props.onInvalid(props.id,false);
            }else{
                 props.onInvalid(props.id,true);
            }
        }else if(props.validation === "not-zero" ){
            if(isNumberAndNotZero(e.target.value)){
                props.onInvalid(props.id,false);
           }else{
                props.onInvalid(props.id,true);
           }
        }else if(props.validation === "requiredRadio"){
            if(!validatePassword(e.target.value)){
                props.onInvalid(props.name,true);
            }else{
                props.onInvalid(props.name,false);
            }
        }else if(props.validation === "alpha-numeric" ){
            if(isValidAlphaNumeric(e.target.value)){
                props.onInvalid(props.id,false);
           }else{
                props.onInvalid(props.id,true);
           }
        }else if(props.validation === "alpha" ){
            if(isValidAlpha(e.target.value)){
                props.onInvalid(props.id,false);
           }else{
                props.onInvalid(props.id,true);
           }
        }else if(props.validation === "integerOnly" ){
            if(isValidInteger(e.target.value)){
                props.onInvalid(props.id,false);
           }else{
                props.onInvalid(props.id,true);
           }
        }else if(props.validation === "decimal" ){
            if(validateNumberDecimal(e.target.value)){
                props.onInvalid(props.id,false);
           }else{
                props.onInvalid(props.id,true);
           }
        }
    }

    

        return (
           
                <input 
                     {...props}
                     
                     onChange = {updateValueToState}
                     onBlur = {validation}
                />
           );
}

InputElement.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool
    //resetDependents: PropTypes.array
};


InputElement.defaultProps = {
    type: 'text',
    label: '',
    id: '',
    className: 'form-control',
    placeholder: '',
    readOnly:false
    //resetDependents: ''
};

export default InputElement;
